import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ContactDashboardService } from '../../../../services/contact-dashboard.service';
import { AddNewCompanyComponent } from '../../../../../shared/custom-add/add-new-company/add-new-company.component';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';


@Component({
  selector: 'kt-widget4-company',
  templateUrl: './widget4-company.component.html',
  styleUrls: ['./widget4-company.component.scss']
})
export class Widget4CompanyComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Customer';
  contactCompanies: any;
  isError = false;
  errorMessage: string;

  public isCollapsed = false;

  constructor(
    private modalService: NgbModal,
    private _cds: ContactDashboardService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-company');
    this.fetchContactCompany(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.companyResult(data);
      });
    }

    if(this.tabHeading['customers'])
      this.headingName = this.tabHeading['customers'];

  }

  fetchContactCompany(projectId: number, contactId: number){
    this._cds.getContactCompany(projectId, contactId).subscribe(
      res=>{
        this.contactCompanies = res['responseData'];
        this.SpinnerService.hide('dashboard-company');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-company');
      }
    )
  }

  addCompany() {
    const _saveMessage = `Opportunity  has been modified.`;
   	const ref = this.modalService.open(AddNewCompanyComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );
    
    ref.componentInstance.moduleType=2;
    ref.componentInstance.flag='contact';
    ref.componentInstance.contactId = this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.companyResult(result);
    });

  }

  companyResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-company');
      this.fetchContactCompany(this.selectedProject, this.parentData);
    }
  }

}
