// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
// Components
import { BaseComponent } from './views/theme/base/base.component';
// Auth
import { AuthGuard } from './core/auth';

const routes: Routes = [
  { path: 'auth', 
    loadChildren: () => import('./views/pages/auth/auth.module').then(m => m.AuthModule),
    data: {title: 'Authentication'}, 
  },
  {
    path: 'dialer-dashboard',
    loadChildren: () => import('./views/pages/dialer-dashboard/dialer-dashboard.module').then(m => m.DialerDashboardModule),
    data: {title: 'Dialer Dashboard'},
  },
  {
    path: 'dialer',
    loadChildren: () => import('./views/pages/dialer/dialer.module').then(m => m.DialerModule),
    data: {title: 'Dialer'},
  },
  // { path: 'error', loadChildren: () => import('./views/pages/error/error.module').then(m => m.ErrorModule) },
  {
    path: '',
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [

      //--- DASHBOARD
      {
        path: 'dashboard',
        loadChildren: () => import('./views/pages/dashboard/dashboard.module')
          .then(m => m.DashboardModule),
         data: {title: 'Dashboard'},
      },
      {
        path: 'customer-dashboard/:companyId/:refId',
        loadChildren: () => import('./views/pages/company-dashboard/company-dashboard.module')
          .then(m => m.CompanyDashboardModule),
         data: {title: 'Customer Dashboard'},
      },
      {
        path: 'customer-dashboard/:companyId',
        loadChildren: () => import('./views/pages/company-dashboard/company-dashboard.module')
          .then(m => m.CompanyDashboardModule),
           data: {title: 'Customer Dashboard'},
      },
      {
        path: 'opportunity-dashboard/:opportunityId',
        loadChildren: () => import('./views/pages/opportunity-dashboard/opportunity-dashboard.module')
          .then(m => m.OpportunityDashboardModule),
           data: {title: 'Opportunity Dashboard'},
      },
      {
        path: 'contact-dashboard/:contactId',
        loadChildren: () => import('./views/pages/contact-dashboard/contact-dashboard.module')
          .then(m => m.ContactDashboardModule),
           data: {title: 'Contact Dashboard'},
      },
      //--- DATA MANAGEMENT
      {
        path: 'customers',
        loadChildren: () => import('./views/pages/company-management/company-management.module')
          .then(m => m.CompanyManagementModule),
           data: {title: 'Customers'},
      },
      {
        path: 'contacts',
        loadChildren: () => import('./views/pages/contact-management/contact-management.module')
          .then(m => m.ContactManagementModule),
           data: {title: 'Contacts'},
      },
      {
        path: 'opportunities',
        loadChildren: () => import('./views/pages/opportunities/opportunities.module')
          .then(m => m.OpportunitiesModule),
           data: {title: 'Opportunities'},
      },
      
      {
        path: 'products',
        loadChildren: () => import('./views/pages/product-management/product-management.module').then(m => m.ProductManagementModule),
         data: {title: 'Products'},
      },
      {
        path: 'documents',
        loadChildren: () => import('./views/pages/documents-management/documents-management.module').then(m => m.DocumentsManagementModule),
         data: {title: 'Documents'},
      },
      {
        path: 'product-form',
        loadChildren: () => import('./views/pages/product-form/product-form.module').then(m => m.ProductModule),
         data: {title: 'Product Form'},
      },
      {
        path: 'product-form-details/:moduleType',
        loadChildren: () => import('./views/pages/product-form/product-form-details/product-form-details.module')
          .then(m => m.ProductFormDetailsModule),
           data: {title: 'Product Form Details'},
      },
      {
        path: 'users',
        loadChildren: () => import('./views/pages/users-management/users-management.module')
          .then(m => m.UsersManagementModule),
           data: {title: 'Users'},
      },
      {
        path: 'users/set-assist',
        loadChildren: () => import('./views/pages/users-management/users-management.module')
          .then(m => m.UsersManagementModule),
           data: {title: 'Users'},
      },

      //--- SETTINGS -- CONFIGURATION SETTINGS
      {
        path: 'settings-assistant',
        loadChildren: () => import('./views/pages/configuration-assistant/configuration-assistant.module')
          .then(m => m.ConfigurationAssistantModule),
           data: {title: 'Settings Assistant'},
      },
      {
        path: 'user-role',
        loadChildren: () => import('./views/pages/user-role/user-role.module')
          .then(m => m.UserRoleModule),
          data: {title: 'User Role'},
      },
      {
        path: 'user-role/set-assist',
        loadChildren: () => import('./views/pages/user-role/user-role.module')
          .then(m => m.UserRoleModule),
          data: {title: 'User Role'},
      },
      {
        path: 'customer-settings',
        loadChildren: () => import('./views/pages/company-configuration/company-configuration.module')
          .then(m => m.CompanyConfigurationModule),
           data: {title: 'Customer Settings'},
      },
      {
        path: 'customer-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/company-configuration/company-configuration.module')
          .then(m => m.CompanyConfigurationModule),
           data: {title: 'Customer Settings'},
      },
      {
        path: 'contact-settings',
        loadChildren: () => import('./views/pages/contact-setting/contact-setting.module')
          .then(m => m.ContactSettingModule),
           data: {title: 'Contact Settings'},
      },
      {
        path: 'contact-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/contact-setting/contact-setting.module')
          .then(m => m.ContactSettingModule),
           data: {title: 'Contact Settings'},
      },
      {
        path: 'opportunity-settings',
        loadChildren: () => import('./views/pages/opportunity-configuration/opportunity-configuration.module')
          .then(m => m.OpportunityConfigurationModule),
          data: {title: 'Opportunity Settings'},
      },
      {
        path: 'opportunity-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/opportunity-configuration/opportunity-configuration.module')
          .then(m => m.OpportunityConfigurationModule),
           data: {title: 'Opportunity Settings'},
      },
      {
        path: 'product-settings',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
           data: {title: 'Product Settings'},
      },
      {
        path: 'product-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
           data: {title: 'Product Settings'},
      },
      {
        path: 'user-roles',
        loadChildren: () => import('./views/pages/user-role/user-role.module')
          .then(m => m.UserRoleModule),
          data: {title: 'User Roles'},
      },
      {
        path: 'product-settings/set-assist/:tab',
        loadChildren: () => import('./views/pages/product-settings/product-settings.module')
          .then(m => m.ProductSettingsModule),
           data: {title: 'Product Settings'},
      },
      {
        path: 'user-settings',
        loadChildren: () => import('./views/pages/user-settings/user-settings.module')
          .then(m => m.UserSettingsModule),
           data: {title: 'User Settings'},
      },
      {
        path: 'activities',
        loadChildren: () => import('./views/pages/manage-activities/manage-activities.module')
          .then(m => m.ManageActivitiesModule),
         data: {title: 'Activities'},
      },
      {
        path: 'activity-list/:moduleType',
        loadChildren: () => import('./views/pages/config-tab-settings/config-tab-settings.module')
          .then(m => m.ConfigTabSettingsModule),
          data: {title: 'Activity List'},
      },
      {
        path: 'general-settings',
        loadChildren: () => import('./views/pages/general-settings/general-settings.module')
          .then(m => m.GeneralSettingsModule),
          data: {title: 'General Settings'},
      },
      {
        path: 'general-settings/:tab',
        loadChildren: () => import('./views/pages/general-settings/general-settings.module')
          .then(m => m.GeneralSettingsModule),
           data: {title: 'General Settings'},
      },
      {
        path: 'contact',
        loadChildren: () => import('./views/pages/contact/contact.module').then(m => m.ContactModule),
         data: {title: 'Contact'},
      },
      {
        path: 'user-activity/:id',
        loadChildren: () => import('./views/pages/user-activity/user-activity.module')
          .then(m => m.UserActivityModule),
           data: {title: 'User Activity'},
      },
      {
        path: 'activity-report',
        loadChildren: () => import('./views/pages/activity-report/activity-report.module').then(m => m.ActivityReportModule),
         data: {title: 'Activity Report'},
      },
      { 
        path: 'detailed-activity-report',
        loadChildren: () => import('./views/pages/detailed-activity-report/detailed-activity-report.module')
          .then(m => m.DetailedActivityReportModule),
           data: {title: 'Detailed Activity Report'},
      },
      {
        path: 'detailed-points-report',
        loadChildren: () => import('./views/pages/detailed-points-report/detailed-points-report.module')
          .then(m => m.DetailedPointsReportModule),
           data: {title: 'Detailed Points Report'},
      },
      {
        path: 'report-builder',
        loadChildren: () => import('./views/pages/report-builder/report-builder.module')
          .then(m => m.ReportBuilderModule),
           data: {title: 'Report Builder'},
      },
      {
        path: 'report-builder-edit',
        loadChildren: () => import('./views/pages/report-builder-edit/report-builder-edit.module')
          .then(m => m.ReportBuilderEditModule),
          data: {title: 'Reports Builder Edit'},
      },
      {
        path: 'reports-catalog',
        loadChildren: () => import('./views/pages/reports-catalog/report-catalog.module').then(m => m.ReportCatalogModule),
         data: {title: 'Reports Catalog'},
      },
      {
        path: 'exports',
        loadChildren: () => import('./views/pages/exports/exports.module').then(m => m.ExportsModule),
         data: {title: 'Exports'},
      },
      {
        path: 'report',
        loadChildren: () => import('./views/pages/reports-catalog/report-catalog.module').then(m => m.ReportCatalogModule),
         data: {title: 'Report'},
      },
      {
        path: 'day-planner',
        loadChildren: () => import('./views/pages/day-planner/day-planner.module')
          .then(m => m.DayPlannerModule),
           data: {title: 'Day Planner'},
      },
      // {
      //   path: 'reports-builder',
      //   loadChildren: () => import('./views/pages/report-builder/report-builder.module').then(m => m.ReportBuilderModule),
      // }, 
      {
        path: 'crm-dashboard',
        loadChildren: () => import('./views/pages/crm-dashboard/crm-dashboard.module').then(m => m.CrmDashboardModule),
         data: {title: 'CRM Dashboard'},
      }, 
      {
        path: 'data-lists',
        loadChildren: () => import('./views/pages/data-lists/data-lists.module').then(m => m.DataListsModule),
         data: {title: 'Data Lists'},
      }, 
      {
        path: 'integrations',
        loadChildren: () => import('./views/pages/integrations/integrations.module').then(m => m.IntegrationsModule),
         data: {title: 'Integrations'},
      },
      {
        path: 'workflows',
        loadChildren: () => import('./views/pages/workflows/workflows.module').then(m => m.WorkflowsModule),
         data: {title: 'Workflows'},
      },

      {
        path: 'assists',
        loadChildren: () => import('./views/pages/assist/assist.module').then(m => m.AssistModule),
         data: {title: 'Assists'},
      },
      {
        path: 'approvals',
        loadChildren: () => import('./views/pages/approvals/approvals.module').then(m => m.ApprovalModule),
         data: {title: 'Approvals'},
      },
      {
        path: 'crm_activities',
        loadChildren: () => import('./views/pages/activities/activities.module').then(m => m.ActivitiesModule),
         data: {title: 'CRM Activities'},
      },
      {
        path: 'report-power-bi/:id',
        loadChildren: () => import('./views/pages/report-power-bi/report-power-bi.module').then(m => m.ReportPowerBiModule),
         data: {title: 'Report Power BI'},
      },
      {
        path: 'call_logs',
        loadChildren: () => import('./views/pages/call-logs/call-logs.module').then(m => m.CallLogsModule),
        data: {title: 'Call Logs'},
      },
      {
        path: 'targets',
        loadChildren: () => import('./views/pages/target-management/target-management.module').then(m => m.TargetManagementModule),
         data: {title: 'Targets'},
      },
      

      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },


  { path: '**', redirectTo: 'error/403', pathMatch: 'full' },

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
