import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListType } from '../../../contact-setting/models/list-type.model';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';

@Component({
  selector: 'kt-edit-col-entity-field',
  templateUrl: './edit-col-entity-field.component.html',
  styleUrls: ['./edit-col-entity-field.component.scss']
})
export class EditColEntityFieldComponent implements OnInit {

  public editColEntityFieldForm: FormGroup;
  editColVal:any;
  fieldName:string;
  label:string;
  maxLabelLength: number = 50;
  measureTypeList:ListType[];
  public measureValueType: FormControl = new FormControl();
  valuelist:any;
  reportType:string|number;
  public displayDateGroupBy:boolean=false;
  public dateGroupByList=new Array<ListType>();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    console.log('editt field----------------')
    console.log(this.editColVal);
    console.log('value loist**************************');
    console.log(this.valuelist);
    this.initeditColEntityFieldForm();
    this.getMeasureList();
    this.fieldName=this.editColVal.entityFieldName;
    if(this.editColVal.label =='' || this.editColVal.label == undefined){
      this.label=this.editColVal.entityFieldName;
    }else{
      this.label=this.editColVal.label;
    }
    console.log('this.fieldName------'+this.fieldName);
    console.log('this.label---'+this.label);
   // if(this.reportType!=2 && this.editYVal.measure !=null && this.editYVal.measure !=''){
   //   this.editColEntityFieldForm.controls['measure'].setValue(this.editYVal.measure);
   // }
   // this.editEntityFieldForm.controls['label'].setValue(this.editVal.entityFieldName);
   if(this.editColVal.entityFieldType.id==3 && (this.reportType==6)){
    
    this.displayDateGroupBy=true;
    this.dateGroupByList.push({id:1,value:'Date'});
    this.dateGroupByList.push({id:2,value:'Week'});
    this.dateGroupByList.push({id:3,value:'Month'});
    this.dateGroupByList.push({id:4,value:'Year'});
    this.editColEntityFieldForm.controls['dateGroupBy'].setValue(this.editColVal.dateGroupBy?this.editColVal.dateGroupBy:1);
    this.editColEntityFieldForm.controls['dateGroupBy'].setValidators([Validators.required]);
   }
  }

  initeditColEntityFieldForm(){
    this.editColEntityFieldForm = this.fb.group({
      fieldName:[{value: '', disabled: true}],
      label: ['', Validators.compose([Validators.required,Validators.maxLength(this.maxLabelLength) ])],
      dateGroupBy:['']
     });
  }

  changeMeasureType(val){
  console.log('measureVal---'+val);
  }

  getMeasureList(){
    this.measureTypeList= new Array<ListType>();

    if(this.editColVal.entityFieldType.id == 7){
      this.measureTypeList.push({ id: 1, value:"sum"});
      this.measureTypeList.push({ id: 2, value:"average"});
      this.measureTypeList.push({ id: 3, value:"count"});
      this.measureTypeList.push({ id: 4, value:"min"});
      this.measureTypeList.push({ id: 5, value:"max"});
     }
     else if(this.editColVal.entityFieldType.id == 3){
      this.measureTypeList.push({ id: 3, value:"count"});
      this.measureTypeList.push({ id: 4, value:"min"});
      this.measureTypeList.push({ id: 5, value:"max"});
     }
     else{
      this.measureTypeList.push({ id: 3, value:"count"});
     }
  }

  Close(){
    this.activeModal.close();
    this.editColEntityFieldForm.reset();
  }

  Save(){

    const controls = this.editColEntityFieldForm.controls;
    const CopyOfEditColVal = {...this.editColVal};
  //  CopyOfEditColVal.measure=controls.measure.value;
    CopyOfEditColVal.label=controls.label.value;
    CopyOfEditColVal.aggFunc=null;
    //console.log(this.editYVal.measure);
    if(CopyOfEditColVal.entityFieldType.id==3 && (this.reportType==6)){
      CopyOfEditColVal.dateGroupBy=controls.dateGroupBy.value;
    }
    this.activeModal.close(CopyOfEditColVal);
    this.editColEntityFieldForm.reset();
    this.eventEmitterService.onModalCloseResult(CopyOfEditColVal);
   // this.activeModal.close();
   // this.editEntityFieldForm.reset();

  }

  Delete(){
    const index = this.valuelist.indexOf(this.editColVal);
    // console.log('index---'+index);
    if(index !== -1) {
     this.valuelist.splice(index, 1);
    }
    this.activeModal.close(null);
    this.editColEntityFieldForm.reset();
   // this.eventEmitterService.onModalCloseResult(index);
   this.eventEmitterService.onModalCloseResult(this.valuelist);
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editColEntityFieldForm.controls[controlName];
      if (!control) {
        return false;
      }
      
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }


}
