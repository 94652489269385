import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from 'src/app/core/_base/layout';
import { AuthService } from '../../../../core/auth';
import * as pbi from 'powerbi-client';


@Component({
  selector: 'kt-app-reports',
  templateUrl: './app-reports.component.html',
  styleUrls: ['./app-reports.component.scss']
})
export class AppReportsComponent implements OnInit {

  @ViewChild('powerBiContainer', { static: true }) powerBiContainer: ElementRef;
  dashboard: any;  
  paramId:String; 
  token:String;

   // Provide a custom implementation of Power BI service (Optional)
   @Input() service?: pbi.service.Service;
   // Power BI service
   powerbi!: pbi.service.Service;

  constructor(
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute, 
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe((params:ParamMap)=> {
      if(this.powerBiContainer){
        let containerNodes=(this.powerBiContainer?.nativeElement as HTMLElement).childNodes
        if(containerNodes.length>0){
          containerNodes.forEach(a=>{
          (this.powerBiContainer?.nativeElement as HTMLElement).removeChild(containerNodes[0])
          })
        }
      }
      this.paramId=params.get("id");
      this.token=params.get("token");
     // this.getPageTitle();
      // this._sh.setTitle(this.currentPagetitle);

      // Initialize powerbi variable for child component
      if (this.service) {
        this.powerbi = this.service;
      } else {
        this.powerbi = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
      }
 
      this.SpinnerService.show('power-bi-report');
      this.GetDashboardURL();

    })

    setTimeout(() => {
      this.hideFrameDiv();
    }, 5000);

  }
/**
 * Hide FC IFrame div
 */
  hideFrameDiv(){
    const frameDiv = document.getElementById('fc_frame');
    if (frameDiv) {
      frameDiv.style.display = 'none'; // Hide the div
    }
  }

  public GetDashboardURL() {
    this.auth.requestAPIforPowerBIReport(this.paramId, this.token)
    .subscribe((data) => {
      this.embedReport(data);
    });
  }

  embedReport(responseData:any) {
    const models = pbi.models;
    const embedConfig: pbi.IEmbedConfiguration = {
      type: 'report',
      id: responseData.embedReports[0].reportId,
      embedUrl: responseData.embedReports[0].embedUrl,
      accessToken: responseData.embedToken,
      tokenType: pbi.models.TokenType.Embed,
      permissions: pbi.models.Permissions.All,
      settings: {
        filterPaneEnabled: true,
        navContentPaneEnabled: true,
        layoutType: models.LayoutType.MobilePortrait
      }
    };
    
    this.powerbi.reset(this.powerBiContainer.nativeElement);
    const report = this.powerbi.embed(this.powerBiContainer.nativeElement, embedConfig);
   
    // Handle errors   
     report.on('error', (error) => {
      console.error(error);
    });
  }

}
