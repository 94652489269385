<div>
        <div class="modal-header">
            <h4 class="modal-title">Alert</h4>
            <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <p>Shared report can not be edited. You can create a copy of this report for editing. </p>
        </div>
        <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary" (click)="copyReport()">Create Copy</button>
        </div>
</div>