import { Component, OnInit, ViewEncapsulation, Input, ɵConsole } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {NgbModal,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';

import { FormControl } from '@angular/forms';
import { StdLookupAttribute } from 'src/app/views/pages/contact-setting/models/std-lookup-attribute.model';
import { AttributeType } from 'src/app/views/pages/contact-setting/models/attribute-type.model';
import { Contact } from 'src/app/views/pages/contact-setting/models/contact.model';
import { DateAttributes } from 'src/app/views/pages/contact-setting/models/date-attributes.model';
import { LooupValues } from 'src/app/views/pages/contact-setting/models/looup-values.model';
import { CompanyService } from '../../services/company.service';
import { ContactListService } from 'src/app/views/pages/contact-setting/services/contact-list.service';
import { CustomService } from 'src/app/views/pages/contact-setting/services/custom.service';
import { Company } from '../../models/company.model';
import {MatChipInputEvent} from '@angular/material/chips';
import {ENTER, COMMA} from '@angular/cdk/keycodes';

@Component({
  selector: 'kt-add-custom-field',
  templateUrl: './add-custom-field.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AddCustomFieldComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  lookupVal: LooupValues[] = [];

  private unsubscribe: Subject<any>;
  private returnUrl: any;
  loading = false;
  attributeContactType: string;
  mandatoryContact:boolean;
  mandatoryContactFlag:number;
 
  stdLookupList: StdLookupAttribute[];
  attributeType:AttributeType;
  resultSet:any;
  displayDateValueDiv: boolean;
  displayMultiSelectDiv: boolean;
  displaySingleSelectDiv:boolean;
  isDisplayText:boolean;
  isDisplayImage:boolean;
  isDisplayNumeric:boolean;
  allCompanyTypeList: Company[];
  dateAttributes: DateAttributes
  lookUpValues: LooupValues[];
  lookup:LooupValues;
  standardTypeValue: StdLookupAttribute;
  companyTypes: Company[];
  companyTyp: Company;
  companyTypesId: string;
  customAttributeId: number;
  customAttributeName: string;
  disabled:boolean;
  duplicate:boolean;
  attributeTypelist:AttributeType[];
  standardTypelist:StdLookupAttribute[];
 
  public attributeFilterType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();

  public addCustomFieldForm: FormGroup;

   /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public companyService: CompanyService,
    public contactService: ContactListService,
    public customService: CustomService) {
      this.unsubscribe = new Subject();
     }

  ngOnInit(): void {
    this.initaddCustomAttributeForm();
    this.getAttributeType();
    this.displayDateValueDiv=false;
    this.displayMultiSelectDiv=false;
    this.displaySingleSelectDiv=false;
    this.isDisplayText=false;
    this.isDisplayImage=false;
    this.isDisplayNumeric=false;
    this.dateAttributes=new DateAttributes();
    this.dateAttributes.stdLookupAttribute= new StdLookupAttribute();
    this.lookUpValues= new Array<LooupValues>();
    this.disabled=false;
    this.duplicate=false;

  }

  getAttributeType(){
    this.customService.getAtrributeType(2).subscribe(
		(result)=>{
      this.attributeTypelist=result;
		},
    error => {
      this.errorMes=error.error.responseData;
    }
    )
  }

  getStandardLookUpList(){
    var attributeId=this.attributeType.id;
    var attributeName=this.attributeType.typeName;
    this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
		(result)=>{
        this.standardTypelist=result;
		},
    error => {
      this.errorMes=error.error.responseData;
    })
  }


  initaddCustomAttributeForm(){
    this.addCustomFieldForm = this.fb.group({
      attributeName: ['', Validators.compose([Validators.required,Validators.maxLength(50) ])],
      attributeType: ['', Validators.compose([ Validators.required])],
      dateValueType: [''],
      attributeMultiSelectName:[''],
      attributeSingleName:[],
      fieldInstruction:[],
      standardTypeValue:[],
      lookup:[],
      futureDateValue:[],
      previousDateValue:[],
      mandatoryCheck:[],
      unique:[],
      minCharater:[],
      maxCharater:[],
      charactersType:[],
      maxImageAllowed:[],
      allowBrowsing:[],
      numericValidationType:[],
      numericMinValue:[],
      numericMaxValue:[],
      digitMinValue:[],
      digitMaxValue:[]
     });
  }

  Save(){
    this.disabled=true;
    this.errorMes="";
    const controls = this.addCustomFieldForm.controls;
      if (this.addCustomFieldForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
        return;
      }
      const addAttributeData = {
        attributeName: controls.attributeName.value,
        dateValueType: controls.dateValueType.value,
      //  attributeCompanyType: controls.attributeCompanyType.value,
        singleValues: controls.attributeSingleName.value,
        ValuesMutli: controls.attributeMultiSelectName.value,
        futureDateValue: controls.futureDateValue.value,
        previousDateValue: controls.previousDateValue.value
      };

      if(this.mandatoryContact == true){
        this.mandatoryContactFlag=1;
      }else{
        this.mandatoryContactFlag=0;
      }
 
      if(this.attributeType.id ==1){

      }else if(this.attributeType.id ==2){

      }else{
        this.lookupVal=[];
      }
      
      if(this.attributeType.id ==3){
        if(addAttributeData.dateValueType == 'Standard'){
            this.dateAttributes.stdLookupAttribute.id=this.standardTypeValue.id;
            this.dateAttributes.stdLookupAttribute.value=this.standardTypeValue.value;
            this.dateAttributes.previousDateAllowed=null;
            this.dateAttributes.futureDateAllowed=null;
        }else if(addAttributeData.dateValueType =='Custom'){
            this.dateAttributes.previousDateAllowed=Number(addAttributeData.previousDateValue);
            this.dateAttributes.futureDateAllowed=Number(addAttributeData.futureDateValue);
            this.dateAttributes.stdLookupAttribute=null;
        }else{
            this.dateAttributes=null;
        }
      }

      const addCustomFieldData={
          'attributeName':addAttributeData.attributeName,
          "projectId": this.selectedProject,
          "moduleType":"2",
          'mandatoryFlag': this.mandatoryContactFlag,
          "status": 1,
          'attributeType': this.attributeType,
          'lookUpValues': this.lookUpValues,
          'dateAttributes': this.dateAttributes
      }

      this.companyService
      .addCustomFields(addAttributeData.attributeName, this.attributeType,this.mandatoryContactFlag,
        this.lookupVal,this.dateAttributes, this.selectedProject)
        .pipe(
          tap(CustomAttibute => {
            console.log(CustomAttibute['responseData']);
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
            
          })
        )
        .subscribe(
          data =>{ console.log("sucesssfullycalled Custom Attribute API");
          this.loading = false;
            this.activeModal.close();
            this.addCustomFieldForm.reset();
        },
          error => {
            this.errorMes=error.error.responseData;
            this.disabled=false;
          }
          
        );  
  }

  changeStd(data){
   this.standardTypeValue=data;
  }

  change(data){
    this.attributeType=data;
    if(this.attributeType.id ==3){
      this.displayDateValueDiv=true;
      this.getStandardLookUpList();
      this.addCustomFieldForm.controls.dateValueType.setValidators([Validators.required]);
      this.addCustomFieldForm.controls.dateValueType.updateValueAndValidity();
    }else{
      this.displayDateValueDiv=false;
      this.addCustomFieldForm.controls.dateValueType.clearValidators();
      this.addCustomFieldForm.controls.dateValueType.updateValueAndValidity();
      this.resetDateValue();
    }

    if(this.attributeType.id ==2){
      this.displayMultiSelectDiv=true;
      this.addCustomFieldForm.controls.attributeMultiSelectName.setValidators([Validators.required]);
      this.addCustomFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }else{
      this.displayMultiSelectDiv=false;
      this.addCustomFieldForm.controls.attributeMultiSelectName.clearValidators();
      this.addCustomFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    }

    if(this.attributeType.id ==1){
      this.displaySingleSelectDiv=true;
      this.addCustomFieldForm.controls.attributeSingleName.setValidators([Validators.required]);
      this.addCustomFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }else{
      this.displaySingleSelectDiv=false;
      this.addCustomFieldForm.controls.attributeSingleName.clearValidators();
      this.addCustomFieldForm.controls.attributeSingleName.updateValueAndValidity();
    }

    if(this.attributeType.id ==6){
      this.isDisplayText=true;
    }else{
      this.isDisplayText=false;
    }

    if(this.attributeType.id ==4){
      this.isDisplayImage=true;
    }else{
      this.isDisplayImage=false;
    }

    if(this.attributeType.id ==7){
      this.isDisplayNumeric=true;
    }else{
      this.isDisplayNumeric=false;
      this.addCustomFieldForm.controls['numericValidationType'].reset();
    }

  }

  isDisplayStandard(): boolean{
    if(this.addCustomFieldForm.controls.dateValueType.value =='Standard'){
      this.addCustomFieldForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.addCustomFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
      this.addCustomFieldForm.controls.standardTypeValue.clearValidators();
      this.addCustomFieldForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayCustom(): boolean{
    if(this.addCustomFieldForm.controls.dateValueType.value =='Custom'){
      return true;
    }else{
      return false;
    }
  }

  changeValidation(id){
    console.log(id);
  }

  isDisplayNumericValue(): boolean{
    if(this.addCustomFieldForm.controls.numericValidationType.value =='1'){
      return true;
    }else{
      return false;
    }
  }

  isDisplayNumericDigit(): boolean{
    if(this.addCustomFieldForm.controls.numericValidationType.value =='2'){
      return true;
    }else{
      return false;
    }
  }

  showOptions(val) {
    this.mandatoryContact=val;
  }

  resetDateValue(){
    this.addCustomFieldForm.controls['dateValueType'].reset();
  }

  Close(){
    this.activeModal.close();
    this.addCustomFieldForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCustomFieldForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    this.duplicate=false;

    
    if ((value || '').trim()) {
      for(let fr of this.lookupVal){
        var name= fr.lookupValue;
        if(value.trim()==name){
          this.duplicate=true;
        }
      }
      if(this.duplicate==false){
      this.lookupVal.push({lookupValue: value.trim(),
      isActive: 1, stageId:null});
      }
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  remove(lookup: LooupValues): void {
    const index = this.lookupVal.indexOf(lookup);

    if (index >= 0) {
      this.lookupVal.splice(index, 1);
    }
  }
  isDisabled() : boolean {
    return this.disabled;
   }

   resetAll(){
    this.addCustomFieldForm.controls['mandatoryCheck'].reset();
    this.addCustomFieldForm.reset();
    this.lookupVal=[];
    this.addCustomFieldForm.controls['attributeMultiSelectName'].reset();
    this.addCustomFieldForm.controls['attributeSingleName'].reset();
    this.disabled=false;
    this.displayDateValueDiv=false;
    this.addCustomFieldForm.controls.dateValueType.clearValidators();
    this.addCustomFieldForm.controls.dateValueType.updateValueAndValidity();
    this.resetDateValue();
    this.displayMultiSelectDiv=false;
    this.addCustomFieldForm.controls.attributeMultiSelectName.clearValidators();
    this.addCustomFieldForm.controls.attributeMultiSelectName.updateValueAndValidity();
    this.displaySingleSelectDiv=false;
    this.addCustomFieldForm.controls.attributeSingleName.clearValidators();
    this.addCustomFieldForm.controls.attributeSingleName.updateValueAndValidity();
    this.isDisplayStandard();
    this.isDisplayCustom();
   }
}
