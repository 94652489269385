import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-delete-dashboard-alert',
  templateUrl: './delete-dashboard-alert.component.html',
  styleUrls: ['./delete-dashboard-alert.component.scss']
})
export class DeleteDashboardAlertComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  Close() {
    this.activeModal.close();
  }
  confirmDelete(){
    this.activeModal.close("delete");
  }
}
