import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';
import { companiesModel } from '../../company-management/models/companies.model';
import { customAttributesModel } from '../../company-management/models/customAttributes.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { } 

  getProductLookup(projectId: number){
    return this.http.post(environment.baseUrl +'/transactions/api/product/getProductLookup?projectId='+projectId,null);
  }

  getFieldsInSetting(moduleType, projectId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getFieldsInSetting?projectId=' + projectId + '&moduleType=' + moduleType);
  }
  
  getLinkedDataListDetails(moduleType, projectId: number){
    return this.http.get(environment.baseUrl + '/transactions/api/customType/getLinkedDataList?projectId='+projectId+'&moduleType='+moduleType)
  }
  
  //Loopkup
  getAttribLookup(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getAttribLookupValues?attributeId=' + attributeId);
  }
  
  //CustomDateAttribute
  getCustomDateAttribute(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getCustomDateAttributes?attributeId=' + attributeId);
  }
  
  //CustomDateAttribute
  getDataListLookupValues(attributeId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/customType/getDataListLookupValues?attributeId=' + attributeId);
  }
  
  add(addData){
    addData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
        return this.http.post(environment.baseUrl + '/transactions/api/company/addEditCompany',addData);
  }
  
  getFieldRelation(attributeId,attributeLookupId){
    return this.http.get<companiesModel>(environment.baseUrl + '/setting/api/customType/getChildAttributes?attributeId='+attributeId+'&attributeLookupId='+attributeLookupId)
  }
  
  getParticularCompany(id,moduleType, projectId: number, callMongoAPI = false){
      if(callMongoAPI){
      return this.http.get<companiesModel[]>(
        environment.baseUrl + '/mongotransactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
      }
      else{
        return this.http.get<companiesModel[]>(
          environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+id);
      }
  }

  getCompanyDetails(companyId, projectId){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompany?projectId='+projectId+'&companyId='+companyId);
  }

  getOwner(projectId: number,moduleId:number){
    return this.http.get<customAttributesModel[]>(
     environment.baseUrl + '/login/api/users/fetchModuleOwnerOptions?projectId='+projectId+'&moduleId='+moduleId);
  }

  getCompanyIdName(projectId:number,moduleType:Number,customerView:number=3){
    return this.http.get<companiesModel[]>(
      environment.baseUrl + '/transactions/api/company/getCompanyIdName?projectId='+projectId+'&moduleType='+moduleType+'&customerView='+customerView);
  }

}