import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialerSearchService } from './services/dialer-search.service';
import { AppConfigurationFieldsEnum } from '../shared/constant/AppConfigurationFieldEnum.constants';
import { AppIntegrationService } from '../shared/services/app-integration.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialerComponent } from '../dialer/dialer.component';
import * as moment from 'moment';
import { SystemAttributeEnum } from '../shared/constant/SystemAttributeEnum.constants';

@Component({
  selector: 'kt-dialer-search',
  templateUrl: './dialer-search.component.html',
  styleUrls: ['./dialer-search.component.scss']
})
export class DialerSearchComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  user = JSON.parse(localStorage.getItem('currentUser'));
  callerList =[];
  emptycallerList:boolean = false;
  modalReference: any;

  constructor(public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private _ds:DialerSearchService ,
    private _ai:AppIntegrationService,
    public snackBar: MatSnackBar,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  searchCallerId(value){
    if(value === null || value.trim() === '') return;

    this.SpinnerService.show('searchCallers');
    this.emptycallerList = false;
    this.callerList=[];
    this._ds.searchCallerId(this.selectedProject, value).subscribe(res=>{
      this.callerList=res['responseData'];
      if(this.callerList== null || this.callerList.length == 0){
        this.emptycallerList = true;
      }
      this.SpinnerService.hide('searchCallers');
    },
    err=>{
      console.log(err);
      this.SpinnerService.hide('searchCallers');
    }) 
  }
  call(callerDetails){
    console.log(callerDetails);
    let paramsDetail = {}
    let url = ''
    let isUserConfigured:boolean=false;
    if (callerDetails.appId == AppConfigurationFieldsEnum.OZONETEL_APP_ID) {
      this.SpinnerService.show();
      this._ai.getConfigurationDetails(this.selectedProject, callerDetails.appId).subscribe(res => {
        if (res['responseData'].configFieldSaveValue.length == 0) {
          this.snackBar.open('App Configuration data not found', '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          return;
        }
        res['responseData'].configFieldSaveValue.forEach(element => {
          if (element.fieldId == AppConfigurationFieldsEnum.API_KEY) {
            paramsDetail['api_key'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.USER_NAME) {
            paramsDetail['username'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.AGENT_ID) {
            if(element.fieldValue.split(",").includes(this.user?.userId?.toString())){
              isUserConfigured=true;
              paramsDetail['agentID'] = this.user.userId;
            }
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.CAMPAIGN_NAME) {
            paramsDetail['campaignName'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.CLICK_TO_CALL_URL) {
            url = element.fieldValue;
          }
        });
        paramsDetail['customerNumber'] = callerDetails.callerIdNumber || "";
        paramsDetail['uui'] = "P"+this.selectedProject+"M"+callerDetails.moduleId+"E"+callerDetails.entityId;
        paramsDetail['format'] = "json";
        if(!isUserConfigured){
          this.snackBar.open(this.user?.firstName+" "+this.user?.lastName+" is not configured for IVR call", '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          return;
        }
        this._ai.agentManualDial(url, paramsDetail).subscribe(res => {
          this.SpinnerService.hide();
          if(res['status']=='queued successfully'){
            this.snackBar.open('Call intiated successfully', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
          }else{
            this.snackBar.open(res['status'], '', {
              duration: 3000,
              panelClass: ['red-snackbar']
            });
            return;
          }
          const ref=this.modalReference = this.modalService.open(DialerComponent,
            {
              centered: false,
              size : 'lg',
              backdrop : 'static'
            });
            if(callerDetails.moduleId==SystemAttributeEnum.COMPANY_MODULE){
              ref.componentInstance.companyId=callerDetails.entityId;
            }
            ref.componentInstance.callStartTime=moment().format('YYYY-MM-DD HH:mm:ss');
            ref.result.then((result) => {
              if(result == 'SUCCESS'){
                this.snackBar.open( 'Successfully added.', '', {
                  duration: 3000,
                  panelClass: ['green-snackbar']
                });
              }else if (result == 'ERROR'){
                this.snackBar.open('Unable to add. Please try again.', '', {
                  duration: 3000,
                  panelClass: ['red-snackbar']
                });
              }
            })
          
        }, error => {
          this.snackBar.open('Unable to configure call. Please try again.', '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
        })
      }, error => {
        this.snackBar.open('Unable to load configuration data. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      });

    }

  }
}
