import { AfterViewInit, Component, ElementRef, OnInit, ViewChild,ChangeDetectorRef ,ViewEncapsulation} from '@angular/core';
import { Contact } from '../models/contact.model';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil, tap } from 'rxjs/operators';
import { EditContactService } from '../services/edit-contact.service';


@Component({
  selector: 'kt-edit-contact',
  templateUrl: './edit-contact.component.html',
  encapsulation: ViewEncapsulation.None
})
export class EditContactComponent implements OnInit {
  errorMes:String="";
  selectedValue: string;
  contactTypeName: string;
  primary_Value: boolean;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  primaryContactVal=false;
  private unsubscribe: Subject<any>;
  loading = false;
  disabled:boolean;

  editContactVal:Contact;
  editContactValue:Contact;
  
  public editContactForm: FormGroup;

  constructor(private modalService: NgbModal,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private edit: EditContactService) {
       this.unsubscribe = new Subject();
   }

 ngOnInit() {
    this.initeditContactForm();
    this.editContactForm.reset();
    this.getContact(this.editContactVal.id);
    
    this.disabled=false;

 }

 getContact(id){
  this.edit.getContactById(id, this.selectedProject).subscribe(
    data=>{
      if(data.isActive==1)
     {
      data.active='Active';
      }
      if(data.isActive==0)
      {
        data.active='Inactive';
       }
       if(data.isPrimary==1)
      {
        data.primary='Yes';
       }
       if(data.isPrimary==0)
      {
        data.primary='No';
       }
     this.editContactValue = data;
    // console.log(this.editContactValue);
     this.selectedValue=this.editContactValue.isActive.toString();
    this.contactTypeName=this.editContactValue.contactTypeName;
  
    var primary=this.editContactValue.primary;
    if(primary =='Yes' || primary=='1'){
       this.primary_Value=true;
    }else{
      this.primary_Value=false;
    }
    }
  )
 }
 ngOnDestroy(): void {
   this.unsubscribe.next();
   this.unsubscribe.complete();
 }

 initeditContactForm(){
  this.editContactForm = this.fb.group({
    contactTypeName: ['', Validators.compose([
      Validators.required,
      Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
    ])
    ]
   });
  }

  showOptionsEdit(val) {
    this.primaryContactVal=val;
    this.editContactValue.primary=val;
  }
  
  Close(){
    this.activeModal.close();
    this.editContactForm.reset();
  }
  Edit(){
    this.disabled=true;
    const controls = this.editContactForm.controls;
  // console.log('touched--'+controls.contactTypeName.touched)
   // console.log('dirty--'+controls.contactTypeName.dirty);
    if(controls.contactTypeName.touched == false){
    this.editContactForm.patchValue({
      contactTypeName:this.contactTypeName
     })
    }

    if(controls.contactTypeName.touched == true && controls.contactTypeName.dirty== false){
      this.editContactForm.patchValue({
        contactTypeName:this.contactTypeName
      })
    }  
    
    if (this.editContactForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
  
    var primaryContact: string = this.editContactValue.primary.toString();
    var isPrimary=0;
    if(primaryContact =='true' || primaryContact=='Yes'){
      isPrimary = 1;
    }
    
    const editRedcordData = {
      contactTypeName: controls.contactTypeName.value,
      isPrimary: isPrimary,
      isActive:Number(this.selectedValue),
      id:this.editContactVal.id
    };
    
    this.edit
        .editContact(editRedcordData.contactTypeName,editRedcordData.isActive,
          editRedcordData.isPrimary,editRedcordData.id, this.selectedProject)
        .pipe(
          tap(contact => {
          //  console.log(contact);
          }),
          takeUntil(this.unsubscribe),
          finalize(() => {
            this.loading = false;
            //this.cdr.markForCheck();
            this.activeModal.close();
            this.editContactForm.reset();
          })
        )
        .subscribe(
          data => console.log("sucesssfulll called API"),
          error => {
            this.errorMes=error.error.responseData;
          }
          
        );
  }
  
  isControlFormHasError(controlName: string, validationType: string): boolean {
    const control = this.editContactForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isDisabled() : boolean {
    return this.disabled;
  }
  
}
