import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactInfoModel } from '../models/contact-info.model';
import { ContactActivitiesModel } from '../models/contact-activities.model';
import { ContactCompanyModel } from '../models/contact-company.model';
import { ContactOpportunitiesModel } from '../models/contact-opportunities.model';
import { ContactStatsModel } from '../models/contact-stats.model';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ContactDashboardService {

  constructor(private http:HttpClient) { }


  getContactInfo(projectId: number, contactId: number){
    return this.http.get<ContactInfoModel[]>(
      environment.baseUrl + '/reportsfetcher/api/contact/dashboard/getContacts?projectId='+projectId+'&contactId='+contactId
    );
  }

  getContactCompany(projectId: number, contactId: number){
    return this.http.get<ContactCompanyModel>(
      environment.baseUrl + '/reportsfetcher/api/contact/dashboard/getCompanyInfo?projectId='+projectId+'&contactId='+contactId
    );
  }

  getContactOpportunities(projectId: number, contactId: number){
    return this.http.get<ContactOpportunitiesModel[]>(
      environment.baseUrl + '/reportsfetcher/api/contact/dashboard/getOpportunities?projectId='+projectId+'&contactId='+contactId
    );
  }

  getContactActivities(projectId: number, contactId: number){
    return this.http.get<ContactActivitiesModel>(
      environment.baseUrl + '/reportsfetcher/api/contact/dashboard/getActivities?projectId='+projectId+'&contactId='+contactId
    );
  }

  getContactStats(projectId: number, contactId: number){
    return this.http.get<ContactStatsModel[]>(
      environment.baseUrl + '/reportsfetcher/api/contact/dashboard/getStats?projectId='+projectId+'&contactId='+contactId
    );
  }
}
