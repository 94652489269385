import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient) { }

  getDashBoardList(projectId: number){
    return this.http.get(environment.baseUrl +'/reportsfetcher/api/dashboard/getDashboards?projectId='+projectId);
  }

  getReportCatalogData(reportId){
      return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/previewCatalogReport?reportId='+reportId)
  }

  getUsers(projectId:any){
    return this.http.get<any>(environment.baseUrl+'/login/api/users/activeProjectUsersWithoutLoggedInUser?projectId='+projectId)
  }

  getRoles(projectId:any){
    return this.http.get<any>(environment.baseUrl+'/setting/api/roles/?projectId='+projectId)
  }

  getReportData(reportId:any){
    return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/fetchReportData?reportId='+reportId)
  }

  getDashBoardReport(projectId: number, dashboardId:any=''){
    return this.http.get(environment.baseUrl +'/reportsfetcher/api/dashboard/fetchReports?projectId='+projectId+'&dashboardId='+dashboardId);
  }

  checkDashboardName(projectId: number, name:string, dashboardId=0){
    return this.http.get(environment.baseUrl +'/reports/api/dashboard/isNameAvailable?projectId='+projectId+'&name='+name+'&dashboardId='+dashboardId);
  }

  addDashboard(dashboardReport:any){
    return this.http.post<any[]>(
      environment.baseUrl+'/reports/api/dashboard/addDashboard',dashboardReport);
  }

  editDisplayOrder(displayOrder:any){
    return this.http.post<any[]>(
      environment.baseUrl+'/reports/api/dashboard/updateDisplayOrder',displayOrder);
  }

  cloneDashboard(cloneData:any){
    return this.http.post<any[]>(
      environment.baseUrl+'/reports/api/dashboard/cloneDashboard',cloneData);
  }

  deleteDashboard(projectId: number, dashboardId:number){
    return this.http.delete(environment.baseUrl +'/reports/api/dashboard/deleteDashboard?projectId='+projectId+'&dashboardId='+dashboardId);
  }

  updateDashboard(updateDashboard:any){
    return this.http.post<any[]>(
      environment.baseUrl+'/reports/api/dashboard/updateDashboard',updateDashboard);
  }

  getSharedDetails(projectId: number, dashboardId){
    return this.http.get(environment.baseUrl +'/reports/api/dashboard/fetchSharingDetails?projectId='+projectId+'&dashboardId='+dashboardId);
  }
}
