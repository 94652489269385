<div class="d-flex flex-column-fluid flex-center mt-5">
	<!--begin::Signup-->
	<div class="login-form login-signup" style="display: block;">
		<div class="text-center mb-5 mb-lg-10">
			<h3 class="font-size-h1">Sign Up</h3>
			<p class="text-muted font-weight-bold">Please enter info below to create your account</p>
		</div>

		<kt-auth-notice></kt-auth-notice>

		<form class="form" [formGroup]="registerForm" autocomplete="off">
			<div class="row">
				<div class="col-xl-6">
					<div class="form-group">
						<mat-form-field>
							<mat-label>First Name
								<span style="color: red; display: inline;">*</span>
							</mat-label>
							<input matInput type="text" placeholder="" formControlName="firstName" ktAutoTrimSpaces />
							<mat-error *ngIf="isControlHasError('firstName','required')">
								<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('firstName','minlength')">
								<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('firstName','maxlength')">
								<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			
				<div class="col-xl-6">
					<div class="form-group">
						<mat-form-field>
							<mat-label>Last Name
								<span style="color: red; display: inline;">*</span>
							</mat-label>
							<input matInput type="text" placeholder="" formControlName="lastName" ktAutoTrimSpaces/>
							<mat-error *ngIf="isControlHasError('lastName','required')">
								<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('lastName','minlength')">
								<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
							</mat-error>
							<mat-error *ngIf="isControlHasError('lastName','maxlength')">
								<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
							</mat-error>
						</mat-form-field>
					</div>
				</div>
			</div>
			<div class="form-group">
				<mat-form-field>
					<mat-label>Work Email Address
						<span style="color: red; display: inline;">*</span>
					</mat-label>
					<input matInput type="email" placeholder=""
						formControlName="email" autocomplete="off" ktAutoTrimSpaces/>
					<mat-error *ngIf="isControlHasError('email','required')">
						<strong>{{ 'AUTH.VALIDATION.REQUIRED_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','email')">
						<strong>{{ 'AUTH.VALIDATION.INVALID_FIELD' | translate }}</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','minlength')">
						<strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
					</mat-error>
					<mat-error *ngIf="isControlHasError('email','maxlength')">
						<strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
					</mat-error>
				</mat-form-field>
			</div>
<!--
			<div class="form-group">
				<mat-form-field>
					<mat-label>Region</mat-label>
					<mat-select formControlName="region">
                        <mat-option value="us">United State (US)</mat-option>
						<mat-option value="ap">Asia Pacific (AP)</mat-option>
						<mat-option value="eu">Europe (EU)</mat-option>
                    </mat-select>
                    <mat-hint>Select region</mat-hint>
                    <mat-error *ngIf="isControlHasError('region', 'required')">Region is required</mat-error>
				</mat-form-field>
			</div>
-->			

			<div class="form-group">
				<mat-checkbox name="agree" formControlName="agree">
					I accept the <a href="https://sys.1channel.ai/tnc"  target="_blank" >terms & conditions</a>

				</mat-checkbox>
				<mat-error class="form-error" *ngIf="isControlHasError('agree','pattern')"><strong>
					Please accept the terms & conditions.</strong>
				</mat-error>
				<mat-error class ="form-error" *ngIf="isControlHasError('agree','required')"><strong>
					Please accept the terms & conditions.</strong>
				</mat-error> 
			</div>

			<!--begin::Action-->
			<div class="form-group text-right">
				<a href="javascript:;"
					routerLink="/auth/login" 
					id="kt_login_signup_cancel"
					class="btn btn-secondary px-9 py-4 my-3 mx-4">Cancel</a>
				
				<button (click)="submit()" id="kt_login_signup_submit"
					class="btn btn-primary px-9 py-4 my-3 mx-4"
					[ngClass]="{'spinner spinner-right spinner-md spinner-light': loading}">Sign Up</button>
				<!--
					<a href="javascript:;"
					routerLink="/auth/authenticate-token/bf4b7250-c407-42bd-9c9d-33e7239f7404" 
					id="kt_login_signup_cancel"
					class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">GP</a>-->
			</div>
			<!--end::Action-->
		</form>
	</div>
	<!--end::Signup-->
</div>

