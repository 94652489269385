// Angular
import { Component, OnInit } from '@angular/core';
// Layout
import { HtmlClassService } from '../html-class.service';
// Object-Path
import * as objectPath from 'object-path';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialerSearchComponent } from '../../pages/dialer-search/dialer-search.component';
@Component({
  selector: 'kt-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  // Public properties
  today: number = Date.now();
  footerClasses = '';
  footerContainerClasses = '';
  isAppIntegerationEnable = JSON.parse(localStorage.getItem('isAppIntegerationEnable'));
  /**
   * Component constructor
   *
   * @param uiClasses: HtmlClassService
   */
  constructor(private uiClasses: HtmlClassService,
    private modalService: NgbModal) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.footerClasses = this.uiClasses.getClasses('footer', true).toString();
    this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString();
  }
  openDialsearch(){
    console.log("*********************************************************");
    const ref = this.modalService.open(DialerSearchComponent, 
      {
        centered: true,
        size : 'sm',
        backdrop : 'static',
        windowClass: 'dialer-serach-modal'

    });
  }
}
