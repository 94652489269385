import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteActivityAlertComponent } from '../delete-activity-alert/delete-activity-alert.component';
import { MenuPermissionModel } from 'src/app/core/_base/layout/models/menu-permission.model';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html'
})
export class EditCellRendererComponent implements OnInit {

  params:any;
  displayRelation:boolean;
  activityId: number;
  activityName:String;
  
  constructor(  private modalService: NgbModal) { }

  agInit(params){
    this.displayRelation=false;
    this.params = params;
    this.activityId = params.data.activityId;
    this.activityName=params.data.activityName;

    if(params.data.defaultActivity == 0 && params.data.anyTransactionPerformed==false){
      this.displayRelation=true;
    }


  }

  ngOnInit(): void {
  }

  public invokeDeleteActivity() {
    let delAlertModalRef = this.modalService.open(DeleteActivityAlertComponent,
      {  centered: true,
         size : 'md',
         backdrop : 'static'
       }
    );
    delAlertModalRef.componentInstance.activityName = this.activityName;
    delAlertModalRef.result.then( result => {
      if(result){
        this.params.context.componentParent.deleteActivity(this.params.data);
      }
    });
  }


}
