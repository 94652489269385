<div class="col-xl-12">
    <div class="col-xl-12">
        <br/>
        <h1 mat-dialog-title>{{data.title}}</h1>
        <hr>
        <div mat-dialog-content>
            <p>{{data.body}}</p>
        </div>
        <hr>
        <div *ngIf="!isSetting" mat-dialog-actions class="float-right">
            <button mat-button (click)="closeDialog()">Ok</button>
        </div>

        <div *ngIf="isSetting" mat-dialog-actions class="float-right">
            <button class="btn btn-sm mx-1 btn-success" (click)="saveAndExit(0)">Save and exit</button>
        </div>
        <div *ngIf="isSetting" mat-dialog-actions class="float-right">
            <button  class="btn btn-sm mx-1 btn-primary" (click)="saveAndExit(1)">Continue editing</button>
        </div>
        <div *ngIf="isSetting" mat-dialog-actions class="float-right">
            <button  class="btn btn-sm mx-1 btn-danger" (click)="saveAndExit(2)">Exit without saving</button>
        </div>
        <br/>
    </div>
</div>