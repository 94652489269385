import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReportBuilderComponent } from './report-builder-edit.component';
import { SharedModule } from '../shared/shared.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { EditCellRendererComponent } from '../shared/edit-cell-renderer/edit-cell-renderer.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditEntityFieldComponent } from './_subs/edit-entity-field/edit-entity-field.component';
import {AgChartsAngularModule} from 'ag-charts-angular';
import { ListFilterPipe } from './list-filter.pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { SaveReportComponent } from './_subs/save-report/save-report.component';
import { EditYEntityFieldComponent } from './_subs/edit-y-entity-field/edit-y-entity-field.component';
import { FilterFieldComponent } from './_subs/filter-field/filter-field.component';
import { EditColEntityFieldComponent } from './_subs/edit-col-entity-field/edit-col-entity-field.component';

const routes:Routes=[{
  path:'',component:ReportBuilderComponent
}]

@NgModule({
  declarations: [ReportBuilderComponent, EditEntityFieldComponent, ListFilterPipe, SaveReportComponent, EditYEntityFieldComponent, FilterFieldComponent, EditColEntityFieldComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AgGridModule.withComponents([]),
    NgxSpinnerModule,
    DragDropModule,
    AgChartsAngularModule,
    Ng2SearchPipeModule
  ],
  entryComponents:[EditEntityFieldComponent, SaveReportComponent, EditYEntityFieldComponent, FilterFieldComponent, EditColEntityFieldComponent],
  bootstrap: [ReportBuilderComponent]
})
export class ReportBuilderEditModule { }
