import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any, args: any , arg2:string, arg3:string): any {

    if(!value)return null;
    if(!args)return value;

    args = args.toLowerCase();

    if(args){
      return value.filter(res => (res[arg2].toLowerCase().indexOf(args) > -1) || (res[arg3]?.toLowerCase().indexOf(args) > -1));
    }
  }

}
