<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
    <div class="row">
        <div [ngClass]="(isEdit) ? 'col-lg-9 reports-section' : 'col-lg-12'">
            <div class="row">
                <div class="col-lg-12" style="height: 20px; margin-top: -13px;" *ngIf="!isEdit" >
                    <span style="position: absolute; right: 12px; cursor: pointer;">
                        <i class="fas fa-ellipsis-v text-primary icon-md" (click)="openDialog()" ></i>
                    </span>
                </div>
                    <div 
                    style="background: rgba(196, 196, 196, 0.2);
                            height: 488px; padding-bottom: 20px;" 
                        [ngClass]="(isLast && isEven) ? 'col-lg-12' : 'col-lg-6'"
        
                        [ngStyle]="{'padding-top': true ? '3px' : '', 
                        'padding-left': isEven ? '16px' : '4px', 
                        'padding-right': isEven && !isLast ? '3px' : '15px',
                        'border-right': isEven && !isLast ? '8px solid white' : '', 
                        'border-left': !isEven ? '8px solid white' : '',
                        'border-bottom': true ? '16px solid white' : ''
                    }" 
        
                        *ngFor="let rep of dashboardReports; index as i; last as isLast; even as isEven">
                        <kt-dashboard-report [parentData]="rep" [condition]="(isLast && isEven)" [isEdit]="isEdit" (removedReport)="removeReport($event)" (editReport)="editReport($event)"> </kt-dashboard-report>
                    </div>
            </div>
            
        </div>
        <div class="col-lg-3" *ngIf="isEdit" >
            <div class="example-preview edit-section">
                <div>
                    <form [formGroup]="editDashForm" autocomplete="off" >
                        <div>
                            <h4 class="modal-title">Edit Dashboard</h4>
                        </div>
                            <div class="form-group row">
                                <div class="col-md-12">
                                    <div class="row" style="margin:0px;">
                                    <mat-form-field appearance="standard" class="input-100">
                                        <mat-label>Name
                                            <span class="mandatory-marker">*</span>
                                        </mat-label>
                                        <input matInput #input1 maxlength="25" formControlName="dashBoardName" (change)="dashboardNameChange()" ktAutoTrimSpaces>
                                        <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/25</mat-hint>
                                        <mat-error *ngIf="isControlHasError('dashBoardName', 'required')">Name cannot be blank</mat-error>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                                    </mat-form-field>
                            </div>
                        </div>
                                <div class="col-md-12">
                                   <div class="row" style="margin:0px;">
                                            <ng-container *ngIf="isLoadingSharingDetail">
                                                <mat-spinner diameter="20"></mat-spinner>
                                            </ng-container>
                                            <ng-container *ngIf="!isLoadingSharingDetail">
                                                    <mat-form-field appearance="standard" class="input-100">
                                                        <mat-label>Share With</mat-label>
                                                        <mat-select formControlName="shareWith" 
                                                                    name="shareWith" 
                                                                    [(ngModel)]="shareWith" 
                                                                    (selectionChange)="changeShareWith($event.value)">
                                                            <mat-option *ngFor="let option of shareWithDrp" [value]="option.id">
                                                                {{option.value}}
                                                            </mat-option>
                                                        </mat-select>
                                                        <mat-error *ngIf="isControlHasError('shareWith', 'required')">Share with cannot be blank</mat-error>
                                                    </mat-form-field>
                                            </ng-container>
                                        </div>
                                    <div class="row" *ngIf="shareWith === 2" style="margin:0px;">
                                        <mat-form-field appearance="standard" class="input-100">
                                            <mat-label>Select Roles
                                                <span class="mandatory-marker">*</span>
                                            </mat-label>
                                            <mat-select formControlName="selectRoles"  [multiple]="true"
                                             name="selectedRoles" (selectionChange)="changeShareIds()">
                                                <mat-option *ngFor="let role of roles;" 
                                                [value]="role.roleId">{{role.roleName}}</mat-option>
                                            </mat-select>
                                            <!-- <div class="mat-form-field-subscript-wrapper pt-3"> -->
                                            <mat-error *ngIf="isControlHasError('selectRoles', 'required')">Role cannot be blank</mat-error>
                                            <!-- </div> -->
                                        </mat-form-field>
                                    </div>
                                    <div class="row" *ngIf="shareWith === 3" style="margin:0px;">
                                            <ng-container *ngIf="isLoadingUsers">
                                                <div class="col-md-4">
                                                    <mat-spinner diameter="20"></mat-spinner>
                                                </div>
                                            </ng-container>
                                            <ng-container *ngIf="!isLoadingUsers">
                                                <mat-form-field appearance="standard" class="input-100">
                                                        <mat-label>Select Users
                                                            <span class="mandatory-marker">*</span>
                                                        </mat-label>
                                                        <mat-select formControlName="selectUsers"  [multiple]="true" 
                                                         name="selectedUsers" (selectionChange)="changeShareIds()">
                                                            <mat-option *ngFor="let user of users;" 
                                                            [value]="user.id">{{user.fullName}}</mat-option>
                                                        </mat-select>
                                                            <mat-error *ngIf="isControlHasError('selectUsers', 'required')">User cannot be blank</mat-error>
                                                </mat-form-field>
                                            </ng-container>
                                    </div>
                                </div>
                            </div>
                        <div style="width: 100%; text-align: center;margin-top: 15px;">
                            <button type="button" style="width: 100%;" class="btn btn-primary" (click)="addReport()">Add New Reports</button>
                        </div>
                        
                    </form> 
                </div>
            </div>  
            
        </div>
        
        <div class="bottom-buttons" *ngIf="isEdit">
            <button type="button" class="btn btn-danger custom-btn" (click)="deleteDashboard()" [disabled]="isDisabled">Delete Dashboard</button>
            <button type="button" class="btn btn-secondary custom-btn" (click)="cancel()">Cancel</button>
            <button type="button" class="btn btn-primary custom-btn" (click)="save()" [disabled]="isDisabled">Save</button>
        </div>
     </div>

