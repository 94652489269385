import { Injectable } from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
@Injectable({
  providedIn: 'root'
})

export class UserLocalizationService{
   
  getLocalTimeZone():string{
    return moment.tz.guess();
  }
  getUserTime():string{
    return moment().format('YYYY-MM-DD HH:mm:ss');
  }
  getUserTimeZoneCode():string{
    return moment().tz(this.getLocalTimeZone()).format('z');
  }
}