// Angular
import {Component, Input, OnInit} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { OpportunityDashboardService } from '../../../../services/opportunity-dashboard.service';
import { AddNewCompanyComponent } from '../../../../../shared/custom-add/add-new-company/add-new-company.component';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';

@Component({
  selector: 'kt-widget3-contact',
  templateUrl: './widget3-contact.component.html',
  styleUrls: ['./widget3-contact.component.scss']
})
export class Widget3ContactComponent implements OnInit  {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Contact';
  contactList: any;

  public isCollapsed = false;
  isError = false;
  errorMessage: string;
  
  constructor(
    private modalService: NgbModal,
    private _ods: OpportunityDashboardService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-contact');
    this.fetchOpportunityContacts(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.contactResult(data);
      });
    }

    if(this.tabHeading['contacts'])
      this.headingName = this.tabHeading['contacts'];

  }

  fetchOpportunityContacts(projectId: number, opportunityId: number){
    this._ods.getOpportunityContacts(projectId, opportunityId).subscribe(
      res=>{
        this.contactList = res['responseData'];
        this.SpinnerService.hide('dashboard-contact');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-contact');
      }
    )
  }

  addContact() {
    const _saveMessage = `Contact has been saved.`;
    const ref = this.modalService.open(AddNewCompanyComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });

    ref.componentInstance.moduleType=1;
    ref.componentInstance.flag='opportunity';
    ref.componentInstance.opportunityId = this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.contactResult(result);
    });
  }

  contactResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-contact');
      this.fetchOpportunityContacts(this.selectedProject, this.parentData);
    }
  }

}
