export enum AppConfigurationFieldsEnum{

    //for Ozonetel
    OZONETEL_APP_ID=1,
    SCREENPOP_URL=1,
    CALLBACK_URL=2,
    CLICK_TO_CALL_URL=3,
    API_KEY=4,
    USER_NAME=5,
    AGENT_ID=6,
    CAMPAIGN_NAME=7,
}