import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListType } from '../../../contact-setting/models/list-type.model';
import { StdLookupAttribute } from '../../../contact-setting/models/std-lookup-attribute.model';
import { CustomService } from '../../../contact-setting/services/custom.service';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';

@Component({
  selector: 'kt-filter-field',
  templateUrl: './filter-field.component.html',
  styleUrls: ['./filter-field.component.scss']
})
export class FilterFieldComponent implements OnInit {

  public FilterForm: FormGroup;
  filterVal:any;
  filterName:string;
  value:string;
  conditionTypeList:ListType[];
  public conditionValueType: FormControl = new FormControl();
  public standardFilterType: FormControl = new FormControl();
  valuelist:any;
  displayNumeric:boolean;
  displayText:boolean;
  displayDate:boolean;
 // standardTypelist:StdLookupAttribute[];
  standardTypelist:ListType[];
  standardTypeValue:ListType
 // standardTypeValue: StdLookupAttribute;
  customPreviousDays: string | number;
  customFutureDays: string | number;
 // dateValueType;
  dateFilterType;
  displayDefaultDate:boolean;
  displayDefaultCustom:boolean;
  standard:number;
  isFirstOpen:boolean;


  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public eventEmitterService: EventEmitterService,
    public customService: CustomService) { }

  ngOnInit(): void {
    
    this.displayDefaultDate=false;
    this.displayDefaultCustom=false;
    console.log(this.filterVal);
    this.standardTypeValue= new ListType();
    this.initeditEntityFieldForm();
    this.getStandardLookUpList();
   // if(this.filterVal.entityFieldType.id == 2){
    if(this.filterVal.entityFieldType.id == 7 || this.filterVal.fieldType == 1){
        this.displayNumeric=true;
        this.displayText=false;
        this.displayDate=false;
        this.FilterForm.controls.dateFilterType.clearValidators();
        this.FilterForm.controls.dateFilterType.updateValueAndValidity();
     }
     else if(this.filterVal.entityFieldType.id == 3){
        this.displayNumeric=false;
        this.displayText=false;
        this.displayDate=true;
        this.FilterForm.controls.dateFilterType.setValidators([Validators.required]);
        this.FilterForm.controls.dateFilterType.updateValueAndValidity();
        this.dateFilterType=this.filterVal.dateFilterType;
        //this.FilterForm.controls['dateValueType'].setValue(this.filterVal.dateValueType);
        if(this.dateFilterType =='' || this.dateFilterType == null || this.dateFilterType == undefined){
          this.dateFilterType = 1;
          this.displayDefaultDate=true;
          this.displayDefaultCustom=false;
          this.handleValueType();
          this.standard=3;
          this.FilterForm.controls['standardTypeValue'].setValue(this.standard);
        }
        else if(this.dateFilterType == 1){
          console.log('standardDateTypeValue-----------'+this.filterVal.standardDateTypeValue);
          this.standard=this.filterVal.standardDateTypeValue;
          this.displayDefaultDate=true;
          this.displayDefaultCustom=false;
          this.FilterForm.controls['standardTypeValue'].setValue(this.standard);
        }
        else if(this.dateFilterType == 2){
          this.displayDefaultDate=false;
          this.displayDefaultCustom=true;
          this.customPreviousDays=this.filterVal.customPreviousDays;
          this.customFutureDays=this.filterVal.customFutureDays;
        }
     }else{
        this.displayNumeric=false;
        this.displayText=true;
        this.displayDate=false;
        this.FilterForm.controls.dateFilterType.clearValidators();
        this.FilterForm.controls.dateFilterType.updateValueAndValidity();
     }
    this.valueFieldValidation();
    this.getConditionList();
    this.filterName=this.filterVal.entityFieldName;

    if(this.filterVal.condition !=null && this.filterVal.condition !=''){
      this.FilterForm.controls['condition'].setValue(this.filterVal.condition);
    }
    if(this.filterVal.value !=null && this.filterVal.value !=''){
      this.value=this.filterVal.value;
    }
   
  }

  valueFieldValidation() {
    if(this.displayNumeric || this.displayText) {
      this.FilterForm.get('value').setValidators(Validators.required);
      this.FilterForm.get('condition').setValidators(Validators.required);
    } else {
      this.FilterForm.get('value').clearValidators();
      this.FilterForm.get('value').updateValueAndValidity();
      this.FilterForm.get('condition').clearValidators();
      this.FilterForm.get('condition').updateValueAndValidity();
    }
  }

  convertToLinearObject() {
    // copying properties of this.filterVal.filter into this.filterVal
    // reason, there were many places in this file where properties of this.filterVal.filter 
    // were used as this.filterVal['property of this.filterVal.filter']
    if(this.filterVal && this.filterVal.filter) {
      Object.keys(this.filterVal.filter).forEach(key => this.filterVal[key] = this.filterVal.filter[key]);
    }
  }

  changeStd(data){
    this.standard=data;
    console.log('vvvvvv------------------'+data);
   }
   handleValueType2(){

   }
   handleValueType(){
    if(this.FilterForm.controls.dateFilterType.value ==2){
      this.displayDefaultDate=false;
      this.displayDefaultCustom=true;
      this.FilterForm.controls.customPreviousDays.setValidators([Validators.required]);
      this.FilterForm.controls.customPreviousDays.updateValueAndValidity();
      this.FilterForm.controls.customFutureDays.setValidators([Validators.required]);
      this.FilterForm.controls.customFutureDays.updateValueAndValidity();
      this.FilterForm.controls.standardTypeValue.clearValidators();
      this.FilterForm.controls.standardTypeValue.updateValueAndValidity();
     
    }else if (this.FilterForm.controls.dateFilterType.value ==1 || this.dateFilterType == 1){
      this.displayDefaultDate=true;
      this.displayDefaultCustom=false;
      this.FilterForm.controls.customPreviousDays.clearValidators();
      this.FilterForm.controls.customPreviousDays.updateValueAndValidity();
      this.FilterForm.controls.customFutureDays.clearValidators();
      this.FilterForm.controls.customFutureDays.updateValueAndValidity();
      this.FilterForm.controls.standardTypeValue.setValidators([Validators.required]);
      this.FilterForm.controls.standardTypeValue.updateValueAndValidity();
      
    }else{
      this.displayDefaultDate=false;
      this.FilterForm.controls.customPreviousDays.clearValidators();
      this.FilterForm.controls.customPreviousDays.updateValueAndValidity();
      this.FilterForm.controls.customFutureDays.clearValidators();
      this.FilterForm.controls.customFutureDays.updateValueAndValidity();
    }
   }
  
  isDisplayCustom(): boolean{
    if(this.FilterForm.controls.dateFilterType.value ==2){
    //  this.FilterForm.controls.previousDateValue.setValidators([Validators.required]);
    //  this.FilterForm.controls.previousDateValue.updateValueAndValidity();
    //  this.FilterForm.controls.futureDateValue.setValidators([Validators.required]);
    //  this.FilterForm.controls.futureDateValue.updateValueAndValidity();
      return true;
    }else{
    //  this.FilterForm.controls.previousDateValue.clearValidators();
   //   this.FilterForm.controls.previousDateValue.updateValueAndValidity();
    //  this.FilterForm.controls.futureDateValue.clearValidators();
    //  this.FilterForm.controls.futureDateValue.updateValueAndValidity();
      return false;
    }
  }

  isDisplayStandard(): boolean{
    if(this.FilterForm.controls.dateFilterType.value ==1){
    //  this.FilterForm.controls.standardTypeValue.setValidators([Validators.required]);
   //   this.FilterForm.controls.standardTypeValue.updateValueAndValidity();
      return true;
    }else{
    //  this.FilterForm.controls.standardTypeValue.clearValidators();
    //  this.FilterForm.controls.standardTypeValue.updateValueAndValidity();
      return false;
    }
  }

  getStandardLookUpList(){
    var attributeId=3;
    var attributeName='Date';
    this.standardTypelist= new Array<ListType>();
    this.standardTypelist.push({ id: 0, value:"Today"});
    this.standardTypelist.push({ id: -1, value:"Yesterday"});
    this.standardTypelist.push({ id: 1, value:"Current week"});
    this.standardTypelist.push({ id: 2, value:"Current month till date"});
    this.standardTypelist.push({ id: 3, value:"Current month"});
    this.standardTypelist.push({ id: 4, value:"Current year till date"});
    this.standardTypelist.push({ id: 5, value:"Current year"});


   // console.log(this.attributeType.id+'----'+this.attributeType.typeName);
   // this.customService.getstdLookUpList(attributeId,attributeName).subscribe(
	//	(result)=>{
   //  console.log(result);
   //     this.standardTypelist=result;
	//	},
  //  error => {
     // this.errorMes=error.error.responseData;
   // })
  }

  initeditEntityFieldForm(){
    this.FilterForm = this.fb.group({
      filterName:[{value: '', disabled: true}],
      value: [''],
      condition:[],
      dateFilterType:[],
      standardTypeValue:[],
      customPreviousDays:[],
      customFutureDays:[]
     });
  }

  changeCondition(val){
  console.log('measureVal---'+val);
  }

  getConditionList(){
    this.conditionTypeList= new Array<ListType>();

   if(this.filterVal.entityFieldType.id == 7){
   // this.conditionTypeList.push({ id: 1, value:"Contains"});
    this.conditionTypeList.push({ id: 2, value:"Equals to"});
    this.conditionTypeList.push({ id: 3, value:"Less than equals to"});
    this.conditionTypeList.push({ id: 4, value:"Less than"});
    this.conditionTypeList.push({ id: 5, value:"Greater than equals to"});
    this.conditionTypeList.push({ id: 6, value:"Greater than"});
   }
   else if(this.filterVal.entityFieldType.id == 3){
    
   }else{
    this.conditionTypeList.push({ id: 1, value:"Contains"});
    this.conditionTypeList.push({ id: 2, value:"Equals to"});
   }
  }

  Close(){
    this.activeModal.close();
    this.FilterForm.reset();
    // if(this.isFirstOpen === true) {
    //   this.Delete();
    // }
  }

  Save(){

    const controls = this.FilterForm.controls;

    console.log('save*****************************');
      if (this.FilterForm.invalid) {
        Object.keys(controls).forEach(controlName => {
          controls[controlName].markAsTouched();
          console.log(controls[controlName].invalid);
        }
        );
        return;
      }
      const CopyOfFilterVal = {...this.filterVal};

    if(CopyOfFilterVal.fieldType == 1 || CopyOfFilterVal.entityFieldType.id == 7){
      //CopyOfFilterVal.fieldType=CopyOfFilterVal.entityFieldType.id;
      CopyOfFilterVal.fieldType=1;
      CopyOfFilterVal.condition=controls.condition.value;
      CopyOfFilterVal.value=Number(controls.value.value);
      CopyOfFilterVal.customPreviousDays ='';
      CopyOfFilterVal.customFutureDays ='';
      CopyOfFilterVal.dateFilterType=null;
      CopyOfFilterVal.customPreviousDays=null;
      CopyOfFilterVal.customFutureDays=null;
      CopyOfFilterVal.standardDateTypeValue=null;
   }
   else if(CopyOfFilterVal.entityFieldType.id == 3){
    CopyOfFilterVal.fieldType=3;
    //CopyOfFilterVal.fieldType=CopyOfFilterVal.entityFieldType.id;
    //CopyOfFilterVal.stdLookupAttribute = new StdLookupAttribute();
    CopyOfFilterVal.dateFilterType=this.FilterForm.controls.dateFilterType.value;
    CopyOfFilterVal.condition=null;
    CopyOfFilterVal.value=null;
    if(CopyOfFilterVal.dateFilterType == 1){

       // CopyOfFilterVal.stdLookupAttribute.id=this.standardTypeValue;
        CopyOfFilterVal.standardDateTypeValue=this.standard;
        CopyOfFilterVal.customPreviousDays =null;
        CopyOfFilterVal.customFutureDays =null;
        
    }else if(CopyOfFilterVal.dateFilterType == 2){
       
       // CopyOfFilterVal.stdLookupAttribute.id='';
        CopyOfFilterVal.standardDateTypeValue=null;
        CopyOfFilterVal.customPreviousDays= Number(controls.customPreviousDays.value);
        CopyOfFilterVal.customFutureDays = Number(controls.customFutureDays.value);
    }

   }else{
      CopyOfFilterVal.fieldType=2;
      //CopyOfFilterVal.fieldType=CopyOfFilterVal.entityFieldType.id;
      CopyOfFilterVal.condition=controls.condition.value;
      CopyOfFilterVal.value=controls.value.value;
      CopyOfFilterVal.customPreviousDays ='';
      CopyOfFilterVal.customFutureDays ='';
      CopyOfFilterVal.dateFilterType=null;
      CopyOfFilterVal.customPreviousDays=null;
      CopyOfFilterVal.customFutureDays=null;
      CopyOfFilterVal.standardDateTypeValue=null;
   }

   console.log(CopyOfFilterVal);
    this.activeModal.close(CopyOfFilterVal);
    this.FilterForm.reset();
    this.eventEmitterService.onModalCloseResult(CopyOfFilterVal);
   
  }

  copyProperties() {
    // copying few properties from this.filterVal into this.filterVal.filter
    // reason, those properties are used from this.filterVal.filter to create 
    // the final request body in report-builder-edit.component.ts (line: 939)
    this.filterVal.filter = {};
    this.filterVal.filter.condition = this.filterVal.condition;
    this.filterVal.filter.customFutureDays = this.filterVal.customFutureDays;
    this.filterVal.filter.customPreviousDays = this.filterVal.customPreviousDays;
    this.filterVal.filter.dateFilterType = this.filterVal.dateFilterType;
    this.filterVal.filter.fieldType = this.filterVal.fieldType;
    this.filterVal.filter.standardDateTypeValue = this.filterVal.standardDateTypeValue;
    this.filterVal.filter.value = this.filterVal.value;
  }

  Delete(){
    const index = this.valuelist.indexOf(this.filterVal);
    // console.log('index---'+index);
    if(index !== -1) {
      this.valuelist.splice(index, 1);
     }
    this.activeModal.close("Deleted");
    this.FilterForm.reset();
    //this.eventEmitterService.onModalCloseResult(index);
    this.eventEmitterService.onModalCloseResult(this.valuelist);
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.FilterForm.controls[controlName];
      if (!control) {
        return false;
      }
      
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }


}
