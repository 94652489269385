import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { ReportBuilderServiceService } from '../../../../report-builder/services/report-builder-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DashboardService } from '../../../services/dashboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFilterComponent } from 'src/app/views/pages/shared/user-filter/user-filter.component';


@Component({
  selector: 'kt-dashboard-report',
  templateUrl: './dashboard-report.component.html',
  styleUrls: ['./dashboard-report.component.scss']
})

export class DashboardReportComponent implements OnInit {
  @Input() public parentData;
  @Input() public condition;
  @Input() public isEdit;
  @Output() public removedReport=new EventEmitter<number>();
  @Output() public editReport=new EventEmitter<{reportId:number,canShareWithOthers:boolean}>();
  @ViewChild('myButton') myButton : ElementRef;
  public reportBuilderForm: FormGroup;
  options: any;
  modalReference: any;
  singularName='';
  chartRef: any;
  gridApi: any;
  gridColumnApi: any;
  Xaxis:any;
  Yaxis:any;
  rowData:any = [];
  columnDefs: any;
  gridOptions: any;
  public popupParent;
  public defaultColDef;
  measureVal: string| number;
  agCharts:any;
  newOptions: any;
  xaixsFields=[];
  yaixsFields=[];
  measureField=[];
  filterField=[];
  userfilterField=[];
  savexaixsFields=[];
  saveyaixsFields=[];
  savefilterField=[];
  saveuserfilterField=[];
  searchValue: any;
  currentMoveItem:any;
  entityIdType:any;
  rendered: boolean;
  reportData=[];
  dataValue:any;
  SaveFieldReportData:any;
  public groupDefaultExpanded;
  public autoGroupColumnDef;
  xfieldName: string;
  yfieldName : string;
  mfieldName: string;
  aggFunc: string;
  dataTable:boolean;
  displayDataTable:boolean;
  showPreview:boolean;
  reportType:string|number;
  entityFieldDetailsList: any;
  selectedItem:number = null;
  datafieldList:any;
  collapsedReportType = false;
  collapsedDataField=false;
  filter:any;
  userfilter:any;
  Columnaxis:any
  currrentMoveVal:string | String;
  currentList:any;
  isEntitySelected:boolean;
  prevFilterFormData=[];
  isReportTypeSelected:boolean;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  selectedCatalog:any = [];
  reportFilters:any;
  userFilters:any;
  isChartDataLoaded:boolean;
  isUserFilter:boolean=false;
  filteredUserFilterData:any=[];
  buttonColor:string= "#00aeff";
  chartData:any;
  reportName:string;
  viewContent:boolean;
  private matrixTableWidth:number;
  showDataCheckbox:boolean;
  showLabel:boolean;
  isLabelChanged:boolean=false;
  private yFieldNameArray=[];
  private xFieldNameArray=[];
  public pinnedBar:any;
  public columnDefsUngrouped;
  fieldDetails: {axisXRowField: any[], axisYColumnField: any[]};
  private isWeekOrMonthForMatrixReport:boolean =false;
  private isDateOrYearForMatrixReport: boolean =false;

  constructor(
    private dashboardService: DashboardService,
    private bas: ReportBuilderServiceService,
    private SpinnerService: NgxSpinnerService,
    private readonly modalService: NgbModal,
    private readonly cdr: ChangeDetectorRef,
    private fb: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.dataTable=false;
    this.displayDataTable=false;
    this.showPreview=false;
    this.showDataCheckbox=false;
    this.showLabel=false;
    this.columnDefs =[];
    this.initReportBuilderForm();
    this.SpinnerService.show();
    this.Xaxis=[];
    this.Yaxis=[];
    this.filter=[];
    this.userfilter=[];
    this.Columnaxis=[];
    this.isEntitySelected=false;
    this.isReportTypeSelected=false;
    this.SpinnerService.hide();
    this.getReportsCatalog();
  
  }
 
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.refreshCells({force : true});
    this.onChartDataRendered();
    if(this.reportType==6){
      this.gridColumnApi.autoSizeAllColumns();
       this. matrixTableWidth=this.getTableStyle("myGrid"+this.parentData.reportId);
    }
  }

  getTableStyle(idName:string): number {
    const minWidthPerColumn = 145; 
    const totalColumns = this.columnDefs.length;
    const containerWidth = document.getElementById(idName).clientWidth; 
    const  matrixTableWidth = totalColumns * minWidthPerColumn;
    if ( matrixTableWidth < containerWidth) {
      return containerWidth;
    } else {
      return  matrixTableWidth;
    }
  }

  onRowDataChanged(event) {
    this.onChartDataRendered();
  }

  getChartToolbarItems(params?) {
    return [];
  }

  getReportsCatalog(editFlag?:number) {
    this.rowData = [];
    this.dashboardService.getReportCatalogData(this.parentData.reportId).subscribe( data => {
        if (data && data.responseData) {
          this.isUserFilter = data.responseData.userFilters?.length > 0 ? true : false;
          this.chartData=data.responseData;
          this.reportType=data.responseData.reportType;
          this.setAxisData(data.responseData);
          if (data && data.responseData && data.responseData.reportFilters) { 
          this.reportFilters = data.responseData.reportFilters;
          }
          if (data && data.responseData && data.responseData.userFilters) { 
            this.userFilters = data.responseData.userFilters;
            }
          this.Preview(); 
      }
    });
   
  }
  

  initReportBuilderForm(){
    this.reportBuilderForm = this.fb.group({
      entityType:[],
      dataTable:[],
      labelData:[]
     });
  }
  changeLabelDlisplay(val){
    if(val == false){
      this.showLabel=false;
    }
    else{
      this.showLabel=true
    }
    this.isLabelChanged=true;
    this.onChartDataRendered();
  }
  changeDataTableDisplay(val){
    if((this.reportType!=5 &&  val == true) || (this.reportType!=6 &&  val == true)){
        this.displayDataTable=true;
    }else{
        this.displayDataTable=false;
    }
  } 
  getFieldNameForPreview(res: {label: string, entityFieldNameWithouModule: string}): string {
    return (res.label===null || res.label==='' || res.label===undefined) ? res.entityFieldNameWithouModule : res.label.replace('.', '');
  }
  Preview(){
    this.xaixsFields=[];
    this.yaixsFields=[];
    this.measureField=[];
    this.filterField=[];
    this.userfilterField=[];
    this.columnDefs=[];
    if(this.reportType == 5){
      this.dataTable=false;
      this.displayDataTable=true;
    } 
    this.SpinnerService.show();
  
    
    if(this.reportType == 1 || this.reportType == 2 || this.reportType == 4){
      this.measureField=null;

      
      
      this.Xaxis.forEach(res=>{
        if(this.reportType==2){
          this.xFieldNameArray.push(this.getFieldNameForPreview(res));
        }
        this.xfieldName = this.getFieldNameForPreview(res);
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
              res.lookupType=true;
        }else{
              res.lookupType=false;
        }
          this.xaixsFields.push({
            "entityId":res.entityFieldId,
            "moduleId":res.entityId,
            "fieldName":this.getFieldNameForPreview(res),
            "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
            "lookupType":res.lookupType,
            "entityFieldType":res.entityFieldType,
            "dateGroupBy":res.dateGroupBy
          })
        })
      this.Yaxis.forEach(res=>{
        this.yFieldNameArray.push(this.getFieldNameForPreview(res))
          if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
            res.lookupType=true;
          }else{
                res.lookupType=false;
          }
          this.yaixsFields.push({
            "entityId":res.entityFieldId,
            "moduleId":res.entityId,
            "fieldName":this.getFieldNameForPreview(res),
            "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
            "lookupType":res.lookupType,
            "entityFieldType":res.entityFieldType,
            "dateGroupBy":this.reportType == 2?res.dateGroupBy:null
          })
      })
      // this.yaixsFields = this.selectedCatalog.axisYColumnField;
      
    }

    if(this.reportType == 3 || this.reportType == 5){
      //this.yaixsFields=null;
      this.Xaxis.forEach(res=>{
        this.xfieldName=this.getFieldNameForPreview(res);
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
              res.lookupType=true;
        }else{
              res.lookupType=false;
        }
          this.xaixsFields.push({
            "entityId":res.entityFieldId,
            "moduleId":res.entityId,
            "fieldName":this.getFieldNameForPreview(res),
            "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
            "lookupType":res.lookupType,
            "entityFieldType":res.entityFieldType,
            "dateGroupBy":res.dateGroupBy
          })
        })
        // this.xaixsFields = this.selectedCatalog.axisXRowField;

      this.Yaxis.forEach(res=>{
        this.yfieldName= this.getFieldNameForPreview(res);
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
          res.lookupType=true;
        }else{
              res.lookupType=false;
        }
        this.yaixsFields.push({
          "entityId":res.entityFieldId,
          "moduleId":res.entityId,
          "fieldName":this.getFieldNameForPreview(res),
          "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
          "lookupType":res.lookupType,
          "entityFieldType":res.entityFieldType
        })
      })
      // this.yaixsFields = this.selectedCatalog.axisYColumnField;
  
    }

    if(this.reportType == 6){
      this.Xaxis.forEach(res=>{
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
          res.lookupType=true;
          }else{
                res.lookupType=false;
          }
            this.xaixsFields.push({
              "entityId":res.entityFieldId,
              "moduleId":res.entityId,
              "fieldName":this.getFieldNameForPreview(res),
              "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
              "lookupType":res.lookupType,
              "entityFieldType":res.entityFieldType,
              "dateGroupBy":res.dateGroupBy
            })
        })
        // this.xaixsFields = this.selectedCatalog.axisXRowField;

      this.Columnaxis.forEach(res=>{
        this.yfieldName= this.getFieldNameForPreview(res);
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
          res.lookupType=true;
        }else{
              res.lookupType=false;
        }
        this.yaixsFields.push({
          "entityId":res.entityFieldId,
          "moduleId":res.entityId,
          "fieldName":this.getFieldNameForPreview(res),
          "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
          "lookupType":res.lookupType,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":res.dateGroupBy
        })
        if(res.dateGroupBy && (res.dateGroupBy==2|| res.dateGroupBy==3)){
          this.isWeekOrMonthForMatrixReport=true;
        }else if(res.dateGroupBy && (res.dateGroupBy==1|| res.dateGroupBy==2 )){
          this.isDateOrYearForMatrixReport=true;
        }
     })
    //  this.yaixsFields = this.selectedCatalog.axisYColumnField;

      this.Yaxis.forEach(res=>{
        this.mfieldName= res.entityFieldVal;
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
          res.lookupType=true;
        }else{
              res.lookupType=false;
        }
        this.measureField.push({
          "entityId":res.entityFieldId,
          "moduleId":res.entityId,
          "fieldName":this.getFieldNameForPreview(res),
          "aggregate":res.aggFunc === 'null' ? null : res.aggFunc,
          "lookupType":res.lookupType,
          "entityFieldType":res.entityFieldType
        })
       })
      //  this.measureField = this.selectedCatalog.axisYColumnField;
  
    }

    this.filter.forEach(res=>{
      var Value=res.value;
      if(res.fieldType !=null && res.fieldType==1){
        Value=Number(Value);
      }
      if(res.entityFieldType && (res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18)){
        res.lookupType=true;
      }else{
        res.lookupType=false;
      }
      this.filterField.push({
        "entityId": res.entityId ? res.entityId : res.entityFieldId,
        "moduleId": res.moduleId,
        "fieldName": 'filter ' + res.entityFieldName,
        "lookupType": res.lookupType,
        "filter":  res.filter ?  res.filter : {
          "fieldType": res.fieldType,
          "condition": res.condition,
          "value": Value,
          "dateFilterType": res.dateFilterType,
          "customPreviousDays": res.customPreviousDays,
          "customFutureDays": res.customFutureDays,
          "standardDateTypeValue": res.standardDateTypeValue
        }
      })
      
     })
     this.userfilter.forEach(res=>{
      var Value=res.value;
      if(res.fieldType !=null && res.fieldType==1){
        Value=Number(Value);
      }
      if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
        res.lookupType=true;
      }else{
        res.lookupType=false;
      }
      this.userfilterField.push({
        "entityId":res.entityId,
        "moduleId":res.moduleId,
        "fieldName":'userFilter ' + res.entityFieldName.replace(/\./g, ''),
        "entityFieldType": res.entityFieldType,
        "lookupType":res.lookupType,
        "label": res.label
      })
    })
    const axisXRowField = this.chartData.axisXRowField;
    axisXRowField.forEach(obj => {
      obj.aggregate = obj.aggregate === 'null' ? null : obj.aggregate;
    })
    const axisYColumnField = this.chartData.axisYColumnField;
    if(axisYColumnField) {
      axisYColumnField.forEach(obj => {
        obj.aggregate = obj.aggregate === 'null' ? null : obj.aggregate;
      })
    }
    
    const previewData = {    
      "projectId": this.selectedProject,
      "reportType": this.reportType,
      "axisXRowField": this.xaixsFields,
      "axisYColumnField":this.yaixsFields,
      "measureField": this.measureField,
      "filters": null,
      "reportFilters":this.filterField,
      "userFilters":this.userfilterField
    };    
    
  this.bas.previewData(previewData).subscribe(
    (result)=>{
      this.fieldDetails = result['responseData'].fieldDetails;
      this.getColDef(this.fieldDetails);
      this.reportData= result['responseData'].reportData;
      if (this.reportType == 5 || this.reportType == 6) {
        this.showDataCheckbox = false;
      } else {
        this.showDataCheckbox = true;
      }

      this.rowData=this.reportData;
      if(this.reportType == 4 || this.reportType == 1 || this.reportType == 2){
        this.rowData.forEach(res=>{
         
          if(this.reportType!=2){
            this.yFieldNameArray.forEach(data=>{
              res[data]=Number(res[data])
            })
          }
          else{
            this.xFieldNameArray.forEach(data=>{
              res[data]=Number(res[data])
            })
          }
        })
      }

      if(this.reportType==6){
        this.generateColumns6(this.rowData);
      }
     
      
      this.isChartDataLoaded = true;
      this.SpinnerService.hide();

      let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
      setTimeout(() => {
        el.click();
        this.viewContent = true;
       }, 300);
    },
    error => {
      this.SpinnerService.hide();
    }
  );
    if (this.chartRef) {
      this.chartRef.destroyChart();
    }
  }
  private setAxisData(selectedCatalog) {

    this.Xaxis = !selectedCatalog.editViewAxisXdata ? [] : selectedCatalog.editViewAxisXdata;
    this.filter = !selectedCatalog.reportFilters ? [] : selectedCatalog.reportFilters;
    this.userfilter = !selectedCatalog.userFilters ? [] : selectedCatalog.userFilters;
    this.filter && this.filter.forEach((filter) => filter.entityFieldName = filter.fieldName);
    this.userfilter && this.userfilter.forEach((userfilter) => userfilter.entityFieldName = userfilter.fieldName);
   this.Xaxis && this.Xaxis.forEach((xdata, index) => {
    xdata.entityFieldVal = xdata.entityFieldNameWithouModule;
    xdata.aggFunc = selectedCatalog.axisXRowField[index].aggregate === 'null' ? null : selectedCatalog.axisXRowField[index].aggregate;
    xdata.lookupType = selectedCatalog.axisXRowField[index].lookupType;
  });
  if(this.reportType==6){
    this.Columnaxis=!selectedCatalog.editViewAxisYdata ? [] : selectedCatalog.editViewAxisYdata;
    this.Yaxis=!selectedCatalog.editViewAxisMeasureData?[]:selectedCatalog.editViewAxisMeasureData;
    this.Columnaxis && this.Columnaxis.forEach((ydata, index) => {
      ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
      ydata.aggFunc =selectedCatalog.axisYColumnField[index].aggregate === 'null' ? null : selectedCatalog.axisYColumnField[index].aggregate;
      ydata.lookupType = selectedCatalog.axisYColumnField[index].lookupType;
    });
    this.Yaxis && this.Yaxis.forEach((ydata, index) => {
      ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
      ydata.aggFunc = selectedCatalog.measureField[index].aggregate === 'null' ? null : selectedCatalog.measureField[index].aggregate;
      ydata.lookupType = selectedCatalog.measureField[index].lookupType;
    });
  }else{
  this.Yaxis = !selectedCatalog.editViewAxisYdata ? [] : selectedCatalog.editViewAxisYdata;
  this.Yaxis && this.Yaxis.forEach((ydata, index) => {
    ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
    ydata.aggFunc = selectedCatalog.axisYColumnField[index].aggregate === 'null' ? null : selectedCatalog.axisYColumnField[index].aggregate;
    ydata.lookupType = selectedCatalog.axisYColumnField[index].lookupType;
  });
}
  }

  applyfilters(){
    const filterModalRef = this.modalService.open(UserFilterComponent,
      {  centered: true,
         size : 'md',
         backdrop : 'static'
       }
    );
    if(this.prevFilterFormData.length>0){
      filterModalRef.componentInstance.prevFilterFormData = this.prevFilterFormData;
    }
    filterModalRef.componentInstance.userFilterData = this.reportData;
    filterModalRef.componentInstance.chartData = this.chartData;
    
    filterModalRef.result.then( result => {
      if(result){
        if (this.rowData != result.filteredData) {
          this.rowData = result.filteredData;
          this.prevFilterFormData = result.prevFormData
          this.cdr.detectChanges();
          this.buttonColor = "#00ff00";
        }
      }
    });
  }
  onChartDataRendered() {
    this.selectedCatalog = this.chartData;
    if(this.reportType == 1){
      var colVale=[];
      this.fieldDetails.axisXRowField.forEach(res=>{
        colVale.push(res);
      })
      this.fieldDetails.axisYColumnField.forEach(res=>{
          colVale.push(res);
      })
      var eContainer1 = document.querySelector('#chart1'+this.parentData.reportId);
      while (eContainer1.firstChild) {
        eContainer1.removeChild(eContainer1.firstChild);
      }
      var params1 = {
        suppressChartRanges:true,
        cellRange: {
          rowStartIndex: 0,
          rowEndIndex: 4000,
          columns: colVale,
        },
        chartThemeOverrides: {
          common: {
            padding: {
              top: 20,
              left: 10,
              bottom: 16,
              right: 10,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
            toolTip:{
              enabled:true
            }
          },
          cartesian: {
            axes: {
              number: {
                gridStyle: [
                  { 
                    stroke: undefined,
                    lineDash: undefined,
                  }
                ],
              },
              category: {
                gridStyle: [
                  {
                    stroke: undefined,
                    lineDash: undefined,
                  },
                ],
              },
              
            },
            yAxes: [{
              ticks: {
                 steps : 10,
                 stepValue : 10,
                 max : 100,
               }
           }] 
          },
        },
        chartType: 'groupedColumn',
        chartContainer: eContainer1
      };
      this.chartRef = this.gridApi ? this.gridApi.createRangeChart(params1) : null;
    } else if(this.reportType == 2){
        var colVale2=[];
        this.fieldDetails.axisXRowField.forEach(res=>{
          colVale2.push(res);
        })
        this.fieldDetails.axisYColumnField.forEach(res=>{
            colVale2.push(res);
        })
        var eContainer2 = document.querySelector('#chart2'+this.parentData.reportId);
        while (eContainer2.firstChild) {
          eContainer2.removeChild(eContainer2.firstChild);
        }
        var params2 = {
          suppressChartRanges:true,
          cellRange: {
            rowStartIndex: 0,
            rowEndIndex: 4000,
            columns: colVale2
          },
          chartThemeOverrides: {
            common: {
              padding: {
                top: 20,
                left: 10,
                bottom: 16,
                right: 10,
              },
              legend: {
                enabled: true,
                position: 'bottom',
              },
            },
          },
        chartType: 'groupedBar',
        chartContainer: eContainer2,
      };
      this.chartRef = this.gridApi ? this.gridApi.createRangeChart(params2) : null;
    } else if(this.reportType == 3){
        var colVale3=[];
        this.fieldDetails.axisXRowField.forEach(res=>{
          colVale3.push(res);
        })
        this.fieldDetails.axisYColumnField.forEach(res=>{
          colVale3.push(res);
        })
        //

        
        
        // deleting child element ,e.firstElementChild can be used.
       if (this.isLabelChanged==true) {
        let e = document.querySelector('#chart3'+this.parentData.reportId);
         let child = e.lastElementChild; 
         while (child) {
             e.removeChild(child);
             child = e.lastElementChild;
         }
       }

        //
        var eContainer3 = document.querySelector('#chart3'+this.parentData.reportId);
        while (eContainer3.firstChild) {
          eContainer3.removeChild(eContainer3.firstChild);
        }
        var params3 = {
        suppressChartRanges:true,
        cellRange: {
            columns: colVale3,
        },
        chartType: 'pie',
        chartContainer: eContainer3,
        chartThemeOverrides: {
          common: {
            padding: {
              top: 40,
              left: 10,
              bottom: 40,
              right: 10,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
           pie: {
            series: {
              title: {
                enabled: false
              },
              label: {
                enabled: this.showLabel,
                offset: 15,
                minAngle: 0.01,
              }
            }
          },
        },
      };
      if(this.gridApi) {
        this.chartRef = this.gridApi.createRangeChart(params3);
      }
    }
    else if(this.reportType == 4){
      var colVale4=[];

      this.fieldDetails.axisXRowField.forEach(res=>{
        colVale4.push(res);
      });

      this.fieldDetails.axisYColumnField.forEach(res=>{
        colVale4.push(res);
      });
      var eContainer4 = document.querySelector('#chart4'+this.parentData.reportId);
      while (eContainer4.firstChild) {
        eContainer4.removeChild(eContainer4.firstChild);
      }
      var params4 = {
        suppressChartRanges:true,
        cellRange: {
          columns: colVale4,
        },
        chartType: 'line',
        chartContainer: eContainer4,
        chartThemeOverrides: {
          common: {
            padding: {
              top: 20,
              left: 40,
              bottom: 16,
              right: 10,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };
      this.chartRef = this.gridApi ? this.gridApi.createRangeChart(params4) : null;
    }
    
  }

  getColDef(fieldDetails){
    if(this.reportType == 1){
     this.generateColumns(fieldDetails);
    }else if(this.reportType == 2){
     this.generateColumns2(fieldDetails);
    }else if(this.reportType == 3){
      this.generateColumns3(fieldDetails);
    }else if(this.reportType == 4){
      this.generateColumns4(fieldDetails);
    }else if(this.reportType == 5){
      this.generateColumns5(fieldDetails);
    }
   
  }
  private mapUngroupedColDef() {
    this.columnDefsUngrouped = JSON.parse(JSON.stringify(this.columnDefs));
    for(let columnDefsUngrouped in this.columnDefsUngrouped ) {
      if(this.columnDefsUngrouped[columnDefsUngrouped].hasOwnProperty('rowGroup')) {
        this.columnDefsUngrouped[columnDefsUngrouped].rowGroup=false;
      }
    }
  }
  generateColumns(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    
    let columnDefinitions = [];

    fieldDetails.axisXRowField.forEach(res=>{
      let mappedColumn={
        field:  res,
        chartDataType: 'category',
        rowGroup: false
      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res=>{
      this.measureVal=res.measure;
      let mappedColumn2={
        field:  res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn2);
    })
    this.columnDefs=columnDefinitions;
    this.mapUngroupedColDef();
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded=-1;
    
  }

  generateColumns2(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res=>{
      let mappedColumn={
        field:  res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res=>{
      this.measureVal=res.measure;
      let mappedColumn2={
        field:  res,
        chartDataType: 'category',
      }
      columnDefinitions.push(mappedColumn2);
    })
    this.columnDefs=columnDefinitions;
    this.columnDefs=[];
    this.columnDefs=columnDefinitions;
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded=-1;
  }

  private handleWeekOrMonthFormatForMatrixReport(dynamicColumn: any[]) {
    function compareDateStrings(a: string, b: string): number {
      function parseWeekFormat(dateString: string): [string, string, string] {
        if(dateString.match(/Week-(\d+) \(([^)]+)\)/)){
          const [, week, monthYear] = dateString.match(/Week-(\d+) \(([^)]+)\)/) || [];
          const [month, year] = monthYear.split('-');
          return [year, month, week];
        }else return['','',''];
        
      }
  
      function parseMMMYYFormat(dateString: string): [string, string, string] {
        if(dateString=='null' || dateString==''){
          return ['','',''];
        }
        const [month, year] = dateString.split('-');
        return [year, month, ''];
      }
  
      const [yearA, monthA, weekA] = parseWeekFormat(a);
      const [yearB, monthB, weekB] = parseWeekFormat(b);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // For MMM-YY format
      if (!weekA && !weekB) {
        const [yearA, monthA] = parseMMMYYFormat(a);
        const [yearB, monthB] = parseMMMYYFormat(b);
        if (yearA !== yearB) {
          return +yearA - +yearB;
        }
        return months.indexOf(monthA) - months.indexOf(monthB);
      }
  
      if (yearA !== yearB) {
        return +yearA - +yearB;
      }
  
      if (weekA !== weekB) {
        return +weekA - +weekB;
      }
  
      
      return months.indexOf(monthA) - months.indexOf(monthB);
    }
  
    dynamicColumn.sort((a, b) => compareDateStrings(a.field, b.field));
    
    return dynamicColumn.reverse();
  }
  
private handleDateOrYearFormatForMatrix(dynamicColumn){
  function compareDates(a: string, b: string): number {
    const partsA = a.split('-');
    const partsB = b.split('-');
  
    // YYYY-MM-DD format
    if (partsA.length === 3 && partsB.length === 3) {
      const dateA = new Date(a);
      const dateB = new Date(b);
  
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    }
  
    // Handle YYYY format
    if (partsA.length === 1 && partsB.length === 1) {
      const yearA = parseInt(a, 10);
      const yearB = parseInt(b, 10);
  
      if (yearA < yearB) {
        return -1;
      }
      if (yearA > yearB) {
        return 1;
      }
      return 0;
    }
  
    return 0; // Return 0 for unsupported formats or when dates are equal
  }
  
  dynamicColumn.sort((a, b) => compareDates(a.field, b.field));
  return dynamicColumn.reverse();
  
}

  getColumnField(res: {label: string, entityFieldVal: string}): string{
    return (res.label===null || res.label==='' || res.label===undefined) ? res.entityFieldVal : res.label;
  }
  generateColumns3(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    if(fieldDetails.axisXRowField) {
      fieldDetails.axisXRowField.forEach(res=>{
        let mappedColumn={
          field:  res,
          chartDataType: 'category',
        }
        columnDefinitions.push(mappedColumn);
      })
    }
    if(fieldDetails.axisYColumnField) {
      fieldDetails.axisYColumnField.forEach(res=>{
        this.measureVal=res.measure;
        let mappedColumn2={
          field:  res,
          chartDataType: 'series',
        }
        columnDefinitions.push(mappedColumn2);
      })
    }
    
    this.columnDefs=columnDefinitions;
   
    this.columnDefs=columnDefinitions;
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded=-1;
    
  }

  generateColumns4(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    
    if(fieldDetails.axisXRowField) {      
      fieldDetails.axisXRowField.forEach(item => {
        let mappedColumn={
          field: item,
          chartDataType: 'category',
        }
        columnDefinitions.push(mappedColumn);
      })
    }
    if(fieldDetails.axisYColumnField) {      
      fieldDetails.axisYColumnField.forEach(item => {        
        this.measureVal=item.measure;
        let mappedColumn2={
          field: item,
          chartDataType: 'series',
        }
        columnDefinitions.push(mappedColumn2);
      })
    }

    this.columnDefs=columnDefinitions;
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded=-1;
    
  }

  generateColumns5(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    if(fieldDetails.axisXRowField) {
      fieldDetails.axisXRowField.forEach(res=>{
        let mappedColumn={
          field:  res,
          chartDataType: 'category',
        }
        columnDefinitions.push(mappedColumn);
      })
    }
    if(fieldDetails.axisYColumnField) {
      fieldDetails.axisYColumnField.forEach(res=>{
        this.measureVal=res.measure;
        let mappedColumn2={
          field:  res,
          chartDataType: 'series',
        }
        columnDefinitions.push(mappedColumn2);
      })
    }
   
    this.columnDefs=columnDefinitions;
   
    this.columnDefs=columnDefinitions;
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded=-1;
    
  }

  generateColumns6(rowData: any) {
    this.getColumnsAndUpdatedRowDataForMatrixReport(rowData);
    this.getPinnedTopData(this.columnDefs);
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
      suppressMovable: true
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;

  }
  getUpdatedRowDataForMatrixReport(rowData: any, dynamicColumns: any, columnFieldName) {
    const fieldNames = new Set<string>(dynamicColumns.map((column: any) => column.field));
    return rowData.map((res: any) => {
      const updatedRes = { ...res };
      if (res[columnFieldName]) {
        for (let data of res[columnFieldName]) {
          updatedRes[data.key] = data.value;
        }
      }
      for (let colName of fieldNames) {
        if (updatedRes[colName] === undefined) {
          updatedRes[colName] = 0;
        }
      }
      return updatedRes;
    });
  }
  getColumnsAndUpdatedRowDataForMatrixReport(rowData: any) {

    const columnDefinitions = [{
      headerName: '',
      field: this.getColumnField(this.Xaxis[0]),
      suppressMovable: true,
      pinned: 'left',
    }];

    const columnFieldName = this.getColumnField(this.Columnaxis[0]);

    let dynamicColumn = [];
    const fieldNames = new Set();

    for (const res of rowData) {
      if (res[columnFieldName]) {
        for (const data of res[columnFieldName]) {
          if (!fieldNames.has(data.key)) {
            res[data.key] = data.value;
            fieldNames.add(data.key);
            const mappedColumn = {
              headerName: "",
              field: data.key,
              pinned: 'left',
            };
            dynamicColumn.push(mappedColumn);
          }
        }
      }
    }

    if (dynamicColumn.length > 0) {
      dynamicColumn.reverse();
      if(this.isWeekOrMonthForMatrixReport){
        dynamicColumn=this.handleWeekOrMonthFormatForMatrixReport(dynamicColumn);
      }else if(this.isDateOrYearForMatrixReport){
        dynamicColumn=this.handleDateOrYearFormatForMatrix(dynamicColumn);
      }
      dynamicColumn[0].headerName = columnFieldName;
    }

    this.rowData = this.getUpdatedRowDataForMatrixReport(rowData, dynamicColumn, columnFieldName);
    this.columnDefs = columnDefinitions.concat(dynamicColumn);
  }
  getPinnedTopData(columnDefinitions:any) {
    const pinnedBar = [{ xAxis: 'y' }]
    columnDefinitions.forEach(res => {
      res.minWidth = 100;
      pinnedBar[0][res.field] = res.field;
    })
    this.pinnedBar = pinnedBar;
  }


  removeReport(){
   this.removedReport.emit(this.parentData.reportId)
  }

  editDashBoardReport(){
    this.editReport.emit({reportId:this.parentData.reportId, canShareWithOthers:this.chartData.canShareWithOthers})
  }

  /**
   * For quick display ag-grid data
   */
   triggerClick() {
   }

}
