import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
//routing module 
import { RouterModule,Routes } from '@angular/router';
   
//component declared
import { OpportunityConfigurationComponent} from './opportunity-configuration.component';
import { OpportunityStatusListComponent } from './_subs/opportunity-status/opportunity-status-list/opportunity-status-list.component';
import { AddOpportunityStatusDialogComponent } from './_subs/opportunity-status/add-opportunity-status-dialog/add-opportunity-status-dialog.component';
import { EditOpportunityStatusDialogComponent } from './_subs/opportunity-status/edit-opportunity-status-dialog/edit-opportunity-status-dialog.component';
import { SettingsComponent } from './_subs/settings/settings.component';
  
//module
import { SharedModule } from '../shared/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';


const routes:Routes=[{
  path:'',component:OpportunityConfigurationComponent
}]

@NgModule({
  declarations: [OpportunityConfigurationComponent, OpportunityStatusListComponent, AddOpportunityStatusDialogComponent, EditOpportunityStatusDialogComponent, SettingsComponent],
  imports: [
		
		CommonModule,
		RouterModule.forChild(routes),
		SharedModule,
		NgxSpinnerModule			
    
	],
	exports:[],
	entryComponents:[AddOpportunityStatusDialogComponent,EditOpportunityStatusDialogComponent]
})
export class OpportunityConfigurationModule { }
