import { Component, OnInit,Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { title } from 'process';

@Component({
  selector: 'kt-widget6-conversations',
  templateUrl: './widget6-conversations.component.html',
  styleUrls: ['./widget6-conversations.component.scss']
})
export class Widget6ConversationsComponent implements OnInit {

  @Input() cssClasses = '';
  @Input() public parentData;
  @Input() isInsightsCollapsed: string;
  @Output() public openChat : EventEmitter<{}> = new EventEmitter<{}>();
  @Input() public companyInfoData;
  private toggle: boolean = false;
  private compName: string="";
  private customerWaNumber : string;

  public isCollapsed:boolean = true;
  isError = false;
  errorMessage: string;
  conversations: any;
  cards=[];

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  constructor(
    private SpinnerService: NgxSpinnerService,
    private _cds: CompanyDashboardService,
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('conversation');
    if(this.selectedProject==413 || this.selectedProject==578){
      this.fetchConversations(this.selectedProject, this.parentData);
    }

  }

  ngOnChanges(changes: SimpleChanges) {
    this.compName = changes['companyInfoData']?.currentValue?.name;
    if (changes['isInsightsCollapsed']?.currentValue=='true') {
      this.isCollapsed=false;
    }else{
      this.isCollapsed=true;
    }
  }

  openWhatsappConversation(chatId:number){
    this.toggle = !this.toggle;
    this.companyInfoData['customerWaNumber'] = this.customerWaNumber
    this.openChat.emit({toggle:this.toggle,conversation:this.conversations[chatId]});
  }

  fetchConversations(projectId: number, companyId: number){
    this._cds.getConversations(projectId,companyId).subscribe(
      res=>{
            console.log(res);
             this.conversations = res['responseData'].conversations;
            this.customerWaNumber = res['responseData']?.customerWaNumber;
             let isDoc = false;
             let isProduct = false;
             for (const conversation in this.conversations) {
              if (this.conversations.hasOwnProperty(conversation)) {
                  const messages = this.conversations[conversation];
                  console.log(messages)
                  messages?.forEach(message => {
                    if(message.messageType === 1){
                       isDoc = true;
                    }
              });
                let title = '';
                if (messages && messages.length > 0 && 'initiatedBy' in messages[0]) {
                  title = messages[0].initiatedBy === 0 ? 'Initiated By Customer' : 'Initiated By System';
                  const createdDate = new Date(messages[0].createdOn);
                  createdDate.setHours(0, 0, 0, 0); 
                  const now = new Date();
                  now.setHours(0, 0, 0, 0); 
                  const diffTime = Math.abs(now.getTime() - createdDate.getTime());
                  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
                  title += `, ${diffDays} ${diffDays === 1 ? 'Day' : 'Days'} ago`;
                  this.cards.push({
                    id: conversation,
                    name: this.compName,
                    title: title,
                    diffDays: diffDays, 
                    isConversationOpen: diffDays < 1,
                    isBasket: false,
                    isDocument: isDoc
                  })
                }
              }
          }
          this.cards.sort((a,b) => a.diffDays - b.diffDays);
          this.SpinnerService.hide('conversation');
          },
          err=>{
            console.log(err);
            this.isError = true;
            this.errorMessage = "Unable to fetch required details. Please try again.";
            this.SpinnerService.hide('conversation');
          }
    );
  }

  sortConversations(){
    this.cards = this.cards.reverse();
  }

}
