import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { OpportunityDashboardService } from '../../../../services/opportunity-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-widget4-company',
  templateUrl: './widget4-company.component.html',
  styleUrls: ['./widget4-company.component.scss']
})
export class Widget4CompanyComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;
  @Output() childData = new EventEmitter<boolean>();

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Customer';
  companyInfo: any;
  companyName: string;
  serialNo:string;
  openOpportunity: number | string;
  estimatedRevenue: string;
  contacts: [];
  companyId: number;
  isError = false;
  errorMessage: string;

  public isCollapsed = false;

  constructor(
    private _ods: OpportunityDashboardService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-company');
    this.fetchOpportunityCompany(this.selectedProject, this.parentData);

    if(this.tabHeading['customers'])
      this.headingName = this.tabHeading['customers'];

  }

  fetchOpportunityCompany(projectId: number, opportunityId: number){
    this._ods.getOpportunityCompanies(projectId, opportunityId).subscribe(
      res=>{
        this.companyInfo = res['responseData'];
        this.companyName = this.companyInfo?.companyData.name;
        this.serialNo=this.companyInfo?.companyData.serialNo;
        this.openOpportunity = this.companyInfo.openOpportunities;
        this.estimatedRevenue = this.companyInfo.estimatedRevenue;
        this.contacts = this.companyInfo.contacts;
        this.companyId = this.companyInfo?.companyData.companyId;
        this.SpinnerService.hide('dashboard-company');
        return this.childData.emit(this.companyId === undefined || this.companyId === null);
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-company');
      }
    )
  }
}
