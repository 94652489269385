import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from 'src/app/core/_base/layout';
import { DashboardService } from './services/dashboard.service';
import { AddDashboardReportsComponent } from './_subs/add-dashboard-reports/add-dashboard-reports.component';
import { AddNewDashboardComponent } from './_subs/add-new-dashboard/add-new-dashboard.component';
import { DashboardDisplayOrderComponent } from './_subs/dashboard-display-order/dashboard-display-order.component';

@Component({
  selector: 'kt-crm-dashboard',
  templateUrl: './crm-dashboard.component.html',
  styleUrls: ['./crm-dashboard.component.scss']
})
export class CrmDashboardComponent implements OnInit {
  @ViewChild('myButton') myButton : ElementRef;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  dashboardTabs: any;
  selectedTab = 0;
  dashBoardClick = false;
  displayOrderClick=false;
  viewContent:boolean;
  openInEditMode=false;

  constructor(private dashBoardService:DashboardService, 
    private subHeaderService:SubheaderService, 
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService) {
     }

  ngOnInit(): void {
    this.subHeaderService.setDashBoardIcon(true);
    this.subHeaderService.openDashBoardModal$.subscribe(()=>{
      if(this.dashBoardClick){
        this.addNewDashBoard()
      }
      this.dashBoardClick=true;
      });

    this.subHeaderService.openDisplayorderModal$.subscribe(()=>
    {
      if(this.displayOrderClick){
        this.addDisplayOrder()
      }
      this.displayOrderClick=true;
    });

    this.getDashBoard(null);
    
  }

  getDashBoard(event){
    this.SpinnerService.show('dashboard-tab');
    this.dashBoardService.getDashBoardList(this.selectedProject).subscribe(
      
        data => {
            this.dashboardTabs=data['responseData'];
            if(event!==null){
              this.openInEditMode=event.isEditMode;
              for(let i=this.dashboardTabs.length-1; i>=0; i--){
                if(this.dashboardTabs[i].dashboardId===event.dashboardId){
                  this.selectedTab=i;
                  break;
                }
              }
            }
            // console.log("hiiiii",this.reportTabs);
             this.SpinnerService.hide('dashboard-tab');
             let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
             setTimeout(() => {
                el.click();
                this.viewContent = true;
              }, 300);
          },
          
          error => {
            console.log('error occured',error);
           
            this.SpinnerService.hide('dashboard-tab');
          } 
    )
 
  }
  
  addNewDashBoard(){
    const ref=this.modalService.open(AddNewDashboardComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });

      ref.result.then((result) => {
        if(result){
          this.addDashBoardReport(result);
        }
        
      })
  }

  addDashBoardReport(dbDetails){
    const ref=this.modalService.open(AddDashboardReportsComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });
      ref.componentInstance.dashBoardDetails=dbDetails;
      ref.result.then((result) => {
        if(result){
          this.relodDashboardList({isOpenClone:false,isEditMode:false,dashboardId:result});
        }
      })
  }

  addDisplayOrder(){
    const ref=this.modalService.open(DashboardDisplayOrderComponent,
      {  
        centered: false,
        size : '380px',
        backdrop : 'static'
      });
      let dashboardOrder = this.dashboardTabs.map(x => {return {dashboardId: x.dashboardId, dashboardName: x.dashboardName, displayOrderId: x.displayOrderId}});
      ref.componentInstance.dashBoardList=dashboardOrder;
      ref.result.then((result) => {
        if(result){
          this.getDashBoard(null);
        }
      })
  }

  relodDashboardList(event){
    console.log(event);
    this.getDashBoard(event);
  }


  /**
   * For quick display ag-grid data
   */
   triggerClick() {}

}
