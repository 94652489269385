<div class="example-preview">
    <div>
        <form [formGroup]="editCompanyForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Edit Customer Type</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Customer Type
                            <span style="color: red; display: inline;">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput #input maxlength="50" formControlName="companyTypeName" [(ngModel)]="companyTypeName">
                        
                            <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
                            <mat-error *ngIf="isControlHasError('companyTypeName', 'required')">Customer Name cannot be blank</mat-error>
                            <mat-error *ngIf="isControlHasError('companyTypeName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon>              
                        </mat-form-field>                    
                    </div>
                </div>
                

                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label">Status
                            <span style="color: red; display: inline;">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select [(value)]="selectedValue">
                                <mat-option value="1">Active</mat-option>
                                <mat-option value="0">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
  
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="Edit()">Save</button>
            </div>
        </form> 
    </div>
</div>  