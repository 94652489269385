<div *ngIf="showModal">
<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="apiResponseSuccess === false" class="text-danger text-center">
    {{ errorMessage }}
  </div>
  <form [formGroup]="filterForm" *ngIf="apiResponseSuccess === true">

    <!-- date -->
    <!-- <div class="form-group row">
    <div class="col-md-4"><label class="col-form-label form-padding-top-25">Date Range</label></div>
    <div class="col-md-8 ">
      <mat-form-field class="example-full-width">
        <input matInput [matDatepicker]="dp" readonly formControlName="dateRange">
        <mat-datepicker-toggle matSuffix [for]="dp" ></mat-datepicker-toggle>
        <mat-datepicker #dp></mat-datepicker>
        <mat-error *ngIf="hasError('dateRange', 'required')">Date Range cannot be blank</mat-error>
      </mat-form-field>
    </div>
  </div> -->
    <!-- test -->
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label form-padding-top-25">Date Range
          <span class="mandatory-marker">*</span>
        </label>
      </div>
      <div class="col-md-8">
        <mat-form-field class="example-full-width">
          <input matInput readonly formControlName="dateRange" />
          <mat-datepicker-toggle
            matSuffix
            [matMenuTriggerFor]="appMenu"
          ></mat-datepicker-toggle>
          <mat-menu #appMenu="matMenu" class="kt-date-range-drop-calendar">
            <div (click)="$event.stopPropagation()">
              <mat-calendar
                [minDate]="minDate"
                [maxDate]="maxDate"
                [startAt]="minDate"
                #calendar
                (selectedChange)="select($event, calendar)"
                [dateClass]="isSelected"
              ></mat-calendar>
            </div>
          </mat-menu>
          <mat-error *ngIf="hasError('dateRange', 'required')"
            >Date Range cannot be blank.</mat-error
          >
          <!-- <mat-error *ngIf="hasError('dateRange', 'minlength')"
            >End Date cannot be blank</mat-error
          > -->
        </mat-form-field>
      </div>
    </div>
    <!-- end test -->
    <!-- end date -->
    <!-- activity -->
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label form-padding-top-25">Activity
          <span class="mandatory-marker">*</span>
        </label>
      </div>
      <div class="col-md-8">
        <mat-form-field appearance="standard" class="input-100">
          <mat-select formControlName="activity" (selectionChange)="onActivityChange()">
            <mat-option>
              <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found"
                #singleDropdownFilter
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="
                let activity of activityList
                  | search: singleDropdownFilter.value:'activityName'
              "
              [value]="activity.activityId"
            >
              {{ activity.activityName }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="hasError('activity', 'required')"
            >Activity cannot be blank.</mat-error
          >
        </mat-form-field>
      </div>
    </div>
    <div class="form-group row" *ngIf="isActivitySelected">
      <div class="col-md-4">
          <label class="col-form-label form-padding-top-25">Report Type</label>
      </div>
      <div class="col-md-8">
          <mat-form-field appearance="standard" class="input-100">
              <mat-select formControlName="reportType">
                  <mat-option>
                      <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #reportTypeFilter></ngx-mat-select-search>
                  </mat-option>
                  <mat-option *ngFor="let val of reportType
              | search: reportTypeFilter.value:'name' " [value]="val.id">
                      {{ val.name }}
                  </mat-option>
              </mat-select>
              <mat-error *ngIf="hasError('reportType', 'required')"
            >Report Type cannot be blank</mat-error
          >
          </mat-form-field>
      </div>
  </div>

    <!-- end activity -->
    <!-- performed by -->
    <div class="form-group row">
      <div class="col-md-4">
        <label class="col-form-label form-padding-top-25">Performed by</label>
      </div>
      <div class="col-md-8">
        <mat-form-field appearance="standard" class="input-100">
          <mat-select #select1 formControlName="performedBy" multiple>
            <mat-option>
              <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found"
                #multipleDropdownFilter
              ></ngx-mat-select-search>
            </mat-option>
            <div class="select-all">
              <mat-checkbox [(ngModel)]="allSelected"
                      [ngModelOptions]="{standalone: true}"
                      (change)="toggleAllSelection(select1)">Select All
              </mat-checkbox>
            </div>
            <mat-option (click)="optionClick(select1)"
              *ngFor="
                let user of usersList
                  | search: multipleDropdownFilter.value:'fullName'
              "
              [value]="user.id"
            >
              {{ user.fullName }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <!-- end performed by -->
  </form>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close()">
    Cancel
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="formSubmit()"
    [disabled]="apiResponseSuccess === false"
  >
    Apply
  </button>
</div>
</div>
<ngx-spinner
class="SpinnerLoading"
bdColor="rgba(0, 0, 0, 0.5)"
size="default"
type="ball-spin-clockwise"
name="activity-filter"
>
<p class="SpinnerFetch Align">Loading...</p>
</ngx-spinner>