import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Contact } from '../models/contact.model';
import { Observable, of } from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {each, filter, find, some} from 'lodash';
import {environment} from 'src/environments/environment';

const API_ADD_CONTACT_URL=environment.baseUrl+'/contact/editContactType';

@Injectable({
  providedIn: 'root'
})
export class EditContactService {

  constructor(private http: HttpClient) { }

  editContact(contactTypeName: string, isActive:number,isPrimary: number,id:number, projectId:number): Observable<Contact> {
    return this.http.post<Contact>(API_ADD_CONTACT_URL, {contactTypeName,"projectId": projectId, isActive, isPrimary,id});
}

  getContactById(id:Number, projectId:number){
    return this.http.post<Contact>(environment.baseUrl+'/contact/getContactType', {id,"projectId": projectId});
  }
}
