<div class="col-xl-12">
    <div class="col-xl-12">
        <br />
        <h1 mat-dialog-title>{{data.title}}</h1>
        <hr>
        <div mat-dialog-content>
            <p *ngIf="data.type === 'hyperlinkError'; else defaultMsg">
                Please check the <span style="color: #F64E60;">Red</span> highlighted link for Carousel image and enter
                a valid link.
            </p>

            <ng-template #defaultMsg>
                {{data.body}}
            </ng-template>
        </div>
        <hr>
        <div mat-dialog-actions class="float-right">
            <button mat-button (click)="closeDialog()">Ok</button>
        </div>
        <br />
    </div>
</div>