export class CarouselOutputEvent {
    data: string;
    isInvalid: boolean;
    errorType: ErrorType;
    errorMessage: string | null;
    action: ActionType;

    constructor(data: string, isInvalid: boolean, errorType: ErrorType = null, errorMessage: string = '', action?: ActionType) {
        this.data = data;
        this.isInvalid = isInvalid;
        this.errorType = errorType;
        this.errorMessage = errorMessage;
        this.action = action ? action : 0;
    }
}

export type ErrorType = 'hyperlinkPattern' | 'fileType' | 'fileSize' | 'unknown' | null;
export type ActionType = 1 | 0;
export type BusyStatus = 'busy' | 'free';