export enum LpplTemplateButtonIdEnum {
    RECORD_PURCHASE = "RecordPurchase",
    REQUEST_CALLBACK = "RequestCallback",
    CHECK_POINTS_BALANCE = "CheckPointBalance",
    REGISTER_NOW = "RegisterNow",
    CHANGE_LANGUAGE = "ChangeLanguage",
    ENGLISH = "English",
    HINDI = "Hindi",
    DONT_CHANGE = "DontChange",
    CONFIRM_REDEMPTION = "ConfirmRedemption",
    DONT_REDEEM = "DontRedeem"
}