import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators, Form } from '@angular/forms';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import { oppotunityStatus } from '../../../models/opportunity-status.model';
import { OpportunityStatusService } from '../../../services/opportunity-status.service';
import { BehaviorSubject } from 'rxjs';
@Component({
  selector: 'kt-edit-opportunity-status-dialog',
  templateUrl: './edit-opportunity-status-dialog.component.html',
  styleUrls: ['./edit-opportunity-status-dialog.component.scss']
})
export class EditOpportunityStatusDialogComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  editOpportunityForm:FormGroup;
  labelPosition: string = "after";
  selectedValue : string;
  editOppVal:oppotunityStatus;
  statusName:string;
  statusValue;
  loading = false;
  
  disabled : BehaviorSubject<Boolean>=new BehaviorSubject<Boolean>(false);
  
  constructor( private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private _os:OpportunityStatusService) { }

  ngOnInit(): void {

    console.log(this.editOppVal);

    this.editOpportunityForm = this.fb.group({
      statusName: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])
      ],
      statusValue: ['', Validators.compose([
          Validators.required        
        ])
      ]
    
     });

    this.editOpportunityForm.reset();
    this.selectedValue=String(this.editOppVal.status);
    this.statusName=this.editOppVal.opportunityStatus;
  
    var stage=this.editOppVal.stage;
    if(stage =='In Process'){
       this.statusValue='1';
    }else if(stage =='Closed Won'){
      this.statusValue='2';
    }else{
      this.statusValue='3';
    }

  }

  Close(){
    this.activeModal.close();
    this.editOpportunityForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
      const control = this.editOpportunityForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Edit(){
    this.disabled.next(true);
    const controls = this.editOpportunityForm.controls;
    
    if (this.editOpportunityForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled.next(false);
      return;
      
    }
       
    const editRedcordData = {
        'opportunityStatus':controls.statusName.value,
        'stage':controls.statusValue.value,
        "projectId": this.selectedProject,  
        "status": Number(this.selectedValue),
        "id": this.editOppVal.id
    };
    
    this._os.editOpportunityStatus(editRedcordData).subscribe(

      data => {
        console.log(data);
        this.loading = false;
        this.activeModal.close();
        this.editOpportunityForm.reset();
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.disabled.next(false);
      }
      
    );
  }

}
