<kt-portlet> 
    <kt-portlet-body>
        <mat-tab-group [(selectedIndex)]="selectedTab" >           
            <mat-tab label="Fields" >
                <ng-template matTabContent>
                    <kt-fields-list [parentData]="moduleType" [entity]="entity" [isEnableForCompanies]="allowForCompanies"></kt-fields-list>
                </ng-template>
            </mat-tab>
            <mat-tab label="Settings" >
                <ng-template matTabContent>
                    <kt-manage-activity-settings [parentData]="moduleType"></kt-manage-activity-settings>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>
<ngx-spinner size="default" type="ball-spin-clockwise" name='activity-config'>  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
  
