import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CompanyConfigurationComponent } from './company-configuration.component';
import { SettingsComponent } from './_subs/settings/settings.component';
import { CompanyTypesListComponent } from './_subs/company-types/company-types-list/company-types-list.component';
import { AddCompanyComponent } from './_subs/company-types/add-company/add-company.component';
//module
import { SharedModule } from '../shared/shared.module';
import { Routes, RouterModule } from '@angular/router';
import { EditCompanyComponent } from './_subs/company-types/edit-company/edit-company.component';
import { CustomFieldsComponent } from './_subs/company-types/custom-fields/custom-fields.component';
import { CustomListComponent } from './_subs/custom-fields/custom-list/custom-list.component';
import { AddCustomFieldComponent } from './_subs/custom-fields/add-custom-field/add-custom-field.component';
import { AddCustomAttributeComponent } from '../shared/add-custom-attribute/add-custom-attribute.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EditCustomFieldComponent } from './_subs/edit-custom-field/edit-custom-field.component';
import { AgGridModule } from 'ag-grid-angular';
import { EditCellRendererComponent } from './_subs/edit-cell-renderer/edit-cell-renderer.component';
import { NgxSpinnerModule } from 'ngx-spinner';


const routes:Routes=[{
  path:'',component:CompanyConfigurationComponent
}]
    

@NgModule({
  declarations: [CompanyConfigurationComponent, SettingsComponent, CompanyTypesListComponent, AddCompanyComponent, EditCompanyComponent, CustomFieldsComponent, CustomListComponent, AddCustomFieldComponent, EditCustomFieldComponent, EditCellRendererComponent],
  imports: [
    CommonModule,
		RouterModule.forChild(routes),
    SharedModule,
    NgbModule,
    AgGridModule.withComponents([EditCellRendererComponent]),
    NgxSpinnerModule
  ],
  exports:[],
	entryComponents:[AddCompanyComponent,EditCompanyComponent,AddCustomFieldComponent, EditCustomFieldComponent]
})
export class CompanyConfigurationModule { }
