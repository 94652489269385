import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CrmDashboardComponent } from './crm-dashboard.component';
import { ReportTabsComponent } from './_subs/report-tabs/report-tabs.component';
import { RouterModule, Routes} from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DashboardReportComponent } from './_subs/report-tabs/dashboard-report/dashboard-report.component';
import { AddNewDashboardComponent } from './_subs/add-new-dashboard/add-new-dashboard.component';
import { AddDashboardReportsComponent } from './_subs/add-dashboard-reports/add-dashboard-reports.component';
import { DashboardDisplayOrderComponent } from './_subs/dashboard-display-order/dashboard-display-order.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DashboardDialogOptionComponent } from './_subs/dashboard-dialog-option/dashboard-dialog-option.component';
import {MatDialogModule} from '@angular/material/dialog';
import { EditDashboardAlertComponent } from './_subs/edit-dashboard-alert/edit-dashboard-alert.component';
import { CloneDashboardComponent } from './_subs/clone-dashboard/clone-dashboard.component';
import { EditReportAlertComponent } from './_subs/edit-report-alert/edit-report-alert.component';
import { DeleteDashboardAlertComponent } from './_subs/delete-dashboard-alert/delete-dashboard-alert.component';
import { MatIconModule } from '@angular/material/icon';
import { UserFilterComponent } from '../shared/user-filter/user-filter.component';


const routes:Routes=[{
  path:'',component:CrmDashboardComponent
}]


@NgModule({
  declarations: [
    CrmDashboardComponent,
    ReportTabsComponent,
    DashboardReportComponent,
    AddNewDashboardComponent,
    AddDashboardReportsComponent,
    DashboardDisplayOrderComponent,
    DashboardDialogOptionComponent,
    EditDashboardAlertComponent,
    CloneDashboardComponent,
    EditReportAlertComponent,
    DeleteDashboardAlertComponent
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule.forChild(routes),
    SharedModule,
    NgxSpinnerModule,
    DragDropModule,
    MatDialogModule,
    MatIconModule
  ],
  entryComponents:[ReportTabsComponent, AddNewDashboardComponent, AddDashboardReportsComponent,
    DashboardDisplayOrderComponent, DashboardDialogOptionComponent,CloneDashboardComponent,
    EditDashboardAlertComponent,EditReportAlertComponent,DeleteDashboardAlertComponent,UserFilterComponent]
})
export class CrmDashboardModule { }
