import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AttributeType } from '../models/attribute-type.model';
import { LooupValues } from '../models/looup-values.model';
import { DateAttributes } from '../models/date-attributes.model';
import { CustomAttibute } from '../models/custom-attibute.model';
import { StdLookupAttribute } from '../models/std-lookup-attribute.model';
import { Contact } from '../models/contact.model';
import { environment } from 'src/environments/environment';
import { SelectedCustomTypes } from '../models/selected-custom-types.model';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';

@Injectable({
  providedIn: 'root'
})
export class CustomService {

  API_ATTRIBUTE_TYPE_URL=environment.baseUrl+'/setting/api/customType/getAllAttributeTypes';
  API_LOOKUP_TYPE_URL=environment.baseUrl+'/setting/api/customType/getStdLookupForAttributeTypes';
  API_ADD_CUSTOM_ATTRIBUTE_URL=environment.baseUrl+'/setting/api/customType/addCustomAttribute';
  API_ADD_CUSTOM_CONTACT=environment.baseUrl+'/customType/addUpdateContactTypes';
  API_EDIT_CUSTOM_ATTRIBUTE_URL=environment.baseUrl+'/setting/api/customType/editCustomAttribute';
  SELECTED_CONTACT_CUSTOM_TYPES=environment.baseUrl+'/customType/getSelectedContactTypes';
  
  constructor(private http: HttpClient,private userLocalization:UserLocalizationService) { }

  getAtrributeType(data){
   
    return this.http.get<AttributeType[]>(this.API_ATTRIBUTE_TYPE_URL+"?moduleType="+data);
  }  

  getstdLookUpList(id: number, typename: string){
    
    return this.http.post<StdLookupAttribute[]>(this.API_LOOKUP_TYPE_URL,{id,typename});
  }

  addCustomAttribute(attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
     lookupValues: LooupValues[],dateAttributes:DateAttributes,projectId:number ){
      return this.http.post<CustomAttibute>(this.API_ADD_CUSTOM_ATTRIBUTE_URL,{attributeName,"projectId": projectId,
          "moduleType":"1",mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes,
          "userTime":this.userLocalization.getUserTime(),"transTimeZone":this.userLocalization.getUserTimeZoneCode()});
  }

  editCustomAttribute(id:number,attributeName: string, attributeType: AttributeType, mandatoryFlag: number,
    lookupValues: LooupValues[],dateAttributes:DateAttributes,projectId:number ){
     return this.http.post<CustomAttibute>(this.API_EDIT_CUSTOM_ATTRIBUTE_URL,{id,attributeName,"projectId": projectId,
         "moduleType":"1",mandatoryFlag,"status": 1,attributeType,lookupValues,dateAttributes,
         "userTime":this.userLocalization.getUserTime(),"transTimeZone":this.userLocalization.getUserTimeZoneCode()});
 }

  addCustomAttributeToContact(attributeId: number, attributeName:string, contactTypes: Contact[],projectId:number){
    return this.http.post<Contact>(this.API_ADD_CUSTOM_CONTACT,{attributeId,attributeName,"projectId": projectId,contactTypes});
  }

  getSelectedContactCustomAttribute(contactCustomData){
    return this.http.post<SelectedCustomTypes[]>(this.SELECTED_CONTACT_CUSTOM_TYPES,contactCustomData);
  }

  getQuantityTransferDetails(projectId: number, activityTypeId: number, attributedId: number) {
    return this.http.get(environment.baseUrl+'/setting/api/customType/getProductQuantityTransferDetail?projectId='+projectId+'&activityTypeId='+activityTypeId+'&attributeId='+attributedId);
  }
}
