export class Contact {
    id: number;
    status?: string;
    active:string;
    primary:string;
    projectId: number;
    contactTypeName: string;
    isPrimary: number;
    isActive: number;
    enforceLiveLocCapture: number;
    updatedBy: string
    contactName?:String

}
  