import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer-company',
  templateUrl: './edit-cell-renderer-company.component.html',
  styleUrls: ['./edit-cell-renderer-company.component.scss']
})
export class EditCellRendererCompanyComponent implements OnInit {

  data:any;
  params:any;

  constructor() { }

  agInit(params){
    this.params = params;
    this.data = params.value;

  }

  ngOnInit(): void {
  }

  public invokeParentMethod() {
    this.params.context.componentParent.edit(this.params.data);
  }

}
