// Angular
import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse ,HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
// RxJS
import { tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { AppState } from '../../../../core/reducers';
import { AuthService } from '../../../auth/_services/auth.service';

/**
 * More information there => https://medium.com/@MetonymyQT/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
 */
@Injectable()
export class InterceptService implements HttpInterceptor {

  constructor(
    private store: Store<AppState>, 
    private authenticationService: AuthService,
    private router: Router) {}

  // intercept request and add token
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // tslint:disable-next-line:no-debugger
    // modify request

    let currentUser = this.authenticationService.currentUserValue;
   

    if (currentUser && currentUser.token) {
      if(request.url==="https://in1-ccaas-api.ozonetel.com/CAServices/AgentManualDial.php"){
        request=request.clone ({headers:new HttpHeaders({})});
      }else{
        request = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + currentUser.token) });
      }
    }

    if (!request.headers.has('Content-Type') && !request.url.search('uploadFrontImage') && !request.url.search('uploadFieldImage')) {
        request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }

    // request = request.clone({ headers: request.headers.set('Accept', 'application/json') });

    //console.log(request);
 /*   return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
           console.log(error);
          return throwError(error);
        })
      )
*/
      return next.handle(request).pipe(
        tap(null, error => {
          if (error.status === 401) {
            this.authenticationService.logout();
          
            this.router.navigateByUrl('/auth/login');
          }
        })
      ); 
  }
}