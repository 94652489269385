import { Injectable } from '@angular/core';
import { Users } from '../Models/users.model';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { UsersList } from '../../opportunity-dashboard/add-activity/Models/users-list.model';
import { RoleList } from '../Models/role-list.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http:HttpClient) { }

  getAllUsersList(projectId: number){
    return this.http.get(environment.baseUrl + '/login/api/users?projectId='+projectId);
  }

  getAllUsers(projectId: number, pageSize:number, pageNumber:number, advanceSearchArray=null){
    return this.http.post(environment.baseUrl + '/login/api/users/getAll?projectId='+projectId+'&pageSize='+pageSize+'&pageNumber='+pageNumber+'&isFirstAdvanceSearch=false',advanceSearchArray);
  }
 
  getUserRole(projectId: number){
    return this.http.get<RoleList>(environment.baseUrl + '/setting/api/roles?projectId='+projectId+'&activeStatus=1');
  }

  getActiveUserList(projectId: number){
    return this.http.get(environment.baseUrl + '/login/api/users?projectId='+projectId+'&activeStatus=1');
  }

  getReportingHeadUserList(projectId: number, userId: Number){
    return this.http.get(environment.baseUrl + '/login/api/users/reportsToOptions?projectId='+projectId+'&userId='+userId);
  }

  saveUserData(userData){
    return this.http.post<any[]>(environment.baseUrl + '/login/api/users',userData)
  }

  getUserDetail(userId, projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/'+userId+'?projectId='+projectId);
  }

  getOneUser(userId, projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/getOne/'+userId+'?projectId='+projectId);
  }

  getUserDownHierarchy(projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/downHierarchy?projectId='+projectId);
  }
  getOwnerAndUsersDownHierarchy(projectId: number){
    return this.http.get<any[]>(environment.baseUrl + '/login/api/users/ownerAndDownHierarchyUsers?projectId='+projectId);
  }

}
