<div *ngIf="showModal" class="example-preview">
    <div>
        <form [formGroup]="addCompaniesForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">{{singularName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              
            <div class="form-group row">
                <label class="col-form-label form-padding-top-15 col-md-4">Serial No.</label>
                <mat-form-field appearance="standard" class="col-md-8">
                    <input matInput formControlName="serialNo" ktAutoTrimSpaces>
                    <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                </mat-form-field>
            </div>

                <div id="div_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0}" class="form-group row" *ngFor="let customAttr of customAttributesList">

                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">{{customAttr.attributeName}}
                            <span class="mandatory-marker">{{customAttr.mandatoryFlag === 1 ? '*' : ''}}</span>
                        </label>
                    </div>
                                                                
                    <!-- first div -->
                    
                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21); else systemAttrbitues ">
                        <mat-form-field appearance="standard" class="input-100">
                            <mat-select [formControlName]="customAttr.combinedAttributeName" (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id,'') : ''">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>                      
                        </mat-form-field>                    
                    </div>                   

                    <!-- second div -->
                        
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                        <mat-form-field class="mat-form-field-fluid">
                            <mat-select #select1 [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id,'') : ''">
                                
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <div class="select-all">
                                    <mat-checkbox [(ngModel)]="allSelected"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(select1)">Select All
                                    </mat-checkbox>
                                  </div>
                                <mat-option (click)="optionClick(select1)" *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                        </mat-form-field>
                                        
                    </div>

                    <!-- third div -->
                    
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>
    
                    <!-- fourth div -->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5">
                        <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                            <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                {{values.lookupValue}}
                            </mat-radio-button>
                        </mat-radio-group>  
                        <mat-hint>{{customAttr.instructions}}</mat-hint>     
                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                    </div>
    
                    <!-- fifth div -->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <div *ngIf="customAttr.numericValidationType==1">
                        <div *ngIf="customAttr.maxDigits!=null">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxDigits}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                            <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint  align="end">{{message.value.length}} / {{customAttr.maxDigits}}</mat-hint>
                        </mat-form-field> 
                        </div> 
                        <div *ngIf="customAttr.maxDigits==null">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message.value.length}} </mat-hint>
                            </mat-form-field> 
                            </div> 
                        </div>
                        <div *ngIf="customAttr.numericValidationType==2">
                            <div *ngIf="customAttr.maxNumber!=null">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input maxlength="{{customAttr.maxNumber.toString().length}}"  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}} / {{customAttr.maxNumber.toString().length}}</mat-hint>
                                </mat-form-field>  
                            </div>
                            <div *ngIf="customAttr.maxNumber == null">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                    <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                </mat-form-field>  
                            </div>

                        </div>
                        <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                            <mat-form-field class="mat-form-field-fluid">
                                <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktStrictNumberOnlyDirective>
                                <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                            <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                            </mat-form-field>  
                        </div>
                           
                        
                    </div>

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <textarea maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>
                                                                    
                    </div>

                        <!-- six div -->
                    
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                        <mat-form-field class="example-full-width">
                            <input matInput [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                        </mat-form-field>
                                            
                    </div>
                    <!-- seven div -->
                        
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4 ">
                        <!-- <img src="{{src}}" id="{{'src'+customAttr.combinedAttributeName}}" style="display:none;" width="100px"> -->
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                        <input id="{{customAttr.combinedAttributeName}}" readonly  type="file" style="display:none;"
                                (change)="processFile($event,customAttr.combinedAttributeName,customAttr.mandatoryFlag,customAttr.maxImagesAllowed)">
                           
                        <span *ngIf="imgURL">

                            <img *ngFor="let url of imgURL" src="{{url.name}}" height="50" width="90" style="padding: 10px 10px 0px 10px;">
                        
                        </span>
                        <span id="image{{customAttr.combinedAttributeName}}"></span>
                        <span *ngIf="customAttr.showIcon"> 
                            <div class="artist-collection-photo">
                               <!-- <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	-->
                               <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail">	
                               <div *ngIf="customAttr.maxImagesAllowed" class="img-index">{{imageUploadedCounter}}/{{customAttr.maxImagesAllowed}}</div>
                            </div>
                        </span>
                        </div>
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        
                        <div class="mat-form-field-subscript-wrapper">
                            <mat-hint class="mat-hint_img"
                                style="font-size: 100% !important; padding-top: 0px !important;">{{customAttr.instructions}}</mat-hint>
                            <mat-error class="mat-hint_img"
                                *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be
                                blank.</mat-error>
                            <mat-error class="mat-hint_img" *ngIf="uploadErr_+customAttr.mandatoryFlag">Please upload again.</mat-error>
                            <mat-error *ngIf="imageNoUploaded"
                                style="font-size: 100% !important; padding-top: 0px !important;">{{customAttr.attributeName}} can not be blank.
                            </mat-error>
                        </div>
                    </div>

                     <!-- nineth div (Email) -->
                     <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                        <mat-form-field class="mat-form-field-fluid">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                        </mat-form-field>                                            
                    </div>

                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==21 ">
                        <mat-form-field class="example-full-width">
                            <input matInput type="datetime-local" 
                             min="{{customAttr.dateAttributes.minDate}}" max="{{customAttr.dateAttributes.maxDate}}" [formControlName]="customAttr.combinedAttributeName" placeholder="" onkeydown="return false;">
                            <mat-hint>{{customAttr.instructions}}</mat-hint>
                    
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                cannot be blank.</mat-error>
                        </mat-form-field>
                    </div>

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==22">
                        <mat-form-field class="mat-form-field-fluid">
                            <input #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces type="time" step="1" onkeydown="return false;">

                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            
                        </mat-form-field>                                            
                    </div>

                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==18">
                        <mat-form-field appearance="standard" class="input-100">

                            <mat-select [formControlName]="customAttr.combinedAttributeName"
                                (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr,'') : ''">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel="Search"
                                        noEntriesFoundLabel="No options found"
                                        #dataListFieldFilter></ngx-mat-select-search>
                                </mat-option>
                                <mat-option
                                    *ngFor="let values of customAttr.lookupValues| search : dataListFieldFilter.value : 'lookupValue'"
                                    [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>

                            <mat-hint>{{customAttr.instructions}}</mat-hint>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one.</mat-error>
                                <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 

                        </mat-form-field>
                    </div>

<!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint>Select country.</mat-hint>
                        
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank.</mat-error>
                                        <mat-hint>Select state.</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank.</mat-error>
                 
                                        <mat-hint>Select city</mat-hint>
                        
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                    </ng-template>

                    <!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21 ">
                            <div class="row">
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint>Select country.</mat-hint>                        
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank.</mat-error>
     
                                        <mat-hint>Select state.</mat-hint>
                        
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank.</mat-error>
                 
                                        <mat-hint>Select city.</mat-hint>
                        
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                      
                    </ng-template>

                   <!-- product form -->
                    <div id="select_{{customAttr.id}}" class="col-md-12" *ngIf="customAttr.attributeType.id==13 && customAttr.parentFormId !==null && productFormFieldArray.length>0 ">
                        <div *ngIf="moduleType > 100  && customAttr.parentFormId.id !==null && customAttr.parentFormId.id !==0" style="font-size:10px; padding:0 0 0 8px;">
                            <div >
                                <div class="form-group row" style="overflow-x: auto;" [formArrayName]="customAttr.combinedAttributeName" *ngIf="productFormFields[customAttr.combinedAttributeName]">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">{{field.attributeName}}</th>
                                                        <!-- <th *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1">Action</th> -->
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(customAttr.combinedAttributeName); let i=index; last as isLast" [formGroupName]="i">
                                                     
                                                            <td *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">
                                                                <mat-form-field  class="inputbox" *ngIf="field.attributeType.id!=13 && (field.attributeType.id==7 || field.attributeType.id==14)"> 
                                                                    <input [readonly]='field.systemAttribute?.id == 50 || field.systemAttribute?.id == 52 || field.attributeType.id==14' matInput formControlName="{{field.combinedAttributeName}}" required [ktStrictNumberOnlyDirective]="field.decimalFlag" >  
                                                                </mat-form-field>
                                                                <mat-form-field class="inputSelect"  *ngIf="field.attributeType.id==13 && field.systemAttribute && field.systemAttribute.id==48">
                                                                    <input *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView===1" matInput formControlName="{{productFormFields[customAttr.combinedAttributeName].productFieldNameInput}}">  
                                                                    <mat-select *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!==1" formControlName="{{field.combinedAttributeName}}" required >
                                                                        <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                            {{sku.productName}}
                                                                        </mat-option>
                                                                    </mat-select>
                                                                 </mat-form-field>
                                                                 <!-- form here (Date)  -->
                                                                 <mat-form-field class="example-full-width"  *ngIf="field.attributeType.id==3">
                                                                    <input matInput readonly [formControlName]="field.combinedAttributeName"  [matDatepicker]="picker">
                                                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                                    <mat-datepicker #picker></mat-datepicker>
                                                                </mat-form-field>

                                                                <!-- from here (Text-single-line) -->
                                                                <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==6">
                                                                    <input matInput  #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                                </mat-form-field>

                                                                <!-- from here (Text-multi-line) -->
                                                                <mat-form-field appearance="standard" class="input-100"  *ngIf="field.attributeType.id==8">
                                                                    <textarea  maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" 
                                                                    [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces></textarea>
                                                                </mat-form-field>

                                                                <!-- from here (Email) -->
                                                                <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==9">
                                                                    <input maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                                </mat-form-field>

                                                                <!-- from here (Image) -->    
                                                                <div *ngIf="field.attributeType.id==4"
                                                                    style="display: flex; flex-direction: row; max-width: auto; overflow-x:auto;  max-width: 80px; overflow-y: hidden; height: 70px;">
                                                                    <input id="{{customAttr.combinedAttributeName + field.combinedAttributeName + i}}" type="file" style="display:none;"
                                                                        multiple="" ktAutoTrimSpaces>
                                                                    <span *ngIf="fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]"
                                                                        style="display: flex; flex-direction: row;">
                                                                        <span
                                                                            *ngFor="let file of fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]?.files; index as ix">
                                                                
                                                                            <div class="artist-collection-photo" style="width: 40px;margin: 4px;">
                                                                                <img width="40" height="50" [src]="baseUrl + file" alt="image.jpg" class="img-thumbnail"
                                                                                    (click)="showImageModalOnly(file)">
                                                                                <div *ngIf="field.maxImagesAllowed" class="img-index"> {{ix+1}}/{{field.maxImagesAllowed}} </div>
                                                                                <div *ngIf="!field.maxImagesAllowed" class="img-index text-center ml-0"> {{ix+1}} </div>
                                                                            </div>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                                <!-- from here (Document,Video) --> 
                                                                <div  *ngIf="customAttr.attributeType.id==15||customAttr.attributeType.id==16"  style="display: flex; flex-direction: row; max-width: 80px; overflow-x:auto; overflow-y: hidden; height: 70px;">
                                                                            <input id="{{customAttr.combinedAttributeName + field.combinedAttributeName + i}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces>
                                                                            <span *ngIf="fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]" style="display: flex; flex-direction: row;">
                                                                                <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]?.files; index as idx">
                                                                                    <div class="artist-collection-photo" style="width: 40px; margin: 4px;">
                                                                                        <a href="{{baseURL + icon}}" target="_blank">
                                                                                            <img width="40" height="50" [src]= "icon" alt="image.jpg" class="img-thumbnail">
                                                                                        </a>
                                                                                        <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{idx+1}}/{{field.maxImagesAllowed}} </div>
                                                                                        <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{idx+1}} </div>
                                                                                    </div>
                                                                                </span>
                                                                            </span>
                                                                </div>

                                                                <!-- from here (reference document) --> 
                                                                <div  *ngIf="customAttr.attributeType.id==17" style="display: flex; flex-direction: row; max-width: 80px; overflow-x:auto; overflow-y: hidden; height: 70px;">
                                                                            <span *ngIf="fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]" style="display: flex; flex-direction: row;">
                                                                                <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]?.files; index as ifx">
                                                                                    <div class="artist-collection-photo" style="width: 40px; margin: 4px;">
                                                                                        <img width="40" height="50" [src]= "icon" alt="image.jpg" class="img-thumbnail" (click)="showRefDocModal(file)">
                                                                                    </div>
                                                                                </span>
                                                                            </span>
                                                                    <div class="mat-form-field-subscript-wrapper">
                                                                        <mat-hint class="mat-hint_img" *ngIf="customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >{{field.instructions}}</mat-hint>
                                                                    </div>    
                                                                </div>

                                                                <!-- From here (single select)-->
                                                                <div *ngIf="field.attributeType.id==1; else systemAttrbitues ">
                                                                    <mat-form-field  appearance="standard" class="input-100">
                                                                        <mat-select  [formControlName]="field.combinedAttributeName">
                                                            
                                                                            <mat-option *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                                {{values.lookupValue}}
                                                                            </mat-option>
                                                                            
                                                                        </mat-select>
                                                                    </mat-form-field>                    
                                                                </div>

                                                                <!-- From here (multi select)-->
                                                                <div *ngIf="field.attributeType.id==2" >
                                                                    <mat-form-field class="mat-form-field-fluid">
                                                                        <mat-select #select3 [formControlName]="field.combinedAttributeName" multiple>
                                                                            <div class="select-all">
                                                                                <mat-checkbox [(ngModel)]="field.allSelected"
                                                                                        [ngModelOptions]="{standalone: true}"
                                                                                        (change)="toggleAllSelection(select3,field)">Select All
                                                                                </mat-checkbox>
                                                                              </div>
                                                                            <mat-option (click)="optionClick(select3,field)" *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                                {{values.lookupValue}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </div>  
                                                            <!-- <td *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1" style="min-width:90px;"> 
                                                                <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary "  [disabled]="true"
                                                                 *ngIf="isLast">
                                                                 <i class="fas fa-plus" style="color: #808080;"></i>
                                                                </button>
                                            
                                                                <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" [disabled]="true">
                                                                    <i class="fas fa-minus" style="color: #808080;"></i>
                                                                </button>
                                                               
                                                            </td> -->
                                                            
                                                  </tr>
                                                  <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isAllowDiscount">
                                                    <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                      <div class="col-md-12 text-right" >
                                                          <label class="col-form-label form-padding-top-15">Sub-total</label>
                                                      </div>
                                                    </td>
                                                    <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                       <input matInput ktTwoDigitDecimaNumberDirective disabled
                                                          [(ngModel)]="productFormFields[customAttr.combinedAttributeName].subTotal" 
                                                          [ngModelOptions]="{standalone: true}"> 
                                                    </td>
                                                </tr>
                                                  <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountValue">
                                                    <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                      <div class="col-md-12 text-right" >
                                                          <label class="col-form-label form-padding-top-15">Discount Amount</label>
                                                      </div>
                                                    </td>
                                                    <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                        <input matInput ktTwoDigitDecimaNumberDirective  disabled
                                                            [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountValue" 
                                                            [ngModelOptions]="{standalone: true}"> 
                                                    </td>
                                                 </tr>
                                                  <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountPercent">
                                                    <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                      <div class="col-md-12 text-right" >
                                                          <label class="col-form-label form-padding-top-15">Discount %</label>
                                                      </div>
                                                    </td>
                                                    <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                        <input matInput ktTwoDigitDecimaNumberDirective   disabled
                                                            [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountPercent" 
                                                            [ngModelOptions]="{standalone: true}">
                                                    </td>
                                                 </tr>
                                                 <tr>
                                                    <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                      <div class="col-md-12 text-right" >
                                                          <label class="col-form-label form-padding-top-15">Total</label>
                                                      </div>
                                                    </td>
                                                    <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                         <input matInput ktTwoDigitDecimaNumberDirective disabled
                                                            [(ngModel)]="productFormFields[customAttr.combinedAttributeName].total" 
                                                            [ngModelOptions]="{standalone: true}"> 
                                                      </td>
                                                 </tr>
                                                </table>
                                             </tr>
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                        </div>
                    </div>

                 <!--   <ng-template #systemAttrbituesM >
                
                        <div class="col-md-12 table_scroll" *ngIf="customAttr.systemAttribute.id==28">
                            <div >
                                <div class="form-group row" formArrayName="skuItems">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>List Price</th>
                                                        <th>Selling Price</th>
                                                        <th>Discount %</th>
                                                        <th>Tax %</th>
                                                        <th>Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                        <td>
                                                            <mat-form-field class="inputSelect">
                                                                <mat-select formControlName="sku" required>
                                                                    
                                                                    <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                        {{sku.productName}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                
                                                             </mat-form-field>
                                                        </td>
                                                            <td>
                                                                <mat-form-field  class="inputbox"> 
                                                                    <input matInput formControlName="quantity" required ktStrictNumberOnlyDirective >  
                                                              
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="sellingPrice" required ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="tax" required ktTwoDigitDecimaNumberDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                                    
                                                                </mat-form-field>
                                                            </td>
                                                            <td style="width:90px;"> 
                                                                <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                    <i class="fas fa-plus" (click)="addTable()"></i>
                                                                </button>
                                            
                                                                <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                                    <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                                </button>
                                                               
                                                            </td>
                                                            
                                                  </tr>
                                                
                                                </table>
                                             </tr>
                                          
                                          
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                            
                            <mat-error *ngIf="addProductError">Please select product</mat-error>
                          
                        </div>
                    </ng-template>  -->
                        
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>

            

            </div>
            <div class="modal-footer" >
                <button type="button" class="btn btn-secondary"  (click)="Close()">Close</button>
            
               
            </div>
        </form> 
    </div>
</div>  
    
<ngx-spinner class="SpinnerLoading" bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
    <p class="SpinnerFetch">Fetching Data...</p>  
</ngx-spinner>
