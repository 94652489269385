import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApprovalService {

  constructor(private http:HttpClient) { }

  getAllPendingApproval(projectId:Number)
  {
    return this.http.get(environment.baseUrl +'/transactions/api/approvals/pending?projectId=' + projectId);
  }
  getAction(projectId:Number,approvalId:Number)
  {
    return this.http.get(environment.baseUrl +'/transactions/api/approvals/fetchActionOptions?projectId=' + projectId + '&approvalId='+ approvalId) ;
  }
  getAllCompletedApproval(projectId:Number)
  {
    return this.http.get(environment.baseUrl +'/transactions/api/approvals/completed?projectId=' + projectId);
    
  }
  updateApproval(data)
  {
    return this.http.post(environment.baseUrl +'/transactions/api/approvals/update',data);

  }
  }
    
  
  
