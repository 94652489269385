import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ListType } from '../../../contact-setting/models/list-type.model';
import { ReportBuilderServiceService } from '../../services/report-builder-service.service';

@Component({
  selector: 'kt-save-report',
  templateUrl: './save-report.component.html',
  styleUrls: ['./save-report.component.scss']
})
export class SaveReportComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public saveReportForm: FormGroup;
  reportCatalog:boolean;
  dashboard:boolean;
  ExistDashoadList:ListType[]=[];
  public dashBoardValueType: FormControl = new FormControl();
  displayDashboard:boolean;
  reportMenue:boolean;
  reportName:string;
  saveType: string = 'new';
  reportData:any;
  newDashboardLocation:any;
  dashboardId:number[];

  newDashboard:string;

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private SpinnerService: NgxSpinnerService,
    private readonly router: Router,
    public snackBar: MatSnackBar,
    public reportService: ReportBuilderServiceService) { }

  ngOnInit(): void {

    this.initsaveReportForm();
    this.reportCatalog=true;
    this.displayDashboard=false;
    this.reportMenue=false;
    this.getDashboardList();
    this.dashboardId=[];
    if(this.saveType==='update') {
      this.update();
    }
  }

  initsaveReportForm(){
    this.saveReportForm = this.fb.group({
      reportName: ['', Validators.compose([Validators.required])],
      description:[],
      reportCatalog:[{value: '', disabled: true}],
      dashboard:[],
      reportMenue:[],
      ExistDashoad:[],
      newDashboard:[]
     });
  }

  changeDash(val){
    this.dashboardId=val;
    if (val && val.length>0) {
      this.saveReportForm.controls['newDashboard'].disable();
    }else{
      this.saveReportForm.controls['ExistDashoad'].setValue('');
      this.saveReportForm.controls['newDashboard'].enable();
    }
  }

  onChangeDisable(){
    if (this.newDashboard!=null && this.newDashboard != '') {
      this.saveReportForm.controls['ExistDashoad'].disable();
    }else{
      this.saveReportForm.controls['ExistDashoad'].enable();
    }
  }

  getDashboardList(){
    this.reportService.getReportLocation(this.selectedProject).subscribe( data => {
      if (data && data.responseData) {
        this.ExistDashoadList = data.responseData.map((dashboard) => {
          return {
            id: dashboard.id,
            value: dashboard.dashboardName
          }
        });
    }
  });
  }

  checkValue(val){
    if(val == true){
        this.displayDashboard=true;
        this.dashboard=true;
    }else{
        this.displayDashboard=false;
        this.dashboard=false;
    }
  } 

  Close(){
    this.activeModal.close();
    this.saveReportForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.saveReportForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  update() {
    this.SpinnerService.show('saving');
    
    const reportId = +(localStorage.getItem('reportId'));
    const SaveReportData = { 
      "id": reportId,   
      "projectId": this.reportData.projectId,
      "reportType": this.reportData.reportType,
      "entityIds":this.reportData.entityIds,
      "location":this.reportData.location,
      "axisXRowField": this.reportData.axisXRowField,
      "axisYColumnField":this.reportData.axisYColumnField,
      "measureField": this.reportData.measureField,
      "filters": null,
      "reportDesc":this.reportData.reportDesc,
      "reportName": this.reportData.reportName,
      "reportFilters": this.reportData.filters,
      "userFilters": this.reportData.userfilters,
      "dashboardLocation":this.reportData?.dashboardLocation
    }; 
    //console.log('id----'+id);
    console.log(SaveReportData);

    this.reportService.saveReport(SaveReportData).subscribe(
      (result)=>{
        
        this.SpinnerService.hide('saving');
        this.reportData= result['responseData'];

        this.snackBar.open( 'Report saved successfully.', '', {
          duration: 5000,
          panelClass: ['green-snackbar']
        });
        this.router.navigate(['/reports-catalog']);
      },
      error => {
        console.log('error', error);
      }    
    );
    this.activeModal.close();
    this.saveReportForm.reset();
  }

  private getLocationArray() {
    const location=[1];
    this.dashboard && location.push(2);
    this.reportMenue && location.push(3);
    return location;
  }

  Save(){

    this.SpinnerService.show('saving');
    const controls = this.saveReportForm.controls;
    if (this.saveReportForm.invalid) { 
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.SpinnerService.hide('saving');
      return;
    }
    const location = this.getLocationArray();

    this.newDashboardLocation={};
    if(this.dashboard == true){
      var dashboardName=controls.newDashboard.value;
      if(dashboardName == undefined){
            dashboardName= null;
      }
      if(!this.dashboardId || this.dashboardId.length===0){
        this.dashboardId=null;
      }

      if(controls.newDashboard.value?.length > 0) {
        // Check if provided dashboard name is in list.
        const dashboard = this.ExistDashoadList.find((dashboard) => dashboard.value === controls.newDashboard.value?.trim());
        if(dashboard) {
          this.dashboardId = [dashboard.id];
          this.newDashboardLocation=null;
        } else {
          dashboardName = controls.newDashboard.value;
          this.newDashboardLocation={
            "id": null,
            "dashboardName":dashboardName
          };
        }
        
      }
      else{
        this.newDashboardLocation=null;
      }
        
    }

    var name=this.reportData.reportType;
    var id=this.reportData.entityIds;
    const SaveReportData = {    
      "projectId": this.reportData.projectId,
      "reportName": controls.reportName.value,
      "reportDesc":controls.description.value,
      "reportType": this.reportData.reportType,
      "entityIds":this.reportData.entityIds,
      "location":location,
      "axisXRowField": this.reportData.axisXRowField,
      "axisYColumnField":this.reportData.axisYColumnField,
      "measureField": this.reportData.measureField,
      "filters": null,
      "reportFilters": this.reportData.filters,
      "userFilters": this.reportData.userfilters,
      // "dashboardLocation":this.dashboardLocation
      "dashboardList": this.dashboardId,
      "newDashboardLocation": this.newDashboardLocation
    };

    this.reportService.saveReport(SaveReportData).subscribe(
      (result)=>{
        this.SpinnerService.hide('saving');
        this.reportData= result['responseData'];
        
        this.snackBar.open( 'Report copied successfully.', '', {
          duration: 5000,
          panelClass: ['green-snackbar']
        });
        this.activeModal.close();
        this.saveReportForm.reset();
        this.router.navigate(['/reports-catalog']);
        return;
      },
      error => {
        this.SpinnerService.hide('saving');
      }
        
    );
  }


}
