<div class="modal-header">
  <h4 class="modal-title">Advanced Search</h4>
</div>
<div class="modal-body">
  <form [formGroup]="advancedSearchForm" autocomplete="off">
    <div formArrayName="rows">
      <div *ngFor="let row of rowsArray.controls; let i = index" [formGroupName]="i">
        <div class="row">
          <div class="col-md-4">
            <mat-form-field appearance="standard" class="input-100">
              <mat-label>Select entity field</mat-label>
              <mat-select formControlName="entityfield" (selectionChange)='changeEntityField($event.value,i)'>
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                    #entityfields></ngx-mat-select-search>
                </mat-option>
                <ng-container *ngIf="moduleType === 1">
                  <mat-option *ngFor="
                              let field of contactEntityFieldList
                                | search: entityfields.value:'field.attributeName'
                            " [value]="field">
                    {{ field.attributeName }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="moduleType === 2">
                  <mat-option *ngFor="
                              let field of customerEntityFieldList
                                | search: entityfields.value:'attributeName'
                            " [value]="field">
                    {{ field.attributeName }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="moduleType === 3">
                  <mat-option *ngFor="
                              let field of opportunityEntityFieldList
                                | search: entityfields.value:'attributeName'
                            " [value]="field">
                    {{ field.attributeName }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="moduleType === 4">
                  <mat-option *ngFor="
                              let field of productsFieldList
                                | search: entityfields.value:'attributeName'
                            " [value]="field">
                    {{ field.attributeName }}
                  </mat-option>
                </ng-container>
                <ng-container *ngIf="moduleType === 5">
                  <mat-option *ngFor="
                              let field of usersFieldList
                                | search: entityfields.value:'attributeName'
                            " [value]="field">
                    {{ field.attributeName }}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="isControlHasError('entityfield', 'required', i)">Entity field cannot be
                blank</mat-error>
            </mat-form-field>
          </div>
          <!-- select operator -->
          <div class="col-md-4">
            <mat-form-field appearance="standard" class="input-100">
              <mat-label>Select operator</mat-label>
              <mat-select formControlName="operator" (selectionChange)="changeOperator($event.value,  i)">
                <mat-option>
                  <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                    #operator></ngx-mat-select-search>
                </mat-option>
                <mat-option *ngFor="
                        let operator of selectedFormArrayEntitiesAndDataList[i].operatorList
                          | search: operator.value:'value'
                      " [value]="operator.key">
                  {{ operator.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="isControlHasError('operator', 'required', i)">Operator cannot be
                blank</mat-error>
            </mat-form-field>
          </div>
          <!-- select value -->
          <div class="col-md-4">
            <div class="row">
              <div
                *ngIf="selectedFormArrayEntitiesAndDataList[i].attributeTypeId==6 || selectedFormArrayEntitiesAndDataList[i].attributeTypeId==8 || selectedFormArrayEntitiesAndDataList[i].attributeTypeId==9" class="col-md-12">
                <mat-form-field appearance="standard" class="input-100">
                  <mat-label>Select value</mat-label>
                  <input matInput formControlName="value" ktAutoTrimSpaces>
                  <mat-icon matSuffix>abc</mat-icon>
                  <mat-error *ngIf="isControlHasError('value', 'required', i)">Value cannot be
                    blank</mat-error>
                </mat-form-field> 
              </div>
              <div
                *ngIf="selectedFormArrayEntitiesAndDataList[i].attributeTypeId==1 || selectedFormArrayEntitiesAndDataList[i].attributeTypeId==2 || selectedFormArrayEntitiesAndDataList[i].attributeTypeId==18" class="col-md-12">
                <mat-form-field appearance="standard" class="input-100">
                  <mat-label>Select value</mat-label>
                  <mat-select formControlName="value" multiple>
                    <mat-option>
                      <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                        #value></ngx-mat-select-search>
                    </mat-option>
                    <mat-option *ngFor="let val of this.selectedFormArrayEntitiesAndDataList[i].fieldValues
                    | search: value.value:'name' " [value]="val.id">
                      {{ val.name }}
                    </mat-option>
                  </mat-select>
                  <mat-error *ngIf="isControlHasError('value', 'required', i)">Value cannot be
                    blank</mat-error>
                </mat-form-field>
              </div>
              <div *ngIf="selectedFormArrayEntitiesAndDataList[i].attributeTypeId==7" class="col-md-12">
                <div *ngIf="!numericBetween">
                  <mat-form-field appearance="standard" class="input-100">
                    <mat-label>Select value</mat-label>
                    <input matInput type="text" formControlName="value" (keypress)="keyPressNumbersWithDecimal($event)" />
                    <mat-icon matSuffix>123</mat-icon>
                    <mat-error *ngIf="isControlHasError('value', 'required', i)">Value cannot be
                      blank</mat-error>
                  </mat-form-field>
                </div>
                <div *ngIf="numericBetween">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="standard" class="input-100">
                        <mat-label>From</mat-label>
                        <input matInput type="text" formControlName="fromvalue" (keypress)="keyPressNumbersWithDecimal($event)" />
                        <mat-icon matSuffix>123</mat-icon>
                        <mat-error *ngIf="isControlHasError('fromvalue', 'required', i)">from value cannot be blank</mat-error>
                      </mat-form-field>
                      
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="standard" class="input-100">
                        <mat-label>To</mat-label>
                        <input matInput type="text" formControlName="tovalue" (keypress)="keyPressNumbersWithDecimal($event)" />
                        <mat-icon matSuffix>123</mat-icon>
                        <mat-error *ngIf="isControlHasError('fromvalue', 'required', i)">to value cannot be blank</mat-error>
                      </mat-form-field>
                      
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedFormArrayEntitiesAndDataList[i].attributeTypeId==3">
                <div *ngIf="!dateBetween">
                  <mat-form-field appearance="standard" class="input-100">
                    <mat-label>Select value</mat-label>
                    <input matInput readonly formControlName="value" [matDatepicker]="picker">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-error *ngIf="isControlHasError('value', 'required', i)">Value cannot be
                      blank</mat-error>
                  </mat-form-field>
                  
                </div>
                <div *ngIf="dateBetween" class="col-md-12">
                  <div class="row">
                    <div class="col-md-6">
                      <mat-form-field appearance="standard" class="input-100">
                        <mat-label>From</mat-label>
                        <input matInput readonly formControlName="fromvalue" [matDatepicker]="picker1">
                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                        <mat-datepicker #picker1></mat-datepicker>
                        <mat-error *ngIf="isControlHasError('fromvalue', 'required', i)">from value cannot be blank</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field appearance="standard" class="input-100">
                        <mat-label>To</mat-label>
                        <input matInput readonly formControlName="tovalue" [matDatepicker]="picker2">
                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                        <mat-datepicker #picker2></mat-datepicker>
                        <mat-error *ngIf="isControlHasError('tovalue', 'required', i)">to value cannot be blank</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add-del-btn">
          <div *ngIf="i === lastRowIndex && i!==0" style="float: right;">
            <button class="btn btn-xs btn-icon" (click)="removeRow(i)">
              <i class="fa fa-minus"></i>
            </button>
          </div>
          <div *ngIf="i === lastRowIndex" style="float: right; padding-right: 10px;">
            <button class="btn btn-xs btn-icon" (click)="addNewRow()">
              <i class="fa fa-plus"></i>
            </button>
          </div>
          <div *ngIf="i !== lastRowIndex">
            <span class="and-b">
              <button mat-button> AND </button>
            </span>
            <div style="float: right; margin-top: 0.75%;">
              <button class="btn btn-xs btn-icon" (click)="removeRow(i)">
                <i class="fa fa-minus"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
  <ngx-spinner
      bdColor="rgba(0, 0, 0, 0.5)"
      size="default"
      type="ball-spin-clockwise"
      name="fetchFields"
    >
      <p style="color: white">Loading...</p>
    </ngx-spinner>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="closeModal()">
    Cancel
  </button>
  <button type="button" class="btn btn-secondary" (click)="clearForm()">
    Clear
  </button>
  <button type="button" class="btn btn-primary" [disabled]='disabled' (click)="onApplyFilters()">
    Apply
  </button>
</div>