import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import { RelationService } from '../../../company-configuration/_subs/services/relation.service';
import { CustomAttibute } from 'src/app/views/pages/contact-setting/models/custom-attibute.model';
import { CompanyService } from '../../../company-configuration/_subs/services/company.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DeleteRelationDialogComponent } from '../_subs/delete-relation-dialog/delete-relation-dialog.component';
import { EditRelationDialogComponent } from '../_subs/edit-relation-dialog/edit-relation-dialog.component';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { EventEmitterService } from '../../services/event-emitter.service';
import { exit } from 'process';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { FieldService } from '../../services/field.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-relationship-dialog',
  templateUrl: './relationship-dialog.component.html',
  styleUrls: ['./relationship-dialog.component.scss']
})
export class RelationshipDialogComponent implements OnInit {
  errorMes:String="";
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  editCustomVal: CustomAttibute;

  relationshipForm:FormGroup;
  
  resultCustom=[];
  filterCustom=[];

  selectedLookupValues=[];

  parentLookupValues=[];

  preSearchparentLookupValues=[];

  selectedParent:string="";

  finalResult=[];

  disabled : BehaviorSubject<Boolean>=new BehaviorSubject<Boolean>(false);

  options= [];

  optionsM:Number;

  current_selected_dependent: string;

  current_selected_parent: string;

  ifSingle:boolean=false;

  isEdit:boolean=false;

  showDeleteButton:boolean=false;

  isLinkedFieldRelaion:boolean=true;

  removedChildRelationsMap = new Map();

  isDisabled:boolean = false;
  modifiedParentLooksIds = new Set();

  public parentFieldFilter: FormControl = new FormControl();

  constructor( private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private _rs:RelationService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public _field: FieldService,
    private companyService: CompanyService,
    private eventEmitterService: EventEmitterService,
    private _cs:CustomAttributesService) {
      
  }

  ngOnInit(): void {
    this.relationshipForm = this.fb.group({
      parentField: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])
      ],
      dependentField:[{value:this.editCustomVal.attributeName,disabled: true}, Validators.compose([
        Validators.required,
        Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
      ]),
      ],
      selectedOptions:[],
      selectedParent:[],
      searchDep:[],
      searchParent:[]

     
    });

    this.setLookupFieldValue(this.editCustomVal.id);

     //get relations
    this._rs.getFieldRelation(this.editCustomVal['moduleId'],this.editCustomVal.id, this.selectedProject).subscribe(
      res=>{

        //if we have data 
        if(res['responseData']){
          //determine if add or rdit module

          this.isLinkedFieldRelaion = res['responseData']?.isLinkedFieldRelaion;
          if(this.isLinkedFieldRelaion){
            this.relationshipForm.controls.parentField.disable();
            this.relationshipForm.controls.selectedOptions.disable();
          }

          this.isEdit=true;
          this.showDeleteButton=true;

          this.resultCustom.push(res['responseData'].parentField);
          this.selectedParent=res['responseData'].parentField.attributeName;
          this.parentLookupValues= res['responseData'].parentField.lookupValues;
          this.preSearchparentLookupValues= res['responseData'].parentField.lookupValues;
          this.relationshipForm.controls['parentField'].setValue(res['responseData'].parentField);

          //mapping 
         // var mapping=res['responseData'].lookupValuesRelation[0];
         var mapping: any; 
         var selectedParentData = res['responseData'].lookupValuesRelation;
         for (let key in selectedParentData) {
           if(selectedParentData[key].childLookupValues.length > 0){
                mapping=selectedParentData[key];
                break;
              }
         }

         if(mapping == undefined){
            mapping = selectedParentData[0];
         }
          
          this.relationshipForm.controls['selectedParent'].setValue([mapping.parentLookupId]);
         
          mapping.childLookupValues.forEach(res=>{
            if(res.childAttributeId ==this.editCustomVal.id && res.childLookupValueId==null){
              this.optionsM=1;
            }else{
              this.options.push(res.childLookupValueId);
            }
          })
          if(this.options.length == this.selectedLookupValues.length){
            this.options.push(-1);
          }
         
          if(this.ifSingle){
            this.relationshipForm.controls['selectedOptions'].setValue(this.options);       
          }else{
            
             this.relationshipForm.controls['selectedOptions'].setValue(this.optionsM); 
          }         
         
          //creating whole data
          res['responseData'].lookupValuesRelation.forEach(res=>{
          
            res['children']=res['childLookupValues'];
            delete(res['childLookupValues']);
            res['children'].forEach(res2=>{
              res2['isActive']=1;
            })
          })

          this.finalResult=res['responseData'].lookupValuesRelation;
          
        }else{  
          this.isEdit=false;    
          this.showDeleteButton=false;
          this.isLinkedFieldRelaion = false;
          this.getCustom();
        }
      }
    )
  }

  getCustom(){
    this._cs.getAllCustomAttributes(this.editCustomVal['moduleId'], this.selectedProject).subscribe(
      result=>{
        result['responseData'].forEach(row=>{
          if(row.attributeType.typeName=="Single Select Dropdown" && row.id !=this.editCustomVal.id 
             && row.status == 1)
          {
            // this next set of conditions will eliminate linked field of same entity by comparing their linkedAttributeModuleId, for activity modules only
            if(row.linkedAttributeModuleId!==null){
              if(this.editCustomVal.linkedAttributeModuleId!==row.linkedAttributeModuleId){
                this.filterCustom.push(row);
              }
            }else{
              this.filterCustom.push(row);
            }
          }
        })

        const data = this.filterCustom;
        data.forEach(row=>{
          var result = this.filterParent(data, row.parentAttributeId);
          if(result == true)
          {
            this.resultCustom.push(row);
           if(this.editCustomVal.systemAttribute?.id>6 && this.editCustomVal.systemAttribute?.id<10){
            this.resultCustom = this.resultCustom.filter(res => res.mandatoryFlag == 1);
           }
          }
        })

      },
      error => {
      }
    )  
  }

  filterParent(data, parent){
    var result = true;
    for(var i=0; i < data.length; i++){
        if(this.editCustomVal.hasChildAttribute == false){
          result = true;
          break;
        }
        if(parent == 0){
          result = true;
          break;
        }
        if(data[i].id == parent){
          result = false;
          break;
        }
        if(this.editCustomVal.id == parent){
          result = false;
          break;
        }
    }
    return result;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.relationshipForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  showSelectedLookupValues(){

    if(this.editCustomVal.attributeType.typeName=="Single Select Dropdown" || this.editCustomVal.attributeType.typeName=="Multi Select Dropdown"){
      this.ifSingle=true;
      this.selectedLookupValues= this.editCustomVal.lookupValues;
    }else{
      this.ifSingle=false;
    }
   
  }

   //Brings lookup data based on attributeId
   setLookupFieldValue(attribId: number){
    this._field.getAttribLookup(attribId).subscribe(
      (row) => {   
     if(row['statusCode']== 200){  
          this.editCustomVal.lookupValues = row['responseData'];
        }
        this.showSelectedLookupValues();
      },
      (error) => {
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  change(data){
    this.finalResult=[];
    this.selectedParent=this.relationshipForm.controls.parentField.value.attributeName;
    this.parentLookupValues= data.lookupValues;
    
    this.preSearchparentLookupValues= data.lookupValues;

  }

  onSelectedParent(lookup){
    this.options=[];
    this.optionsM=0;

    var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(lookup);
    if(removeIndex > -1){
      //pre select values
      var lookups=this.finalResult[removeIndex].children;
    
      if(lookups.length>0){
  
        lookups.forEach(res=>{

          if(res.childAttributeId ==this.editCustomVal.id && res.childLookupValueId==null){
            this.optionsM=1;
          }else{
            this.options.push(res.childLookupValueId);
          }
        })
        if(this.options.length == this.selectedLookupValues.length){
          this.options.push(-1);
        }
      
        if(this.ifSingle){
          this.relationshipForm.controls['selectedOptions'].setValue(this.options);       
        }else{
          
           this.relationshipForm.controls['selectedOptions'].setValue(this.optionsM); 
        } 
      
        
      }else{
        if(this.ifSingle){
          this.relationshipForm.controls['selectedOptions'].setValue([]);       
        }else{
          
          this.relationshipForm.controls['selectedOptions'].setValue(''); 
        } 
      }      

    }else{
      
      if(this.ifSingle){
        this.relationshipForm.controls['selectedOptions'].setValue([]);       
      }else{
        
        this.relationshipForm.controls['selectedOptions'].setValue(''); 
      }  

    }
   }

  selectAllOptions(lookup) {
    const selectAllOption = this.selectedLookupValues.map(lookup => lookup.id);

    const allOptions = lookup.source.options._results;
    const values = this.relationshipForm.controls['selectedOptions']?.value;
    if(!lookup.option._selected){
      this.relationshipForm.controls['selectedOptions'].setValue([])
    }
    allOptions.forEach(option=>{
      if(option.value !=-1 && lookup.option._selected && !option._selected){
        
        const newloopkup = {...lookup};
        option._selected =true;
        newloopkup.option = option;
        try {
          this.onSelectedDependent(newloopkup);
        } catch (error) {

          console.error('An error occurred:', error.message);
        }
        
        values.push(option.value);


      }else if(option.value !=-1 && !lookup.option._selected ){
        
        const newloopkup = {...lookup};
        option._selected = false;
        newloopkup.option = option;
        try {
          this.onSelectedDependent(newloopkup);
        } catch (error) {
          console.error('An error occurred:', error.message);
        }

      }

    })
    if(lookup.option._selected){
      this.relationshipForm.controls['selectedOptions'].setValue(values);
    }
  }

  onSelectedDependent(lookup){
      
      if(lookup.option.value== -1) {
        this.selectAllOptions(lookup) 
        return;
      }
      if(!lookup.option._selected){
        const values = this.relationshipForm.controls['selectedOptions']?.value;
        this.relationshipForm.controls['selectedOptions'].setValue(values.filter((value) => value !== -1));
      }
      this.current_selected_dependent = lookup.option.value;
      this.current_selected_parent=this.relationshipForm.controls['selectedParent'].value[0];
      this.modifiedParentLooksIds.add(this.current_selected_parent)
      var childData=[];
      var childObject= {
        "childAttributeId": this.editCustomVal.id,
        "childLookupValueId": this.current_selected_dependent,
        "isActive": 1
      };
      childData.push(
        {
          "childAttributeId": this.editCustomVal.id,
          "childLookupValueId": this.current_selected_dependent,
          "isActive": 1
        }
      )
   
      var data={
        "attributeId": this.relationshipForm.controls['parentField'].value.id,
        "parentLookupId": this.current_selected_parent,
        "children": childData,
        "projectId":this.selectedProject
      };

      //earch fpr existing data
      var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(this.current_selected_parent);
        if(removeIndex > -1){
          //if exist
          this.finalResult[removeIndex];

          if(this.finalResult[removeIndex].children.length>0){

            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childLookupValueId; }).indexOf(this.current_selected_dependent);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.option._selected){
                if(this.isEdit){
                  this.finalResult[removeIndex].children[removeIndex2].isActive=0;
                  this.addInactiveChildToMap(removeIndex, removeIndex2);
                }else{
                  this.finalResult[removeIndex].children.splice(removeIndex2, 1);
                }
              }else{
                if(this.isEdit){
                  this.finalResult[removeIndex].children[removeIndex2].isActive=1;
                }else{
                }
              }

            }else{
              if(lookup.option._selected){
                //if true
                let childObj = this.getChildToReactivateFromMap(false);
                childObj!==null ? childObject = childObj : '';
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
         
          }else{
            if(lookup.option._selected){
              //if true
              let childObj = this.getChildToReactivateFromMap(false);
              if(childObj!=null){
                childObject = childObj;
              }
              this.finalResult[removeIndex].children.push(childObject);
            }
          }
          
        }else{
          if(lookup.option._selected){
            //if true
            this.finalResult.push(data);
          }
        }

        if(this.isEdit){
          this.finalResult.forEach(res=>{
            res['attributeId']= this.relationshipForm.controls['parentField'].value.id
          })
        }
    
  }

  addInactiveChildToMap(removeIndex, removeIndex2){
    let arr = [];
    if(this.removedChildRelationsMap.has(this.current_selected_parent)){
      arr = this.removedChildRelationsMap.get(this.current_selected_parent);
    }
    arr.push(this.finalResult[removeIndex].children[removeIndex2]);
    this.removedChildRelationsMap.set(this.current_selected_parent, arr);
    
    this.finalResult[removeIndex].children.splice(removeIndex2, 1);
  }

  getChildToReactivateFromMap(onSelectedMandatoryCalled:boolean){
    if(this.removedChildRelationsMap.has(this.current_selected_parent)){
      let arr = this.removedChildRelationsMap.get(this.current_selected_parent);

      let index = onSelectedMandatoryCalled?arr.findIndex(x => x.childAttributeId==this.editCustomVal.id):arr.findIndex(x => x.childLookupValueId==this.current_selected_dependent);

      if(index > -1){
        let reactivatedChild = arr[index];
        reactivatedChild.isActive=1;
        arr.splice(index, 1);
        return reactivatedChild;
      }
    }
    return null;
  }

  pushInactiveChildrenToArray(){
    this.finalResult.forEach(x => {
      if(this.removedChildRelationsMap.has(x.parentLookupId)){
        let inactiveChildren = this.removedChildRelationsMap.get(x.parentLookupId);
        x.children.push(...inactiveChildren);
      }
      this.removedChildRelationsMap.delete(x.parentLookupId);
      // adding project id
      x['projectId'] = this.selectedProject;
      // adding attribbute id to each element
      // x['attributeId']= this.relationshipForm.controls['parentField'].value.id;
    });
  }

  onSelectedMandatory(lookup){ 

    this.current_selected_parent=this.relationshipForm.controls['selectedParent'].value[0];

    var childData=[];
    var childObject= {
      "childAttributeId": this.editCustomVal.id,
      "childLookupValueId": null,
      "isActive": 1
    };
    childData.push(
      {
        "childAttributeId": this.editCustomVal.id,
        "childLookupValueId": null,
        "isActive": 1
      }
    )
          
    
    var data={
      "attributeId": this.relationshipForm.controls['parentField'].value.id,
      "parentLookupId": this.current_selected_parent,
      "children": childData,
      "projectId": this.selectedProject
    };

    //earch fpr existing data
    var removeIndex = this.finalResult.map(function(item) { return item.parentLookupId; }).indexOf(this.current_selected_parent);
      if(removeIndex > -1){
        //if exist
        this.finalResult[removeIndex];

        if(this.finalResult[removeIndex].children.length>0){

          if(this.isEdit){
            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childAttributeId; }).indexOf(this.editCustomVal.id);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.source._checked){
                this.finalResult[removeIndex].children[removeIndex2].isActive=0;
                 this.addInactiveChildToMap(removeIndex, removeIndex2);
              }else{
                let childObj = this.getChildToReactivateFromMap(true);
                if(childObj!=null){
                  childObject = childObj;
                }
                this.finalResult[removeIndex].children.push(childObject);
              }

            }else{
              if(lookup.source._checked){
                //if true
                let childObj = this.getChildToReactivateFromMap(true);
                if(childObj!=null){
                  childObject = childObj;
                }
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
          }else{
            var removeIndex2 = this.finalResult[removeIndex].children.map(function(item) { return item.childAttributeId; }).indexOf(this.editCustomVal.id);
                  
            if(removeIndex2 >-1){
              //exist 
              if(!lookup.source._checked){
                if(this.isEdit){
                  this.finalResult[removeIndex].children[removeIndex2].isActive=0;
                  this.addInactiveChildToMap(removeIndex, removeIndex2);
                }else{
                  this.finalResult[removeIndex].children.splice(removeIndex2, 1);
                }
              }else{
                if(this.isEdit){
                let childObj = this.getChildToReactivateFromMap(true);
                childObject = childObj!==null ? childObj : '';
                this.finalResult[removeIndex].children.push(childObject);
                }else{
                }
              }

            }else{
              if(lookup.source._checked){
                //if true
                let childObj = this.getChildToReactivateFromMap(true);
                childObject = childObj!==null ? childObj : '';
                this.finalResult[removeIndex].children.push(childObject);
              }
            }
          }
       
        }else{
          if(lookup.source._checked){
            //if true
            if(this.isEdit){
              let childObj = this.getChildToReactivateFromMap(true);
              if(childObj!=null){
                childObject = childObj;
              }
              this.finalResult[removeIndex].children.push(childObject);
            }
            else{
              this.finalResult[removeIndex].children.push(childObject);
            }
            
          }
        }
      }else{
        if(lookup.source._checked){
          //if true
          this.finalResult.push(data);
        }
      }

      if(this.isEdit){
        this.finalResult.forEach(res=>{
          res['attributeId']= this.relationshipForm.controls['parentField'].value.id;
        })
      }
  
}

  Close(){
    this.activeModal.close();
    this.relationshipForm.reset();
  }

  Save(){

  //for opp status/winProbablity/value
    if(!this.isDependentFieldValid()){
      return;
    }  

    if(this.isEdit){
      //modal
      const ref = this.dialog.open(EditRelationDialogComponent, 
        { 
          width: '600px',
          data: {}
        }
      );

      ref.afterClosed().subscribe(result => {
        if(result){
          this.saveData();
        }
      });

    }else{
      //no modal
      this.saveData();

    }
    
  }

  saveData(){
    this.isDisabled = true;
    const controls = this.relationshipForm.controls;

    if (this.relationshipForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.isDisabled = false;
      return;
    }
    this.pushInactiveChildrenToArray();
    if(this.editCustomVal['moduleId'] == 6){
      this.finalResult = this.finalResult.filter(obj =>
        this.modifiedParentLooksIds.has(obj.parentLookupId)
      );
    }
    this._rs.addRelation(this.finalResult).subscribe(
      res=>{
        this.activeModal.close('RELATIONSHIP');
        this.eventEmitterService.onModalCloseResult('RELATIONSHIP');
      },
      error => {
        this.isDisabled = false;
        if (error && error.error && error.error.responseData === "Index 0 out of bounds for length 0") {
          this.snackBar.open('Please mark the Make Dependent checkbox.', '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
        } else {
          this.snackBar.open('Unable to add the relation. Please try again.', '', {
            duration: 2000,
            panelClass: ['red-snackbar']
          });
        }
      }
    )
  }

  private isDependentFieldValid(): boolean {
    if (this.editCustomVal.systemAttribute?.id>6 && this.editCustomVal.systemAttribute?.id<10) {
      // Create a set of all parentLookupIds that have children
      const parentLookupIdsWithChildren = new Set(this.finalResult.filter(data => data.children?.length > 0 && data.children.some(child => child.childAttributeId === this.editCustomVal.id)).map(data => data.parentLookupId));
  
      // Check if all parentLookupValues with children have a corresponding entry in parentLookupIdsWithChildren
      const isDependentFieldChecked = this.parentLookupValues.every(data => parentLookupIdsWithChildren.has(data.id));
  
      if (!isDependentFieldChecked) {
        // Display an alert if all parent field values are not mapped to dependent field values
        const initialNotice = 'All the parent field value should be mapped to dependent field value';
        this.dialog.open(AlertDialogComponent, {
          width: '600px',
          position: { top: '20px' },
          data: { title: 'Alert', body: initialNotice }
        });
        return false;
      }
    }
  
    return true;
  }

  searchThisForParent(){
    var key= this.relationshipForm.controls['searchParent'].value;
    if(key){
      this.parentLookupValues= this.parentLookupValues.filter(res => res['lookupValue'].toLowerCase().indexOf(key) > -1)
       
    }else{
      this.parentLookupValues=this.preSearchparentLookupValues;
    }
  }

  searchThisForDep(){
    var key= this.relationshipForm.controls['searchDep'].value;
    if(key){
      this.selectedLookupValues= this.selectedLookupValues.filter(res => res['lookupValue'].toLowerCase().indexOf(key) > -1)
      
    }else{
      this.showSelectedLookupValues();
    }
  }

  delete(){

    const ref = this.dialog.open(DeleteRelationDialogComponent, {
      width: '600px',
      position: {top: '20px'},
      data: {}
    });

    ref.afterClosed().subscribe(result => {
      if(result){
        var data=[{
          "attributeId": this.editCustomVal.id         
          
        }];   

        this._rs.deleteRelation(data).subscribe(
          res=>{
      
            this.activeModal.close('RELATIONSHIP');
            this.eventEmitterService.onModalCloseResult('RELATIONSHIP');
          }
        )


      }
    });

  } 
  

}
