<div class="example-preview">
    <div>
        <form [formGroup]="editOpportunityForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Edit Opportunity Status</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Opportunity Status</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput #input maxlength="50" formControlName="statusName" [(ngModel)]="statusName">
                            <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
                            <mat-error *ngIf="isControlHasError('statusName', 'required')">Opportunity Status is required</mat-error>
                            <mat-error *ngIf="isControlHasError('statusName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                            <mat-icon *ngIf="isControlHasError('statusName', 'required') == false;" matSuffix [ngStyle]="{'color':'blue'}"></mat-icon>              
                        </mat-form-field>                    
                    </div>
                </div>               

                <div class="form-group row">
                    <div class="col-md-4">
                        <label>This Status Means Opportunity is</label>
                    </div>
                    <div class="col-md-8">
                        <mat-radio-group [labelPosition]="labelPosition" formControlName="statusValue"   [(ngModel)]="statusValue" >
                            <mat-radio-button  value="1">In Process</mat-radio-button>
                            <mat-radio-button  value="2">Closed Won</mat-radio-button>
                            <mat-radio-button  value="3">Closed Lost</mat-radio-button>                          
                        </mat-radio-group>
                        <br>
                        <mat-error *ngIf="isControlHasError('statusValue', 'required')">Please select one.</mat-error>
                    </div>
                </div>


                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Status</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select [(value)]="selectedValue">
                                <mat-option value="1">Active</mat-option>
                                <mat-option value="0">Inactive</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
  
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"   (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]="!editOpportunityForm.valid" (click)="Edit()">Save</button>
            </div>
        </form> 
    </div>
</div>  