<div class="d-flex flex-column flex-root" id="kt_login_wrapper">
	<div class="login login-3 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white" 
		id="kt_login" *ngIf="registerView == 'login'">
		<!--begin::Aside-->
		<div class="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
			style="background: #4FA2E3 url(./assets/media/bg/channelplay-1.png) center no-repeat;">
			<!--begin: Aside Container-->
			<div class="d-flex flex-row-fluid flex-column justify-content-between">
				
			</div>

		</div>
		<!--begin::Aside-->

		<!--begin::Content-->
		<div class="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden">
			<!--begin::Content header-->
			<div class="top-0 text-center mt-0 mb-0 flex-column-auto justify-content-center py-5 px-10">
			<a href="https://www.getassistive.com/" target="_blank" class="flex-column-auto mt-5">
				<img src="./assets/media/logos/assistive_logo.png" class="max-h-70px" />
			</a>
				
			</div>
			<!--end::Content header-->
			
			<router-outlet></router-outlet>
			
		</div>
		<!--end::Content-->
	</div>

	<div class="d-flex flex-column flex-root p-5" *ngIf="registerView == 'report'">
		<!--begin::Report-->
		<router-outlet></router-outlet>
		<!--end::Report-->
	</div>
</div>