import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder,Validators, Form } from '@angular/forms';
import {NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { OpportunityStatusService } from '../../../services/opportunity-status.service';
import { tap, takeUntil, finalize } from 'rxjs/operators';
import { Subject, BehaviorSubject } from 'rxjs';

@Component({
  selector: 'kt-add-opportunity-status-dialog',
  templateUrl: './add-opportunity-status-dialog.component.html',
  styleUrls: ['./add-opportunity-status-dialog.component.scss']
})
export class AddOpportunityStatusDialogComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:String="";
  addOpportunityForm:FormGroup;
  labelPosition: string = "after";
  private unsubscribe: Subject<any>;
  
  loading = false;

  disabled : BehaviorSubject<Boolean>=new BehaviorSubject<Boolean>(false);

  innerhtml:HTMLElement;
  constructor( private fb: FormBuilder,public activeModal: NgbActiveModal, private _os:OpportunityStatusService) { }

  ngOnInit(): void {
    this.addOpportunityForm = this.fb.group({
      statusName: ['', Validators.compose([
          Validators.required,
          Validators.maxLength(50) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
        ])
      ],
      statusValue: ['', Validators.compose([
          Validators.required        
        ])
      ]
     });
  }

  Close(){
    this.activeModal.close();
    this.addOpportunityForm.reset();
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
      const control = this.addOpportunityForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Save(){
    this.disabled.next(true);
    const controls = this.addOpportunityForm.controls;
      if (this.addOpportunityForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled.next(false);
        return;
      }
      const addData = {
        'opportunityStatus':controls.statusName.value,
        'stage':controls.statusValue.value,
        "projectId": this.selectedProject,     
        "winProbability": 0.27,
        "status": 1        
      };
    
      this._os.addOpportunityStatus(addData).subscribe(
        data => {
            this.loading = false;
            this.activeModal.close();
            this.addOpportunityForm.reset();
        },
        error =>{
          
          this.errorMes=error.error.responseData;
          this.disabled.next(false);
        }
      );
  }

  SaveAndAddNew(){
    this.disabled.next(true);
    const controls = this.addOpportunityForm.controls;
      if (this.addOpportunityForm.invalid) {
        Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled.next(false);
        return;
      }
      const addData = {
        'opportunityStatus':controls.statusName.value,
        'stage':controls.statusValue.value,
        "projectId": this.selectedProject,     
        "winProbability": 0.27,
        "status": 1        
      };
    
      this._os.addOpportunityStatus(addData).subscribe(
        data => {
            this.loading = false;
            this.addOpportunityForm.reset();
        },
        error =>{
          this.errorMes=error.error.responseData;
          this.disabled.next(false);
        }
          
        );
  }

  onDynamicInputField(data){
    console.log(data);
    let row = document.createElement('div');   
      row.className = 'row'; 
      row.innerHTML = data; 
      document.querySelector('.showInputField').appendChild(row); 
  }

}
