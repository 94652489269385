import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {LicenseManager} from 'ag-grid-enterprise';


//LicenseManager.setLicenseKey('For_Trialing_ag-Grid_Only-Not_For_Real_Development_Or_Production_Projects-Valid_Until-24_October_2020_[v2]_MTYwMzQ5NDAwMDAwMA==ef5f6946fff5bf632125c41a235517db');

LicenseManager.setLicenseKey('CompanyName=Channel Play,LicensedApplication=1ChannelCRM,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=1,AssetReference=AG-016945,ExpiryDate=29_June_2022_[v2]_MTY1NjQ1NzIwMDAwMA==47e0c5fb0f631f9b58906d032f26c9ae');

if (environment.production) {  
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
