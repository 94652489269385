import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { SubheaderService } from '../../../../../../../../app/core/_base/layout';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { EditCompaniesDialogComponent } from '../../../../../shared/custom-edit/edit-companies-dialog/edit-companies-dialog.component';
import { DialerDashboardService } from 'src/app/views/pages/dialer-dashboard/Services/DialerDashboardService.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'kt-widget4-company',
  templateUrl: './widget4-company.component.html',
  styleUrls: ['./widget4-company.component.scss']
})
export class Widget4CompanyComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;
  @Input() public optionalId;
  @Output() companyChildEvent = new EventEmitter<any>();
  @Input() dialerFlag = false;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('singularName'));
  headingName = 'Customer';
  companyInfo: any;
  companyName: string;
  serialNo:string;
  companyType: string;
  customAttributes: [];
  errorMessage: string;
  isError = false;
  private subscriptions: Subscription[] = [];

  public isCollapsed = false;

  constructor(
    private _cds: CompanyDashboardService,
    private SpinnerService: NgxSpinnerService,
    private subheaderService: SubheaderService,
    private eventEmitterService: EventEmitterService,
    private ds:DialerDashboardService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-company');
    if(this.parentData){
      this.fetchCompanyInfo(this.selectedProject, this.parentData);
    }
    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.companyResult(data);
      });
    }
    this.subscriptions.push(this.ds.entityId$.subscribe((entityId) => {
      if(entityId){
        this.parentData=entityId;
        this.companyResult("SUCCESS");
      }
      })
    )

    if(this.tabHeading['customers'])
      this.headingName = this.tabHeading['customers'];
      
  }

  fetchCompanyInfo(projectId: number, companyId: number){
    const id = this.optionalId ? this.optionalId : this.parentData;
    this.isError = false;
    this.errorMessage = "";
    this._cds.getCompanyInfo(projectId, id).subscribe(
      res=>{
        this.companyInfo = res['responseData'];
        this.companyChildEvent.emit(this.companyInfo);
        this.subheaderService.setTitle(this.companyInfo.name + "-" +this.companyInfo.serialNo );
        this.companyName = this.companyInfo.name;
        this.serialNo=this.companyInfo.serialNo;
        this.customAttributes = this.companyInfo.customAttributeValues;
        this.SpinnerService.hide('dashboard-company');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-company');
      }
    )
  }

  editCompany() {
    const _saveMessage = `Opportunity  has been modified.`;
   	const ref = this.modalService.open(EditCompaniesDialogComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );
    
    ref.componentInstance.moduleType=2;
    ref.componentInstance.flag='company';
    ref.componentInstance.editId = this.parentData !== undefined ? this.parentData : this.optionalId;
    ref.componentInstance.activityFlag=false;
    ref.componentInstance.isEditAllowed= this.dialerFlag ? false : true;

    ref.result.then((result) => {
      this.companyResult(result);
    });
    
  }

  companyResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-company');
      this.fetchCompanyInfo(this.selectedProject, this.parentData);
    }
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
