// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { ContactPartialsModule } from './contact-partials/contact-partials.module';
import { ContactDashboardComponent } from './contact-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    ContactPartialsModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: ContactDashboardComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbProgressbarModule
  ],
  providers: [],
  declarations: [
    ContactDashboardComponent,
  ],
  entryComponents:[]
})
export class ContactDashboardModule { }
