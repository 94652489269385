import { Component, OnInit,ChangeDetectionStrategy } from '@angular/core';
import { environment} from '../../../../../environments/environment';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ImageViewerComponent } from'../image-viewer/image-viewer.component';

@Component({
  selector: 'kt-attachment-renderrer',
  templateUrl: './attachment-renderrer.component.html',
  styleUrls: ['./attachment-renderrer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AttachmentRenderrerComponent implements OnInit {

  params:any;
  paramData: any;
  index: number;
  paramDataArray : any;
  fieldType: number;
  basePath: string;
  downloadUrl: string;
  displayFlag:boolean = false;
  viewFlag:boolean;

  constructor(private modalService: NgbModal) {    
  }

  agInit(params){
    this.basePath = environment.imageUrl;
    
    this.params = params;
    this.paramData = this.params.data;

    if(params.isUserModuleOrDetailedActRep){
      // special case for user module and detailed activity report only ============>
      console.log(this.params.customFieldType);
      if(this.params.customFieldType == 4){
        this.viewFlag = true;
      }  else{
        this.downloadUrl = this.params.value?.split(",").map(x =>  x.trim()==''? '': this.basePath + x);
        this.viewFlag = false;
      }
      
      if(this.params.value && this.params.value !== ""){
        this.displayFlag = true;
      } else {
        this.displayFlag = false;
      }
//  <============ END::special case for user module and detailed activity report only
    }else if(params.isCallLogComponent) {
      this.index = this.params.index;

      this.downloadUrl = this.paramData[this.index];
      this.viewFlag = false;
    
      if(this.paramData[this.index] !== "" && this.paramData[this.index] !== null && this.paramData[this.index] !== undefined){
       this.displayFlag = true;
      } else {
        this.displayFlag = false;
      }
    }else{
      // for any other modules ============>      
      this.index = this.params.index;
      this.paramDataArray = this.paramData.data[this.index];
    

      this.downloadUrl = this.basePath + this.paramDataArray.customFieldValue;

      if(this.paramDataArray.customFieldType == 4)
        this.viewFlag = true;
      else
        this.viewFlag = false;
    
      if(this.paramDataArray.customFieldValue !== ""){
       this.displayFlag = true;
      } else {
        this.displayFlag = false;
      }
//  <============ END::for any other modules  
    }
  }

  ngOnInit(): void {
  }

  public displayImage(){
    if(this.params.isUserModuleOrDetailedActRep){
      // special case for user module and detailed activity report only ============>
      const ref = this.modalService.open(ImageViewerComponent,
        {
          centered: false,
          size : 'xl'
        }
      );
      const lastCommaIndex = this.params.value.lastIndexOf(',');
      if(lastCommaIndex>0){
        this.params.value = this.params.value.substring(0, lastCommaIndex);
      }
      ref.componentInstance.dataString = this.params.value;
  
      ref.result.then((result) => {

      });
//  <============ END::special case for user module and detailed activity report only
    }else{
      // for any other modules ============>
      console.log("Param Data Id : " + this.paramData.id);
      console.log("Custom Field : " +this.paramData.data[this.index].customFieldType);
      const ref = this.modalService.open(ImageViewerComponent,
        {
          centered: false,
          size : 'xl'
        }
      );
      ref.componentInstance.dataString = this.paramDataArray.customFieldValue;

      ref.result.then((result) => {

      });
//  <============ END::for any other modules 
    }
  }


  public invokeParentMethod() {
    console.log("Param Data Id : " + this.paramData.id);
    console.log("Custom Field : " +this.paramData.data[this.index].customFieldType);
    this.params.context.componentParent.viewAttachment(this.paramData, this.paramDataArray);
  }

}