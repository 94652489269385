<div style="background-color: #FFFFFF; box-shadow: -4px 2px 16px rgba(0, 0, 0, 0.25), 0px 4px 8px rgba(0, 0, 0, 0.25); border-radius: 12px;">
    <div class="modal-header">
        <h4 class="modal-title">Add Activity</h4>
    </div>
    <div class="modal-body">
        <!-- <div class="form-group row" *ngFor="let activity of rowData">
            <button  class="btn btn-primary btn-lg button-150"  (click)="add(activity)">{{ activity.activityName}}</button>
        </div> -->
        <ng-container>
            <div class="menu-submenu menu-submenu-classic menu-submenu-left" >
                <span class="menu-arrow menu-arrow-adjust"></span>
        
                <ul  class="menu-subnav" *ngFor="let activity of rowData">
                    <li class="menu-item" >
                        <a class="menu-link pl-0" (click)="add(activity)" >
                           
                            <span  class="menu-text"> {{ activity.activityName}} </span>
                        </a>
                    </li>
                </ul>
        
            </div>
        </ng-container>  

    </div>
</div>  


 