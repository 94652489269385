import { Component, Input, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ContactDashboardService } from '../../../../services/contact-dashboard.service';
import { AddNewCompanyComponent } from '../../../../../shared/custom-add/add-new-company/add-new-company.component';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';

@Component({
  selector: 'kt-widget5-opportunity',
  templateUrl: './widget5-opportunity.component.html',
  styleUrls: ['./widget5-opportunity.component.scss']
})
export class Widget5OpportunityComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  tabHeading = JSON.parse(localStorage.getItem('pluralName'));
  headingName = 'Opportunity';
  opportunityList: any;
  isError = false;
  errorMessage: string;

  public isCollapsed = false;

  constructor(
    private modalService: NgbModal,
    private _cds: ContactDashboardService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('dashboard-opportunity');
    this.fetchContactOpportunities(this.selectedProject, this.parentData);

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.opportunityResult(data);
      });
    }

    if(this.tabHeading['opportunities'])
      this.headingName = this.tabHeading['opportunities'];
      
  }

  fetchContactOpportunities(projectId: number, opportunityId: number){
    this._cds.getContactOpportunities(projectId, opportunityId).subscribe(
      res=>{
        this.opportunityList = res['responseData'];
        this.SpinnerService.hide('dashboard-opportunity');
      },
      err=>{
        console.log(err);
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        this.SpinnerService.hide('dashboard-opportunity');
      }
    )
  }

  addOpportunity() {
    const _saveMessage = `Contact has been saved.`;
    const ref = this.modalService.open(AddNewCompanyComponent,
      {
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });

    ref.componentInstance.moduleType=3;
    ref.componentInstance.flag='contact';
    ref.componentInstance.contactId = this.parentData;
    ref.componentInstance.activityFlag=false;

    ref.result.then((result) => {
      this.opportunityResult(result);
    });

  }

  opportunityResult(result: string) {
    if(result === 'SUCCESS'){
      this.SpinnerService.show('dashboard-opportunity');
      this.fetchContactOpportunities(this.selectedProject, this.parentData);
    }
  }

}
