import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { countriesModel} from '../../company-management/models/countries.model';
import { cityModel } from '../../company-management/models/city.model';
import { stateModel } from '../../company-management/models/state.model';
@Injectable({
  providedIn: 'root'
})
export class AddressService {

  constructor(private http:HttpClient) { }

  getAllCountries(projectId: number){
    return this.http.get<countriesModel[]>(environment.baseUrl + '/setting/api/geo/getAllCountries?projectId='+projectId);
  }

  getStatesForCountry(countryId){
    return this.http.get<stateModel[]>(environment.baseUrl + '/setting/api/geo/getStatesForCountry?countryId='+countryId);
  }

  getCitiesForState(stateId){
    return this.http.get<cityModel[]>(environment.baseUrl + '/setting/api/geo/getCitiesForState?stateId='+stateId);
  }

}
