import { Component, OnInit } from '@angular/core';
import { environment} from '../../../../../environments/environment';

@Component({
  selector: 'kt-attachment-view',
  templateUrl: './attachment-view.component.html',
  styleUrls: ['./attachment-view.component.scss']
})
export class AttachmentViewComponent implements OnInit {

  dataArray:any;
  imagePath:string;
  fieldType:number;
  basePath: string;
  
  constructor() { }

  ngOnInit(): void {
    this.basePath = environment.imageUrl;
    this.imagePath = this.basePath + this.dataArray.customFieldValue;
    this.fieldType = this.dataArray.index;
    console.log(this.imagePath);
    console.log(this.fieldType);

  }

}
