import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ConfigTabSettingsComponent } from './config-tab-settings.component';
import { ManageActivitySettingsComponent } from './_subs/manage-activity-settings/manage-activity-settings.component';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatChipsModule } from '@angular/material/chips';

const routes:Routes=[
  {path:'',component:ConfigTabSettingsComponent}
]

@NgModule({
  declarations: [
    ConfigTabSettingsComponent,
    ManageActivitySettingsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule,
    MatButtonToggleModule,
    MatChipsModule
  ],
  entryComponents:[
    AlertDialogComponent

  ]
})
export class ConfigTabSettingsModule { }
