import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { OpportunityInfoModel } from '../models/opportunity-info.model';
import { OpportunityActivitiesModel } from '../models/opportunity-activities.model';
import { OpportunityContactsModel } from '../models/opportunity-contacts.model';
import { OpportunityCompanyModel } from '../models/opportunity-company.model';
import { OpportunityStatsModel } from '../models/opportunity-stats.model';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OpportunityDashboardService {

  constructor(private http:HttpClient) { }


  getOpportunityInfo(projectId: number, opportunityId: number){
    return this.http.get<OpportunityInfoModel[]>(
      environment.baseUrl + '/reportsfetcher/api/opportunity/dashboard/getOpportunityInfo?projectId='+projectId+'&opportunityId='+opportunityId
    );
  }

  getOpportunityContacts(projectId: number, opportunityId: number){
    return this.http.get<OpportunityContactsModel>(
      environment.baseUrl + '/reportsfetcher/api/opportunity/dashboard/getContacts?projectId='+projectId+'&opportunityId='+opportunityId
    );
  }

  getOpportunityCompanies(projectId: number, opportunityId: number){
    return this.http.get<OpportunityCompanyModel[]>(
      environment.baseUrl + '/reportsfetcher/api/opportunity/dashboard/getCompanies?projectId='+projectId+'&opportunityId='+opportunityId
    );
  }

  getOpportunityActivities(projectId: number, opportunityId: number){
    return this.http.get<OpportunityActivitiesModel>(
      environment.baseUrl + '/reportsfetcher/api/opportunity/dashboard/getActivities?projectId='+projectId+'&opportunityId='+opportunityId
    );
  }

  getOpportunityStats(projectId: number, opportunityId: number){
    return this.http.get<OpportunityStatsModel[]>(
      environment.baseUrl + '/reportsfetcher/api/opportunity/dashboard/getStats?projectId='+projectId+'&opportunityId='+opportunityId
    );
  }
}
