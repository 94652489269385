<div class="example-preview" *ngIf="formView">
    <div>
        <form [formGroup]="projectSelectionForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Select System</h4>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <p>Dear {{userDetail.firstName}},</p>
                        <p>You are registered as a user on multiple systems. 
                            Please select the one you want to access now. 
                            You can also set a default to avoid selection every time you login. 
                            You can change the system or reset the default from the navigation bar at any time.
                        </p>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-15">System Name
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="selectProject" (selectionChange)="changeEntity($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="projectSelectFilter" ></ngx-mat-select-search>
                                </mat-option>
                            
                                <mat-option *ngFor="let proj of projectList | search : projectSelectFilter.value :'proj.project.projectName'" [value]="proj.project.projectId">
                                    {{proj.project.projectName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('selectProject', 'required')">Please select system</mat-error> 
                        </mat-form-field>
                    </div>
                </div>
                <div class="form-group row mb-0">
                    <div class="col-md-4">
                        <label class="col-form-label pt-1">Set as Default 
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox3  formControlName="defaultSet" [(ngModel)]="defaultProjSet" > Yes
                        </mat-checkbox>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="Proceed()">Proceed</button>
            </div>
        </form> 
    </div>
</div>  
