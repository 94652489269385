import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { GeneralSettingsComponent } from './general-settings.component';
import { LocalizationComponent } from './_subs/localization/localization.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { OpportunitySettingComponent } from './_subs/opportunity-setting/opportunity-setting.component';
import { ContactSettingComponent } from './_subs/contact-setting/contact-setting.component';
import { CustomerSettingComponent } from './_subs/customer-setting/customer-setting.component';
import { CarouselItemComponent } from './_subs/localization/_subs/carousel-item/carousel-item.component';
import { AlertDialogComponent } from './_subs/localization/_subs/alert-dialog/alert-dialog.component';
import { SupportSettingComponent } from './_subs/support-setting/support-setting.component';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TwoDigitNumberPipe } from '../shared/pipes/twoDigitNumber.pipe';


const routes:Routes=[
  {
    path:'',
    component:GeneralSettingsComponent
  }
]

@NgModule({
  declarations: [
    GeneralSettingsComponent,
    LocalizationComponent,
    OpportunitySettingComponent,
    ContactSettingComponent,
    CustomerSettingComponent,
    CarouselItemComponent,
    AlertDialogComponent,
    SupportSettingComponent,
    TwoDigitNumberPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild(routes),
    NgxSpinnerModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule
  ],
  entryComponents:[
    LocalizationComponent,
    AlertDialogComponent

  ]
})
export class GeneralSettingsModule { }
