// Angular
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DialerDashboardService } from 'src/app/views/pages/dialer-dashboard/Services/DialerDashboardService.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'kt-company-widget1-sales-stat',
  templateUrl: './widget1-sales-stat.component.html',
  styleUrls: ['./widget1-sales-stat.component.scss']
})
export class Widget1SalesStatComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;
  @Output() public isInsightsCollapsed : EventEmitter<string> = new EventEmitter<string>();

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  orderValue: number;
  orderCount: number;
  avgOrderValue: number;
  avgMonthlyOrders: number;
  lastOrderSince: number;
  topProducts: string[];
  private subscriptions: Subscription[] = [];

  public isCollapsed : boolean = false;

  constructor(
    private _cds: CompanyDashboardService,
    private SpinnerService: NgxSpinnerService,
    private ds:DialerDashboardService
  ) {}


  ngOnInit(): void {
    this.SpinnerService.show('dashboard-stat');
    if(this.parentData){
      this.fetchComapnyStats(this.selectedProject, this.parentData);
    }
    this.subscriptions.push(this.ds.entityId$.subscribe((entityId) => {
      if(entityId){
        this.parentData=entityId;
        this.fetchComapnyStats(this.selectedProject, this.parentData);
      }
      })
    )
    
  }

  toggle(){
    this.isCollapsed = !this.isCollapsed;
    if(this.isCollapsed){
      this.isInsightsCollapsed.emit('true');
    }else{
      this.isInsightsCollapsed.emit('false');
    }
  }
  fetchComapnyStats(projectId: number, companyId: number){
    this._cds.getCompanyStats(projectId, companyId).subscribe(
      res=>{
        const companyStats = res['responseData'];
        this.orderValue = companyStats.orderValue;
        this.orderCount = companyStats.orderCount;
        this.avgOrderValue = companyStats.avgOrderValue;
        this.avgMonthlyOrders = companyStats.avgMonthlyOrders;
        this.lastOrderSince = companyStats.lastOrderSince;
        this.topProducts = companyStats.topProducts;
        this.SpinnerService.hide('dashboard-stat');

      },
      err=>{
        console.log(err);
        this.SpinnerService.hide('dashboard-stat');
      }
    )
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

}
