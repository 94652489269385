import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../app/core/_base/layout';
import { ManageActivitiesService } from '../manage-activities/services/manage-activities.service';
import { EventEmitterService } from '../shared/services/event-emitter.service';

@Component({
  selector: 'kt-config-tab-settings',
  templateUrl: './config-tab-settings.component.html'
})
export class ConfigTabSettingsComponent implements OnInit {
  
  selectedTab = 0;
  moduleType: number;
  returnUrl: any;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  entity:string;
  allowForCompanies: number;


  

  /**
     * Component constructor
     *
     * @param router: Router
     * @param route
     */
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private subheaderService: SubheaderService,
    private activityService: ManageActivitiesService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
  ) {
   }

  ngOnInit(): void {
    this.eventEmitterService.onModalCloseResult(true);
    this.SpinnerService.show('activity-config');

    // redirect back to the returnUrl before login
	    this.route.queryParams.subscribe(params => {
		  this.returnUrl = params.returnUrl || '/';
	  });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.moduleType = params.moduleType;
        console.log('moduleType : '+this.moduleType);
    });
    this.entity="Activity";
    this.fetchActivity(this.moduleType);
    this.SpinnerService.hide('activity-config');
  }

  fetchActivity(activityId:any){
    this.activityService.getSelectedActivity(activityId,this.selectedProject).subscribe(
      res=>{
        const activityName = res['responseData'].activityName;
        this.allowForCompanies = res['responseData'].allowForCompanies;
        const titleName = activityName;
        this.subheaderService.setTitle(titleName);
        this.subheaderService.setTransactionPerformedOnActivity(res['responseData'].anyTransactionPerformed);
        this.SpinnerService.hide('activity-config');
      },
      err=>{
        console.log(err);
      }
    )
  }
}
