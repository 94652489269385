import { Component, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, ValidationErrors,FormArray, ValidatorFn } from '@angular/forms';
import { CompaniesService } from '../../../company-management/services/companies.service';
import { NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AddressService } from '../../services/address.service';
import { countriesModel } from '../../../company-management/models/countries.model';
import { stateModel } from '../../../company-management/models/state.model';
import { cityModel } from '../../../company-management/models/city.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { ContactsService } from '../../../contact-management/services/contacts.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { EventEmitterService } from '../../services/event-emitter.service';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { OpportunitiesService } from '../../../opportunities/services/opportunities.service';
import { ProductService  } from '../../../product-management/services/product.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { ReferenceDocumentService } from '../../services/reference-document.service';
import { ImageViewerComponent } from '../../image-viewer/image-viewer.component';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SystemAttributeEnum } from '../../constant/SystemAttributeEnum.constants';
import { FieldService } from '../../services/field.service';

@Component({
  selector: 'kt-edit-companies-dialog',
  templateUrl: './edit-companies-dialog.component.html',
  styleUrls: ['./edit-companies-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditCompaniesDialogComponent implements OnInit {
  allSelected=false;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  systemAttributeEstimateArray=[];
  systemAttributecompaniesArray=[];
  systemAttributecontactsArray=[];
  systemAttributeopportunityArray=[];
  systemAttributeproductsArray=[];
  systemAttributeopportunityListArray=[];
  systemAttributecompanyListArray=[];
  systemAttributecontactsListArray=[];
  systemAttributeStatusArray=[];

  companyId:number|string;
  contactId:number;
  opportunityId:number;
  flag:String;
  ShowModal:boolean=false;
  skuItemsArray=[];
  skuList:any;
  totalSum: number = 0;
  showImageArray=[];
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  imgURL:any;
  images=[];
  imagesnameArray=[];
  systemAttribute11Array=[];
  systemAttribute6Array=[];
  newOpenDiv=[];
  openDivs=[];
  moduleType:number;
  moduleName:String;
  customAttributeValues=[];
  customAttributesTestValues:[];
  isSubmitted:boolean=true;
  errorMes:string="";
  disabled:Boolean=false;
  maxDate= new Date();
  minDate= new Date();
  customAttributesList=[];
  customAttributesList2=[];
  parentPropertyId:Number;
  countriesList:countriesModel[];
  stateList:stateModel[];
  cityList:cityModel[];
  image:any;
  addCompaniesForm :FormGroup;
  editId:any;
  editOppVal:any;
  fieldVisibilityConfig = [];
  singularName: string;
  systemAttributeOwnerArray=[];
  errorFlag:boolean;
  imageNoUploaded: boolean = false;
  isSubmitShow:any;
  myFormValueChanges$;
  DisplayDateFlag:boolean;
  DisplayDateMinFlag:boolean;
  DisplayDateMaxFlag:boolean;
  private subscriptions: Subscription[] = [];
  isEditAllowed = false;
  // image
  fileArray = {};
  baseUrl = environment.imageUrl;
  uploadQueue = {};
  baseURL = environment.imageUrl;
  fieldVisibilityNotAttributeIds = [5,14,19,10,11,12,17];
  documentTypeMap = new Map([
    [1, {type: "PDF", extension:".pdf", matchString: "application/pdf"}],
    [2, {type: "Word", extension:".docx", matchString: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"}],
    [3, {type: "Excel", extension:".xlsx", matchString: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"}],
    [4, {type: "Power Point", extension:".pptx", matchString: "application/vnd.openxmlformats-officedocument.presentationml.presentation"}]
  ]);
  documentIconUrlMap = new Map([
    ["pdf", "./assets/media/svg/icons/General/PDF-File.svg"],
    ["docx", "./assets/media/svg/icons/General/file-alt-solid.svg"],
    ["xlsx", "./assets/media/svg/icons/General/file-excel-solid.svg"],
    ["pptx", "./assets/media/svg/icons/General/file-powerpoint-solid.svg"]
  ]);

  private numericPattern="^(0|[1-9][0-9]*)$";
  private decimalPattern="^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";
  private albhabetPatternErrorMsg ='Only Alphabets are allowed';
  private albhaNumericPatternErrorMsg ='Only Alpha-Numeric characters are allowed';

  public singleDropdownFilter: FormControl = new FormControl();
  public multiDropdownFilter: FormControl = new FormControl();
  public countryNameFilter: FormControl = new FormControl();
  public stateNameFilter: FormControl = new FormControl();
  public cityNameFilter: FormControl = new FormControl();
  spinnerCount: number = 0;
  public teamFlag:boolean=false;
  productSerialNo: String;

  constructor(private fb: FormBuilder,
    private _cs:CompaniesService,
    public activeModal: NgbActiveModal,
    private _as:AddressService,
    private _us:UploadImageService,
    private _docUpload: ReferenceDocumentService,
    private _customAttrService:CustomAttributesService,
    private _contact_service:ContactsService,
    private eventEmitterService: EventEmitterService,
    private SpinnerService: NgxSpinnerService,
    private _ps:ProductService,
    private _os:OpportunitiesService,
    private _field: FieldService,
    public dialog: MatDialog,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    this.SpinnerService.show('addCompany');

    this.getModuleName();
    this.DisplayDateFlag= false;
    this.DisplayDateMinFlag = false;
    this.DisplayDateMaxFlag = false;

    
     //form builder 
     this.initaddCompanyForm();

    //  this.getAllCountries();
      // get fields with field Visibility Config
     this.getFieldVisibilityConfig();
     this.errorFlag=false;
     if(this.isSubmitShow != false){  
      this.isSubmitShow="true";
     }

  }

  getModuleName(){
    switch (this.moduleType){
      case 1 :{
        this.moduleName="Contact";
        break;
      }
      case 2 :{
        this.moduleName="Company";
        break;
      }
      case 3 :{
        this.moduleName="Opportunity";
        break;
      }
      default:{
        this.moduleName="";
        break;
      }
    }
  }

  setStatus(){
    this.systemAttributeStatusArray.forEach(res=>{  
      console.log(res);
      const id=this.createDivId(res);
      var division1=<HTMLElement>document.getElementById(id);
      if(division1){              
        division1.classList.add('hidden');
      }
           
      this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res.lookupValues[0].id); 
      this.addCompaniesForm.controls[res.combinedAttributeName].disable();          
       
    })

  }

  getFieldVisibilityConfig(){
    if(this.moduleType==SystemAttributeEnum.CONTACT_MODULE || 
      this.moduleType==SystemAttributeEnum.COMPANY_MODULE || 
      this.moduleType==SystemAttributeEnum.OPPORTUNITY_MODULE || 
      this.moduleType>=SystemAttributeEnum.ACTIVITY_MODULE) {
      this._field.getFieldVisibilitySettings(this.selectedProject, this.moduleType,true).subscribe(res => {
        if(res['responseData']) {
          res['responseData'].filter(x => x['fieldVisibilityConfig'])
          .forEach(x => {
            this.fieldVisibilityConfig.push(...x['fieldVisibilityConfig'])
          })
          this.getFields();
        }
      });
    }
    else{
      this.getFields();
    }
  }


  getAllOwner(){
    console.log('owner filed loads');
    this._customAttrService.getOwner(this.selectedProject,Number(this.moduleType)).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.fullName;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeOwnerArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }

  getAllOpportunities(){
    if(this.companyId!=null && this.companyId!='undefined'){
    // this._os.getAllOpportunityDetails(this.flag=="company"? this.opportunityId : null).subscribe(
     this._os.getAllOpportunityDetails2(this.companyId).subscribe(
       data=>{
        
         data['responseData'].forEach(res=>{
           
             res.lookupValue=res.opportunityName;
             res.id=res.opportunityId;
           
         })
         var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeopportunityArray[0].id);
       
         if(removeIndex >-1){
           
           this.customAttributesList[removeIndex].lookupValues=data['responseData'];
           this.customAttributesList[removeIndex].hasChildAttribute=true;
   
         }
 
         if(this.opportunityId && this.flag=="opportunity"){
           console.log('into opp');
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].setValue(Number(this.opportunityId));
           this.addCompaniesForm.controls[this.systemAttributeopportunityArray[0].combinedAttributeName].disable(); 
 
         }
 
       }
     ) 
    }
   }
 
   getAllOpportunitiesDependentInputs(id){
     //api to prefill opportunity inputs
     this._os.getOpportunity(id,this.selectedProject).subscribe(res=>{
       console.log('query params 2');
       console.log(this.systemAttributeopportunityListArray);
 
       var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributecompaniesArray[0].id);
    
       res['responseData'][0].customAttributeValues.forEach(res1=>{
         console.log(res1);
         if(removeIndex >-1 && res1.attributeName=="Company Name" && this.opportunityId){
           
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].setValue(res1.value[0].id);   
           this.addCompaniesForm.controls[this.systemAttributecompaniesArray[0].combinedAttributeName].disable(); 
         }
         this.systemAttributeopportunityListArray.forEach(res=>{
           
           
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.systemAttribute.id==26){
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
               }else{                
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id);  
               }          
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
              
               if(res.systemAttribute.id==26){
                 console.log(res);
                 if(this.opportunityId && this.flag=="opportunity"){
 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(id); 
                 }
                 
               }else{                
                 if(res.attributeType.id==2){
                   var arraynew=[];
                   res1.value.forEach(d=>{
                     arraynew.push(d.id);
                   })
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
                 
                 }else{
                   this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
                 
                 }  
               }                
           
             }
           }         
         })
       })
     })
   }
 
   getAllCompanyDependentInputs(id){
     //api to prefill opportunity inputs
     this._cs.getCompanyDetails(id,this.selectedProject).subscribe(res=>{
 
       res['responseData'][0].customAttributeValues.forEach(res1=>{
       
         this.systemAttributecompanyListArray.forEach(res=>{
     
           if(res1.value.length >0){
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].id); 
               
               }
                      
               
             }
             if(res1.attributeId==res.linkedAttributeId && (res1.attributeTypeId !==1) ){
               if(res.attributeType.id==2){
                 var arraynew=[];
                 res1.value.forEach(d=>{
                   arraynew.push(d.id);
                 })
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(arraynew); 
               
               }else{
                 this.addCompaniesForm.controls[res.combinedAttributeName].setValue(res1.value[0].name);  
               
               }
           
             }
           }
          
          
         })
       })
     })
   }
 
   getAllEstimates(){
    this._customAttrService.getActivityLookup(this.moduleType,this.selectedProject,this.companyId,this.opportunityId).subscribe(
      data=>{
        console.log('estimates');
        console.log(data['responseData']);

        data['responseData'].forEach(res=>{          
          res.lookupValue=res.activityName;
          res.id=res.activityId;          
        })

        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttributeEstimateArray[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
          this.customAttributesList[removeIndex].hasChildAttribute=true;
  
        }
      }
    ) 
  }

   getAllProducts(){
     this._ps.getProductLookup(this.selectedProject).subscribe(
       data=>{
         this.skuList= data['responseData'];     
       }
     ) 
   }

   
  getCompanyDetails(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject).subscribe(
      data=>{    
        this.editOppVal=data['responseData'];
  
      }
    )
  }
  
  initaddCompanyForm(){

    this.addCompaniesForm = new FormGroup({   
    });
  }

  
  // getAllCountries(){
  //   this._as.getAllCountries(this.selectedProject).subscribe(
	// 	(result)=>{ 
  //      this.countriesList=result['responseData']; 
	// 	},
	// 	error => {
	// 		console.log(error)
  //   })
  // }

  changeCountry(countryId){
    //get states
    // var id=countryId.split('@',2);
    // this.getAllStates(id[1]);
  }

  getSKU() {
    
    return this.fb.group({
     sku: ['', Validators.compose([ Validators.required])],
     quantity: ['', Validators.compose([ Validators.required])],
     listPrice: [''],
     sellingPrice: ['', Validators.compose([ Validators.required])],
     discount: [''],
     tax: ['', Validators.compose([ Validators.required])],
     total: ['']
    });
  }

  getControls() {
    return (this.addCompaniesForm.get('skuItems') as FormArray).controls;
  }

  private updateItems(skuItems: any) {
    
    const control = <FormArray>this.addCompaniesForm.controls['skuItems'];
    
    this.totalSum = 0;
    for (let i in skuItems) {
      if(skuItems[i].sku!==''){
        for(let sku of this.skuList){
          if(sku.productId == skuItems[i].sku){
            control.at(+i).get('listPrice').setValue(sku.price, {onlySelf: true, emitEvent: false});
          }
       }
      }

      if((skuItems[i].listPrice!=='' && skuItems[i].sellingPrice!=='') &&
          (skuItems[i].listPrice!==null && skuItems[i].sellingPrice!==null)){

          var discount=Number(skuItems[i].listPrice-skuItems[i].sellingPrice);
          discount=((discount/skuItems[i].listPrice)*100);
          discount=Number(discount.toFixed(2));
          control.at(+i).get('discount').setValue(discount, {onlySelf: true, emitEvent: false});

      }else{
        control.at(+i).get('discount').setValue('', {onlySelf: true, emitEvent: false});
      }

    
    if((skuItems[i].quantity!=='' && skuItems[i].sellingPrice!=='' && skuItems[i].tax!=='')
    && (skuItems[i].quantity!== null && skuItems[i].sellingPrice!==null && skuItems[i].tax!==null)){

      let total = skuItems[i].quantity*(Number(skuItems[i].sellingPrice)+Number((skuItems[i].sellingPrice * (skuItems[i].tax/100))));
      total=Number(total.toFixed(2));
      control.at(+i).get('total').setValue(total, {onlySelf: true, emitEvent: false});
       this.totalSum += total;

      }else{
        let total=0;
        control.at(+i).get('total').setValue('', {onlySelf: true, emitEvent: false});
       this.totalSum += total;
      }

    }
  }


  // getAllStates(countryId){
  //   this._as.getStatesForCountry(countryId).subscribe(
  //     (result)=>{  
  //       this.stateList=result['responseData']; 
  //     },
  //     error => {
  //       console.log(error)
  //     }
  //   )
  // }

  changeState(stateId){
    //get cities
    // var id=stateId.split('@',2);
    // console.log(id);
    // this.getAllCities(id[1]);
  }

  // getAllCities(stateId){
  //   this._as.getCitiesForState(stateId).subscribe(
  //     (result)=>{
  //       this.cityList=result['responseData']; 

  //     },
  //     error => {
  //       console.log(error)
  //     }
  //   )
  // }

  getAllCompanies(){
     this._cs.getCompanyIdName(this.selectedProject,this.moduleType,this.teamFlag?2:1).subscribe(
      data=>{
       
        console.log(data);
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.name;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute11Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    )
 
  }

  getAllContacts(){
    this._contact_service.getAllContactDetailsForSelfAndTeam(this.selectedProject,this.moduleType).subscribe(
      data=>{
        data['responseData'].forEach(res=>{
          
            res.lookupValue=res.contactName;
            res.id=res.contactId;
          
        })
        var removeIndex= this.customAttributesList.map(function(item) { return item.id; }).indexOf(this.systemAttribute6Array[0].id);
      
        if(removeIndex >-1){
          
          this.customAttributesList[removeIndex].lookupValues=data['responseData'];
  
        }

      }
    ) 
  }
  isMongoId(value){
    return !(new RegExp("^\\d*$").test(value));
  }
  getFields(){
    this._customAttrService.getParticularCompany(this.editId,this.moduleType, this.selectedProject, this.isMongoId(this.editId)).subscribe(
      data=>{       
        this.editOppVal=data['responseData'][0];
        this.addCompaniesForm = new FormGroup({
          serialNo: new FormControl({ value: this.moduleType === 4 ?  this.productSerialNo : this.editOppVal.serialNo, disabled: true })
        });
        this.editId = (this.moduleType === 2 && this.editOppVal.refCompanyId!== undefined) ? this.editOppVal.refCompanyId : this.editId;
        console.log("my edit api result");
        console.log(this.editOppVal);
        
        this._customAttrService.getFields(this.moduleType, this.selectedProject).subscribe(
          res=>{
            res['responseData'] = res['responseData'].filter(ele => {
              if(ele.status == 1){
                // if(ele.attributeType.id==4 ){
                //   if( ele.allowBrowsing==1 ){
                //     return 1;
                //   }else{
                //     return 0;
                //   }
                // }else{
                  return 1;
                // }
              }
            })
                    
            this.SpinnerService.hide('addCompany');
            this.ShowModal=true;
            console.log("my get fields api result");
            console.log(res['responseData']);
            res['responseData'].forEach(element=>{
              if(element.systemAttribute==null)
              {
                element.systemAttribute={};
              }
            
    
              if(element.attributeType.id==1 && element.systemAttribute['id']!==16 && element.systemAttribute['id']!==15 && element.systemAttribute['id']!==21){
                
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                let controlValue = 0;
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
        
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element});
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                      controlValue = ele.value[0].id;
              
                    }
                  }
                });
                
                if(element.parentAttributeId === 0 && element.hasChildAttribute) {
                  setTimeout(() => {
                    this.invokeChildMethod(controlValue, element, '');
                  }, 10);
                }

               
              }else{
                if(element.systemAttribute['id']==16 || element.systemAttribute['id']==15 || element.systemAttribute['id']==21){           
    
                  if(element.mandatoryFlag==1){
                    this.addCompaniesForm.addControl('countryName',  new FormControl('',Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('stateName',  new FormControl('',Validators.compose([ Validators.required])));
                    this.addCompaniesForm.addControl('cityName',  new FormControl('',Validators.compose([ Validators.required])));
      
                  }else{
    
                    this.addCompaniesForm.addControl('countryName',  new FormControl());
                    this.addCompaniesForm.addControl('stateName',  new FormControl());
                    this.addCompaniesForm.addControl('cityName',  new FormControl());
        
                  }
                  if(!this.isEditAllowed){
                    this.addCompaniesForm.get('countryName').disable();
                    this.addCompaniesForm.get('stateName').disable();
                    this.addCompaniesForm.get('cityName').disable();
                  }

                  this.editOppVal.customAttributeValues.forEach(ele=>{
              
                    if(ele.attributeId==element.id){             
                      if(ele.value.length>0){
                        if(ele.value.length > 0){
                          this.addCompaniesForm.controls['countryName'].setValue(ele.value[0].name+'@'+ele.value[0].id);
                        }
                        //after filling country get states 
                        // this.getAllStates(ele.value[0].id);
                        if(ele.value.length > 1){
                          this.addCompaniesForm.controls['stateName'].setValue(ele.value[1].name+'@'+ele.value[1].id);
                            //after filling state get cities
                          // this.getAllCities(ele.value[1].id);
                        }
                        
                        if(ele.value.length > 2){
                          this.addCompaniesForm.controls['cityName'].setValue(ele.value[2].name+'@'+ele.value[2].id);
                        }
                      }

                    }
                  })

                }
              }
    
              if(element.attributeType.id==2){
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                var multiSelectArray=[];
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(ele.value.length>0){
                        if(element.parentAttributeId !==0){
                          this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                        }
                        this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                
                      }
                      ele.value.forEach(res=>{
                        multiSelectArray.push(res.id);
                      })
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(multiSelectArray);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==5){
                //radio
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==6 || element.attributeType.id==20){
                //text
                let patternString = ".*";
                let patternErrorMsg = '';
                if(element.characterType == 3){
                  patternString = "^[a-zA-Z ]*$";
                  patternErrorMsg = this.albhabetPatternErrorMsg;
                }else if(element.characterType == 2){
                  patternString = "^[a-zA-Z0-9 ]*$";
                  patternErrorMsg = this.albhaNumericPatternErrorMsg;
                } else if (element.attributeType.id==6 && element.addValidation){
                  patternString = element.regexExpression;
                  patternErrorMsg = element.errorMessage;
                }
                element.patternErrorMsg = patternErrorMsg;
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required, Validators.pattern(patternString)])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.pattern(patternString)])));
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
               
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }
    
              if(element.attributeType.id==7){
                //numeric
                let pattern = this.numericPattern;
                let patternErrorMsg = '';
                if (element.decimalFlag) {
                  pattern = this.decimalPattern;
                } else if (element.addValidation) {
                  pattern = element.regexExpression;
                  patternErrorMsg = element.errorMessage;
                }
                element.patternErrorMsg = patternErrorMsg;
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.pattern(pattern)])));
                }else{
    
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('',Validators.compose([ Validators.pattern(pattern)])));
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
    
              if(element.attributeType.id==8){
                let patternString = ".*";
                let patternErrorMsg = '';
                if(element.characterType == 3){
                  patternString = "^[a-zA-Z \n]*$";
                  patternErrorMsg = this.albhabetPatternErrorMsg;
                }else if(element.characterType == 2){
                  patternString = "^[a-zA-Z0-9 \n]*$";
                  patternErrorMsg = this.albhaNumericPatternErrorMsg;
                }
                element.patternErrorMsg = patternErrorMsg;
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required, Validators.pattern(patternString)])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.pattern(patternString)])));
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                    this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }
                
              if(element.attributeType.id==3 || element.attributeType.id==21){
               
                  this.maxDate= new Date();
      
                  this.minDate= new Date();
      
                  console.log(element);
                  if(element.dateAttributes){
            
                    if(element.dateAttributes.stdLookupAttribute != null){
                      this.DisplayDateMinFlag=false;
                      this.DisplayDateMaxFlag=false;
                      if(element.dateAttributes.stdLookupAttribute.id == 2){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 3){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 5){
                        this.DisplayDateFlag=true;
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay()+6);
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 6){
                        this.DisplayDateFlag=true;
                        var date = new Date();
                        //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 7){
                        this.DisplayDateFlag=true;
                        var date = new Date();
                        //var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
                        //var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
                        element.dateAttributes.minDate= new Date(date.getFullYear(), date.getMonth(), 1);
                        element.dateAttributes.maxDate= new Date(date.getFullYear(), date.getMonth() + 1, 0);
                    
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 8){
                        this.DisplayDateFlag=true;
                        var now = new Date();
                        var start = new Date(now.getFullYear(), 0, 0);
                        var diff = Number(now) - Number(start);
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diff / oneDay);
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                     
                      }
                      else if(element.dateAttributes.stdLookupAttribute.id == 9){
                        this.DisplayDateFlag=true;
                        var now = new Date();
                        var start = new Date(now.getFullYear(), 0, 0);
                        var end = new Date(now.getFullYear(), 11, 31);
                        var diffStart = Number(now) - Number(start);
                        var diffend = Number(end) - Number(now) ;
                        var oneDay = 1000 * 60 * 60 * 24;
                        var day = Math.floor(diffStart / oneDay);
                        var dayEnd = Math.floor(diffend / oneDay);
                        element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day-1));
                        element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
                       }
                    }else{
                        if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed == null){
                      
                          this.DisplayDateFlag=false;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateMaxFlag=false;
                        }
                        else if(element.dateAttributes.previousDateAllowed == 0 && element.dateAttributes.futureDateAllowed == 0){
                
                          this.DisplayDateFlag=true;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateMaxFlag=false;
                          element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                          element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                        }
                        else if(element.dateAttributes.previousDateAllowed == null && element.dateAttributes.futureDateAllowed != null){
                        
                          this.DisplayDateMaxFlag=true;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateFlag=false;
                          if(element.dateAttributes.futureDateAllowed ==0){
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                          }else{
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                          }
                        }
                        else if(element.dateAttributes.previousDateAllowed != null && element.dateAttributes.futureDateAllowed == null){
                      
                          this.DisplayDateMinFlag=true;
                          this.DisplayDateFlag=false;
                          this.DisplayDateMaxFlag=false;
                          if(element.dateAttributes.previousDateAllowed ==0 ){
                            element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                          }else{
                            element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                          }
                        }else{
                         
                          this.DisplayDateFlag=true;
                          this.DisplayDateMinFlag=false;
                          this.DisplayDateMaxFlag=false;
                          if(element.dateAttributes.previousDateAllowed ==0 ){
                            element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
                          }else{
                           element.dateAttributes.minDate= new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - element.dateAttributes.previousDateAllowed);
                          }
              
                          if(element.dateAttributes.futureDateAllowed ==0){
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
                          }else{
                            element.dateAttributes.maxDate= new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + element.dateAttributes.futureDateAllowed);
                          }
                        } 
                     }
                     if(element.attributeType.id==21){
                      let DateTimeMinDate = new Date(element.dateAttributes.minDate);
                      let minYear = DateTimeMinDate.getFullYear();
                      let minMonth = (DateTimeMinDate.getMonth() + 1).toString().padStart(2, '0'); 
                      let minDays = DateTimeMinDate.getDate().toString().padStart(2, '0');
                      element.dateAttributes.minDate = `${minYear}-${minMonth}-${minDays}T00:00:00`;
                      let DateTimeMaxDate = new Date(element.dateAttributes.maxDate);
                      let maxYear = DateTimeMaxDate.getFullYear();
                      let maxMonth = (DateTimeMaxDate.getMonth() + 1).toString().padStart(2, '0'); 
                      let maxDays = DateTimeMaxDate.getDate().toString().padStart(2, '0');
                      element.dateAttributes.maxDate = `${maxYear}-${maxMonth}-${maxDays}T00:00:00`;
                     }
                  }
                 
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
    
                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
              }
    
              if(element.attributeType.id ==22){
                if(element.mandatoryFlag==1&& element.parentAttributeId === 0){
                  
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
              }else{
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl(''));
              }
              if(!this.isEditAllowed){
                this.addCompaniesForm.get(element.combinedAttributeName).disable();
              }

              this.editOppVal.customAttributeValues.forEach(ele=>{
                if(ele.attributeId==element.id){
                  if(ele.value.length>0){
                    if(element.parentAttributeId !==0){
                      this.newOpenDiv.push({divId: 'div_'+ele.attributeId, field: element})
                    }
                   this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                  }
                }
              })

              }

              if(element.attributeType.id==4){
                console.log('element');
                console.log(element);
      
                if(element.maxImagesAllowed === null) {
                  element.maxImagesAllowed = 0;
                }
      
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
              }else{
      
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
              }
                              
                let files = [];
                this.fileArray[element.combinedAttributeName] = {
                  "attributeName": element.combinedAttributeName,
                  "files": files,
                  "maxUpload": element.maxImagesAllowed,
                  "showUploadIcon": true,
                  "showDeleteOption":true,
                  "uploaded": 0,
                  "hasError": false
                };
                const fileAttr = this.fileArray[element.combinedAttributeName];
                  
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
                   
                    if(ele.value?.length>0){
                      for (let i = 0; i < ele.value.length; i++) {
                        files.push(ele.value[i].name.replace(this.baseUrl, ""));                           
                      }
                      fileAttr.files = files;
                      fileAttr.uploaded = files.length;
                      this.updateFileControlValue(element.combinedAttributeName);
      
                      console.log('jyg');
                      console.log(this.fileArray);
                    }
                  }
                  fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
                })
              }
             
              if(element.attributeType.id==9){
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  // this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
                   this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required,Validators.email])));
                }else{
    
                    this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.email])));
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }

                this.editOppVal.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element})
                      }
                     this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].name);
                    }
                  }
                })
    
              }
    
              if(element.attributeType.id==15){
      
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
                  
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
              }else{
      
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
              }

              let validDocTypes = [];
              let allowedDocText = [];
              if(element.documentType != null && element.documentType != ""){
                (element.documentType as string).split(",").forEach(x => {
                  let type =this.documentTypeMap.get(+x);
                  validDocTypes.push(type);
                  allowedDocText.push(type.extension);
                });
              }
              else{
                for (const [key, value] of this.documentTypeMap.entries()) {
                  validDocTypes.push(value);
                  allowedDocText.push(value.extension);
                }
              }
                              
                let files = [];
                this.fileArray[element.combinedAttributeName] = {
                  "attributeName": element.combinedAttributeName,
                  "files": files,
                  "documentIcons": [],
                  "allowedDocText": allowedDocText.join(", "),
                  "validDocType": validDocTypes,
                  "maxUpload": element.maxImagesAllowed,
                  "showUploadIcon": true,
                  "showDeleteOption":true,
                  "uploaded": 0,
                  "hasError": false
                };
                const fileAttr = this.fileArray[element.combinedAttributeName];
                  
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
                   
                    if(ele.value?.length>0){
                      for (let i = 0; i < ele.value.length; i++) {
                        files.push(ele.value[i].name.replace(this.baseUrl, ""));
                        let fileType = ele.value[i].name.split('.').pop().toLowerCase();
                        fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
                      }
                      fileAttr.files = files;
                      fileAttr.uploaded = files.length;
                      this.updateFileControlValue(element.combinedAttributeName);
                    }
                  }
                  
                  fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
                })
              }

              if(element.attributeType.id==16){
                if(element.mandatoryFlag==1 && element.parentAttributeId ===0){
    
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([ Validators.required])));
                } else{
            
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }

                let files = [];
                this.fileArray[element.combinedAttributeName] = {
                  "attributeName": element.combinedAttributeName,
                  "files": files,
                  "maxUpload": element.maxImagesAllowed,
                  "showUploadIcon": true,
                  "showDeleteOption":true,
                  "uploaded": 0,
                  "hasError": false
                };
                const fileAttr = this.fileArray[element.combinedAttributeName];
                  
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
                   
                    if(ele.value?.length>0){
                      for (let i = 0; i < ele.value.length; i++) {
                        files.push(ele.value[i].name.replace(this.baseUrl, ""));
                      }
                      fileAttr.files = files;
                      fileAttr.uploaded = files.length;
                      this.updateFileControlValue(element.combinedAttributeName);
                    }
                    return;
                  }
                })
                console.log(this.fileArray);
                fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
    
              }

              if(element.attributeType.id==17){
                this.fileArray[element.combinedAttributeName] = {
                  "attributeName": element.combinedAttributeName,
                  "files": [],
                  "documentIcons": [],
                  // "allowedDocText": allowedDocText.join(", "),
                  // "validDocType": validDocTypes,
                  "maxUpload": element.maxImagesAllowed,
                  "showUploadIcon": false,
                  "uploaded": 0,
                  "hasError": false
                };
                const fileAttr = this.fileArray[element.combinedAttributeName];
    
                (element.referenceDocumentUrl as Array<string>).forEach( file => {
                  fileAttr.files.push(file);
                  let fileType = file.split('.').pop().toLowerCase();
                  const icon = this.documentIconUrlMap.get(fileType);
                  fileAttr.documentIcons.push(icon ? icon : file);
                })
    
              }

              //Condition for Data List
              if (element.attributeType.id == 18) {
    
                if (element.mandatoryFlag == 1 && element.parentAttributeId === 0) {
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
                } else {
                  this.addCompaniesForm.addControl(element.combinedAttributeName, new FormControl());
                }
                if(!this.isEditAllowed){
                  this.addCompaniesForm.get(element.combinedAttributeName).disable();
                }
                // START::setting value to main datalist field
                let controlValue = 0;
                this.editOppVal.customAttributeValues.forEach(ele=>{
             
                  if(ele.attributeId==element.id){
        
                    if(ele.value.length>0){
                      if(element.parentAttributeId !==0){
                        this.newOpenDiv.push({divId: this.createDivId(element), field: element});
                      }
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                      controlValue = ele.value[0].id;
              
                    }
                  }
                });
                
                if(element.hasChildAttribute) {
                  setTimeout(() => {
                    this.invokeChildMethod(controlValue, element, '');
                  }, 10);
                }
                // END::setting value to main datalist field

                if (element.childAttributeList?.length > 0) {
                  element.childAttributeList.forEach(childElement => {
                    // setting some proprties to child datalist fields for later use
                    childElement.parentAttributeId = element.parentDataListId;
                    childElement.parentCombinedAttributeName = element.combinedAttributeName;
                    childElement.mandatoryFlag = element.mandatoryFlag;
                    childElement.parentLinkedDataListAttribId = element.id;

                    if (childElement.systemAttribute === null) {
                      childElement.systemAttribute = {};
                    }

                    if (childElement.attributeType.id == 1) {
                      if (childElement.mandatoryFlag == 1 && childElement.parentAttributeId === 0) {
                        this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
                      } else {
                        this.addCompaniesForm.addControl(childElement.combinedAttributeName, new FormControl());
                      }
                      if(!this.isEditAllowed) {
                        this.addCompaniesForm.get(childElement.combinedAttributeName).disable();
                      }
                      // START::setting value to child datalist field
                      this.editOppVal.customAttributeValues.forEach(ele=>{
                   
                        if(ele.attributeId==childElement.id && ele.parentLinkedDatalistAttribId==childElement.parentLinkedDataListAttribId){
              
                          if(ele.value.length>0){
                            this.newOpenDiv.push({divId: this.createDivId(childElement), field: childElement});
                            this.addCompaniesForm.controls[childElement.combinedAttributeName].setValue(ele.value[0].id);
                          }
                        }
                      });
                      // END::setting value to child datalist field
                    }
                  }
                  )
                }
              }

             
              //other conditions
              if(element.systemAttribute.id==11 || element.systemAttribute.id==5){
                this.systemAttribute11Array.push(element);  
              }

              //CONDITION FOR CONTACTS LIST
              if(element.systemAttribute.id==6){
                this.systemAttribute6Array.push(element);  
              }
               //CONDITION FOR COMPANY OWNER LIST
              if(element.systemAttribute.id==34 || element.systemAttribute.id==35 || element.systemAttribute.id==65){
                console.log('condition for owner array');
               this.systemAttributeOwnerArray.push(element);   
              }

              if(element.systemAttribute.id==28){
          
                if(element.mandatoryFlag==1){
                  console.log('adding mandatory flag in sku')
                  this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()],[Validators.required]));
                  
                }else{
                  this.addCompaniesForm.addControl('skuItems', this.fb.array([ this.getSKU()]));
                }
                
        
                this.myFormValueChanges$ = this.addCompaniesForm.controls['skuItems'].valueChanges;
           
                this.myFormValueChanges$.subscribe(skuItems => this.updateItems(skuItems));
              }
   
            })
    
            let customAttributesList = [];
            (res['responseData'] as Array<any>).forEach(field => {
                customAttributesList.push(field);
              if (field.attributeType.id == 18 && field.childAttributeList !== null && field.childAttributeList.length > 0) {
                customAttributesList.push(...field.childAttributeList);
              }
            });
            this.customAttributesList = customAttributesList.map(x => {
              x['isHidden'] = false;
              x['isEdit'] = true;
              return x;
            });
           this.getFieldValues();
           this.setupfieldVisibilityConfigurationAndValue();

          },
          error => {
            this.disabled=false;
            this.errorMes=error.error.responseData.errorMsg;
    
          }
        )
      }
    )

    
  }

  setupfieldVisibilityConfigurationAndValue(){
    if(this.moduleType==SystemAttributeEnum.CONTACT_MODULE || 
      this.moduleType==SystemAttributeEnum.COMPANY_MODULE || 
      this.moduleType==SystemAttributeEnum.OPPORTUNITY_MODULE || 
      this.moduleType>=SystemAttributeEnum.ACTIVITY_MODULE) {
        this.fieldVisibilityConfig.forEach(fieldsconfig => {
          this.customAttributesList.forEach(fields => {
            if (fieldsconfig.attributeId == fields.id) {
              if (fieldsconfig.fieldVisibility == 1){
                fields['isHidden'] = true
                if(!this.fieldVisibilityNotAttributeIds.includes( fields.attributeType.id) && fieldsconfig.value ){
                    this.setupFieldVisibilityForHidden(fields,fieldsconfig); 
                }  
              } else if (fieldsconfig.fieldVisibility == 2 && !this.fieldVisibilityNotAttributeIds.includes( fields.attributeType.id)) {
                this.setupFieldVisibilityforViewOnly(fields,fieldsconfig);
              }
            }
          })
        })
      }
  }


  setupFieldVisibilityForHidden(fields:any,fieldsconfig:any){
    if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId) {
      if (fieldsconfig.parentDataListId == fields.parentLinkedDataListAttribId && (fieldsconfig.value != null)) {
        if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));}
      }
    } else if (fields.attributeType.id == 1 && [15, 16, 21].includes(fields.systemAttribute.id)) {
      if(!this.addCompaniesForm.controls['countryName'].value){
      const countryStateCityVals = (fieldsconfig.value as string).split("->");
      this.addCompaniesForm.controls['countryName'].setValue(countryStateCityVals[0]);
      // this.getAllStates(countryStateCityVals[0].split('@')[1]);
      this.addCompaniesForm.controls['stateName'].setValue(countryStateCityVals[1]);
      // this.getAllCities(countryStateCityVals[1].split('@')[1]);
      this.addCompaniesForm.controls['cityName'].setValue(countryStateCityVals[2]);
      }
    }else if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId == null) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));}
    }else if (fields.attributeType.id == 2) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(Number));}
    } else if (fields.attributeType.id == 4 || fields.attributeType.id == 15 || fields.attributeType.id == 16) {
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(String));
      if(this.fileArray[fields.combinedAttributeName].files.length==0){
      let files = [];
      const fileAttr = this.fileArray[fields.combinedAttributeName];
      let fileValues = fieldsconfig.value.split(',').map(String);
      if (fileValues?.length > 0) {
        for (let i = 0; i < fileValues.length; i++) {
          files.push(fileValues[i]);
          fileAttr.files = files;
          fileAttr.uploaded = files.length;
          if (fields.attributeType.id == 15) {
            let fileType = fileValues[i].split('.').pop().toLowerCase();
            fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
          }
        }
      }
    }else{
      this.fileArray[fields.combinedAttributeName].showUploadIcon = false;
      this.fileArray[fields.combinedAttributeName].showDeleteOption = false;
    }
    } else if (fields.attributeType.id == 18) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);
      if(fields.parentAttributeId === 0 && fields.hasChildAttribute) {
          this.invokeChildMethod(fieldsconfig.value, fields,'');
      }
    }
    }
    else {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value);}
    }
  }

  setupFieldVisibilityforViewOnly(fields:any,fieldsconfig:any){
    if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId) {
      if ((fieldsconfig.parentDataListId == fields.parentLinkedDataListAttribId) && (fieldsconfig.value != null)) {
        if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));
        }
        this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
        fields['isEdit'] = false;
      }
     
    } else if (fields.attributeType.id == 1 && [15, 16, 21].includes(fields.systemAttribute.id)) {
      if(!this.addCompaniesForm.controls['countryName'].value && fieldsconfig.value){
      const countryStateCityVals = (fieldsconfig.value as string).split("->");
      this.addCompaniesForm.controls['countryName'].setValue(countryStateCityVals[0]);
      // this.getAllStates(countryStateCityVals[0].split('@')[1]);
      this.addCompaniesForm.controls['stateName'].setValue(countryStateCityVals[1]);
      // this.getAllCities(countryStateCityVals[1].split('@')[1]);
      this.addCompaniesForm.controls['cityName'].setValue(countryStateCityVals[2]);
      }
      this.addCompaniesForm.controls['countryName'].disable();
      this.addCompaniesForm.controls['stateName'].disable();
      this.addCompaniesForm.controls['cityName'].disable();
      fields['isEdit'] = false;
    }else if (fields.attributeType.id == 1 && fields.parentLinkedDataListAttribId == null) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(Number(fieldsconfig.value));
      }
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    } else if (fields.attributeType.id == 2) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(Number));}
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    } else if (fields.attributeType.id == 4 || fields.attributeType.id == 15 || fields.attributeType.id == 16) {
      this.fileArray[fields.combinedAttributeName].showUploadIcon = false;
      this.fileArray[fields.combinedAttributeName].showDeleteOption = false;
      if(this.fileArray[fields.combinedAttributeName].files.length==0 && fieldsconfig.value){
        this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value.split(',').map(String));
      let files = [];
      const fileAttr = this.fileArray[fields.combinedAttributeName];
      let fileValues = fieldsconfig.value.split(',').map(String);
      if (fileValues?.length > 0) {
        for (let i = 0; i < fileValues.length; i++) {
          files.push(fileValues[i]);
          fileAttr.files = files;
          fileAttr.uploaded = files.length;
          fileAttr.showUploadIcon = false;
          fileAttr.showDeleteOption = false;
          if (fields.attributeType.id == 15) {
            let fileType = fileValues[i].split('.').pop().toLowerCase();
            fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
          }
        }
      }
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    }else{
      this.fileArray[fields.combinedAttributeName].showUploadIcon = false;
      this.fileArray[fields.combinedAttributeName].showDeleteOption = false;
    }
    } else if (fields.attributeType.id == 18) {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(+fieldsconfig.value);
       if(fields.parentAttributeId === 0 && fields.hasChildAttribute) {
          this.invokeChildMethod(fieldsconfig.value, fields,'');                    
    }
  }
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    }
    else {
      if(!this.addCompaniesForm.controls[fields.combinedAttributeName].value && fieldsconfig.value){
      this.addCompaniesForm.controls[fields.combinedAttributeName].setValue(fieldsconfig.value);}
      this.addCompaniesForm.controls[fields.combinedAttributeName].disable();
      fields['isEdit'] = false;
    }
  }
  getFieldValues(){    
      // this.showDivs(); -> it's no longer needed as new implemented changes in invokeChildMethod() will handel this functionality with some differences
      if(this.systemAttribute11Array.length >0){
      
        this.getAllCompanies();
 
      }
 
      if(this.systemAttribute6Array.length >0){
        
        this.getAllContacts();
 
      }
 
      if(this.systemAttributeopportunityArray.length >0){
        
        this.getAllOpportunities();

      }

      if(this.systemAttributeopportunityListArray.length >0){
 
        if(this.opportunityId && this.flag=="opportunity"){
          console.log('lllll');
          this.getAllOpportunitiesDependentInputs(this.opportunityId);

        }
        else{
          this.systemAttributeopportunityListArray.forEach(rep=>{
            if(rep.systemAttribute.id !==26){
              var divId=this.createDivId(rep);
              var division1=<HTMLElement>document.getElementById(divId);
              if(division1){            
                division1.classList.add('hidden');
              }
            }
           
          })        
        }
      }

      if(this.systemAttributecompanyListArray.length >0){
 
        if(this.opportunityId && this.flag=="company"){
          this.getAllCompanyDependentInputs(this.opportunityId);

        }
      }



      if(this.systemAttributeproductsArray.length >0){
           
        this.getAllProducts();

      }

      if(this.systemAttributeEstimateArray.length >0){
        
        this.getAllEstimates();

      }

      if(this.systemAttributeStatusArray.length >0){
   
        this.setStatus();

      }

      if(this.systemAttributeOwnerArray.length >0){
        
        this.getAllOwner();

      }
  }

  showDivs(){
    setTimeout(() => {
      this.newOpenDiv.forEach(res => {
        var division1 = <HTMLElement>document.getElementById(res.divId);

        if (division1) {
          const field = res.field;
          this.addValidators(field.combinedAttributeName, field.mandatoryFlag === 1, field.attributeType.id, res.decimalFlag === 1);  
          division1.classList.remove('hidden');
        }
      });
    }, 10);
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCompaniesForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.addCompaniesForm.reset();
  }

  clickImage(id){
    let element:HTMLElement =document.getElementById(id) as HTMLElement;
    element.click();
  }
  isError(val){
  //console.log('val----------'+val);
  }
  AddCompany(controls){
    this.customAttributeValues=[];
    //dyniamic fields
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;


      if((element.attributeType.id==1 ||
        element.attributeType.id==2 ||
        element.attributeType.id==5 ||
        element.attributeType.id==6 ||
        element.attributeType.id==20 ||
        element.attributeType.id==7 || 
        element.attributeType.id==8 || 
        element.attributeType.id==9 ||
        element.attributeType.id==18) && 
         (element.systemAttribute.id!==16) &&
          (element.systemAttribute.id!==15) &&
           (element.systemAttribute.id!==21)){

       
        this.customAttributeValues.push({
          "attributeId":element.id,
          "parentLinkedDatalistAttribId": element.moduleType==6 ? element.parentLinkedDataListAttribId : null,
          "value":[
            {
              "name": String(this.addCompaniesForm.controls[id]['value']==null ? '':this.addCompaniesForm.controls[id]['value'].toString().trim())
            }
          ]
          
        });
      }else{
        if(element.systemAttribute.id==16 || element.systemAttribute.id==15 || element.systemAttribute.id==21){
          var country= this.addCompaniesForm.controls.countryName['value'];
          var state=this.addCompaniesForm.controls.stateName['value'];
          var city = this.addCompaniesForm.controls.cityName['value'];
          var address2='';
          if(country!=null && state!=null && city!=null){
            address2=(country.trim()+'->'+state.trim()+'->'+city.trim());
          }else if(country!=null && state==null && city==null){
           address2=(country.trim());
          }else if(country!=null && state!=null && city==null){
            address2=(country.trim()+'->'+state.trim());
          }else{
           address2='';
          }
        
          console.log(address2);
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":[
              {
                'name':address2
               }
              
            ]
            
          })
        }
      }

      if(element.attributeType.id==3){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': formatDate(this.addCompaniesForm.controls[id]['value'])
            }          
            
          ]
          
        })
      }

      if(element.attributeType.id==21 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']?.replace('T', ' ')
            }                      
          ]          
        })
      }
      if(element.attributeType.id==22 ){
        this.customAttributeValues.push({
          "attributeId":element.id,
          "value":[
            {
              'name': this.addCompaniesForm.controls[id]['value']
            }                      
          ]          
        })
      }

      if(element.attributeType.id==4 || element.attributeType.id==15 || element.attributeType.id==16){
        console.log('image control');
        console.log(this.addCompaniesForm.controls[id]);
        if(this.addCompaniesForm.controls[id]){
          var resultArrayOfImages=[];
          this.addCompaniesForm.controls[id]['value']?.forEach(resultImage=>{
            resultArrayOfImages.push(
              {
                'name':resultImage
              }
            )
          })
          
          this.customAttributeValues.push({
            "attributeId":element.id,
            "value":resultArrayOfImages
            
          })       
        }
      }

    })


    function formatDate(date) {
      if(date == null || date == "")
        return "";
      else {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;    
    
        return [year, month, day].join('-');
      }
    }

    var addData;
    console.log(this.moduleType);
    if(this.moduleType==1){
      addData = {
        "contactId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==2){
      addData = {
        "companyId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==3){
      addData = {
        "opportunityId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues
  
      };
    }else if(this.moduleType==4){
      addData = {
        "productId":this.editId,
        "appVersion": null,
        "deviceInfo": null,
        "gps": null,
        "isActive": 1,
        "projectId": this.selectedProject,
        "customAttributeValues": this.customAttributeValues  
      };
    }else{

    }
       
    return addData;
  }

  Save(){
    if(!this.isEditAllowed) return;
    this.disabled=true;
    this.errorFlag=false;
    
    this.customAttributesList.forEach(element=>{
      var id=element.combinedAttributeName;
      var message='';
      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==1){
        const min = element.minDigits;
        const max = element.maxDigits;
        let elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
          if(element.decimalFlag) {
            elementValue = this.extractWholeNumber(elementValue);
          }
          if((min!=null && min!="0" && min!=0 && Number(min) > Number(elementValue.toString().length)) 
              || (max!=null && max!="0" && max!=0 && Number(elementValue.toString().length) > Number(max))){
            this.errorFlag=true;
            message='Enter number between '+Number(min) +' and '+Number(max) +' digits for '+element.attributeName;
            this.OpenAlertValidation(message);
            this.disabled=false;
          
          }
        }
      }

      if(element.attributeType.id==7 && element.numericValidationType !=null && element.numericValidationType==2){
        const min = element.minNumber;
        const max = element.maxNumber;
        const elementValue=this.addCompaniesForm.controls[id]['value'];
        if(elementValue!=""){
          if((min!=null && Number(min) > Number(elementValue)) || (max!=null && Number(elementValue) > Number(max))){
            this.errorFlag=true;
            message='Enter value between '+Number(min) +' and '+Number(max) +' for '+element.attributeName;
            this.OpenAlertValidation(message);
            this.disabled=false;
          } 
      }
    }

    // image validation section             
    if(element.attributeType.id === 4 && element.maxImagesAllowed != 0 ){      
      const fileAttr = this.fileArray[element.combinedAttributeName];
      if(fileAttr.files.length > element.maxImagesAllowed){
        fileAttr.hasError = true;
        fileAttr.errorMsg = `Maximum ${element.maxImagesAllowed} image(s) allowed`;
        this.errorFlag = true;
      }      
    }

    })

    const controls = this.addCompaniesForm.controls;
    if (this.addCompaniesForm.invalid) {
      Object.keys(controls).forEach(controlName =>
          controls[controlName].markAsTouched()
        );
        this.disabled=false;
      return;
    }
    
    if(this.errorFlag){
      this.disabled=false;
      return;
    }
    
    if (this.isSubmitted==false) {
      return;
    }

    console.log(controls);

    const addData=this.AddCompany(controls);
    // console.log(addData);return;

    this._customAttrService.add(addData,this.moduleType).subscribe(

      data => {
        console.log(data);

        this.addCompaniesForm.reset();
        this.passEntry.emit('SUCCESS');
        this.activeModal.close('SUCCESS');
        this.eventEmitterService.onModalCloseResult('SUCCESS');
        
      },
      error => {
        this.disabled=false;
        this.errorMes=error.error.responseData.errorMsg;
        console.log(this.errorMes);
        this.eventEmitterService.onModalCloseResult('ERROR');
      }
        
    );
  }

  invokeChildMethod(lookupvalue, customAttr, editdata){
    if (!lookupvalue) {
      lookupvalue = 0;
    }
    
    let attributeId=customAttr.id;
    let parentLinkedDataListAttribId = 0;
    
    // Datalist child field condition
    if (customAttr.attributeType.id == 18) {
      attributeId = customAttr.parentDataListId;
      parentLinkedDataListAttribId = customAttr.id;
    } 
    else if (customAttr.moduleType == 6) {
      parentLinkedDataListAttribId = customAttr.parentLinkedDataListAttribId;
    } else if (customAttr.attributeType.id === 1 && customAttr.linkedAttributeId !== null) {
      attributeId = customAttr.linkedAttributeId;
    }
    
    //cal  api
    this.showSpinner();
    this._customAttrService.getFieldRelation(attributeId,lookupvalue).subscribe(
      res=>{        
        if(res['responseData']){
          res['responseData'] = res['responseData'].filter(ele => {
            return ele.status == 1;
          });
          let childAttribDivIds = [];

          if(res['responseData'].length>0){
            res['responseData'].forEach(element=>{
              if(customAttr.linkedAttributeId!==null) {
                const linkedField = this.customAttributesList.find(x => x.linkedAttributeId === element.id);
                if(linkedField) {
                  element.combinedAttributeName = linkedField.combinedAttributeName;
                }
              }    
              // update combined attribute name and mandatoryFlag info for datalist child fields
              if (customAttr.attributeType.id == 18) {
                element.combinedAttributeName = customAttr.combinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }    
              else if(customAttr.moduleType == 6 && customAttr.parentCombinedAttributeName) {
                element.combinedAttributeName = customAttr.parentCombinedAttributeName + element.combinedAttributeName;
                element.mandatoryFlag = customAttr.mandatoryFlag;
              }
              
              if(editdata){
                editdata.customAttributeValues.forEach(ele=>{
                  if(ele.attributeId==element.id){
                    if(ele.value.length>0){
      
                      this.addCompaniesForm.controls[element.combinedAttributeName].setValue(ele.value[0].id);
                    
                    }
                  }
                })
              }
              
              //serch for key
              var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
            
              // var divId='div_'+element.id;
              var divId = this.createDivId(element, customAttr.linkedAttributeId!==null, this.customAttributesList);
              childAttribDivIds.push(divId);

              if(removeIndex >-1){
                console.log('if big div exsits');
                console.log(this.openDivs[removeIndex]);
                console.log(divId);
                var removeIndexInside = this.openDivs[removeIndex].value.map(function(item) { return item.divId; }).indexOf(String(divId));
                console.log(removeIndexInside);

                if(removeIndexInside >-1){
                  console.log('if small div exsits');
                  //yes exists then no need to do anything
                }else{
                  console.log('if small div not exsits');
                  this.openDivs[removeIndex].value.push({
                    divId: divId, combinedAttributeName: element.combinedAttributeName, 
                    attributeTypeId: element.attributeType.id, 
                    id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                  });
  
                  var division1=<HTMLElement>document.getElementById(divId);
                  if(division1){            
                    this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                    division1.classList.remove('hidden');
                  } 
                }
                               

              }else{
                console.log('this else div');
                this.openDivs.push({
                  attributeId:attributeId,
                  value:[
                    {
                      divId: divId, combinedAttributeName: element.combinedAttributeName, 
                      attributeTypeId: element.attributeType.id, 
                      id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId}
                  ] 
                })

                var division1=<HTMLElement>document.getElementById(divId);
                console.log(division1);
                if(division1){            
                  this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1);        
                  division1.classList.remove('hidden');
                }               
          
              } 

              const childAttrib = this.findChildAttribByIdAndCombinedName(element.id, element.combinedAttributeName, customAttr.linkedAttributeId!==null);
            
              if (childAttrib) {
                childAttrib.lookupValues = element.lookupValues;
                if (childAttrib.attributeType.id === 1) { // following lines will execute for single select only as only single selects may have child fields
                  if (element.lookupValues.find(x => x.id == this.addCompaniesForm.get(childAttrib.combinedAttributeName)?.value)) {
                    this.invokeChildMethod(this.addCompaniesForm.get(childAttrib.combinedAttributeName).value, childAttrib, '');
                  }
                  else {
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib);
                  }
                }
              }

            });
            // ----------------> START:: hiding the divs of child attrib when such a value selected for parent attrib 
            // where there's no relation mapping with child attrib
            var removeIndex = this.openDivs.map(function (item) { return item.attributeId; }).indexOf(attributeId);
            let childAttribsToKeep = [];
            console.log(childAttribDivIds);
            if (removeIndex > -1) {
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res => {
                if (parentLinkedDataListAttribId===res.parentLinkedDataListAttribId && !childAttribDivIds.includes(res.divId)) {
                  var division = <HTMLElement>document.getElementById(res.divId);
                  if (division) {
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                } else {
                  childAttribsToKeep.push(res);
                }
              }); console.log(this.openDivs);
            };
            this.openDivs[removeIndex].value = childAttribsToKeep;
            // <---------------- END
          }else{
            // -> hiding the divs of child attrib whose relationships doesn't exist anymore
            var removeIndex = this.openDivs.map(function(item) { return item.attributeId; }).indexOf(attributeId);
        
            let markToRemovedChildDivIds: string[] = [];
            if(removeIndex >-1){
              //close all open divs
              this.openDivs[removeIndex].value.forEach(res=>{
                if(res.parentLinkedDataListAttribId===parentLinkedDataListAttribId) { // proceed only if parentLinkedDataListAttribId is matched
                  markToRemovedChildDivIds.push(res.divId);
                  var division=<HTMLElement>document.getElementById(res.divId);
                  if(division){      
                    const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId!==null);        
                    this.resetChildAttribsAndinvokeChildMethod(childAttrib, true);
                    division.classList.add('hidden');
                  }
                }
              });
              //remove key an pairs
              if(this.openDivs[removeIndex].value) {
                if(this.openDivs[removeIndex].value.length === markToRemovedChildDivIds.length) { // remove the whole
                  this.openDivs.splice(removeIndex, 1);           
                }
                else if(this.openDivs[removeIndex].value.length !== markToRemovedChildDivIds.length) { // remove only the mark for remove ones
                  this.openDivs[removeIndex].value = this.openDivs[removeIndex].value.filter(x => !markToRemovedChildDivIds.includes(x.divId));
                }
              }            
              // <- END      
            }
          }
        }
        this.hideSpinner();
      }
    )
  }

  findChildAttribByIdAndCombinedName(id, combinedAttributeName, isLinkedField) {
    if(isLinkedField) {
      return this.customAttributesList.find(item => item.linkedAttributeId == id);
    }
    return this.customAttributesList.find(item => item.id == id && item.combinedAttributeName == combinedAttributeName);
  }

  resetChildAttribsAndinvokeChildMethod(childAttrib, clearValidatorsFlag = false) {
    //  -> it will reset child attrib and invoke child method for the same to remove the grand child attribs
    const control = this.addCompaniesForm.get(childAttrib.combinedAttributeName);
    if(control){
      control.reset();
      if(clearValidatorsFlag) {
        control.clearValidators()
        control.updateValueAndValidity();
      }
      if([4, 15, 16].includes(childAttrib.attributeType.id)) {
        const selectedFileLength = this.fileArray[childAttrib.combinedAttributeName].files.length;
        for (let index = 0; index < selectedFileLength; index++) {
          this.removeFile(childAttrib.combinedAttributeName, 0)
        }
      }
    }
    if(childAttrib.attributeType.id == 1){
      this.invokeChildMethod(0, childAttrib, '');
    }
  }

  createDivId(customAttr, isLinkedField: boolean = false, fieldList: any[] = []): string {
    if(isLinkedField) {
      const linkedField = fieldList.find(x => x.linkedAttributeId === customAttr.id);
      if(linkedField) {
        return 'div_' + linkedField.combinedAttributeName + '_' + linkedField.id;
      }
    }
    return 'div_' + customAttr.combinedAttributeName + '_' + customAttr.id;
  }

  showSpinner() {
    if( this.spinnerCount++ === 0) {
      this.SpinnerService.show('addCompany');
    }
  }

  hideSpinner() {
    if( --this.spinnerCount === 0) {
      this.SpinnerService.hide('addCompany');
    }
  }
  
  addValidators(combinedAttributeName: string, mandatoryFlag: boolean, attributeTypeId: number, decimalFlag: boolean){
    if([10, 11, 17].includes(attributeTypeId)){
      return;
    }
    let validatorFns: ValidatorFn[] = [];
    if(mandatoryFlag){
      validatorFns.push(Validators.required);
    }
    if(attributeTypeId == 7){
      let pattern=this.numericPattern;
      if(decimalFlag) {
        pattern = this.decimalPattern;
      }
      validatorFns.push(Validators.pattern(pattern));
    }
    else if(attributeTypeId == 9){
      validatorFns.push(Validators.email);
    }
    const control = this.addCompaniesForm.get(combinedAttributeName);
    control.setValidators(validatorFns);
    control.updateValueAndValidity();
  }

  getDecimalFieldLength(value: string): number {
    if(value) {
      const wholeNumber = this.extractWholeNumber(String(value));
      if(wholeNumber !== null) {
        return String(wholeNumber).length;
      }
    }
    return 0;
  }

  extractWholeNumber(val: String): number {
    if(val!=='' && val!==null) {
      val = val.replace(/\.\d*/, '');
      if(val!=='' && val!==null && val.match(/^\d*$/) !==null) {
        return Number(val);
      }
    }
    return null;
  }

  checkFileType(file, attributeTypeId, validDocTypes) {
    if(attributeTypeId == 4){
      return file.type.match('image/jpeg|image/png');
    }
    if(attributeTypeId == 15){
      for(let type of validDocTypes){
        if(file.type.match(type.matchString)){
          return true;
        }
      }
      return false;
    }
    if(attributeTypeId == 16){
      return file.type.match('video/mp4|video/quicktime|audio/mpeg');
    }
    else{
      return false;
    }
  }

  getFileErrorMsg(attribTypeId, allowedDocs){
    if(attribTypeId == 4){
      return 'Only .jpeg, .png files allowed';
    }
    if(attribTypeId == 15){
      return `Only ${allowedDocs} files allowed`;
    }
    if(attribTypeId == 16){
      return 'Only .mp4, .mov, mp3 files allowed';
    }
    return 'Error';
  }

  getSlicedErrorFileName(name: string){
    if(name.length <=20){
      return name;
    }
    return ". . . ." + name.slice(-20);
  }

  checkUploadQueue(attr, event){
    if(--this.uploadQueue[attr] == 0){
      this.SpinnerService.hide(attr);
      this.updateFileControlValue(attr);
      event.target.value='';
      delete this.uploadQueue[attr];
      if(Object.keys(this.uploadQueue).length == 0){
        this.disabled = false;
      }
    }
  }

  updateFileControlValue(attr){
    this.addCompaniesForm.controls[attr].setValue(this.fileArray[attr].files);
  }

  processFile(event, attribName, attributeTypeId, maxImage) {
    const initialFileCount = event.target.files.length;
    if (initialFileCount > 0) {
      this.disabled = true;
      this.SpinnerService.show(attribName);
      const fileAttr = this.fileArray[attribName];
      fileAttr.hasError = false;
      this.uploadQueue[attribName] = initialFileCount;

      let uploadedFilesCount = fileAttr.files.length;
      for (let i = 0; i < initialFileCount; i++) {
        if ( !maxImage || uploadedFilesCount < maxImage) {
          if (this.checkFileType(event.target.files[i], attributeTypeId, fileAttr.validDocType)) {
            if(attributeTypeId == 4){
              this.subscriptions.push(this._us.uploadImage(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
  
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
  
                this.checkUploadQueue(attribName, event);
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName, event);
                })
              );
            }
            if(attributeTypeId == 15){
              this.subscriptions.push(this._docUpload.uploadDoc(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let fileType = res.responseData.name.split('.').pop().toLowerCase();
                fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;
  
                fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
  
                this.checkUploadQueue(attribName, event);
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(attribName, event);
                })
              );
            }
            if(attributeTypeId == 16){
              if(event.target.files[i].size < 26214400){
                this.subscriptions.push(this._docUpload.uploadDoc(this.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                  fileAttr.files.push(res.responseData.name);
                  let uploadCount = fileAttr.files.length;
                  fileAttr.uploaded = uploadCount;
    
                  fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
    
                  this.checkUploadQueue(attribName, event);
                },
                  error => {
                    fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                    fileAttr.hasError = true;
                    this.checkUploadQueue(attribName, event);
                  })
                );
              }
              else{
                fileAttr.errorMsg = 'File size limit 25 MB';
                fileAttr.hasError = true;
                this.checkUploadQueue(attribName, event);
              }
            }
              // this.subscriptions.push(fileUploadSubscription);
            uploadedFilesCount++;
          } else {
            fileAttr.errorMsg = this.getFileErrorMsg(attributeTypeId, fileAttr.allowedDocText);
            fileAttr.hasError = true;
            this.checkUploadQueue(attribName, event);
          }
        } else {
          this.uploadQueue[attribName] -= (initialFileCount-i-1);
          this.checkUploadQueue(attribName, event);
          break;
        }
      }
    }
  }

  removeFile(attr, id){
    const fileAttr = this.fileArray[attr];
    if(fileAttr){
      fileAttr.files.splice(id, 1);
      fileAttr.documentIcons?.splice(id, 1);
      let uploadCount = fileAttr.files.length;
      fileAttr.uploaded = uploadCount;
      this.customAttributesList.find(x => {
        
        fileAttr.showUploadIcon = fileAttr.maxUpload==0? true: uploadCount < fileAttr.maxUpload;
      });
    }
    fileAttr.hasError = false;
    this.updateFileControlValue(attr);
  }

  showImageModalOnly(imgURL){
    const ref = this.modalService.open(ImageViewerComponent,
      {
        centered: false,
        size : 'xl'
      }
    );
    ref.componentInstance.dataString = imgURL.replace(this.baseUrl, '');

    ref.result.then((result) => {
     // this.modalResult(result);
    });
  }

  showRefDocModal(attr, id){
    const fileAttr = this.fileArray[attr];
    if(fileAttr){
      let fileType = fileAttr.files[id].split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      if(icon){
        window.open(fileAttr.files[id]);
      }
      else{
        this.showImageModalOnly(fileAttr.files[id]);
      }
    }
  }

  OpenAlertValidation(message){
    const initialNotice = message;
     const dialogRef = this.dialog.open(AlertDialogComponent, {
       width: '600px',
       position: {top: '20px'},
       data: { title: 'Alert', body: initialNotice }
     });
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
   /** For  Select All in Multiselect */
  
   toggleAllSelection(matSelect: MatSelect,customAttr) {
    if (customAttr.allSelected) {
      matSelect.options.forEach((item: MatOption) => {(item.value!==undefined && item.value!==null) ? item.select() : null});
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick(matSelect: MatSelect,customAttr) {
    let newStatus = true;
    matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    customAttr.allSelected = newStatus;
  }
}
