import { Component, OnInit } from '@angular/core';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-dashboard-display-order',
  templateUrl: './dashboard-display-order.component.html',
  styleUrls: ['./dashboard-display-order.component.scss']
})
export class DashboardDisplayOrderComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  dashBoardList:any;
  dashBoardDisplayOrder:any;
  orderChange:boolean;
  isDisabled=false;
  

  constructor( public activeModal: NgbActiveModal, private _ds:DashboardService, private snackBar:MatSnackBar) { }

  ngOnInit(): void {
   
  }

  drop(event: CdkDragDrop<string[]>) {
    this.orderChange=true;
    moveItemInArray(this.dashBoardList, event.previousIndex, event.currentIndex);
  }

  save(){
    this.isDisabled=true;
    this.dashBoardDisplayOrder={projectId:this.selectedProject,dashboardList:this.dashBoardList};
    this._ds.editDisplayOrder(this.dashBoardDisplayOrder).subscribe(
      data => {
        this.isDisabled=false;
        const res = data['responseData'];
        this.activeModal.close('SUCCESS');
        this.snackBar.open('Display-Order Updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
      },
      error => {
        this.isDisabled=false;
        this.snackBar.open('Failed.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        this.activeModal.close('');
      }

    );

  }


  Close(){
    this.activeModal.close();
  }
}
