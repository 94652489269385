import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { environment} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppIntegrationService {

  constructor(private http:HttpClient) { }

  getConfigurationDetails(projectId:number,appId:number){
    return this.http.get( environment.baseUrl+'/setting/api/integration/getConfigurationDetails?projectId='+projectId+'&appId='+appId)
  }
  
  agentManualDial(url:string,paramsDetail: any) {
    return this.http.get(url, { params: paramsDetail });
  }
  getConfiguredAndAvailableIntegrationAppDetails(projectId: number) {
    return this.http.get(
        environment.baseUrl +'/setting/api/integration/getAvailableIntegrationAndConfiguredApp?projectId='+projectId
    );
}

}
