// Angular
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// RxJS
import { BehaviorSubject, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
// Object-Path
import * as objectPath from 'object-path';
// Services
import { PageConfigService } from './page-config.service';
import { MenuConfigService } from './menu-config.service';
import { EventEmitterService } from '../../../../views/pages/shared/services/event-emitter.service';

export interface Breadcrumb {
  title: string;
  page: string | any;
}

export interface BreadcrumbTitle {
  title: string;
  desc?: string;
  singularName?: string;
  pruralName?: string;
}

export interface rightTab {
  viewFlag: boolean;
  viewMode: number;
  tabList: Array<number>;
}

//If required in future, make it general
export interface rightTabCustomerActivity {
  viewFlag: boolean;
  viewMode:number;
  tabList: Array<number>;
}

export interface filterModal {
  showFilter: boolean;
  filterApplied: boolean;
}

export interface dashBoardIcon {
  showIcons: boolean;
  
}


@Injectable()
export class SubheaderService {
  // Public properties
  title$: BehaviorSubject<BreadcrumbTitle> = new BehaviorSubject<BreadcrumbTitle>({title: '', desc: ''});
  moduleType$:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  breadcrumbs$: BehaviorSubject<Breadcrumb[]> = new BehaviorSubject<Breadcrumb[]>([]);
  disabled$: Subject<boolean> = new Subject<boolean>();
  rightTab$: BehaviorSubject<rightTab> = new BehaviorSubject<rightTab>({viewFlag: false, viewMode:1, tabList: null});
  rightTabCustomerActivity$:BehaviorSubject<rightTabCustomerActivity> = new BehaviorSubject<rightTabCustomerActivity>({viewFlag: false, viewMode:1, tabList: null});
  gridView$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  filterButton$: BehaviorSubject<filterModal> = new BehaviorSubject<filterModal>({showFilter:false, filterApplied: false});
  openModal$: BehaviorSubject<object> = new BehaviorSubject<object>({});

  dashBoardIcon$: BehaviorSubject<dashBoardIcon> = new BehaviorSubject<dashBoardIcon>({showIcons:false});
  openDashBoardModal$:BehaviorSubject<object> = new BehaviorSubject<object>({});
  openDisplayorderModal$:BehaviorSubject<object> = new BehaviorSubject<object>({});

  dailyActivityDateRangePicker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  detailedPointsDateRangePicker$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activitiesDateRangePicker$ : BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  applyDateFilter$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  applyDateFilterForAct$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  applyDateFilterForDetailedPoints$: BehaviorSubject<object> = new BehaviorSubject<object>({});
  transactionPerformedOnActivity$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dayPlannerBulkUploadButton$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private onDatePicked: BehaviorSubject<{startDate: string; endDate: string}> = new BehaviorSubject(null);
  // Private properties
  private manualBreadcrumbs: any = {};
  private appendingBreadcrumbs: any = {};
  private manualTitle: any = {};

  private asideMenus: any;
  private headerMenus: any;
  private pageConfig: any;
  
  /**
   * Service Constructor
   *
   * @param router: Router
   * @param pageConfigService: PageConfigServie
   * @param menuConfigService: MenuConfigService
   */
  constructor(
    private router: Router,
    private pageConfigService: PageConfigService,
    private eventEmitterService: EventEmitterService,
    private menuConfigService: MenuConfigService) {
    const initBreadcrumb = () => {
      // get updated title current page config
      this.pageConfig = this.pageConfigService.getCurrentPageConfig();

      this.headerMenus = objectPath.get(this.menuConfigService.getMenus(), 'header');
      this.asideMenus = objectPath.get(this.menuConfigService.getMenus(), 'aside');

      // update breadcrumb on initial page load
      this.updateBreadcrumbs();

      if (objectPath.get(this.manualTitle, this.router.url)) {
        this.setTitle(this.manualTitle[this.router.url]);
      } else {
        // get updated page title on every route changed
        this.title$.next(objectPath.get(this.pageConfig, 'page'));

        // subheader enable/disable
        const hideSubheader = objectPath.get(this.pageConfig, 'page.subheader');
        this.disabled$.next(typeof hideSubheader !== 'undefined' && !hideSubheader);

        if (objectPath.get(this.manualBreadcrumbs, this.router.url)) {
          // breadcrumbs was set manually
          this.setBreadcrumbs(this.manualBreadcrumbs[this.router.url]);
        } else {
          // get updated breadcrumbs on every route changed
          this.updateBreadcrumbs();
          // breadcrumbs was appended before, reuse it for this page
          if (objectPath.get(this.appendingBreadcrumbs, this.router.url)) {
            this.appendBreadcrumbs(this.appendingBreadcrumbs[this.router.url]);
          }
        }
      }

      this.setRightTabConfig(false, 1, null);
      this.setRightTabConfigCustomerActivity(false, 1, null);
      this.setFilterButton(false, false);

      this.setDashBoardIcon(false);
      this.setDailyActivityDateRangePicker(false);
      this.setDetailedPointsDateRangePicker(false);
      this.setActivitiesDateRangePicker(false);

      /**
       * Disable Back Button
       */
      this.eventEmitterService.onModalCloseResult(false);

    };

    initBreadcrumb();
    
    // subscribe to router events
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(initBreadcrumb);

  }

  /**
   * Update breadCrumbs
   */
  updateBreadcrumbs() {
    // get breadcrumbs from header menu
    let breadcrumbs = this.getBreadcrumbs(this.headerMenus);
    // if breadcrumbs empty from header menu
    if (breadcrumbs.length === 0) {
      // get breadcrumbs from aside menu
      breadcrumbs = this.getBreadcrumbs(this.asideMenus);
    }

    if (
      // if breadcrumb has only 1 item
      breadcrumbs.length === 1 &&
      // and breadcrumb title is same as current page title
      breadcrumbs[0].title.indexOf(objectPath.get(this.pageConfig, 'page.title')) !== -1) {
      // no need to display on frontend
      breadcrumbs = [];
    }

    this.breadcrumbs$.next(breadcrumbs);
  }

  /**
   * Manually set full breadcrumb paths
   */
  setBreadcrumbs(breadcrumbs: Breadcrumb[] | any[]) {
    this.manualBreadcrumbs[this.router.url] = breadcrumbs;
    this.breadcrumbs$.next(breadcrumbs);
  }

  /**
   * Append breadcrumb to the last existing breadcrumbs
   * param breadcrumbs
   */
  appendBreadcrumbs(breadcrumbs: Breadcrumb[] | any[]) {
    this.appendingBreadcrumbs[this.router.url] = breadcrumbs;
    const prev = this.breadcrumbs$.getValue();
    this.breadcrumbs$.next(prev.concat(breadcrumbs));
  }

  /**
   * Get breadcrumbs from menu items
   * param menus
   */
  getBreadcrumbs(menus: any) {
    let url = this.pageConfigService.cleanUrl(this.router.url);
    url = url.replace(new RegExp(/\./, 'g'), '/');

    const breadcrumbs = [];
    const menuPath = this.getPath(menus, url) || [];
    menuPath.forEach(key => {
      menus = menus[key];
      if (typeof menus !== 'undefined' && menus.title) {
        breadcrumbs.push(menus);
      }
    });

    return breadcrumbs;
  }

  /**
   * Set title
   *
   * @param title: string
   */
  setTitle(title: string, closeModal: boolean = true) {
    this.manualTitle[this.router.url] = title;
    this.title$.next({title});

    if(title != '' && title != 'Day Planner')
    //if(title != '' && closeModal)
      this.eventEmitterService.onModalCloseResult(true);
  } 

  enableBack(flag: boolean) {
    this.eventEmitterService.onModalCloseResult(flag);
  }

  setTransactionPerformedOnActivity(anyTransactionPerformed:boolean){
    this.transactionPerformedOnActivity$.next(anyTransactionPerformed);
  }
  setModuleType(moduleType:number){
    this.moduleType$.next(moduleType);
  }

  setRightTabConfig(viewFlag: boolean, viewMode: number, tabList: Array<number> ){
    this.rightTab$.next({viewFlag, viewMode , tabList});
  }
  
  setRightTabConfigCustomerActivity(viewFlag: boolean, viewMode:number, tabList: Array<number> ){
    this.rightTabCustomerActivity$.next({viewFlag, viewMode, tabList});
  }
  setGridView(gridViewFlag: boolean){
    this.gridView$.next(gridViewFlag);
  }

  setFilterButton(showFilter: boolean, filterApplied: boolean){ 
    this.filterButton$.next({showFilter, filterApplied});
  }

  setDashBoardIcon(showIcons: boolean){ 
    this.dashBoardIcon$.next({showIcons});
  }

  openFilterModal(){
    this.openModal$.next({});
  }

  dayPlannerBulkUpload() {
    this.dayPlannerBulkUploadButton$.next(true);
  }

  public emitDateRangeChanged(minDate, maxDate){
    this.onDatePicked.next({
      startDate: minDate,
      endDate: maxDate
    })
  }
  
  public onDateRangeChange() {
    return this.onDatePicked.asObservable();
  }

  addDashBoard(){
    this.openDashBoardModal$.next({});
  }
  addDisplayOrder(){
    this.openDisplayorderModal$.next({});
  }
  setDailyActivityDateRangePicker(showPicker: boolean){
    this.dailyActivityDateRangePicker$.next(showPicker);
  }
  setDetailedPointsDateRangePicker(showPicker: boolean){
    this.detailedPointsDateRangePicker$.next(showPicker);
  }
  setActivitiesDateRangePicker(showPicker: boolean){
    this.activitiesDateRangePicker$.next(showPicker);
  }
  

  applyDateFilter(startDate, endDate){
    this.applyDateFilter$.next({startDate, endDate});
  }

  applyDateFilterForAct(startDate, endDate){
    this.applyDateFilterForAct$.next({startDate, endDate});
  }

  applyDateFilterForDetailedPoints(startDate, endDate){
    this.applyDateFilterForDetailedPoints$.next({startDate, endDate});
  }
  
  /**
   * Get object path by value
   * param obj
   * param value
   */
  getPath(obj, value) {
    if (typeof obj !== 'object') {
      return;
    }
    const path = [];
    let found = false;

    const search = (haystack) => {
      // tslint:disable-next-line
      for (let key in haystack) {
        path.push(key);
        if (haystack[key] === value) {
          found = true;
          break;
        }
        if (typeof haystack[key] === 'object') {
          search(haystack[key]);
          if (found) {
            break;
          }
        }
        path.pop();
      }
    };

    search(obj);
    return path;
  }
}
