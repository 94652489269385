import {Component,ViewChild,TemplateRef,ChangeDetectionStrategy,OnInit} from "@angular/core";
import {Gallery,GalleryItem,ThumbnailsPosition,ImageSize} from "ng-gallery";
import { Lightbox } from "ng-gallery/lightbox";
import { NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { environment} from '../../../../../environments/environment';

@Component({
  selector: 'kt-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImageViewerComponent implements OnInit {

  items: GalleryItem[];
  @ViewChild("itemTemplate", { static: true }) itemTemplate: TemplateRef<any>;

  dataString: string;
  //imageData = data;
  imageData = [];
  basePath: string;

  constructor(
    public gallery: Gallery, 
    public lightbox: Lightbox,
    public activeModal: NgbActiveModal) {}

  ngOnInit() {

    this.basePath = environment.imageUrl;
    this.imageData = this.createImageArrayList(this.dataString);

    /** Basic Gallery Example */

    // Creat gallery items
    this.items = this.imageData.map(item => {
      return {
        type: "imageViewer",
        data: {
          src: item.srcUrl,
          thumb: item.previewUrl
        }
      };
    });

    /** Lightbox Example */

    // Get a lightbox gallery ref
    const lightboxRef = this.gallery.ref("lightbox");

    // Add custom gallery config to the lightbox (optional)
    lightboxRef.setConfig({
      imageSize: ImageSize.Cover,
      thumbPosition: ThumbnailsPosition.Top,
      itemTemplate: this.itemTemplate,
      gestures: true
    });

    // Load items into the lightbox gallery ref
    lightboxRef.load(this.items);
    this.lightbox.open(0);
    this.activeModal.close();
  }

  public createImageArrayList(dataArray:string){
    const imageArr = [];
    const splitImage = dataArray.split(",");
    for(let i=0; i < splitImage.length; i++){
      console.log(splitImage[i]);
      imageArr.push(
        {
          "srcUrl": this.basePath + splitImage[i],
          "previewUrl": this.basePath + splitImage[i]
        }
      );
    }
    return imageArr;
  }

}

const data = [
  {
    srcUrl: "https://preview.ibb.co/jrsA6R/img12.jpg",
    previewUrl: "https://preview.ibb.co/jrsA6R/img12.jpg"
  },
  {
    srcUrl: "https://preview.ibb.co/kPE1D6/clouds.jpg",
    previewUrl: "https://preview.ibb.co/kPE1D6/clouds.jpg"
  },
  {
    srcUrl: "https://preview.ibb.co/mwsA6R/img7.jpg",
    previewUrl: "https://preview.ibb.co/mwsA6R/img7.jpg"
  },
  {
    srcUrl: "https://preview.ibb.co/kZGsLm/img8.jpg",
    previewUrl: "https://preview.ibb.co/kZGsLm/img8.jpg"
  }
];
