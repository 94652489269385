import { Injectable } from '@angular/core';
import { oppotunityStatus } from '../models/opportunity-status.model'
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class OpportunityStatusService {

  constructor(private http:HttpClient) { }

  getOpportunityStatusList(projectId: number){
    return this.http.post<oppotunityStatus[]>(environment.baseUrl +'/opportunity/getAllOpportunityType',{"projectId": projectId})
  }

  addOpportunityStatus(addDataValues){
    return this.http.post<oppotunityStatus>(environment.baseUrl +'/opportunity/addOpportunityType',addDataValues)
  }

  editOpportunityStatus(editDataValues){
    return this.http.post<oppotunityStatus>(environment.baseUrl +'/opportunity/editOpportunityType',editDataValues)
  }

}
