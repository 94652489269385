
<kt-portlet> 
    <kt-portlet-body>
        <mat-tab-group> 
            <mat-tab>
                <ng-template mat-tab-label >
                </ng-template>
                <ng-template matTabContent>
                    <div class="font-size-h6 mt-6" *ngIf="viewContent">
                        <p>Welcome to the configuration assistant. This assistant will help you configure and customize Assistive  according to your needs.</p>
                        <p>Please use the options at the left to navigate to the appropriate option, and come back to this assistant when you are done. 
                            While this assistant is best used in the sequence of options defined - you can choose to do it in any order that you would like.</p>
                    </div>
                </ng-template>
            </mat-tab>         
            <mat-tab *ngFor="let parent of assistantList">
                <ng-template mat-tab-label>
                   <div class="mr-4" [ngStyle]="{'width': '30px'}"> <i [ngClass]="['fa', 'fas', parent.menuIcon, 'icon-xl']"></i></div>
                    <div> {{parent.menuName}}
                        <span>{{parent.menuDescription}}</span>
                    </div>
                </ng-template>
                <ng-template matTabContent>
                    <div class="font-size-h4 pb-4">{{parent.menuName}}</div>
                    <div class="alert alert-custom alert-light-primary fade show p-4" role="alert" *ngFor="let child of parent.childList">
                        <div class="alert-text">
                            <div class=" font-size-h6 text-dark">{{child.menuName}}</div>
                            <div class="text-muted font-size-lg">{{child.menuDescription}}</div>
                        </div>
                        <div class="alert-close">
                            <span class="mr-3" *ngIf="child.greenTick">
                                <span class="symbol symbol-20 symbol-success">
                                    <span class="symbol-label" style="border-radius:0.25rem">
                                        <i class="fa fas fa-check text-white icon-nm"></i>
                                    </span>
                                </span>
                            </span>

                            <a  href="javascript:;"
                                routerLink="/{{child.urlPath}}" >
                                <span class="symbol symbol-20 symbol-primary">
                                    <span class="symbol-label" style="border-radius:0.25rem">
                                        <i class="fa fas fa-angle-right text-white icon-md"></i>
                                    </span>
                                </span>
                            </a>
                        </div>
                    </div>
                </ng-template>
            </mat-tab>  
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>

<ngx-spinner size="default" type="ball-spin-clockwise" name='setting-assistant'>  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
  
