import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { ProductFormModels } from '../models/product-form-model';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';

@Injectable({
  providedIn: 'root'
})
export class ProductFormService {

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { }

  getProductForm(projectId: number){
    return this.http.get(environment.baseUrl +'/setting/api/productForm/getSettings?projectId='+projectId);
  }

  addFormFields(addProductFormData:any){
    addProductFormData.userTime=this.userLocalization.getUserTime();
    addProductFormData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any[]>(
      environment.baseUrl+'/setting/api/productForm/addUpdateSetting',addProductFormData);
  }

  saveFormUpdateData(addProductFormData:any){
    addProductFormData.userTime=this.userLocalization.getUserTime();
    addProductFormData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any[]>(
      environment.baseUrl+'/setting/api/productForm/addUpdateSetting',addProductFormData);
  }

  getSelectedProductForm(productFormId) {
    return this.http.get<ProductFormModels[]>(
      environment.baseUrl + '/setting/api/productForm/getSetting/'+ productFormId);
  }

  getFields(moduleType, projectId: number){
    return this.http.get<any[]>(
      environment.baseUrl+'/transactions/api/customType/getFields?projectId='+projectId+'&moduleType='+moduleType);
  }

  deleteProductForm(productFormId, projectId: number){
    return this.http.delete<any[]>(
      environment.baseUrl + '/setting/api/productForm/deleteProductForm?projectId='+projectId+'&productFormId='+ productFormId);
  }

  saveSequenceOrder(sequenceData){
    return this.http.post<any[]>(environment.baseUrl+'/setting/api/productForm/sequenceProductForms', sequenceData);
  }

  validateCalcFields(projectId: number, moduleId: string | number, expression: any){
    return this.http.get<any[]>(
      environment.baseUrl+'/setting/api/calculatedField/validate?projectId='+projectId+'&moduleId='+moduleId+'&expression='+expression);
  }

  validateExpression(addAttributeData){
    console.log("URL : "+environment.baseUrl+'/setting/api/calculatedField/validate');
    return this.http.post<any[]>(
      environment.baseUrl+'/setting/api/calculatedField/validate', addAttributeData);
  }

  getProductFilterConfig( projectId: number,moduleType:number,attributeId:number){
    return this.http.get<any[]>(
      environment.baseUrl+'/transactions/api/customType/getProductFilterConfig?projectId='+projectId+'&moduleType='+moduleType+'&attributeId='+attributeId);
  }

}