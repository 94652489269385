import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ManageActivitiesModel } from '../../manage-activities/models/manage-activities.model';

@Injectable({
  providedIn: 'root'
})
export class DialerSearchService {

  constructor(private http:HttpClient) { }


  getAllActivities(projectId: number){
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId);
  }

  searchCallerId(projectId, searchText){
    return this.http.get(
      environment.baseUrl + '/transactions/api/integration/searchCaller?projectId='+projectId+ '&searchText='+searchText);
  }
  
}