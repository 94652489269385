<!--begin::Subheader-->
<link href="https://fonts.googleapis.com/icon?family=Material+Icons"
rel="stylesheet">
<div class="subheader py-2 py-lg-4" [ngClass]="subheaderClasses" id="kt_subheader">
	<div [ngClass]="subheaderContainerClasses" class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
    <!--begin::Info-->
    <div class="d-flex align-items-center flex-wrap mr-1">
      <!--begin::Page Heading-->
      <div class="d-flex align-items-baseline mr-5">
        <ng-container *ngIf="title">
          <!--begin::Page Title-->
          <h5 class="text-dark font-weight-bold my-2 mr-5">
            {{title}}
          </h5>
        </ng-container>
        <!--end::Page Title-->
    
        <!--begin::Breadcrumb-->
        <ng-container *ngIf="breadcrumbs.length > 0">
          <ul class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2">
            <li class="breadcrumb-item"><a href="#"><i class="flaticon2-shelter text-muted icon-1x"></i></a></li>
            <ng-container *ngFor="let item of breadcrumbs">
              <li class="breadcrumb-item">
                <a [routerLink]="item.page" [queryParams]="item.queryParams" class="text-muted">
                  {{item.title}}
                </a>
              </li>
            </ng-container>
          </ul>
        </ng-container>
        <!--end::Breadcrumb-->
      </div>
      <!--end::Page Heading-->
    </div>
    <!--end::Info-->
    <div class="d-flex align-items-center">
      <!-- Activities DateRange -->
      <div [style.display]="activitiesDatePicker? 'flex': 'none'" style="display: flex; border-bottom: 1px solid #DADADA;">
        <input type="text" ngxDaterangepickerMd [locale]="{format: 'DD-MM-YYYY', direction: 'ltr'}"
          [(ngModel)]="preSelectedDatesForAct" class="form-control" [dateLimit]='30' [showDropdowns]="true"
          [lockStartDate]="false" [linkedCalendars]="true" [customRangeDirection]="false"
          (ngModelChange)="ngModelChangeDateRangePickerForAct($event)" showCancel="true"
          style="padding-bottom: 0px; border: none;" />
        <span class="ngx-daterangepicker-action" style="margin-left: -8px; padding-top: 12px; cursor: pointer;"
          (click)="openDateRangePicker($event)">
          <mat-icon class="ngx-daterangepicker-action" aria-hidden="false" aria-label="Example today icon" color='primary'
            [inline]="false">today</mat-icon>
        </span>
      </div>
      <!-- end::Activities DateRange -->
    
      <!-- Detailed Points Report DateRange -->
      <div [style.display]="detailedPointsDatePicker? 'flex': 'none'"
        style="display: flex; border-bottom: 1px solid #DADADA;">
        <input type="text" ngxDaterangepickerMd [locale]="{format: 'DD-MM-YYYY', direction: 'ltr'}"
          [(ngModel)]="preSelectedDatesForDetailedPoints" class="form-control" [maxDate]='rangePickerMaxDate' [dateLimit]='91'
          [showDropdowns]="true" [lockStartDate]="false" [linkedCalendars]="true" [customRangeDirection]="false"
          (ngModelChange)="ngModelChangeDateRangePickerForDetailedPoints($event)" showCancel="true"
          style="padding-bottom: 0px; border: none;" />
        <span class="ngx-daterangepicker-action" style="margin-left: -8px; padding-top: 12px; cursor: pointer;"
          (click)="openDateRangePicker($event)">
          <mat-icon class="ngx-daterangepicker-action" aria-hidden="false" aria-label="Example today icon" color='primary'
            [inline]="false">today</mat-icon>
        </span>
      </div>
      <!-- end::Detailed Points Report DateRange -->

      <ng-container *ngIf="rightTabView">
        <!--begin::User Selection-->
        <div *ngIf="moduleType==3">
          <mat-form-field>
            <button mat-raised-button type="button" name="button" color="primary" class="roundedButton" style="width: 78%;">
            <mat-select [(value)]="selectedOpporunityView" [compareWith]="compareFn" (selectionChange)="changeOpportunityView($event.value)">
              <mat-select-trigger><mat-icon class="selection-icon"><span class="material-icons">{{selectedOpporunityView.icon}}</span></mat-icon>{{selectedOpporunityView.viewValue}}</mat-select-trigger>
              <mat-option *ngFor="let option of opportunityViewOptions" [value]="option">
                <mat-icon class="material-icons">{{option.icon}}</mat-icon>{{option.viewValue}}
              </mat-option>
            </mat-select>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="moduleType==2">
          <mat-form-field>
            <button mat-raised-button type="button" name="button" color="primary" class="roundedButton" [ngStyle]="{'width':selected2.viewValue.length>4? '165px':'120px'}">
            <mat-select [(value)]="selected2" [compareWith]="compareFn" (selectionChange)="changeUserCustomerActivity($event.value)">
              <mat-select-trigger><mat-icon class="selection-icon"><span class="material-icons">{{selected2.icon}}</span></mat-icon>{{selected2.viewValue}}</mat-select-trigger>
              <mat-option *ngFor="let option of selectedUserOptionsCustomer" [value]="option">
                <mat-icon class="material-icons">{{option.icon}}</mat-icon>{{option.viewValue}}
              </mat-option>
            </mat-select>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="moduleType!=1 && moduleType!=2 && moduleType!=3">
          <mat-form-field>
            <button mat-raised-button type="button" name="button" color="primary" class="roundedButton">
            <mat-select [(value)]="selected2" [compareWith]="compareFn" (selectionChange)="changeUserCustomerActivity($event.value)">
              <mat-select-trigger><mat-icon class="selection-icon"><span class="material-icons">{{selected2.icon}}</span></mat-icon>{{selected2.viewValue}}</mat-select-trigger>
              <mat-option *ngFor="let option of selectedUserOptionsActivities" [value]="option">
                <mat-icon class="material-icons">{{option.icon}}</mat-icon>{{option.viewValue}}
              </mat-option>
            </mat-select>
            </button>
          </mat-form-field>
        </div>
        <div *ngIf="moduleType==1 || moduleType==3">
          <mat-form-field>
            <button mat-raised-button type="button" name="button" color="primary" class="roundedButton">
            <mat-select [(value)]="selected2" [compareWith]="compareFn" (selectionChange)="changeUser($event.value)">
              <mat-select-trigger><mat-icon class="selection-icon"><span class="material-icons">{{selected2.icon}}</span></mat-icon>{{selected2.viewValue}}</mat-select-trigger>
              <mat-option *ngFor="let option of selectedUserOptions" [value]="option">
                <mat-icon class="material-icons">{{option.icon}}</mat-icon>{{option.viewValue}}
              </mat-option>
            </mat-select>
            </button>
          </mat-form-field>
        </div>
        <!--end::User Selection-->
      </ng-container>
      <!-- smn -->
      <ng-container *ngIf="filterModalView">
        <span class="symbol symbol-35 symbol-light-success kt-custom-filter-button">
        <span class="symbol-label font-size-h5 font-weight-bold" (click)="openFilterModal()" *ngIf="!filterApplied"><img src="/assets/media/svg/icons/Text/Filter.svg" class="kt-custom-filter-icon"></span>
        <span class="symbol-label font-size-h5 font-weight-bold" (click)="openFilterModal()" *ngIf="filterApplied"><img src="/assets/media/svg/icons/Text/Filter-Ticked.svg"></span>
        <!-- <span style="position: absolute; top: -2px; right: 3px;" *ngIf="filterApplied">✔</span> -->
      </span>
      </ng-container>
      <!-- end::smn -->

      <!--Add-dashBoard-->
      <ng-container *ngIf="dashBoardIconView">
        <span class="symbol symbol-30 mr-3">
        <span class="symbol-label font-size-h5 font-weight-bold" style="cursor:pointer;"><i class="fas fa-plus text-primary icon-md" (click)="addDashBoard()"></i></span>
        </span>
        <span class="symbol symbol-30">
        <span class="symbol-label font-size-h5 font-weight-bold" style="cursor:pointer;"><i class="fas fa-align-justify text-primary icon-md" (click)="addDisplayOrder()"></i></span>
      </span>
      </ng-container>
      <!-- Add-dashBoard -->

      <!-- Daily Activity DateRange -->
      <div [style.display]="dailyActivityDatePicker? 'flex': 'none'" style="display: flex; border-bottom: 1px solid #DADADA;">
        <input type="text" ngxDaterangepickerMd [locale]="{format: 'DD-MM-YYYY', direction: 'ltr'}"
          [(ngModel)]="preSelectedDates" class="form-control" [maxDate]='rangePickerMaxDate' [dateLimit]='91'
          [showDropdowns]="true" [lockStartDate]="false" [linkedCalendars]="true" [customRangeDirection]="false"
          (ngModelChange)="ngModelChangeDateRangePicker($event)" showCancel="true"
          style="padding-bottom: 0px; border: none;" />
        <span class="ngx-daterangepicker-action" style="margin-left: -8px; padding-top: 12px; cursor: pointer;"
          (click)="openDateRangePicker($event)">
          <mat-icon class="ngx-daterangepicker-action" aria-hidden="false" aria-label="Example today icon" color='primary'
            [inline]="false">today</mat-icon>
        </span>
      </div>
      <!-- end::Daily Activity DateRange -->
      
      <!-- Day Planner Bulk Upload Button -->
      <div *ngIf="title == 'Day Planner'" class = "button-container pr-7">
        <button type="button" mat-raised-button color="primary" (click)="dayPlannerBulkUpload()">Upload</button>
      </div>
      <!--begin::Daterange-->
      <ng-container *ngIf="title == 'Day Planner' && selected">
        <div class="form-group row drp-ngx">
          <div class="col-lg-12">
            <input (click)="d.toggle()" ngbDatepicker #d="ngbDatepicker" 
            [(ngModel)]="selected"
            placeholder="Choose date"
            class="form-control date-cls"
            (dateSelect)="applyFilter($event)"
            />
            <i class="fa fas fa-calendar position-absolute cal-class"></i>
          </div>
        </div>
      </ng-container>
      <!--end::Daterange-->
      <!-- <mat-date-range-input matInput [rangePicker]="picker">
                <input matStartDate matInput placeholder="Start Date">
                <input matEndDate matInput placeholder="End Date">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker> -->
      <!--<ng-container *ngIf="displayDaterangepicker">
        <a href="#" class="btn btn-light btn-sm font-weight-bold mr-3" id="kt_dashboard_daterangepicker" data-toggle="tooltip" title="Select dashboard daterange" data-placement="left">
          <span class="text-muted font-weight-bold mr-2" id="kt_dashboard_daterangepicker_title">Today</span>
          <span class="text-primary font-weight-bold" id="kt_dashboard_daterangepicker_date">Aug 16</span>
        </a>
      </ng-container>-->
      <!--end::Daterange-->

      <!--begin::Actions-->
      <!--<a href="#" class="btn btn-light font-weight-bold btn-sm">
        Actions
      </a>-->
      <!--end::Actions-->

      <!--begin::Dropdown-->
      <!--<div ngbDropdown [placement]="'bottom-right'"  class="dropdown dropdown-inline" ngbTooltip="Quick actions">
				<a ngbDropdownToggle href="javascript:;" class="btn btn-icon">
					<span class="svg-icon svg-icon-success svg-icon-2x" [inlineSVG]="'./assets/media/svg/icons/Files/File-plus.svg'"></span>
				</a>
				<div ngbDropdownMenu class="dropdown-menu p-0 m-0 dropdown-menu-md dropdown-menu-right">-->
          <!--begin::Navigation-->
          <!--<ul class="navi navi-hover">
            <li class="navi-header font-weight-bold py-5">
              <span class="font-size-lg">Choose Label:</span>
              <i class="flaticon2-information icon-md text-muted" data-toggle="tooltip" data-placement="right" title="Click to learn more..."></i>
            </li>
            <li class="navi-separator mb-3 opacity-70"></li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-success">Customer</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-danger">Partner</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-warning">Suplier</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-primary">Member</span>
            </span>
              </a>
            </li>
            <li class="navi-item">
              <a href="#" class="navi-link">
            <span class="navi-text">
                <span class="label label-xl label-inline label-light-dark">Staff</span>
            </span>
              </a>
            </li>
            <li class="navi-separator mt-3 opacity-70"></li>
            <li class="navi-footer pt-5 pb-4">
              <a class="btn btn-clean font-weight-bold btn-sm" href="#">
                <i class="ki ki-plus icon-sm"></i>
                Add new
              </a>
            </li>
          </ul>-->
          <!--end::Navigation-->
				<!--</div>
			</div>-->
      <!--end::Dropdown-->
    </div>
	</div>
</div>
<!--end::Subheader-->
