// Angular
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// RxJS
import { finalize, takeUntil, tap } from 'rxjs/operators';
// Translate
import { TranslateService } from '@ngx-translate/core';
// NGRX
import { Store } from '@ngrx/store';
import { AppState } from '../../../../core/reducers';
// Auth
import { AuthNoticeService, AuthService, UserLoaded, Login, User } from '../../../../core/auth/';
import { Observable, Subject } from 'rxjs';
import { ConfirmPasswordValidator } from './confirm-password.validator';
import { ActivatedRoute } from '@angular/router';

import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AuthDialogComponent } from '../auth-dialog/auth-dialog.component'
//material
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ProjectSelectionComponent } from '../project-selection/project-selection.component'
import { EventEmitterService } from '../../shared/services/event-emitter.service';

@Component({
	selector: 'kt-authenticate-token',
  	templateUrl: './authenticate-token.component.html',
  	encapsulation: ViewEncapsulation.None
})
export class AuthenticateTokenComponent implements OnInit, OnDestroy {
	registerForm: FormGroup;
	loading = false;
	errors: any = [];
	
	name:string;
	userEmail:string;
	tokenParam: string;
	returnUrl: any;
	currentRouteUrl: any = '';
	authFor: string;
	loginForm: FormGroup;
	isLoggedIn$: Observable<boolean>;
	projectId: any;
	private menuObj:any = {};
  
  

	private unsubscribe: Subject<any>; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param router: Router
	 * @param auth: AuthService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 */
	constructor(
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private router: Router,
		private auth: AuthService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private activatedRoute: ActivatedRoute,
		private dialog: MatDialog,
		private modalService: NgbModal,
		private eventEmitterService: EventEmitterService,
	) {
		this.unsubscribe = new Subject();
	}

	/*
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
    */

	/**
	 * On init
	 */
	ngOnInit() {
	
		this.currentRouteUrl = this.router.url;
		console.log(this.currentRouteUrl);
		if(this.currentRouteUrl.search('resetpassword-token') == -1){
			this.authFor = 'Create-Password';
		} else {
			this.authFor = 'Reset-Password';
		}
		console.log(this.authFor);
		
		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe(params => {
		this.returnUrl = params.returnUrl || '/';
		});

		const routeSubscription =  this.activatedRoute.params.subscribe(params => {
		this.tokenParam = params.token;
		console.log('Token : '+this.tokenParam);

		});

		if(this.authFor === 'Reset-Password'){
			this.validateResetPassToken();
		} else {
			this.validateToken();
		}

	}

	/*
    * On destroy
    */
	ngOnDestroy(): void {
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
  }
  
  /**
   * Token Validation
   */
  validateToken(){
    this.auth.validateToken(this.tokenParam).subscribe(
        data=>{
          console.log(data);
          if(data.statusCode == 200){
              this.userEmail = data.responseData.email;
              this.name = data.responseData.firstName;
              this.initRegisterForm();
          } else {
              this.authNoticeService.setNotice(data.message, 'danger');
              this.router.navigateByUrl('/auth/login');
          }
        },
        err=>{
          //this.errorText=true;
          console.log('Error');
        }
      )
	}
	
	validateResetPassToken(){
		this.auth.validateResetPassToken(this.tokenParam).subscribe(
			data=>{
			  console.log(data);
			  if(data.statusCode == 200){
				  this.userEmail = data.responseData.userName;
				  this.name = data.responseData.firstName;
				  this.initRegisterForm();
			  } else {
				  this.authNoticeService.setNotice(data.message, 'danger');
				  this.router.navigateByUrl('/auth/login');
			  }
			},
			err=>{
				this.authNoticeService.setNotice('This link is no longer valid. Please request password reset link again.', 'danger');
				this.router.navigateByUrl('/auth/login');
			  console.log('Error');
			}
		  )
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initRegisterForm() {
		this.registerForm = this.fb.group({
			username: [this.userEmail, Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			]),
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50)
			])
			],
			confirmPassword: ['', Validators.compose([
				Validators.required,
				Validators.minLength(8),
				Validators.maxLength(50)
			])
			],
		//	agree: [false, Validators.compose([Validators.required])]
		}, {
			validator: ConfirmPasswordValidator.MatchPassword
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.registerForm.controls;

		// check form
		if (this.registerForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		this.loading = true;
		const _user: User = new User();
		_user.clear();
		_user.username = controls.username.value;
		_user.email = controls.username.value;
		_user.password = controls.password.value;

		console.log('UserName : '+ _user.username + ', Password : '+ _user.password);

		if(this.authFor === 'Reset-Password'){
			this.resetPassword(_user);
		} else {
			this.createPassword(_user);
		}
  }

  createPassword(_user:User){
	this.auth.createPassword(_user).pipe(
		tap(user => {
			if (user) {
				if(user.statusCode == 200){
					const authData = {
						username: _user.username,
						password: _user.password
					};
					this.initiateLogin(authData);
				} else {
					this.authNoticeService.setNotice(user.message, 'danger');
					this.router.navigateByUrl('/auth/login');
				}
			} else {
				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				this.router.navigateByUrl('/auth/login');
			}
		}),
		takeUntil(this.unsubscribe),
		finalize(() => {
			this.loading = false;
			this.cdr.markForCheck();
		})
	).subscribe();
  }

  resetPassword(_user:User){
	this.auth.resetPassword(_user).pipe(
		tap(user => {
			if (user) {
				if(user.statusCode == 200){
					const authData = {
						username: _user.username,
						password: _user.password
					};
					this.initiateLogin(authData);
				} else {
					this.authNoticeService.setNotice(user.message, 'danger');
					this.router.navigateByUrl('/auth/login');
				}
			} else {
				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
				this.router.navigateByUrl('/auth/login');
			}
		}),
		takeUntil(this.unsubscribe),
		finalize(() => {
			this.loading = false;
			this.cdr.markForCheck();
		})
	).subscribe();
  }

/**
 * 
 * @param authData 
 */
  initiateLogin(authData){
    this.auth
    .login(authData.username, authData.password)
    .pipe(
      tap(res => {
        if (res) {
			var userObj = new User;
			userObj = res.user;
			userObj.token = res.token;
			this.store.dispatch(new UserLoaded({user: userObj}));

			console.log("User Project Length : "+ userObj.userProject.length);
			if(userObj.userProject.length > 1 && (userObj.defaultProjectId == null || userObj.defaultProjectId == 0)) {
				this.selectProject(userObj);
			} else {
				if(+userObj.defaultProjectId > 0) {
					this.fetchUserMenu(userObj);
				} else {
					userObj.defaultProjectId = res.user.userProject[0].project.projectId;
					this.fetchUserMenu(userObj);
				}
			}
        } else {
          this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
        }

      }),

      takeUntil(this.unsubscribe),
      finalize(() => {
        this.loading = false;
        this.cdr.markForCheck();
      })
    )
    .subscribe(
      user => {
		console.log('Login Sucessfulll');
		if(this.authFor === 'Create-Password'){
			this.router.navigateByUrl('/settings-assistant');
		} else {
			this.router.navigateByUrl(this.returnUrl); // Main page
		}
      },
      error => {
        this.loading = false;
        this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');

      }
    );
  }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.registerForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result = control.hasError(validationType) && (control.dirty || control.touched);
		return result;
	}

	/**
	 * 
	 * @param loginResult 
	 */
	selectProject(user: User){
		const ref=this.modalService.open(ProjectSelectionComponent,
			{
			  centered: false,
			  size : 'md',
			  backdrop : 'static'
			});
		ref.componentInstance.userDetail=user;
	}

	/**
	 * 
	 * @param user 
	 */
	fetchUserMenu(user: User){
		this.projectId = user.defaultProjectId;

		return this.auth.getUserMenu(this.projectId).subscribe(
			async data=>{
					const userMenu = this.finalizeUserMenu(data['responseData']);
					this.createPageHeading(userMenu);
					const finalMenu: any = {
										header: {
											self: {},
											items: userMenu
										}
									}

					this.store.dispatch(new Login({authToken: user.token})); //user.accessToken
					localStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('userProject', JSON.stringify(user.userProject));
					localStorage.setItem('userMenu', JSON.stringify(finalMenu));
					localStorage.setItem('selectedProject', this.projectId);
					console.log("Past local storage : "+localStorage.getItem('selectedProject'));

					if(this.authFor === 'Create-Password'){
						//this.router.navigateByUrl('/settings-assistant');
						window.location.href = window.location.origin+"/settings-assistant";
					} else {
						this.router.navigateByUrl(this.returnUrl); // Main page
					}
			}
		  )
	}

	finalizeUserMenu(menuData: any){
		for(let res of menuData) {
			if(res.root == true) {
				res.alignment = 'left';
				res.toggle = 'click';
			}
		}
		return menuData;
	}

	/**
	 * 
	 * @param userMenu 
	 */
	createPageHeading(userMenu: any)
	{
		/* First Level */
		for(let res of userMenu){
			if(res.page && res.page != '#'){
			  this.generatePageHeading(res);
			}
			/* Second Level */
			if(res.submenu){
			  for(let res1 of res.submenu){
				if(res1.page && res1.page != '#'){
				  this.generatePageHeading(res1);
				}
				/* Third Level */
				if(res1.submenu){
				  for(let res2 of res1.submenu){
					if(res2.page && res2.page != '#'){
					  this.generatePageHeading(res2);
					}
					/* Forth Level */
					if(res2.submenu){
					  for(let res3 of res2.submenu){
						if(res3.page && res3.page != '#'){
						  this.generatePageHeading(res3);
						}
					  }
					}
				  }
				}
			  }
			}
		  }

		  localStorage.setItem('pageTitle', JSON.stringify(this.menuObj));
	}

	/**
	 * 
	 * @param menu 
	 */
	generatePageHeading(menu:any)
	{
		let jsonVal = {};
    	let pageName = menu.page.replace(/\//g, '');

		jsonVal = {
					"page" : {
						"title": menu.title,
						"desc": '',
						"singularName":menu.singularName,
						"pruralName": menu.pruralName
					}
				};
			this.menuObj[pageName] = jsonVal;
	}
}
