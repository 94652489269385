<div *ngIf="ShowModal" class="example-preview">
    <div>
        <form [formGroup]="addCompaniesForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Edit {{singularName}}</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-4">
                      <label class="col-form-label form-padding-top-25">Serial No.</label>
                    </div>
                    <div class="col-md-8">
                      <mat-form-field class="mat-form-field-fluid">
                        <input matInput formControlName="serialNo" ktAutoTrimSpaces>
                        <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">lock</mat-icon> 
                      </mat-form-field>                                            
                    </div>
                  </div>                  

                <div id="div_{{customAttr.combinedAttributeName}}_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0 && customAttr.parentAttributeId !==null && customAttr.isEdit}" class="form-group row" *ngFor="let customAttr of customAttributesList">

                    <div class="col-md-4" *ngIf="!customAttr.isHidden">
                        <label class="col-form-label form-padding-top-25" [ngClass]="{'header-text': customAttr.attributeType.id == 10, 'normal-text': customAttr.attributeType.id == 11}">{{customAttr.attributeName}}
                            <span class="mandatory-marker">{{customAttr.mandatoryFlag === 1 ? '*' : ''}}</span>
                        </label>
                    </div>
                                                                
                    <!-- first div -->
                    
                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21); else systemAttrbitues ">
                        <mat-form-field appearance="standard" class="input-100"  *ngIf="!customAttr.isHidden">
        
                            <mat-select [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr,'')">
                                
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                        
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>        
                             <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                        
                        </mat-form-field>                    
                    </div>                   

                    <!-- second div -->
                        
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                            <mat-select #select1 [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr,'') : ''">
                                
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <div class="select-all">
                                    <mat-checkbox [(ngModel)]="customAttr.allSelected"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(select1,customAttr)">Select All
                                    </mat-checkbox>
                                  </div>
                                <mat-option (click)="optionClick(select1,customAttr)" *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>         

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                        </mat-form-field>
                                        
                    </div>

                        <!-- third div -->
                    
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6 ||customAttr.attributeType.id==20">
                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>         
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-error *ngIf="customAttr.attributeType.id==6 && isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </mat-form-field>                                            
                    </div>
    
                    <!-- fourth div -->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5 && !customAttr.isHidden">
                        <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                            <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                {{values.lookupValue}}
                            </mat-radio-button>
                                                                                                                                                                                                        
                        </mat-radio-group>  
                        <mat-hint>{{customAttr.instructions}}</mat-hint>       

                        <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                        <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>

                    </div>
    
                    <!-- fifth div -->
                
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                        <div *ngIf="customAttr.numericValidationType==1">
                        <div *ngIf="customAttr.maxDigits!=null">
                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                            <input maxlength="{{customAttr.decimalFlag?customAttr.maxDigits+3:customAttr.maxDigits}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                            <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>          
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                            <mat-hint  align="end">{{customAttr.decimalFlag?getDecimalFieldLength(message.value):message.value.length}} / {{customAttr.maxDigits}}</mat-hint>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </mat-form-field> 
                        </div> 
                        <div *ngIf="customAttr.maxDigits==null">
                            <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>        
                                <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message.value.length}} </mat-hint>
                                <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                            </mat-form-field> 
                            </div> 
                        </div>
                        <div *ngIf="customAttr.numericValidationType==2">
                            <div *ngIf="customAttr.maxNumber!=null">
                                <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                    <input maxlength="{{customAttr.maxNumber.toString().length}}"  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                    <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}} / {{customAttr.maxNumber.toString().length}}</mat-hint>
                                    <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                                </mat-form-field>  
                            </div>
                            <div *ngIf="customAttr.maxNumber == null">
                                <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                    <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                    <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>         
                                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                    <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                    <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                                </mat-form-field>  
                            </div>

                        </div>
                        <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                            <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                                <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>        
                                <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                                <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                                <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                            </mat-form-field>  
                        </div>
                           
                        
                    </div>

                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==8">
                        <mat-form-field appearance="standard" class="input-100"  *ngIf="!customAttr.isHidden">
                            <textarea maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" matInput #message placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            <mat-icon *ngIf="customAttr.isEdit"  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </mat-form-field>
                                                                    
                    </div>

                        <!-- six div -->
                    
                   <!-- <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                        <mat-form-field class="example-full-width">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                            <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                        </mat-form-field>
                                            
                    </div>  -->
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMaxFlag && !DisplayDateMinFlag && !DisplayDateFlag">
                        <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>

                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMinFlag && !DisplayDateMaxFlag && !DisplayDateFlag">
                        <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag ">
                        <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                        </mat-form-field>
                                            
                    </div>
                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && !DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag">
                        <mat-form-field class="example-full-width"  *ngIf="!customAttr.isHidden">
                            <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [matDatepicker]="picker">
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker></mat-datepicker>
                            
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                        </mat-form-field>
                                            
                    </div>

                     <!-- fourth div (Image) -->

                     <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4 && !customAttr.isHidden">
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                                <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                    (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                
                                <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                    <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">

                                        <div class="artist-collection-photo">
                                            <div class="remove-img-btn" *ngIf="isEditAllowed"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                            </button></div>
                                            <img width="90" height="100" [src]= "baseUrl + file" alt="image.jpg" class="img-thumbnail" (click)="showImageModalOnly(file)">
                                            <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div> 
                                            <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                        </div>

                                    </span>
                                    <div *ngIf="!isEditAllowed && fileArray[customAttr.combinedAttributeName].uploaded==0" class="pt-6">No File Uploaded.</div>
                                </span>
                                <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                    <div class="artist-collection-photo" *ngIf="isEditAllowed">
                                        <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                        <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                            {{"0"}}/{{customAttr.maxImagesAllowed}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        <div class="mat-form-field-subscript-wrapper">
                            <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                            </ng-container>

                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank.</mat-error>
                            <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </div>

                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                            
                    </div>

                     <!-- nineth div (Email) -->
                     <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                        <mat-form-field class="mat-form-field-fluid"  *ngIf="!customAttr.isHidden">
                            <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon *ngIf="customAttr.isEdit" matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                            <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </mat-form-field>                                            
                    </div>
                    
                    <!-- tenth div (document upload) -->
                        
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==15 && !customAttr.isHidden">
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                                <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                    (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                
                                <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                    <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">
    
                                        <div class="artist-collection-photo">
                                            <div class="remove-img-btn" *ngIf="isEditAllowed"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                            </button></div>
                                            <a href="{{baseURL + fileArray[customAttr.combinedAttributeName]?.files[i]}}" target="_blank">
                                                <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail">
                                            </a>
                                            <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                            <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                        </div>
    
                                    </span>
                                    <div *ngIf="!isEditAllowed && fileArray[customAttr.combinedAttributeName].uploaded==0" class="pt-6">No File Uploaded.</div>
                                </span>
                                <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                    <div class="artist-collection-photo" *ngIf="isEditAllowed">
                                        <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                        <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                            {{"0"}}/{{customAttr.maxImagesAllowed}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        <div class="mat-form-field-subscript-wrapper">
                            <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                            </ng-container>
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank.</mat-error>
                            <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </div>
    
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                            
                    </div>

                    <!-- eleventh div (video upload) --> 
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==16 && !customAttr.isHidden">
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                                <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                    (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                                
                                <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                    <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">
    
                                        <div class="artist-collection-photo">
                                            <div class="remove-img-btn" *ngIf="isEditAllowed || fileArray[customAttr.combinedAttributeName]?.showDeleteOption"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)" *ngIf="fileArray[customAttr.combinedAttributeName]?.showDeleteOption">
                                                <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                            </button></div>
                                            <a href="{{baseURL + fileArray[customAttr.combinedAttributeName]?.files[i]}}" target="_blank">
                                                <img width="90" height="100" src="./assets/media/svg/icons/General/file-alt-solid.svg" alt="image.jpg" class="img-thumbnail">
                                            </a>
                                            <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                            <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                        </div>
    
                                    </span>
                                    <div *ngIf="!isEditAllowed && fileArray[customAttr.combinedAttributeName].uploaded==0" class="pt-6">No File Uploaded.</div>
                                </span>
                                <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                                    <div class="artist-collection-photo" *ngIf="isEditAllowed">
                                        <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                        <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                            {{"0"}}/{{customAttr.maxImagesAllowed}}
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <div class="mat-form-field-underline ng-star-inserted">
                            <span class="mat-form-field-ripple"></span>
                        </div>
                        <div class="mat-form-field-subscript-wrapper">
                            <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                                <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                            </ng-container>
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank.</mat-error>
                            <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </div>
    
                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                            
                    </div>

                    <!-- twelfth div (reference document) --> 
                    <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==17 && !customAttr.isHidden">
                        <div class="mat-form-field">
                            <div class="mat-form-field-wrapper">
                                
                                <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                                    <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">
    
                                        <div class="artist-collection-photo">
                                            <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail" (click)="showRefDocModal(customAttr.combinedAttributeName, i)">
                                        </div>
    
                                    </span>
                                </span>
                            </div>
                        </div> 
                        <div class="mat-form-field-subscript-wrapper">

                            <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                             <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                             <!-- <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" > {{customAttr.instructions}} (Optional) </mat-hint>
                            <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >Optional </mat-hint>
                        
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                            <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error> -->     
                        </div>                    
                    </div>

                    <!-- thirteenth div (Data list field) -->

                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==18" >
                        <mat-form-field  appearance="standard" class="input-100" *ngIf="!customAttr.isHidden">
        
                            <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr,'') : ''">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #dataListFieldFilter ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let values of customAttr.lookupValues| search : dataListFieldFilter.value : 'lookupValue'" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-option>
                            </mat-select>
                        
                            <mat-hint>{{customAttr.instructions}}</mat-hint>     
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one.</mat-error>  
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>       
            
                        </mat-form-field>                    
                    </div> 

                    <div class="col-md-8" *ngIf="customAttr.attributeType.id==21 ">
                        <mat-form-field class="example-full-width" *ngIf="!customAttr.isHidden">
                            <input matInput type="datetime-local" 
                             min="{{customAttr.dateAttributes.minDate}}" max="{{customAttr.dateAttributes.maxDate}}" [formControlName]="customAttr.combinedAttributeName" placeholder="" onkeydown="return false;">
                            <mat-hint>{{customAttr.instructions}}</mat-hint>
                    
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                        </mat-form-field>
                    </div>


                    <div class="col-md-8"  *ngIf="customAttr.attributeType.id==22">
                        <mat-form-field class="mat-form-field-fluid" *ngIf="!customAttr.isHidden">
                            <input #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces type="time" step="1" onkeydown="return false;">

                            <mat-hint>{{customAttr.instructions}}</mat-hint>         
                            
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                            <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                            <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                            
                        </mat-form-field>                                            
                    </div>

<!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21">
                            <div class="row" *ngIf="!customAttr.isHidden">
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>

                                        <mat-hint>Select country.
                                        </mat-hint>
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank.</mat-error>
                                        <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 

                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank.</mat-error>
     
                             <mat-hint>Select state.
                                        </mat-hint>
                                         <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank.</mat-error>
                
                                        <mat-hint>Select city.
                                        </mat-hint>
                                         <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                    </ng-template>

                    <!--      =========================system Attributes block================================= -->

                    <ng-template #systemAttrbitues >
                        <div class="col-md-8"  *ngIf="customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21 ">
                            <div class="row" *ngIf="!customAttr.isHidden">
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint>Select country.
                                        </mat-hint>
                        
                                        <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank.</mat-error>
                                        <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank.</mat-error>

                                        <mat-hint>Select state.
                                        </mat-hint>
                                         <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon> 

                                    </mat-form-field>
                                </div>
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select formControlName="cityName">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank.</mat-error>
                 

                                        <mat-hint>Select city.
                                        </mat-hint>
                                        <mat-icon  *ngIf="!customAttr.isEdit" matSuffix [ngStyle]="{'color':'#808080'}">lock</mat-icon>  
                        
                                    </mat-form-field>
                                </div>                                       
                            </div>                                                        
                        </div>
                      
                    </ng-template>

                    <ng-template #systemAttrbituesM >
                
                        <div class="col-md-12 table_scroll" *ngIf="customAttr.systemAttribute.id==28">
                            <div >
                                <div class="form-group row" formArrayName="skuItems">
                                    <div class="row">
                                        <td>                                            
                                            <tr>
                                                <table class="style">
                                                    <tr>
                                                        <th>Product</th>
                                                        <th>Quantity</th>
                                                        <th>List Price</th>
                                                        <th>Selling Price</th>
                                                        <th>Discount %</th>
                                                        <th>Tax %</th>
                                                        <th>Total</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                        <td>
                                                            <mat-form-field class="inputSelect">
                                                                <mat-select formControlName="sku" required>
                                                                    <!-- <mat-option>
                                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="skuFilterType" ></ngx-mat-select-search>
                                                                    </mat-option> -->
                                                                    <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                        {{sku.productName}}
                                                                    </mat-option>
                                                                </mat-select>
                                                                
                                                             </mat-form-field>
                                                        </td>
                                                            <td>
                                                                <mat-form-field  class="inputbox"> 
                                                                    <input matInput formControlName="quantity" required ktStrictNumberOnlyDirective >  
                                                                    <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective> 
                                                                    <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint> 
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="sellingPrice" required ktStrictNumberOnlyDirective>  
                                                                    <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                                    <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="tax" required ktTwoDigitDecimaNumberDirective>  
                                                                </mat-form-field>
                                                            </td>
                                                            <td>
                                                                <mat-form-field class="inputbox"> 
                                                                    <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                                    <mat-hint class="pad-right">Only numeric values are allowed. </mat-hint>
                                                                </mat-form-field>
                                                            </td>
                                                            <td style="width:90px;"> 
                                                                <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                                    <i class="fas fa-plus" (click)="addTable()"></i>
                                                                </button>
                                            
                                                                <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                                    <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                                </button>
                                                               
                                                            </td>
                                                            
                                                  </tr>
                                                
                                                </table>
                                             </tr>
                                            
                                           
                                           <!-- <tr>
                                                <td>
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-9">
                                                                <label class="col-form-label form-padding-top-15"></label>
                                                            </div> 
                                                            <div class="col-md-3">
                                                                <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{totalSum}}</label>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>  -->
                                        </td>
                                    </div>  
                                   
                                </div>                                   
                            </div>  
                            <!-- <mat-error *ngIf="isControlHasError('sku', 'required')">Please select product</mat-error> -->
                            <mat-error *ngIf="addProductError">Please select product</mat-error>
                          
                        </div>
                    </ng-template>
                        
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>
                    
                </div>
            
            </div>
            <div class="modal-footer" *ngIf="isSubmitShow">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]='disabled || this.spinnerCount !== 0' *ngIf="isEditAllowed" (click)="Save()">Save</button>
               
            </div>
        </form> 
    </div>
</div>  
    
<ngx-spinner class="SpinnerLoading" bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="addCompany">  
    <p class="SpinnerFetch">Fetching Data...</p>  
</ngx-spinner> 