import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})

export class UploadImageService {
  imagePreview:any;
  selectedFile: File;
  imagePath:any;
  constructor(private http:HttpClient) { }

  validatingImage(event) {
    this.selectedFile = event.target.files[0];
    if(this.selectedFile.type =="image/jpeg"|| this.selectedFile.type =="image/jpg"){
      if(this.selectedFile.size){
 
        return {
          'isValidated':true,
          'image': this.selectedFile
        }
        
      }else{
        return {
          'isValidated':false,
          'image': ''
        }
      }
    }else{
      return {
        'isValidated':false,
        'image': ''
      }
    }
    
   }
   
  uploadImage( moduleType: Number, image: File, projectId: number): Observable<any> {
      const formData: any = new FormData();
      formData.append("projectId", projectId);
      formData.append("moduleType", moduleType);
      formData.append("image", image);
  
      console.log(formData);
      return this.http.post(environment.baseUrl + '/transactions/api/customType/uploadFieldImage', formData).pipe(
        catchError(this.errorMgmt)
      ) 
    }
  
    errorMgmt(error: HttpErrorResponse) {
      let errorMessage = '';
      if (error.error instanceof ErrorEvent) {
        // Get client-side error
        errorMessage = error.error.message;
      } else {
        // Get server-side error
        errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
      console.log(errorMessage);
      return throwError(errorMessage);
    }
}
