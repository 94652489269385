import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { roleModel } from '../models/role.model';
import { BehaviorSubject } from 'rxjs';
import { menuItemNode } from '../models/menu.model';
@Injectable({
  providedIn: 'root'
})


export class RoleService {

  constructor(private http:HttpClient) {

   }
   
  getRoles(projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/setting/api/roles/?projectId='+projectId)
  }

  geMenuList(roleId, projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/login/api/menu/web/v2?projectId='+projectId+'&roleId='+roleId);
  }

  geMenuListForMobile(roleId, projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/login/api/menu/mobile/v2?projectId='+projectId+'&roleId='+roleId);
  }

  geMenuListForReport(roleId, projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/login/api/menu/reportBuilder?projectId='+projectId+'&roleId='+roleId);
  }

  saveRoles(data){
    return this.http.post<roleModel[]>(environment.baseUrl +'/setting/api/roles',data);
  }

  getRoleDetails(roleId, projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/setting/api/roles/'+roleId+'?projectId='+projectId);
  }

 
}
