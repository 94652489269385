<div>
    <form [formGroup]="cloneDashForm" autocomplete="off" >
        <div class="modal-header">
            <h4 class="modal-title">Clone Dashboard</h4>
            <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group row">
                <div class="col-md-12">
                    <div class="row" style="margin-bottom: 30px;">
                <div class="col-md-4">
                    <label class="col-form-label form-padding-top-25">Name
                        <span style="color: red; display: inline;">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="standard" class="input-100" >
                        <input matInput #input1 maxlength="25" formControlName="name" ktAutoTrimSpaces>
                        <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/25</mat-hint>
                        <mat-error *ngIf="isControlHasError('name', 'required')">Name cannot be blank</mat-error>
                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>
        </div>
        <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"(click)="save()" >Save</button>
        </div>
    </form> 
</div>