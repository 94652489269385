import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs/internal/Subscription';
import { environment } from 'src/environments/environment';
import { countriesModel } from '../../../company-management/models/countries.model';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import { roleModel } from '../../../user-role/models/role.model';
import { RoleService } from '../../../user-role/services/role.service';
import { AlertDialogComponent } from '../../alert-dialog/alert-dialog.component';
import { ImageViewerComponent } from '../../image-viewer/image-viewer.component';
import { AddressService } from '../../services/address.service';
import { CustomAttributesService } from '../../services/custom-attributes.service';
import { FieldService } from '../../services/field.service';
import { ReferenceDocumentService } from '../../services/reference-document.service';
import { UploadImageService } from '../../services/upload-image.service';

@Component({
  selector: 'kt-field-visibility-setting',
  templateUrl: './field-visibility-setting.component.html',
  styleUrls: ['./field-visibility-setting.component.scss']
})
export class FieldVisibilitySettingComponent implements OnInit {

  private selectedProject: number = JSON.parse(localStorage.getItem('selectedProject'));
  fieldAccessForm: FormGroup;
  userRoles: roleModel[];
  isDisable: boolean = false;
  showDefaultValueField = false;
  fieldDetail;
  allFields: CustomAttibute[];
  fixedCountryfieldName = 'countryName';
  fixedStatefieldName = 'stateName';
  fixedCityfieldName = 'cityName';
  countriesList: countriesModel[];
  stateLookupObj = {};
  cityLookupObj = {};
  maxDate: Date | String;
  minDate: Date | String;
  DisplayDateFlag: boolean;
  DisplayDateMinFlag: boolean;
  DisplayDateMaxFlag: boolean;
  formGroupFlag: boolean = false;
  fileArray = {};//Image
  baseUrl = environment.imageUrl;
  uploadQueue = {};
  openDivsObj = {};
  customAttributesList = {};
  private subscriptions: Subscription[] = [];
  private oldRadioValue = {};
  disabled = false;
  errorFlag = false;

  documentIconUrlMap = new Map([
    ["pdf", "./assets/media/svg/icons/General/PDF-File.svg"],
    ["docx", "./assets/media/svg/icons/General/file-alt-solid.svg"],
    ["xlsx", "./assets/media/svg/icons/General/file-excel-solid.svg"],
    ["pptx", "./assets/media/svg/icons/General/file-powerpoint-solid.svg"]
  ]);
  documentTypeMap = new Map([
    [1, { type: "PDF", extension: ".pdf", matchString: "application/pdf" }],
    [2, { type: "Word", extension: ".docx", matchString: "application/vnd.openxmlformats-officedocument.wordprocessingml.document" }],
    [3, { type: "Excel", extension: ".xlsx", matchString: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }],
    [4, { type: "Power Point", extension: ".pptx", matchString: "application/vnd.openxmlformats-officedocument.presentationml.presentation" }]
  ]);

  private numericPattern = "^(0|[1-9][0-9]*)$";
  private decimalPattern = "^(0|[1-9][0-9]*)$|^((0|[1-9][0-9]*)\\.\\d{1,2})$";
  private albhabetPatternErrorMsg = 'Only Alphabets are allowed';
  private albhaNumericPatternErrorMsg = 'Only Alpha-Numeric characters are allowed';

  private existingData = [];
  isDataListField = false;
  isLinkedField = false;

  constructor(
    private activeModal: NgbActiveModal,
    public _field: FieldService,
    private _fb: FormBuilder,
    private _role: RoleService,
    private _as: AddressService,
    private _customAttrService: CustomAttributesService,
    private _docUpload: ReferenceDocumentService,
    private _us: UploadImageService,
    private modalService: NgbModal,
    private spinnerService: NgxSpinnerService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.isDataListField = this.fieldDetail.attributeType.id === 18;
    if (this.fieldDetail.linkedAttributeId != null && this.fieldDetail.linkedAttributeId != 0) {
      this.isLinkedField = true;
    }

    if (this.fieldDetail.attributeType.id == 1 || this.fieldDetail.attributeType.id == 2) {
      this.setLookupFieldValue(this.fieldDetail.id);
    }
    else if (this.fieldDetail.linkedAttributeModuleId == 6) {
      this.getCustom1(6)
    }
    else if (this.fieldDetail.attributeType.id == 3 || this.fieldDetail.attributeType.id == 21) {
      this.getCustomDateAttribute(this.fieldDetail.id);
    }
    else {
      this.showSpinner();
      this.initFieldAccessForm();
    }

  }

  setLookupFieldValue(attribId: number) {
    this.showSpinner();
    this._field.getAttribLookup(attribId).subscribe(
      (row) => {
        if (row['statusCode'] == 200) {
          this.fieldDetail.lookupValues = row['responseData'];
          this.initFieldAccessForm();
        }
      },
      (error) => {
        this.spinnerService.hide('fetch-data');
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  getLookupValuesAndDataListDetails(attribId: number) {
    this.showSpinner();
    this._field.getDataListLookupValues(attribId).subscribe(
      (row: any) => {
        if (row['statusCode'] == 200) {
          const responseData = row['responseData']; // Retrieve responseData from the row object
          this.fieldDetail.lookupValues = responseData.lookupValues;
          this.fieldDetail.parentLookupValues = responseData.parentLookupValues;
          this.fieldDetail.parentRelationList = responseData.parentRelationList;
          this.fieldDetail.selectedDependentFields = responseData.selectedDependentFields;
          this.fieldDetail.selectedParentFiledValues = responseData.selectedParentFiledValues;
          this.initFieldAccessForm();
        }
      },
      (error) => {
        this.spinnerService.hide('fetch-data');
        console.error('Error fetching lookup values:', error);
      }
    );
  }

  getCustomDateAttribute(attribId: number) {
    this.showSpinner();
    this._field.getCustomDateAttribute(attribId).subscribe(
      (row) => {
        if (row['statusCode'] == 200) {
          this.fieldDetail.dateAttributes = row['responseData'];
          this.initFieldAccessForm();
        }
      },
      (error) => {
        this.spinnerService.hide('fetch-data');
        console.error('Error fetching dateAttributes values:', error);
      }
    );
  }

  getCustom1(moduleType) {
    this._field.getFieldsInSetting(moduleType, this.selectedProject).subscribe(
      (result) => {

        if (result && result['responseData'].length) {
          result['responseData'].forEach(res => {
            if (res.id === this.fieldDetail.parentDataListId) {
              this.fieldDetail.childAttributeList = res.childAttributeList;
            }
          });
          this.getLookupValuesAndDataListDetails(this.fieldDetail.id);
        } else {
          console.log('No valid data in the response or empty responseData');
        }
      },
      error => {
        console.log(error)
      })
  }

  initFieldAccessForm() {
    this.fieldAccessForm = this._fb.group({});
    this.fetchAndSetOwnerOptionsThenFetchUserRoles(this.fieldDetail);
  }

  fetchUserRoles() {
    this.userRoles = [];
    this._role.getRoles(this.selectedProject).subscribe(res => {
      this.userRoles = res['responseData'].filter(role => role.active == 1);
      this.userRoles.forEach(role => {
        this.oldRadioValue[role.roleName] = 0;
        const tempArray = this.getFieldWithChilds();
        this.fieldAccessForm.addControl(role.roleName, new FormControl('3'));
        this.customAttributesList[role.roleName] = tempArray;
        this.populateExitingRadioData(role.roleId, role.roleName);
        this.formGroupFlag = true;
        this.hideSpinner();
      });
    }, error => {
      this.hideSpinner();
      console.log(error);
    })
  }

  populateExitingRadioData(roleId: number, roleName: string) {
    const ext = this.existingData.find(x => x.attributeId === this.fieldDetail.id && x.roleId === roleId);
    if (ext) {
      const radioVal = ext.fieldVisibility + '';
      this.fieldAccessForm.get(roleName).setValue(radioVal);
      this.onRadioButtonChange(+radioVal, roleId, roleName, false);
    }
  }

  populateExistingMainFieldData(roleId: number, roleName: string) {
    const mainField = this.fieldDetail;
    const value = this.getExistingFieldValue(roleId, mainField, mainField.linkedAttributeId !== null);

    if ([15, 16, 21].includes(this.fieldDetail.systemAttribute?.id)) {
      if (value) {
        const countryStateCityVals = (value as string).split("->");
        const countryControl = this.fieldAccessForm.get(roleName + this.fixedCountryfieldName);
        if (countryControl && countryStateCityVals[0]) {
          countryControl.setValue(countryStateCityVals[0]);
          //after filling country get states 
          this.getAllStates(countryStateCityVals[0].split('@')[1], roleName, false);
        }
        const stateControl = this.fieldAccessForm.get(roleName + this.fixedStatefieldName);
        if (stateControl && countryStateCityVals[1]) {
          stateControl.setValue(countryStateCityVals[1]);
          //after filling state get cities
          this.getAllCities(countryStateCityVals[1].split('@')[1], roleName, false);
        }
        const cityControl = this.fieldAccessForm.get(roleName + this.fixedCityfieldName);
        if (cityControl && countryStateCityVals[2]) {
          cityControl.setValue(countryStateCityVals[2]);
        }
      }
    } else {
      const controlName = roleName + mainField.combinedAttributeName;
      const control = this.fieldAccessForm.get(controlName);
      if (control) control.setValue(value);
      if (mainField.id === this.fieldDetail.id && (mainField.attributeType.id === 1 || mainField.attributeType.id === 18)) {
        setTimeout(() => {
          this.invokeChildMethod(value, mainField, roleId, roleName, true);
        }, 10);
      }
    }

  }

  getFieldWithChilds() {
    let fields = [];
    let parentAttribIds: number[] = [];
    this.allFields.forEach(x => {
      if (x.id === this.fieldDetail.id) {
        fields.push(x);
        parentAttribIds.push(x.id);
      } else if (parentAttribIds.includes(x.parentId)) {
        fields.push(x);
        parentAttribIds.push(x.id);
      }
    });
    return JSON.parse(JSON.stringify(fields));
  }

  onRadioButtonChange(visibility: number, roleId: number, roleName: string, skipSetup = true) {
    if (+visibility === 3) {
      if (this.oldRadioValue[roleName] !== 0) {
        this.removeRoleFieldControls(roleName);
        // this.customAttributesList[roleName] = [];
        this.openDivsObj[roleName] = [];
      }
    } else {
      this.customAttributesList[roleName] = this.getFieldWithChilds();
      this.customAttributesList[roleName].forEach(x => {
        this.initializeFormControlsForRole(roleName, x);
      });
      this.openDivsObj[roleName] = [];
      if (!skipSetup) this.populateExistingMainFieldData(roleId, roleName,);
    }
    this.oldRadioValue[roleName] = +visibility;
  }

  removeRoleFieldControls(roleName: string) {
    this.customAttributesList[roleName].forEach(f => {
      const controlName = roleName + f.combinedAttributeName;
      this.fieldAccessForm.removeControl(controlName);
    });
  }

  private initializeFormControlsForRole(roleName: string, field) {
    //Initializing Controls for Single Select
    if (!this.isLinkedField && field.attributeType.id == 1) {
      this.iniatilizeControlsForSingleSelect(roleName, field);
    }
    //Initializing Controls for Multi Select
    if (!this.isLinkedField && field.attributeType.id == 2) {
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
      }
      else {
        this.fieldAccessForm.addControl(controlName, new FormControl());
      }
    }
    // Initializing Controls for Date Or Date Time Field
    if (!this.isLinkedField && (field.attributeType.id == 3 || field.attributeType.id == 21)) {
      this.iniatilizeControlsForDateField(roleName, field);
    }
    // Initializing Controls for Image
    if (!this.isLinkedField && field.attributeType.id == 4) {
      this.iniatilizeControlsForImageField(roleName, field);
    }
    // Initializing Controls for Radio Butto Fields
    if (!this.isLinkedField && field.attributeType.id == 5) {
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
      }
      else {
        this.fieldAccessForm.addControl(controlName, new FormControl());
      }
    }
    // Initializing Controls for Text Single-Line Fields
    if (!this.isLinkedField && (field.attributeType.id == 6 || field.attributeType.id == 20)) {
      this.iniatilizeControlsForTextSingleLineField(roleName, field);
    }
    // Initializing Controls for Numeric Fields
    if (!this.isLinkedField && field.attributeType.id == 7) {
      let pattern = this.numericPattern;
      if (field.decimalFlag) {
        pattern = this.decimalPattern;
      }
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required, Validators.pattern(pattern)])));
      } else {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.pattern(pattern)])));
      }
    }
    // Initializing Controls for Multi-Line Fields
    if (!this.isLinkedField && field.attributeType.id == 8) {
      let patternString = ".*";
      let patternErrorMsg = '';
      if (field.characterType == 3) {
        patternString = "^[a-zA-Z \n]*$";
        patternErrorMsg = this.albhabetPatternErrorMsg;
      } else if (field.characterType == 2) {
        patternString = "^[a-zA-Z0-9 \n]*$";
        patternErrorMsg = this.albhaNumericPatternErrorMsg;
      }
      field.patternErrorMsg = patternErrorMsg;
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required, Validators.pattern(patternString)])));
      } else {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.pattern(patternString)])));
      }
    }
    // Initializing Controls for Email Fields
    if (!this.isLinkedField && field.attributeType.id == 9) {
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required, Validators.email])));
      } else {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.email])));
      }
    }
    // Initializing Controls for Document Upload Fields
    if (!this.isLinkedField && field.attributeType.id == 15) {
      this.iniatilizeControlsForDocumentField(roleName, field);
    }
    // Initializing Controls for Audio/Viedo Fields
    if (!this.isLinkedField && field.attributeType.id == 16) {
      this.iniatilizeControlsForAudioVideoField(roleName, field);
    }
    // Initializing Controls for Reference Document Fields
    if (!this.isLinkedField && field.attributeType.id == 17) {
      this.iniatilizeControlsForReferenceDocField(roleName);
    }
    // Initializing Controls for Data List Fields
    if (!this.isLinkedField && field.attributeType.id == 18) {
      this.iniatilizeControlsForDataListField(roleName);
    }
    // Initializing Controls for Time Duration Fields
    if (!this.isLinkedField && field.attributeType.id == 22) {
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
      }
      else {
        this.fieldAccessForm.addControl(controlName, new FormControl(''));
      }
    }
  }

  private iniatilizeControlsForSingleSelect(roleName: string, field) {
    if (field.systemAttribute?.id != 16 && field.systemAttribute?.id != 15 && field.systemAttribute?.id != 21) {

      const controlName = roleName + field.combinedAttributeName;
      //Reseting Control value on radio button change
      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
      }
      else {
        this.fieldAccessForm.addControl(controlName, new FormControl());
      }
    }
    else if (field.systemAttribute?.id == 16 || field.systemAttribute?.id == 15 || field.systemAttribute?.id == 21) {
      this.getAllCountries();
      const countryControlName = roleName + this.fixedCountryfieldName;
      const stateControlName = roleName + this.fixedStatefieldName;
      const cityControlName = roleName + this.fixedCityfieldName;

      if (this.fieldAccessForm.get(countryControlName)) {
        const controlValue = this.fieldAccessForm.get(countryControlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(countryControlName).setValue(null);
        }
      }
      if (this.fieldAccessForm.get(stateControlName)) {
        const controlValue = this.fieldAccessForm.get(stateControlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(stateControlName).setValue(null);
        }
      }
      if (this.fieldAccessForm.get(cityControlName)) {
        const controlValue = this.fieldAccessForm.get(cityControlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(cityControlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(countryControlName, new FormControl('', Validators.compose([Validators.required])));
        this.fieldAccessForm.addControl(stateControlName, new FormControl('', Validators.compose([Validators.required])));
        this.fieldAccessForm.addControl(cityControlName, new FormControl('', Validators.compose([Validators.required])));
      }
      else {
        this.fieldAccessForm.addControl(countryControlName, new FormControl());
        this.fieldAccessForm.addControl(stateControlName, new FormControl());
        this.fieldAccessForm.addControl(cityControlName, new FormControl());
      }
    }
  }

  private iniatilizeControlsForDateField(roleName: string, field) {
    this.maxDate = new Date();
    this.minDate = new Date();
    if (field.dateAttributes) {

      if (field.dateAttributes.stdLookupAttribute != null) {
        this.DisplayDateMinFlag = false;
        this.DisplayDateMaxFlag = false;
        if (field.dateAttributes.stdLookupAttribute.id == 2 || field.dateAttributes.stdLookupAttribute.id == 10) {
          this.DisplayDateFlag = true;
          field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
        }
        else if (field.dateAttributes.stdLookupAttribute.id == 3 || field.dateAttributes.stdLookupAttribute.id == 11) {
          this.DisplayDateFlag = true;
          field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);

        }
        else if (field.dateAttributes.stdLookupAttribute.id == 5 || field.dateAttributes.stdLookupAttribute.id == 12) {
          this.DisplayDateFlag = true;
          field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay() + 6);
        }
        else if (field.dateAttributes.stdLookupAttribute.id == 6 || field.dateAttributes.stdLookupAttribute.id == 13) {
          this.DisplayDateFlag = true;
          var date = new Date();
          field.dateAttributes.minDate = new Date(date.getFullYear(), date.getMonth(), 1);
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());

        }
        else if (field.dateAttributes.stdLookupAttribute.id == 7 || field.dateAttributes.stdLookupAttribute.id == 14) {
          this.DisplayDateFlag = true;
          var date = new Date();
          field.dateAttributes.minDate = new Date(date.getFullYear(), date.getMonth(), 1);
          field.dateAttributes.maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        }
        else if (field.dateAttributes.stdLookupAttribute.id == 8 || field.dateAttributes.stdLookupAttribute.id == 15) {
          this.DisplayDateFlag = true;
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var diff = Number(now) - Number(start);
          var oneDay = 1000 * 60 * 60 * 24;
          var day = Math.floor(diff / oneDay);
          field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day - 1));
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());

        }
        else if (field.dateAttributes.stdLookupAttribute.id == 9 || field.dateAttributes.stdLookupAttribute.id == 16) {
          this.DisplayDateFlag = true;
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var end = new Date(now.getFullYear(), 11, 31);
          var diffStart = Number(now) - Number(start);
          var diffend = Number(end) - Number(now);
          var oneDay = 1000 * 60 * 60 * 24;
          var day = Math.floor(diffStart / oneDay);
          var dayEnd = Math.floor(diffend / oneDay);
          field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day - 1));
          field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
        }
      } else {
        if (field.dateAttributes.previousDateAllowed == null && field.dateAttributes.futureDateAllowed == null) {
          this.DisplayDateFlag = false;
          this.DisplayDateMinFlag = false;
          this.DisplayDateMaxFlag = false;
        }
        else if (field.dateAttributes.previousDateAllowed == null && field.dateAttributes.futureDateAllowed != null) {
          this.DisplayDateMaxFlag = true;
          this.DisplayDateMinFlag = false;
          this.DisplayDateFlag = false;
          if (field.dateAttributes.futureDateAllowed >= 0) {
            field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + field.dateAttributes.futureDateAllowed);
          }
        }
        else if (field.dateAttributes.previousDateAllowed != null && field.dateAttributes.futureDateAllowed == null) {
          this.DisplayDateMinFlag = true;
          this.DisplayDateFlag = false;
          this.DisplayDateMaxFlag = false;
          if (field.dateAttributes.previousDateAllowed >= 0) {
            field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - field.dateAttributes.previousDateAllowed);
          }
        } else {

          this.DisplayDateFlag = true;
          this.DisplayDateMinFlag = false;
          this.DisplayDateMaxFlag = false;
          if (field.dateAttributes.previousDateAllowed >= 0) {
            field.dateAttributes.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - field.dateAttributes.previousDateAllowed);
          }
          if (field.dateAttributes.futureDateAllowed >= 0) {
            field.dateAttributes.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + field.dateAttributes.futureDateAllowed);
          }
        }
      }
      if (field.attributeType.id == 21) {
        let DateTimeMinDate = new Date(field.dateAttributes.minDate);
        let minYear = DateTimeMinDate.getFullYear();
        let minMonth = (DateTimeMinDate.getMonth() + 1).toString().padStart(2, '0');
        let minDays = DateTimeMinDate.getDate().toString().padStart(2, '0');
        field.dateAttributes.minDate = `${minYear}-${minMonth}-${minDays}T00:00:00`;
        let DateTimeMaxDate = new Date(field.dateAttributes.maxDate);
        let maxYear = DateTimeMaxDate.getFullYear();
        let maxMonth = (DateTimeMaxDate.getMonth() + 1).toString().padStart(2, '0');
        let maxDays = DateTimeMaxDate.getDate().toString().padStart(2, '0');
        field.dateAttributes.maxDate = `${maxYear}-${maxMonth}-${maxDays}T00:00:00`;
      }
    } else {
      this.minDate = "";
      this.maxDate = "";
    }

    const controlName = roleName + field.combinedAttributeName;

    if (this.fieldAccessForm.get(controlName)) {
      const controlValue = this.fieldAccessForm.get(controlName).value;
      if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
        this.fieldAccessForm.get(controlName).setValue(null);
      }
    }

    if (field.mandatoryFlag == 1 && field.parentId === 0) {
      this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
    } else {
      this.fieldAccessForm.addControl(controlName, new FormControl());
    }
  }

  private iniatilizeControlsForImageField(roleName: string, field) {
    if (field.attributeType.id == 4) {
      const controlName = roleName + field.combinedAttributeName;

      if (this.fieldAccessForm.get(controlName)) {
        const controlValue = this.fieldAccessForm.get(controlName).value;
        if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
          this.fieldAccessForm.get(controlName).setValue(null);
        }
      }

      if (field.mandatoryFlag == 1) {
        this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
      } else {
        this.fieldAccessForm.addControl(controlName, new FormControl());
      }

      this.fileArray[roleName + field.combinedAttributeName] = {
        "attributeName": field.combinedAttributeName,
        "files": [],
        "maxUpload": field.maxImagesAllowed,
        "showUploadIcon": true,
        "uploaded": 0,
        "hasError": false
      };
    }
  }

  private iniatilizeControlsForTextSingleLineField(roleName: string, field) {
    let patternString = ".*";
    let patternErrorMsg = '';
    if (field.characterType == 3) {
      patternString = "^[a-zA-Z ]*$";
      patternErrorMsg = this.albhabetPatternErrorMsg;
    } else if (field.characterType == 2) {
      patternString = "^[a-zA-Z0-9 ]*$";
      patternErrorMsg = this.albhaNumericPatternErrorMsg;
    }
    field.patternErrorMsg = patternErrorMsg;
    const controlName = roleName + field.combinedAttributeName;

    if (this.fieldAccessForm.get(controlName)) {
      const controlValue = this.fieldAccessForm.get(controlName).value;
      if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
        this.fieldAccessForm.get(controlName).setValue(null);
      }
    }

    if (field.mandatoryFlag == 1) {
      this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required, Validators.pattern(patternString)])));
    } else {
      this.fieldAccessForm.addControl(controlName, new FormControl('', [Validators.pattern(patternString)]));
    }
  }

  private iniatilizeControlsForDocumentField(roleName: string, field) {
    const controlName = roleName + field.combinedAttributeName;

    if (this.fieldAccessForm.get(controlName)) {
      const controlValue = this.fieldAccessForm.get(controlName).value;
      if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
        this.fieldAccessForm.get(controlName).setValue(null);
      }
    }

    if (field.mandatoryFlag == 1) {
      this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
    } else {
      this.fieldAccessForm.addControl(controlName, new FormControl());
    }
    let validDocTypes = [];
    let allowedDocText = [];
    if (field.documentType != null && field.documentType != "") {
      (field.documentType as string).split(",").forEach(x => {
        let type = this.documentTypeMap.get(+x);
        validDocTypes.push(type);
        allowedDocText.push(type.extension);
      });
    }
    else {
      for (const [key, value] of this.documentTypeMap.entries()) {
        validDocTypes.push(value);
        allowedDocText.push(value.extension);
      }
    }

    this.fileArray[roleName + field.combinedAttributeName] = {
      "attributeName": field.combinedAttributeName,
      "files": [],
      "documentIcons": [],
      "allowedDocText": allowedDocText.join(", "),
      "validDocType": validDocTypes,
      "maxUpload": field.maxImagesAllowed,
      "showUploadIcon": true,
      "uploaded": 0,
      "hasError": false
    };
  }

  private iniatilizeControlsForAudioVideoField(roleName: string, field) {
    const controlName = roleName + field.combinedAttributeName;

    if (this.fieldAccessForm.get(controlName)) {
      const controlValue = this.fieldAccessForm.get(controlName).value;
      if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
        this.fieldAccessForm.get(controlName).setValue(null);
      }
    }

    if (field.mandatoryFlag == 1) {
      this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
    } else {
      this.fieldAccessForm.addControl(controlName, new FormControl());
    }
    this.fileArray[roleName + field.combinedAttributeName] = {
      "attributeName": field.combinedAttributeName,
      "files": [],
      "maxUpload": field.maxImagesAllowed,
      "showUploadIcon": true,
      "uploaded": 0,
      "hasError": false
    };
  }

  private iniatilizeControlsForReferenceDocField(roleName: string) {
    this.fileArray[roleName + this.fieldDetail.combinedAttributeName] = {
      "attributeName": this.fieldDetail.combinedAttributeName,
      "files": [],
      "documentIcons": [],
      "maxUpload": this.fieldDetail.maxImagesAllowed,
      "showUploadIcon": false,
      "uploaded": 0,
      "hasError": false
    };
    const fileAttr = this.fileArray[roleName + this.fieldDetail.combinedAttributeName];

    (this.fieldDetail.referenceDocumentUrl as Array<string>).forEach(file => {
      fileAttr.files.push(file);
      let fileType = file.split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      fileAttr.documentIcons.push(icon ? icon : file);
    })
  }

  private iniatilizeControlsForDataListField(roleName: string) {
    const controlName = roleName + this.fieldDetail.combinedAttributeName;
    if (this.fieldAccessForm.get(controlName)) {
      const controlValue = this.fieldAccessForm.get(controlName).value;
      if (controlValue !== null && controlValue !== undefined && controlValue !== '') {
        this.fieldAccessForm.get(controlName).setValue(null);
      }
    }
    if (this.fieldDetail.mandatoryFlag == 1) {
      this.fieldAccessForm.addControl(controlName, new FormControl('', Validators.compose([Validators.required])));
    } else {
      this.fieldAccessForm.addControl(controlName, new FormControl());
    }
    if (this.fieldDetail.childAttributeList?.length > 0) {
      this.fieldDetail.childAttributeList.forEach(childElement => {
        const attributeName = childElement.attributeName.replace(/[^a-zA-Z0-9]/g, ' ').split(' ').join('')
        childElement.parentAttributeId = this.fieldDetail.parentDataListId;
        childElement.combinedAttributeName = this.fieldDetail.combinedAttributeName + attributeName;
        childElement.parentCombinedAttributeName = this.fieldDetail.combinedAttributeName;
        childElement.mandatoryFlag = this.fieldDetail.mandatoryFlag;
        childElement.parentLinkedDataListAttribId = this.fieldDetail.id;
        if (childElement.attributeType.id == 1) {
          if (childElement.systemAttribute === null) {
            childElement.systemAttribute = {};
          }

          if (childElement.mandatoryFlag == 1 && childElement.parentId === 0) {
            this.fieldAccessForm.addControl(roleName + childElement.combinedAttributeName, new FormControl('', Validators.compose([Validators.required])));
          } else {
            this.fieldAccessForm.addControl(roleName + childElement.combinedAttributeName, new FormControl());
          }
        }
      });

      let customAttributesList = [];
      const tempArray = [];
      tempArray.push(this.fieldDetail);
      this.customAttributesList[roleName] = tempArray;

      (this.customAttributesList[roleName] as Array<any>).forEach(field => {
        customAttributesList.push(field);
        if (field.attributeType.id == 18 && field.childAttributeList !== null && field.childAttributeList.length > 0) {
          customAttributesList.push(...JSON.parse(JSON.stringify(field.childAttributeList)));
        }
      });
      this.customAttributesList[roleName] = customAttributesList;
    }
  }

  invokeChildMethod(lookupvalue, customAttr, roleId: number, roleName: string, setValue = false) {
    if (this.openDivsObj[roleName] === undefined) {
      this.openDivsObj[roleName] = [];
    }
    if (!lookupvalue) {
      lookupvalue = 0;
    }
    var attributeId = customAttr.id;
    let parentLinkedDataListAttribId = 0;

    // Datalist child field condition
    if (customAttr.attributeType.id == 18) {
      attributeId = customAttr.parentDataListId;
      parentLinkedDataListAttribId = customAttr.id;
    }
    else if (customAttr.moduleType == 6) {
      parentLinkedDataListAttribId = customAttr.parentLinkedDataListAttribId;
    } else if (customAttr.attributeType.id === 1 && customAttr.linkedAttributeId !== null) {
      attributeId = customAttr.linkedAttributeId;
    }

    if (customAttr.hasChildAttribute) {
      //cal  api
      this.showSpinner();
      this._customAttrService.getFieldRelation(attributeId, lookupvalue).subscribe(
        res => {

          if (res['responseData']) {
            res['responseData'] = res['responseData'].filter(ele => {
              return ele.status == 1;
            });
            let childAttribDivIds = [];

            if (res['responseData'].length > 0) {
              res['responseData'].forEach(element => {

                //logic for product form if it is dependent on other field(attributeId)
                if (element.parentFormId != null) {
                  // this.dependentProductFormDeleteOrAdd(attributeId, element);
                }
                if (customAttr.linkedAttributeId !== null) {
                  const linkedField = this.customAttributesList[roleName].find(x => x.linkedAttributeId === element.id);
                  if (linkedField) {
                    element.combinedAttributeName = linkedField.combinedAttributeName;
                    element.mandatoryFlag = linkedField.mandatoryFlag;
                  }
                }
                // update combined attribute name and mandatoryFlag info for datalist child fields
                if (customAttr.attributeType.id == 18) {
                  element.combinedAttributeName = customAttr.combinedAttributeName + element.combinedAttributeName;
                  element.mandatoryFlag = customAttr.mandatoryFlag;
                }
                else if (customAttr.moduleType == 6 && customAttr.parentCombinedAttributeName) {
                  element.combinedAttributeName = customAttr.parentCombinedAttributeName + element.combinedAttributeName;
                  element.mandatoryFlag = customAttr.mandatoryFlag;
                }
                //serch for key
                var removeIndex = this.openDivsObj[roleName].map(function (item) { return item.attributeId; }).indexOf(attributeId);
                var divId = this.createDivId(element, customAttr.linkedAttributeId !== null, this.customAttributesList[roleName], roleName);
                childAttribDivIds.push(divId);

                if (removeIndex > -1) {

                  var removeIndexInside = this.openDivsObj[roleName][removeIndex].value.map(function (item) { return item.divId; }).indexOf(String(divId));

                  if (removeIndexInside > -1) {
                    console.log('if small div exsits');
                    //yes exists then no need to do anything
                  } else {
                    console.log('if small div doesnt exsit');
                    this.openDivsObj[roleName][removeIndex].value.push({
                      divId: divId, combinedAttributeName: element.combinedAttributeName,
                      id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                    });

                    var division1 = <HTMLElement>document.getElementById(divId);
                    if (division1) {
                      this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1, roleName);
                      division1.classList.remove('hidden');
                      if (setValue) {
                        const extVal = this.getExistingFieldValue(roleId, element, customAttr.linkedAttributeId !== null);
                        this.fieldAccessForm.get(roleName + element.combinedAttributeName).setValue(extVal);
                      }
                    }
                  }
                } else {

                  this.openDivsObj[roleName].push({
                    attributeId: attributeId,
                    value: [
                      {
                        divId: divId, combinedAttributeName: element.combinedAttributeName,
                        id: element.id, parentLinkedDataListAttribId: parentLinkedDataListAttribId
                      }
                    ]
                  });

                  var division1 = <HTMLElement>document.getElementById(divId);

                  if (division1) {
                    this.addValidators(element.combinedAttributeName, element.mandatoryFlag === 1, element.attributeType.id, element.decimalFlag === 1, roleName);
                    division1.classList.remove('hidden');
                    if (setValue) {
                      const extVal = this.getExistingFieldValue(roleId, element, customAttr.linkedAttributeId !== null);
                      this.fieldAccessForm.get(roleName + element.combinedAttributeName).setValue(extVal);
                    }
                  }
                }

                const childAttrib = this.findChildAttribByIdAndCombinedName(element.id, element.combinedAttributeName, customAttr.linkedAttributeId !== null, roleName);

                if (childAttrib) {
                  childAttrib.lookupValues = element.lookupValues;
                  this.resetChildAttribsAndinvokeChildMethod(childAttrib, roleId, roleName, setValue, customAttr.linkedAttributeId !== null);
                }

              });
              // ----------------> START:: hiding the divs of child attrib when such a value selected for parent attrib 
              // where there's no relation mapping with child attrib
              var removeIndex = this.openDivsObj[roleName].map(function (item) { return item.attributeId; }).indexOf(attributeId);
              let childAttribsToKeep = [];
              console.log(childAttribDivIds);

              if (removeIndex > -1) {
                //close all open divs
                this.openDivsObj[roleName][removeIndex].value.forEach(res => {
                  if (parentLinkedDataListAttribId === res.parentLinkedDataListAttribId && !childAttribDivIds.includes(res.divId)) {
                    var division = <HTMLElement>document.getElementById(res.divId);
                    if (division) {
                      //Change Control
                      const control = this.fieldAccessForm.get(roleName + res.combinedAttributeName);
                      if (control) {
                        control.clearValidators();
                        control.reset();
                        control.updateValueAndValidity();

                        const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId !== null, roleName);

                        if (childAttrib) {
                          this.resetChildAttribsAndinvokeChildMethod(childAttrib, roleId, roleName, setValue, customAttr.linkedAttributeId !== null);
                        }
                      }
                      division.classList.add('hidden');
                    }
                  } else {
                    childAttribsToKeep.push(res);
                  }
                });
              };
              this.openDivsObj[roleName][removeIndex].value = childAttribsToKeep;
              // <---------------- END

            } else {
              // -> hiding the divs of child attrib whose relationships doesn't exist anymore
              var removeIndex = this.openDivsObj[roleName].map(function (item) { return item.attributeId; }).indexOf(attributeId);
              let markToRemovedChildDivIds: string[] = [];
              if (removeIndex > -1) {
                //close all open divs
                this.openDivsObj[roleName][removeIndex].value.forEach(res => {
                  if (res.parentLinkedDataListAttribId === parentLinkedDataListAttribId) { // proceed only if parentLinkedDataListAttribId is matched
                    markToRemovedChildDivIds.push(res.divId);
                    var division = <HTMLElement>document.getElementById(res.divId);
                    if (division) {
                      //Change Control
                      const control = this.fieldAccessForm.get(roleName + res.combinedAttributeName);
                      if (control) {
                        control.clearValidators();
                        control.reset();
                        control.updateValueAndValidity();

                        const childAttrib = this.findChildAttribByIdAndCombinedName(res.id, res.combinedAttributeName, customAttr.linkedAttributeId !== null, roleName);

                        if (childAttrib) {
                          this.resetChildAttribsAndinvokeChildMethod(childAttrib, roleId, roleName, setValue, customAttr.linkedAttributeId !== null);
                        }
                      }
                      division.classList.add('hidden');
                    }
                    division.classList.add('hidden');
                  }
                });
                //remove key an pairs
                if (this.openDivsObj[roleName][removeIndex].value) {
                  if (this.openDivsObj[roleName][removeIndex].value.length === markToRemovedChildDivIds.length) { // remove the whole
                    this.openDivsObj[roleName].splice(removeIndex, 1);
                  }
                  else if (this.openDivsObj[roleName][removeIndex].value.length !== markToRemovedChildDivIds.length) { // remove only the mark for remove ones
                    this.openDivsObj[roleName][removeIndex].value = this.openDivsObj[roleName][removeIndex].value.filter(x => !markToRemovedChildDivIds.includes(x.divId));
                  }
                }
                // <- END
              }
            }
          }
          this.hideSpinner();
        }
      )
    }
  }

  getExistingFieldValue(roleId: any, field, isLinkedField) {
    if (isLinkedField && field.id !== this.fieldDetail.id) {
      const parent = this.allFields.find(x => x.linkedAttributeId === field.id);
      field = parent ? parent : field;
    }
    let ext = null;
    if (this.isDataListField && field.id !== this.fieldDetail.id) {
      ext = this.existingData.find(x => x.roleId === roleId && x.attributeId === field.id && x.parentDataListId === this.fieldDetail.id);
    } else {
      ext = this.existingData.find(x => x.roleId === roleId && x.attributeId === field.id);
    }
    if (ext && ext.value) {
      if ((field.attributeType.id === 1 || field.attributeType.id === 18) && ![15, 16, 21].includes(field.systemAttribute?.id)) {
        return +ext.value;
      } else if (field.attributeType.id === 2) {
        return (ext.value as string).split(",").map(x => +x);
      } else if ([4, 15, 16].includes(field.attributeType.id)) {
        const value = (ext.value as string).split(",").map(x => x.replace(this.baseUrl, ""));
        const roleName = this.userRoles.find(x => x.roleId === roleId).roleName;
        const fileAttr = this.fileArray[roleName + field.combinedAttributeName];
        let files = value;
        fileAttr.files = files;
        fileAttr.uploaded = files.length;
        this.updateFileControlValue(roleName + field.combinedAttributeName);
        fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : fileAttr.uploaded < fileAttr.maxUpload;
        if (field.attributeType.id === 15) {
          for (const f of value) {
            let fileType = f.split('.').pop().toLowerCase();
            fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
          }
        }
        return value;
      }
      return ext.value;
    }
    return null;
  }

  findChildAttribByIdAndCombinedName(id, combinedAttributeName, isLinkedField, roleName) {
    if (isLinkedField) {
      return this.customAttributesList[roleName].find(item => item.linkedAttributeId == id);
    }
    return this.customAttributesList[roleName].find(item => item.id == id && item.combinedAttributeName == combinedAttributeName);
  }

  resetChildAttribsAndinvokeChildMethod(childAttrib, roleId: number, roleName, setValue, isLinkedField) {
    //  -> it will reset child attrib and invoke child method for the same to remove the grand child attribs
    //Change Control Name
    const control = this.fieldAccessForm.get(roleName + childAttrib.combinedAttributeName);
    if (control && !setValue) {
      control.reset();
      if ([4, 15, 16].includes(childAttrib.attributeType.id)) {
        const selectedFileLength = this.fileArray[roleName + childAttrib.combinedAttributeName].files.length;
        for (let index = 0; index < selectedFileLength; index++) {
          this.removeFile(roleName + childAttrib.combinedAttributeName, 0)
        }
      }
    }
    if (childAttrib.attributeType.id == 1) {
      let lv = 0;
      if (setValue) {
        lv = this.getExistingFieldValue(roleId, childAttrib, isLinkedField);
      }
      this.invokeChildMethod(lv, childAttrib, roleId, roleName, setValue);
    }
  }

  createDivId(customAttr, isLinkedField: boolean = false, fieldList: any[] = [], roleName): string {
    if (isLinkedField) {
      const linkedField = fieldList.find(x => x.linkedAttributeId === customAttr.id);
      if (linkedField) {
        return 'div_' + roleName + linkedField.combinedAttributeName + '_' + linkedField.id;
      }
    }
    return 'div_' + roleName + customAttr.combinedAttributeName + '_' + customAttr.id;
  }

  addValidators(combinedAttributeName: string, mandatoryFlag: boolean, attributeTypeId: number, decimalFlag: boolean, roleName: string) {
    if ([10, 11, 17].includes(attributeTypeId)) {
      return;
    }
    let validatorFns: ValidatorFn[] = [];
    if (mandatoryFlag) {
      validatorFns.push(Validators.required);
    }
    if (attributeTypeId == 7) {
      let pattern = this.numericPattern;
      if (decimalFlag) {
        pattern = this.decimalPattern;
      }
      validatorFns.push(Validators.pattern(pattern));
    }
    else if (attributeTypeId == 9) {
      validatorFns.push(Validators.email);
    }
    //Change Control
    const control = this.fieldAccessForm.get(roleName + combinedAttributeName);
    if (control) {
      control.setValidators(validatorFns);
      control.updateValueAndValidity();
    }
  }

  fetchAndSetOwnerOptionsThenFetchUserRoles(field) {
    if (field.systemAttribute?.id == 34 || field.systemAttribute?.id == 35 || field.systemAttribute?.id == 65) {
      this.showSpinner();
      this._customAttrService.getOwner(this.selectedProject, Number(this.fieldDetail.moduleType)).subscribe(data => {
        data['responseData'].forEach(res => {
          res.lookupValue = res.fullName;
        });
        this.fieldDetail.lookupValues = data['responseData'];
        this.fetchUserRoles();
      });
    } else {
      this.fetchUserRoles();
    }
  }

  // Country/State/City Related Methods
  getAllCountries() {
    this._as.getAllCountries(this.selectedProject).subscribe(
      (result) => {
        this.countriesList = result['responseData'];
      },
      error => {
        console.log(error)
      })
  }

  changeCountry(countryId, roleName: string, needReset: boolean = true) {
    //get states
    var id = countryId.split('@', 2);
    this.getAllStates(id[1], roleName, needReset);
    if (needReset) { // to reset city dropdown options
      this.changeState('0@0', roleName, needReset);
    }
  }

  getAllStates(countryId, roleName: string, needReset: boolean) {
    this._as.getStatesForCountry(countryId).subscribe(
      (result) => {
        this.stateLookupObj[roleName] = result['responseData'];
        if (needReset) {
          // reset city and state form controls
          this.fieldAccessForm.get(roleName + this.fixedStatefieldName).reset();
          this.fieldAccessForm.get(roleName + this.fixedCityfieldName).reset();
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  changeState(stateId, roleName: string, needReset: boolean = true) {
    //get cities
    var id = stateId.split('@', 2);
    this.getAllCities(id[1], roleName, needReset);
  }

  getAllCities(stateId, roleName: string, needReset: boolean) {
    this._as.getCitiesForState(stateId).subscribe(
      (result) => {
        this.cityLookupObj[roleName] = result['responseData'];
        if (needReset) {
          // reset city and state form controls
          this.fieldAccessForm.get(roleName + this.fixedCityfieldName).reset();
        }
      },
      error => {
        console.log(error)
      }
    )
  }

  processFile(event, attribName, attributeTypeId, maxImage, roleName: string, fieldCombinedAttributeName: string = '', formArrayIndex: string = '') {
    const arrayAttribName = fieldCombinedAttributeName + formArrayIndex;
    const initialFileCount = event.target.files.length;
    if (initialFileCount > 0) {
      this.disabled = true;
      this.spinnerService.show(roleName + attribName + arrayAttribName);
      const fileAttr = this.fileArray[roleName + attribName + arrayAttribName];
      fileAttr.hasError = false;
      this.uploadQueue[roleName + attribName + arrayAttribName] = initialFileCount;

      let uploadedFilesCount = fileAttr.files.length;
      for (let i = 0; i < initialFileCount; i++) {
        if (!maxImage || uploadedFilesCount < maxImage) {
          if (this.checkFileType(event.target.files[i], attributeTypeId, fileAttr.validDocType)) {
            if (attributeTypeId == 4) {
              this.subscriptions.push(this._us.uploadImage(this.fieldDetail.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;

                fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : uploadCount < fileAttr.maxUpload;

                this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
                if (arrayAttribName != null && arrayAttribName != '' && (formArrayIndex != null && formArrayIndex.toString() != '')) {
                  const control = <FormArray>this.fieldAccessForm.controls[roleName + attribName];
                  control.at(+formArrayIndex).get(fieldCombinedAttributeName).setValue(fileAttr.files);
                }
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if (attributeTypeId == 15) {
              this.subscriptions.push(this._docUpload.uploadDoc(this.fieldDetail.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                fileAttr.files.push(res.responseData.name);
                let fileType = res.responseData.name.split('.').pop().toLowerCase();
                fileAttr.documentIcons.push(this.documentIconUrlMap.get(fileType));
                let uploadCount = fileAttr.files.length;
                fileAttr.uploaded = uploadCount;

                fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : uploadCount < fileAttr.maxUpload;

                this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
              },
                error => {
                  fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                  fileAttr.hasError = true;
                  this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
                })
              );
            }
            if (attributeTypeId == 16) {
              if (event.target.files[i].size < 26214400) {
                this.subscriptions.push(this._docUpload.uploadDoc(this.fieldDetail.moduleType, event.target.files[i], this.selectedProject).subscribe(res => {
                  fileAttr.files.push(res.responseData.name);
                  let uploadCount = fileAttr.files.length;
                  fileAttr.uploaded = uploadCount;

                  fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : uploadCount < fileAttr.maxUpload;

                  this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
                },
                  error => {
                    fileAttr.errorMsg = 'Failed to upload file ' + this.getSlicedErrorFileName(event.target.files[i].name) + '. Please try again!';
                    fileAttr.hasError = true;
                    this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
                  })
                );
              }
              else {
                fileAttr.errorMsg = 'File size limit 25 MB';
                fileAttr.hasError = true;
                this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
              }
            }
            // this.subscriptions.push(fileUploadSubscription);
            uploadedFilesCount++;
          } else {
            fileAttr.errorMsg = this.getFileErrorMsg(attributeTypeId, fileAttr.allowedDocText);
            fileAttr.hasError = true;
            this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
          }
        } else {
          this.uploadQueue[roleName + attribName + arrayAttribName] -= (initialFileCount - i - 1);
          this.checkUploadQueue(roleName + attribName + arrayAttribName, event, arrayAttribName === '');
          break;
        }
      }
    }
  }

  checkUploadQueue(attr, event, updateControl: boolean = true) {
    if (--this.uploadQueue[attr] == 0) {
      this.spinnerService.hide(attr);
      if (updateControl) {
        this.updateFileControlValue(attr);
      }
      event.target.value = '';
      delete this.uploadQueue[attr];
      if (Object.keys(this.uploadQueue).length == 0) {
        this.disabled = false;
      }
    }
  }

  checkFileType(file, attributeTypeId, validDocTypes) {
    if (attributeTypeId == 4) {
      return file.type.match('image/jpeg|image/png');
    }
    if (attributeTypeId == 15) {
      for (let type of validDocTypes) {
        if (file.type.match(type.matchString)) {
          return true;
        }
      }
      return false;
    }
    if (attributeTypeId == 16) {
      return file.type.match('video/mp4|video/quicktime|audio/mpeg');
    }
    else {
      return false;
    }
  }

  removeFile(attr, id, fieldCombinedAttributeName: string = '', formArrayIndex: string = '') {
    const arrayAttribName = fieldCombinedAttributeName + formArrayIndex;
    const fileAttr = this.fileArray[attr + arrayAttribName];
    if (fileAttr) {
      fileAttr.files.splice(id, 1);
      fileAttr.documentIcons?.splice(id, 1);
      let uploadCount = fileAttr.files.length;
      fileAttr.uploaded = uploadCount;
      fileAttr.showUploadIcon = fileAttr.maxUpload == 0 ? true : uploadCount < fileAttr.maxUpload;
    }
    fileAttr.hasError = false;
    arrayAttribName === '' ? this.updateFileControlValue(attr) : this.updateFormArrayControlValue(attr, fieldCombinedAttributeName, formArrayIndex);
  }

  updateFileControlValue(attr) {
    this.fieldAccessForm.controls[attr].setValue(this.fileArray[attr].files);
  }

  updateFormArrayControlValue(combinedAttribName, fieldCombinedAttributeName, formArrayIndex) {
    const control = <FormArray>this.fieldAccessForm.controls[combinedAttribName];
    const fileAttr = this.fileArray[combinedAttribName + fieldCombinedAttributeName + formArrayIndex];
    control.at(+formArrayIndex).get(fieldCombinedAttributeName).setValue(fileAttr.files);
  }

  clickImage(attribName, arrayAttribName: string = null) {
    if (arrayAttribName !== null) {
      let element: HTMLElement = document.getElementById(attribName + arrayAttribName) as HTMLElement;
      element.click();
    }
    else {
      let element: HTMLElement = document.getElementById(attribName) as HTMLElement;
      element.click();
    }
  }

  showImageModalOnly(imgURL) {
    const ref = this.modalService.open(ImageViewerComponent,
      {
        centered: false,
        size: 'xl'
      }
    );
    ref.componentInstance.dataString = imgURL.replace(this.baseUrl, '');
    ref.result.then((result) => {
      // this.modalResult(result);
    });
  }

  getFileErrorMsg(attribTypeId, allowedDocs) {
    if (attribTypeId == 4) {
      return 'Only .jpeg, .png files allowed';
    }
    if (attribTypeId == 15) {
      return `Only ${allowedDocs} files allowed`;
    }
    if (attribTypeId == 16) {
      return 'Only .mp4, .mov, mp3 files allowed';
    }
    return 'Error';

  }

  getSlicedErrorFileName(name: string) {
    if (name.length <= 20) {
      return name;
    }
    return ". . . ." + name.slice(-20);
  }

  showRefDocModal(attr, id) {
    const fileAttr = this.fileArray[attr];
    if (fileAttr) {
      let fileType = fileAttr.files[id].split('.').pop().toLowerCase();
      const icon = this.documentIconUrlMap.get(fileType);
      if (icon) {
        window.open(fileAttr.files[id]);
      }
      else {
        const ref = this.modalService.open(ImageViewerComponent,
          {
            centered: false,
            size: 'xl'
          }
        );
        ref.componentInstance.dataString = fileAttr.files[id].replace(this.baseUrl, '');
        ref.result.then((result) => {
          // this.modalResult(result);
        });
      }
    }
  }

  /** For  Select All in Multiselect */
  toggleAllSelection(matSelect: MatSelect, customAttr) {
    if (customAttr.allSelected) {
      matSelect.options.forEach((item: MatOption) => {
        if (item.value !== undefined && item.value !== null) {
          item.select()
        }
      });
    } else {
      matSelect.options.forEach((item: MatOption) => item.deselect());
    }
  }

  optionClick(matSelect: MatSelect, customAttr) {
    let newStatus = true;
    matSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    customAttr.allSelected = newStatus;
  }

  showSpinner() {
    this.spinnerService.show('field-access');
  }

  hideSpinner() {
    this.spinnerService.hide('field-access');
  }

  save() {
    this.errorFlag = false;
    this.userRoles.forEach(role => {
      const roleName = role.roleName;
      this.customAttributesList[roleName].forEach(element => {
        var message = '';
        if (element.attributeType.id == 7 && element.numericValidationType != null && element.numericValidationType == 1) {
          const min = element.minDigits;
          const max = element.maxDigits;
          const controlName = roleName + element.combinedAttributeName;
          const fieldControl = this.fieldAccessForm.get(controlName);
          let elementValue = fieldControl?.value;
          if (elementValue !== undefined && elementValue !== null && elementValue != "") {
            if (element.decimalFlag) {
              elementValue = this.extractWholeNumber(elementValue);
            }
            if ((min != null && min != "0" && min != 0 && Number(min) > Number(elementValue.toString().length))
              || (max != null && max != "0" && max != 0 && Number(elementValue.toString().length) > Number(max))) {
              this.errorFlag = true;
              message = 'Please enter a number with a minimum of ' + Number(min) + ' digits and maximum of ' + Number(max) + ' digits for ' + element.attributeName + ' in ' + roleName + ' role.';
              this.OpenAlertValidation(message);
              this.disabled = false;
            }
          }
        }
        if (element.attributeType.id == 7 && element.numericValidationType != null && element.numericValidationType == 2) {
          const min = element.minNumber;
          const max = element.maxNumber;
          const controlName = roleName + element.combinedAttributeName;
          const fieldControl = this.fieldAccessForm.get(controlName);
          const elementValue = fieldControl?.value;
          if (elementValue !== undefined && elementValue !== null && elementValue != "") {
            if ((min != null && Number(min) > Number(elementValue)) || (max != null && Number(elementValue) > Number(max))) {
              this.errorFlag = true;
              message = 'Enter value between ' + Number(min) + ' and ' + Number(max) + ' for ' + element.attributeName + ' in ' + roleName + ' role.';
              this.OpenAlertValidation(message);
              this.disabled = false;
            }
          }
        }
        if (element.attributeType.id == 6 || element.attributeType.id == 8) {
          const min = element.minCharacters;
          const max = element.maxCharacters;
          const controlName = roleName + element.combinedAttributeName;
          const fieldControl = this.fieldAccessForm.get(controlName);
          const elementValue = fieldControl?.value;
          if (elementValue !== undefined && elementValue !== null && elementValue != "") {
            if ((min != null && Number(min) > Number(elementValue.length)) || (max != null && Number(elementValue.length) > Number(max))) {
              this.errorFlag = true;
              message = 'Enter text having length between ' + Number(min) + ' and ' + Number(max) + ' for ' + element.attributeName + ' in ' + roleName + ' role.';
              this.OpenAlertValidation(message);
              this.disabled = false;
            }
          }
        }
      });
    });

    const controls = this.fieldAccessForm.controls;
    if (this.fieldAccessForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      console.error("Field Validation Failed. ", this.fieldAccessForm.controls);
      return;
    }

    if (this.errorFlag) {
      this.disabled = false;
      return;
    }

    const requestData = this.getRequestData();
    this.disabled = true;

    this._customAttrService.addEditFieldVisibilitySettings(requestData).subscribe(res => {
      this.activeModal.close('SUCCESS');
    },
      err => {
        this.disabled = false;
        this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar'],
          verticalPosition: 'bottom'
        });
      });
  }

  private getRequestData() {
    const requestData = {
      "projectId": this.selectedProject,
      "moduleType": this.fieldDetail.moduleType,
      "attributeId": this.fieldDetail.id,
      "attributeTypeId": this.fieldDetail.attributeType.id
    };

    const fieldVisibilityConfigList = [];

    this.userRoles.forEach(role => {
      const roleName = role.roleName;
      const roleId = role.roleId;
      const radioValue = this.fieldAccessForm.get(roleName).value;

      this.customAttributesList[roleName].forEach(field => {
        const fieldVisibilityConfig = {
          "projectId": this.selectedProject,
          "roleId": roleId,
          "moduleType": this.fieldDetail.moduleType,
          "fieldVisibility": radioValue,
          "attributeId": field.id,
          "parentDataListId": (this.isDataListField && field.id !== this.fieldDetail.id) ? this.fieldDetail.id : null
        };

        let fieldValue = null;

        if ([15, 16, 21].includes(field.systemAttribute?.id)) {
          let val = null;
          const countryControl = this.fieldAccessForm.get(roleName + this.fixedCountryfieldName);
          const countryVal = countryControl ? countryControl.value : null;
          if (countryVal) val = countryVal;

          const stateControl = this.fieldAccessForm.get(roleName + this.fixedStatefieldName);
          const stateVal = stateControl ? stateControl.value : null;
          if (stateVal) val = val + '->' + stateVal;

          const cityControl = this.fieldAccessForm.get(roleName + this.fixedCityfieldName);
          const cityVal = cityControl ? cityControl.value : null;
          if (cityVal) val = val + '->' + cityVal;
          fieldValue = val;
        } else {
          const controlName = roleName + field.combinedAttributeName;
          const fieldControl = this.fieldAccessForm.get(controlName);
          if (fieldControl) {
            fieldValue = this.getFieldValue(field, fieldControl.value);
          }
        }
        fieldVisibilityConfig["value"] = fieldValue;

        fieldVisibilityConfigList.push(fieldVisibilityConfig);
      });
    });
    requestData["fieldVisibilityConfig"] = fieldVisibilityConfigList;
    return requestData;
  }

  getFieldValue(field, data: any): string {
    if (data) {
      if ([2, 4, 15, 16].includes(field.attributeType.id)) {
        return (data as Array<any>).join(",");
      }
      return data;
    }
    return null;
  }

  OpenAlertValidation(message) {
    const initialNotice = message;
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      position: { top: '20px' },
      data: { title: 'Alert', body: initialNotice }
    });
  }

  extractWholeNumber(val: String): number {
    if (val !== '' && val !== null) {
      val = val.replace(/\.\d*/, '');
      if (val !== '' && val !== null && val.match(/^\d*$/) !== null) {
        return Number(val);
      }
    }
    return null;
  }

  close() {
    this.activeModal.close();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.fieldAccessForm.controls[controlName];
    if (!control) {
      return false;
    }
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }
}
