import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })

  export class HeaderService {

    constructor(private http:HttpClient) { }

    getLocalization(projectId){
        return this.http.get<any[]>(environment.baseUrl +'/setting/api/projects/'+projectId);
      }

  }
