import {OnInit, Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[ktMargin]'
})
export class MarginDirective implements OnInit{

  @Input() marginBottom:string='20px';
  constructor(private elementRef: ElementRef, private renderer: Renderer2) {
  
  }

  ngOnInit():void{
    console.log(this.marginBottom);
    this.renderer.setStyle(this.elementRef.nativeElement, 'margin-bottom', this.marginBottom);  
  }

}
