// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { CompanyPartialsModule } from './company-partials/company-partials.module';
import { CompanyDashboardComponent } from './company-dashboard.component';
import { Widget2RecentActivitiesComponent } from './company-partials/content/dashboard-widgets/widget2-recent-activities/widget2-recent-activities.component';
import { DialerDashboardService } from '../dialer-dashboard/Services/DialerDashboardService.service';
import { Widget7WhatsappConversationsComponent } from './company-partials/content/dashboard-widgets/widget7-whatsapp-conversations/widget7-whatsapp-conversations.component';

@NgModule({
  imports: [
    CommonModule,
    CompanyPartialsModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: CompanyDashboardComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbProgressbarModule
  ],

  exports: [Widget2RecentActivitiesComponent,Widget7WhatsappConversationsComponent],
  providers: [DialerDashboardService],

  declarations: [
    CompanyDashboardComponent
  ],
  entryComponents:[]
})
export class CompanyDashboardModule { }
