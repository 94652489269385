<div class="modal-header">
    <h4 class="modal-title">Delete Activity</h4>
</div>

<div class="modal-body">
    <div>Are you sure you want to delete "{{activityName}}"?</div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmDelete()">
        Yes
    </button>
</div>

