// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
// Partials
import { PartialsModule } from '../partials/partials.module';
// Pages
import { CoreModule } from '../../core/core.module';
import { MailModule } from './apps/mail/mail.module';
import { UserManagementModule } from './user-management/user-management.module';
import { MyPageComponent } from './my-page/my-page.component';
import { ContactSettingModule } from './contact-setting/contact-setting.module';
import { OpportunityConfigurationModule } from './opportunity-configuration/opportunity-configuration.module';
import { CompanyConfigurationModule } from './company-configuration/company-configuration.module';
import { OpportunityDashboardModule } from './opportunity-dashboard/opportunity-dashboard.module';
import { ConfigurationAssistantModule } from './configuration-assistant/configuration-assistant.module';
import { ManageActivitiesModule } from './manage-activities/manage-activities.module';
import { ConfigTabSettingsModule } from './config-tab-settings/config-tab-settings.module';
import { GeneralSettingsModule } from './general-settings/general-settings.module';
import { CompanyDashboardModule } from './company-dashboard/company-dashboard.module';
import { ContactDashboardModule } from './contact-dashboard/contact-dashboard.module';
import { ReportBuilderEditModule } from './report-builder-edit/report-builder-edit.module';
import { CrmDashboardModule } from './crm-dashboard/crm-dashboard.module';
import { BrowserModule } from '@angular/platform-browser';
import { ApprovalsComponent } from './approvals/approvals.component';
import { ReportPowerBiComponent } from './report-power-bi/report-power-bi.component';


@NgModule({
  declarations: [MyPageComponent, ReportPowerBiComponent],
  exports: [],
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    CoreModule,
    PartialsModule,
    MailModule,
    UserManagementModule,
    ContactSettingModule,
    OpportunityConfigurationModule,
    CompanyConfigurationModule,
    ConfigurationAssistantModule,
    ManageActivitiesModule,
    ConfigTabSettingsModule,
    GeneralSettingsModule,
    CompanyDashboardModule,
    OpportunityDashboardModule,
    ContactDashboardModule,
    ReportBuilderEditModule,
    CrmDashboardModule
  ],
  providers: []
})
export class PagesModule {
}
