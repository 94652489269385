<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<span *ngIf="errorText"><i>No record found</i></span>
<kt-portlet>
	<!-- start::Body (attribute: ktPortletBody) -->
	<kt-portlet-body>
		<!-- start::FILTERS & GROUP ACTIONS -->
		<div class="example-header">
            <!-- start::FILTERS -->
            <div class="form-filtration" style="margin:0px;">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                            <mat-hint align="start">Search in all fields</mat-hint>
                        </mat-form-field>
                    </div>
                    <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
                    <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                        <button type="button" mat-raised-button color="primary" 
                        (click)="AddActivity();">Add</button>
                    </div>
                </div>
            </div>
    <!-- end::FILTERS -->
        </div>
        <!-- AG GRID MODULE START -->

        <ag-grid-angular
            class="ag-theme-alpine"
            [columnDefs]="columnDefs"
            [defaultColDef]="defaultColDef"
            [overlayNoRowsTemplate]="noRowsTemplate"
            [rowDragManaged]="true"
            [animateRows]="true"
            [domLayout]="domLayout"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            (gridReady)="onGridReady($event)"
        >
        </ag-grid-angular>

        

        <div class="form-group row form-padding-top-35">
            <div class="text-left col-md-4">
                <button type="button" mat-raised-button color="primary" (click)="generateDisplayOrder()">Save Display Order</button>
            </div>
            <div class="col-md-8">
                <span style="color:red;">{{errorText}}</span>
            </div> 
        </div>

        <!--
        <div class="row col-md-5" style="margin-top:-45px;">
            <div class="col-md-3 pl-0">
                <label class="col-form-label form-padding-top-15">Items per page:</label>
            </div>
            <div class="col-md-2">
                <mat-form-field class="mat-form-field-fluid">
                    <mat-select (selectionChange)="onPageSizeChanged($event.value)" [(value)]="selectedValue">
                        <mat-option value="10">10</mat-option>
                        <mat-option value="50">50</mat-option>
                        <mat-option value="100">100</mat-option>
                        <mat-option value="500">500</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>  
        </div> -->
    </kt-portlet-body>
	<!-- end::Body -->
</kt-portlet>