<div class="example-preview">
    <div>
        <form [formGroup]="dashBoardForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add New Dashboard</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="row" style="margin-bottom: 30px;">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Name
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1 maxlength="25" formControlName="dashBoardName" [(ngModel)]="dashBoardName" ktAutoTrimSpaces>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/25</mat-hint>
                            <mat-error *ngIf="isControlHasError('dashBoardName', 'required')">Name cannot be blank.</mat-error>
                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>  
                        <div class="form-group row">
                            <div class="col-md-12">
                                <span style="color:red">{{errorMes}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

                    <!-- <div class="form-group row"> -->
                    <div class="col-md-12">
                       <div class="row" style="margin-bottom: 30px;">
                            <div class="col-md-4">
                                <div>Share With</div>
                            </div>
                            <div class="col-md-8">
                                <ng-container *ngIf="isLoadingSharingDetail">
                                    <mat-spinner diameter="20"></mat-spinner>
                                </ng-container>
                                <ng-container *ngIf="!isLoadingSharingDetail">
                                    <div class="row">
                                        <mat-form-field class="mat-form-field-fluid input-100">
                                            <mat-select placeholder="All/Select Roles/Select Users"
                                                        formControlName="shareWith" 
                                                        name="shareWith" 
                                                        [(ngModel)]="shareWith" 
                                                        (selectionChange)="changeShareWith($event.value)"
                                            >
                                                <mat-option *ngFor="let option of shareWithDrp" [value]="option.id">
                                                    {{option.value}}
                                                </mat-option>
                                            </mat-select>
                                            <div class="mat-form-field-subscript-wrapper pt-3">
                                                <mat-error *ngIf="isControlHasError('shareWith', 'required')">Share with cannot be blank.</mat-error>
                                            </div>
                                        </mat-form-field>
                                    </div>
                                </ng-container>
                                
                            </div>
                        </div>
                        <div class="row" *ngIf="shareWith === 2" style="margin-bottom: 30px;">
                            <div class="col-md-4">
                                <div>Select Roles
                                    <span class="mandatory-marker">*</span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <div class="row">
                                    <mat-select [(ngModel)]="selectedRoles" placeholder="Select Roles" formControlName="selectRoles"  [multiple]="true"
                                    style="border-bottom: 1px solid lightgray; padding-bottom: 3px;" name="selectedRoles">
                                        <mat-option *ngFor="let role of roles;" 
                                        [value]="role.roleId">{{role.roleName}}</mat-option>
                                    </mat-select>
                                    <div class="mat-form-field-subscript-wrapper pt-3">
                                    <mat-error *ngIf="isControlHasError('selectRoles', 'required')">Role cannot be blank.</mat-error>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="shareWith === 3" style="margin-bottom: 30px;">
                            <ng-container *ngIf="isLoadingUsers">
                                <div class="col-md-4">
                                    <mat-spinner diameter="20"></mat-spinner>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="!isLoadingUsers">
                                <div class="col-md-4">
                                    <div>Select Users
                                        <span class="mandatory-marker">*</span>
                                    </div>
                                </div>
                                <div class="col-md-8">
                                    <div class="row">
                                        <mat-select [(ngModel)]="selectedUsers" placeholder="Select Users" formControlName="selectUsers"  [multiple]="true" 
                                        style="border-bottom: 1px solid lightgray; padding-bottom: 3px;" name="selectedUsers">
                                            <mat-option *ngFor="let user of users;" 
                                            [value]="user.userId">{{user.fullName}}</mat-option>
                                        </mat-select>
                                        <div class="mat-form-field-subscript-wrapper pt-3">
                                            <mat-error *ngIf="isControlHasError('selectUsers', 'required')">User cannot be blank</mat-error>
                                        </div>
                                    </div>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                    <!-- </div> -->
                </div>
            </div>
            <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="addReport()" [disabled]="isDisabled">Add Reports</button>
            </div>
        </form> 
    </div>
</div>  
