import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SubheaderService } from 'src/app/core/_base/layout';
import { Breadcrumb } from 'src/app/core/_base/layout/services/subheader.service';

@Component({
  selector: 'kt-opportunity-configuration',
  templateUrl: './opportunity-configuration.component.html',
  styleUrls: ['./opportunity-configuration.component.scss']
})
export class OpportunityConfigurationComponent implements OnInit {

  selectedTab = 0;
  moduleType=3;
  returnUrl: any;
  private subscriptions: Subscription[] = [];
  breadcrumbs: Breadcrumb[] = [];
  singularName : string;

  /**
     * Component constructor
     *
     * @param router: Router
     * @param route
     */
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    public subheaderService: SubheaderService
  ) { }

  ngOnInit(): void {

    this.subscriptions.push(this.subheaderService.title$.subscribe(bt => {
      if (bt) {
          this.singularName=bt.singularName;
      }
    }));


      // redirect back to the returnUrl before login
      this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl || '/';
    });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
      this.selectedTab = params.tab;
      console.log('Tab : '+this.selectedTab);

    });
  }

}
