<div class="example-preview">
    <form [formGroup]="supportSettingForm" autocomplete="off">

        <!-- Checkbox to enable chat support -->
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">Show Assistive Chat Support</label>
            </div>
            <div class="col-md-8">
                <mat-checkbox appearance="standard" [checked]="selectChatSupportFlag"
                    (change)="changeChatSupport($event)" formControlName="enableChatSupport"></mat-checkbox>
                <label class="ml-3">Yes</label>
                <br>
                <mat-hint class="form-hint">Tick this if you want to show Assistive chat support.</mat-hint>
            </div>
        </div>

        <!-- Toll Free Number  -->
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-35">Toll Free Number
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                    <input matInput #input2 formControlName="tollFreeNumber" ktStrictNumberOnlyDirective minlength="10"
                        maxlength="11">
                    <mat-hint align="end" style="color: rgba(0,0,0,.54)">
                        {{ supportSettingForm.get('tollFreeNumber').value?.length || 0 }}/11
                    </mat-hint>
                    <mat-hint>Enter a 10-11 digits Toll Free support number. Leave blank if not required.</mat-hint>
                    <mat-error
                        *ngIf="supportSettingForm.get('tollFreeNumber').touched || isControlHasError('tollFreeNumber', 'minlength')">Enter
                        a valid Toll Free support
                        number (10-11 digits).
                    </mat-error>
                    <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">123</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <!-- Support Email -->
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-35">Support Email
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                    <input matInput #input2 formControlName="supportEmail" ktAutoTrimSpaces type="email">
                    <mat-hint>Enter the support email here, with a valid email format. Leave blank if not
                        required.</mat-hint>
                    <mat-error *ngIf="supportSettingForm.get('supportEmail').hasError('email')">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="supportSettingForm.get('supportEmail').hasError('required')">
                        Email is <strong>required</strong>
                    </mat-error>
                    <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">alternate_email</mat-icon>
                </mat-form-field>
            </div>
        </div>

        <!-- Support Timing -->
        <div class="form-group row mt-10">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-35">Support Timings
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8 d-flex">

                <!-- From Day -->
                <mat-form-field appearance="standard" class="custom-width">
                    <mat-label>From</mat-label>
                    <mat-select formControlName="fromDay">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngIf="supportSettingForm.get('fromDay').value" [removable]="true"
                                    (removed)="onDayRemoved(supportSettingForm.get('fromDay'))">
                                    {{ supportSettingForm.get('fromDay').value }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let day of daysArray" [value]="day.value">{{day.value}}</mat-option>
                    </mat-select>
                    <mat-hint>From Day</mat-hint>
                    <mat-error *ngIf="isControlHasError('fromDay', 'required')">Select Day
                    </mat-error>
                </mat-form-field>

                <!-- To Day -->
                <mat-form-field appearance="standard" class="custom-width">
                    <mat-label>To</mat-label>
                    <mat-select formControlName="toDay">
                        <mat-select-trigger>
                            <mat-chip-list>
                                <mat-chip *ngIf="supportSettingForm.get('toDay').value" [removable]="true"
                                    (removed)="onDayRemoved(supportSettingForm.get('toDay'))">
                                    {{ supportSettingForm.get('toDay').value }}
                                    <mat-icon matChipRemove>cancel</mat-icon>
                                </mat-chip>
                            </mat-chip-list>
                        </mat-select-trigger>
                        <mat-option *ngFor="let day of daysArray" [value]="day.value">{{day.value}}</mat-option>
                    </mat-select>
                    <mat-hint>To Day</mat-hint>
                    <mat-error *ngIf="isControlHasError('toDay', 'required')">Select Day
                    </mat-error>
                </mat-form-field>

                <!-- From Time -->
                <div class="time-picker">
                    <mat-form-field class="custom-width" style="width: 80px;" appearance="outline">
                        <input matInput type="number" placeholder="HH" formControlName="fromHours" min="0" max="12">
                        <mat-hint class="form-hint">From Time</mat-hint>
                        <mat-error *ngIf="isControlHasError('fromHours', 'required')">Pick hour
                        </mat-error>
                    </mat-form-field>
                    :
                    <mat-form-field class="custom-width ml-3" style="width: 70px;" appearance="outline">
                        <input matInput type="number" placeholder="MM" formControlName="fromMinutes" min="0" max="59">
                        <mat-error *ngIf="isControlHasError('fromMinutes', 'required')">Pick minute
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="custom-width" style="width: 70px;" appearance="outline">
                        <mat-select formControlName="fromPeriod">
                            <mat-option value="AM">AM</mat-option>
                            <mat-option value="PM">PM</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <!-- To Time -->
                <div class="time-picker">
                    <mat-form-field class="custom-width" style="width: 80px;" appearance="outline">
                        <input matInput type="number" placeholder="HH" formControlName="toHours" min="0" max="12">
                        <mat-hint>To Time</mat-hint>
                        <mat-error *ngIf="isControlHasError('toHours', 'required')">Pick hour
                        </mat-error>
                    </mat-form-field>
                    :
                    <mat-form-field class="custom-width ml-3" style="width: 70px;" appearance="outline">
                        <input matInput type="number" placeholder="MM" formControlName="toMinutes" min="0" max="59">
                        <mat-error *ngIf="isControlHasError('toMinutes', 'required')">Pick minute
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="custom-width" style="width: 70px;" appearance="outline">
                        <mat-select formControlName="toPeriod">
                            <mat-option value="AM">AM</mat-option>
                            <mat-option value="PM">PM</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>

        <div class="row" ktMargin [marginBottom]="'20px'"></div>

        <div class="modal-footer pr-0">
            <button type="button" class="btn btn-secondary" (click)="Cancel()">Cancel</button>
            <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
        </div>

    </form>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Fetching Data... </p>
</ngx-spinner>