<div *ngIf="!isCollapsed" class="card card-custom" [ngClass]="cssClasses">
  <!-- Header -->
  <div class="card-header pl-4 pr-5">
    <div class="card-title text-primary font-weight-bold font-size-h6">
      <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="whatsappConversation"
        [ngStyle]="{'cursor':'pointer'}">
        <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
        <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
      </a>
      {{title}}
    </div>
  </div>

  <div id="whatsappConversation" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 whatsappConversation-body-scroll">
    <div class="card-body p-5 whatsappConversation-scroll">
      <div class="chat-window">
        <div class="messages" #messagesContainer>
          <div *ngFor="let message of messages" class="message d-block"
            [ngClass]="{'business': message.initiatedBy === 'business', 'customer': message.initiatedBy === 'customer'}">
            <img *ngIf="message.messageType===1" [src]="message.messageBody" alt="Image">
            <div class="message-content" *ngIf="message.messageType===0 || message.messageType===3 || message.messageType==2">{{
              message.messageBody }}</div>

            <div class="message-meta">
              <span class="timestamp">{{ message.createdOn | date:'shortTime' }}</span>
              <span class="read-receipt" *ngIf="message.status !== null">
                <ng-container [ngSwitch]="message.status">
                  <img *ngSwitchCase="0" src="./assets/media/svg/icons/Communication/check_mark.svg" alt="Sent">
                  <img *ngSwitchCase="1" src="./assets/media/svg/icons/Communication/double_check_mark.svg"
                    alt="Delivered">
                  <img *ngSwitchCase="2" src="./assets/media/svg/icons/Communication/double_check_blue.svg" alt="Read">
                  <img *ngSwitchCase="3" src="./assets/media/svg/icons/Communication/check_mark.svg" alt="Failed">
                </ng-container>
              </span>
            </div>
          </div>

        </div>
        <div class="chat-input-container">
          <input placeholder="Type a message">
          <div class="add-button" (click)="fetchTemplates()">
            <i class="fa fa-plus"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>