<div class="modal-header">
  <h4 class="modal-title">Edit Display Order</h4>
  <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
      <span aria-hidden="true">&times;</span>
  </button>
</div>

<div cdkDropList [cdkDropListData]="dashBoardList" class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let list of dashBoardList" cdkDrag><i class="fas fa-align-justify text-primary icon-md ml-4"></i><span class="ml-5">{{list.dashboardName}}</span></div>
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="save()" [disabled]="isDisabled">Save</button>
</div>