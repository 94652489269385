import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'kt-clone-dashboard',
  templateUrl: './clone-dashboard.component.html'
})
export class CloneDashboardComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  previousDashboardName;
  previousDashboardId;
  public cloneDashForm: FormGroup;

  constructor(private fb:FormBuilder,
    public activeModal: NgbActiveModal,
    private snackBar:MatSnackBar,
    public _dbs: DashboardService,) { }

  ngOnInit(): void {
    this.initaddClonecloneDashForm();
  }

  initaddClonecloneDashForm(){
    console.log(this.previousDashboardName);
    this.cloneDashForm = this.fb.group({
      name: ['Copy of '+this.previousDashboardName,[Validators.required,Validators.maxLength(25) ]],
     });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.cloneDashForm.controls[controlName];
      if (!control) {
        return false;
      }

      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }
  Close(){
    this.activeModal.close();
    this.cloneDashForm.reset();
  }

  save(){
    const controls = this.cloneDashForm.controls;
		/** check form */
		if (this.cloneDashForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      return;
  }
  this._dbs.checkDashboardName(this.selectedProject, controls.name.value, this.previousDashboardId).subscribe(
    data=>{
      const addCloneData = {
        dashboardName: controls.name.value,
        dashboardId: this.previousDashboardId,
        projectId: this.selectedProject,
      };
      this._dbs.cloneDashboard(addCloneData).subscribe(data=>{
        this.activeModal.close(data['responseData'].dashboardId);
        this.snackBar.open('Dashboard cloned successfully', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
      },
      error=>{
        this.snackBar.open('Failed', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      })
    },
    error=>{
      this.snackBar.open(`A dashboard with the name ${controls.name.value} already exist in this project.`, '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }
  );
  
}

}
