import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-edit-dashboard-alert',
  templateUrl: './edit-dashboard-alert.component.html'
})
export class EditDashboardAlertComponent implements OnInit {
  isLoading: boolean = false;
  constructor( public activeModal: NgbActiveModal, private router: Router ) { }

  ngOnInit(): void { 
    console.log(this.activeModal);
    
  }

  copyDashBoard() {
    this.activeModal.close('Success');
  }

  

  Close() {
    this.activeModal.close();
  }
}
