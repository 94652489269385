<div class="example-preview">
    <div class="modal-header">
        <h4 class="modal-title">{{currentStep == STEPS.TEMPLATE_SELECTION ? 'Available Templates' : 'Message Content'}}
        </h4>
    </div>

    <div class="modal-body">
        <!-- Template Selection Section-->
        <div *ngIf="currentStep == STEPS.TEMPLATE_SELECTION" class="template-pile">
            <div *ngFor="let template of templates" [ngClass]="{'selected': template.name === selectedTemplate?.name}"
                class="template-card" (click)="selectTemplate(template)">
                <div class="template-content">
                    <span class="template-name">{{template.name}}</span>
                    <span class="separator">|</span>
                    <span class="template-details">{{template.varibaleCount}} Variables</span>
                    <span class="separator" *ngIf="template.hasMedia">|</span>
                    <img *ngIf="template.hasMedia" src="./assets/media/svg/icons/Media/attachment.svg" alt="Document"
                        class="">
                </div>
            </div>

            <!-- Add New Template Card -->
            <div class="template-card add-new-template">
                <div class="add-new-template-content">
                    <span class="">Add New Template in the <span class="campaign-manager">Campaign Manager</span></span>
                    <img src="./assets/media/svg/icons/Media/add-button.svg" alt="Add Template"
                        class="add-button-image">
                </div>
            </div>
        </div>

        <!-- Message Sending Part -->
        <div *ngIf="currentStep == STEPS.SEND_MESSAGE">
            <!-- Template Preview Section -->
            <div class="form-group">
                <div class="row">
                    <label class="section-label mb-4 ml-4">Template preview</label>
                </div>
                <div class="row">
                    <div class="col mb-4">
                        <textarea [(ngModel)]="messageContent" name="markdown" class="form-control"
                            data-provide="markdown" rows="12" readonly></textarea>
                    </div>
                </div>
            </div>

            <!-- Variables Input Section -->
            <!-- Header Variables -->
            <div *ngIf="headerVariables.length > 0" class="form-group row">
                <label class="section-label ml-4">Header</label>
            </div>
            <div class="form-group row mt-3" *ngFor="let variable of headerVariables; let i = index">
                <label class="col-lg-1 col-form-label">{{variable}}</label>
                <div class="col-lg-11">
                    <input type="text" class="form-control" placeholder="Enter value"
                        [(ngModel)]="userInputForHeaderVariables[i]" (input)="updateMessageContent()" #headerVariableInput="ngModel"/>

                    <mat-error *ngIf="headerVariableInput.touched && !userInputForHeaderVariables[i]" class="custom-error">This field is required.</mat-error>
                </div>
            </div>

            <!-- Body Variables -->
            <div *ngIf="bodyVariables.length > 0" class="form-group row">
                <label class="section-label mt-3 ml-4">Body</label>
            </div>
            <div class="form-group row mt-3" *ngFor="let variable of bodyVariables; let i = index">
                <label class="col-lg-1 col-form-label">{{variable}}</label>
                <div class="col-lg-11">
                    <input type="text" class="form-control" placeholder="Enter value"
                        [(ngModel)]="userInputForBodyVariables[i]" (input)="updateMessageContent()" #headerVariableInput="ngModel"/>

                    <mat-error *ngIf="headerVariableInput.touched && !userInputForBodyVariables[i]" class="custom-error">This field is required.</mat-error>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="onClose()">Close</button>
        <button *ngIf="currentStep == STEPS.SEND_MESSAGE" type="button" class="btn btn-secondary"
            (click)="previousTab()">Back</button>
        <button *ngIf="currentStep == STEPS.TEMPLATE_SELECTION" type="button" class="btn btn-primary"
            (click)="onConfirm()" [disabled]="!isTemplateSelected">Next</button>
        <button *ngIf="currentStep == STEPS.SEND_MESSAGE" type="button" class="btn btn-primary"
            (click)="onSendMessage()" [disabled]="hasEmptyFields">Send</button>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="fetching-templates">
    <p style="color: white"> {{spinnerText}} </p>
</ngx-spinner>