<!-- existing carousels -->
<div *ngIf="!uploadFunctionality" class="col-md-12 mt-5 pl-0" style="display: flex; flex-wrap: wrap;">
    <input [id]="UPDATE_CAROUSEL" type="file" style="display:none;" (change)="uploadCarouselFile($event)">
    <div class="flex-row">
        <!-- carousel image container -->
        <div class="carousel-container" (mouseover)="showImageEditIcon()" (mouseleave)="hideImageEditIcon()">
            <div class="remove-img-btn">
                <button [hidden]="!allowImageEdit" mat-icon-button class="btn btn-xs btn-icon btn-light-primary"
                    title="Edit image" (click)="updateCarousel()">
                    <i class="far fa-edit"></i>
                </button>
            </div>
            <img width="50" height="50" [src]="imageUrl" alt="carousel" class="img-thumbnail">
        </div>
        <!-- carousel hyperlink -->
        <div class="hyperlink-container ml-4">
            <input matInput #hyperlinkInput [ngClass]="{'invalid-input': _hyperlinkInvalidStatus}" placeholder="Add hyperlink"
                ktAutoTrimSpaces [ngModel]="hyperLink" (focus)="_hyperlinkInvalidStatus=false"
                (blur)="_hyperlinkInvalidStatus=hyperlinkInvalid" (change)="hyperlinkChanged(hyperlinkInput.value)"
                (keydown.enter)="preventEnter($event)">
        </div>
    </div>
    <div class="flex-row">
        <!-- select user roles -->
        <div class="form-group row" style="margin-top: -10px;">
            <div class="col-md-6 pt-2 pr-0">
                <label class="col-form-label">Display this image only to</label>
            </div>
            <div class="col-md-6 pl-0">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select multiple [ngModel]="selectedUserRoles"
                        (selectionChange)="userRoleChanged($event.value)">
                        <mat-option *ngFor="let userRole of allUserRoles" [value]="userRole.roleId">
                            {{userRole.roleName}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </div>
</div>

<!-- upload new carousel -->
<div *ngIf="uploadFunctionality">
    <input [id]="UPLOAD_CAROUSEL" type="file" style="display:none;" (change)="uploadCarouselFile($event)">
    <div class="row col-md-12 mt-4">
        <div class="carousel-upload-col">
            <div class="carousel-upload-icon">
                <img width="40" height="50" src="./assets/media/svg/icons/General/imageIcon.svg" alt="upload file"
                    class="img-thumbnail" (click)="uploadCarousel()">
            </div>
        </div>
        <div class="carousel-upload-col">
            <span class="carousel-hint">Max Images: 5, 1 image per selection</span>
            <span class="carousel-hint" [ngStyle]="{'color': fileSizeError ? '#F64E60': ''}">
                Max File Size: {{MAX_ALLOWED_SIZE}} MB
            </span>
            <span class="carousel-hint" [ngStyle]="{'color': fileTypeError ? '#F64E60': ''}">
                Image Type: .jpeg, .png files only
            </span>
        </div>
    </div>
</div>