
import { BehaviorSubject } from 'rxjs';


export class DialerDashboardService {
    entityId$:BehaviorSubject<number> = new BehaviorSubject<number>(0);

    constructor(){

    }
    setEntityType(entityid:number){
      console.log(entityid);
        this.entityId$.next(entityid);
        console.log("entityId set to :-",this.entityId$);
        
      }
}


