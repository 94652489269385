import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-edit-report-alert',
  templateUrl: './edit-report-alert.component.html'
})
export class EditReportAlertComponent implements OnInit {
  isLoading: boolean = false;
  constructor( public activeModal: NgbActiveModal, private router: Router ) { }

  ngOnInit(): void { 
    console.log(this.activeModal);
    
  }

  copyReport() {
    this.router.navigateByUrl('/report-builder-edit');
    this.activeModal.close('Success');
  }

  

  Close() {
    this.activeModal.close();
  }
}
