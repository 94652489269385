import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';


import { ConfigurationAssistantComponent } from './configuration-assistant.component';
import { InlineSVGModule } from 'ng-inline-svg';


const routes:Routes=[
  {path:'',component:ConfigurationAssistantComponent}
]

@NgModule({
  declarations: [ConfigurationAssistantComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild(routes),
    InlineSVGModule
  ]
})
export class ConfigurationAssistantModule { }
