<div class="example-preview">
    <div>
            <form [formGroup]="editContactForm" autocomplete="off" >
                <div class="modal-header">
                    <h4 class="modal-title">Edit Contact Type</h4>
                    <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                 <div class="modal-body">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Contact Type</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input  matInput  #input  maxlength="50" formControlName="contactTypeName" [(value)]="contactTypeName">
                          
                                <mat-hint align="end">{{input.value?.length || 0}}/50</mat-hint>
                                <mat-error *ngIf="isControlFormHasError('contactTypeName', 'required')"><strong>Contact Name cannot be blank</strong></mat-error>
                                <mat-error *ngIf="isControlFormHasError('contactTypeName', 'maxlength')"><strong>Maximum 50 characters</strong></mat-error>
                                <mat-icon  matSuffix [ngStyle]="{'color':'blue'}">text_fields</mat-icon> 
                                </mat-form-field>                    
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                             <label>Primary Contact</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsEdit(checkbox.checked)'  [checked]="primary_Value==true" [(value)]=primary_Value>
                                Select if this is a primary contact type
                            </mat-checkbox>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label">Status</label>
                       </div>
                       <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100" >
                                <mat-select [(value)]="selectedValue">
                                    <mat-option value="1">Active</mat-option>
                                    <mat-option value="0">Inactive</mat-option>
                                </mat-select>
                            </mat-form-field>
                       </div>   
                    </div>   
                    <div class="form-group row">
                        <div class="col-md-12">
                            <span style="color:red">{{errorMes}}</span>
                        </div>
                        
                    </div>    
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                    <button type="button" class="btn btn-primary"  [disabled]='isDisabled()' (click)="Edit()">Save</button>
                </div>
            </form> 
        
    </div>
</div>  
