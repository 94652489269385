import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { localization } from '../models/localization.model';
import { environment} from '../../../../../environments/environment';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';
import { roleModel } from '../../user-role/models/role.model';
import { CarouselDetails } from '../models/carousel-details.model';

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { }

  getLocalization(projectId){
    console.log(environment.baseUrl + '/setting/api/projects/'+projectId);
    return this.http.get<any[]>(environment.baseUrl + '/setting/api/projects/'+projectId);
  }

  updateLocalization(selectedCountryId : number,selectedTimeZoneId : number,
    selectedLanguageId : number,projectId: number,systemName: string,systemLogo:String,
    selectCarouselFlag: number, carouselDetails: Array<CarouselDetails>,
    userTargetFlag: number, customerTargetFlag: number, opportunityTargetFlag: number){

   // return this.http.post<any[]>(environment.baseUrl+'/setting/api/projects/{'+this.selectedProject,localizationData);
   return this.http.post<any[]>(environment.baseUrl + '/setting/api/projects/'+projectId,{"selectedCountryId" : selectedCountryId,"selectedTimeZoneId":selectedTimeZoneId,"selectedLanguageId":selectedLanguageId,
   "projectId" : projectId,"systemName":systemName,"systemLogo":systemLogo,
      "selectCarouselFlag": selectCarouselFlag, "carouselDetails": carouselDetails,
      "opportunityTargetFlag": opportunityTargetFlag, "customerTargetFlag":customerTargetFlag, "userTargetFlag": userTargetFlag,
  });
  }

  // Opportunity Setting Service
  saveOpportunitySettings(addSettingValues){
    addSettingValues.userTime=this.userLocalization.getUserTime();
    addSettingValues.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any>(environment.baseUrl +'/setting/api/opportunity/addUpdateOpportunitySetting',addSettingValues)
  }

  getOpportunitySettings(projectId:number){
    return this.http.post<any[]>(environment.baseUrl +'/setting/api/opportunity/getOpportunitySetting',{"projectId": projectId})
  }

  // Contact Setting Service
  saveContactSettings(addSettingValues ){
    addSettingValues.userTime=this.userLocalization.getUserTime();
    addSettingValues.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any>(
      environment.baseUrl +'/setting/api/contact/addUpdateContactSetting',addSettingValues)
  }
  
  getContactSettings(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/contact/getContactSetting?projectId='+projectId);
  }

  // Company Setting Service
  saveCompanySettings(addSettingValues ){
    addSettingValues.userTime=this.userLocalization.getUserTime();
    addSettingValues.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any>(
      environment.baseUrl +'/setting/api/company/saveCompanySettings',addSettingValues)  }

  getCompanySettings(projectId:number){
    return this.http.post<any[]>(
      environment.baseUrl +'/setting/api/company/getCompanySettings',{"projectId": projectId})  }

  getModulesDynamicName(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/projects/'+projectId+'/dynamicFieldNames')
  }

  getFields(projectId: number, moduleType: number) {
    return this.http.get(
      environment.baseUrl + '/transactions/api/customType/getFields?projectId=' + projectId + '&moduleType=' + moduleType);
  }

  getActiveRoleList(projectId: number){
    return this.http.get<roleModel[]>(environment.baseUrl +'/setting/api/roles/?projectId=' + projectId + '&activeStatus=1');
  }
  
  fetchActiveUserList(projectId: number){
    return this.http.get(
      environment.baseUrl + '/login/api/users?activeStatus=1&projectId='+projectId);
  }

  getSupportSetting(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl +'/setting/api/projects/getSupportSettingDetails?projectId='+projectId);
  }
  saveSupportSettings(addSupportSettingsValues){
    addSupportSettingsValues.userTime=this.userLocalization.getUserTime();
    addSupportSettingsValues.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any>(environment.baseUrl +'/setting/api/projects/addUpdateSupportSetting',addSupportSettingsValues)
  }


}
