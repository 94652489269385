import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { WhatsAppTemplate } from '../../../../models/whatsapp-template.model';
import { WaTemplateListComponent } from './_subs/wa-template-list/wa-template-list.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'kt-widget7-whatsapp-conversations',
  templateUrl: './widget7-whatsapp-conversations.component.html',
  styleUrls: ['./widget7-whatsapp-conversations.component.scss']
})
export class Widget7WhatsappConversationsComponent implements OnInit {

  templates: WhatsAppTemplate[] = [];
  showTemplates = false;

  @Input() cssClasses = '';
  public isCollapsed: boolean = true;
  @Input() isActCollaspsed: string;
  @Input() conversations=[];
  @Input() public companyInfoData;
  messages =[];
  title:String='Whatsapp Conversations';

  @ViewChild('messagesContainer') private messagesContainer: ElementRef;


  constructor(private modalService: NgbModal,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isActCollaspsed'] && changes['isActCollaspsed'].firstChange==false ) {
      this.messages = changes['isActCollaspsed'].currentValue.conversation;
      this.isCollapsed=false;
      this.updateTitle(this.messages?.[0]?.createdOn);
    }
  }
  updateTitle(cdate: any) {
    const date = new Date(cdate);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    const dayOfWeek = date.toLocaleString('default', { weekday: 'long' });
    const ordinalSuffix = (day: number): string => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };
    this.title = `${day}${ordinalSuffix(day)} ${month}, ${year}, ${dayOfWeek}`;
  }


  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  private scrollToBottom(): void {
    try {
      this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
    } catch (err) { }
  }

  fetchTemplates() {
    const modalRef = this.modalService.open(WaTemplateListComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static' 
    });

    modalRef.componentInstance.companyId = this.companyInfoData['companyId'];
    modalRef.componentInstance.customerWaNumber = this.companyInfoData['customerWaNumber'];

    modalRef.result.then((result) => {
      if(result == 'SUCCESS'){
        this.snackBar.open('Message sent successfully!!', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });

      } else if(result == 'ERROR'){
        this.snackBar.open('Failsed to send the message. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    });
  }
}
