<div>
    <div class="modal-header" style="background:#3699FF !important ;">
        <h4 class="modal-title" style="color: #F5F7FD !important ;">New Call</h4>
    </div>
    <div class="modal-body" style="height: 260px; padding: 1rem;">
        <ngx-spinner class="search-dialer-spinner" size="default" type="ball-spin-clockwise" name='searchCallers'>  
            <p style="color: white">Fetching Data... </p>  
        </ngx-spinner>
        <div style="height: 165px; overflow-y: auto;">
            <div *ngIf = "emptycallerList" class="emptyMessage">No Results Found</div>
            <div *ngFor ="let caller of callerList" class="caller-item width-100" >
                <div class="width-100">
                    <span class="caller-id-name">{{caller.callerIdName}}</span>
                    <span class="float-right">
                        <img *ngIf="caller.callerIdNumber" src="./assets/media/svg/icons/Communication/call-icon.svg" height="16" (click)="call(caller)">
                        <img *ngIf="!caller.callerIdNumber" src="./assets/media/svg/icons/Communication/call-icon-disabled.svg" height="16">
                    </span>
                </div>
                <div class="caller-entity-name">
                    {{caller.moduleName}}
                </div>
            </div>
            
        </div>
        <div class="searchbar">
            <label>Search</label>
            <input placeholder="Type text and press enter to search" #callerSearch (keyup.enter)="searchCallerId(callerSearch.value)">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-secondary btn-sm" (click)="closeModal()">
            Close
        </button>
    </div>
</div>