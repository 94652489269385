<button #myButton id="refresh" aria-hidden="true" class="display-none"></button>
<div class="example-preview">
    <form  [formGroup]="localizationForm" autocomplete="off" >           
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Country
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="countryName">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let country of countryList| search : countryNameFilter.value :'name'" [value]="country.id">
                            {{country.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select country</mat-hint>
                    <mat-error *ngIf="isControlHasError('countryName', 'required')">Country is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Time Zone
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="timeZone">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="timeZoneFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let zone of timeZoneList| search : timeZoneFilter.value :'name'" [value]="zone.id">
                            {{zone.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select Time Zone</mat-hint>
                    <mat-error *ngIf="isControlHasError('timeZone', 'required')">Time Zone is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-15">Language
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field class="mat-form-field-fluid input-100">
                    <mat-select formControlName="language">
                        <mat-option>
                            <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="languageFilter" ></ngx-mat-select-search>
                        </mat-option>
                        <mat-option *ngFor="let lang of languageList| search : languageFilter.value :'name'" [value]="lang.id">
                            {{lang.name}}
                        </mat-option>
                    </mat-select>
                    <mat-hint>Select Language</mat-hint>
                    <mat-error *ngIf="isControlHasError('language', 'required')">Language is required</mat-error>
                </mat-form-field>
           </div> 
        </div>

        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">System Name
                    <span class="mandatory-marker">*</span>
                </label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #systemNameInput placeholder="" formControlName="systemName" ktAutoTrimSpaces>
                    <mat-hint align="end">{{systemNameInput.value?.length || 0}}/50</mat-hint>    
                    <mat-error *ngIf="isControlHasError('systemName', 'required')">System Name is required</mat-error>
                    <mat-error *ngIf="isControlHasError('systemName', 'maxlength')"><strong>Maximum 100 characters</strong></mat-error>
                    <mat-icon *ngIf="isControlHasError('systemName', 'required') == false;" matSuffix [ngStyle]="{'color':'blue'}"></mat-icon>
                </mat-form-field>  
           </div> 
        </div>
         <!-- project logo -->
         <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">System Logo</label>
            </div>
                        
         <div class="col-md-8">
            <div class="mat-form-field">
                <div class="mat-form-field-wrapper">
                    <input id="sys_logo"  type="file" style="display:none;"
                        (change)="processFile($event)">
                    
                    <span *ngIf="systemLogoPath">
                            <div class="artist-collection-photo">
                                <div (mouseover)="showImageEditOption()" (mouseleave)="hideImageEditOption()" class="parent-img">
                                    <div class="remove-img-btn">
                                    <button [hidden]="!allowImageEdit"  mat-icon-button  class="btn btn-xs btn-icon btn-light-primary" title="Edit image" (click)="clickImage(sys_logo)">
                                        <i class="far fa-edit"></i>
                                    </button></div>

                                    <img width="50" height="50" [src]= "systemLogoPath" alt="image.jpg" class="img-thumbnail">
                                </div>
                            </div>
                    </span>
                    <span *ngIf="!systemLogoPath"> 
                        <div class="artist-collection-photo">
                            <img width="35" height="50" src="./assets/media/svg/icons/General/upload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(sys_logo)">	
                        </div>
                    </span>
                </div>
            </div>
            <div class="mat-form-field-subscript-wrapper">
                <ng-container *ngIf="!logoFieldHasError">
                    <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >Upload .jpeg , .jpg, .png , .gif files only, height should be upto 100 pixels and file size should be upto 40 KB</mat-hint>
                </ng-container>
                
                <mat-error class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" *ngIf="logoFieldHasError">{{logoFieldErrorMsg}}</mat-error>
            </div>
                                
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="sys_logo"></ngx-spinner>
      
        </div>
        </div>

        <div style="height: 40px;"></div>

        <!-- Carousel Images -->
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label pt-0">Upload Carousel Images</label>
            </div>
        
            <div class="col-md-8">
                <mat-checkbox [checked]="selectCarouselFlag" (change)="changeCarouselCB($event)"></mat-checkbox>
                <label class="ml-3">Yes</label>
                <br>
                <mat-hint class="form-error">Tick this if you want to show carousel images in the Assistive App</mat-hint>
                <div style="height: 1rem;"></div>
                <span *ngIf="selectCarouselFlag">
                    <!-- <div > -->
                        <kt-carousel-item *ngFor="let carouselDetail of selectedCarouselDetails; let i = index"
                            [id] = "i"
                            [imagePath] = "carouselDetail.imagePath"
                            [hyperLink] = "carouselDetail.hyperlink"
                            [selectedUserRoles] = "carouselDetail.userRoles"
                            [userRoles] = "activeUserRoles"
                            (hyperlinkChanged) = "carouselHyperlinkChanged(i, $event)"
                            (userRoleChanged) = "carouselUserRoleChanged(i, $event)"
                            (imageUpdated)="carouselImageUpdated(i, $event)"
                            (busyStatusChanged) = "handleBusyStatus($event)"
                        >
                        </kt-carousel-item>
                    <!-- </div> -->
                    <kt-carousel-item *ngIf="showCarouselUpload"
                        [uploadFunctionality]="true" 
                        (busyStatusChanged)="handleBusyStatus($event)"
                        (newCarouselAdded)="newCarouselAdded($event)"
                    >
                    </kt-carousel-item>
                </span>
            </div>
        
            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" 
                type="ball-spin-clockwise" [name]="UPLOAD_CAROUSEL">
            </ngx-spinner>
        </div>

        <!-- Target Settings -->
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label pt-0">Enable Target for</label>
            </div>
        
            <div class="col-md-8">
                <mat-checkbox formControlName="targetUserFlag"></mat-checkbox><label class="ml-3 mr-3">Users</label>
                <mat-checkbox formControlName="targetCustomerFlag"></mat-checkbox><label class="ml-3 mr-3">{{dynamicName.customerTabLabel}}</label>
                <mat-checkbox formControlName="targetOpportunityFlag"></mat-checkbox><label class="ml-3 mr-3">{{dynamicName.opportunityTabLabel}}</label>
            </div> 
        
        </div>
        <div class="form-group row">
            <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">System Id</label>
            </div>
            <div class="col-md-8">
                <mat-form-field appearance="standard" class="input-100" >
                    <input matInput #systemIdInput placeholder="" formControlName="systemId" ktAutoTrimSpaces>
                    <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                </mat-form-field>  
           </div> 
        </div>

        
      
        <div class="row" ktMargin [marginBottom]="'20px'"></div>

        <div class="modal-footer pr-0">
            <button type="button" class="btn btn-secondary" (click)="ResetForm()">Cancel</button>
            <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
        </div>
        
    </form> 
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
