<ng-container *ngIf="user"> <!--*ngIf="user$ | async as _user"-->
	<!-- begin::Offcanvas Toolbar Quick Actions -->
	<div ktOffcanvas [options]="offcanvasOptions" id="kt_quick_user" class="offcanvas offcanvas-right p-10"
		[ngStyle]="{ overflow: 'hidden' }">
		<div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
			<h3 class="font-weight-bold m-0">
				User Profile
			</h3>
			<a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
				<i class="ki ki-close icon-xs text-muted"></i>
			</a>
		</div>

		<div class="offcanvas-content" [ngStyle]="{ 'height': '100vh', 'overflow': 'hidden' }">
			<div [perfectScrollbar]="{ wheelPropagation: false }" class="scroll"
				[ngStyle]="{ 'height': '600px', 'position': 'relative' }">
				<div class="d-flex align-items-center mt-5">
					<div class="symbol symbol-100 mr-5">						
						<!-- Profile image section -->
						<div (mouseover)="showImageEditOption()" (mouseleave)="hideImageEditOption()"  class="symbol-label" [ngStyle]="{'background-image': 'url('+ userProfilePic + ')'}">		  
							<!--<mat-icon aria-hidden="false" aria-label="Example home icon" style="margin-top: 83px;margin-left: 80px;">
								<mat-select >				  
									<mat-option value="1" (click)="selectProfilepic($event)" >Upload New Image</mat-option>
									<mat-option value="2" (click)="removeProfilePic($event)" >Remove Image</mat-option>
								</mat-select>
							</mat-icon>					
							<input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" class="ng-hide">	

							<div *ngIf="showImageProcessing" class="img-loading-overlay">
								<div class="img-spinning-circle"></div>
							  </div>-->

							  <!-- 
							   <button [hidden]="!allowImageEdit" style="margin-left: -154px;"  mat-icon-button  [matMenuTriggerFor]="profilePic" #profilePicTrigger="matMenuTrigger" class="pic-icon-positon btn btn-xs btn-icon btn-light-primary">
								<i class="far fa-edit"></i>
							</button> 
							-->


							<ng-container *ngIf="imageNotUploaded; then uploadImg; else chooseOperation"> </ng-container>
						<ng-template #uploadImg>
							<button [hidden]="!allowImageEdit"  matTooltip="Change Profile Image" mat-icon-button  (click)="selectProfilepic($event)" class="pic-icon-positon btn btn-xs btn-icon btn-light-primary" title="Change Profile Image">
								<i class="far fa-edit"></i>
							</button>
						</ng-template>
						<ng-template #chooseOperation>
							<button [hidden]="!allowImageEdit" matTooltip="Change Profile Image" mat-icon-button  [matMenuTriggerFor]="profilePic" class="pic-icon-positon btn btn-xs btn-icon btn-light-primary" title="Change Profile Image">
								<i class="far fa-edit"></i>
							</button>
						</ng-template>



						</div>
						
						

						 
						
						<mat-menu #profilePic="matMenu">
							<button mat-menu-item (click)="selectProfilepic($event)" class="custom-font-pic-upload text-hover-primary">
								<!--<mat-icon>dialpad</mat-icon>-->
								<span>Upload New Image</span>
							</button>
							<button mat-menu-item (click)="removeProfilePic($event)" class="custom-font-pic-upload text-hover-primary">
								<!--<mat-icon>voicemail</mat-icon>-->
								<span>Remove Image</span>
							</button>
						</mat-menu>
						<input #imageInput type="file" accept="image/*" (change)="processFile(imageInput)" style="display:none;" >	
						
						<ngx-spinner size="default" type="ball-spin-clockwise" name="showImageProcessing">  
				            <p style="color: white"></p>  
				        </ngx-spinner>
						
						<!-- End Profile image section -->
						
					</div>
					<div class="d-flex flex-column">
						<span class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
							{{user.firstName}}  {{user.lastName}}
						</span>
						<div class="navi mt-2">
							<span class="navi-item">
								<span class="navi-link p-0 pb-2">
									<span class="navi-icon mr-1">
										<span class="svg-icon svg-icon-lg svg-icon-primary"
											[inlineSVG]="'./assets/media/svg/icons/Communication/Mail-Custom24.svg'"></span>
									</span>
									<span class="navi-text text-muted text-hover-primary">
										{{user.userName}}
									</span>
								</span>
							</span>
						</div>
						<a (click)="logout()" class="btn btn-light-primary btn-bold">
							Sign Out
						</a>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- end::Offcanvas Toolbar Quick User Actions -->
</ng-container>