<div class="example-preview" style="background-color:  #FFFFFF;">
    <form [formGroup]="reportBuilderForm" novalidate autocomplete="off" >
        <div class="row vertical-divider" >
            <div class="col-sm-3 " >
                <div style="padding-left: 10px;">
                    <table>            
                        <td class="report-header">
                            <span style=" text-align: left; color: #3699FF;">Report Type</span>
                        </td>
                        <td>
                            <span  *ngIf="collapsedReportType" (click)="expandReportType()">
                                <i style="color:rgb(54, 153, 255);" class="fa fas fa-angle-double-down"></i>
                            </span>
                            <span  *ngIf="!collapsedReportType" (click)="collapseReportType()">
                                <i style="color:rgb(54, 153, 255);" class="fa fas fa-angle-double-up "></i>
                            </span>
                        </td>
                    </table>
                    <hr style="border: 1px solid rgba(63, 66, 84, 0.4);">
                    <div style="width: 300px; " >
                            <mat-nav-list *ngIf="!collapsedReportType">
                                <mat-list-item  *ngFor="let type of reportTypeList; index as i" (click)="onReportTypeChange(type.id,i)" [class.active]="selectedItem === i">
                                    <div mat-line style="height: 20px;">
                                        <td  [ngStyle]="{'width': '25px'}"> <i [ngClass]="['fa', 'fas', type.iconSvg, 'icon-md']"></i></td> 
                                        <td  class="report-names"> {{type.typeName}}</td>
                                    </div>
    
                                </mat-list-item>
                               
                            </mat-nav-list>
                        <td style="height: 30px;"></td>
                        
                    </div>
                </div>
                <div style="padding-left: 10px;">
                    <table>            
                        <td style="padding-left: 12px; width: 100%;">
                            <span style="text-align: left; color: #3699FF;">Data Fields</span>
                        </td>
                        <td>
                            <span  *ngIf="collapsedDataField" (click)="expandDataField()">
                                <i style="color:rgb(54, 153, 255);" class="fa fas fa-angle-double-down"></i>
                            </span>
                            <span  *ngIf="!collapsedDataField" (click)="collapseDataField()">
                                <i style="color:rgb(54, 153, 255);" class="fa fas fa-angle-double-up "></i>
                            </span>
                        </td>
                    </table>
                    <hr style="border: 0.5px solid rgba(63, 66, 84, 0.4);">
                    <div style="overflow-y: auto; height:450px;">
                    <div style="width: 100%; padding-left: 12px;" *ngIf="!collapsedDataField" >
                        <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                            <mat-label >Entity</mat-label>
                            <mat-select formControlName="entityType" multiple  (selectionChange)="invokeEntityType($event.value)" placeholder="Entity">
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="entityDetailsValue" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let entity of entityDetailsList | search : entityDetailsValue.value :'entityName'" [value]="entity.id">
                                    {{entity.entityName}}
                                </mat-option>
                            </mat-select>
                            <mat-error  *ngIf="isControlHasError('entityDetailsValue', 'required')">Select one</mat-error>
                        </mat-form-field>
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search"  autocomplete="off" [(ngModel)]="searchValue" [ngModelOptions]="{standalone: true}" >
                             
                        </mat-form-field>
                    </div>
                    <div style="width: 100%;" *ngIf="isEntitySelected">
                        <div style="width: 100%; padding-top: 10px;" *ngIf="!collapsedDataField" >
                                <div
                                cdkDropList
                                #todoList="cdkDropList"
                                [cdkDropListData]="todo"
                                [cdkDropListConnectedTo]="[XaxisList,YaxisList,filterList,ColumnaxisList,userfilterList]"
                                class="example-list"
                                (cdkDropListDropped)="drop($event)">
                                <div class="example-box2" *ngFor="let item of todo | filter : searchValue" (cdkDragMoved)="move(item)" cdkDrag>
                                    <td  [ngStyle]="{'width': '25px'}"> <i style="color: #3699FF;" [ngClass]="['fa', 'fas',item.menuIcon, 'icon-s']"></i></td> 
                                        <td > {{item.entityFieldName}}</td>
                                </div>
                            </div>
                        
                        </div>
                    </div>
                    <div *ngIf="!isEntitySelected && !collapsedDataField" style="width: 100%; padding-top: 10px;">
                        <p class="entity-message">Select entities above to start building your report</p>
                    </div>
                    </div>
                    
                </div>
            </div>
          
            <div class="col-sm-3 ">
                <table>            
                    <td class="report-header">
                        <span style="text-align: left; color:  #3699FF; ">Fields</span>
                    </td>
                    <td>
                        <span  >
                            <i style="color:rgb(54, 153, 255);" class="fa fas fa-angle-double-down"></i>
                        </span>
                    </td>
                </table>
                <hr style="border: 1px solid rgba(63, 66, 84, 0.4);">
                <div style="width: 100%;" *ngIf="!isReportTypeSelected">
                    <p class="entity-message">Select any 'Report Type' to view fields here</p>
                </div>
                <div style="width: 100%;">
                  
                        <div *ngIf="isReportTypeSelected">
                        <div *ngIf="reportType == 1 || reportType == 2 || reportType == 4">
                            <span style="text-align: left; color:black">X axis
                                <span class="mandatory-marker">*</span></span>
                        </div>
                        <div *ngIf="reportType == 5 || reportType == 6"  >
                            <span style=" text-align: left; color:black">Row
                                <span class="mandatory-marker">*</span></span>
                        </div>
                        <div *ngIf="reportType == 3"  >
                            <span style=" text-align: left; color:black">Field          
                                <span class="mandatory-marker">*</span></span>
                        </div>
                        </div>
                        <div style="width: 100%;">
                            <div
                                cdkDropList
                                #XaxisList="cdkDropList"
                                [cdkDropListData]="Xaxis"
                                [cdkDropListConnectedTo]="[todoList]"
                                class="example-list"
                                (cdkDropListDropped)="drop($event)">
                                <div *ngIf="isReportTypeSelected">
                                    <div class="example-box"  *ngFor="let item of Xaxis" cdkDrag> 
                                        <td style="padding-left: 10px; width: 20px;"><i style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-align-justify"></i></td>
                                        <td style="padding-left: 10px; width: 1000px;"> {{item.label}}  </td>
                                        <td style="width: 70px;"><i (click)="OpenEditField(item,Xaxis,'Edit Field')" style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-angle-double-right"></i></td></div>
                                    <div *ngIf="Xaxis.length == 0" class="example-boxD">Drag fields here</div>  
                                    <div *ngIf="Xaxis.length >= 1 && reportType!=6" class="example-box3">Drag more fields here</div> 
                                </div>
                            </div>
                        </div>
    
                        <div *ngIf="isReportTypeSelected && reportType == 6">
                            <div  style="width: 354px; ">
                                <span style=" text-align: left; color:black">Column
                                    <span style="color: red; display: inline;">*</span></span>
                            </div>
                        </div>
                        <div style="width: 100%;"> 
                            <div
                                cdkDropList
                                #ColumnaxisList="cdkDropList"
                                [cdkDropListData]="Columnaxis"
                                [cdkDropListConnectedTo]="[todoList]"
                                class="example-listCol"
                                (cdkDropListDropped)="dropC($event)">
                                <div *ngIf="isReportTypeSelected && reportType == 6">
                                    <div class="example-box"  *ngFor="let item of Columnaxis" cdkDrag> 
                                        <td style="padding-left: 10px; width: 20px;"><i style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-align-justify"></i></td>
                                        <td style="padding-left: 10px; width: 1000px;"> {{item.label}}  </td>
                                        <td style="width: 70px;"><i (click)="OpenColEditField(item,Columnaxis)" style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-angle-double-right"></i></td></div>
                                    <div *ngIf="Columnaxis.length == 0" class="example-boxD">Drag fields here</div>  
                                    <div *ngIf="Columnaxis.length >= 1 && reportType!=6" class="example-box3">Drag more fields here</div> 
                                </div>  
                            </div>
                        </div>    
                         
            
                        <div *ngIf="isReportTypeSelected">
                        <div *ngIf="reportType == 1 || reportType == 2 || reportType == 4" style="width: 354px; ">
                            <span style="text-align: left; color:black">Y axis
                                <span class="mandatory-marker">*</span></span>
                        </div>
                        <div *ngIf="reportType == 3 || reportType == 5 || reportType == 6" style="width: 354px; ">
                            <span style="text-align: left; color:black">Measure
                                <span class="mandatory-marker">*</span></span>
                        </div>
                        </div>
                        <div style="width: 100%;">
                            <div
                                cdkDropList
                                #YaxisList="cdkDropList"
                                [cdkDropListData]="Yaxis"
                                [cdkDropListConnectedTo]="[todoList]"
                                class="example-list"
                                (cdkDropListDropped)="dropY($event)">
                                <div *ngIf="isReportTypeSelected">
                                    <div class="example-box"  *ngFor="let item of Yaxis" cdkDrag> 
                                        <td style="padding-left: 10px; width: 20px;">
                                            <i style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-align-justify"></i></td>
                                        <td style="padding-left: 10px; width: 1000px;"> {{item.label}}  </td>
                                        <td style="width: 70px;"><i (click)="OpenYEditField(item,Yaxis)" style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-angle-double-right"></i></td>
                                    </div>
                                    <div *ngIf="Yaxis.length == 0" class="example-boxD">Drag fields here</div>  
                                    <div *ngIf="Yaxis.length >= 1 && reportType!=3 && reportType!=6" class="example-box3">Drag more fields here</div> 
                                </div>  
                            </div>
                        </div>    
                        <div  *ngIf="isReportTypeSelected" style="width: 354px; padding-top: 10px;">
                            <span style="text-align: left; color:black">Filters
                            </span>
                        </div>
                        <div style="width: 100%; ">
                            <div
                                cdkDropList
                                #filterList="cdkDropList"
                                [cdkDropListData]="filter"
                                [cdkDropListConnectedTo]="[todoList]"
                                class="example-list"
                                (cdkDropListDropped)="dropFilter($event)">
                                <div *ngIf="isReportTypeSelected">
                                    <div class="example-box"  *ngFor="let item of filter" cdkDrag> 
                                        <td style="padding-left: 10px; width: 20px;"><i style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-align-justify"></i></td>
                                        <td style="padding-left: 10px; width: 1000px;"> {{item.entityFieldName}}  </td>
                                        <td style="width: 70px;"><i (click)="OpenFilterField(item,filter)" style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-angle-double-right"></i></td></div>
                                    <div *ngIf="!filter || filter.length == 0" class="example-boxD">Drag fields here</div>  
                                    <div *ngIf="filter && filter.length >= 1" class="example-box3">Drag more fields here</div>   
                                </div>
                            </div>
                        </div>    
                        <div  *ngIf="isReportTypeSelected" style="width: 354px; padding-top: 10px;">
                            <span style="text-align: left; color:black">User Filter
                            </span>
                        </div>
                        <div style="width: 100%; ">
                            <div
                                cdkDropList
                                #userfilterList="cdkDropList"
                                [cdkDropListData]="userfilter"
                                [cdkDropListConnectedTo]="[todoList]"
                                class="example-list"
                                (cdkDropListDropped)="dropuserFilter($event)">
                                <div *ngIf="isReportTypeSelected">
                                    <div class="example-box"  *ngFor="let item of userfilter" cdkDrag> 
                                        <td style="padding-left: 10px; width: 20px;"><i style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-align-justify"></i></td>
                                        <td style="padding-left: 10px; width: 1000px;"> {{item.entityFieldName}}  </td>
                                        <td style="width: 70px;"><i (click)="OpenEditField(item,userfilter,'User Filter')" style="color:rgb(54, 153, 255); justify-content: right;" class="fa fas fa-angle-double-right"></i></td></div>
                                    <div *ngIf="userfilter.length == 0" class="example-boxD">Drag fields here</div>  
                                    <div *ngIf="userfilter.length >= 1" class="example-box3">Drag more fields here</div>   
                                </div>
                            </div>
                        </div>
                    
                        <div *ngIf="isReportTypeSelected" style="width: 100%;  padding-left: 5px; padding-top: 10px;">
                           <button type="button" class="btn btn-primary" style="width: 100%; height: 41px;" (click)="Preview(true)">Show Preview</button>
                        </div>
                   
                </div>
            </div>
          
            <div class="col-sm-6 " style="padding-right: 20px;">
                <!-- <table>            
                    <td class="report-header">
                        <span style=" text-align: left; color: rgb(54, 153, 255); ">Preview</span>
                    </td>
                    <td class="col-md-3 text-right" style="padding-top: 8px;">
                        <button type="button" class="btn btn-primary" (click)="Save('update')">Save</button>
                        <button type="button" class="btn btn-primary" (click)="Save()">Save As</button>
                    </td> 
                </table>
                         -->
                <div style='display: flex; justify-content: space-between; align-items: center;'>
                    <span style=" text-align: left; color: rgb(54, 153, 255); ">Preview</span>
                    <div class="text-right" style="padding-top: 8px;">
                        <button *ngIf="showSave"  type="button" class="btn btn-primary" style="margin-right:10px; " (click)="Save()">Save As Copy</button>
                        <button *ngIf="isSharedWithOthers && showSave && showSaveButton" type="button" class="btn btn-primary" (click)="Save('update')">Save</button>
                    </div>
                </div>
                <hr style="border: 1px solid rgba(63, 66, 84, 0.4);">
                <div *ngIf="(this.reportType != null || this.reportType!='' ) && showPreview" id="previewDiv">  
                    <div *ngIf="this.reportType == 1" style="overflow-x: auto; width: 600px;">                
                        <div
                            id="chart1"
                            style="flex: 1 1 auto; height: 400px; width:750px; "[ngStyle]="{'width':rowData?.length>9?((rowData.length)*10.5)+'%':'100%'}  "
                            ></div>  </div>
                    <div *ngIf="this.reportType == 2" style="overflow-y: auto; width: 600px;height: 400px;"> 
                        <div
                            id="chart2"
                            style="flex: 1 1 auto; width:750px; 
                            "[ngStyle]="{'height': rowData.length > 9 ? ((rowData.length * 10.5) + '%') : '100%'}"
                        ></div>  
                    </div>
                    <div *ngIf="this.reportType == 3" style="overflow-x: auto; width: 100%;" > 
                        <div
                            id="chart3"
                            style="flex: 1 1 auto; height: 400px; min-width: 100%;" [ngStyle]="{'width': rowData ? (((rowData.length/8)*100)+300)+'px' : '600px'}"
                        ></div>  
                    </div>
                    <div *ngIf="this.reportType == 4" style="overflow-x: auto; width: 600px;"> 
                        <div
                            id="chart4"
                            style="flex: 1 1 auto; height: 400px; width:750px;"
                            [ngStyle]="{'width':rowData.length>9?((rowData.length)*10.5)+'%':'100%'}"
                        ></div>  
                    </div>
    
                    <div *ngIf="showDataCheckbox" class="p-2">
                        <mat-checkbox #checkbox  formControlName="dataTable" [(ngModel)]="dataTable" checked (change)="changeDataTableDisplay(dataTable)"></mat-checkbox>
                        &nbsp; Show data table  
                        <mat-checkbox *ngIf="this.reportType==3" style="margin-left: 10px;" #checkbox2 formControlName="labelData" [(ngModel)]="showLabel" checked (change)="changeLabelDlisplay(showLabel)">
                            Show label
                        </mat-checkbox>
                        &nbsp; 
                    </div>
                    <div *ngIf="!isLoading">
                    <div *ngIf="this.reportType != 6"
                        [style.display]="displayDataTable ? 'block' : 'none'">
                       <!-- <ag-grid-angular
                            #agGrid
                            style="width: 100%; height: 400px;"
                            class="ag-theme-alpine"
                            [defaultExcelExportParams]="defaultExcelExportParams"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            [rowData]="rowData"
                            [enableRangeSelection]="true"
                            [enableCharts]="true"
                            [popupParent]="popupParent"
                            [getChartToolbarItems]="getChartToolbarItems"
                            [groupDefaultExpanded]="groupDefaultExpanded"
                            ></ag-grid-angular> -->
                            <ag-grid-angular
                            #agGrid
                            style="width: 100%; height: 400px;"
                            id="myGrid"
                            class="ag-theme-alpine"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            [rowData]="rowData"
                            [enableRangeSelection]="true"
                            [enableCharts]="true"
                            [popupParent]="popupParent"
                            [getChartToolbarItems]="getChartToolbarItems"
                            [groupDefaultExpanded]="groupDefaultExpanded"
                            (gridReady)="onGridReady($event)"
                            (rowDataChanged)="onRowDataChanged($event)"
                            ></ag-grid-angular>
                    </div> 
                    <div *ngIf="!displayDataTable && this.reportType == 6" style="overflow-x: auto;">
                        <ag-grid-angular
                            #agGrid
                            [ngStyle]="{'width': columnDefs ? matrixTableWidth+'px' : '100%' , 'height': '400px'}"     
                            id="myGrid"
                            class="ag-theme-alpine"
                            [columnDefs]="columnDefs"
                            [defaultColDef]="defaultColDef"
                            [rowData]="rowData"
                            [enableRangeSelection]="true"
                            [enableCharts]="true"
                            [pinnedTopRowData]="pinnedBar"
                            [popupParent]="popupParent"
                            [gridOptions]="gridOptions"
                            [getChartToolbarItems]="getChartToolbarItems"
                            [groupDefaultExpanded]="groupDefaultExpanded"
                            (gridReady)="onGridReady($event)"
                            (rowDataChanged)="onRowDataChanged($event)"
                            ></ag-grid-angular>
                    </div> 
                </div>
                </div>   
            </div>
           
        </div>
    </form>
    </div>
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Fetching Data... </p>  
    </ngx-spinner>
    <ngx-spinner name="saving" bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
        <p style="color: white">Saving Data... </p>  
    </ngx-spinner>
    
    
    