<kt-portlet>
    <kt-portlet-body>
        <mat-tab-group #tabGroup [(selectedIndex)]="selectedTab">
            <mat-tab label="Project Settings"> <!-- changed to Project Settings from Localization-->
                <ng-template matTabContent>
                    <kt-localization [dynamicName]="dynamicName"></kt-localization>
                </ng-template>
            </mat-tab>

            <mat-tab label="{{customerTabLabel}} Settings">
                <ng-template matTabContent>
                    <kt-customer-setting
                        (updateCustomerTabLabel)="updateCustomerTabLabel($event)"></kt-customer-setting>
                </ng-template>
            </mat-tab>

            <mat-tab label="{{opportunityTabLabel}} Settings">
                <ng-template matTabContent>
                    <kt-opportunity-setting
                        (updateOpportunityTabLabel)="updateOpportunityTabLabel($event)"></kt-opportunity-setting>
                </ng-template>
            </mat-tab>

            <mat-tab label="{{contactTabLabel}} Settings">
                <ng-template matTabContent>
                    <kt-contact-setting (updateContactTabLabel)="updateContactTabLabel($event)"></kt-contact-setting>
                </ng-template>
            </mat-tab>

            <mat-tab label="Support Settings">
                <ng-template matTabContent>
                    <kt-support-setting></kt-support-setting>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>