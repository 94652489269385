import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'kt-add-dashboard-reports',
  templateUrl: './add-dashboard-reports.component.html',
  styleUrls: ['./add-dashboard-reports.component.scss']
})
export class AddDashboardReportsComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  columnDefs: any;
  defaultColDef: any;
  domLayout: any;
  gridOptions: any;
  public rowData;
  gridApi: any;
  selectedValue: any;
  gridColumnApi:any;
  paginationPageSize: any;
  rowSelection:any;
  noRowsTemplate:any;
  searchValue:any;
  dashBoardDetails:{dashboardName:string, shareType:number, sharedIds:any, reportIds:any, projectId:number};
  dashboardId:any='';
  disabled:boolean;
  errorMes:String="";
  editFlag=false;
  isDisabled=false;

  constructor(  private _ds:DashboardService,
    public activeModal: NgbActiveModal, 
    public snackBar: MatSnackBar,
    private SpinnerService: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getReportList();
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'reportName',    
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Description',
        field: 'reportDesc',    
        menuTabs: ['filterMenuTab']
      },
      {
        headerName: 'Created By',
        field: 'createdByUser',    
        menuTabs: ['filterMenuTab']
      },
      {
      headerName: 'Select',
      // field: 'select',
      minWidth: 150,
      checkboxSelection:true,
      },
      ]
    this.rowSelection = 'multiple';
    this.defaultColDef = {
      sortable: true,
      resizable: false,
      editable: false,
      filter: true,
      flex: 1,
      minWidth: 150
    };
  
    this.domLayout = 'autoHeight';
    this.selectedValue='10';

    this.paginationPageSize = 10;
  }
  

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.getProductList(0);
  }

  Close(){
    this.activeModal.close();
  }
  quickSearch(){
    this.gridApi.setQuickFilter(this.searchValue);
  }

  getReportList(){

    this._ds.getDashBoardReport(this.selectedProject,this.dashboardId).subscribe(
      data=>{

        if (!data || !data['responseData'].length) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.noRowsTemplate = '<span>There are no records.</span>';
          this.gridOptions.api.showNoRowsOverlay();
        }
        
        this.rowData=data['responseData'];
        // console.log(this.rowData);
      },
      err=>{
        //this.errorText=true;
      }  
    )
 
  } 

  getDashBoardReportData(){
    this.dashBoardDetails.projectId=this.selectedProject;
    this.dashBoardDetails.reportIds= this.gridApi.getSelectedRows().map(report=>report.id)
}

  save(){
    this.isDisabled=true;
    if(this.gridApi.getSelectedRows().length==0){
      this.snackBar.open('Select atleast one report to continue.', '', {
        duration: 3000,
        panelClass: ['black-snackbar']
      });
      this.isDisabled=false;
      return;

    }
   
    // console.log("DashBoardReportData",this.dashBoardDetails);
    if(this.editFlag){
      this.activeModal.close(this.gridApi.getSelectedRows().map(report=>report.id))
      this.isDisabled=false;
      return;
    }
    this.SpinnerService.show('adddashBoardReport');
    this.getDashBoardReportData();
    this._ds.addDashboard(this.dashBoardDetails).subscribe(
      data => {
        this.isDisabled=false;
        // const res = data['responseData'];
        // console.log(data);
        this.activeModal.close(data['responseData'].dashboardId);
        this.disabled=false;
        this.SpinnerService.hide('adddashBoardReport');
        this.snackBar.open('Dashboard created successfully.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
      },
      error => {
        this.isDisabled=false;
        // console.log('error occured',error);
        this.snackBar.open('Failed.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        this.SpinnerService.hide('adddashBoardReport');
        this.errorMes=error.error.message;
        this.disabled=false;
      }

    );

  }

}
