import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[ktAutoTrimSpaces]'
})
export class AutoTrimSpacesDirective {

  inputValue: string;
  //private specialKeys: Array<String> = ['Backspace', 'ArrowLeft','ArrowRight'];
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', '-', 'ArrowLeft', 'ArrowRight', 'Del', 'Delete'];


  constructor(private elementRef: ElementRef) { }

  @HostListener('keydown',['$event'])onkeyDown(event: KeyboardEvent){
    if(this.specialKeys.indexOf(event.key) !== -1){
      return;
    }
    const substring = "\\n";
    const substring2 = "\\r";
   // console.log(this.elementRef.nativeElement.value);
    if(this.elementRef.nativeElement.value =="" || this.elementRef.nativeElement.value == null){
      if (event.keyCode === 32) {
        event.preventDefault();
      }
    }else{
      this.inputValue= this.elementRef.nativeElement.value.concat(event.key);
    
    this.inputValue=this.inputValue.toString();

    if(this.inputValue.includes(substring)){
      event.preventDefault();
    }
    if(this.inputValue.includes(substring2)){
      event.preventDefault();
    }
   }
    return;
  }

  @HostListener('paste',['$event']) onPaste(event){
    const clipboardData= (event.originalEvent || event).clipboardData.getData('text/plain');
    const substring = "\\n";
    const substring2 = "\\r";
    if(clipboardData){
    this.inputValue=clipboardData.toString();
      console.log(clipboardData);
      if(this.inputValue.includes(substring)){
        event.preventDefault();
      }
      if(this.inputValue.includes(substring2)){
        event.preventDefault();
      }
    }
    return;
  }
}
