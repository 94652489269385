<div *ngIf="formGroupFlag">
    <form [formGroup]="fieldAccessForm" autocomplete="off">
        <div class="modal-header">
            <h4 class="modal-title">Access</h4>
            <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div *ngFor="let role of userRoles" class="form-group row mt-4">
                <div class="col-md-4">
                    <label class="col-form-label">{{ role.roleName }}</label>
                </div>
                <div class="col-md-8">
                    <mat-radio-group [formControlName]="role.roleName">
                        <mat-radio-button color="primary" value=1
                            (change)="onRadioButtonChange($event.value, role.roleId, role.roleName)">Hidden</mat-radio-button>
                        <mat-radio-button color="primary" value=2
                            (change)="onRadioButtonChange($event.value, role.roleId, role.roleName)">View Only</mat-radio-button>
                        <mat-radio-button color="primary" value=3
                            (change)="onRadioButtonChange($event.value, role.roleId, role.roleName)">Allow Edit</mat-radio-button>
                    </mat-radio-group>
                </div>

                <div id="div_{{role.roleName+customAttr.combinedAttributeName}}_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentId !==0 && customAttr.parentId !==null}"
                    class="col-md-12 row" *ngFor="let customAttr of customAttributesList[role.roleName]">

                    <div *ngIf="!isLinkedField && fieldAccessForm.get(role.roleName).value != 3" class="col-md-4">
                        <label *ngIf="customAttr.parentId !==0 && customAttr.parentId !==null; else altFieldName" class="col-form-label form-padding-top-25">{{customAttr.attributeName}}</label>
                        <ng-template #altFieldName><label class="col-form-label form-padding-top-25">Default Value <span class="mandatory-marker">{{customAttr.mandatoryFlag === 1 ? '*' : ''}}</span></label></ng-template>
                    </div>

                    <div *ngIf="!isLinkedField && fieldAccessForm.get(role.roleName).value != 3" class="col-md-8">
                        <!-- Single Select Dropdown -->
                        <div id="select_{{customAttr.id}}"
                            *ngIf="customAttr.attributeType.id==1 && customAttr.systemAttribute?.id!=16 && customAttr.systemAttribute?.id!=15 && customAttr.systemAttribute?.id!=21">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-select [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    (selectionChange)="invokeChildMethod($event.value, customAttr, role.roleId, role.roleName)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                                            #singleDropdownFilter></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'"
                                        [value]="values.id">
                                        {{values.lookupValue}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">Please
                                    select one.</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Multi Select Dropdown -->
                        <div id="select_{{customAttr.id}}" *ngIf="customAttr.attributeType.id==2">
                            <mat-form-field class="mat-form-field-fluid">
                                <mat-select #select1 [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    multiple
                                    (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value, customAttr.id, role.roleId, role.roleName) : ''">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                                            #multiDropdownFilter></ngx-mat-select-search>
                                    </mat-option>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="customAttr.allSelected"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(select1,customAttr)">Select All
                                        </mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick(select1, customAttr)"
                                        *ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'"
                                        [value]="values.id">
                                        {{values.lookupValue}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Date -->
                        <div  *ngIf="customAttr.attributeType.id==3 && DisplayDateMaxFlag && !DisplayDateMinFlag && !DisplayDateFlag">
                            <mat-form-field class="example-full-width">
                                <input matInput readonly [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="customAttr.attributeType.id==3 && DisplayDateMinFlag && !DisplayDateMaxFlag && !DisplayDateFlag">
                            <mat-form-field class="example-full-width">
                                <input matInput readonly [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    [min]="customAttr.dateAttributes.minDate" [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="customAttr.attributeType.id==3 && DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag ">
                            <mat-form-field class="example-full-width">
                                <input matInput readonly [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    [min]="customAttr.dateAttributes.minDate" [max]="customAttr.dateAttributes.maxDate"
                                    [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>
                        <div *ngIf="customAttr.attributeType.id==3 && !DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag">
                            <mat-form-field class="example-full-width">
                                <input matInput readonly [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    [matDatepicker]="picker">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Image -->
                        <div class="mt-3 mb-1 pb-12" *ngIf="customAttr.attributeType.id==4">
                            <div class="mat-form-field">
                                <div class="mat-form-field-wrapper">
                                    <input id="{{role.roleName + customAttr.combinedAttributeName}}" type="file" style="display:none;"
                                        multiple="" ktAutoTrimSpaces
                                        (change)="processFile($event, customAttr.combinedAttributeName, customAttr.attributeType.id, customAttr.maxImagesAllowed, role.roleName)">

                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName]">
                                        <span
                                            *ngFor="let file of fileArray[role.roleName+customAttr.combinedAttributeName]?.files; index as i">

                                            <div class="artist-collection-photo">
                                                <div class="remove-img-btn"><button
                                                        class="closeImageButton close removeImgButton" type="button"
                                                        (click)="removeFile(role.roleName+customAttr.combinedAttributeName, i)">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                <img width="90" height="100" [src]="baseUrl + file" alt="image.jpg"
                                                    class="img-thumbnail" (click)="showImageModalOnly(file)">
                                                <div *ngIf="customAttr.maxImagesAllowed" class="img-index">
                                                    {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                                <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0">
                                                    {{i+1}} </div>
                                            </div>

                                        </span>
                                    </span>
                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].showUploadIcon">
                                        <div class="artist-collection-photo">
                                            <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg"
                                                alt="image.jpg" class="img-thumbnail"
                                                (click)="clickImage(role.roleName+customAttr.combinedAttributeName)">
                                            <div *ngIf="customAttr.maxImagesAllowed && fileArray[role.roleName+customAttr.combinedAttributeName].uploaded == 0"
                                                class="img-index">
                                                {{"0"}}/{{customAttr.maxImagesAllowed}}
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="mat-form-field-underline ng-star-inserted">
                                <span class="mat-form-field-ripple"></span>
                            </div>
                            <div class="mat-form-field-subscript-wrapper">
                                <ng-container
                                    *ngIf="!isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required') && !fileArray[role.roleName+customAttr.combinedAttributeName].hasError">
                                    <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;">
                                        {{ customAttr.mandatoryFlag ? customAttr.instructions : (!customAttr.instructions ? '' : customAttr.instructions) }}
                                      </mat-hint>
                                </ng-container>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    can not be blank</mat-error>
                                <mat-error *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].hasError"
                                    style="font-size: 100% !important; padding-top: 0px !important;">{{fileArray[role.roleName+customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            </div>

                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise"
                                name={{role.roleName+customAttr.combinedAttributeName}}></ngx-spinner>

                        </div>

                        <!-- Radio -->
                        <div *ngIf="customAttr.attributeType.id==5">
                            <mat-radio-group [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                fxLayout="column" fxLayoutGap=".25rem">
                                <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                                    {{values.lookupValue}}
                                </mat-radio-button>
                            </mat-radio-group>
                            <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                            <mat-hint
                                *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                            <mat-error
                                *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                cannot be blank</mat-error>
                        </div>

                        <!-- Text (single-line) -->
                        <div *ngIf="customAttr.attributeType.id == 6 || customAttr.attributeType.id == 20">
                            <mat-form-field class="mat-form-field-fluid">
                                <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}"
                                    #message matInput [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    ktAutoTrimSpaces>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon>
                                <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} /
                                    {{customAttr.maxCharacters}}</mat-hint>
                            </mat-form-field>
                        </div>

                        <!-- Numeric -->
                        <div *ngIf="customAttr.attributeType.id==7">
                            <div *ngIf="customAttr.numericValidationType==1">
                                <div *ngIf="customAttr.maxDigits!=null">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input
                                            maxlength="{{customAttr.decimalFlag?customAttr.maxDigits+3:customAttr.maxDigits}}"
                                            #message matInput placeholder=""
                                            [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                            [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                        <mat-hint
                                            *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                        <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon>
                                        <mat-hint
                                            align="end">{{customAttr.decimalFlag?getDecimalFieldLength(message.value):message.value.length}}
                                            / {{customAttr.maxDigits}}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="customAttr.maxDigits==null">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input #message matInput placeholder=""
                                            [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                            [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                        <mat-hint
                                            *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                        <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon>
                                        <mat-hint align="end">{{message.value.length}} </mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div *ngIf="customAttr.numericValidationType==2">
                                <div *ngIf="customAttr.maxNumber!=null">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input maxlength="{{customAttr.maxNumber.toString().length}}" #message2 matInput placeholder=""
                                            [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                            [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                        <mat-hint
                                            *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                         <!-- <mat-error *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                        <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon>
                                        <mat-hint align="end">{{message2.value.length}} / {{customAttr.maxNumber.toString().length}}</mat-hint>
                                    </mat-form-field>
                                </div>
                                <div *ngIf="customAttr.maxNumber == null">
                                    <mat-form-field class="mat-form-field-fluid">
                                        <input #message2 matInput placeholder=""
                                            [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                            [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                        <mat-hint
                                            *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                         <!-- <mat-error *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                        <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon>
                                        <mat-hint align="end">{{message2.value.length}}</mat-hint>
                                    </mat-form-field>
                                </div>

                            </div>
                            <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                                <mat-form-field class="mat-form-field-fluid">
                                    <input #message2 matInput placeholder=""
                                        [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                        [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                                    <mat-hint *ngIf="customAttr.mandatoryFlag">Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>
                                    <mat-hint
                                        *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                     <!-- <mat-error *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                                    <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon>
                                    <mat-hint align="end">{{message2.value.length}}</mat-hint>
                                </mat-form-field>
                            </div>
                        </div>

                        <!-- Text (multi-line) -->
                        <div class="mb-2" *ngIf="customAttr.attributeType.id==8">
                            <mat-form-field appearance="standard" class="input-100">
                                <textarea maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}"
                                    #message matInput placeholder=""
                                    [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    ktAutoTrimSpaces></textarea>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                                <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon>
                                <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} /
                                    {{customAttr.maxCharacters}}</mat-hint>
                            </mat-form-field>
                        </div>

                        <!-- Email -->
                        <div *ngIf="customAttr.attributeType.id==9">
                            <mat-form-field class="mat-form-field-fluid">
                                <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}"
                                    #message matInput placeholder=""
                                    [formControlName]="role.roleName+customAttr.combinedAttributeName" ktAutoTrimSpaces>
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'email')">Invalid
                                    email format</mat-error>
                                <mat-icon matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon>
                                <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} /
                                    {{customAttr.maxCharacters}}</mat-hint>
                            </mat-form-field>
                        </div>

                        <!-- Document Upload -->
                        <div class="mt-3 mb-1 pb-12" *ngIf="customAttr.attributeType.id==15">
                            <div class="mat-form-field">
                                <div class="mat-form-field-wrapper">
                                    <input id="{{role.roleName + customAttr.combinedAttributeName}}" type="file" style="display:none;"
                                        multiple="" ktAutoTrimSpaces
                                        (change)="processFile($event, customAttr.combinedAttributeName, customAttr.attributeType.id, customAttr.maxImagesAllowed, role.roleName)">

                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName]">
                                        <span
                                            *ngFor="let icon of fileArray[role.roleName+customAttr.combinedAttributeName]?.documentIcons; index as i">

                                            <div class="artist-collection-photo">
                                                <div class="remove-img-btn"><button
                                                        class="closeImageButton close removeImgButton" type="button"
                                                        (click)="removeFile(role.roleName+customAttr.combinedAttributeName, i)">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                <a href="{{baseUrl + fileArray[role.roleName+customAttr.combinedAttributeName]?.files[i]}}"
                                                    target="_blank">
                                                    <img width="90" height="100" [src]="icon" alt="image.jpg"
                                                        class="img-thumbnail">
                                                </a>
                                                <div *ngIf="customAttr.maxImagesAllowed" class="img-index">
                                                    {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                                <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0">
                                                    {{i+1}} </div>
                                            </div>

                                        </span>
                                    </span>
                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].showUploadIcon">
                                        <div class="artist-collection-photo">
                                            <img width="90" height="100"
                                                src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg"
                                                class="img-thumbnail" (click)="clickImage(role.roleName+customAttr.combinedAttributeName)">
                                            <div *ngIf="customAttr.maxImagesAllowed && fileArray[role.roleName+customAttr.combinedAttributeName].uploaded == 0"
                                                class="img-index">
                                                {{"0"}}/{{customAttr.maxImagesAllowed}}
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="mat-form-field-underline ng-star-inserted">
                                <span class="mat-form-field-ripple"></span>
                            </div>
                            <div class="mat-form-field-subscript-wrapper">
                                <ng-container
                                    *ngIf="!isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required') && !fileArray[role.roleName+customAttr.combinedAttributeName].hasError">
                                    <mat-hint class="mat-hint_img" *ngIf="customAttr.mandatoryFlag"
                                        style="font-size: 100% !important; padding-top: 0px !important;">{{customAttr.instructions}}</mat-hint>
                                    <mat-hint class="mat-hint_img"
                                        *ngIf="!customAttr.mandatoryFlag && customAttr.instructions"
                                        style="font-size: 100% !important; padding-top: 0px !important;">
                                        {{customAttr.instructions}}</mat-hint>
                                    <mat-hint class="mat-hint_img"
                                        *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions"
                                        style="font-size: 100% !important; padding-top: 0px !important;">
                                    </mat-hint>
                                </ng-container>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    can not be blank</mat-error>
                                <mat-error *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].hasError"
                                    style="font-size: 100% !important; padding-top: 0px !important;">{{fileArray[role.roleName+customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            </div>

                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise"
                                name={{role.roleName+customAttr.combinedAttributeName}}></ngx-spinner>
                        </div>

                        <!-- Audio/Video -->
                        <div class="mt-3 mb-1 pb-12" *ngIf="customAttr.attributeType.id==16">
                            <div class="mat-form-field">
                                <div class="mat-form-field-wrapper">
                                    <input id="{{role.roleName + customAttr.combinedAttributeName}}" type="file" style="display:none;"
                                        multiple="" ktAutoTrimSpaces
                                        (change)="processFile($event, customAttr.combinedAttributeName, customAttr.attributeType.id, customAttr.maxImagesAllowed, role.roleName)">

                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName]">
                                        <span
                                            *ngFor="let file of fileArray[role.roleName+customAttr.combinedAttributeName]?.files; index as i">

                                            <div class="artist-collection-photo">
                                                <div class="remove-img-btn"><button
                                                        class="closeImageButton close removeImgButton" type="button"
                                                        (click)="removeFile(role.roleName+customAttr.combinedAttributeName, i)">
                                                        <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                    </button></div>
                                                <a href="{{baseUrl + fileArray[role.roleName+customAttr.combinedAttributeName]?.files[i]}}"
                                                    target="_blank">
                                                    <img width="90" height="100"
                                                        src="./assets/media/svg/icons/General/file-alt-solid.svg"
                                                        alt="image.jpg" class="img-thumbnail">
                                                </a>
                                                <div *ngIf="customAttr.maxImagesAllowed" class="img-index">
                                                    {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                                <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0">
                                                    {{i+1}} </div>
                                            </div>
                                        </span>
                                    </span>
                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].showUploadIcon">
                                        <div class="artist-collection-photo">
                                            <img width="90" height="100"
                                                src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg"
                                                class="img-thumbnail" (click)="clickImage(role.roleName+customAttr.combinedAttributeName)">
                                            <div *ngIf="customAttr.maxImagesAllowed && fileArray[role.roleName+customAttr.combinedAttributeName].uploaded == 0"
                                                class="img-index">
                                                {{"0"}}/{{customAttr.maxImagesAllowed}}
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <div class="mat-form-field-underline ng-star-inserted">
                                <span class="mat-form-field-ripple"></span>
                            </div>
                            <div class="mat-form-field-subscript-wrapper">
                                <ng-container
                                    *ngIf="!isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required') && !fileArray[role.roleName+customAttr.combinedAttributeName].hasError">
                                    <mat-hint class="mat-hint_img" *ngIf="customAttr.mandatoryFlag"
                                        style="font-size: 100% !important; padding-top: 0px !important;">{{customAttr.instructions}}</mat-hint>
                                    <mat-hint class="mat-hint_img"
                                        *ngIf="!customAttr.mandatoryFlag && customAttr.instructions"
                                        style="font-size: 100% !important; padding-top: 0px !important;">
                                        {{customAttr.instructions}}</mat-hint>
                                    <mat-hint class="mat-hint_img"
                                        *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions"
                                        style="font-size: 100% !important; padding-top: 0px !important;">
                                    </mat-hint>
                                </ng-container>

                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    can not be blank</mat-error>
                                <mat-error *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName].hasError"
                                    style="font-size: 100% !important; padding-top: 0px !important;">{{fileArray[role.roleName+customAttr.combinedAttributeName].errorMsg}}</mat-error>
                            </div>

                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise"
                                name={{role.roleName+customAttr.combinedAttributeName}}></ngx-spinner>
                        </div>

                        <!-- Reference Documents -->
                        <div class="mt-3 mb-1 pb-12" *ngIf="customAttr.attributeType.id==17">
                            <div class="mat-form-field">
                                <div class="mat-form-field-wrapper">
                                    <span *ngIf="fileArray[role.roleName+customAttr.combinedAttributeName]">
                                        <span
                                            *ngFor="let icon of fileArray[role.roleName+customAttr.combinedAttributeName]?.documentIcons; index as i">
                                            <div class="artist-collection-photo">
                                                <img width="90" height="100" [src]="icon" alt="image.jpg" class="img-thumbnail"
                                                    (click)="showRefDocModal(customAttr.combinedAttributeName, i)">
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="mat-form-field-subscript-wrapper">
                                <mat-hint class="mat-hint_img" *ngIf="customAttr.instructions"
                                    style="font-size: 100% !important; padding-top: 0px !important;">{{customAttr.instructions}}</mat-hint>
                                <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions"
                                    style="font-size: 100% !important; padding-top: 0px !important;">
                                </mat-hint>
                            </div>
                        </div>

                        <!-- Data List -->
                        <div id="select_{{customAttr.id}}" *ngIf="customAttr.attributeType.id==18">
                            <mat-form-field appearance="standard" class="input-100">
                                <mat-select [formControlName]="role.roleName+customAttr.combinedAttributeName"
                                    (selectionChange)="invokeChildMethod($event.value, customAttr, role.roleId, role.roleName)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                                            #dataListFieldFilter></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let values of customAttr.lookupValues| search : dataListFieldFilter.value : 'lookupValue'"
                                        [value]="values.id">
                                        {{values.lookupValue}}
                                    </mat-option>
                                </mat-select>

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">Please
                                    select one.</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Data Time field -->
                        <div *ngIf="customAttr.attributeType.id==21">
                            <mat-form-field class="example-full-width">
                                <input matInput type="datetime-local" min="{{customAttr.dateAttributes.minDate}}"
                                    max="{{customAttr.dateAttributes.maxDate}}"
                                    [formControlName]="role.roleName+customAttr.combinedAttributeName" placeholder=""
                                    onkeydown="return false;">
                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- Time Duration Field -->
                        <div *ngIf="customAttr.attributeType.id==22">
                            <mat-form-field class="mat-form-field-fluid">
                                <input #message matInput placeholder=""
                                    [formControlName]="role.roleName+customAttr.combinedAttributeName" ktAutoTrimSpaces
                                    type="time" step="1" onkeydown="return false;">

                                <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>
                                <mat-hint
                                    *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}}</mat-hint>
                                <mat-error
                                    *ngIf="isControlHasError(role.roleName+customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                                    cannot be blank</mat-error>
                            </mat-form-field>
                        </div>

                        <!-- System Attributes -->
                        <!-- Country/State/City -->
                        <div *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute?.id==16 || customAttr.systemAttribute?.id==15 || customAttr.systemAttribute?.id==21)">
                            <div class="row">
                                <!-- Country -->
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select [formControlName]="role.roleName+fixedCountryfieldName"
                                            (selectionChange)="changeCountry($event.value, role.roleName)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No options found"
                                                    #countryNameFilter></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'"
                                                [value]="country.countryName+'@'+country.countryId">
                                                {{country.countryName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select country</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select country</mat-hint>
                                        <mat-error
                                            *ngIf="isControlHasError(role.roleName+fixedCountryfieldName, 'required')">Country
                                            cannot be blank</mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- State -->
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select [formControlName]="role.roleName+fixedStatefieldName"
                                            (selectionChange)="changeState($event.value, role.roleName)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No options found"
                                                    #stateNameFilter></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option
                                                *ngFor="let state of stateLookupObj[role.roleName]| search : stateNameFilter.value :'stateName'"
                                                [value]="state.stateName+'@'+state.stateId">
                                                {{state.stateName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error
                                            *ngIf="isControlHasError(role.roleName+fixedCountryfieldName, 'required')">State
                                            cannot be blank</mat-error>
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select state</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select state</mat-hint>
                                    </mat-form-field>
                                </div>
                                <!-- City -->
                                <div class="col-md-4">
                                    <mat-form-field class="mat-form-field-fluid input-100">
                                        <mat-select [formControlName]="role.roleName+fixedCityfieldName">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel="Search"
                                                    noEntriesFoundLabel="No options found"
                                                    #cityNameFilter></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let city of cityLookupObj[role.roleName]| search : cityNameFilter.value :'cityName'"
                                                [value]="city.cityName+'@'+city.cityId">
                                                {{city.cityName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError(role.roleName+fixedCityfieldName, 'required')">City
                                            cannot be blank</mat-error>
                                        <mat-hint *ngIf="customAttr.mandatoryFlag">Select city</mat-hint>
                                        <mat-hint *ngIf="!customAttr.mandatoryFlag">Select city</mat-hint>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" (click)="close()">Cancel</button>
            <button type="button" class="btn btn-primary" [disabled]='disabled' (click)="save()">Save</button>
        </div>
    </form>
</div>
<ngx-spinner size="default" type="ball-spin-clockwise" name="field-access">
    <p>Fetching Data... </p>
</ngx-spinner>