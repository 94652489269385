import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-relation-dialog',
  templateUrl: './edit-relation-dialog.component.html',
  styleUrls: ['./edit-relation-dialog.component.scss']
})
export class EditRelationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
