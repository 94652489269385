<div class="modal-header">
    <h4 class="modal-title">Confirm Login</h4>
</div>

<div class="modal-body">
    <div>You are already logged into another device. If you login in this device, you will be logged out from the other device. Do you want to continue?</div>
</div>

<div class="modal-footer" style="padding: .5rem !important;">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
        No
    </button>
    <button type="button" class="btn btn-primary" (click)="confirmLogin()">
        Yes
    </button>
</div>

