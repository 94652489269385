import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfigurationAssistantService } from '../configuration-assistant/services/configuration-assistant.service';

@Component({
  selector: 'kt-configuration-assistant',
  templateUrl: './configuration-assistant.component.html',
  styleUrls: ['./configuration-assistant.component.scss']
})
export class ConfigurationAssistantComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  assistantList: any;
  viewContent: boolean;
  
  constructor(
    private modalService: NgbModal,
    private _cas:ConfigurationAssistantService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('setting-assistant');
    this.viewContent = false;
    this.getConfigAssistantList(this.selectedProject);
  }

  getConfigAssistantList(projectId: number){
    this._cas.getAllConfigAssistant(projectId).subscribe(
      data => {
        this.assistantList = data['responseData'];
        console.log(this.assistantList);
        this.viewContent = true;
        this.SpinnerService.hide('setting-assistant');
    });
  }

  triggerClick() {}

}
