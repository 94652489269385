import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-confirm-login',
  templateUrl: './confirm-login.component.html'
})
export class ConfirmLoginComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }
  confirmLogin(){
    this.activeModal.close(true);
  }

  closeModal(){
    this.activeModal.close();
  }

}
