import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder,Validators, Form , FormControl, FormArray} from '@angular/forms';
//utilities
import { LayoutUtilsService ,MessageType} from '../../../../../core/_base/crud';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '../../services/localization.service';
import { CustomAttibute } from '../../../contact-setting/models/custom-attibute.model';
import { AttributeType } from '../../../contact-setting/models/attribute-type.model';
import { LooupValues } from '../../../contact-setting/models/looup-values.model';
import { roleModel } from '../../../user-role/models/role.model';
import { UsersList } from '../../../workflows/workflows-details/models/users-list.model';

@Component({
  selector: 'kt-customer-setting',
  templateUrl: './customer-setting.component.html',
  styleUrls: ['./customer-setting.component.scss']
})
export class CustomerSettingComponent implements OnInit {

  selectedProject:number;
  addCompanySettingsForm:FormGroup;
  enforceLiveLocCapture: boolean;
  sortBy;
  resultSet:any;
  disabled:boolean;
  singularName: string;
  pruralName:string;
  prevsingularName: string;
  prevpruralName: string;
  serialKeyUpdated: boolean;
  chooseCompanyOwnerFlag;
  id:number;
  errorMes:string="";
  currentRouteUrl: string;
  entityFieldList: CustomAttibute[] = null;
  lookupList: LooupValues[] = [];
  lookupListsObj = [];
  activeRoles: roleModel[];
  showCustomerAccess: boolean = false;
  @Output() updateCustomerTabLabel = new EventEmitter<string>();
  @Output() saveAndExit = new EventEmitter<boolean>();
  unsavedChanges = false;

  selectedEntityField: number = null;

  constructor(private fb: FormBuilder,
    private layoutUtilsService: LayoutUtilsService,
    private compantSettingService: LocalizationService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private subheaderService: SubheaderService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Customers');
    }

    this.selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    this.getActiveRolesList(+this.selectedProject);
    //this.SpinnerService.show();
    this.addCompanySettingsForm = this.fb.group({
      enforceLiveLocCapture: [''],
      singularName: ['', Validators.compose([ Validators.required])],
      pruralName:['', Validators.compose([ Validators.required])],
      serialKey: ['', Validators.compose([ Validators.required])],
      sortBy:[''],
      chooseCompanyOwnerFlag:['', Validators.compose([ Validators.required])],
      customerLogin : [],
      customerAccess : this.fb.array([])
     });
     this.SpinnerService.show();
     this.getSettingsValues(this.selectedProject);
     this.disabled=false;
  }

  getSettingsValues(selectedProject: number){
    this.SpinnerService.show();
    this.compantSettingService.getCompanySettings(selectedProject).subscribe(
      res=>{
       // console.log(res['responseData']);
        this.resultSet=res['responseData'];
        this.enforceLiveLocCapture=this.resultSet.enforceLocationCapture == 1 ? true : false;
        this.sortBy=this.resultSet.sortBy.toString();
        this.singularName=this.resultSet.singularName;
        this.updateCustomerTabLabel.emit(this.resultSet.singularName);
        this.pruralName=this.resultSet.pruralName;
        this.prevsingularName=this.resultSet.singularName;
        this.prevpruralName=this.resultSet.pruralName;
        this.addCompanySettingsForm.controls.serialKey.setValue(this.resultSet.serialKey);
        this.serialKeyUpdated=this.resultSet.serialKeyUpdatedFlag  == 0 ? false : true;
        if(this.serialKeyUpdated){
          this.addCompanySettingsForm.controls.serialKey.disable();
        } 
        this.chooseCompanyOwnerFlag=this.resultSet.chooseCompanyOwnerFlag.toString();
        this.id=this.resultSet.id;
        this.showCustomerAccess = this.resultSet.customerLoginFlag === 1;
        this.addCompanySettingsForm.controls.customerLogin.setValue(this.showCustomerAccess);
        if(this.resultSet.customerLoginFlag === 1 && this.resultSet.customerLoginAccess && this.resultSet.customerLoginAccess.length>0){
          this.fetchEntityField(2, true);
        }
        this.SpinnerService.hide();
        this.setupFormValueChanges();
      },
      err=>{
        console.log(err);
      }
    )
  }

  setupFormValueChanges(): void {
    this.addCompanySettingsForm.valueChanges.subscribe(() => {
      if (this.addCompanySettingsForm.dirty) {
        this.unsavedChanges=true;
      }
    });
  }

  get customerAccess() :FormArray{return this.addCompanySettingsForm.get("customerAccess")as FormArray}

  addExtraField() {
    this.customerAccess.push(this.fb.group({
      entityField: [{
        value: this.customerAccess.length>0 ? this.selectedEntityField : null, 
        disabled : this.customerAccess.length>0
      }, Validators.compose([Validators.required])],
      entityFieldValue: [null, Validators.compose([Validators.required])],
      role: [null, Validators.compose([Validators.required])]
    }));
    this.lookupListsObj.push({
      options: this.getNewLookupList(this.customerAccess.length-1)
    });
  }

  addExtraFieldWithValue(existingData){
    this.customerAccess.controls = [];
    const attributeId = existingData[0].entityFieldId;
    this.changeEntityField(attributeId, -1);
    this.selectedEntityField = attributeId;
    existingData.forEach((x, i)=>{
      this.customerAccess.push(this.fb.group({
        entityField : [{value: x.entityFieldId, disabled: this.customerAccess.length>0}, Validators.compose([Validators.required])], 
        entityFieldValue : [(x.value)?.split(",").map(x=>+x), Validators.compose([Validators.required])], 
        role : [x.roleId, Validators.compose([Validators.required])]}));
      this.lookupListsObj.push({
        options: this.getNewLookupList(i)
      });
    });
    this.lookupListsObj[0] = {
      options: this.getNewLookupList(0)
    };
  }

  removeExtraField (index) {
    if(this.customerAccess.controls.length > 1){
      this.customerAccess.removeAt(index);
      if(index === 0) {
        this.customerAccess.controls.forEach((x, i) => {
          if(i === 0) {
            x.get("entityField").enable();
          } else {
            x.get("entityField").disable();
          }
        })
      }
      this.lookupListsObj.splice(index, 1);
      this.updateAllLookValues();
    }
  }

  getNewLookupList(idx) {
    let existingVals = [];
    this.customerAccess.controls.forEach((x, i) => {
      if(i===idx) return;
      const selectedVals = x.get("entityFieldValue").value;
      if(selectedVals) {
        existingVals.push(...selectedVals);
      }
    });
    existingVals = existingVals.map(x => +x);
    return this.lookupList.filter(x => !existingVals.includes(x.id));
  }

  updateAllLookValues() {
    for(let i = 0; i<this.customerAccess.controls.length; i++){
      let existingVals = [];
      this.customerAccess.controls.forEach((x, j) => {
        if(i===j) return;
        const selectedVals = x.get("entityFieldValue").value;
        if(selectedVals) {
          existingVals.push(...selectedVals);
        }
      });
      this.lookupListsObj[i].options = this.lookupList.filter(x => !existingVals.includes(x.id));
    }
  }

  fetchEntityField(entityId : number, fillData = false){
    this.SpinnerService.show();
    this.compantSettingService.getFields(+this.selectedProject, entityId).subscribe(res => {
      this.entityFieldList = res['responseData'].filter(result=> result.attributeType.id === 1 && result.systemAttribute?.id!==17 && result.systemAttribute?.id!==16 && result.isActive === 1);
      this.setActiveUsersToOwnerField(+this.selectedProject, fillData);
    })
  }
  
  setActiveUsersToOwnerField(projectId: number, fillData = false){
    this.compantSettingService.fetchActiveUserList(projectId).subscribe(res => {
      const activeUsers = (res['responseData'] as Array<UsersList>).sort((u1, u2) => {
        return (u1.firstName + " " + u1.lastName).toLowerCase().localeCompare((u2.firstName + " " + u2.lastName).toLowerCase());
      });
      const ownerField = this.entityFieldList.find(x => x.systemAttribute?.id === 34);
      ownerField.lookupValues = activeUsers.map(x => {return {
        id: x.userId, lookupValue: x.firstName+" "+x.lastName, isActive: 1, stageId: null
      }});
      if(fillData) {
        this.addExtraFieldWithValue(this.resultSet.customerLoginAccess);
      }
      this.SpinnerService.hide();
    })
  }

  changeEntityField(attributeId: number, idx: number){
    const attribute= this.entityFieldList.find(res=>res.id === attributeId)
    this.lookupList = attribute.lookupValues;

    if(this.lookupListsObj[idx]) {
      this.lookupListsObj[idx].options = this.getNewLookupList(idx);
    }
    this.selectedEntityField = attributeId;
    this.customerAccess.controls.forEach((x) => {
      x.get("entityField").setValue(attributeId);
      x.get("entityFieldValue").reset();
      x.get("entityFieldValue").updateValueAndValidity();
    });
  }

  changeEntityFieldValue() {
    this.updateAllLookValues();
  }


  isCustomerAccessControlHasError(controlName: string, idx: number, validationType: string): boolean {
    const control = this.customerAccess.at(idx).get(controlName);
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addCompanySettingsForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  changeCustomerLogin(e) {

    if(!e.checked) {
      this.showCustomerAccess = false;
      this.customerAccess.controls = [];
      this.lookupList = [];
      this.lookupListsObj = [];
    }
    else {
      this.showCustomerAccess = true;
      this.fetchEntityField(2);
      this.addExtraField();
    }
  }


  Save(calledFromParent:boolean=false){
    this.disabled=true;
    const controls = this.addCompanySettingsForm.controls;
    if (this.addCompanySettingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      const formArray = this.customerAccess;
      for (let i = 0; i < formArray.length; i++) {
        const formGroup = (formArray.at(i) as FormGroup);
        Object.keys(formGroup.controls).forEach(name => {
          formGroup.get(name).markAsTouched();
        });
      }
      this.disabled=false;
      return;
    }
       
    const addSettingsData = {
        "projectId": this.selectedProject,  
        "enforceLocationCapture": controls.enforceLiveLocCapture.value ==true ? 1 :0,
        "singularName":controls.singularName.value.trim(),
        "pruralName":controls.pruralName.value.trim(),
        "id": this.id,
        "sortBy": controls.sortBy.value,
        "chooseCompanyOwnerFlag":controls.chooseCompanyOwnerFlag.value,
        "companyOwnerFlag": 0,
        "geoFencing": 0,
        "tolerance": 0,
        "serialKey":controls.serialKey.value.trim(),
        "customerLoginFlag":controls.customerLogin.value == true ? 1 : 0,
        "customerLoginAccess": this.customerAccess.controls.map(res => {
          return {
            "entityFieldId": res.get("entityField").value,
            "value": (res.get("entityFieldValue").value as Array<Number>).join(","),
            "roleId": res.get("role").value
          }
        })
    };
    this.disabled=false;
   // console.log(addSettingsData);
    
    this.compantSettingService.saveCompanySettings(addSettingsData).subscribe(
      data => {
        if(data['statusCode']>=400){
          this.handleErrorForEditActivity(data['statusCode']);
          return;
         } 
      if(!(this.prevsingularName == this.addCompanySettingsForm.controls.singularName.value) ||
          !(this.prevpruralName == this.addCompanySettingsForm.controls.pruralName.value)){
            this.snackBar.open('Renamed value will appear on your next login', '', {
              duration: 2000,
            });
          }else{
            this.snackBar.open('Settings updated.', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
          }
          
        this.getSettingsValues(this.selectedProject);
        
        if(calledFromParent){
          this.saveAndExit.emit(true);
        }
        this.addCompanySettingsForm.markAsPristine();
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.snackBar.open(this.errorMes, '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
      
    );
    this.unsavedChanges =false;
  }


  Cancel(){
    this.disabled=true;
    this.SpinnerService.show();
    this.getSettingsValues(this.selectedProject);
    this.addCompanySettingsForm.markAsPristine();
    this.unsavedChanges =false;
    this.disabled=false;
  }
  
  isDisabled() : boolean {
    return this.disabled;
   }

   handleErrorForEditActivity(statusCode: number) {
    let message: string = "";
    if(statusCode == 409){
      message = 'Serial No. Key already exist.';
    }
    else {
      message = 'Unable to save, please try again.';
    }
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
  }

  getActiveRolesList(projectId: number) {
    this.compantSettingService.getActiveRoleList(projectId).subscribe(res => {
      this.activeRoles = res['responseData'];
    });
  }
}
