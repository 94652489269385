import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';

@Component({
  selector: 'kt-add-new-dashboard',
  templateUrl: './add-new-dashboard.component.html',
  styleUrls: ['./add-new-dashboard.component.scss']
})
export class AddNewDashboardComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  dashBoardName: string;
  public dashBoardForm: FormGroup;
  errorMes:String="";
  disabled:boolean;
  public shareWith: number = null;
  public isLoadingUsers: boolean = false;
  public isLoadingSharingDetail: boolean = false;
  name: string;
  roles: any = [];
  users: any = [];
  isDisabled=false;
  selectedRoles: any = [];
  selectedUsers: any = [];
  public shareWithDrp = [
    { id:0, value: 'All'},
    { id: 2, value: 'Roles'},
    { id: 3, value: 'Users'}
  ];
  constructor(private fb:FormBuilder,
    public activeModal: NgbActiveModal,
    public _dbs: DashboardService,
    private snackBar:MatSnackBar
   ) { }

  ngOnInit(): void {
    this.initaddDashboardForm();
    this.getRoles();
    this.getUsers();
  }

  initaddDashboardForm(){
    this.dashBoardForm = this.fb.group({
      dashBoardName: ['', Validators.compose([Validators.required,Validators.maxLength(25) ])],
      shareWith:['0'],
      selectRoles:[''],
      selectUsers:[''],

     });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.dashBoardForm.controls[controlName];
      if (!control) {
        return false;
      }

      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  Close(){
    this.activeModal.close();
    this.dashBoardForm.reset();
  }

  getRoles() {
    const projectId = +localStorage.getItem('selectedProject');
    this._dbs.getRoles(projectId).subscribe( data => {
        if (data.statusCode == 200) {
          this.roles = data.responseData;
        }
    });
  }

  getUsers() {
    const projectId = +localStorage.getItem('selectedProject');
    this._dbs.getUsers(projectId).subscribe( data => {
        if (data.statusCode == 200) {
          this.users = data.responseData;
        }
    });
  }

  changeShareWith(item) {
    if(item==2){
      this.selectedRoles = [];
      this.dashBoardForm.controls.selectRoles.setValidators(Validators.required);
      this.dashBoardForm.controls.selectRoles.updateValueAndValidity();
      this.dashBoardForm.controls.selectUsers.clearValidators();
      this.dashBoardForm.controls.selectUsers.updateValueAndValidity();
    }
  else if(item==3){
    this.selectedUsers = [];
    this.dashBoardForm.controls.selectUsers.setValidators(Validators.required);
    this.dashBoardForm.controls.selectUsers.updateValueAndValidity();
    this.dashBoardForm.controls.selectRoles.clearValidators();
    this.dashBoardForm.controls.selectRoles.updateValueAndValidity();
  }
  else{
    this.selectedRoles=[];
    this.selectedUsers=[];
    this.dashBoardForm.controls.selectUsers.clearValidators();
    this.dashBoardForm.controls.selectUsers.updateValueAndValidity();
    this.dashBoardForm.controls.selectRoles.clearValidators();
    this.dashBoardForm.controls.selectRoles.updateValueAndValidity();
  }
    
  }

  addReport(){
    this.isDisabled=true;
    const controls = this.dashBoardForm.controls;
		/** check form */
		if (this.dashBoardForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.isDisabled=false;
      return;
  }
  this._dbs.checkDashboardName(this.selectedProject, controls.dashBoardName.value).subscribe(
    data=>{
      this.isDisabled=false;
      const addReportData = {
        dashboardName: controls.dashBoardName.value,
        shareType: controls.shareWith.value,
        sharedIds: [],
      };
      if( controls.shareWith.value==2){
        addReportData.sharedIds=controls.selectRoles.value;
      }
      if( controls.shareWith.value==3){
        addReportData.sharedIds=controls.selectUsers.value;
      }
      this.activeModal.close(addReportData);
    },
    error=>{
      this.isDisabled=false;
      this.snackBar.open(`A dashboard with the name ${controls.dashBoardName.value} already exist in this project.`, '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    }
  );
  
}
}
