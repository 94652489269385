import { Component, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'kt-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {

  isFromDayPlannerBulkUpload: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

    onDismiss(): void {
      // Close the dialog, return false
      this.dialogRef.close();
    }

    onConfirm(): void {
      // Close the dialog, return true
      this.dialogRef.close(true);
    }

}
