import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EventEmitterService } from '../../shared/services/event-emitter.service';
import { AuthService } from '../../../../core/auth';
import { User } from 'src/app/core/auth';
import { AppIntegrationService } from '../../shared/services/app-integration.service';
import { MenuPermissionService } from '../../shared/services/menu-permission.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'kt-project-selection',
  templateUrl: './project-selection.component.html',
  styleUrls: ['./project-selection.component.scss']
})
export class ProjectSelectionComponent implements OnInit {

  userDetail: User;
  projectSelectionForm: FormGroup;
  projectList:[];
  projectSelectFilter: FormControl = new FormControl();
  disabled:boolean;
  defaultProjSet:boolean;
  userProj: any;
  returnUrl: any;
  menuObj:any = {};
  systemName : any;
  projectId: any;
  formView = false;
  selectionFlag: string;
  defaultMenuFlag = false;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  pluralName: any = {};
  singularName: any = {};
  systemLogo : any;

  constructor(
    private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    private eventEmitterService: EventEmitterService,
    private auth: AuthService,
    private ais:AppIntegrationService,
    private router: Router,
    
    private menuPermission : MenuPermissionService
    ) { }

  ngOnInit(): void {

    this.defaultProjSet = false;

    this.userProj = this.userDetail.userProject;
    
    if(this.selectionFlag === 'Login') {
        this.selectProject();
    } else {
      this.userDetail.selectedProjectId = this.selectedProject;
        this.switchProject()
    }

  }

  selectProject(){
    if(this.userProj.length > 1 && (this.userDetail.defaultProjectId == null || this.userDetail.defaultProjectId == 0)){
      this.formView = true;
      this.initProjectSelectionForm();
      this.projectList = this.userDetail.userProject;

      if(this.userDetail.defaultProjectId) {
        this.defaultProjSet = true;
        this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.defaultProjectId);
      }
    } else {
      this.activeModal.close();
      if(+this.userDetail.defaultProjectId > 0) {
        this.fetchUserMenu(this.userDetail);
      } else {
        this.userDetail.defaultProjectId = this.userProj[0].project.projectId;
        this.fetchUserMenu(this.userDetail);
      }
    }
  }

  switchProject(){
    this.formView = true;
    this.initProjectSelectionForm();
    this.projectList = this.userDetail.userProject;

    if(this.userDetail.defaultProjectId == this.userDetail.selectedProjectId) {
      this.defaultProjSet = true;
      this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.defaultProjectId);
    } else {
      this.defaultProjSet = false;
      this.projectSelectionForm.controls['selectProject'].setValue(this.userDetail.selectedProjectId);
    }
  }

  initProjectSelectionForm(){
    this.projectSelectionForm = this.fb.group({
      selectProject: ['', Validators.compose([Validators.required ])],
      defaultSet: []
     });
  }

  Proceed(){
    this.disabled=true;
    const controls = this.projectSelectionForm.controls;
    if (this.projectSelectionForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    const selectedProject = controls.selectProject.value;
    this.userDetail.selectedProjectId = selectedProject;
    if(controls.defaultSet.value == true){
        this.auth.updateDefaultProject(selectedProject).subscribe(
          data => {
            console.log(data);
            this.userDetail.defaultProjectId = selectedProject;
          },
          error => {
            console.log('error occured');
          }
        );
    }
    this.activeModal.close();
    this.disabled=false;
    this.fetchUserMenu(this.userDetail);
//    this.eventEmitterService.onModalCloseResult(this.userDetail);

  }

  changeEntity(data){

    if(data == this.userDetail.defaultProjectId){
      this.defaultProjSet = true;
    }else{
      this.defaultProjSet = false;
    }
  }

  Close(){
    this.activeModal.close();
    this.projectSelectionForm.reset();
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.projectSelectionForm.controls[controlName];
      if (!control) {
        return false;
      }
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isDisabled() : boolean {
    return this.disabled;
  }

  fetchUserMenu(user: User){
		//console.log(user);

    if(user.selectedProjectId)
      this.projectId = user.selectedProjectId;
    else
      this.projectId = user.defaultProjectId;

      this.ais.getConfiguredAndAvailableIntegrationAppDetails(this.projectId).subscribe(
        async res=>{
           const ivrIntegerationEnabled:boolean= res['responseData'].configuredApp?.length>0?true:false
           localStorage.setItem('isAppIntegerationEnable',JSON.stringify(ivrIntegerationEnabled))
         },error=>{
          localStorage.setItem('isAppIntegerationEnable',null)
         }
       )

    this.auth.getSupportSetting(this.projectId).subscribe(
      async resp => {
        if (resp['statusCode'] == 200) {
          console.log("Resp", resp)
          const res = resp['responseData'];
          const enableChatSupport = res ? res.enableChatSupport : '1';
          localStorage.setItem('enableChatSupport', enableChatSupport);
        }
      },
      error => {
        localStorage.setItem('enableChatSupport', '1');
        console.error('Error occurred while fetching support setting:', error);
      }
    );

       this.auth.getLocalization(this.projectId).subscribe(
        async response=>{   
          if (response && response['responseData']) {
            this.systemLogo = response['responseData'].systemLogo;
	           localStorage.setItem('systemLogo', this.systemLogo ? this.systemLogo : '');
            this.systemName = response['responseData'].systemName;
            localStorage.setItem('systemName', this.systemName ? JSON.stringify(this.systemName) : '');
          } else {
            console.error('Invalid or missing responseData:', response);
            this.systemName = ''; // default name
            localStorage.setItem('systemName',this.systemName);
          }
      },

      error => {
        console.error('Error fetching localization:', error);
        localStorage.setItem('systemName', '');
      }
        )

		return this.auth.getUserMenu(this.projectId).subscribe(
			async data=>{
          localStorage.setItem('userMenu', null);
          localStorage.setItem('selectedProject', null);
          localStorage.setItem('pageTitle', null);
          localStorage.setItem('pluralName', null);
          localStorage.setItem('singularName', null);

          const userMenu = this.finalizeUserMenu(data['responseData']);
					this.createPageHeading(userMenu);
					const finalMenu: any = {
										header: {
											self: {},
											items: userMenu
										}
                  }

					localStorage.setItem('currentUser', JSON.stringify(user));
					localStorage.setItem('userProject', JSON.stringify(user.userProject));
					localStorage.setItem('userMenu', JSON.stringify(finalMenu));
					localStorage.setItem('selectedProject', this.projectId);
          localStorage.setItem('pluralName', JSON.stringify(this.pluralName));
          localStorage.setItem('singularName', JSON.stringify(this.singularName));
				//	console.log("Past local storage : "+localStorage.getItem('selectedProject'));
          this.menuPermission.prcessMenu(finalMenu.header.items);

          if(this.defaultMenuFlag){
            window.location.href = window.location.origin+"/settings-assistant";
          } else {
            window.location.href = window.location.origin+"/dashboard";
          }
        //  this.router.navigateByUrl(this.returnUrl); // Main page
		})
  }
  
  finalizeUserMenu(menuData: any){
		for(let res of menuData) {
			if(res.root == true) {
				res.alignment = 'left';
				res.toggle = 'click';
			}
		}
		return menuData;
  }
  
  /**
	 * 
	 * @param userMenu 
	 */
	createPageHeading(userMenu: any)
	{
		/* First Level */
		for(let res of userMenu){
			if(res.page && res.page != '#'){
			  this.generatePageHeading(res);
			}
			/* Second Level */
			if(res.submenu){
			  for(let res1 of res.submenu){
				if(res1.page && res1.page != '#'){
				  this.generatePageHeading(res1);
				}
				/* Third Level */
				if(res1.submenu){
				  for(let res2 of res1.submenu){
					if(res2.page && res2.page != '#'){
					  this.generatePageHeading(res2);
					}
					/* Forth Level */
					if(res2.submenu){
					  for(let res3 of res2.submenu){
						if(res3.page && res3.page != '#'){
						  this.generatePageHeading(res3);
						}
					  }
					}
				  }
				}
			  }
			}
		  }

		  localStorage.setItem('pageTitle', JSON.stringify(this.menuObj));
	}

	/**
	 * 
	 * @param menu 
	 */
	generatePageHeading(menu:any)
	{
		let jsonVal = {};
    let pageName = menu.page.replace(/\//g, '');

		jsonVal = {
					"page" : {
						"title": menu.title,
						"desc": '',
						"singularName":menu.singularName,
						"pruralName": menu.pruralName
					}
				};
			this.menuObj[pageName] = jsonVal;

      /** Default Menu Set */
      if(pageName == 'settings-assistant') {
        this.defaultMenuFlag = true;
      }

      if(pageName == 'customers' || pageName == 'contacts' || pageName == 'opportunities'){
        this.pluralName[pageName] = menu.pruralName;
        this.singularName[pageName] = menu.singularName;
      }

	}

}
