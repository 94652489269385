import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ManageActivitiesModel } from '../models/manage-activities.model';
import { environment} from '../../../../../environments/environment';
import { UserLocalizationService } from '../../shared/services/UserLocalization.service';


@Injectable({
  providedIn: 'root'
})
export class ManageActivitiesService {

  constructor(private http:HttpClient,private userLocalization:UserLocalizationService) { }

  getAdditionalFilteredActivities(projectId: number, moduleId: number, moduleRecordId: number){	
    return this.http.get<ManageActivitiesModel[]>(	
      environment.baseUrl +'/transactions/api/activities/fetchPerformWebActivities?'+	
      'projectId='+projectId+'&moduleId='+moduleId+'&moduleRecordId='+moduleRecordId);	
  }
  
  getAllActivities(projectId: number,isAdminPage:boolean=false){
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl +'/transactions/api/activities/manageActivities/?projectId='+projectId+'&isAdminPage='+isAdminPage);
  }

  saveActivity(activityData){
    activityData.userTime=this.userLocalization.getUserTime();
    activityData.transTimeZone=this.userLocalization.getUserTimeZoneCode();
    return this.http.post<any[]>(
      environment.baseUrl+'/setting/api/activities/manageActivities/settings',activityData);
  }

  updateSequence(sequenceData){
    return this.http.post<any[]>(
      environment.baseUrl+'/transactions/api/activities/manageActivities/updateSequence',sequenceData);
  }

  getSelectedActivity(activityId, projectId: number) {
    return this.http.get<ManageActivitiesModel[]>(
      environment.baseUrl + '/setting/api/activities/manageActivities/settings/'+ activityId +'?projectId='+projectId);
  }

  deleteActivities(activityId, projectId: number){
    return this.http.delete<any[]>(
      environment.baseUrl + '/transactions/api/activities/manageActivities/?projectId='+projectId+'&activityId='+ activityId);
  }

  getdynamicFieldNames(projectId: number){
    return this.http.get<any[]>(
      environment.baseUrl + '/setting/api/projects/'+projectId+'/dynamicFieldNames');
  
  }

  getActiveField(moduletype, projectId: number) {
    return this.http.get<any[]>(
      environment.baseUrl + '/transactions/api/customType/getFields/?projectId='+projectId+'&moduleType='+moduletype);
  }

  getActiveUsers(projectId: number){
    return this.http.get(
      environment.baseUrl + '/login/api/users/?projectId='+projectId+'&activeStatus=1');
  }

  getActiveRoles(projectId: number){
    return this.http.get(
      environment.baseUrl + '/setting/api/roles/?projectId='+projectId);
  }

  getCustomers(){

    return this.http.get(environment.baseUrl +'/transactions/api/dayPlanner/getCustomerList?month=1&year=2021&projectId=320')
  }
  getActivityFilter(projectId: number,viewMode,startDate,endDate)
  {
    return this.http.get(
      environment.baseUrl + '/transactions/api/activities/getActivityLookupView?projectId='+projectId+'&activityView='+viewMode+'&startDate='+startDate+'&endDate='+endDate);
    
  }

  getUserDownHierarchy(projectId: number){
    return this.http.get(environment.baseUrl + '/login/api/users/downHierarchy?projectId='+projectId);
  }

  getActivityFilterbasedOnPagination(projectId: number,viewMode,startDate,endDate,pageSize:number,pageNumber:number)
  {
    return this.http.get(
      environment.baseUrl+  '/transactions/api/activities/v1/getActivityLookupView?projectId='+projectId+'&activityView='+viewMode+'&startDate='+startDate+'&endDate='+endDate+'&pageSize='+pageSize+'&pageNumber='+pageNumber,);
    
  }
  getPlannedUnperformedActivityBasedOnPagination(projectId: number,viewMode,startDate,endDate,pageSize:number,pageNumber:number)
  {
    return this.http.get(
      environment.baseUrl+ '/transactions/api/activities/v1/getUnperformedPlannedActivityLookupView?projectId='+projectId+'&activityView='+viewMode+'&startDate='+startDate+'&endDate='+endDate+'&pageSize='+pageSize+'&pageNumber='+pageNumber,);
    
  }

}
