<div class="example-preview">
    <form [formGroup]="addCompanySettingsForm" autocomplete="off">
        <div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>Default sort in app
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>

                <div class="col-md-8">
                    <mat-radio-group formControlName="sortBy" fxLayout="column" fxLayoutGap=".25rem"
                        [(ngModel)]="sortBy">
                        <mat-radio-button [value]="'1'">By Name (A-Z)</mat-radio-button>
                        <mat-radio-button [value]="'0'">By Location (Nearest first)</mat-radio-button>
                        <br>
                        <mat-error *ngIf="isControlHasError('defaultSort', 'required')">Default Sort is
                            required</mat-error>
                    </mat-radio-group>
                </div>    
            </div>
            <div style="height: 30px;"></div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>Enforce live location capture while adding from app</label>
                </div>
                <div class="col-md-8">
                    <mat-checkbox #checkbox formControlName="enforceLiveLocCapture" [(ngModel)]="enforceLiveLocCapture"
                        checked></mat-checkbox> <label class="ml-3">Yes</label>
                    <br>
                    <mat-hint class="form-error">Select to enforce location capture when adding Customer</mat-hint>   
                </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label>Ownership can be assigned to 
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>

                <div class="col-md-8">
                    <mat-radio-group formControlName="chooseCompanyOwnerFlag" fxLayout="column" fxLayoutGap=".25rem"
                        [(ngModel)]="chooseCompanyOwnerFlag">
                        <mat-radio-button [value]="'1'">Up in the hierarchy</mat-radio-button>
                        <mat-radio-button [value]="'2'">Down the hierarchy</mat-radio-button>
                        <mat-radio-button [value]="'3'">Any user</mat-radio-button>
                        <mat-error *ngIf="isControlHasError('chooseCompanyOwnerFlag', 'required')">Select
                            one.</mat-error>
                    </mat-radio-group>
                </div> 
            </div>

            <div style="height: 10px;"></div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label class="col-form-label form-padding-top-25">Rename Customer?
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="standard" class="input-100">
                        <input matInput #input2 maxlength="25" formControlName="singularName" [(ngModel)]="singularName"
                            ktAutoTrimSpaces>
                        <mat-hint align="end" style="color: rgba(0,0,0,.54)">{{input2.value?.length || 0}}/25</mat-hint>
                        <mat-error *ngIf="isControlHasError('singularName', 'required')">Rename Customer cannot be
                            blank</mat-error>
                        <mat-error *ngIf="isControlHasError('singularName', 'maxlength')"><strong>Maximum 25
                                characters</strong></mat-error>
                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                    </mat-form-field>                    
                </div>
                
            </div>
            <div style="height: 10px;"></div>

            <div class="form-group row">
                <div class="col-md-4">
                    <label class="col-form-label form-padding-top-20">What would be the plural?
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <mat-form-field class="input-100">
                        <input matInput #input maxlength="25" formControlName="pruralName" [(ngModel)]=pruralName
                            ktAutoTrimSpaces>
                        <mat-hint align="end" style="color: rgba(0,0,0,.54)"> {{input.value?.length || 0}}/25</mat-hint>
                        <mat-error *ngIf="isControlHasError('pruralName', 'required')">Plural cannot be
                            blank</mat-error>
                        <mat-error *ngIf="isControlHasError('pruralName', 'maxlength')"><strong>Maximum 25
                                characters</strong></mat-error>
                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                    </mat-form-field>                    
                </div>
            </div>

            <div styles="height: 10px;"></div>
            <div class="form-group row">
                <div class="col-md-4">
                    <label class="col-form-label form-padding-top-25">Serial No. Key
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="standard" class="input-100">
                        <input matInput #input maxlength="25" formControlName="serialKey" ktAutoTrimSpaces>
                        <mat-hint align="start" style="color: rgba(0,0,0,.54);">
                            <ng-container *ngIf="serialKeyUpdated === false; else updatesAvailable">
                                <strong>1 Update left.</strong>
                            </ng-container>
                            <ng-template #updatesAvailable>
                                <strong>0 updates available.</strong>
                            </ng-template>
                            Changing the serial no. key will start a background re-index of your project, and may break
                            some external integrations.
                        </mat-hint>
                        <mat-hint align="end" style="color: rgba(0,0,0,.54)"> {{input.value?.length || 0}}/25</mat-hint>
                        <mat-error *ngIf="isControlHasError('serialKey', 'required')">Serial No. Key cannot be
                            blank</mat-error>
                        <mat-error *ngIf="isControlHasError('serialKey', 'maxlength')"><strong>Maximum 25
                                characters</strong></mat-error>
                        <mat-icon *ngIf="!serialKeyUpdated" matSuffix
                            [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        <mat-icon *ngIf="serialKeyUpdated" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                    </mat-form-field>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-md-12">
                    <span style="color:red">{{errorMes}}</span>
                </div>
                
            </div>
                
                <div class="form-group row mt-8">
                    <div class="col-md-4">
                        <label>Allow customers to Login</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox (change)="changeCustomerLogin($event)" formControlName="customerLogin">Yes</mat-checkbox> 
                        <br>
                        <mat-hint class="form-error">Tick this if you want to allow customers to login into assistive app and portal</mat-hint>
                    </div>
                </div>

                <div *ngIf="showCustomerAccess" formArrayName = "customerAccess">
                <div class="form-group row" *ngFor = "let value of customerAccess.controls; let i = index; last as isLast" [formGroupName] = "i">
                    <div class="col-md-4">
                        <label *ngIf="i == 0" class="col-form-label form-padding-top-25">Define customer access</label>
                    </div>
                    <div class="col-md-8 row">
                        <div class="col-md-12 row">
                    <!-- entity field-->
                    <div class="col-md-3">
                        <mat-form-field appearance="standard" class="input-100" >
                          <mat-label>Entity Field
                            <span class="mandatory-marker">*</span>
                          </mat-label>
                          <mat-select formControlName="entityField" (selectionChange)='changeEntityField($event.value, i)'>
                            <mat-option>
                              <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found"
                                #entityFieldFilter
                              ></ngx-mat-select-search>
                            </mat-option>
                            <mat-option
                              *ngFor="
                                let field of entityFieldList
                                  | search: entityFieldFilter.value:'attributeName'
                              "
                              [value]="field.id"
                            >
                              {{ field.attributeName }}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="isCustomerAccessControlHasError('entityField', i, 'required')">Entity Field cannot be blank.</mat-error>
                        </mat-form-field>                  
                      </div>
                
                      <!-- entity field value-->
                      <div class="col-md-4">
                        <mat-form-field appearance="standard" class="input-100" >
                          <mat-label>Entity Field Value
                            <span class="mandatory-marker">*</span>
                          </mat-label>
                          <mat-select formControlName="entityFieldValue" multiple (selectionChange)='changeEntityFieldValue()'>
                            <mat-option>
                              <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found"
                                #lookupFilter
                              ></ngx-mat-select-search>
                            </mat-option> 
                            <mat-option
                              *ngFor="
                                let lookup of lookupListsObj[i]?.options
                                  | search: lookupFilter.value:'lookupValue'
                              "
                              [value]="lookup.id"
                            >
                              {{ lookup.lookupValue}}
                            </mat-option>
                          </mat-select>
                          <mat-error *ngIf="isCustomerAccessControlHasError('entityFieldValue', i, 'required')">Entity Field Value cannot be blank.</mat-error>
                        </mat-form-field>
                      </div>
                        <!-- Role -->
                                            <div class="col-md-4">
                                                <mat-form-field appearance="standard" class="input-100">
                                                  <mat-label>Role
                                                    <span class="mandatory-marker">*</span>
                                                  </mat-label>
                                                  <mat-select formControlName="role">
                                                    <mat-option>
                                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found"
                                                          #rolesFilter
                                                        ></ngx-mat-select-search>
                                                      </mat-option> 
                                                    <mat-option
                                                    *ngFor="
                                                    let role of activeRoles
                                                      | search: rolesFilter.value:'roleName'
                                                  "
                                                  [value]="role.roleId"
                                                >
                                                {{ role.roleName }}
                                                    </mat-option>
                                                  </mat-select>
                                                  <mat-error *ngIf="isCustomerAccessControlHasError('role', i, 'required')">Role cannot be blank</mat-error>
                                                </mat-form-field>       
                                              </div>
                        
                                              <!-- minus button -->
                                              <div class="col-md-1 pt-8">
                                                <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" (click)="removeExtraField(i)">
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                              </div>
                        </div>

                        <!-- add button -->
                        <div class="form-group row mt-4 col-md-12" *ngIf="isLast"> 
                            <div class="col-md-11"></div> 
                            <div class="col-md-1">
                                <button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" (click)="addExtraField()">
                                    <i class="fas fa-plus"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
        </div>
        <div class="row" ktMargin [marginBottom]="'20px'"></div>

        <div class="modal-footer">
            <button type="button" class="btn btn-secondary" [disabled]='isDisabled()' (click)="Cancel()">Cancel</button>
            <button type="button" class="btn btn-primary" [disabled]='isDisabled()' (click)="Save()">Save</button>
        </div>
        
    </form> 
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
