import { Injectable } from '@angular/core';
import {HttpClient, HttpParams, HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';
import { Contact } from '../models/contact.model';
import { map, mapTo } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomAttibute } from '../models/custom-attibute.model';
import { CustomAttributeList } from '../models/custom-attribute-list.model';
@Injectable({
  providedIn: 'root'
})
export class ContactListService {

  url=environment.baseUrl +'/contact/getAllContactType';
  url2= environment.baseUrl + '/setting/api/customType/getAllCustomAttributes';
  API_CUSTOM_ATTRIBUTE_URL=environment.baseUrl+'/setting/api/customType/getAllCustomAttributes';

  constructor(private http: HttpClient) { }

  getContact(projectId: number)
  {
    return this.http.post<Contact[]>(this.url, { "projectId": projectId});
  }

getCustom(projectId: number)
  {
    return this.http.post<CustomAttributeList>(this.API_CUSTOM_ATTRIBUTE_URL, { "projectId": projectId, "moduleType" : "1"});
}
}
