<div class="card card-custom" [ngClass]="cssClasses">
	<!-- Header -->
	<!--<div class="card-header border-0 bg-danger py-5">
		<h3 class="card-title font-weight-bolder text-white">Sales Stat</h3>
		
	</div>-->
	

    <div class="card-title text-primary font-weight-bold font-size-h6" style="padding: 10px 0px 0px 10px;"> 
        <a (click)="toggle()" [attr.aria-expanded]="!isCollapsed" aria-controls="insights" [ngStyle]="{'cursor':'pointer'}">
            <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
            <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
        </a>
        Insights
    </div>

    <!-- Body -->

    <div id="insights" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 insights-body-scroll">
        <div class="card card-border rounded p-2 ml-2 mr-2 mb-2">
            <div class="card-body p-0 position-relative overflow-hidden">
                <!--begin::Chart-->
                <div class="card-rounded bg-success pt-20 text-center text-white" [style.height]="'200px'">
                    <h1>{{orderValue | number}}</h1>
                    <h5>Order Value till Date</h5>
                </div>
                <!--end::Chart-->
    
                <!--begin::Stats-->
                <div class="card-spacer mt-n25">
                    <!--begin::Row-->
                    <div class="row m-0">
                        <div class="col bg-light-success text-success px-6 py-10 rounded-xl mr-2 mb-2 text-center">
                            <div class="font-weight-bold font-size-h6"> {{orderCount}} </div>
                            <div class="font-weight-bold"> Orders </div>
                        </div>
                        <div class="col bg-light-warning text-warning px-6 py-10 rounded-xl mb-2 text-center">
                            <div class="font-weight-bold font-size-h6"> {{avgOrderValue}} </div>
                            <div class="font-weight-bold"> Avg. Order Value </div>
                        </div>
                    </div>
                    <!--end::Row-->
                    <!--begin::Row-->
                    <div class="row m-0">
                        <div class="col bg-light-danger text-danger px-6 py-10 rounded-xl mr-2 mb-2 text-center">
                            <div class="font-weight-bold font-size-h6"> {{avgMonthlyOrders}} </div>
                            <div class="font-weight-bold"> Avg. Monthly Orders </div>
                        </div>
                        <div class="col bg-light-info text-info px-6 py-10 rounded-xl mb-2 text-center">
                            <div class="font-weight-bold font-size-h6"> {{lastOrderSince}} </div>
                            <div class="font-weight-bold"> Days Since Last Order </div>
                        </div>
                    </div>
                    <!--end::Row-->
                    <div class="card card-border rounded p-2 mb-4" [ngStyle]="{'background-color':'#F2F2F2'}">
                        <div class="d-flex align-items-center p-2 pb-0">
                            <div class="d-flex flex-column flex-grow-1">
                                <span class="text-dark-75 text-primary font-weight-bold font-size-lg mb-1">Top Products
                                    Sold</span>
                            </div>
                        </div>
                        <div class="d-flex align-items-center pl-2 pr-2 pb-2">
                            <div class="d-flex flex-column flex-grow-1 cust-attr-scroll custom-width-limit">
                                <ng-container *ngIf="topProducts?.length > 0 ; else noProducts">
                                    <div class="text-muted font-size-sm pt-2" [title]="product"
                                        style="text-overflow: ellipsis;overflow: hidden;white-space: nowrap;"
                                        *ngFor="let product of topProducts">
                                        {{product}}
                                    </div>
                                </ng-container>
    
                                <ng-template #noProducts>
                                    <div class="text-muted font-size-sm pt-2" title="N/A">
                                        N/A
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <!--end::Stats-->
            </div>
        </div>
    </div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='dashboard-stat'>  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>