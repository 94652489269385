<div class="card card-custom mb-4" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-2 pr-6" [ngStyle]="{'border-bottom':'0'}">
        <div class="card-title text-primary font-weight-bold font-size-h6"> 
            <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="opportunity" [ngStyle]="{'cursor':'pointer'}">
                <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
                <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
            </a>
            {{headingName}} Info
        </div>
        <div class="card-toolbar">
            <div class="example-tools justify-content-center">
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary" (click)="editOpportunity()">
                    <img src="./assets/media/svg/icons/General/Edit-2.svg" class="pr-1">
                </a>
            </div>
        </div>
    </div>
    <!-- Body -->
    <div id="opportunity" [ngbCollapse]="isCollapsed" class="card-body p-0 m-0 card-body-scroll">
        <ngx-spinner size="default" type="ball-spin-clockwise" name="dashboard-opportunity">  
            <p style="color: white">Fetching Data... </p>  
        </ngx-spinner>
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <!-- Opportunity Start -->
        <div class="card card-border rounded p-2 ml-2 mr-2 mb-2">
            <div class="d-flex align-items-center p-2">
                <!--<div class="symbol symbol-circle symbol-35 mr-3">
                    <img *ngIf="(imageName == null || imageName == '')" alt="Pic" src="./assets/media/stock-600x400/img-70.jpg">
                    <img *ngIf="(imageName != null && imageName != '')" alt="Pic" src="{{imageName}}">
                </div>-->
                <div class="d-flex flex-column flex-grow-1">
                    <a href="javascript:void(0)" class="text-dark-75 text-hover-primary font-weight-bold font-size-lg">{{opportunityName}}-{{serialNo}}</a>
                    <span class="text-muted font-weight-bold font-size-sm">
                        <ngb-progressbar type="success" [value]="winProbability"></ngb-progressbar>
                    </span>
                </div>
                <div class="text-muted font-weight-bold font-size-sm pl-2 pr-4 pt-6">{{winProbability}}%</div>
            </div>
            <div class="d-flex align-items-center p-2">
                <div class="d-flex flex-column flex-grow-1">
                    <span class="text-muted font-size-sm">
                        <img src="./assets/media/svg/icons/General/settings_1.svg" class="pl-3 pr-4" title="Status"> 
                        {{status}}
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <img src="./assets/media/svg/icons/General/money_2.svg" class="pl-3 pr-4" title="Estimated Revenue"> 
                        {{estimatedRevenue}}
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <img src="./assets/media/svg/icons/General/Calendar.svg" class="pl-3 pr-4" title="Closure Date">
                        {{closureDate}}
                    </span>
                    <span class="text-muted font-size-sm pt-2">
                        <img src="./assets/media/svg/icons/General/people_outline_24px.svg" class="pl-3 pr-4" title="Contacts">
                        <span class="label label-light-primary mr-2" *ngFor="let contact of contacts" ngbTooltip="{{contact.name}}">
                            {{contact.initials}}
                        </span>
                    </span>
                </div>
            </div>
            <!--<div class="d-flex align-items-center p-2">
                <div class="d-flex flex-column flex-grow-1">
                    <span class="text-muted font-size-sm">
                        <a href="javascript:void(0)" class="text-dark-75 text-hover-primary mb-1 font-size-sm font-weight-bolder"> View Edit History</a>
                    </span>
                </div>
            </div>-->
        </div>
        <!-- Opportunity End -->
    </div>
</div>
