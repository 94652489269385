import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html'
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;
  displayRelation:boolean;

  constructor() { }

  agInit(params){
    this.displayRelation=false;
    this.params = params;
    this.data = params.value;
    if(params.data.fieldSpec ==1 || params.data.fieldSpec ==2){
      this.displayRelation=true;
    }
    if(params.data.childAttributes.fieldSpec ==1 || params.data.childAttributes.fieldSpec ==2){
      this.displayRelation=true;
    }
  }

  ngOnInit(): void {
  }

  public invokeParentMethod() {
    this.params.context.componentParent.EditCustomField(this.params.data);
  }

  public invokeRelationMethod() {
    this.params.context.componentParent.editRelationship(this.params.data);
  }

}
