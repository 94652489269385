import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ListType } from '../../../contact-setting/models/list-type.model';
import { EventEmitterService } from '../../../shared/services/event-emitter.service';
import { ReportBuilderUtil as reportUtil } from '../../../report-builder/report-builder.util';

@Component({
  selector: 'kt-edit-entity-field',
  templateUrl: './edit-entity-field.component.html',
  styleUrls: ['./edit-entity-field.component.scss']
})
export class EditEntityFieldComponent implements OnInit {

  public editEntityFieldForm: FormGroup;
  mandatoryFlag: boolean = false;
  editVal:any;
  header:any;
  fieldName:string;
  label:string;
  measureTypeList:ListType[];
  maxLabelLength: number = 50;
  public measureValueType: FormControl = new FormControl();
  valuelist:any;
  reportType:string|number;
  isFirstOpen:boolean;
  public displayDateGroupBy:boolean=false;
  public dateGroupByList=new Array<ListType>();

  constructor(private fb: FormBuilder,
    public activeModal: NgbActiveModal,
    public eventEmitterService: EventEmitterService) { }

  ngOnInit(): void {
    this.initeditEntityFieldForm();
    this.getMeasureList();
    this.mandatoryFlag = this.isMeasureControlRequired();
    this.fieldName=this.editVal.entityFieldName;
    if(this.editVal.label =='' || this.editVal.label == undefined){
      this.label=this.editVal.entityFieldName;
    }else{
      this.label=this.editVal.label;
    }
   // console.log('this.fieldName------'+this.fieldName);
   // console.log('this.label---'+this.label);
    if( this.reportType == 2 && this.header!= 'User Filter' && this.editVal.aggFunc !=null && this.editVal.aggFunc !=''){
      let msr: any = this.measureTypeList.find(x => x.value == this.editVal.aggFunc);
      msr = msr ? msr.id : null;
      this.editEntityFieldForm.controls['measure'].setValue(msr);
    }
   // this.editEntityFieldForm.controls['label'].setValue(this.editVal.entityFieldName);
   if((this.header!="User Filter" && this.editVal.entityFieldType.id==3) && (this.reportType == 1 || this.reportType == 3 || this.reportType == 4 || this.reportType == 5 || this.reportType == 6 )){
    this.displayDateGroupBy=true;
    this.dateGroupByList.push({id:1,value:'Date'});
    this.dateGroupByList.push({id:2,value:'Week'});
    this.dateGroupByList.push({id:3,value:'Month'});
    this.dateGroupByList.push({id:4,value:'Year'});
    this.editEntityFieldForm.controls['dateGroupBy'].setValue(this.editVal.dateGroupBy?this.editVal.dateGroupBy:1);
    this.editEntityFieldForm.controls['dateGroupBy'].setValidators([Validators.required]);
   }
  }

  initeditEntityFieldForm(){
    this.editEntityFieldForm = this.fb.group({
      fieldName:[{value: '', disabled: true}],
      label: ['', Validators.compose([Validators.required,Validators.maxLength(this.maxLabelLength) ])],
      measure:[],
      dateGroupBy:[''],
     });
     
     if(this.reportType == 2 && this.header!= 'User Filter' && [3, 7].includes(this.editVal.entityFieldType.id)){
      this.editEntityFieldForm.get('measure').setValidators([Validators.required]);
     }
  }

  changeMeasureType(val){
    const oldLabel: string = this.editEntityFieldForm.get('label').value;
    const newLabel = reportUtil.getNewLabelOnMeasureChange(oldLabel, val, this.measureTypeList);
    this.editEntityFieldForm.get('label').setValue(newLabel);
  }

  getMeasureList(){
    this.measureTypeList= new Array<ListType>();

   if(this.editVal.entityFieldType.id == 7){
    this.measureTypeList.push({ id: 1, value:"sum"});
    this.measureTypeList.push({ id: 2, value:"average"});
    this.measureTypeList.push({ id: 3, value:"count"});
    this.measureTypeList.push({ id: 4, value:"min"});
    this.measureTypeList.push({ id: 5, value:"max"});
   }
   else if(this.editVal.entityFieldType.id == 3){
    this.measureTypeList.push({ id: 3, value:"count"});
    this.measureTypeList.push({ id: 4, value:"min"});
    this.measureTypeList.push({ id: 5, value:"max"});
   }
   else{
    this.measureTypeList.push({ id: 3, value:"count"});
   }
  }

  Close(){
    this.activeModal.close();
    this.editEntityFieldForm.reset();
    // if(this.isFirstOpen === true) {
    //   this.Delete();
    // }
  }

  Save(){

    const controls = this.editEntityFieldForm.controls;

		if (this.editEntityFieldForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}
    const CopyOfEditVal = {...this.editVal};
    
    CopyOfEditVal.measure=controls.measure.value;
    if(CopyOfEditVal.measure !=null && CopyOfEditVal.measure !=''){
      if(CopyOfEditVal.measure == 1)
            CopyOfEditVal.aggFunc='sum';
      else if(CopyOfEditVal.measure == 2)
            CopyOfEditVal.aggFunc='average';
      else if(CopyOfEditVal.measure == 3)
            CopyOfEditVal.aggFunc='count';
      else if(CopyOfEditVal.measure == 4)
            CopyOfEditVal.aggFunc='min';
      else if(CopyOfEditVal.measure == 5)
            CopyOfEditVal.aggFunc='max';
    }else{
            CopyOfEditVal.aggFunc=null;
    }
    CopyOfEditVal.label=controls.label.value;
    if((this.header!="User Filter" && CopyOfEditVal.entityFieldType.id==3) && (this.reportType == 1 || this.reportType == 3 || this.reportType == 4 || this.reportType == 5 || this.reportType == 6 )){
      CopyOfEditVal.dateGroupBy=controls.dateGroupBy.value;
    }
   // console.log(CopyOfEditVal.measure);
    this.activeModal.close(CopyOfEditVal);
    this.editEntityFieldForm.reset();
    this.eventEmitterService.onModalCloseResult(CopyOfEditVal);
  // this.eventEmitterService.onModalCloseResult(this.valuelist);
    //this.activeModal.close();
    //this.editEntityFieldForm.reset();

  }

  Delete(){
    const index = this.valuelist.indexOf(this.editVal);
   // console.log('index---'+index);
   if(index !== -1) {
    this.valuelist.splice(index, 1);
   }
    this.activeModal.close("Deleted");
    this.editEntityFieldForm.reset();
   // this.eventEmitterService.onModalCloseResult(this.editVal);
   this.eventEmitterService.onModalCloseResult(this.valuelist);
  }
  
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editEntityFieldForm.controls[controlName];
      if (!control) {
        return false;
      }
      
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  isMeasureControlRequired(): boolean {
    if(this.reportType == 2 && this.header!= 'User Filter' && [3, 7].includes(this.editVal.entityFieldType.id))
    {return true;}
  return false
  }

  isGroupByRequired(): boolean{
    if((this.header!="User Filter" && this.editVal.entityFieldType.id==3) && (this.reportType == 1 || this.reportType == 3 || this.reportType == 4 || this.reportType == 5 || this.reportType == 6 ))
    return true;
  return false;
  }

}
