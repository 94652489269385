// Angular
import {Component, Input, OnInit} from '@angular/core';
import { OpportunityDashboardService } from '../../../../services/opportunity-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-widget1-sales-stat',
  templateUrl: './widget1-sales-stat.component.html',
  styleUrls: ['./widget1-sales-stat.component.scss']
})
export class Widget1SalesStatComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  opportunityStats: any;
  revenueEarned: number | string;
  order: number;
  estimate: number;
  onSite: number;
  offSite: number;
  win: number;
  nextVisit: string;
  futureActivityDueDate: string;
  lastOrder: string;
  daysOld: number;
  velocity: string;

  constructor(
    private _ods: OpportunityDashboardService,
    private SpinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    this.SpinnerService.show('dashboard-stat');
    this.fetchOpportunityStats(this.selectedProject, this.parentData);

  }

  fetchOpportunityStats(projectId: number, opportunityId: number){
    this._ods.getOpportunityStats(projectId, opportunityId).subscribe(
      res=>{
        this.opportunityStats = res['responseData'];
        this.revenueEarned = this.opportunityStats.revenueEarned;
        this.order = this.opportunityStats.activitiesCount.order;
        this.estimate = this.opportunityStats.activitiesCount.estimate;
        this.onSite = this.opportunityStats.activitiesCount.onSite;
        this.offSite = this.opportunityStats.activitiesCount.offSite;
        this.daysOld = this.opportunityStats.activitiesCount.daysOld;
        this.velocity = this.opportunityStats.activitiesCount.velocity;
        this.win = this.opportunityStats.performance;
        this.nextVisit = this.opportunityStats.activityDates.nextVisit;
        this.futureActivityDueDate = this.opportunityStats.activityDates.futureActivityDueDate;
        this.lastOrder = this.opportunityStats.activityDates.lastOrder;

        this.SpinnerService.hide('dashboard-stat');
        
      },
      err=>{
        console.log(err);
      }
    )
  }

}
