import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportCatalogService {

  constructor(private http: HttpClient) { }

  getReportscatalog(projectId:any){
    return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/fetchReports?projectId='+projectId,{}).pipe(
      map(data => {
        return data;
     })
    )
  }

  getReportCatalogData(reportId, setToStorage:boolean = true){
    // const header = neSw HttpHeaders().set('content-type', 'application/json').set('Access-Control-Allow-Origin', '*');
    // return this.http.get<any>(
    //   environment.baseUrl + '/reportsfetcher/api/reportBuilder/previewCatalogReport?reportId='+reportId);
      return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/previewCatalogReport?reportId='+reportId,{}).pipe(
        map(data => {
          if(setToStorage) {
            localStorage.setItem('chartData', JSON.stringify(data.responseData));        
          }
          return data;
       })
      )
  }

  copyReportCatalog(payload): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/reports/api/reportBuilder/copyReport', payload).pipe(
      map(data => {
       return data;
     })
    )
  }

  getUsers(projectId:any){
    return this.http.get<any>(environment.baseUrl+'/login/api/users/activeProjectUsersWithoutLoggedInUser?projectId='+projectId)
  }

  getRoles(projectId:any){
    return this.http.get<any>(environment.baseUrl+'/setting/api/roles/?projectId='+projectId,{}).pipe(
      map(data => {
        return data;
     })
    )
  }

  shareReportCatalog(payload): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/reports/api/reportBuilder/shareReport', payload).pipe(
      map(data => {
       return data;
     })
    )
  }

  getReportLocation(projectId:any, reportid: string=''){
    return this.http.get<any>(environment.baseUrl+'/reports/api/reportBuilder/fetchReportDashboardLocationList?projectId='+projectId+'&reportId='+reportid,{}).pipe(
      map(data => {
        return data;
     })
    )
  }

  getReportSharedDetail(reportid: string){
    return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/fetchReportSharedWithDetails?reportId='+reportid,{}).pipe(
      map(data => {
        return data;
     })
    )
  }

  addReport(payload): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/reports/api/reportBuilder/saveReportLocation', payload).pipe(
      map(data => {
       return data;
     })
    )
  }

  public saveReport(payload): Observable<any> {
    return this.http.post<any>(environment.baseUrl+'/reports/api/reportBuilder/saveReport', payload).pipe(
      map(data => {
       return data;
     })
    )
  }

  getReportData(reportId:any){
    return this.http.get<any>(environment.baseUrl+'/reportsfetcher/api/reportBuilder/fetchReportData?reportId='+reportId,{}).pipe(
      map(data => {
        return data;
     })
    )
  }

}
