<div class="example-preview">
    <div>
        <form [formGroup]="saveReportForm" novalidate autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Add New</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                 <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25"> Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="reportName" [(ngModel)]="reportName" ktAutoTrimSpaces>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/50</mat-hint>
                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                            <mat-error  *ngIf="isControlHasError('entityDetailsValue', 'required')">Select one</mat-error>
                        </mat-form-field>                    
                    </div>
                </div>

             <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Description</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input2  maxlength="200" formControlName="description" ktAutoTrimSpaces>
                            <mat-hint align="end">{{input2.value?.length || 0}}/200</mat-hint>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                        </mat-form-field>                    
                    </div>
                </div>
                <div class="form-group row" style="padding-top: 10px;">
                    <div class="col-md-4">
                        <label>Location</label>
                    </div>
                    <div class="col-md-8">
                        <mat-checkbox #checkbox  formControlName="reportCatalog" [(ngModel)]="reportCatalog" checked></mat-checkbox>
                        &nbsp; Reports Catalog  &nbsp; &nbsp; &nbsp;
                        <mat-checkbox #checkbox  formControlName="dashboard" [(ngModel)]="dashboard" checked (change)="checkValue(dashboard)"></mat-checkbox>
                        &nbsp; Dashboard  &nbsp; &nbsp; &nbsp;
                        <mat-checkbox #checkbox  formControlName="reportMenue" [(ngModel)]="reportMenue" checked></mat-checkbox>
                        &nbsp; Reports Menu 
                        </div>
                </div>  

                <div *ngIf="displayDashboard" style="padding-top: 10px;">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label pt-6">Select Existing Dashboard</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select multiple formControlName="ExistDashoad" (selectionChange)="changeDash($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search #existingDashoadFilter placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="dashBoardValueType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let dash of ExistDashoadList| search : existingDashoadFilter.value :'value'" [value]="dash.id">
                                        {{dash.value}}
                                    </mat-option>
                                </mat-select>
                                <button matSuffix mat-icon-button type="button" aria-label="clear" (click)="changeDash('');$event.stopPropagation();">
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>  
                    </div>
                    or
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Enter New Dashboard Name</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field  class="input-100" >
                                <input matInput #input2  maxlength="200" formControlName="newDashboard" [(ngModel)]="newDashboard"
                                    (change)="onChangeDisable()" ktAutoTrimSpaces>
                                <mat-hint align="end">{{input2.value?.length || 0}}/200</mat-hint>
                                <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                            </mat-form-field>                    
                        </div>
                    </div>

                </div>
                

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"   (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  


<ngx-spinner name="saving" bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise">  
    <p style="color: white">Saving Data... </p>  
</ngx-spinner>