<div class="example-preview">
    <div>
        <form [formGroup]="editFieldForm" novalidate autocomplete="off">
            <div class="modal-header">
                <h4 class="modal-title">Edit Field</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Type</label>
                    </div>
                    <div class="col-md-8">
                        <!--  <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                            <mat-select formControlName="attributeType" (selectionChange)="change($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="attributeFilterType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let attributeType of attributeTypelist| search : attributeFilterType.value :'typeName'" [value]="attributeType.id">
                                    {{attributeType.typeName}}
                                </mat-option>
                            </mat-select>
                          <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field</mat-hint>  
                            <mat-error *ngIf="isControlHasError('attributeType', 'required')">Field Type is required</mat-error>
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>  -->

                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput formControlName="attributeType" [(ngModel)]="attributeTypeVal">
                            <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>
                    </div>
                </div>
                
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Name
                            <span *ngIf="!disableAttributeName" class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput #input1 maxlength="500" formControlName="attributeName"
                                [(ngModel)]="attributeName" ktAutoTrimSpaces>

                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input1.value?.length || 0}}/500
                            </mat-hint>
                            <mat-error *ngIf="isControlHasError('attributeName', 'required')">Field Name cannot be blank.
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('attributeName', 'maxlength')"><strong>Maximum 500
                                    characters</strong></mat-error>
                            <mat-icon *ngIf="disableAttributeName" matSuffix [ngStyle]="{'color':'grey'}">lock
                            </mat-icon>
                            <mat-icon *ngIf="disableAttributeName== false" matSuffix [ngStyle]="{'color':'#3699FF'}">
                                text_fields</mat-icon>
                        </mat-form-field>                  
                    </div>
                </div>

                <div *ngIf="showLogicalField" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Logical Field Name</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput formControlName="logicalFieldName"
                            [(ngModel)]="attributeIdentifierName" ktAutoTrimSpaces>
                            <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>

                <div *ngIf="!normalText && !headerText && !pageSeperator" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field Instructions</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput #input2 maxlength="200" formControlName="instructions"
                                [(ngModel)]="instructions" ktAutoTrimSpaces>
                            <mat-hint>Enter the instructions to show for adding data in this field.</mat-hint>
                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input2.value?.length || 0}}/200
                            </mat-hint>
                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <!-- <div *ngIf="displayFields">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>New / Existing</label>
                        </div>

                        <div class="col-md-8">
                            <mat-radio-group formControlName="fieldValue" fxLayout="column" fxLayoutGap=".25rem">
                                <mat-radio-button [value]="'1'">New Field</mat-radio-button>
                                <mat-radio-button [value]="'2'">Link from other entities</mat-radio-button>
                            </mat-radio-group>
                        </div>
                    </div>
                </div> -->

                <div *ngIf="isDisplayLinkedField">
                    <div class="form-group row" *ngIf="!isFieldTypeDataList">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Entity</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100" *ngIf="attributeType.id==19">
                                <mat-select formControlName="entityValue" (selectionChange)="changeEntity($event.value)" >
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="entityValueType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let entityVal of entityTypeList; let i = index;" [value]="entityVal.id">
                                        {{entityVal.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select the entity</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="standard" class="input-100" *ngIf="attributeType.id!==19">
                                <input matInput #input1 maxlength="50" formControlName="entityValue"
                                    [(ngModel)]="entityValue" ktAutoTrimSpaces>
                                <mat-icon matSuffix [ngStyle]="{'color':'grey'}"></mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select the entity</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="isEntityField">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">{{isFieldTypeDataList ? 'List Name' :
                                'Entity Field'}}</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100" *ngIf="attributeType.id==19">
                                <mat-select formControlName="linkedAttributeId" >
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="entityfieldType" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let fieldEntity of entityfieldList; let i = index;" [value]="fieldEntity.id">
                                        {{fieldEntity.attributeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select field of the entity to be 
                                    used for sending OTP</mat-hint>
                            </mat-form-field>
                            <mat-form-field appearance="standard" class="input-100" *ngIf="attributeType.id!==19">
                                <input matInput #input12 maxlength="50" formControlName="linkedAttributeId"
                                    [(ngModel)]="linkedAttributeId">
                                <mat-icon matSuffix [ngStyle]="{'color':'grey'}" *ngIf="attributeType.id!==19">lock</mat-icon>
                                <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="attributeType.id==19">Select field of the entity to be 
                                    used for sending OTP</mat-hint>
                                <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="attributeType.id!==19">Select field of the entity that can be
                                    selected</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- Start Product Form Selection -->
                    <div class="form-group row" *ngIf="isDisplaySelection">
                        <div class="col-md-4">
                            <label class="col-form-label pt-8">Select Form</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="selectionType" [(ngModel)]="selectionType"
                                    (selectionChange)="changeSelectionType($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="selectionTypeValue">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let productForm of productFormList | search : selectionTypeValue.value :'name'"
                                        [value]="productForm.id">
                                        {{productForm.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-error *ngIf="isControlHasError('selectionType', 'required')">Please select form
                                </mat-error>
                                <mat-hint style="color: rgba(0,0,0,.54);">Please Select from.</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row mt-6" *ngIf="isProductFormSelected">
                        <div class="col-md-4">
                            <label>Allow user to select data from previously submitted form
                            </label>
                        </div>
                        <div class="col-md-8 mt-3">
                            <mat-checkbox #checkbox #mandatoryCB (change)='previousFormcheckboxChanged($event)' formControlName="previousFormcheckbox">
                            </mat-checkbox>&nbsp; Yes <br>
                        </div>
                    </div>

                    <div class="form-group row" *ngIf="isPreviousFormcheckboxSelected">
                        <div class="col-md-4 mt-10">
                            <label>Select Activity</label>
                        </div>
                        <div class="col-md-8" >
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="previousForm">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="previousFormFilter">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let option of optionsForActivity | search : previousFormFilter.value :'value';"
                                        [value]="option.key">
                                        {{option.value}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <!-- Close Product Form Selection -->
                    <!-- Start DataList -->
                    <div class="form-group row" *ngIf="showParentValueDD">
                        <div class="col-md-4">
                            <label class="col-form-label pt-8">Select Parent fields Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select #selectParentFieldVal formControlName="selectParentFieldValues"
                                    [(ngModel)]="selectParentvalues"
                                    (selectionChange)="changeparentFieldvalues($event.value)" multiple>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="selectParentFieldValue">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelectedParentFieldValues"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(selectParentFieldVal,allSelectedParentFieldValues)">
                                            Select All
                                        </mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick(selectParentFieldVal, 'allSelectedParentFieldValues')"
                                        *ngFor="let lookup of parentLookupValues | search : selectParentFieldValue.value :'lookupValue'; let i = index;"
                                        [value]="lookup.id">
                                        {{lookup.lookupValue}}
                                    </mat-option>
                                </mat-select>
                                
                                <mat-hint style="color: rgba(0,0,0,.54);">Select parent field values you want to use
                                </mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                    
                    <div class="form-group row" *ngIf="showEntityFieldLV && !isDisplayLinkedDataListField">
                        <div class="col-md-4">
                            <label class="col-form-label pt-8">Select Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select #selectVal [(ngModel)]="selectValues" [ngModelOptions]="{standalone: true}" multiple>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="selectValueFilter">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelectedValues"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(selectVal,allSelectedValues)">Select All
                                        </mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick(selectVal, 'allSelectedValues')"
                                        *ngFor="let lookup of selctedEntityFieldLV | search : selectValueFilter.value :'lookupValue'; let i = index;"
                                        [value]="lookup.id">
                                        {{lookup.lookupValue}}
                                    </mat-option>
                                </mat-select>
                                
                                <mat-hint style="color: rgba(0,0,0,.54);">{{isFieldTypeDataList ?'Select field values
                                    you want to use. If left blank, all list values will be displayed'
                                    :'Select field of the entity that can be selected'}}</mat-hint>
                            </mat-form-field>
                        </div>  
                    </div>
                
                    <div *ngIf="showDependentFieldCB">
                        <div class="form-group row mt-6">
                            <div class="col-md-4">
                                <label>Add Dependent Fields</label>
                            </div>
                            <div class="col-md-8">
                                <mat-checkbox #showDependentfieldCheckBox
                                    (change)='showDependentFieldsDD(showDependentfieldCheckBox.checked)'
                                    [checked]="flag_dependentcheckBox==true" [(value)]=flag_dependentcheckBox>
                                </mat-checkbox>&nbsp; Yes <br>
                                <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select to add dependent
                                    fields of entity field to the form</mat-hint>
                            </div>
                        </div>
                    </div> 

                    <!-- <div class="form-group row" *ngIf="showDependentFieldDD">
                        <div class="col-md-4">
                            <label class="col-form-label pt-8">Select Dependent fields</label>
                        </div>
                        <div class="col-md-8" style="width: 100%;">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100" style="width: 100%;">
                                <mat-select #select2 [(ngModel)]="selectDependentFields" [ngModelOptions]="{standalone: true}" multiple>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="selectDependentFieldFilter"></ngx-mat-select-search>
                                    </mat-option>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSelected"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="toggleAllSelection(select2)">Select All
                                        </mat-checkbox>
                                      </div>
                                    <mat-option (click)="optionClick(select2)" *ngFor="let dependentField of dependentFieldList | search : selectDependentFieldFilter.value :'attributeName'; let i = index;" [value]="dependentField.id">
                                        {{dependentField.attributeName}}
                                    </mat-option>
                                </mat-select>
                                
                                <mat-hint style="color: rgba(0,0,0,.54);">Select the dependent field to display in the form </mat-hint>
                            </mat-form-field>
                        </div>  
                    </div> -->
                    <!-- End DataList -->
                </div>
                <!-- Start Reference Document -->
                <div *ngIf="displayRefDoc">
                    <div class="row pt-3 pb-3">
                        <div class="col-md-4" style="padding-top:1.65rem !important">
                            <label class="col-form-label">Upload File</label>
                        </div>
                        <div class="col-md-8 pb-4">
                            <div class="mat-form-field">
                                <div class="mat-form-field-wrapper">
                                    <input id="fileInput" type="file" style="display:none;" multiple="" ktAutoTrimSpaces
                                        (change)="processFile($event)">
                                    <span> 
                                        <div class="artist-collection-photo ml-0 mb-4">
                                            <img width="90" height="100"
                                                src="./assets/media/svg/icons/General/DocumentUpload.svg"
                                                alt="image.jpg" class="img-thumbnail" (click)="clickFile()">
                                        </div>
                                    </span>
                                    <span id="selectedImages"></span>
                                </div>
                            </div>
                            <div class="mat-form-field-subscript-wrapper pt-3">
                                <mat-hint *ngIf="!isControlHasError('refDocument', 'required') && !uploadError"
                                    [ngStyle]="{'color':wrongFormat?'red':'rgba(0,0,0,.54)'}">Upload .jpeg, .png, .pdf,
                                    .xlsx, .docx, .pptx files only</mat-hint>
                                <mat-error *ngIf="isControlHasError('refDocument', 'required')">Reference document
                                    cannot be blank.</mat-error>
                                <mat-error *ngIf="uploadError">File {{uploadErrorFileName}} could not be uploaded.
                                    Please try again!</mat-error>
                            </div>    
                            <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise"
                                [fullScreen]="false" name="uploadRefDoc">
                                <!-- <p style="color: white">Uploading File(s)... </p>   -->
                            </ngx-spinner>                                  
                        </div>  
                    </div>
                </div>
                <!-- Close Reference Document -->
                <!-- Start Document upload -->
                <div *ngIf="isDocumentUpload">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Document Type</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select #select3 formControlName="documentType" multiple>
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #docTypeFilter ></ngx-mat-select-search>
                                    </mat-option>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="documentTypeAllSelected"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="toggleAllSelection(select3, documentTypeAllSelected)">Select All
                                        </mat-checkbox>
                                    </div>
                                    <mat-option (click)="optionClick(select3, 'documentTypeAllSelected')" 
                                    *ngFor="let type of documentTypes| search : docTypeFilter.value :'name';" [value]="type.id">
                                        {{type.name}}
                                    </mat-option>
                                    <mat-select-trigger>
                                        <span> {{editFieldForm.controls.documentType.value ?
                                            mapToValue(editFieldForm.controls.documentType.value): ''}}</span>
                                    </mat-select-trigger>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select allowed file types, blank defaults to
                                    all allowed.</mat-hint>
                                <mat-error *ngIf="isControlHasError('attributeType', 'required')">Document Type cannot
                                    be blank</mat-error>
                            </mat-form-field>
                        </div>  
                    </div>
                </div>
                <!-- End Document upload -->

                <!-- new single/multi select -->
                <div *ngIf="displaySingleSelectDiv || displayMultiSelectDiv" formArrayName="OptionsItems"
                    style="padding-top: 25px;">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options
                                <span class="mandatory-marker">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <table class="style" style="width: 100%;">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <div class="example-container" style="width: 100%;">
                                        <div cdkDropList
                                            [cdkDropListData]="editFieldForm.controls.OptionsItems.controls"
                                            class="example-list" (cdkDropListDropped)="drop($event)">
                                            <div class="example-box"
                                                *ngFor="let item of editFieldForm.controls.OptionsItems.controls; let i=index; last as isLast"
                                                [formGroupName]="i" cdkDrag>
                                                <!-- DRAG -->
                                                <td style="text-align: left;margin-left: 10px;" class="btn-drag">
                                                    <span>
                                                        <i class="fas fa-align-justify text-primary mr-5 icon-lg"></i>
                                                    </span>
                                                </td>
                                                <!-- OPTION -->
                                                <td style="width: 100%;">
                                                    <mat-form-field appearance="standard" class="inputbox1"
                                                        class="input-100">
                                                        <mat-label style="color: rgba(0,0,0,.54);">Option
                                                            <span class="mandatory-marker">*</span></mat-label>
                                                        <input id="option{{i}}" matInput #input4
                                                            formControlName="OptionVal" maxlength="500"
                                                            (keyup.enter)="addTable()">
                                                        <mat-hint *ngIf="isLast" style="color: rgba(0,0,0,.54);">
                                                            Enter unique values only. Press 'Enter' to add new value.
                                                        </mat-hint>
                                                        <mat-hint align="end" style="color: rgba(0,0,0,.54);">
                                                            {{input4.value?.length || 0}}/500
                                                        </mat-hint>
                                                        <mat-error *ngIf="item.controls.OptionVal.errors?.required">
                                                            Options cannot be blank.</mat-error>
                                                        <mat-error *ngIf="item.controls.OptionVal.errors?.unique">Enter
                                                            unique values for the options</mat-error>
                                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields
                                                        </mat-icon>
                                                        <!--<mat-error *ngIf="item.controls.OptionVal.invalid">
                                                    Unit name is required.        
                                                </mat-error>  -->
                                                    </mat-form-field>
                                                </td>
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                                <!-- MINUS BUTTON -->
                                                <td *ngIf="!disableattributeSingleName" style="text-align: right;">
                                                    <button
                                                        class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                                        <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                    </button>
                                                </td>
                                            </div>
                                        </div>
                                    </div>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- END::new single/multi select -->

                <div *ngIf="displaySingleSelectDivTable" formArrayName="OptionsItems">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options
                                <span class="mandatory-marker">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <table class="style">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr>
                                    <div class="example-container">
                                        <div cdkDropList
                                            [cdkDropListData]="editFieldForm.controls.OptionsItems.controls"
                                            class="example-list" (cdkDropListDropped)="drop($event)">
                                            <div class="example-box"
                                                *ngFor="let item of editFieldForm.controls.OptionsItems.controls; let i=index; last as isLast"
                                                [formGroupName]="i" cdkDrag>
                                                <!-- DRAG -->
                                                <td style="text-align: left;margin-left: 10px;" class="btn-drag">
                                                    <span>
                                                        <i class="fas fa-align-justify text-primary mr-5 icon-lg"></i>
                                                    </span>
                                                </td>
                                                <!-- VALUE -->
                                                <td>
                                                    <mat-form-field appearance="standard" class="inputbox1">
                                                        <mat-label style="color: rgba(0,0,0,.54);">Value</mat-label>
                                                        <input id="option{{i}}" matInput #input4
                                                            formControlName="OptionVal" maxlength="500"
                                                            (keyup.enter)="addTable()">
                                                        <mat-hint *ngIf="isLast" style="color: rgba(0,0,0,.54);">
                                                            Enter unique values only. Click Enter to add new value
                                                        </mat-hint>
                                                        <mat-hint align="end" style="color: rgba(0,0,0,.54);">
                                                            {{input4.value?.length || 0}}/500</mat-hint>
                                                        <mat-error *ngIf="item.controls.OptionVal.errors?.required">
                                                            Value can not be blank</mat-error>
                                                        <mat-error *ngIf="item.controls.OptionVal.errors?.unique">
                                                            Enter unique values for the options
                                                        </mat-error>
                                                        <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields
                                                        </mat-icon>
                                                        <!--<mat-error *ngIf="item.controls.OptionVal.invalid">
                                                    Unit name is required.        
                                                </mat-error>  -->
                                                    </mat-form-field>
                                                </td>
                                                <!-- STAGE -->
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                                <td>
                                                    <mat-form-field appearance="standard"
                                                        class="mat-form-field-fluid inputSelect1 ">
                                                        <mat-label style="color: rgba(0,0,0,.54);">Stage</mat-label>
                                                        <mat-select formControlName="stageId">
                                                            <mat-option>
                                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stageFilterType">
                                                                </ngx-mat-select-search>
                                                            </mat-option>
                                                            <mat-option
                                                                *ngFor="let StageType of stageTypeList| search : stageFilterType.value :'value'"
                                                                [value]="StageType.id">
                                                                {{StageType.value}}
                                                            </mat-option>

                                                        </mat-select>
                                                        <mat-error>Please select one.</mat-error>
                                                    </mat-form-field>
                                                </td>
                                                <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                                <!-- MINUS BUTTON -->
                                                <td style="text-align: right;">
                                                    <button
                                                        class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                                        <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                    </button>

                                                </td>
                                            </div>
                                        </div>
                                    </div>
                                </tr>
                                <tr>
                                    <td style="text-align: right;">    
                                        <!--<button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" *ngIf="editFieldForm.controls.OptionsItems.controls.length > 1">
                                            <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                        </button>  -->
                                        <button
                                            class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                            <i class="fas fa-plus" (click)="addTable()"></i>
                                        </button>
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="displaySingleContactDivTable" formArrayName="OptionsItems">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Options</label>
                        </div>
                        <div class="col-md-8">
                            <table class="style">
                                <tr>
                                    <th></th>
                                    <th></th>
                                </tr>
                                <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">

                                    <td>
                                        <mat-form-field appearance="standard" class="inputbox">
                                            <mat-label style="color: rgba(0,0,0,.54);">Value</mat-label>
                                            <input matInput #input4 formControlName="OptionVal" maxlength="500"
                                                ktAutoTrimSpaces>
                                            <mat-hint align="end" style="color: rgba(0,0,0,.54);">{{input4.value?.length
                                                || 0}}/500</mat-hint>
                                            <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                                            <mat-error>Value can not be blank</mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td>
                                        <mat-form-field appearance="standard"
                                            class="mat-form-field-fluid inputSelect form-padding-top-10">
                                            <mat-label style="color: rgba(0,0,0,.54);">Primary Contact</mat-label>
                                            <mat-select formControlName="stageId">
                                                <mat-option>
                                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="contactFilterType">
                                                    </ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option
                                                    *ngFor="let contact of primaryContactTypeList| search : contactFilterType.value :'value'"
                                                    [value]="contact.id">
                                                    {{contact.value}}
                                                </mat-option>

                                            </mat-select>
                                            <mat-error>Please select one.</mat-error>
                                        </mat-form-field>
                                    </td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>

                                    <td style="text-align: right;">
                                        <button
                                            class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                            <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                        </button>

                                    </td>

                                </tr>
                                <tr>
                                    <td></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td></td>
                                    <td>&nbsp;&nbsp;&nbsp;&nbsp; </td>
                                    <td style="text-align: right;">
                                        <!--<button class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3" *ngIf="editFieldForm.controls.OptionsItems.controls.length > 1">
                                        <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                    </button>  -->
                                        <button
                                            class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary mr-3">
                                            <i class="fas fa-plus" (click)="addTable()"></i>
                                        </button>
                                    </td>
                                </tr>

                            </table>
                        </div>
                    </div>
                </div>

                <div *ngIf="displayDateValueDiv">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Value Type
                                <span class="mandatory-marker">*</span>
                            </label>
                        </div>

                        <div class="col-md-8">
                            <mat-radio-group formControlName="dateValueType" fxLayout="column" fxLayoutGap=".25rem"
                                [(ngModel)]="dateValueType">
                                <mat-radio-button [value]="'Standard'">Standard</mat-radio-button>
                                <mat-radio-button [value]="'Custom'">Custom</mat-radio-button>
                                <!--   <mat-hint>Select the value type for date field</mat-hint>  -->
                                <mat-error class="form-error" *ngIf="isControlHasError('dateValueType', 'required')">
                                    Please select one.</mat-error>
                            </mat-radio-group>
                        </div>
                    </div>
                </div>
                <div *ngIf="isDisplayStandard()">

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Value
                                <span class="mandatory-marker">*</span>
                            </label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="standardTypeValue"
                                    (selectionChange)="changeStd($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="standardFilterType">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let standardTypeValue of standardTypelist; let i = index;"
                                        [value]="standardTypeValue.id">
                                        {{standardTypeValue.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select standard date field from the list
                                </mat-hint>
                                <mat-error class="form-error"
                                *ngIf="isControlHasError('standardTypeValue', 'required')">Please ensure you have made a selection.</mat-error>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <div *ngIf="isDisplayCustom()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Previous Dates Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="previousDateValue" ktStrictNumberOnlyDirective
                                    [(ngModel)]="previousDateValue">
                                <mat-hint>Only numeric values are allowed. Number of days, blank defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Future Dates Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="futureDateValue" ktStrictNumberOnlyDirective
                                    [(ngModel)]="futureDateValue">
                                <mat-hint>Only numeric values are allowed. Number of days, blank defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                
                <!-- options alphabetically -->
                <div *ngIf="displaySingleSelectDiv || displayMultiSelectDiv" style="padding-top: 20px;">
                    <div class="form-group row mt-2">
                        <div class="col-md-4">
                            <label>Show Options Alphabetically</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsAlphabetically(checkbox.checked)'
                                [checked]="flag_alphabeticOptions==true" [(value)]=flag_alphabeticOptions>
                            </mat-checkbox>&nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">
                                Selecting this option will ignore the above sequencing of options
                            </mat-hint>
                        </div>
                    </div>
                </div>
                <!-- END::options alphabetically -->

                <!-- Allow Product Quantity Transfer -->
                <div *ngIf="isEnableForCompanies==1 && productFormFlag" class="form-group row mt-6">
                    <hr class="customise-hr">
                    <div class="col-md-4 mt-4">
                        <label>Allow Product Quantity Transfer</label>
                    </div>
                    <div class="col-md-8 mt-4">
                        <mat-checkbox (change)="onCheckboxChange($event)" formControlName="quantityTransferCheckbox"></mat-checkbox>&nbsp; Yes <br>
                    </div>
                    <div *ngIf="editFieldForm.get('quantityTransferCheckbox')?.value" class="form-group row ml-1 mr-1" >
                        <div class="col-md-4 mt-10">
                            <label>{{selectCompanyField}}</label>
                        </div>
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="customerTransfer">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="transferOptionFilter">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let option of optionsForQuantityTransfer | search : transferOptionFilter.value :'name';"
                                        [value]="option.id">
                                        {{option.name}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Customer on which activity will be performed to be considered as</mat-hint>
                                <mat-error *ngIf="isControlHasError('customerTransfer', 'required')">Please select one.</mat-error>
                            </mat-form-field>
                        </div>
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Select {{editFieldForm.get('customerTransfer')?.value == 1 ? 'Transfer To' : 'Transfer From'}}</label>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4 mt-6">
                                    <label>Entity</label>
                                    <mat-form-field class="mat-form-field-fluid input-100" >
                                        <mat-select formControlName="entityTransfer" (selectionChange)="changeEntityForQuantityTransfer($event.value)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="entityOptionFilter">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let entity of entityOptionsForQuantityTransfer | search : entityOptionFilter.value :'name';" [value]="entity.id">
                                                {{entity.name}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 mt-6">
                                    <label>Entity Field</label>
                                    <mat-form-field class="mat-form-field-fluid input-100" >
                                        <mat-select formControlName="entityFieldTransfer" (selectionChange)="changeEntityFieldForQuantityTransfer($event.value, false)">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="entityFieldOptionFilter">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let field of entityFieldListForQuantityTransfer | search : entityFieldOptionFilter.value :'attributeName';" [value]="field">
                                                {{field.attributeName}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('entityFieldTransfer', 'required')">Please select one.</mat-error>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-4 mt-6">
                                    <label>Value</label>
                                    <mat-form-field class="mat-form-field-fluid">
                                        <mat-select formControlName="entityFieldValueTransfer" #select1  multiple (selectionChange)="changeValueForQuantityTransfer()">
                                            <mat-option>
                                                <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="valueFilter">
                                                </ngx-mat-select-search>
                                            </mat-option>
                                            <div class="select-all">
                                                <mat-checkbox [(ngModel)]="allSelectedOption"
                                                    [ngModelOptions]="{standalone: true}"
                                                    (change)="toggleAllSelection(select1, allSelectedOption)">Select All
                                                </mat-checkbox>
                                            </div>
                                            
                                            <mat-option *ngFor="let value of valuesForQuantityTransfer | search : valueFilter.value :'name';" [value]="value.id">
                                                {{value.name}}
                                            </mat-option>
                                        </mat-select>
                                        <mat-error *ngIf="isControlHasError('entityFieldValueTransfer', 'required')">Please select value</mat-error>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mt-8" *ngIf="editFieldForm.get('entityTransfer')?.value == 5">
                            <label>Show values</label>
                        </div>
                        <div class="col-md-8 mt-8" *ngIf="editFieldForm.get('entityTransfer')?.value == 5">
                            <mat-radio-group formControlName="showValues" fxLayout="column" fxLayoutGap=".25rem">
                            <mat-radio-button [value]="'1'">Up in the hierarchy</mat-radio-button>
                            <mat-radio-button [value]="'2'">Down the hierarchy</mat-radio-button>
                            <mat-radio-button [value]="'3'">Both</mat-radio-button>
                            <mat-radio-button [value]="'4'">Any user</mat-radio-button>
                            </mat-radio-group>
                            <mat-error *ngIf="isControlHasError('showValues', 'required')">Please select one.</mat-error>
                        </div>
                    </div>                        
                    <hr class="customise-hr">
                </div>
                <!-- Allow Product Quantity Transfer END -->

                <div *ngIf="!displayRefDoc && !normalText && !headerText && !pageSeperator" style="padding-top: 20px;">
                    <div class="form-group row mt-2">
                        <div class="col-md-4">
                            <label>Mandatory</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox [disabled]="disableMandatoryCheck"
                                (change)='showOptionsEdit(checkbox.checked)' [checked]="flag_Value==true"
                                [(value)]=flag_Value>
                            </mat-checkbox>&nbsp; Yes <br>
                            <mat-hint *ngIf="!isVideoUpload && disableMandatoryCheck == false"
                                style="color: rgba(0,0,0,.54);" class="form-error">
                                Select to make this a mandatory field</mat-hint>
                            <mat-hint *ngIf="isVideoUpload && disableMandatoryCheck == false"
                                style="color: rgba(0,0,0,.54);" class="form-error">
                                Select to make this a mandatory field, only .mp4, .mov file formats are allowed
                            </mat-hint>
                        </div>
                    </div>
                </div>

                <div *ngIf="addValidationField">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Add Validation</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox (change)='allowRgxValidationCB($event)'
                            [checked]="addValidationFieldFlag==true" [(value)]=addValidationFieldFlag>
                            </mat-checkbox>&nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">
                                Select if you want to add validation to this field
                            </mat-hint>
                        </div>
                    </div>
                
                    <div *ngIf="enableRegexField">
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Enter Regex
                                    <span class="mandatory-marker">*</span>
                                </label>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="standard" class="input-100">
                                    <input matInput #input1 formControlName="enterRgx" ktAutoTrimSpaces>
                                    <mat-hint style="color: rgba(0,0,0,.54);">To know more on Regex <a
                                            href="https://en.wikipedia.org/wiki/Regular_expression" target="_blank">click here</a>
                                    </mat-hint>
                                    <mat-error *ngIf="isControlHasError('enterRgx', 'required')">Field Name cannot be
                                        blank.</mat-error>
                                    <mat-error *ngIf="isControlHasError('enterRgx', 'invalidRegex')">
                                        The entered regex is invalid.
                                    </mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                
                    <div *ngIf="enableRegexField">
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Error Message
                                    <span class="mandatory-marker">*</span>
                                </label>
                            </div>
                            <div class="col-md-8">
                                <mat-form-field appearance="standard" class="input-100">
                                    <input matInput #input1 formControlName="rgxErrorMessage" ktAutoTrimSpaces>
                                    <mat-hint style="color: rgba(0,0,0,.54);">Enter the Error Message to be shown upon Regex validation
                                        failure
                                    </mat-hint>
                                    <mat-error *ngIf="isControlHasError('rgxErrorMessage', 'required')">Field Name cannot be
                                        blank.</mat-error>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                
                </div>
                    
                <div *ngIf="isDisplayNumeric">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Allow Decimals</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #decimalCB (change)='allowDecimalsCB(decimalCB.checked)' [checked]="decimalFlag">
                            </mat-checkbox>&nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">
                                Select if this field should allow decimal values
                            </mat-hint>
                        </div>
                    </div>
                </div>  

                <div *ngIf="isDisplayText">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsUnique(checkbox.checked)'
                                [checked]="unique_Value==true" [(value)]=unique_Value>
                            </mat-checkbox> &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if this field should
                                only allow unique values</mat-hint>

                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Type of Characters</label>
                        </div>
                        <!-- <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="characterType" >
                                    
                                    <mat-option value=0>All</mat-option>
                                    <mat-option value=2>Alpha-numeric</mat-option>
                                    <mat-option value=3>Alphabets</mat-option>
                                    
                                </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field </mat-hint>
                        </mat-form-field>
                    </div>  -->
                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="characterType"
                                    (selectionChange)="changeCharacterType($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="attributecharacterType">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let characterType of characterTypeList| search : attributecharacterType.value :'value'"
                                        [value]="characterType.id">
                                        {{characterType.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in
                                    this field</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Min Characters</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="minCharacters" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter minimum no of characters, blank defaults
                                    to no limit</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Characters</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="maxCharacters" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter maximum no of characters, blank defaults
                                    to no limit</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayImage">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Allow Browsing</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptions(checkbox.checked)' [checked]="browseVal==true"
                                [(value)]=browseVal>
                            </mat-checkbox> <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if user can choose image
                                from gallery</mat-hint>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Image Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="maxImagesAllowed" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Number of maximum images to be taken, blank
                                    defaults to no limit</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <!-- document upload -->
                <div *ngIf="isDocumentUpload">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Documents Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="maxImagesAllowed" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Number of maximum documents to be taken, blank
                                    defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>
                
                <!-- video upload -->
                <div *ngIf="isVideoUpload">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Max Videos Allowed</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="maxImagesAllowed" ktStrictNumberOnlyDirective>
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Number of maximum videos that can be uploaded,
                                    blank defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>                    
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayEmail">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox [disabled]="disableUniqueValCheck"
                                (change)='showOptionsUnique(checkbox.checked)' [checked]="unique_Value==true"
                                [(value)]=unique_Value>
                            </mat-checkbox> &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="!disableUniqueValCheck" class="form-error">
                                Select if this field should only allow unique values</mat-hint>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumeric">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>Unique Values</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox (change)='showOptionsUnique(checkbox.checked)'
                                [checked]="unique_Value==true" [(value)]=unique_Value>
                            </mat-checkbox> &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">Select if this field should
                                only allow unique values</mat-hint>

                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Validation Type</label>
                        </div>
                        <div class="col-md-8">
                            <!--  <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="numericValidationType" (selectionChange)="changeValidation($event.value)">
                                    <mat-option value="1">Select</mat-option>
                                    <mat-option value="2">Value</mat-option>
                                    <mat-option value="3">Digit</mat-option>                                    
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in this field (Optional)</mat-hint>
                            </mat-form-field>  -->

                            <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="numericValidationType"
                                    (selectionChange)="changeValidation($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="attributenumericValidationType">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option
                                        *ngFor="let numericValidationType of numericValidationTypeList| search : attributenumericValidationType.value :'value'"
                                        [value]="numericValidationType.id">
                                        {{numericValidationType.value}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select what type of data will be entered in
                                    this field.</mat-hint>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumericValue()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Value</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="numericMinValue" [ktStrictNumberOnlyDirective]="decimalFlag">
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter minimum no of value, blank defaults to
                                    no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Value</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="numericMaxValue" [ktStrictNumberOnlyDirective]="decimalFlag">
                                <mat-hint style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter maximum no of value, blank defaults to
                                    no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumericDigit()">
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Minimum Digits</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="minDigits" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="!decimalFlag" style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter minimum no of digits, blank defaults
                                    to no limit.</mat-hint>
                                <mat-hint *ngIf="decimalFlag" style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter minimum no. of digits before decimal, 
                                    blank defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Maximum Digits</label>
                        </div>

                        <div class="col-md-8">
                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput formControlName="maxDigits" ktStrictNumberOnlyDirective>
                                <mat-hint *ngIf="!decimalFlag" style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter maximum no of digits, blank defaults
                                    to no limit.</mat-hint>
                                <mat-hint *ngIf="decimalFlag" style="color: rgba(0,0,0,.54);">Only numeric values are allowed. Enter maximum no. of digits before decimal, 
                                    blank defaults to no limit.</mat-hint>
                                <mat-icon matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplaySelectionLock">

                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Selection Type</label>
                        </div>
                        <div class="col-md-8">
                            <!-- <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                                <mat-select formControlName="selectionType" (selectionChange)="changeSelectionType($event.value)">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="selectionTypeValue" ></ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let attrType of selectionTypeList | search : selectionTypeValue.value :'typeName'" [value]="attrType.id">
                                        {{attrType.typeName}}
                                    </mat-option>
                                </mat-select>
                                <mat-hint style="color: rgba(0,0,0,.54);">Select from the list</mat-hint>
                            </mat-form-field> -->

                            <mat-form-field appearance="standard" class="input-100">
                                <input matInput #input1 maxlength="50" formControlName="selectionType"
                                    [(ngModel)]="selectionType">
                                <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div *ngIf="isDisplayNumeric && !decimalFlag">
                    <div class="form-group row mt-6">
                        <div class="col-md-4">
                            <label>OTP Validation</label>
                        </div>
                        <div class="col-md-8">
                            <mat-checkbox #checkbox
                                (change)='showOptionsOtpValid(checkbox.checked)' [checked]="otp_validation==true"
                                [(value)]=otp_validation>
                            </mat-checkbox> &nbsp; Yes <br>
                            <mat-hint style="color: rgba(0,0,0,.54);" class="form-error">
                                Select if value of this field should be verified through OTP</mat-hint>
                        </div>
                    </div>
                </div>

                <div *ngIf="disableSelect == false" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Status
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="mat-form-field-fluid input-100">
                            <mat-select formControlName="status" (selectionChange)="OpenAlert($event.value)">
                                <mat-option value="1">Active</mat-option>
                                <mat-option value="0">Inactive</mat-option>
                            </mat-select>
                            <mat-hint style="color: rgba(0,0,0,.54);" *ngIf="disableSelect == false">Select to change
                                the status</mat-hint>
                        </mat-form-field>
                    </div>
                </div>

                <div *ngIf="disableSelect" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Status</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100">
                            <input matInput formControlName="status" [(ngModel)]="statusVal">
                            <mat-icon matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-12">
                        <span style="color:red">{{errorMes}}</span>
                    </div>

                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary" [disabled]='disabled' (click)="Save()">Save</button>
            </div>
        </form>
    </div>
</div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='edit-field'>  
    <p style="color: rgb(0, 0, 0)">Fetching Data... </p>  
</ngx-spinner>
<ngx-spinner size="default" type="ball-spin-clockwise" name="update-data">  
    <p style="color: white">Updating Data... </p>  
</ngx-spinner>
