// Angular
import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CompanyDashboardService } from '../../../../services/company-dashboard.service';
import { AddActivityComponent } from '../../../../../shared/add-activity/add-activity.component';
import { EventEmitterService } from '../../../../../shared/services/event-emitter.service';
import { ManageActivitiesService } from '../../../../../manage-activities/services/manage-activities.service';
import { AddNewCompanyComponent } from '../../../../../shared/custom-add/add-new-company/add-new-company.component';
import { MenuConstants, MenuPermissionService } from 'src/app/views/pages/shared/services/menu-permission.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppIntegrationService } from 'src/app/views/pages/shared/services/app-integration.service';
import { DialerComponent } from 'src/app/views/pages/dialer/dialer.component';
import * as moment from 'moment';
import { AppConfigurationFieldsEnum } from 'src/app/views/pages/shared/constant/AppConfigurationFieldEnum.constants';
import { EditApprovalComponent } from 'src/app/views/pages/shared/edit-approval/edit-approval.component';
import { DialerDashboardService } from 'src/app/views/pages/dialer-dashboard/Services/DialerDashboardService.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
}

const DEFAULT_CONFIG: FilePreviewDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel'
}

@Component({
  selector: 'kt-widget2-recent-activities',
  templateUrl: './widget2-recent-activities.component.html',
  styleUrls: ['./widget2-recent-activities.component.scss'],
  providers: [DatePipe]
})
export class Widget2RecentActivitiesComponent implements OnInit,OnChanges {
  @ViewChild('fileButton') fileButton: ElementRef<HTMLAnchorElement>;
  @Input() cssClasses = '';
  @Input() public parentData;
  @Input() public companyInfoData;
  @Input() public tabType;
  @Input() public dialerFlag = false;
  @Input() isActCollaspsed: string;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  user = JSON.parse(localStorage.getItem('currentUser'));
  activityList: any;
  noDataFound: false;
  modalReference: any;
  moduleString: string;
  errorMessage: string;
  isError = false;
  actList: any;
  companyId: number;
  rowData: any;
  estimateModuleId: string|number;
  public isCollapsed = false;
  public callDetail:any={};
  private subscriptions: Subscription[] = [];


  constructor(
    private modalService: NgbModal,
    private _cds: CompanyDashboardService,
    private SpinnerService: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private _mas: ManageActivitiesService,
    private datePipe: DatePipe,
    public snackBar: MatSnackBar,
    private ds:DialerDashboardService,
    private _ai:AppIntegrationService,
    private http: HttpClient

  ) { }
  ngOnChanges(changes: SimpleChanges): void {

    if (changes.companyInfoData && this.companyInfoData) {
      this.callDetail.callerFieldName=this.companyInfoData?.callerIdDetails?.key;
      this.callDetail.callerIdNumber=this.companyInfoData?.callerIdDetails?.value;
      this.callDetail.ivrCall=this.companyInfoData?.ivrCall
    }
    if (changes['isActCollaspsed'] && changes['isActCollaspsed'].firstChange==false) {
      this.isCollapsed=true;
    }
  }

  ngOnInit(): void {

    this.moduleString = 'company';
    this.SpinnerService.show('dashboard-activity');
    if(this.parentData){
      this.fetchCompanyActivities(this.selectedProject, this.parentData);
    }
    this.subscriptions.push(this.ds.entityId$.subscribe((entityId) => {
      if(entityId){
        this.parentData=entityId;
        this.fetchCompanyActivities(this.selectedProject, this.parentData);
      }
      })
    )
    
    
    this.getAllActivitiesList();
    
/*
    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.activityResult(data);
      });
    } */
  }

  fetchCompanyActivities(projectId: number, companyId: number){
    this.isError = false;
    this.errorMessage = "";
    this.activityList = [];
    this._cds.getCompanyActivities(projectId, companyId).subscribe(
      res=>{
        this.actList = res['responseData'];
        var winProbPrev: number;
        var probability: string;
        var textClass: string;
        var borderColor: string;
        var bgLightClass: string;
        var lableLight: string;
        var iconClass: string;
        var displayImage: boolean;
        var statusLable: string;
        for(let res of this.actList.reverse()) {
          /** Date Format */
          if(res.activityDate == null){
            res.created = "";
          } else {
            let dateCreated = new Date(res.activityDate);
            res.created = this.datePipe.transform(dateCreated, 'dd-MMM-yyyy HH:mm:ss')+" "+res.timeZone;
          }

          /** For set percentage */
          if(res.status != 'N/A'){
            if(res.winProbability > winProbPrev) {
              probability = 'Increased';
              lableLight = 'label-light-success';
              iconClass = 'fa-arrow-up text-success'
            } else if(res.winProbability < winProbPrev){
              probability = 'Decreased';
              lableLight = 'label-light-danger';
              iconClass = 'fa-arrow-down text-danger'
            } else {
              probability = 'No-Change';
              lableLight = 'label-light-primary';
              iconClass = 'fa-arrows-alt-h text-primary'
            }
          }

          /** For set row color */
          if(res.status === 'Won'){
            textClass = 'text-success';
            bgLightClass = 'bg-light-success';
            borderColor = 'timeline-media-success';
            statusLable = 'label-light-success';
          } else if(res.status === 'Lost'){
            textClass = 'text-danger';
            bgLightClass = 'bg-light-danger';
            borderColor = 'timeline-media-danger';
            statusLable = 'label-light-danger';
          } else if(res.activityTypeName === 'Estimate'){
            textClass = 'text-warning';
            bgLightClass = 'bg-light-warning';
            borderColor = 'timeline-media-warning';
            statusLable = 'label-light-primary';
          } else if(res.activityTypeName === 'Order'){
            textClass = 'text-success';
            bgLightClass = 'bg-light-success';
            borderColor = 'timeline-media-success';
            statusLable = 'label-light-primary';
          } else {
            textClass = 'text-primary';
            bgLightClass = 'bg-light-primary';
            borderColor = 'timeline-media-primary';
            statusLable = 'label-light-primary';
          }


          /** For set display image */
          if(res.images.length > 0) {
            displayImage = true;
          } else {
            displayImage = false;
          }

          res.probability = probability;
          res.lableLight = lableLight;
          res.iconClass = iconClass;
          res.textClass = textClass;
          res.bgLightClass = bgLightClass;
          res.borderColor = borderColor;
          res.displayImage = displayImage;
          res.statusLable = statusLable;
          if(res.status != 'N/A'){
            winProbPrev = res.winProbability;
          }
        }
        this.actList.reverse();
        this.activityList = this.actList;
        this.SpinnerService.hide('dashboard-activity');
      },
      err=>{
        this.isError = true;
        this.errorMessage = "Unable to fetch required details. Please try again.";
        console.log(err);
        this.SpinnerService.hide('dashboard-activity');
      }
    )
  }
/*
  AddActivity() {
    console.log(this.parentData);
    const ref= this.modalReference = this.modalService.open(AddActivityComponent,
      {  centered: false,
          size : 'xxs'
      }
    );
    ref.componentInstance.companyId=this.parentData;
  }
*/


AddActivity(activityId:number) {   
  const _saveMessage = `Activity has been saved.`;
  const ref=this.modalReference = this.modalService.open(AddNewCompanyComponent,
    {  
      centered: false,
      size : 'lg',
      backdrop : 'static' 
    });

  ref.componentInstance.moduleType = activityId;
  ref.componentInstance.flag = this.moduleString;
  ref.componentInstance.activityFlag = true;
  ref.componentInstance.companyId = this.parentData;
  ref.componentInstance.estimateModuleId=this.estimateModuleId;
  ref.componentInstance.companyInfoData=this.companyInfoData;


  ref.result.then((result) => {
    this.activityResult(result);
  });

}

activityResult(result: string) {
  if(result === 'SUCCESS'){
    this.SpinnerService.show('dashboard-activity');
    this.fetchCompanyActivities(this.selectedProject, this.parentData);
  }
  
}

/*
  activityResult(data: number) {
    this.companyId = data;
    console.log("CompanyId-1 : "+this.companyId);
    
    this.SpinnerService.show('dashboard-activity');
    this.fetchCompanyActivities(this.selectedProject, this.companyId);
  }
*/
  getAllActivitiesList(){
    this._mas.getAdditionalFilteredActivities(this.selectedProject, 2, this.parentData).subscribe(
      data=>{
        this.rowData =data['responseData'].filter(ele=>{
          return ele.allowForCompanies > 0 && ele.active == 1;
        })
        data['responseData'].forEach(res=>{  
         if(res.activityName == 'Estimate') {
           this.estimateModuleId=res.activityId;
         }        
       })
      }
      
    )
  }

  downloadZip(activityData){
    this.SpinnerService.show('download-report');
    this._cds.getFiles(this.selectedProject,activityData.actTypeId ,activityData.activityId).subscribe(
      res=>{
        const link = document.createElement('a');
        link.href = res['responseData'];
        link.click();
        this.SpinnerService.hide('download-report');
      },
      err=>{
        this.errorMessage = "Unable to Download File. Please try again.";
        console.log(err);
        this.SpinnerService.hide('download-report');
      }
    )
  }

  call(data: any) {

    let paramsDetail = {}
    let url = ''
    let isUserConfigured:boolean=false;

    if (data.ivrCall == AppConfigurationFieldsEnum.OZONETEL_APP_ID) {
      this.SpinnerService.show();
      this._ai.getConfigurationDetails(this.selectedProject, data.ivrCall).subscribe(res => {
        if (res['responseData'].configFieldSaveValue.length == 0) {
          this.snackBar.open('App Configuration data not found', '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          return;
        }
        res['responseData'].configFieldSaveValue.forEach(element => {
          if (element.fieldId == AppConfigurationFieldsEnum.API_KEY) {
            paramsDetail['api_key'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.USER_NAME) {
            paramsDetail['username'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.AGENT_ID) {
            if(element.fieldValue.split(",").includes(this.user?.userId?.toString())){
              isUserConfigured=true;
              paramsDetail['agentID'] = this.user.userId;
            }
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.CAMPAIGN_NAME) {
            paramsDetail['campaignName'] = element.fieldValue;
          }
          else if (element.fieldId == AppConfigurationFieldsEnum.CLICK_TO_CALL_URL) {
            url = element.fieldValue;
          }
        });
        paramsDetail['customerNumber'] = data.callerIdNumber || "";
        paramsDetail['uui'] = "P"+this.selectedProject+"M2"+"E"+this.parentData;
        paramsDetail['format'] = "json";
        if(!isUserConfigured){
          this.snackBar.open(this.user?.firstName+" "+this.user?.lastName+" is not configured for IVR call", '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
          return;
        }
        this.ivrManualDial(url, paramsDetail);
      }, error => {
        this.snackBar.open('Unable to load configuration data. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      });

    }

  }

  private ivrManualDial(url: string, paramsDetail: {}) {
    this._ai.agentManualDial(url, paramsDetail).subscribe(res => {
      this.SpinnerService.hide();
      if (res['status'] == 'queued successfully') {
        this.snackBar.open('Call intiated successfully', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
      } else {
        this.snackBar.open(res['status'], '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        return;
      }

    }, error => {
      this.snackBar.open('Unable to configure call. Please try again.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    });
  }

  viewActivity(activityData) {
		const ref = this.modalService.open(EditApprovalComponent,
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      }
    );
    ref.componentInstance.editId=activityData.activityId;
    ref.componentInstance.moduleType=activityData.actTypeId;
    ref.componentInstance.isAllowEditing=false;
    ref.componentInstance.actionOnDate=activityData.activityDate;
    ref.componentInstance.isCompleted=true;
    ref.componentInstance.activityTypeName=activityData.activityTypeName;
    ref.componentInstance.isFromCompanyDashboard=true;
    ref.componentInstance.companyInfoData=this.companyInfoData;
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
