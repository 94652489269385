import { ListType } from "../contact-setting/models/list-type.model";

export class ReportBuilderUtil {

  public static getNewLabelOnMeasureChange(oldLabel: string, selectedMeasureId: number, measureList: ListType[]): string {
    let newLabel = oldLabel;
    const selectedMeasure = measureList.find(x => x.id === selectedMeasureId);
    const regexString = measureList.map(x => x.value).join("|");
    const regex = new RegExp("({{)(" + regexString + ")(}})");
    if (selectedMeasure) {
      if (regex.test(oldLabel)) {
        newLabel = oldLabel.replace(regex, `{{${selectedMeasure.value}}}`);
      } else {
        newLabel = `${oldLabel} - {{${selectedMeasure.value}}}`;
      }
    }
    return newLabel;
  }

  public static isDuplicateFieldAllowed(axis: AxisType, reportType: number): boolean {
    const allowed = true;
    const notAllowed = false;
    if (([1, 4, 5].includes(reportType) && axis === AxisType.Y) || (reportType === 2 && axis === AxisType.X)) {
      return allowed;
    }
    return notAllowed;
  }
}

export enum AxisType {
  X = 1,
  Y = 2,
  COL = 3
}

export enum ReportType {
  VERTICAL_BAR = 1,
  HORIZONTAL_BAR = 2,
  PIE_CHART = 3,
  LINE_CHART = 4,
  TABLE = 5,
  MATRIX = 6
}