export enum DaysEnum {

   SUN = 1,
   MON = 2,
   TUE = 3,
   WED = 4,
   THU = 5,
   FRI = 6,
   SAT = 7

}