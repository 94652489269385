import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-delete-relation-dialog',
  templateUrl: './delete-relation-dialog.component.html',
  styleUrls: ['./delete-relation-dialog.component.scss']
})
export class DeleteRelationDialogComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
