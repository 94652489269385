import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-edit-cell-renderer',
  templateUrl: './edit-cell-renderer.component.html',
  styleUrls: ['./edit-cell-renderer.component.scss']
})
export class EditCellRendererComponent implements OnInit {

  data:any;
  params:any;
  displayRelation:boolean;
  disabledRelation:boolean;
  openAccessSetting = false;

  constructor() { }

  agInit(params){
    this.displayRelation=false;
    this.params = params;
    this.data = params.value;
    if(this.params.data.systemAttribute?.id == 60){
      this.disabledRelation = true;
    }
    if((params.data.fieldSpec ==1 || params.data.fieldSpec ==2 || (params.data.systemAttribute?.id>6 && params.data.systemAttribute?.id<10)) && params.data.attributeType.id!=12){
      this.displayRelation=true;
    }
    if(params.data.moduleType > 100 && params.data.systemAttribute!=null && ((params.data.systemAttribute.id==26) ||
      (params.data.systemAttribute.id==29) || (params.data.systemAttribute.id==30) || (params.data.systemAttribute.id==31) || (params.data.systemAttribute.id==32) ||
      (params.data.systemAttribute.id==40))){
        this.displayRelation=false;
    }
    
    if(params.data.moduleType == 1 || params.data.moduleType == 2 || params.data.moduleType == 3 || params.data.moduleType >= 100) {
      this.openAccessSetting = true;

      //Disabling Visiblity icon for Child/Dependent Field and some standard fields of Customer, Contact, Opportunity
      if(this.params.data.parentId != 0 || 
      [11, 5, 6, 25, 26, 27].includes(this.params.data.systemAttribute?.id)) {
        this.openAccessSetting = false;
      }
    }
  }

  ngOnInit(): void {
  }

  public invokeParentMethod() {
    this.params.context.componentParent.EditCustomField(this.params.data);
  }

  public invokeRelationMethod() {
    this.params.context.componentParent.editRelationship(this.params.data);
  }

  public openAccessModal() {
    const rowData = this.params.context.componentParent.rowData;
    this.params.context.componentParent.fieldVisibilitySetting(this.params.data, rowData);
  }

}
