import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { ConfigurationAssistantModel } from '../models/configuration-assistant.model'


@Injectable({
    providedIn: 'root'
  })
export class ConfigurationAssistantService {

    constructor(private http:HttpClient) { }

    getAllConfigAssistant(projectId:number){
        return this.http.get<ConfigurationAssistantModel[]>(
          environment.baseUrl + '/setting/api/configuration/assistant?projectId='+projectId);
      }

}
