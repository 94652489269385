import { AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
	/**
	 * Check matching password with confirm password
	 * @param control AbstractControl
	 */
	static MatchPassword(control: AbstractControl) {
		const email = control.get('username').value;
		const password = control.get('password').value;
		const confirmPassword = control.get('confirmPassword').value;
		if(confirmPassword== '' || confirmPassword == null){
			return null;
		}
		else if(email == password){
			control.get('password').setErrors({password: true});
		}
		else if (password != '' && password != confirmPassword) {
			control.get('confirmPassword').setErrors({ConfirmPassword: true});
		}else if (password != '' && confirmPassword!='' && password == confirmPassword) {
			control.get('confirmPassword').setErrors(null);
		} else {
			return null;
		}
	}
}
