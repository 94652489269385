import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { WaTemplatePayload } from "../models/wa-template-payload";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MetaWaService {

    private META_GRAPH_DOMAIN = "https://graph.facebook.com";
    private META_GRAPH_VERSION = "v19.0";
    private PHONE_NUMBER_ID = "408990765621818";
    private FOAMICO_BANDHAN_PHONE_NUMBER = "919870410035";
    private FOAMICO_BANDHAN_WABA_ID = "369429626256292";

    constructor(private http: HttpClient) { }

    fetchAllTemplates(nextUrl?: string): Promise<any[]> {
        const templatesUrl = nextUrl || `${this.META_GRAPH_DOMAIN}/${this.META_GRAPH_VERSION}/${this.FOAMICO_BANDHAN_WABA_ID}/message_templates?fields=name,status,components`;
        return fetch(templatesUrl, {
            headers: {
                'Authorization': `Bearer ${environment.foamicoBandhanAccessToken}`
            }
        })
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            // Store current batch of templates
            const templates = data.data;

            // Check if there's a next page of data
            if (data.paging && data.paging.next) {
                // Recursively fetch the next page
                return this.fetchAllTemplates(data.paging.next).then(nextTemplates => {
                    return templates.concat(nextTemplates);
                });
            } else {
                return templates;
            }
        })
        .catch(error => {
            console.error('Template API Error:', error);
            throw error;
        });
    }

    sendTemplateMessage(templatePayload: WaTemplatePayload) {
        return this.http.post<WaTemplatePayload>(
            environment.baseUrl+'/transactions/api/foamicoBandhan/sendTemplateMessages', templatePayload);
    }

}