import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ActivitiesService {

    constructor(private http: HttpClient) { } 

    deleteActivities(activityId: number,moduleType,projectId:number) {
        return this.http.delete<any[]>(
            environment.baseUrl + '/transactions/api/activities/deleteActivityData?&projectId='+projectId+'&moduleType='+moduleType+'&activityId='+activityId);
    }
}