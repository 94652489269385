import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { FormGroup,FormControl, FormBuilder,Validators, Form } from '@angular/forms';
import { LocalizationService } from '../../services/localization.service';
//utilities
import { LayoutUtilsService ,MessageType} from '../../../../../core/_base/crud';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-opportunity-setting',
  templateUrl: './opportunity-setting.component.html',
  styleUrls: ['./opportunity-setting.component.scss']
})
export class OpportunitySettingComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes:string="";
  addSettingsForm:FormGroup;
  singularName: string;
  serialKeyUpdated: boolean;
  enforceLiveLocCapture: boolean;
  enableOpportunityFeature: boolean;
  pruralName:string;
  disabled:boolean;
  currentRouteUrl: string;
  prevsingularName: string;
  prevpruralName: string;
  @Output() updateOpportunityTabLabel = new EventEmitter<string>();
  @Output() saveAndExit = new EventEmitter<boolean>();
  unsavedChanges = false;

  constructor(private fb: FormBuilder,
     private _ss:LocalizationService,
     private layoutUtilsService: LayoutUtilsService,
     private SpinnerService: NgxSpinnerService,
     private router: Router,
    private subheaderService: SubheaderService,
    private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('Opportunity');
    }
    this.addSettingsForm = this.fb.group({
      enforceLiveLocCapture: [''],
      enableOpportunityFeature: [''],
      singularName: ['', Validators.compose([ Validators.required])],
      pruralName:['', Validators.compose([ Validators.required])],
      serialKey: ['', Validators.compose([ Validators.required])],
      customerOwnerUserFlag:[],
      customerOwnerUpHierarchyFlag:[],
      customerOwnerDownHierarchyFlag:[],
      customerOwnerAnyUserFlag:[],
      opportunityOwnerFlag:['', Validators.compose([ Validators.required])]

     });

     this.disabled=false;
    this.getSettingsValues();    
  }

  getSettingsValues(){
    this.SpinnerService.show();
    this._ss.getOpportunitySettings(this.selectedProject).subscribe(
      resp=>{
        console.log(resp);
        var res=resp['responseData'];
        this.enforceLiveLocCapture=res.enforceLiveLocCapture ==1 ? true :false;
        this.enableOpportunityFeature = res.enableOpportunityFeature ==1 ? true :false;
        this.singularName=res.singularName;
        this.updateOpportunityTabLabel.emit(res.singularName);
        this.pruralName=res.pruralName;
        this.prevsingularName=res.singularName;
        this.prevpruralName=res.pruralName;
        this.addSettingsForm.controls.serialKey.setValue(res.serialKey);
        this.serialKeyUpdated=res.serialKeyUpdatedFlag == 0 ? false : true;
        if(this.serialKeyUpdated){
          this.addSettingsForm.controls.serialKey.disable();
        }
        this.toggleOpportunityFeature(this.enableOpportunityFeature);
        this.SpinnerService.hide();
        this.addSettingsForm.get("customerOwnerUserFlag").setValue(this.convertFlagToBoolean(res.customerOwnerUserFlag));
        this.addSettingsForm.get("customerOwnerUpHierarchyFlag").setValue(this.convertFlagToBoolean(res.customerOwnerUpHierarchyFlag));
        this.addSettingsForm.get("customerOwnerDownHierarchyFlag").setValue(this.convertFlagToBoolean(res.customerOwnerDownHierarchyFlag));
        this.addSettingsForm.get("customerOwnerAnyUserFlag").setValue(this.convertFlagToBoolean(res.customerOwnerAnyUserFlag));
        this.addSettingsForm.get("opportunityOwnerFlag").setValue(res.opportunityOwnerFlag);
        //this.addSettingsForm.controls['singularName'].setValue(res.singularName);
        //this.addSettingsForm.controls['pruralName'].setValue(res.pruralName);
        this.setupFormValueChanges();
      },
      err=>{
        console.log(err);
      }
    )
    this.unsavedChanges=false;

  }


  setupFormValueChanges(): void {
    this.addSettingsForm.valueChanges.subscribe(() => {
      if (this.addSettingsForm.dirty) {
        this.unsavedChanges=true;
      }
    });
  }
   
  convertFlagToBoolean(flag: number): boolean{
    return flag === 1 ? true : false;  
  }
   
  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.addSettingsForm.controls[controlName];
    if (!control) {
      return false;
    }
  
    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  Save(calledFromParent:boolean=false){
    this.disabled=true;
    const controls = this.addSettingsForm.controls;
    if (this.addSettingsForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }
       
    const addSettingsData = {
        "projectId": this.selectedProject,  
        "enforceLiveLocCapture": controls.enforceLiveLocCapture.value ==true ? 1 :0,
        "enableOpportunityFeature": controls.enableOpportunityFeature.value ==true ? 1 :0,        
        "singularName":controls.singularName.value.trim(),
        "pruralName":controls.pruralName.value.trim(),
        "serialKey":controls.serialKey.value.trim(),
        "customerOwnerUserFlag":controls.customerOwnerUserFlag.value === true ? 1:0,
        "customerOwnerUpHierarchyFlag":controls.customerOwnerUpHierarchyFlag.value === true ? 1:0,
        "customerOwnerDownHierarchyFlag":controls.customerOwnerDownHierarchyFlag.value === true ? 1:0,
        "customerOwnerAnyUserFlag":controls.customerOwnerAnyUserFlag.value === true ?1:0,
        "opportunityOwnerFlag":controls.opportunityOwnerFlag.value

    };
    this.disabled=false;
    
    this._ss.saveOpportunitySettings(addSettingsData).subscribe(
      data => {
        if(data['statusCode']>=400){
          this.handleErrorForEditActivity(data['statusCode']);
          return;
         } 
        if(!(this.prevsingularName == this.addSettingsForm.controls.singularName.value) ||
        !(this.prevpruralName == this.addSettingsForm.controls.pruralName.value)){
          this.snackBar.open('Renamed value will appear on your next login', '', {
            duration: 2000,
          });
        }else{
          this.snackBar.open('Settings updated.', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
          if(calledFromParent){
            this.saveAndExit.emit(true);
          }
        }
        this.getSettingsValues();

        this.addSettingsForm.markAsPristine();
      },
      error =>{
        this.errorMes=error.error.responseData;
        this.snackBar.open(this.errorMes, '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
      
    );
    this.unsavedChanges = false;
  }

  Cancel(){
    this.disabled=true;
    this.getSettingsValues();
    this.disabled=false;
    this.addSettingsForm.markAsPristine();
    this.unsavedChanges =false;
  }

  isDisabled() : boolean {
    return this.disabled;
   }

   toggleOpportunityFeature(isChecked: any){     
     if(isChecked){
      this.addSettingsForm.controls.singularName.enable();
      this.addSettingsForm.controls.pruralName.enable();
      this.addSettingsForm.controls.enforceLiveLocCapture.enable();
     }else{
      this.addSettingsForm.controls.singularName.disable();
      this.addSettingsForm.controls.pruralName.disable();
      this.addSettingsForm.controls.enforceLiveLocCapture.disable();
     }     
   }

   handleErrorForEditActivity(statusCode: number) {
    let message: string = "";
    if(statusCode == 409){
      message = 'Serial No. Key already exist.';
    }
    else {
      message = 'Unable to save, please try again.';
    }
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
  }

}
