import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from 'src/app/core/_base/layout';
import { UploadImageService } from '../../../shared/services/upload-image.service';
import { AlertData } from '../../models/alert-data.model';
import { LocalizationService } from '../../services/localization.service';
import { AlertDialogComponent } from '../localization/_subs/alert-dialog/alert-dialog.component';
import { DaysEnum } from '../../../shared/constant/DaysEnum';

@Component({
  selector: 'kt-support-setting',
  templateUrl: './support-setting.component.html',
  styleUrls: ['./support-setting.component.scss']
})
export class SupportSettingComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  errorMes: string = "";
  supportSettingForm: FormGroup;
  selectChatSupportFlag: boolean = true;
  disabled: boolean;
  daysArray: { id: number, value: string }[] = [];
  currentRouteUrl: string;
  @Output() saveAndExit = new EventEmitter<boolean>();
  unsavedChanges = false;

  constructor(private fb: FormBuilder,
    private _ss: LocalizationService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private subheaderService: SubheaderService,
    public snackBar: MatSnackBar,
    private _us: UploadImageService,
    private dialog: MatDialog,) { }

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if (this.currentRouteUrl.search('set-assist') > 0) {
      this.subheaderService.setTitle('Support');
    }
    this.disabled = false;
    this.initSupportSettingForm();
    this.getSupportSetting();
    this.mappingDays(this.daysArray)
  }

  initSupportSettingForm() {
    this.supportSettingForm = this.fb.group({
      fromHours: ['', Validators.compose([Validators.required])],
      fromMinutes: ['', Validators.compose([Validators.required])],
      fromPeriod: ['AM', Validators.compose([Validators.required])],
      toHours: ['', Validators.compose([Validators.required])],
      toMinutes: ['', Validators.compose([Validators.required])],
      toPeriod: ['AM', Validators.compose([Validators.required])],
      fromDay: ['', Validators.compose([Validators.required])],
      toDay: ['', Validators.compose([Validators.required])],
      enableChatSupport: ['true', Validators.compose([Validators.required])],
      tollFreeNumber: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      supportEmail: ['', [Validators.required, Validators.email,]],
    });
  }

  mappingDays(daysArray: { id: number, value: string }[]) {
    this.daysArray.push({ id: DaysEnum.SUN, value: 'Sun' });
    this.daysArray.push({ id: DaysEnum.MON, value: 'Mon' });
    this.daysArray.push({ id: DaysEnum.TUE, value: 'Tue' });
    this.daysArray.push({ id: DaysEnum.WED, value: 'Wed' });
    this.daysArray.push({ id: DaysEnum.THU, value: 'Thu' });
    this.daysArray.push({ id: DaysEnum.FRI, value: 'Fri' });
    this.daysArray.push({ id: DaysEnum.SAT, value: 'Sat' });
  }

  getSupportSetting() {
    this.SpinnerService.show();
    this._ss.getSupportSetting(this.selectedProject).subscribe(
      resp => {
        var res = resp['responseData'];
        if(resp['responseData']){
          this.supportSettingForm.controls['enableChatSupport'].setValue(res.enableChatSupport == 1 ? true : false);
          this.supportSettingForm.get('tollFreeNumber').setValue(res.tollFreeNumber);
          this.supportSettingForm.controls['supportEmail'].setValue(res.supportEmail);
          const selectedFromDay = this.daysArray.find(day => day.id === res.fromDay);
          if (selectedFromDay) {
            this.supportSettingForm.controls['fromDay'].setValue(selectedFromDay.value);
          }
          const selectedToDay = this.daysArray.find(day => day.id === res.toDay);
          if (selectedToDay) {
            this.supportSettingForm.controls['toDay'].setValue(selectedToDay.value);
          }
          if (res.fromTime) {
            const fTime = res.fromTime;
            const [fromTime, fromPeriod] = fTime.split(' ');
            const [fromHours, fromMinutes] = fromTime.split(':').map(part => parseInt(part));
            this.supportSettingForm.controls['fromHours'].setValue(fromHours);
            this.supportSettingForm.controls['fromMinutes'].setValue(fromMinutes);
            this.supportSettingForm.controls['fromPeriod'].setValue(fromPeriod);
          }
  
          if (res.toTime) {
            const tTime = res.toTime;
            const [toTime, toPeriod] = tTime.split(' ');
            const [toHours, toMinutes] = toTime.split(':').map(part => parseInt(part));
            this.supportSettingForm.controls['toHours'].setValue(toHours);
            this.supportSettingForm.controls['toMinutes'].setValue(toMinutes);
            this.supportSettingForm.controls['toPeriod'].setValue(toPeriod);
          }
        }
        this.SpinnerService.hide();
        this.setupFormValueChanges();
      },
      err => {
        console.log(err);
      }
    )
    this.unsavedChanges = false;
  }

  setupFormValueChanges(): void {
    this.supportSettingForm.valueChanges.subscribe(() => {
      if (this.supportSettingForm.dirty) {
        this.unsavedChanges = true;
      }
    });
  }

  changeChatSupport(event: MatCheckboxChange) {
    if (!event.checked)
      this.selectChatSupportFlag = false;
    else
      this.selectChatSupportFlag = true;
    this.unsavedChanges = true;
  }

  showAlert(alertData: AlertData): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      panelClass: 'custom-carousel-dialog-container',
      data: alertData
    });
  }

  Save() {
    this.disabled = true;
    const controls = this.supportSettingForm.controls;
    if (this.supportSettingForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled = false;
      return;
    }
    const selectedFromDay = this.daysArray.find(day => day.value === this.supportSettingForm.controls['fromDay'].value);
    const selectedToDay = this.daysArray.find(day => day.value === this.supportSettingForm.controls['toDay'].value);
    const fromHoursValue = this.supportSettingForm.controls['fromHours'].value;
    const fromMinutesValue = this.supportSettingForm.controls['fromMinutes'].value;
    const fromPeriodValue = this.supportSettingForm.controls['fromPeriod'].value;
    const toHoursValue = this.supportSettingForm.controls['toHours'].value;
    const toMinutesValue = this.supportSettingForm.controls['toMinutes'].value;
    const toPeriodValue = this.supportSettingForm.controls['toPeriod'].value;
    const formattedFromTime = `${fromHoursValue.toString().padStart(2, '0')}:${fromMinutesValue.toString().padStart(2, '0')} ${fromPeriodValue}`;
    const formattedToTime = `${toHoursValue.toString().padStart(2, '0')}:${toMinutesValue.toString().padStart(2, '0')} ${toPeriodValue}`;
    const addSupportSettingsData = {
      "projectId": this.selectedProject,
      "enableChatSupport": controls.enableChatSupport.value == true ? 1 : 0,
      "tollFreeNumber": controls.tollFreeNumber.value.trim(),
      "supportEmail": controls.supportEmail.value.trim(),
      "fromDay": selectedFromDay.id,
      "toDay": selectedToDay.id,
      "fromTime": formattedFromTime,
      "toTime": formattedToTime,
    };
    this._ss.saveSupportSettings(addSupportSettingsData).subscribe(
      data => {
        if (data['statusCode'] >= 400) {
          this.handleErrorForEditActivity(data['statusCode']);
          this.disabled = false;
          return;
        }
        else {
          this.snackBar.open('Support settings  successfully updated. Please refresh to view the changes.', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
          this.disabled = false;
        }
        this.getSupportSetting();
        this.supportSettingForm.markAsPristine();
      },
      error => {
        this.errorMes = error.error.responseData;
        this.snackBar.open(this.errorMes, '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        this.disabled = false;
      }
    );
    this.saveAndExit.emit(true);
    this.unsavedChanges = false;
  }

  handleErrorForEditActivity(statusCode: number) {
    let message: string = "";
    if (statusCode == 400) {
      message = 'No support dating data exist.';
    }
    else {
      message = 'Unable to save, please try again.';
    }
    this.snackBar.open(message, '', {
      duration: 3000,
      panelClass: ['red-snackbar']
    });
  }

  Cancel() {
    this.disabled = true;
    this.getSupportSetting();
    this.disabled = false;
    this.supportSettingForm.markAsPristine();
    this.unsavedChanges = false;
  }

  getLocalization(selectedProject: number) { }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.supportSettingForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  isDisabled(): boolean {
    return this.disabled;
  }

  onDayRemoved(control: FormControl) {
    control.setValue(null);
  }

}
