import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'kt-location-dialog',
  templateUrl: './location-dialog.component.html',
  styleUrls: ['./location-dialog.component.scss']
})
export class LocationDialogComponent implements OnInit {

  location:any;
  lat:number;
  lng:number;
  constructor() { }

  ngOnInit(): void {
    var array=this.location.split(' ');
    console.log(array);
    console.log(Number(array[0]));
    this.lat=Number(array[0]);
    this.lng=Number(array[1]);

  }


}
