<h1 mat-dialog-title>Delete Relationship</h1>
<hr>
<div mat-dialog-content>
    <p>Deleting the relationship will remove all the relationship data permanently</p>
    <br>
    <p>Are you sure you want to proceed?</p>

</div>
<!-- <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div> -->


<div class="modal-footer">
    <button type="button" class="btn btn-secondary" mat-dialog-close>Cancel</button>
    <button type="button" class="btn btn-primary"  [mat-dialog-close]="true" cdkFocusInitial>Yes</button>
</div>