// Angular
import {Component, Input, OnInit} from '@angular/core';
import { ContactDashboardService } from '../../../../services/contact-dashboard.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'kt-widget1-sales-stat',
  templateUrl: './widget1-sales-stat.component.html',
  styleUrls: ['./widget1-sales-stat.component.scss']
})
export class Widget1SalesStatComponent implements OnInit {
  @Input() cssClasses = '';
  @Input() public parentData;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  contactStats: any;
  revenueEarned: number | string;
  company: number | string;
  opportunity: number;
  potentialRevenue: number;
  activities: number;
  win: number;
  lost: number;
  associatedContacts: [];

  constructor(
    private _cds: ContactDashboardService,
    private SpinnerService: NgxSpinnerService
  ) {}


  ngOnInit(): void {
    this.SpinnerService.show('dashboard-stat');
    this.fetchContactStats(this.selectedProject, this.parentData);

  }

  fetchContactStats(projectId: number, companyId: number){
    this._cds.getContactStats(projectId, companyId).subscribe(
      res=>{
        this.contactStats = res['responseData'];
        this.revenueEarned = this.contactStats.revenueEarned;
        this.company = this.contactStats.activitiesCount.company;
        this.opportunity = this.contactStats.activitiesCount.opportunity;
        this.potentialRevenue = this.contactStats.activitiesCount.potentialRevenue;
        this.activities = this.contactStats.activitiesCount.activities;
        this.win = this.contactStats.performance.win;
        this.lost = this.contactStats.performance.lost;
        this.associatedContacts = this.contactStats.associatedContacts;
        this.SpinnerService.hide('dashboard-stat');
      },
      err=>{
        console.log(err);
      }
    )
  }

}
