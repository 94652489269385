// Angular
import { Component, OnInit } from '@angular/core';
import { NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LayoutConfigService} from '../../../../core/_base/layout';
import { ProjectSelectionComponent } from '../../../pages/auth/project-selection/project-selection.component';

@Component({
  selector: 'kt-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  searchDisplay = false;
  notificationsDisplay = false;
  quickActionsDisplay = true;
  cartDisplay = false;
  quickPanelDisplay = false;
  languagesDisplay = false;
  userDisplay = true;
  userLayout = 'offcanvas';
  userDropdownStyle = 'light';
  switchSystemDisplay = false;

  currentUser = JSON.parse(localStorage.getItem('currentUser'));
  userMenu = JSON.parse(localStorage.getItem('userMenu'));
  userProject = JSON.parse(localStorage.getItem('userProject'));
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));



  constructor(
    private layoutConfigService: LayoutConfigService,
		private modalService: NgbModal) {
  //  this.searchDisplay = this.layoutConfigService.getConfig('extras.search.display');
  //  this.notificationsDisplay = this.layoutConfigService.getConfig('extras.notifications.display');
    this.quickActionsDisplay = this.layoutConfigService.getConfig('extras.quick-actions.display');
  //  this.cartDisplay = this.layoutConfigService.getConfig('extras.cart.display');
  //  this.quickPanelDisplay = this.layoutConfigService.getConfig('extras.quick-panel.display');
  //  this.languagesDisplay = this.layoutConfigService.getConfig('extras.languages.display');
    this.userDisplay = this.layoutConfigService.getConfig('extras.user.display');
    this.userLayout = this.layoutConfigService.getConfig('extras.user.layout');
    this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style');
  }

  ngOnInit(): void {
    if(this.userProject.length > 1){
      this.quickPanelDisplay = true;
    }
  }

  switchSystem(){
    const ref=this.modalService.open(ProjectSelectionComponent,
			{
			  centered: false,
			  size : 'md',
			  backdrop : 'static'
			});
    ref.componentInstance.userDetail=this.currentUser;
    ref.componentInstance.selectionFlag='Switch';
  }
  
}
