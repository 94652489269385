import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../general-settings/services/localization.service';
import { EventEmitterService } from '../shared/services/event-emitter.service';

@Component({
  selector: 'kt-company-dashboard',
  templateUrl: './company-dashboard.component.html',
  styleUrls: ['./company-dashboard.component.scss']
})
export class CompanyDashboardComponent implements OnInit {

  companyId: number;
  refId: number;
  returnUrl: any;
  selectedProject: number
  enableOpportunityFeature:boolean=false;
  public companyInfoData:any;
  isInsightsCollapsed: string;
  isWhatsappConfigured:boolean=true;
  isActCollaspsed:string;

  insightsCollapsed(data: string) {
    this.isInsightsCollapsed = data;
  }
  openChat(data:string){
    this.isActCollaspsed = data;
  }


  constructor(
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService,
    private _ss:LocalizationService,
  ) { }

  ngOnInit(): void {
    this.selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
    //this.eventEmitterService.onModalCloseResult(true);

    // redirect back to the returnUrl before login
    this.route.queryParams.subscribe(params => {
		  this.returnUrl = params.returnUrl || '/';
	  });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.companyId = params.companyId;
        this.refId = params.refId;
        console.log('companyId : '+this.companyId);
    });

    this.getOpportunitySettings();
  }

  //Hide the Opportunity Tab in Customer dashboard
  getOpportunitySettings(){
    this._ss.getOpportunitySettings(this.selectedProject).subscribe(
      resp=>{
        var res=resp['responseData'];
        if(res.enableOpportunityFeature==1){
          this.enableOpportunityFeature=true;
        }
      },
      err=>{
        console.log(err);
      }
    )
  }
  receiveCompanyData(data: any) {
    this.companyInfoData=data;
   }

}
