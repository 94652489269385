import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CloneDashboardComponent } from '../clone-dashboard/clone-dashboard.component';
import { EditDashboardAlertComponent } from '../edit-dashboard-alert/edit-dashboard-alert.component';

@Component({
  selector: 'kt-dashboard-dialog-option',
  templateUrl: './dashboard-dialog-option.component.html',
  styleUrls: ['./dashboard-dialog-option.component.scss']
})
export class DashboardDialogOptionComponent implements OnInit {

  constructor(private modalService: NgbModal, private matdialog:MatDialogRef<DashboardDialogOptionComponent>) { }

  ngOnInit(): void {
  }
  addCloneDashBoard(){
    this.matdialog.close('clone');
     
  }

  editDashBoard(){
   
     this.matdialog.close('edit');
      
  }
}
