import { Component, OnInit, ViewEncapsulation, Injector, Input, ViewChild } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { ManageActivitiesService } from '../../manage-activities/services/manage-activities.service';
import { AddNewCompanyComponent } from '../../shared/custom-add/add-new-company/add-new-company.component';
import { LayoutUtilsService ,MessageType} from '../../../../core/_base/crud';
import { EventEmitterService } from '../services/event-emitter.service';
@Component({
  selector: 'kt-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddActivityComponent implements OnInit {
  @Input() public activityModule: string;
  @Input() public companyId: string;
  @Input() public contactId: string;
  @Input() public opportunityId: string;
  
  rowData=[];
  modalReference: any;
  
  
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  constructor(private modalService: NgbModal,
    private _mas: ManageActivitiesService,
    private inj: Injector,
    private eventEmitterService: EventEmitterService,
    private layoutUtilsService: LayoutUtilsService) { }

  ngOnInit(): void {
    console.log('add activity module')
    console.log('companyId: '+this.companyId+', contactId: '+this.contactId+', opportunityId: '+this.opportunityId);
    console.log(this.activityModule);

    this.getAllActivitiesList();
  }

  getAllActivitiesList(){
    this._mas.getAllActivities(this.selectedProject).subscribe(
      data=>{
       console.log(data);
       if(this.activityModule==="company"){
        this.rowData =data['responseData'].filter(ele=>{
          return ele.allowForCompanies >0;
        })
       }else if(this.activityModule==="opportunity"){
        this.rowData =data['responseData'].filter(ele=>{
          return ele.allowForOpportunities >0;
          })
       }else{        
         this.rowData =  data['responseData'];
       }
       

      }
    )
  }
  
  add(activity) {   
    const _saveMessage = `Activity has been saved.`;
    const _messageType = MessageType.Create;
    const ref=this.modalReference = this.modalService.open(AddNewCompanyComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static' 
      });

    ref.componentInstance.moduleType=activity.activityId;
    ref.componentInstance.flag=this.activityModule;
    ref.componentInstance.activityFlag=true;
    
    if(this.activityModule==="company")
      ref.componentInstance.companyId=this.companyId;
    else if(this.activityModule==="contact")
      ref.componentInstance.contactId=this.contactId;
    else
      ref.componentInstance.opportunityId=this.opportunityId;
  
/*
    this.modalReference.result.then((result) => {
      if(result){
        console.log(this.parentData);
        this.layoutUtilsService.showActionNotification(_saveMessage, _messageType, 10000, true, true);
        this.eventEmitterService.onModalCloseResult('SUCCESS');
      }
     // this.getAllActivitiesList();
    });
*/
  }
}
