// Angular
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { environment } from 'src/environments/environment';
// RxJS
import { Subject, from, BehaviorSubject } from 'rxjs';
import { MenuItemModel } from '../models/menu-item.model';
// Object-Path
import * as objectPath from 'object-path';
// Lodash
import { merge } from 'lodash';

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>;
  // Private properties
  private menuConfig: any;

  menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  /**
   * Service Constructor
   */
  constructor(private http: HttpClient) {
    // register on config changed event and set default config
     this.onConfigUpdated$ = new Subject();

  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig;
   // return this.http.post<MenuItemModel[]>(environment.baseUrl +'/menu/getAll',{});
  }

  /**
   * Load config
   *
   * @param config: any
   */
  loadConfigs(config: any) {
    const userMenu = JSON.parse(localStorage.getItem('userMenu'));
    //console.log(userMenu);
    this.menuConfig = userMenu;
   //this.menuConfig = config;
    this.onConfigUpdated$.next(this.menuConfig);
  }

}
