<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<!-- start::FILTERS & GROUP ACTIONS -->
<div class="form mb-3">
    <!-- start::FILTERS -->
    <div class="form-filtration">
        <div class="row align-items-center">
            <div class="col-md-2 kt-margin-bottom-10-mobile">
                <mat-form-field class="mat-form-field-fluid">
                    <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                    <mat-hint align="start">Search in all fields</mat-hint>
                </mat-form-field>
            </div>
            <div class="col-md-7 kt-margin-bottom-10-mobile"></div>
            <div class="col-md-3 text-right kt-margin-bottom-10-mobile">
                <button type="button" mat-raised-button color="primary"
                [disabled]="!allFieldLoaded"
                 (click)="AddCustomField()">Add Field</button>

            </div>
        </div>
    </div>
    <!-- end::FILTERS -->
</div>
<!-- end::FILTERS & GROUP ACTIONS -->

<!-- AG GRID MODULE START -->
<!-- <ag-grid-angular
    class="ag-theme-alpine"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [rowDragManaged]="true"
    [animateRows]="true"
    [domLayout]="domLayout"
    (rowDragEnd)="onBtForEachNode()"
    [gridOptions]="gridOptions"
    [rowData]="rowData"
    (gridReady)="onGridReady($event)"
>
</ag-grid-angular>  -->
<ng-container>

    <ag-grid-angular *ngIf="colDefReady"
        #agGrid
        id="myGrid"
        class="ag-theme-alpine"
        [rowData]="rowData"
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef"
        [treeData]="true"
        [components]="components"
        [animateRows]="true"
        [groupDefaultExpanded]="groupDefaultExpanded"
        [getDataPath]="getDataPath"
        [gridOptions]="gridOptions"
        [getRowNodeId]="getRowNodeId"
        [autoGroupColumnDef]="autoGroupColumnDef"
        (rowDragEnd)="onRowDragEnd($event)"
        (gridReady)="onGridReady($event)"
        [domLayout]="domLayout"
      ></ag-grid-angular>
</ng-container>

<div class="row form-padding-top-35">

    <div class="col-md-10 text-left">
        <table>
        <td>
        <!--<button type="button" id='btn' mat-raised-button color="primary" (click)="showDisplayList()" >Preview Display Order</button> -->
        <kt-preview-display-order [parentData]="moduleType" [singularData]="singularName"></kt-preview-display-order>
    </td><td>
        <button type="button" mat-raised-button color="primary" [disabled]='isDisabled()' (click)="SaveDisplayOrder(1)">Save Display Order</button>
    </td>   
    </table>
    </div>

</div>

