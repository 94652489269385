// Angular
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule, NgbTabsetModule, NgbTooltipModule, NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
// Core Module
import { CoreModule } from '../../../core/core.module';
import { OpportunityPartialsModule } from './opportunity-partials/opportunity-partials.module';
import { OpportunityDashboardComponent } from './opportunity-dashboard.component';

@NgModule({
  imports: [
    CommonModule,
    
    OpportunityPartialsModule,
    CoreModule,
    RouterModule.forChild([
      {
        path: '',
        component: OpportunityDashboardComponent
      },
    ]),
    // ng-bootstrap modules
    NgbDropdownModule,
    NgbTabsetModule,
    NgbTooltipModule,
    NgbProgressbarModule,
    NgbModule
  ],
  providers: [],
  declarations: [
    OpportunityDashboardComponent,
  ]
})
export class OpportunityDashboardModule {
}
