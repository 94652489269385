import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventEmitterService } from '../shared/services/event-emitter.service';


@Component({
  selector: 'kt-opportunity-dashboard',
  templateUrl: './opportunity-dashboard.component.html',
  styleUrls: ['./opportunity-dashboard.component.scss']
})
export class OpportunityDashboardComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  opportunityId: number;
  returnUrl: any;
  companyId:Number;
  hideCompanyWidget:boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private eventEmitterService: EventEmitterService
  ) { }

  ngOnInit(): void {
    
    this.eventEmitterService.onModalCloseResult(true);

    // redirect back to the returnUrl before login
    this.activatedRoute.queryParams.subscribe(params => {
		  this.returnUrl = params.returnUrl || '/';
	  });

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
        this.opportunityId = params.opportunityId;
        console.log('opportunityId : '+this.opportunityId);
    });

    console.log('too');
    console.log(this.opportunityId);

}

hideContainer(hideCompanyWidget){
 this.hideCompanyWidget=hideCompanyWidget; 
}

}