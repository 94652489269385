<div class="modal-header">
    <h4 class="modal-title">Select Filters</h4>
</div>

<div class="modal-body">
        <form [formGroup]="userfilter">
            <div class="row" *ngFor="let field of userFilterField">
              <div class="col-md-4">
                <label class="col-form-label form-padding-top-25">{{field.label}}</label>
              </div>
              <div class="col-md-8">
                <div *ngIf="field.entityFieldType.id == 3">
                  <mat-form-field class="example-full-width">
                    <input matInput readonly [formControlName]="'userFilter ' + field.fieldName">
                    <mat-datepicker-toggle
                      matSuffix
                      [matMenuTriggerFor]="appMenu"
                    ></mat-datepicker-toggle>
                    <mat-menu #appMenu="matMenu" class="kt-date-range-drop-calendar">
                      <div (click)="$event.stopPropagation()">
                        <mat-calendar
                          [minDate]="minDate"
                          [maxDate]="maxDate"
                          [startAt]="minDate"
                          #calendar
                          (selectedChange)="select($event, calendar,'userFilter ' + field.fieldName)"
                          [dateClass]="isSelected"
                        ></mat-calendar>
                      </div>
                    </mat-menu> 
                </mat-form-field>
                </div>  
                <div *ngIf="field.entityFieldType.id !== 3">
                  <mat-form-field class="mat-form-field-fluid">
                    <mat-select [formControlName]="'userFilter ' + field.fieldName" multiple>
                      <mat-option>
                        <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found" #filter></ngx-mat-select-search>
                      </mat-option>
                      <mat-option *ngFor="let val of filterData['userFilter ' + field.fieldName]
                    | search: filter.value:'name' " [value]="val.id">
                            {{ val.name }}
                        </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
        </form>    
</div>

<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">
        Cancel
    </button>
    <button type="button" class="btn btn-primary" (click)="onApplyFilters()">
        Apply
    </button>
</div>
