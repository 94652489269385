import { Component, OnInit, ElementRef, ViewChild, EventEmitter, Output, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { FormGroup, Validators, FormControl, AbstractControl } from '@angular/forms';
import { LocalizationService } from '../../services/localization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubheaderService } from '../../../../../../app/core/_base/layout';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadImageService } from '../../../shared/services/upload-image.service';
import { environment } from 'src/environments/environment';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { roleModel } from '../../../user-role/models/role.model';
import { CarouselDetails } from '../../models/carousel-details.model';
import { BusyStatus, CarouselOutputEvent } from '../../models/carousel-output-event.model';
import { MatDialog } from '@angular/material/dialog';
import { AlertDialogComponent } from './_subs/alert-dialog/alert-dialog.component';
import { AlertData } from '../../models/alert-data.model';

@Component({
  selector: 'kt-localization',
  templateUrl: './localization.component.html',
  styleUrls: ['./localization.component.scss']
})
export class LocalizationComponent implements OnInit {

  @ViewChild('myButton') myButton : ElementRef;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  localizationData: any;
  localizationForm: FormGroup;
  unsubscribe: Subject<any>;
  disabled:boolean;
  countryList:[];
  timeZoneList: [];
  languageList: [];
  errorMes = '';
  currentRouteUrl: string;
  baseUrl = environment.imageUrl;
  systemLogoName: String = null;
  systemLogoPath:String = null;
  allowImageEdit: boolean = false; 
  logoFieldHasError: boolean = false;
  logoFieldErrorMsg: String = null;

  maxFileSizeInKB: number = 40;

  selectCarouselFlag: boolean = false;
  readonly UPLOAD_CAROUSEL = 'UPLOAD_CAROUSEL';
  showCarouselUpload = false;
  private readonly MAX_ALLOWED_CAROUSEL = 5;


  public countryNameFilter: FormControl = new FormControl();
  public timeZoneFilter: FormControl = new FormControl();
  public languageFilter: FormControl = new FormControl();

  selectedCarouselDetails: Array<CarouselDetails> = [];
  selectedCarouselDetailsApiRes: Array<CarouselDetails> = [];
  activeUserRoles: Array<roleModel> = [];
  @Output() saveAndExit = new EventEmitter<boolean>();
  unsavedChanges = false;
  @Input() public dynamicName;
  
  constructor(
    private _ls:LocalizationService,
    private SpinnerService: NgxSpinnerService,
    private router: Router,
    private subheaderService: SubheaderService,
    public snackBar: MatSnackBar,
    private _us:UploadImageService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.currentRouteUrl = this.router.url;
    if(this.currentRouteUrl.search('set-assist') > 0){
      this.subheaderService.setTitle('System');
    }

    this.disabled=false;
    //form builder 
    this.initLocalizationForm();
    //get countries
    this.getLocalizationAndActiveRoles(this.selectedProject);

  }
  getLocalizationAndActiveRoles(selectedProject: number) {
    this.SpinnerService.show();
    this._ls.getActiveRoleList(selectedProject).subscribe(res => {
      this.activeUserRoles = res['responseData'];
      this.getLocalization(selectedProject);
    });
  }


  isDisabled() : boolean {
    return this.disabled;
  }

  initLocalizationForm(){
   
    this.localizationForm = new FormGroup({
      countryName: new FormControl('',Validators.compose([Validators.required])),
      timeZone: new FormControl('', Validators.compose([ Validators.required])),
      language: new FormControl('',Validators.compose([ Validators.required])),
      systemName: new FormControl('',Validators.compose([ Validators.required])),
      systemId: new FormControl({value: '', disabled: true}),
      targetUserFlag:new FormControl(false),
      targetCustomerFlag:new FormControl(false),
      targetOpportunityFlag:new FormControl(false)
   });
   
 }

 getLocalization(selectedProject:number){
    this._ls.getLocalization(selectedProject).subscribe(
		(result)=>{ 
        this.localizationData = result['responseData']; 
        this.countryList = this.localizationData.countryList;
        this.timeZoneList = this.localizationData.timeZoneList;
        this.languageList = this.localizationData.languageList;

        /* Set Selected Value */
        this.localizationForm.controls['countryName'].setValue(this.localizationData.selectedCountryId);
        this.localizationForm.controls['timeZone'].setValue(this.localizationData.selectedTimeZoneId);
        this.localizationForm.controls['language'].setValue(this.localizationData.selectedLanguageId);
        this.localizationForm.controls['systemName'].setValue(this.localizationData.systemName);
        this.localizationForm.controls['systemId'].setValue(this.localizationData.systemId);
        if(this.localizationData.systemLogo){
          this.systemLogoName = this.localizationData.systemLogo;
          this.systemLogoPath = environment.imageUrl + this.localizationData.systemLogo;
        }
        this.localizationForm.controls['targetUserFlag'].setValue(this.localizationData.userTargetFlag == 1);
        this.localizationForm.controls['targetCustomerFlag'].setValue(this.localizationData.customerTargetFlag == 1);
        this.localizationForm.controls['targetOpportunityFlag'].setValue(this.localizationData.opportunityTargetFlag == 1);
        this.selectedCarouselDetails = this.localizationData.carouselDetails;
        this.selectCarouselFlag = this.localizationData.selectCarouselFlag === 1 ? true : false;
        this.showCarouselUpload = this.selectedCarouselDetails.length < this.MAX_ALLOWED_CAROUSEL;
       console.log(this.localizationData);
       this.setupFormValueChanges();
       this.SpinnerService.hide();
		},
		error => {
			console.log(error)
    })
  }

  Save(calledFromParent:boolean=false){
    this.disabled=true;
    const controls = this.localizationForm.controls;
    if (this.localizationForm.invalid) {
      Object.keys(controls).forEach(controlName =>
        controls[controlName].markAsTouched()
      );
      this.disabled=false;
      return;
    }

    if (this.selectCarouselFlag && (!this.selectedCarouselDetails || this.selectedCarouselDetails.length === 0)) {
      this.showAlert(new AlertData("Carousel images enabled, upload at least one image to proceed or disable the option."));
      this.disabled=false;
      return;
    } else {
      const invalidCarousel = this.selectedCarouselDetails.find(items => items.hyperlinkError);
      if (invalidCarousel) {
        if (invalidCarousel.hyperlinkError) {
          this.showAlert(new AlertData('', 'hyperlinkError'));
        } else {
          this.showAlert(new AlertData('Error'));
        }
        this.disabled=false;
        return;
      }
    }
  
    var selectedCountryId = controls.countryName.value;
    var selectedTimeZoneId = controls.timeZone.value;
    var selectedLanguageId = controls.language.value;
    var projectId = this.selectedProject;
    var systemName = controls.systemName.value.trim();
    var userTargetFlag = controls.targetUserFlag.value;
    var customerTargetFlag = controls.targetCustomerFlag.value;
    var opportunityTargetFlag = controls.targetOpportunityFlag.value;
    
//  console.log(updateData);

    //this._ls.updateLocalization(updateData).subscribe(
      this._ls.updateLocalization(selectedCountryId,selectedTimeZoneId,selectedLanguageId,projectId,systemName,this.systemLogoName,
        this.selectCarouselFlag?1:0, this.selectedCarouselDetails,userTargetFlag?1:0,customerTargetFlag?1:0,opportunityTargetFlag?1:0).subscribe(
      data => {
        console.log(data);
      //  this.settingActivityForm.reset();
        this.disabled=false;
        this.snackBar.open('Settings updated.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        this.getLocalization(this.selectedProject);
        if(calledFromParent){
          this.saveAndExit.emit(true);
        }
        this.localizationForm.markAsPristine();
      },
      error => {
        console.log('error occured');
        const ref = this.snackBar.open('Unable to save changes. Please try again.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
        this.disabled=false;
        this.errorMes=error.error.responseData;
      }
    ); 
    this.unsavedChanges = false;
  }

  setupFormValueChanges(): void {
    this.localizationForm.valueChanges.subscribe(() => {
      if (this.localizationForm.dirty) {
        this.unsavedChanges=true;
      }
    });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.localizationForm.controls[controlName];
      if (!control) {
        return false;
      }
    
      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  ResetForm(){
    this.getLocalization(this.selectedProject);
    this.localizationForm.markAsPristine();
    this.unsavedChanges =false;
  }

  checkFileType(file) {
      return file.type.match('image/jpeg|image/png|image/gif');
  }

  async checkImageDimensions(file){
    if (file) {
      const dimensions = await this.getImageDimensions(file);
      console.log('Image height:',dimensions.height);

      if(dimensions.height <= 100){
        return true;
      }
    }
    return false;
  }

  clickImage(){
      let element:HTMLElement =document.getElementById("sys_logo") as HTMLElement;
      element.click();
  }

   async processFile(event) {
    this.logoFieldHasError = false;
    this.logoFieldErrorMsg = null;
      const fileType = this.checkFileType(event.target.files[0]);
      const imageDimension = fileType ? await this.checkImageDimensions(event.target.files[0]) : false;
      const imageFileSize = this.checkImageSizeValidation(event.target.files[0]);
      if (fileType && imageDimension && imageFileSize) {
        this.systemLogoName = null;
        this.systemLogoPath = null;
        this.SpinnerService.show("sys_logo");
        this._us.uploadImage(5, event.target.files[0], this.selectedProject).subscribe(res => {

          this.systemLogoName = res.responseData.name;
          this.systemLogoPath = res.responseData.path;
          this.triggerClick();
          this.SpinnerService.hide("sys_logo");
          this.snackBar.open('Logo Updated! Save and Re-login to view changes', '', {
            duration: 10000,
            panelClass: ['green-snackbar']
          });
        },
          error => {
            this.SpinnerService.hide("sys_logo");
          })

      } 
    else {
        this.logoFieldHasError = true;
        if(!fileType){
          this.logoFieldErrorMsg = "Upload .jpeg, .jpg, .png , .gif files only";
        }
        else if(!imageDimension){
          this.logoFieldErrorMsg = "Height should be <= 100 pixels and file size should be <= 40 KB";
        }
        else{
          this.logoFieldErrorMsg = "Error";
        }
        this.triggerClick();
      }
            
  }

  triggerClick() {
    let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click();
        }, 30);
  }

  showImageEditOption(){
  	this.allowImageEdit = true;
  }

  hideImageEditOption(){
		this.allowImageEdit = false;
             
	}  

  getImageDimensions(file: File): Promise<{height: number}> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const height = img.height;
          resolve({ height });
        };
        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    });
  }

  checkImageSizeValidation(file: File): boolean {

    const fileSizeInKB = file.size / 1024;

    return fileSizeInKB <= this.maxFileSizeInKB;
  }

  changeCarouselCB(event: MatCheckboxChange) {
    this.selectCarouselFlag = event.checked;
    this.unsavedChanges = true;
    if (!this.selectCarouselFlag) this.selectedCarouselDetails = [];
    this.showCarouselUpload = this.selectedCarouselDetails.length < this.MAX_ALLOWED_CAROUSEL;
  }

  carouselHyperlinkChanged(index: number, event: CarouselOutputEvent) {console.log(index, event);
    this.selectedCarouselDetails[index].hyperlink = event.data;
    this.selectedCarouselDetails[index].hyperlinkError = event.isInvalid
    if (event.isInvalid && event.action) {
      if(event.errorType === 'hyperlinkPattern') {
        this.showAlert(new AlertData('', 'hyperlinkError'));
      } else {
        this.showAlert(new AlertData("Error"));
      }
    }
    
  }

  carouselUserRoleChanged(index: number, data: Array<number>) {
    this.selectedCarouselDetails[index].userRoles = data;
  }

  carouselImageUpdated(index: number, event: CarouselOutputEvent) {
    if (event.isInvalid && event.action === 1) {
      this.showAlert(new AlertData(event.errorMessage ? event.errorMessage : 'Error'));
    } else {
      this.selectedCarouselDetails[index].imagePath = event.data;
    }
  }

  newCarouselAdded(imagePath: string) {
    this.selectedCarouselDetails.push({ imagePath: imagePath, hyperlink: '', userRoles: [], hyperlinkError: false });
    this.showCarouselUpload = this.selectedCarouselDetails.length < this.MAX_ALLOWED_CAROUSEL;
  }

  handleBusyStatus(status: BusyStatus) {
    switch (status) {
      case 'busy':
        this.SpinnerService.show(this.UPLOAD_CAROUSEL);
        break;

      case 'free':
        this.SpinnerService.hide(this.UPLOAD_CAROUSEL);
        break;

      default:
        break;
    }
  }

  showAlert(alertData: AlertData): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '600px',
      panelClass: 'custom-carousel-dialog-container',
      data: alertData
    });
  }
}
