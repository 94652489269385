import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ReportBuilderServiceService } from './services/report-builder-service.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditEntityFieldComponent } from './_subs/edit-entity-field/edit-entity-field.component';
import { SaveReportComponent } from './_subs/save-report/save-report.component';
import { NgxSpinnerService } from 'ngx-spinner';
import 'ag-grid-enterprise';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { EditYEntityFieldComponent } from './_subs/edit-y-entity-field/edit-y-entity-field.component';
import { FilterFieldComponent } from './_subs/filter-field/filter-field.component';
import { EditColEntityFieldComponent } from './_subs/edit-col-entity-field/edit-col-entity-field.component';
import { I } from '@angular/cdk/keycodes';
import { ReportCatalogService } from '../reports-catalog/services/report-catalog-service.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { v4 as uuidv4 } from 'uuid';
import { AxisType, ReportBuilderUtil as reportUtil } from '../../pages/report-builder/report-builder.util';
import { ApprovalEnum } from '../shared/constant/ApprovalEnum';

@Component({
  selector: 'kt-report-builder',
  templateUrl: './report-builder-edit.component.html',
  styleUrls: ['./report-builder-edit.component.scss']
})
export class ReportBuilderComponent implements OnInit {

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  public isSharedWithOthers = JSON.parse(localStorage.getItem('canShareWithOthers')) == 'true' ? true : false;
  public loggedInUser=JSON.parse(localStorage.getItem('currentUser'));
  public reportBuilderForm: FormGroup;
  public entityTypeValue: FormControl = new FormControl();
  public entityDetailsValue: FormControl = new FormControl();
  //assistantList: any;
  reportTypeList: any;
  entityDetailsList: any;
  entityFieldDetailsList: any;
  selectedItem: number = null;
  datafieldList: any;
  collapsedReportType = false;
  collapsedDataField = false;
  selectedEntityIds: any = [];
  todo: any;
  todoList: any;
  Xaxis: any;
  Yaxis: any;
  filter: any;
  userfilter:any;
  Columnaxis: any
  // done:any;
  //done2:any;
  //done3:any;
  //done4:any;
  currrentMoveVal: string | String;
  currentList: any;
  isExist: boolean;
  currentList2: any;
  isExist2: boolean;
  isEntitySelected: boolean;
  isReportTypeSelected: boolean;
  reportType: string | number;
  //options: any;
  xaixsFields = [];
  yaixsFields = [];
  measureField = [];
  filterField = [];
  userfilterField=[];
  savexaixsFields = [];
  saveyaixsFields = [];
  savefilterField = [];
  saveuserfilterField=[];
  searchValue: any;
  currentMoveItem: any;
  entityIdType: any = [];
  rendered: boolean;

  options: any;
  reportData = [];
  dataValue: any;
  SaveFieldReportData: any;

  public gridApi;
  public gridColumnApi;
  public columnDefs;
  public columnDefsUngrouped;
  public defaultColDef;
  public rowData;
  public popupParent;
  public groupDefaultExpanded;
  public autoGroupColumnDef;
  gridOptions: any;
  xfieldName: string;
  yfieldName: string;
  mfieldName: string;
  aggFunc: string;
  measureVal: string | number;
  public chartRef;
  dataTable: boolean;
  displayDataTable: boolean;
  showDataCheckbox: boolean;
  showPreview: boolean;
  editedData: any;
  private matrixTableWidth: number;
  public isLoading: boolean;
  public firstTime: boolean = true;
  defaultExcelExportParams = {
    sheetName: "Report"
  }
  showSave: boolean;
  showLabel:boolean;
  isLabelChanged:boolean=false;
  public showSaveButton=false;
  public isPreviewed=true;
  public  message:string;
  private yFieldNameArray=[];
  private xFieldNameArray=[];
  public pinnedBar;
  fieldDetails: {axisXRowField: any[], axisYColumnField: any[]};
  private showSpinner:boolean=true;
  private isWeekOrMonthForMatrixReport:boolean =false;
  private isDateOrYearForMatrixReport: boolean =false;
  private  APPROVAL_FIELD_LIST:Number[] = [ApprovalEnum.APPROVAL_NAME,
    ApprovalEnum.APPROVAL_STATUS,
    ApprovalEnum.APPROVAL_APPROVED_BY,
    ApprovalEnum.APPROVAL_APPROVED_DATE,
    ApprovalEnum.APPROVAL_APPROVED_DATE_TIME,
    ApprovalEnum.APPROVAL_REMARKS]

  constructor(private bas: ReportBuilderServiceService,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private SpinnerService: NgxSpinnerService,
    private catalogService: ReportCatalogService,
    public snackBar: MatSnackBar) {

  }

  ngOnInit(): void {

    // this.gridOptions.suppressNoRowsOverlay = true;
    this.SpinnerService.show();
    this.dataTable = true;
    this.showPreview = false;
    this.showSave=false;
    this.columnDefs = [];
    this.columnDefsUngrouped = [];
    this.isExist = false;
    this.initReportBuilderForm();
    this.getReportType(this.selectedProject);
    this.getEntityDetails(this.selectedProject)
    this.Xaxis = [];
    this.Yaxis = [];
    this.filter = [];
    this.userfilter=[];
    this.Columnaxis = [];
    this.isEntitySelected = false;
    this.isReportTypeSelected = false;
    this.showLabel=false;
    //this.showDataCheckbox=true;
    this.showDataCheckbox = false;
    if (this.reportType == 1 || this.reportType == 2 || this.reportType == 3 || this.reportType == 4) {
      this.dataTable = true;
      this.displayDataTable = false;
    } else {
      this.dataTable = false;
      this.displayDataTable = false;
    }
    //  this.disp();
    this.getReportsCatalog();

  }


  getReportsCatalog(editFlag?: number) {
    const reportId = +(localStorage.getItem('reportId'));
    const projectId = +(localStorage.getItem('selectedProject'));

    this.catalogService.getReportscatalog(this.selectedProject).subscribe(data => {
      if (data && data['responseData']) {
        const allReportData = data['responseData'];
        let entityIds = allReportData['entityIds'];
        allReportData.map(report => {
          if (report.projectId == projectId && report.id == reportId) {
            this.getReportData(report.canShareWithOthers);
          }
        });
      }
     
    },
    error=>{
      this.showSpinner=false;
      this.SpinnerService.hide();
      this.snackBar.open('Unable to load data.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    });
   
  }

  getReportData(isSharedWithOthers) {
    this.catalogService.getReportCatalogData(+localStorage.getItem('reportId')).subscribe(data => {
      if (data && data.responseData) {
        if (data && data.responseData) {
          this.editedData = data.responseData;
          console.log(this.editedData)
          if(this.editedData.createdBy==this.loggedInUser.userId){
            this.showSaveButton=true;
          }
          this.isReportTypeSelected = true;
          this.Xaxis = !this.editedData.editViewAxisXdata ? [] : this.editedData.editViewAxisXdata;
          if(this.editedData.reportType==6){
            this.Columnaxis=!this.editedData.editViewAxisYdata ? [] : this.editedData.editViewAxisYdata;
            this.Yaxis=!this.editedData.editViewAxisMeasureData?[]:this.editedData.editViewAxisMeasureData;
            this.Columnaxis && this.Columnaxis.forEach((ydata, index) => {
              ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
              ydata.aggFunc = this.editedData.axisYColumnField[index].aggregate === 'null' ? null : this.editedData.axisYColumnField[index].aggregate;
              ydata.lookupType = this.editedData.axisYColumnField[index].lookupType;
            });
            this.Yaxis && this.Yaxis.forEach((ydata, index) => {
              ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
              ydata.aggFunc = this.editedData.measureField[index].aggregate === 'null' ? null : this.editedData.measureField[index].aggregate;
              ydata.lookupType = this.editedData.measureField[index].lookupType;
            });
          }else{
            this.Yaxis = !this.editedData.editViewAxisYdata ? [] : this.editedData.editViewAxisYdata;
              this.Yaxis && this.Yaxis.forEach((ydata, index) => {
            ydata.entityFieldVal = ydata.entityFieldNameWithouModule;
            ydata.aggFunc = this.editedData.axisYColumnField[index].aggregate === 'null' ? null : this.editedData.axisYColumnField[index].aggregate;
            ydata.lookupType = this.editedData.axisYColumnField[index].lookupType;
          });
          }
          this.filter = !this.editedData.reportFilters ? [] : this.editedData.reportFilters;
          this.userfilter = !this.editedData.userFilters ? [] : this.editedData.userFilters;
          this.isSharedWithOthers = isSharedWithOthers;
          

          this.Xaxis && this.Xaxis.forEach((xdata, index) => {
            xdata.entityFieldVal = xdata.entityFieldNameWithouModule;
            xdata.aggFunc = this.editedData.axisXRowField[index].aggregate === 'null' ? null : this.editedData.axisXRowField[index].aggregate;
            xdata.lookupType = this.editedData.axisXRowField[index].lookupType;
          });
          this.filter && this.filter.forEach((data,index) => {
            data.entityFieldName = this.editedData.reportFilters[index].fieldName;
            data.fieldType=this.editedData.reportFilters[index].filter.fieldType;
            data.condition=this.editedData.reportFilters[index].filter.condition;
            data.value=this.editedData.reportFilters[index].filter.value;
            data.dateFilterType=this.editedData.reportFilters[index].filter.dateFilterType;
            data.customPreviousDays=this.editedData.reportFilters[index].filter.customPreviousDays;
            data.customFutureDays=this.editedData.reportFilters[index].filter.customFutureDays;
            data.standardDateTypeValue=this.editedData.reportFilters[index].filter.standardDateTypeValue;
            data.filter=null;
            data.entityFieldId=data.entityId;
          });
          this.userfilter && this.userfilter.forEach((userfilterdata,index) => {
            userfilterdata.entityFieldName = this.editedData.userFilters[index].fieldName;
            userfilterdata.entityFieldId = userfilterdata.entityId;
          });

          // this.onReportTypeChange(data.responseData.reportType,data.responseData.reportType-1)
          this.reportType = data.responseData.reportType;
          this.selectedItem = data.responseData.reportType - 1;
          if (this.editedData && this.editedData.entityIds && this.editedData.entityIds.length) {
            this.reportBuilderForm.patchValue({
              entityType: this.editedData.entityIds
            })
            this.entityIdType = this.editedData.entityIds;
            this.editedData.entityIds.map(id => {
              this.getEntityFieldDetails(this.selectedProject, id);
            })
            this.Preview(true)
          }
        }
      }
    },
    error=>{
      this.showSpinner=false;
      this.SpinnerService.hide();
      this.snackBar.open('Unable to load data.', '', {
        duration: 3000,
        panelClass: ['red-snackbar']
      });
    });
  }




  initReportBuilderForm() {
    this.reportBuilderForm = this.fb.group({
      //  entityType: ['', Validators.compose([Validators.required])]
      //  selectionType:[]
      entityType: [],
      dataTable: [],
      labelData:[]
    });
  }


  onChartDataRendered() {
    if (this.reportType == 1) {
      var colVale = [];
      this.fieldDetails.axisXRowField.forEach(res => {
        colVale.push(res);
      })
      this.fieldDetails.axisYColumnField.forEach(res => {
        colVale.push(res);
      })

      var eContainer1 = document.querySelector('#chart1');
      var params1 = {
        suppressChartRanges:true,
        cellRange: {
          // rowStartIndex: 0,
          // rowEndIndex: 4000,
          columns: colVale,
        },
        chartThemeOverrides: {
          common: {
            padding: {
              top: 20,
              left: 10,
              bottom: 30,
              right: 10,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
          cartesian: {
            axes: {
              number: {
                gridStyle: [
                  { 
                    stroke: undefined,
                    lineDash: undefined,
                  }
                ],
              },
              category: {
                gridStyle: [
                  {
                    stroke: undefined,
                    lineDash: undefined,
                  },
                ],
              },
            },
          },
        },
        chartType: 'groupedColumn',
        chartContainer: eContainer1
      };
      this.chartRef = this.gridApi.createRangeChart(params1);
    } else if (this.reportType == 2) {
      var colVale2 = [];
      this.fieldDetails.axisXRowField.forEach(res => {
        colVale2.push(res);
      })
      this.fieldDetails.axisYColumnField.forEach(res => {
        colVale2.push(res);
      })
      var eContainer2 = document.querySelector('#chart2');
      var params2 = {
        suppressChartRanges:true,
        cellRange: {
           rowStartIndex: 0,
          rowEndIndex: 4000,
          columns: colVale2
        },
        chartThemeOverrides: {
          common: {
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
        chartType: 'groupedBar',
        chartContainer: eContainer2,
      };
      this.chartRef = this.gridApi.createRangeChart(params2);
    } else if (this.reportType == 3) {
      var colVale3 = [];
      this.fieldDetails.axisXRowField.forEach(res => {
        colVale3.push(res);
      })
      this.fieldDetails.axisYColumnField.forEach(res => {
        colVale3.push(res);
      })
         // deleting child element ,e.firstElementChild can be used.
         if (this.isLabelChanged==true) {
          let e = document.querySelector('#chart3');
           let child = e.lastElementChild; 
           while (child) {
               e.removeChild(child);
               child = e.lastElementChild;
           }
         }
      var eContainer3 = document.querySelector('#chart3');
      var params3 = { 
        suppressChartRanges:true,
        cellRange: {
          columns: colVale3,
        },
        chartType: 'pie',
        chartContainer: eContainer3,
        chartThemeOverrides: {
          common: {
            padding: {
              top: 40,
              left: 50,
              bottom: 40,
              right: 50,
            },
            legend: {
              enabled: true,
              position: 'bottom',
              item: {
                  label: {
                      maxLength: 30
                  }
              }
            },
          },
          pie: {
            series: {
              title: {
                enabled: false
              },
              label: {
                enabled: this.showLabel,
                offset: 10,
                minAngle: 0.01,
              }
            }
          },
        },
      };
      if(this.gridApi && this.gridApi.createRangeChart) {
        this.chartRef = this.gridApi.createRangeChart(params3);
      }
    }
    else if (this.reportType == 4) {
      var colVale4 = [];
      this.fieldDetails.axisXRowField.forEach(res => {
        colVale4.push(res);
      })
      this.fieldDetails.axisYColumnField.forEach(res => {
        colVale4.push(res);
      })
      var eContainer4 = document.querySelector('#chart4');
      var params4 = {
        suppressChartRanges:true,
        cellRange: {
          columns: colVale4,
        },
        chartType: 'line',
        chartContainer: eContainer4,
        chartThemeOverrides: {
          common: {
            padding: {
              top: 20,
              left: 40,
              bottom: 30,
              right: 10,
            },
            legend: {
              enabled: true,
              position: 'bottom',
            },
          },
        },
      };
      this.chartRef = this.gridApi.createRangeChart(params4);
    }

  }

  onRowDataChanged(event) {
    setTimeout(() => {
      this.onChartDataRendered();
      this.rendered = true;
    })

    //params.api.getDisplayedRowAtIndex(0).setSelected(true);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.refreshCells({ force: true });
    if(this.reportType==6){
      this.gridApi.addEventListener('firstDataRendered', () => {
        // Call autoSizeColumns() after the first data is rendered in the grid
        this.autoSizeAllColumns();
        this.matrixTableWidth=this.getTableStyle("myGrid");
      });
    }
    //----------  this.gridOptions.api.setData(this.rowData);
  }

  getTableStyle(idName:string): number {
    const minWidthPerColumn = 145; 
    const totalColumns = this.columnDefs.length;
    const containerWidth = document.getElementById(idName).clientWidth; 
    const  matrixTableWidth = totalColumns * minWidthPerColumn;
    if ( matrixTableWidth < containerWidth) {
      return containerWidth;
    } else {
      return  matrixTableWidth;
    }
  }

  autoSizeAllColumns() {
    // Automatically resize all columns to fit their content
    this.gridColumnApi.autoSizeColumns(this.gridColumnApi.getAllColumns().map(column => column.getColId()));
  }

  getChartToolbarItems(params) {
    return [];
  }
  checkValidSelection(reportType:any, xAxis:any, yAxis:any,columnAxis:any) {
    this.message = '';
    switch (reportType) {
			case 1:
          if(xAxis.length == 0 || yAxis.length == 0){
            this.message = 'X-axis and Y-axis fields are mandatory for Bar Graph. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
			case 2:
          if(xAxis.length == 0 || yAxis.length == 0){
            this.message = 'X-axis and Y-axis fields are mandatory for Bar Graph. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
			case 3:
          if(xAxis.length == 0 || yAxis.length == 0){
            this.message = 'Field and Measure fields are mandatory for Pie Chart. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
      case 4:
          if(xAxis.length == 0 || yAxis.length == 0){
            this.message = 'X-axis and Y-axis fields are mandatory for Bar Graph. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
      case 5:
          if(xAxis.length == 0){
            this.message = 'Row and Measure fields are mandatory for Table Report. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
      case 6:
          if(xAxis.length == 0|| yAxis == 0 || columnAxis == 0){
            this.message = 'Row, Column and Measure fields are mandatory for Matrix. They cannot be left empty.';
            alert(this.message);
            return false;
          } else {
            return true;
          }
		}
		return true;

  }


  Preview(isClicked:boolean=false) {
    // this.Xaxis = this.editedData.editViewAxisXdata;
    // this.Yaxis = this.editedData.editViewAxisYdata;
    this.isPreviewed=true;
    if(!this.firstTime) {
      this.isLoading = true;
    }
    this.firstTime = false;
    this.showSave=false;
    this.showPreview = true;
    this.xaixsFields = [];
    this.yaixsFields = [];
    this.measureField = [];
    this.filterField = [];
    this.userfilterField=[];
    this.columnDefs = [];
    this.yFieldNameArray=[];
    this.xFieldNameArray=[];
    
    let validFields = this.checkValidSelection(this.reportType, this.Xaxis, this.Yaxis,this.Columnaxis);

    if(validFields){
    if (this.reportType == 1 || this.reportType == 2 || this.reportType == 3 || this.reportType == 4) {
      this.dataTable = true;
      this.displayDataTable = true;
    } else if (this.reportType == 5) {
      this.dataTable = false;
      this.displayDataTable = true;
    } else {
      this.dataTable = false;
      this.displayDataTable = false;
    }
    // this.gridApi.refreshCells({force : true});
    this.SpinnerService.show();
    this.Xaxis.forEach(x => x.randomId = uuidv4());
    this.Yaxis.forEach(x => x.randomId = uuidv4());
    this.Columnaxis.forEach(x => x.randomId = uuidv4());

    if (this.reportType == 1 || this.reportType == 2 || this.reportType == 4) {
      this.measureField = null;
      
      this.Xaxis.forEach(res => {
        this.xfieldName = this.getFieldNameForPreview(res);
        if(this.reportType==2){
          this.xFieldNameArray.push(this.getFieldNameForPreview(res));
        }
        if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.xaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":res.dateGroupBy
        })
      })
      this.Yaxis.forEach(res => {
        this.yFieldNameArray.push(this.getFieldNameForPreview(res));
        if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.yaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":this.reportType == 2?res.dateGroupBy:null
        })
      })
    }

    if (this.reportType == 3 || this.reportType == 5) {
      //this.yaixsFields=null;
      this.Xaxis.forEach(res => {
        this.xfieldName = this.getFieldNameForPreview(res);
        
        if(!res.hasOwnProperty('lookupType')) {
          if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
            res.lookupType = true;
          } else {
            res.lookupType = false;
          }
        }
        this.xaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":res.dateGroupBy
        })
      })
      this.Yaxis.forEach(res => {
        this.yfieldName = this.getFieldNameForPreview(res);
        if(!res.hasOwnProperty('lookupType')) {
          if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
            res.lookupType = true;
          } else {
            res.lookupType = false;
          }
        }
       
        this.yaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType
        })
      })

    }

    if (this.reportType == 6) {
      this.Xaxis.forEach(res => {
        if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.xaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":res.dateGroupBy
        })
      })
      this.Columnaxis.forEach(res => {
        this.yfieldName = this.getFieldNameForPreview(res);
        if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.yaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType,
          "dateGroupBy":res.dateGroupBy
        })
        if(res.dateGroupBy && (res.dateGroupBy==2|| res.dateGroupBy==3)){
          this.isWeekOrMonthForMatrixReport=true;
        }else if(res.dateGroupBy && (res.dateGroupBy==1|| res.dateGroupBy==2 )){
          this.isDateOrYearForMatrixReport=true;
        }
      })
      this.Yaxis.forEach(res => {
        this.mfieldName = this.getFieldNameForPreview(res);
        if (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.measureField.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": this.getFieldNameForPreview(res),
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "entityFieldId": null,
          "filter": null,
          "label": null,
          "systemFieldId": null,
          "entityFieldType":res.entityFieldType
        })
      })

    }
    
    if (this.filter && this.filter.length > 0) {
      this.filter.forEach(res => {
        var Value = res.value;
        if (res.fieldType != null && res.fieldType == 1) {
          Value = Number(Value);
        }
        if (res.entityFieldType && (res.entityFieldType.id == 1 || res.entityFieldType.id == 2 || res.entityFieldType.id==18)) {
          res.lookupType = true;
        } else {
          res.lookupType = false;
        }
        this.filterField.push({
          "entityId": res.entityFieldId,
          "moduleId": res.moduleId,
          "fieldName": 'filter ' + res.entityFieldName,
          "lookupType": res.lookupType,
          "filter":  res.filter ?  res.filter : {
            "fieldType": res.fieldType,
            "condition": res.condition,
            "value": Value,
            "dateFilterType": res.dateFilterType,
            "customPreviousDays": res.customPreviousDays,
            "customFutureDays": res.customFutureDays,
            "standardDateTypeValue": res.standardDateTypeValue
          }
        })
      })

    }

    if(this.userfilter && this.userfilter.length>0){
      this.userfilter.forEach(res=>{
        var Value=res.value;
        if(res.fieldType !=null && res.fieldType==1){
          Value=Number(Value);
        }
        if(res.entityFieldType.id == 1 || res.entityFieldType.id==2 || res.entityFieldType.id==18){
          res.lookupType=true;
        }else{
          res.lookupType=false;
        }
        this.userfilterField.push({
          "entityId":res.entityFieldId,
          "moduleId":res.moduleId,
          "fieldName":'userfilter ' + res.entityFieldName.replace(/\./g, ''),
          "entityFieldType": res.entityFieldType,
          "lookupType":res.lookupType,
          "label": res.label
        })
      })
    }

    // "reportFilters":this.filterField
    const previewData = {
      "projectId": this.selectedProject,
      "id":+localStorage.getItem('reportId'),
      "reportType": this.reportType,
      "axisXRowField": this.xaixsFields,
      "axisYColumnField": this.yaixsFields,
      "measureField": this.measureField,
      "filters": null,
      "reportFilters": this.filterField,
      "userFilters":this.userfilterField,
      'limit':isClicked?100:null
    };

    // const previewData = {
    //   axisXRowField: [
    //     {
    //         aggregate: null,
    //         entityId: 5478,
    //         fieldName: "Product Name",
    //         lookupType: false,
    //         moduleId: 4
    //     }
    // ],
    // axisYColumnField: [
    //     {
    //         aggregate: "count",
    //         entityId: 5480,
    //         fieldName: "Description",
    //         lookupType: false,
    //         moduleId: 4,
    //     }
    // ],
    // filters: null,
    // measureField: null,
    // projectId: 324,
    // reportFilters: [],
    // reportType: 1
    // }

    // this.rowData = [];
    this.bas.previewData(previewData).subscribe(
      (result) => {
        this.fieldDetails = result['responseData'].fieldDetails;
        this.getColDef(this.fieldDetails);
        this.reportData = result['responseData'].reportData;
        // this.reportData = this.reportData.splice(0,10);
        // this.showDataCheckbox=true;
        if (this.reportType == 5 || this.reportType == 6) {
          this.showDataCheckbox = false;
        } else {
          this.showDataCheckbox = true;
        }
        // if (this.reportType == 3) {
        //   this.reportData.forEach(res => {
        //     if(isNaN(res[this.yfieldName])) {
        //       res[this.yfieldName]=res[this.yfieldName].substring(0,10).replaceAll(',','');
        //     } 
        //     if(isNaN(res[this.xfieldName])) {
        //       res[this.xfieldName]=res[this.xfieldName].substring(0,10).replaceAll(',','');
        //     }
        //   })
        // }
        this.rowData = this.reportData;
        if (this.reportType == 4 || this.reportType == 1 || this.reportType == 2) {
          this.rowData.forEach(res => {
            if(this.reportType!=2){
            this.yFieldNameArray.forEach(data=>{
              res[data]=Number(res[data])
            })
          }
          else{
            this.xFieldNameArray.forEach(data=>{
              res[data]=Number(res[data])
            })
          }
          })
        }

        if (this.reportType == 6) {
          this.generateColumns6(this.rowData);
        }


        // this.gridApi.refreshCells({force : true});
        this.isLoading = false;
        this.showSpinner=false;
        this.SpinnerService.hide();
        this.showSave = true;
      },
      error => {
        this.rowData = [];
        this.SpinnerService.hide();
        this.isLoading = false;
        this.showSpinner=false;
        this.snackBar.open('Unable to load data.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }

    );
    if (this.chartRef) {
      this.chartRef.destroyChart();
    }
  }
  }

  getFieldNameForPreview(res: {label: string, entityFieldNameWithouModule: string}): string {
    return (res.label===null || res.label==='' || res.label===undefined) ? res.entityFieldNameWithouModule : res.label.replace('.', '');
  }

  getColDef(fieldDetails) {
    if (this.reportType == 1) {
      this.generateColumns(fieldDetails);
    } else if (this.reportType == 2) {
      this.generateColumns2(fieldDetails);
    } else if (this.reportType == 3) {
      this.generateColumns3(fieldDetails);
    } else if (this.reportType == 4) {
      this.generateColumns4(fieldDetails);
    } else if (this.reportType == 5) {
      this.generateColumns5(fieldDetails);
    }

  }

  private mapUngroupedColDef() {
    this.columnDefsUngrouped = JSON.parse(JSON.stringify(this.columnDefs));
    for(let columnDefsUngrouped in this.columnDefsUngrouped ) {
      if(this.columnDefsUngrouped[columnDefsUngrouped].hasOwnProperty('rowGroup')) {
        this.columnDefsUngrouped[columnDefsUngrouped].rowGroup=false;
      }
    }
  }

  generateColumns(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res => {
      let mappedColumn = {
        field: res,
        chartDataType: 'category',
        rowGroup: false,

      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res => {
      //  this.aggFunc=res.aggFunc;
      this.measureVal = res.measure;
      let mappedColumn2 = {
        field: res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn2);
    })
    this.columnDefs = columnDefinitions;
    this.mapUngroupedColDef();
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;

  }

  generateColumns2(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res => {
      this.measureVal = res.measure;
      let mappedColumn2 = {
        field: res,
        chartDataType: 'series',
        rowGroup: false,
      }
      columnDefinitions.push(mappedColumn2);
    })
    fieldDetails.axisYColumnField.forEach(res => {
      let mappedColumn = {
        field: res,
        chartDataType: 'category',
      }
      columnDefinitions.push(mappedColumn);
    })
    this.columnDefs = [];
    this.columnDefs = columnDefinitions;
    this.mapUngroupedColDef();

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;
  }
  generateColumns3(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res => {
      let mappedColumn = {
        field: res,
        chartDataType: 'category',
        rowGroup: false,
      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res => {
      //  this.aggFunc=res.aggFunc;
      this.measureVal = res.measure;
      let mappedColumn2 = {
        field: res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn2);
    })

    this.columnDefs = columnDefinitions;
    this.mapUngroupedColDef();

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;

  }

  generateColumns4(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res => {
      let mappedColumn = {
        field: res,
        chartDataType: 'category',
      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res => {
      this.measureVal = res.measure;
      let mappedColumn2 = {
        field: res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn2);
    })

    this.columnDefs = columnDefinitions;
    this.mapUngroupedColDef();

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;

  }

  generateColumns5(fieldDetails: {axisXRowField: any[], axisYColumnField: any[]}) {
    let columnDefinitions = [];
    fieldDetails.axisXRowField.forEach(res => {
      let mappedColumn = {
        field: res,
        chartDataType: 'category',
      }
      columnDefinitions.push(mappedColumn);
    })
    fieldDetails.axisYColumnField.forEach(res => {
      this.measureVal = res.measure;
      let mappedColumn2 = {
        field: res,
        chartDataType: 'series',
      }
      columnDefinitions.push(mappedColumn2);
    })

    this.columnDefs = columnDefinitions;
    this.mapUngroupedColDef();

    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth: 100,
      filter: true,
      resizable: true,
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;

  }

  generateColumns6(rowData: any) {
    this.getColumnsAndUpdatedRowDataForMatrixReport(rowData);
    this.getPinnedTopData(this.columnDefs);
    this.defaultColDef = {
      editable: false,
      sortable: true,
      flex: 1,
      minWidth:100,
      filter: true,
      resizable: true,
      suppressMovable: true
    };
    this.popupParent = document.body;
    this.groupDefaultExpanded = -1;
  }
  getUpdatedRowDataForMatrixReport(rowData: any, dynamicColumns: any, columnFieldName) {
    const fieldNames = new Set<string>(dynamicColumns.map((column: any) => column.field));
    return rowData.map((res: any) => {
      const updatedRes = { ...res };
      if (res[columnFieldName]) {
        for (let data of res[columnFieldName]) {
          updatedRes[data.key] = data.value;
        }
      }
      for (let colName of fieldNames) {
        if (updatedRes[colName] === undefined) {
          updatedRes[colName] = 0;
        }
      }
      return updatedRes;
    });
  }
  getColumnsAndUpdatedRowDataForMatrixReport(rowData: any) {
    const columnDefinitions = [{
      headerName: '',
      field: this.getColumnField(this.Xaxis[0]),
      suppressMovable: true,
      pinned: 'left',
    }];

    const columnFieldName = this.getColumnField(this.Columnaxis[0]);

    let dynamicColumn = [];
    const fieldNames = new Set();

    for (const res of rowData) {
      if (res[columnFieldName]) {
        for (const data of res[columnFieldName]) {
          if (!fieldNames.has(data.key)) {
            res[data.key] = data.value;
            fieldNames.add(data.key);
            const mappedColumn = {
              headerName: "",
              field: data.key,
              pinned: 'left',
            };
            dynamicColumn.push(mappedColumn);
          }
        }
      }
    }

    if (dynamicColumn.length > 0) {
      dynamicColumn.reverse();
      if(this.isWeekOrMonthForMatrixReport){
        dynamicColumn=this.handleWeekOrMonthFormatForMatrixReport(dynamicColumn);
      }else if(this.isDateOrYearForMatrixReport){
        dynamicColumn=this.handleDateOrYearFormatForMatrix(dynamicColumn);
      }
      dynamicColumn[0].headerName = columnFieldName;
    }

    this.rowData = this.getUpdatedRowDataForMatrixReport(rowData, dynamicColumn, columnFieldName);
    this.columnDefs = columnDefinitions.concat(dynamicColumn);
  }
  getPinnedTopData(columnDefinitions: any) {
    const pinnedBar = [{ xAxis: 'y' }]
    columnDefinitions.forEach(res => {
      res.minWidth = 100;
      pinnedBar[0][res.field] = res.field;
    })
    this.pinnedBar = pinnedBar;
  }
  private handleWeekOrMonthFormatForMatrixReport(dynamicColumn: any[]) {
    function compareDateStrings(a: string, b: string): number {
      function parseWeekFormat(dateString: string): [string, string, string] {
        if(dateString.match(/Week-(\d+) \(([^)]+)\)/)){
          const [, week, monthYear] = dateString.match(/Week-(\d+) \(([^)]+)\)/) || [];
          const [month, year] = monthYear.split('-');
          return [year, month, week];
        }else return['','',''];

      }
  
      function parseMMMYYFormat(dateString: string): [string, string, string] {
        if(dateString=='null' || dateString==''){
          return ['','',''];
        }
        const [month, year] = dateString.split('-');
        return [year, month, ''];
      }
  
      const [yearA, monthA, weekA] = parseWeekFormat(a);
      const [yearB, monthB, weekB] = parseWeekFormat(b);
      const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // For MMM-YY format
      if (!weekA && !weekB) {
        const [yearA, monthA] = parseMMMYYFormat(a);
        const [yearB, monthB] = parseMMMYYFormat(b);
        if (yearA !== yearB) {
          return +yearA - +yearB;
        }
        return months.indexOf(monthA) - months.indexOf(monthB);
      }
  
      if (yearA !== yearB) {
        return +yearA - +yearB;
      }
  
      if (weekA !== weekB) {
        return +weekA - +weekB;
      }
  
      
      return months.indexOf(monthA) - months.indexOf(monthB);
    }
  
    dynamicColumn.sort((a, b) => compareDateStrings(a.field, b.field));
    
    return dynamicColumn.reverse();
  }
  
private handleDateOrYearFormatForMatrix(dynamicColumn){
  function compareDates(a: string, b: string): number {
    const partsA = a.split('-');
    const partsB = b.split('-');
  
    // YYYY-MM-DD format
    if (partsA.length === 3 && partsB.length === 3) {
      const dateA = new Date(a);
      const dateB = new Date(b);
  
      if (dateA < dateB) {
        return -1;
      }
      if (dateA > dateB) {
        return 1;
      }
      return 0;
    }
  
    // Handle YYYY format
    if (partsA.length === 1 && partsB.length === 1) {
      const yearA = parseInt(a, 10);
      const yearB = parseInt(b, 10);
  
      if (yearA < yearB) {
        return -1;
      }
      if (yearA > yearB) {
        return 1;
      }
      return 0;
    }
  
    return 0; // Return 0 for unsupported formats or when dates are equal
  }
  
  dynamicColumn.sort((a, b) => compareDates(a.field, b.field));
  return dynamicColumn.reverse();
  
}

  getColumnField(res: {label: string, entityFieldVal: string}): string{
    return (res.label===null || res.label==='' || res.label===undefined) ? res.entityFieldVal : res.label;
  }

  Save(saveType: string = 'new') {
    if(this.isPreviewed==false){
      alert("Please preview your changes");
      return;
    }
    this.savexaixsFields = [];
    this.saveyaixsFields = [];
    this.savefilterField = [];
    this.saveuserfilterField=[];
    if (this.Xaxis != null) {
      this.Xaxis.forEach(res => {
        this.savexaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": res.entityFieldNameWithouModule,
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "label": res.label,
          "dateGroupBy":res.dateGroupBy
          
        })
      })
    } else {
      this.savexaixsFields = null;
    }

    if (this.Xaxis != null) {
      this.Yaxis.forEach(res => {
        this.saveyaixsFields.push({
          "entityId": res.entityFieldId,
          "moduleId": res.entityId,
          "fieldName": res.entityFieldNameWithouModule,
          "aggregate": res.aggFunc,
          "lookupType": res.lookupType,
          "label": res.label,
          "dateGroupBy":res.dateGroupBy
        })
      })
    } else {
      this.saveyaixsFields = null;
    }

    if (this.filter != null) {
      this.filter.forEach(res => {
        var Value = res.value;
     
        if (res.fieldType != null && res.fieldType == 1) {
          Value = Number(Value);
        }
        this.savefilterField.push({
          "entityId": res.entityFieldId,
          "moduleId": res.moduleId,
          "fieldName": res.entityFieldName,
          "entityFieldType": res.entityFieldType,
          "filter": {
            "fieldType": res.fieldType,
            "condition": res.condition,
            "value": Value,
            "dateFilterType": res.dateFilterType,
            "customPreviousDays": res.customPreviousDays,
            "customFutureDays": res.customFutureDays,
            "standardDateTypeValue": res.standardDateTypeValue
          }
        })
      })
    } else {
      this.savefilterField = null;
    }
    if(this.userfilter !=null){
      this.userfilter.forEach(res=>{
        var Value=res.value;
        if(res.fieldType !=null && res.fieldType==1){
          Value=Number(Value);
        }
        this.saveuserfilterField.push({
          "entityId":res.entityFieldId,
          "moduleId":res.moduleId,
          "fieldName":res.entityFieldName,
          "entityFieldType": res.entityFieldType,
          "lookupType":res.lookupType,
          "label": res.label
        })
      })
    }else{
      this.saveuserfilterField=null;
    }
    let SaveReportData:any;
    if(this.reportType==6){
      let columnAxisrFields=[];
       if(this.Xaxis !=null){
         this.Columnaxis.forEach(res=>{
           columnAxisrFields.push({
             "entityId":res.entityFieldId,
             "moduleId":res.entityId,
             "fieldName":res.entityFieldNameWithouModule,
             "aggregate":res.aggFunc,
             "lookupType":res.lookupType,
             "label": res.label,
             "dateGroupBy":res.dateGroupBy
           })
         })
       }
        SaveReportData = {    
         "projectId": this.selectedProject,
         "reportType": this.reportType,
         "axisXRowField": this.savexaixsFields,
         "axisYColumnField":columnAxisrFields,
         "measureField": this.saveyaixsFields,
         "filters": this.savefilterField,
         "userfilters":this.saveuserfilterField,
         "entityIds":this.entityIdType,
         "measureType": null,
         "reportDesc": this.editedData.reportDesc,
         "reportName": this.editedData.reportName,
         "dashboardLocation": this.editedData.dashboardLocation,
         "location": this.editedData.location,
       };  
     }else{
     SaveReportData = {
      "projectId": JSON.parse(localStorage.getItem('selectedProject')),
      "reportType": this.reportType,
      "axisXRowField": this.savexaixsFields,
      "axisYColumnField": this.saveyaixsFields,
      "measureField": null,
      "filters": this.savefilterField,
      "userfilters":this.saveuserfilterField,
      "measureType": null,
      "reportDesc": this.editedData.reportDesc,
      "reportName": this.editedData.reportName,
      "dashboardLocation": this.editedData.dashboardLocation,
      "location": this.editedData.location,
      "entityIds": this.entityIdType
    };
  }
    this.SaveFieldReportData = SaveReportData;

    const ref = this.modalService.open(SaveReportComponent,
      {
        centered: false,
        size: 'lg',
        backdrop: 'static'
      }
    );
    ref.componentInstance.reportData = SaveReportData;
    ref.componentInstance.saveType = saveType;
  }

  move(currentMovedVal) {
    this.currentMoveItem = [];
    this.currentMoveItem = currentMovedVal;
    this.currrentMoveVal = currentMovedVal.entityFieldName;

  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.reportBuilderForm.controls[controlName];
    if (!control) {
      return false;
    }

    const result = control.hasError(validationType) && (control.dirty || control.touched);
    return result;
  }

  drop(event: CdkDragDrop<string[]>) {

    if (event.previousContainer == event.container) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        // event.previousContainer.data.indexOf(this.currentMoveItem),
        event.currentIndex);

      this.todo = this.entityFieldDetailsList;

    } else {

      this.currentList = event.container.data;
      if (this.currentList != null) {
        for (let a of this.currentList) {
          if (a.entityFieldName == this.currrentMoveVal) {
            this.isExist = true;
          }
        }

        if ((this.reportType != 6) && this.currentList.length >= 1) {
          this.isExist = true;
        }
        if ((this.isExist) || this.currentList.length == 0) {
          this.OpenEditField(this.currentMoveItem, event.container.data,"Edit Field", true);
          this.currentMoveItem = [];
        }
        this.isExist = false;
      }
    }
  }

  dropC(event: CdkDragDrop<string[]>) {

    if (event.previousContainer == event.container) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        // event.previousContainer.data.indexOf(this.currentMoveItem),
        event.currentIndex);

      this.todo = this.entityFieldDetailsList;

    } else {

      this.currentList = event.container.data;
      if (this.currentList != null) {
        for (let a of this.currentList) {
          if (a.entityFieldName == this.currrentMoveVal) {
            this.isExist = true;
          }
        }

        if ((this.reportType != 6) && this.currentList.length >= 1) {
          this.isExist = true;
        }

        if ((this.isExist) || this.currentList.length == 0) {
          this.OpenColEditField(this.currentMoveItem, event.container.data);
          this.currentMoveItem = [];
        }
        this.isExist = false;
      }
    }
  }

  dropY(event: CdkDragDrop<string[]>) {

    if (event.previousContainer == event.container) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        // event.previousContainer.data.indexOf(this.currentMoveItem),
        event.currentIndex);

      this.todo = this.entityFieldDetailsList;
    } else {

      this.currentList = event.container.data;
      if (this.currentList != null) {
        for (let a of this.currentList) {
          if (a.entityFieldName == this.currrentMoveVal) {
            this.isExist = true;
          }
        }
        if ((this.reportType == 1 || this.reportType == 4 || this.reportType == 5) && this.currentList.length >= 1) {
          this.isExist = true;
        }

        if ((this.reportType != 3 && this.reportType != 6) && this.currentList.length >= 1) {
          this.isExist = true;
        }
        if (this.isExist || this.currentList.length == 0) {
          this.OpenYEditField(this.currentMoveItem, event.container.data, true);
          this.currentMoveItem = [];
        }
        this.isExist = false;
      }
    }
  }

  dropFilter(event: CdkDragDrop<string[]>) {

    if (event.previousContainer == event.container) {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        // event.previousContainer.data.indexOf(this.currentMoveItem),
        event.currentIndex);

      this.todo = this.entityFieldDetailsList;

    } else {

      this.currentList = event.container.data;
      if (this.currentList != null) {
        for (let a of this.currentList) {
          if (a.entityFieldName == this.currrentMoveVal) {
            this.isExist = true;
          }
        }
        if(this.isExist){
       
        }else{
            this.OpenFilterField(this.currentMoveItem,event.container.data, true);
            this.currentMoveItem=[];
        }   
        this.isExist=false;
      }
    }
  }


  dropuserFilter(event: CdkDragDrop<string[]>) {

   if (event.previousContainer == event.container) {
     transferArrayItem(event.previousContainer.data,
                           event.container.data,
                           event.previousIndex,
                           //event.previousContainer.data.indexOf(this.currentMoveItem),
                           event.currentIndex);
     this.todo=this.entityFieldDetailsList;
   } else {
     this.currentList=event.container.data;
     if(this.currentList!= null){
     for(let a of this.currentList){
       if(a.entityFieldName == this.currrentMoveVal){
          this.isExist=true;
       }
     }
 
     if(this.isExist){
      
     }else{
         this.OpenEditField(this.currentMoveItem,event.container.data,"User Filter", true);
         this.currentMoveItem=[];
     }   
     this.isExist=false;
   }
   }
 }

  changeDataTableDisplay(val) {
    if ((this.reportType != 5 && val == true) || (this.reportType != 6 && val == true)) {
      // this.dataTable=true;
      this.displayDataTable = true;
      //this.onChartDataRendered();
    } else {
      //  this.dataTable=false;
      this.displayDataTable = false;
    }
  }
  changeLabelDlisplay(val){
    if(val == false){
      this.showLabel=false;
    }
    else{
      this.showLabel=true
    }
    this.isLabelChanged=true;
    this.onChartDataRendered();
  }

  toggle() {
    this.collapsedReportType = !this.collapsedReportType;
    this.collapsedDataField = !this.collapsedDataField;
  }

  expandReportType() {
    this.collapsedReportType = false;
  }

  collapseReportType() {
    this.collapsedReportType = true;
  }

  expandDataField() {
    this.collapsedDataField = false;
  }

  collapseDataField() {
    this.collapsedDataField = true;
  }

  getReportType(projectId: number) {
    this.bas.getReportType(projectId).subscribe(
      data => {
        this.SpinnerService.show();
        this.reportTypeList = data['responseData'];
        this.showSpinner?this.SpinnerService.show():this.SpinnerService.hide();
      });
  }

  getEntityDetails(projectId: number) {
    this.bas.getEntityDetails(projectId).subscribe(
      data => {
        this.entityDetailsList = data['responseData'];
        this.showSpinner?this.SpinnerService.show():this.SpinnerService.hide();
      });
  }

  invokeEntityType(id) {
    let vleues = this.reportBuilderForm['controls']['entityType'];

    this.isEntitySelected = true;
    if (id == '')
      this.isEntitySelected = false;
    this.SpinnerService.show();
    this.entityIdType = id;
    this.getEntityFieldDetails(this.selectedProject, id);
  }

  search() {
    this.todo.setQuickFilter(this.searchValue);
  }

  getEntityFieldDetails(projectId: number, entityId) {

    this.isEntitySelected = true;
    if (entityId == '')
      this.isEntitySelected = false;
    this.SpinnerService.show();

    this.bas.getEntityFieldDetails(projectId, entityId).subscribe(
      data => {
        this.entityFieldDetailsList = data['responseData'];
        this.todo = this.entityFieldDetailsList;

        data['responseData'].forEach(res => {

          res.moduleId=res.entityId;
          if (res.entityFieldType.id == 7) {
            res.menuIcon = 'fa-hashtag';
          } else if (res.entityFieldType.id == 3) {
            res.menuIcon = 'fa-calendar-alt';
          } else if (res.entityFieldType.id == 9) {
            res.menuIcon = 'fa-envelope';
          } else {
            res.menuIcon = 'fa-font';
          }
          res.label = res.entityFieldName;
          res.entityFieldVal = res.entityFieldNameWithouModule;
          //  res.entityFieldVal=(res.entityFieldName.substr(0, res.entityFieldName.indexOf('('))).trim(); 

        })
        this.entityFieldDetailsList.sort((a, b) => {
          if (String(a.entityFieldId).substring(0, 2) == '-6' && String(b.entityFieldId).substring(0, 2) !== '-6') {
            return -1;
          } else if (String(a.entityFieldId).substring(0, 2) !== '-6' && String(b.entityFieldId).substring(0, 2) == '-6') {
            return 1;
          } else {
            return a.entityFieldName.toLowerCase() > b.entityFieldName.toLowerCase() ? 1 : -1;
          }
        });
        this.SpinnerService.hide();
      });
  }

  onReportTypeChange(val, i) {
    this.isReportTypeSelected = true;
    this.reportType = val;
    this.selectedItem = i;
    this.Xaxis = [];
    this.Yaxis = [];
    this.filter = [];
    this.userfilter=[];
    this.Columnaxis = [];
    this.rowData = [];
    this.columnDefs = [];
    this.showDataCheckbox = false;
    this.showPreview = false;

    /* if(this.showDataCheckbox=true){
      this.showDataCheckbox=false;
    }
    if(this.reportType == 1 || this.reportType == 2 || this.reportType == 3 ||this.reportType == 4 ){
      this.showDataCheckbox=true;
      this.dataTable=true;
      this.displayDataTable=true;
    }else{
      this.dataTable=false;
      this.displayDataTable=false;
    }  */
    //this.gridApi.refreshCells({force : true});
  }

  OpenEditField(item, list,header:string, isFirstOpen: boolean = false) {

    const ref = this.modalService.open(EditEntityFieldComponent,
      {
        centered: false,
        size: 'md',
        backdrop: 'static'
      }
    );
    ref.componentInstance.editVal = item;
    ref.componentInstance.header=header;
    ref.componentInstance.valuelist = list;
    ref.componentInstance.reportType = this.reportType;
    ref.componentInstance.isFirstOpen = isFirstOpen;
    ref.result.then((result) => {
      this.SpinnerService.show();
      if(result=="Deleted"){
        this.isPreviewed=false;
      }
      else if(result !== null && result !== undefined) {
        this.isPreviewed=false;
        item=result;
        if(reportUtil.isDuplicateFieldAllowed(AxisType.X, +this.reportType)) {
          const index = (list as Array<any>).findIndex(x => x.randomId === item.randomId);
          if(index === -1) {
            item.randomId = uuidv4();
            list.push(item);
          } else {
            list[index] = item;
          }
        } else {
          if(this.APPROVAL_FIELD_LIST.includes(item.entityFieldId)){
            if(!(list as Array<any>).find(a=>this.APPROVAL_FIELD_LIST.includes(a.entityFieldId) && item.entityId!=a.entityId)){
              const approvalIndex= (list as Array<any>).findIndex(a=>a.entityFieldId==item.entityFieldId);
              if(approvalIndex==-1){
                list.push(item)
              }
            }
          }
          else{
            const index = (list as Array<any>).findIndex(x => (x.entityFieldId === item.entityFieldId));
            (index === -1) ? list.push(item) : list[index] = item;
          }
        }
      }
      this.SpinnerService.hide();
    });
  }

  OpenColEditField(item, list4) {

    const ref = this.modalService.open(EditColEntityFieldComponent,
      {
        centered: false,
        size: 'md',
        backdrop: 'static'
      }
    );
    ref.componentInstance.editColVal = item;
    ref.componentInstance.valuelist = list4;
    ref.componentInstance.reportType = this.reportType;
    ref.result.then((result) => {
      this.SpinnerService.show();
      //   if(result >=0){
      if(result !== null && result !== undefined) {
        this.isPreviewed=false;
        item=result;
        if(reportUtil.isDuplicateFieldAllowed(AxisType.COL, +this.reportType)) {
          const index = (list4 as Array<any>).findIndex(x => x.randomId === item.randomId);
          if(index === -1) {
            item.randomId = uuidv4();
            list4.push(item);
          } else {
            list4[index] = item;
          }
        } else {
          if(this.APPROVAL_FIELD_LIST.includes(item.entityFieldId)){
            if(!(list4 as Array<any>).find(a=>this.APPROVAL_FIELD_LIST.includes(a.entityFieldId) && item.entityId!=a.entityId)){
              const approvalIndex= (list4 as Array<any>).findIndex(a=>a.entityFieldId==item.entityFieldId);
              if(approvalIndex==-1){
                list4.push(item)
              }
            }
          }
          else{
            const index = (list4 as Array<any>).findIndex(x => (x.entityFieldId === item.entityFieldId));
            index === -1 ? list4.push(item) : list4[index] = item;
          }
          
        }
      }
      this.SpinnerService.hide();

      //   }

    });
  }

  OpenYEditField(item, list2, isFirstOpen: boolean = false) {

    const ref = this.modalService.open(EditYEntityFieldComponent,
      {
        centered: false,
        size: 'md',
        backdrop: 'static'
      }
    );
    ref.componentInstance.editYVal = item;
    ref.componentInstance.valuelist = list2;
    ref.componentInstance.reportType = this.reportType;
    ref.componentInstance.isFirstOpen = isFirstOpen;
    ref.result.then((result) => {
      this.SpinnerService.show();
      if(result=="Deleted"){
        this.isPreviewed=false;
        this.showSpinner?this.SpinnerService.show():this.SpinnerService.hide();
      }
      else if(result !== null && result !== undefined) {
      this.isPreviewed=false;
      item=result;
      if(reportUtil.isDuplicateFieldAllowed(AxisType.Y, +this.reportType)) {
        const index = (list2 as Array<any>).findIndex(x => x.randomId === item.randomId);//console.log("duplicateFieldAllowed");console.log(index);
        if(index === -1) {
          item.randomId = uuidv4();
          list2.push(item);
        } else {
          list2[index] = item;
        }
      } else {
        if(this.APPROVAL_FIELD_LIST.includes(item.entityFieldId)){
          if(!(list2 as Array<any>).find(a=>this.APPROVAL_FIELD_LIST.includes(a.entityFieldId) && item.entityId!=a.entityId)){
            const approvalIndex= (list2 as Array<any>).findIndex(a=>a.entityFieldId==item.entityFieldId);
            if(approvalIndex==-1){
              list2.push(item)
            }
          }
        }else{
          const index = (list2 as Array<any>).findIndex(x => (x.entityFieldId === item.entityFieldId));
          index === -1 ? list2.push(item) : list2[index] = item;
        }
        
      }
    }
      this.SpinnerService.hide();

    });
  }

  OpenFilterField(item, list3, isFirstOpen: boolean = false) {

    const ref = this.modalService.open(FilterFieldComponent,
      {
        centered: false,
        size: 'md',
        backdrop: 'static'
      }
    );
    
    ref.componentInstance.filterVal = item;
    ref.componentInstance.valuelist = list3;
    ref.componentInstance.isFirstOpen=isFirstOpen;
    ref.result.then((result) => {
      this.SpinnerService.show();
      if(result=="Deleted"){
        this.isPreviewed=false;
      }
    else if(result !== null && result !== undefined) {
      this.isPreviewed=false;
      if(this.APPROVAL_FIELD_LIST.includes(item.entityFieldId)){
        if(!(list3 as Array<any>).find(a=>this.APPROVAL_FIELD_LIST.includes(a.entityFieldId) && item.entityId!=a.entityId)){
          const approvalIndex= (list3 as Array<any>).findIndex(a=>a.entityFieldId==item.entityFieldId);
          if(approvalIndex==-1){
            list3.push(result)
          }
        }
      }else{
        const index = (list3 as Array<any>).findIndex(x => (x.entityFieldId === item.entityFieldId) || (x.entityFieldId.toString().startsWith("-5") && item.entityFieldId.toString().startsWith("-5")));
        index === -1 ? list3.push(result) : list3[index] = result;
      }
     
    }
      this.SpinnerService.hide();
    });
  }


}

function numberValueParser(params) {
  var res = Number.parseInt(params.newValue);
  if (isNaN(res)) {
    return undefined;
  }
  return res;
}

function createRowData() {


  var countries = [
    {
      status: "Won",
      value: 1500,
    },
    {
      status: "Lost",
      value: 2000,
    },
    {
      status: "In Progress",
      value: 3000,
    },
    {
      status: "Won",
      value: 500,
    },
    {
      status: "In Progress",
      value: 800,
    },
    {
      status: "Won",
      value: 900,
    },
    {
      status: "In Progress",
      value: 200,
    },
    {
      status: "In Progress",
      value: 400,
    },
  ];
  return countries;
}

function createRowData2() {


  var countries = [
    {
      "Value": "10",
      "Status": "Lost"
    },
    {
      "Value": "35",
      "Status": "In Progress"
    },
    {
      "Value": "10",
      "Status": "In Progress"
    },
    {
      "Value": "11",
      "Status": "In Progress"
    },
    {
      "Value": "3000",
      "Status": "In Progress"
    },
    {
      "Value": "2000",
      "Status": "In Progress"
    },
    {
      "Value": "3000",
      "Status": "In Progress"
    },
    {
      "Value": "50000",
      "Status": "Won"
    },
    {
      "Value": "1000",
      "Status": "In Progress"
    },
    {
      "Value": "30000",
      "Status": "In Progress"
    },
    {
      "Value": "2000",
      "Status": "In Progress"
    },
    {
      "Value": "30000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "15000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "600",
      "Status": "In Progress"
    },
    {
      "Value": "3000",
      "Status": "In Progress"
    },
    {
      "Value": "30000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "2000",
      "Status": "In Progress"
    },
    {
      "Value": "2000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "1000",
      "Status": "In Progress"
    },
    {
      "Value": "40000",
      "Status": "Won"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "2000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "10000",
      "Status": "In Progress"
    },
    {
      "Value": "3000",
      "Status": "In Progress"
    },
    {
      "Value": "20000",
      "Status": "In Progress"
    },
    {
      "Value": "25000",
      "Status": "In Progress"
    },
    {
      "Value": "32423",
      "Status": "Won"
    },
    {
      "Value": "100",
      "Status": "In Progress"
    },
    {
      "Value": "100",
      "Status": "In Progress"
    },
    {
      "Value": "100",
      "Status": "In Progress"
    },
    {
      "Value": "100",
      "Status": "In Progress"
    },
    {
      "Value": "1000",
      "Status": "In Progress"
    },
  ];
  return countries;
}

function strcmp(a, b) {
  return a < b ? -1 : a > b ? 1 : 0;
}

