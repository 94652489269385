import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserProfilePicService {

  constructor(private http:HttpClient) { }

  fetchProfilePicture(){
    return this.http.get(environment.baseUrl + '/login/api/users/profile/fetchProfilePic');    
  }

  uploadProfilePicture(prfilePic: any){
    return this.http.post<any[]>(environment.baseUrl + '/login/api/users/profile/uploadProfilePic',prfilePic);    
  }

  removeProfilePicture(){
    return this.http.put(environment.baseUrl + '/login/api/users/profile/removeProfilePic', null);    
  }

  logout(projectId: number) {
    return this.http.post<any[]>(environment.baseUrl + '/login/api/logout?projectId=' + projectId, {});
  }
}
