<!-- begin: Header -->
<div ktHeader #ktHeader class="header " [ngClass]="headerClasses" id="kt_header">
  <!--begin::Container-->
  <div [ngClass]="headerContainerClasses" class="d-flex align-items-stretch justify-content-between">

    <ng-container *ngIf="(loader.progress$|async) > 0">
      <ngb-progressbar class="loading-bar" [value]="loader.progress$|async" height="3px"></ngb-progressbar>
    </ng-container>

    <!-- begin: Header Menu -->
    <ng-container *ngIf="headerMenuSelfDisplay">
      <kt-menu-horizontal
        [headerLogo]="headerLogo"
        [headerMenuSelfDisplay]="headerMenuSelfDisplay"
        [headerMenuClasses]="headerMenuClasses"
        [backBtn]="backBtn"
      ></kt-menu-horizontal>
    </ng-container> 
    <!--begin::Page Title-->
    <!--<div class="d-flex align-items-baseline mr-5"> 
      <ng-container *ngIf="backBtn">
        <div class="my-5 mr-5">
          <a (click)="goBack()"
              class="btn btn-text-dark-50 btn-icon-primary font-weight-bold font-size-h5 mr-3 pl-0">
            <i class="flaticon2-left-arrow icon-nm"></i>Back
          </a>
        </div>  
      </ng-container>
    </div>-->
      <!--end::Page Title-->
    <!-- end: Header Menu -->

    <!-- begin:: Header Topbar -->
    <!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
    <ng-container *ngIf="!headerMenuSelfDisplay">
      <div></div>
    </ng-container>
    <!--begin::Topbar-->
    <kt-topbar class="topbar"></kt-topbar>
    <!--end::Topbar-->
    <!-- end:: Header Topbar -->
  </div>
  <!--end::Container-->
</div>
<!-- end: Header -->
