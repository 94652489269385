import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventEmitterService } from '../../shared/services/event-emitter.service';


@Component({
  selector: 'kt-auth-dialog',
  templateUrl: './auth-dialog.component.html'
})
export class AuthDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<AuthDialogComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any){

      }
     // public eventEmitterService: EventEmitterService) { }

      onClose(): void {
      this.dialogRef.close();
  }

 // Resend(){
   // this.eventEmitterService.onModalCloseResult('SUCCESS');
  //}

}
