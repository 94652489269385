import { Component, Input, OnInit } from '@angular/core';
import { retry } from 'rxjs/operators';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ManageActivitiesService } from '../../../manage-activities/services/manage-activities.service';
import { UsersService } from '../../../users-management/Services/users.service';
import { Subscription } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { validators } from 'src/assets/plugins/formvalidation/src/js';

@Component({
  selector: 'kt-activity-filter',
  templateUrl: './activity-filter.component.html',
  styleUrls: ['./activity-filter.component.scss']
})
export class ActivityFilterComponent implements OnInit {
  allSelected=false;

  @Input() title;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));

  private subscriptions: Subscription[] = [];

  errorMessage = "Something went wrong.";
  apiResponseSuccess;
  EXPECTED_API_RESPONSE_COUNT = 3;
  CompanyFieldName = "Customer";
  OpportunityFieldName = "Opportunity";
  ContactFieldName = "Contact";
  showModal:boolean=false;
  activityList = [
    { activityId: '2', activityName: '' },
    { activityId: '3', activityName: '' },
    { activityId: '1', activityName: '' },
  ];
  usersList: Array<object> = [];
  apiResponseCount = 0;

  filterForm: FormGroup;
  FilterFormData = {
    dateRange: '',
    moduleType: '',
    activityName: '',
    performedBy: '',
    reportType:''
  };
  maxDate = new Date();
  minDate: string | Date;
  daysSelected: any[] = [];
  prevFilterData = {
    hasPrevData: false,
    dateRange: '',
    moduleType: '',
    activityName: '',
    performedBy: '',
    reportType:''
  };
  public reportType=[{id:1,name:"Created On"},{id:2,name:"Updated On"}];
  private isActivitySelected:boolean=false;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private activityService: ManageActivitiesService,
    private usersService: UsersService,
    private SpinnerService: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.SpinnerService.show('activity-filter');

    if(this.prevFilterData.hasPrevData){
      this.filterForm = this.fb.group({
        dateRange: [this.prevFilterData.dateRange, [Validators.required]],
        activity: [this.prevFilterData.moduleType, Validators.required],
        performedBy: this.prevFilterData.performedBy? [this.prevFilterData.performedBy.split(',').map(x => +x)]: '',
        reportType:[this.prevFilterData.reportType,Validators.required]
      });
      this.daysSelected = this.prevFilterData.dateRange.split(' - ');

      const firstElement =this.daysSelected[0].split('-');
      this.minDate = new Date(firstElement[2], firstElement[1] - 1, firstElement[0]);

      const possibleMaxDate = moment(this.minDate).add(30,'days');
      this.maxDate = moment.min(possibleMaxDate, moment()).toDate();
      if(this.prevFilterData.reportType!=''){
        this.isActivitySelected=true;
      }
    }else{
      this.filterForm = this.fb.group({
        dateRange: ['', [Validators.required]],
        activity: ['', Validators.required],
        performedBy: [''],
        reportType: ['']
      });
    }

    this.getdynamicFieldNames();
    this.getAllActivitiesList();
    this.getDownHierarchyUsers();
  }

  getdynamicFieldNames() {
    this.subscriptions.push(this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result => {
        result['responseData'].forEach(
          row => {
            if (row.moduleId == 2) {
              this.CompanyFieldName = row.singularName;
              this.activityList[0].activityName = row.singularName;
            }
            if (row.moduleId == 3) {
              this.OpportunityFieldName = row.singularName;
              this.activityList[1].activityName = row.singularName;
            }
            if (row.moduleId == 1) {
              this.ContactFieldName = row.singularName;
              this.activityList[2].activityName = row.singularName;
            }
          }
        );
        this.onResponseSuccess();
      },
      error => {
        this.SpinnerService.hide('activity-filter');
        this.apiResponseSuccess = false;
      }
    ));
  }

  getAllActivitiesList() {
    this.subscriptions.push(this.activityService.getAllActivities(this.selectedProject).subscribe(
      data => {
        data['responseData'].forEach(row => {
          this.activityList.push({
            activityId: row.activityId+"",
            activityName: row.activityName
          })
        });
        this.onResponseSuccess();
      },
      error => {
        this.SpinnerService.hide('activity-filter');
        this.apiResponseSuccess = false;
      }
    ));
  }

  getDownHierarchyUsers() {
    this.subscriptions.push(this.usersService.getOwnerAndUsersDownHierarchy(this.selectedProject).subscribe(
       users => {
    this.usersList = users['responseData'];
    this.onResponseSuccess();
       },
     error => {
       this.SpinnerService.hide('activity-filter');
       this.apiResponseSuccess = false;
     }
    ));
  }

  onResponseSuccess() {
    this.apiResponseCount++;
    if (this.apiResponseCount == this.EXPECTED_API_RESPONSE_COUNT) {
      this.SpinnerService.hide('activity-filter');
      this.showModal=true
      this.apiResponseSuccess = true;
    }
  }

  formSubmit() {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      console.log("INVALID");
      return;
    }
    // this.filterForm.get('dateRange').setValue(new DatePipe('en-US').transform(this.filterForm.get('dateRange').value, 'dd/MM/yyyy'));
    this.FilterFormData.dateRange = this.filterForm.get('dateRange').value;
    this.FilterFormData.moduleType = this.filterForm.get('activity').value;
    this.FilterFormData.activityName = this.activityList.filter(el => el.activityId == this.FilterFormData.moduleType)[0].activityName;
    this.FilterFormData.performedBy = this.filterForm.get('performedBy').value.toString();
    this.FilterFormData.reportType=this.filterForm.get('reportType').value;

    console.log(this.FilterFormData);
    this.activeModal.close(this.FilterFormData);
  }

  hasError(controlName: string, errorName: string) {
    return this.filterForm.get(controlName).hasError(errorName);
  }

  // date range ==========>

  isSelected = (event: any) => {
    const date = ('00' + event.getDate()).slice(-2) + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + event.getFullYear();

    if (this.daysSelected.length === 2) {
      let startDate = this.daysSelected[0].split('-');
      startDate = new Date(startDate[2], startDate[1] - 1, startDate[0]);

      let endDate = this.daysSelected[1].split('-');
      endDate = new Date(endDate[2], endDate[1] - 1, endDate[0]);

      if (this.daysSelected[0] == date) {
        return 'kt-date-range-selected-first-date';
      }
      else if (this.daysSelected[1] == date) {
        return 'kt-date-range-selected-last-date';
      }
      else if (moment(event).isBetween(startDate, endDate)) {
        return 'kt-date-range-date-in-range';
      }
    }

    return this.daysSelected.find((x) => x == date) ? 'kt-date-range-selected' : null;
  };

  select(event: any, calendar: any) {

    const date = ('00' + event.getDate()).slice(-2) + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + event.getFullYear();

    const index = this.daysSelected.findIndex((x) => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    if (this.daysSelected.length > 2) this.daysSelected.splice(0, 2);
    if (this.daysSelected.length === 1) {
      const firstElement = this.daysSelected[0].split('-');
      this.minDate = new Date(firstElement[2], firstElement[1] - 1, firstElement[0]);

      const possibleMaxDate = moment(this.minDate).add(30,'days');
      this.maxDate = moment.min(possibleMaxDate, moment()).toDate();

      this.filterForm.get('dateRange').markAsUntouched();
      this.filterForm.get('dateRange').setValue(this.daysSelected[0]);
    }
    else if (this.daysSelected.length === 2) {
      this.filterForm.get('dateRange').setValue(this.daysSelected[0] + ' - ' + this.daysSelected[1]);
    }
    else {
      this.minDate = '';
      this.maxDate = new Date();
      this.filterForm.get('dateRange').setValue('');
      this.filterForm.get('dateRange').markAsTouched();
    }
    calendar.updateTodaysDate();
  }
ngOnDestroy(){
  this.subscriptions.forEach(sb => sb.unsubscribe());
}


 /** For  Select All in Multiselect */

 toggleAllSelection(matSelect: MatSelect) {
  if (this.allSelected) {
    matSelect.options.forEach((item: MatOption) => {(item.value!==undefined && item.value!==null) ? item.select() : null});
  } else {
    matSelect.options.forEach((item: MatOption) => item.deselect());
  }
}
 optionClick(matSelect: MatSelect) {
  let newStatus = true;
  matSelect.options.forEach((item: MatOption) => {
    if (!item.selected) {
      newStatus = false;
    }
  });
  this.allSelected = newStatus;
}
onActivityChange(){
  this.isActivitySelected=true;
  this.filterForm.get('reportType').reset();
  this.filterForm.get('reportType').clearValidators();
  this.filterForm.get('reportType').setValidators(Validators.required)
}
}
