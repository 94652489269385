import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes} from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '../shared/shared.module';
import { ManageActivitiesComponent } from './manage-activities.component';
import { AddActivityComponent } from './_subs/add-activity/add-activity.component';
import { EditCellRendererComponent } from './_subs/edit-cell-renderer/edit-cell-renderer.component';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';

import { AgGridModule } from 'ag-grid-angular';
import { DeleteActivityAlertComponent } from './_subs/delete-activity-alert/delete-activity-alert.component';

const routes:Routes=[
  {
    path:'',
    component:ManageActivitiesComponent
  }
]

@NgModule({
  declarations: [
    ManageActivitiesComponent, 
    AddActivityComponent, 
    EditCellRendererComponent, DeleteActivityAlertComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    NgbModule,
    RouterModule.forChild(routes),
    AgGridModule.withComponents([EditCellRendererComponent])
  ],
  exports:[],
  entryComponents:[
    AddActivityComponent,
    AlertDialogComponent,
    DeleteActivityAlertComponent

  ]
})
export class ManageActivitiesModule { }
