<div class="example-preview">
    <div>
        <form [formGroup]="relationshipForm" autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Manage Dependency</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-6">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-label>Parent Field <span class="mandatory-marker">*</span></mat-label>
                            <mat-select placeholder="" #parent formControlName="parentField" (selectionChange)="change($event.value)">
                                <mat-option>    
                                     <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="parentFieldFilter" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let custom of resultCustom | search : parentFieldFilter.value : 'attributeName'" [value]="custom">
                                    {{custom.attributeName}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="isControlHasError('parentField', 'required')">Parent field cannot be blank</mat-error>
                            <mat-hint>Select the field for which dependent field will be visible</mat-hint>                            
                        
                        </mat-form-field>                        
                    </div>
                    <div class="col-md-6">
                      
                        <mat-form-field class="mat-form-field-fluid input-100">   
                            <input  placeholder="Dependent Field" matInput #dependent formControlName="dependentField" >
                            
                        </mat-form-field>                    
                    </div>
                </div>

                <div class="form-group row">
                    <div class="col-md-6 p-0 border">   
                        <div *ngIf="selectedParent">                 
                           
                            <div class="cardCSS" >
                                <span>{{selectedParent}}</span>
                            </div>
    
                            <div class="col-md-12  pl-0 pr-0 pt-0 pb-8">  
                                <mat-form-field  class="mat-form-field-fluid input-100 p-5">   
                                    <input   matInput #search formControlName="searchParent" (input)="searchThisForParent()" >
                                    <mat-hint>Enter value to search</mat-hint>                            
                            
                                </mat-form-field>    

                                <mat-selection-list  [multiple]="false" formControlName="selectedParent">
                                    <mat-list-option class="textSize" *ngFor="let lookup of parentLookupValues" (click)="onSelectedParent(lookup.id)" [value]="lookup.id">
                                        {{lookup.lookupValue}}
                                    </mat-list-option>
                                </mat-selection-list>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 pr-0">
                        <div class="p-0 border">             
                            <div class="cardCSS">
                                <span>{{dependent.value}}</span>
                            </div>
                            <div class="col-md-12  pl-0 pr-0 pt-0 pb-8" *ngIf="ifSingle; else notSingle" >
                                <mat-form-field class="mat-form-field-fluid input-100 p-5">   
                                    <input   matInput formControlName="searchDep" (input)="searchThisForDep()" >
                                    <mat-hint>Enter value to search</mat-hint>                            
                            
                                </mat-form-field>
                                <mat-selection-list #dependentFormValue formControlName="selectedOptions" (selectionChange)="onSelectedDependent($event)" >
                                    <mat-list-option checkboxPosition="before" class="colorChange textSize" [value]="-1">
                                        Select All
                                    </mat-list-option>
                                    <mat-list-option checkboxPosition="before" class=" colorChange textSize" *ngFor="let lookup of selectedLookupValues"  [value]="lookup.id">
    
                                        {{lookup.lookupValue}}
                                    
                                    </mat-list-option>
                                </mat-selection-list>                           
                            </div> 
                          
                            <ng-template #notSingle>
                                <div class="col-md-12" >
                                
                                    <!-- <mat-selection-list #dependentFormValue formControlName="selectedOptions" (selectionChange)="onSelectedMandatory($event)">
                                    
                                            <mat-list-option checkboxPosition="before" class="textSize" [value]="1" >Make Dependent </mat-list-option>
                                            <mat-hint class="hint">Select to display the field</mat-hint>                            
                            
                                    </mat-selection-list>   -->
                                    
                                    <div class="form-group row pt-4">
                                        <div class="col-md-5">
                                            <label>Make Dependent</label>
                                        </div>
                                        <div class="col-md-7">
                                            <mat-checkbox #dependentFormValue formControlName="selectedOptions" (change)="onSelectedMandatory($event)"> </mat-checkbox>
                                            &nbsp; Yes <br>
                                            <mat-hint>Select to display the field</mat-hint>  
                                        </div>
                                    </div>
                                                
                                </div> 
                            </ng-template>
                        </div>                
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <div class="row">             
                    <button *ngIf="isEdit" type="button" class="btn btn-primary" style="margin-right: 457px;" [disabled]='isLinkedFieldRelaion || isDisabled' (click)="delete()">Delete Relationship</button>  
                    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
                    &nbsp; &nbsp;
                    <button type="button" class="btn btn-primary" [disabled]='isLinkedFieldRelaion || isDisabled' (click)="Save()">Save</button>
                </div>
            </div>

        </form> 
    </div>
</div>  
