import { Component, OnInit, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DashboardService } from '../../services/dashboard.service';
import { AddDashboardReportsComponent } from '../add-dashboard-reports/add-dashboard-reports.component';
import { CloneDashboardComponent } from '../clone-dashboard/clone-dashboard.component';
import { DashboardDialogOptionComponent } from '../dashboard-dialog-option/dashboard-dialog-option.component';
import { DeleteDashboardAlertComponent } from '../delete-dashboard-alert/delete-dashboard-alert.component';
import { EditDashboardAlertComponent } from '../edit-dashboard-alert/edit-dashboard-alert.component';
import { EditReportAlertComponent } from '../edit-report-alert/edit-report-alert.component';

@Component({
  selector: 'kt-report-tabs',
  templateUrl: './report-tabs.component.html',
  styleUrls: ['./report-tabs.component.scss']
})
export class ReportTabsComponent implements OnInit {
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  @ViewChild('myButton') myButton : ElementRef;
  @Input() public dashboardData;
  @Input() public editFlagFromParent;
  @Output() relodDashboardList = new EventEmitter<{isOpenClone:boolean,isEditMode:boolean,dashboardId:number}>();
  isEdit=false;
  public editDashForm: FormGroup;
  errorMes:String="";
  disabled:boolean;
  public shareWith: number = null;
  public isLoadingUsers: boolean = false;
  public isLoadingSharingDetail: boolean = false;
  roles: any = [];
  users: any = [];
  selectedRoles: any = [];
  selectedUsers: any = [];
  isFirstDelete=true;
  dashboardReports=[];
  modalReference: any;
  public shareWithDrp = [
    { id:0, value: 'All'},
    { id: 2, value: 'Roles'},
    { id: 3, value: 'Users'}
  ];
  removedReportIds=[];
  addedReportIds=[];
  sharedDetailsChanged=false;
  isRelodeNeeded=false;
  isDisabled=false;
  
  constructor(public dialog: MatDialog,
    private fb:FormBuilder, 
    public _dbs: DashboardService,
    private modalService: NgbModal,
    private router: Router,
    public snackBar: MatSnackBar
    ) { }

  ngOnInit(): void {
    this.dashboardData.reportIds.forEach(element => {
      this.dashboardReports.push(element)
    });
    this.isEdit=this.editFlagFromParent;
    if(this.isEdit){
      this.initaddeditDashForm();
      this.getRoles();
      this.getUsers();
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DashboardDialogOptionComponent, {
      width: '275px',
      position: {
        'top': '240px',
        'right': '55px',
    }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result=='edit'){
        if(this.dashboardData.canBeEdited){
          this.isEdit=true;
          this.initaddeditDashForm();
          this.getRoles();
          this.getUsers();
          this.getSharedDetails();
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click();
         }, 300);
        }
        else{
          const ref=this.modalService.open(EditDashboardAlertComponent,
            {  
              centered: false,
              size : 'lg',
              backdrop : 'static'
            });
            ref.result.then((result) => {
              if(result){
                this.openCloneDashboard(true);
              }
            })
        }
      }

      if(result=='clone'){
        this.openCloneDashboard(false);
    }
  });
  }

  openCloneDashboard(isEdit){
    const ref=this.modalService.open(CloneDashboardComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });
      ref.componentInstance.previousDashboardName=this.dashboardData.dashboardName;
      ref.componentInstance.previousDashboardId=this.dashboardData.dashboardId;
      ref.result.then((result) => {
        if(result){
          this.relodDashboardList.emit({isOpenClone:true,isEditMode:isEdit,dashboardId:result});
        }
      })
  }

  initaddeditDashForm(){
    this.editDashForm = this.fb.group({
      dashBoardName: [this.dashboardData.dashboardName, Validators.compose([Validators.required,Validators.maxLength(25) ])],
      shareWith:['0'],
      selectRoles:[''],
      selectUsers:[''],

     });
  }

  isControlHasError(controlName: string, validationType: string): boolean {
    const control = this.editDashForm.controls[controlName];
      if (!control) {
        return false;
      }

      const result = control.hasError(validationType) && (control.dirty || control.touched);
      return result;
  }

  changeShareWith(item) {
    console.log(item);
    this.sharedDetailsChanged=true;
    if(item==2){
      this.selectedRoles = [];
      this.editDashForm.controls.selectRoles.setValidators(Validators.required);
      this.editDashForm.controls.selectRoles.updateValueAndValidity();
      this.editDashForm.controls.selectUsers.clearValidators();
      this.editDashForm.controls.selectUsers.updateValueAndValidity();
    }
  else if(item==3){
    this.selectedUsers = [];
    this.editDashForm.controls.selectUsers.setValidators(Validators.required);
    this.editDashForm.controls.selectUsers.updateValueAndValidity();
    this.editDashForm.controls.selectRoles.clearValidators();
    this.editDashForm.controls.selectRoles.updateValueAndValidity();
  }
  else{
    this.selectedRoles=[];
    this.selectedUsers=[];
    this.editDashForm.controls.selectUsers.clearValidators();
    this.editDashForm.controls.selectUsers.updateValueAndValidity();
    this.editDashForm.controls.selectRoles.clearValidators();
    this.editDashForm.controls.selectRoles.updateValueAndValidity();
  }
    
  }

  changeShareIds(){
  this.sharedDetailsChanged=true;
  }

  getRoles() {
    this._dbs.getRoles(this.selectedProject).subscribe( data => {
        if (data.statusCode == 200) {
          this.roles = data.responseData;
        }
    });
  }

  getUsers() {
    this._dbs.getUsers(this.selectedProject).subscribe( data => {
        if (data.statusCode == 200) {
          this.users = data.responseData;
        }
    });
  }

  addReport(){
    const ref=this.modalService.open(AddDashboardReportsComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });
      ref.componentInstance.dashboardId=this.dashboardData.dashboardId;
      ref.componentInstance.editFlag=true;
      ref.result.then((result) => {
        if(result){
         this.isRelodeNeeded=true;
         this.addedReportIds=result;
        }
      })
  
}

removeReport(event){
this.isRelodeNeeded=true;
const removeIndex= this.dashboardReports.findIndex(report=>report.reportId==event);
if(removeIndex!=-1){
  this.dashboardReports.splice(removeIndex,1);
  this.removedReportIds.push(event);
}
}

editReport(event){
    localStorage.setItem('reportId', event.reportId)
    localStorage.setItem('sharedWithOthers', event.canShareWithOthers)
    localStorage.setItem('currentProjectId', this.selectedProject)
  if(event.canShareWithOthers) { 
    this.router.navigateByUrl('/report-builder-edit');
    return;
  }
  const ref=this.modalReference = this.modalService.open(EditReportAlertComponent,
    {
      centered: false,
      size : 'lg',
      backdrop : 'static'
    });
  }


  cancel(){
    this.isEdit=false;
    this.dashboardReports=[];
    this.dashboardData.reportIds.forEach(element => {
    this.dashboardReports.push(element)
    });
    this.removedReportIds=[];
    this.addedReportIds=[];
  }

  deleteDashboard(){
    this.isDisabled=true;
    const ref=this.modalService.open(DeleteDashboardAlertComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });
      ref.result.then((result) => {
        if(result){
          this._dbs.deleteDashboard(this.selectedProject,this.dashboardData.dashboardId).subscribe(data=>{
            this.isDisabled=false;
            this.snackBar.open( 'Dashboard deleted successfully', '', {
              duration: 3000,
              panelClass: ['green-snackbar']
            });
            this.relodDashboardList.emit({isOpenClone:false,isEditMode:false,dashboardId:0});
          },
          error=>{
            this.isDisabled=false;
            this.snackBar.open( 'Failed', '', {
              duration: 3000,
              panelClass: ['red-snackbar']
            });
          })
          }
          else{
           this.isDisabled=false;
          }
          let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
      setTimeout(() => {
        el.click();
       }, 300);
      });
      
    
  }

  save(){
    this.isDisabled=true;
    const controls = this.editDashForm.controls;
		/** check form */
		if (this.editDashForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
      this.isDisabled=false;
      return;
  }
    this._dbs.checkDashboardName(this.selectedProject, this.editDashForm.controls.dashBoardName.value, this.dashboardData.dashboardId).subscribe(
      data=>{
        this.isDisabled=false;
        let updateDashBoardDetails={
          dashboardName:this.editDashForm.controls.dashBoardName.value,
          shareType:this.editDashForm.controls.shareWith.value, 
          sharedIds:[], 
          projectId:this.selectedProject, 
          dashboardId:this.dashboardData.dashboardId, 
          removedReports:this.removedReportIds, 
          addedReports:this.addedReportIds, 
          sharingDetailsChanged:this.sharedDetailsChanged
        };
          if( this.editDashForm.controls.shareWith.value==2){
            updateDashBoardDetails.sharedIds=this.editDashForm.controls.selectRoles.value;
          }
          if( this.editDashForm.controls.shareWith.value==3){
            updateDashBoardDetails.sharedIds=this.editDashForm.controls.selectUsers.value;
          }
          console.log("updateDashBoardDetails",updateDashBoardDetails);
          this._dbs.updateDashboard(updateDashBoardDetails).subscribe(
      data => {
        this.isDisabled=false;
        this.isEdit=false;
        if(this.isRelodeNeeded){
          this.relodDashboardList.emit({isOpenClone:false,isEditMode:false,dashboardId:0});
        }
        this.snackBar.open('Dashboard updated successfully.', '', {
          duration: 3000,
          panelClass: ['green-snackbar']
        });
        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
          setTimeout(() => {
            el.click();
           }, 300);
      },
      error => {
        this.isDisabled=false;
        console.log('error occured',error);
        this.snackBar.open('Failed.', '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }

    );
      },
      error=>{
        this.isDisabled=false;
        this.snackBar.open(`A dashboard with the name ${this.editDashForm.controls.dashBoardName.value} already exist in this project.`, '', {
          duration: 3000,
          panelClass: ['red-snackbar']
        });
      }
    );
   }

   getSharedDetails(){
     this._dbs.getSharedDetails(this.selectedProject,this.dashboardData.dashboardId).subscribe(data=>{
       this.editDashForm.controls.shareWith.setValue(data['responseData'].shareType)
       if(data['responseData'].shareType==2){
         this.editDashForm.controls.selectRoles.setValue(data['responseData'].sharedIds)
         let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
         setTimeout(() => {
            el.click();
          }, 300);
        }
        if(data['responseData'].shareType==3){
          this.editDashForm.controls.selectUsers.setValue(data['responseData'].sharedIds)
          let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
          setTimeout(() => {
            el.click();
           }, 300);
        }
        this.changeShareWith(this.editDashForm.controls.shareWith.value);
       })
   }
  
   dashboardNameChange(){
     this.isRelodeNeeded=true;
   }
/**
   * For quick display ag-grid data
   */
  triggerClick(){}

}
