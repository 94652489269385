import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'kt-delete-activity-alert',
  templateUrl: './delete-activity-alert.component.html',
  styleUrls: ['./delete-activity-alert.component.scss']
})
export class DeleteActivityAlertComponent implements OnInit {
  activityName:String=""
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  confirmDelete(){
    this.activeModal.close(true);
  }

  closeModal(){
    this.activeModal.close();
  }
}
