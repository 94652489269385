import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { roleModel } from 'src/app/views/pages/user-role/models/role.model';
import { environment } from 'src/environments/environment';
import { ActionType, BusyStatus, CarouselOutputEvent, ErrorType } from '../../../../models/carousel-output-event.model';
import { UploadImageService } from 'src/app/views/pages/shared/services/upload-image.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-carousel-item',
  templateUrl: './carousel-item.component.html',
  styleUrls: ['./carousel-item.component.scss']
})
export class CarouselItemComponent implements OnInit {

  @Input('id') id: number;
  @Input('imagePath') imagePath: string;
  @Input('userRoles') allUserRoles: Array<roleModel>;
  @Input('hyperLink') hyperLink: string = '';
  @Input('selectedUserRoles') selectedUserRoles: Array<number> = [];
  @Input("uploadFunctionality") uploadFunctionality: boolean;

  @Output('hyperlinkChanged') _hyperlinkChanged = new EventEmitter<CarouselOutputEvent>();
  @Output('userRoleChanged') _userRoleChanged = new EventEmitter<Array<number>>();
  @Output('busyStatusChanged') _busyStatus = new EventEmitter<BusyStatus>();
  @Output('imageUpdated') _imageUpdated = new EventEmitter<CarouselOutputEvent>();
  @Output('newCarouselAdded') _newCarouselAdded = new EventEmitter<string>();


  private readonly selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  private readonly hyperlinkRegex = /^(https?:\/\/|www\.)[a-zA-Z0-9-]+(\.[a-zA-Z]{1,})+(\S*)$/;

  imageUrl: string = '';
  allowImageEdit: boolean = false;
  hyperlinkInvalid = false;
  _hyperlinkInvalidStatus = false;
  UPLOAD_CAROUSEL = 'UPLOAD_CAROUSEL';
  UPDATE_CAROUSEL = 'UPDATE_CAROUSEL';
  readonly MAX_ALLOWED_SIZE: number = 1; //MB
  fileTypeError: boolean = false;
  fileSizeError: boolean = false;

  constructor(public snackBar: MatSnackBar, private _us: UploadImageService) { }

  ngOnInit(): void {
    if (this.id === undefined || this.id === null) {
      this.id = Math.floor(Math.random() * (10000 - 1 + 1)) + 1;
    }console.log("The id: ", this.id);
    this.UPDATE_CAROUSEL += this.id;
    this.UPLOAD_CAROUSEL += this.id;
    this.imageUrl = environment.imageUrl + this.imagePath;
    this.checkIfHyperlinkValid(this.hyperLink, 0);
  }

  hyperlinkChanged(hyperLink: string) {
    hyperLink = hyperLink.trim();
    this.checkIfHyperlinkValid(hyperLink, 1);
  }

  checkIfHyperlinkValid(hyperLink: string, action: ActionType): void {
    this.hyperlinkInvalid = (hyperLink !== null && hyperLink !== undefined && hyperLink.length > 0) && !this.hyperlinkRegex.test(hyperLink);
    this._hyperlinkInvalidStatus = this.hyperlinkInvalid;
    const errorType: ErrorType = this.hyperlinkInvalid ? 'hyperlinkPattern' : null;
    this._hyperlinkChanged.emit(new CarouselOutputEvent(hyperLink, this.hyperlinkInvalid, errorType, '', action));
  }

  userRoleChanged(userRoles: Array<number>) {
    this._userRoleChanged.emit(userRoles);
  }

  preventEnter(event: KeyboardEvent) {
    event.preventDefault();
  }

  showImageEditIcon() {
    this.allowImageEdit = true;
  }

  hideImageEditIcon() {
    this.allowImageEdit = false;
  }

  uploadCarousel() {
    let element: HTMLElement = document.getElementById(this.UPLOAD_CAROUSEL) as HTMLElement;
    element.click();
  }

  updateCarousel() {
    let element: HTMLElement = document.getElementById(this.UPDATE_CAROUSEL) as HTMLElement;
    element.click();
  }

  uploadCarouselFile(event: Event) {
    this.fileTypeError = false;
    this.fileSizeError = false;
    const inputElement = event.target as HTMLInputElement;
    const file: File = inputElement.files[0];
    inputElement.value = null;
    if (!file) return;
    const isValidFileType = this.isValidFileType(file);
    const isValidFileSize = this.isValidFileSize(file);
    if (!isValidFileType || !isValidFileSize) {
      this.handleFileError(isValidFileType, isValidFileSize)
      return;
    }
    this._busyStatus.emit('busy');
    this._us.uploadImage(5, file, this.selectedProject).subscribe(res => {
      const carouselImgName: string = res.responseData.name;
      const carouselImgUrl: string = res.responseData.path;
      if (this.uploadFunctionality) {
        this._newCarouselAdded.emit(carouselImgName);
      } else {
        this.imageUrl = carouselImgUrl;
        this._imageUpdated.emit(new CarouselOutputEvent(carouselImgName, false));
      }

      this._busyStatus.emit('free');
      this.snackBar.open('Image Added! Configure the hyperlink & User Roles to complete the configuration and Save the page.', '', {
        duration: 10000,
        panelClass: ['green-snackbar']
      });
    },
      () => {
        this._busyStatus.emit('free');
      }
    );
  }
  handleFileError(isValidFileType: boolean, isValidFileSize: boolean) {
    if (this.uploadFunctionality) {
      this.fileTypeError = !isValidFileType;
      this.fileSizeError = !isValidFileSize;
    } else {
      let errorType: ErrorType = 'unknown';
      let errorMsg = 'Error';
      if (!isValidFileType) {
        errorType = 'fileType';
        errorMsg = 'Only .jpeg and .png files are allowed. Please select a valid file.';
      } else if (!isValidFileSize) {
        errorType = 'fileSize';
        errorMsg = 'File size should be up to 1MB. Please select a smaller file.';
      }
      this._imageUpdated.emit(new CarouselOutputEvent(null, true, errorType, errorMsg, 1));
    }
  }

  isValidFileSize = (file: File): boolean => (file.size / 1024) <= (this.MAX_ALLOWED_SIZE * 1024);

  isValidFileType = (file: File): any => new RegExp('^image\/(jpeg|png)$').test(file.type);

}
