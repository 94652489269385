<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<kt-portlet> 
    <kt-portlet-body style="padding:16px 16px 0px 16px;">
        <div class="txt-middle" *ngIf="dashboardTabs?.length==0">No Dahboard Available!</div>
        <mat-tab-group [(selectedIndex)]="selectedTab" >           
            <mat-tab label="{{dashboard.dashboardName}}" *ngFor="let dashboard of dashboardTabs" >
                <ng-template matTabContent>
                    <kt-report-tabs [dashboardData]="dashboard" [editFlagFromParent]="openInEditMode" (relodDashboardList)="relodDashboardList($event)"></kt-report-tabs>
                </ng-template>
            </mat-tab>
        </mat-tab-group>
    </kt-portlet-body>
</kt-portlet>
<ngx-spinner size="default" type="ball-spin-clockwise" name='dashboard-tab'>  
    <p style="color: white">Fetching Data... </p>  
</ngx-spinner>
  
