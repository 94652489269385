import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddNewCompanyComponent } from '../custom-add/add-new-company/add-new-company.component';

@Component({
  selector: 'kt-preview-display-order',
  templateUrl: './preview-display-order.component.html',
  styleUrls: ['./preview-display-order.component.scss']
})
export class PreviewDisplayOrderComponent implements OnInit {

  moduleType:string | number;
  singularName: string;
  @Input() public parentData;
  @Input() public singularData;

  
  modalReference: any;
  someEventEmitter: any;
  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    
    console.log(this.moduleType);
    this.singularName=this.singularData;
      
  }

  OpenAppView(){
    this.modalReference = this.modalService.open(AddNewCompanyComponent,
      { 
        centered: false,
        backdrop : 'static',
        windowClass: 'my-class'
      }
    );
    this.modalReference.componentInstance.moduleType=this.parentData;
    this.modalReference.componentInstance.viewMode='AppPreview';
    this.modalReference.componentInstance.singularName=this.singularName;
    this.modalReference.componentInstance.preview=true;

  }

  OpenWebView(){
    this.modalReference = this.modalService.open(AddNewCompanyComponent,
      { 
        centered: false,
        size : 'lg',
        backdrop : 'static'
      }
    );
    this.modalReference.componentInstance.moduleType=this.parentData;
    this.modalReference.componentInstance.viewMode='WebPreview';
    this.modalReference.componentInstance.singularName=this.singularName;
    this.modalReference.componentInstance.preview=true;

  }

  

}
