<div class="example-preview">
    <div>
        <form [formGroup]="FilterForm" novalidate autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Filter</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="filterName" [(ngModel)]="filterName" ktAutoTrimSpaces>
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
                <div *ngIf="displayNumeric || displayText" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label pt-6">Condition
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="condition" (selectionChange)="changeCondition($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="conditionValueType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let condition of conditionTypeList| search : conditionValueType.value :'value'" [value]="condition.id">
                                    {{condition.value}}
                                </mat-option>
                            </mat-select>
                            <mat-error class="form-error" *ngIf="isControlHasError('condition', 'required')">Select one.</mat-error>
                        </mat-form-field>
                    </div>  
                </div>
                <div *ngIf="displayNumeric" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Value
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="value" [(ngModel)]="value" ktAutoTrimSpaces>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                            <mat-error class="form-error" *ngIf="isControlHasError('value', 'required')">Value field connot be empty.</mat-error>
                        </mat-form-field>                    
                    </div>
                </div>
                <div *ngIf="displayText" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Value
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="value" [(ngModel)]="value" ktAutoTrimSpaces>
                            <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon>
                            <mat-error class="form-error" *ngIf="isControlHasError('value', 'required')">Value field connot be empty.</mat-error>
                        </mat-form-field>                    
                    </div>
                </div>
                <div *ngIf="displayDate">
                    <div class="form-group row " style="padding-top: 10px;">
                        <div class="col-md-4">
                            <label>Value Type
                                <span class="mandatory-marker">*</span>
                            </label>
                        </div>
    
                            <div class="col-md-8">
                                <mat-radio-group formControlName="dateFilterType" fxLayout="column" fxLayoutGap=".25rem"  (change)="handleValueType()" [(ngModel)]="dateFilterType">
                                <mat-radio-button [value]="1">Standard</mat-radio-button>
                                <mat-radio-button [value]="2">Custom</mat-radio-button>
                                <mat-error class="form-error" *ngIf="isControlHasError('dateFilterType', 'required')">Select one.</mat-error>
                                    </mat-radio-group>
                            </div>    
                        </div>
                    </div>
                    <div *ngIf="displayDefaultDate || isDisplayStandard()">
                    
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Value
                                    <span class="mandatory-marker">*</span>
                                </label>
                        </div>
                        <div class="col-md-8 form-padding-top-15">
                            <mat-form-field class="mat-form-field-fluid input-100">
                                <mat-select formControlName="standardTypeValue" (selectionChange)="changeStd($event.value)" >
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="standardFilterType" ></ngx-mat-select-search>
                                    </mat-option>
                                   <!--<mat-option *ngFor="let standardTypeValue of standardTypelist; let i = index;" [value]="standardTypeValue.id">
                                        {{standardTypeValue.value}}
                                    </mat-option> -->
                                    <mat-option *ngFor="let standardTypeValue of standardTypelist| search : standardFilterType.value :'value'" [value]="standardTypeValue.id">
                                        {{standardTypeValue.value}}
                                    </mat-option>
                                    <mat-error *ngIf="isControlHasError('standardTypeValue', 'required')">Select one.</mat-error>
                                </mat-select>
                            </mat-form-field>
                        </div>  
                        </div>
                    </div>
                    <div *ngIf="displayDefaultCustom ||  isDisplayCustom()">
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Previous Dates
                                    <span class="mandatory-marker">*</span>
                                </label>
                            </div>
    
                            <div class="col-md-8">
                                <mat-form-field appearance="standard" class="input-100">
                                    <input  matInput formControlName="customPreviousDays"  ktStrictNumberOnlyDirective [(ngModel)]="customPreviousDays">
                                    <mat-hint>Only numeric values are allowed. Number of days before current date.</mat-hint>
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                    <mat-error *ngIf="isControlHasError('customPreviousDays', 'required')">Select one.</mat-error>
                                </mat-form-field>                    
                            </div>
                        </div>
                        
                        <div class="form-group row">
                            <div class="col-md-4">
                                <label class="col-form-label form-padding-top-25">Future Dates
                                    <span class="mandatory-marker">*</span>
                                </label>
                            </div>
    
                            <div class="col-md-8">
                                <mat-form-field appearance="standard" class="input-100">
                                    <input  matInput formControlName="customFutureDays"  ktStrictNumberOnlyDirective [(ngModel)]="customFutureDays">
                                    <mat-hint>Only numeric values are allowed. Number of days after current date.</mat-hint>
                                    <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">format_list_numbered</mat-icon>
                                    <mat-error *ngIf="isControlHasError('customFutureDays', 'required')">Select one.</mat-error>
                                </mat-form-field>                    
                            </div>
                        </div>
                    </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger"  (click)="Delete()">Delete Field</button>
                <td style="padding-right: 195px;"></td>
                <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"   (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  
