<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<div class="d-flex flex-column flex-root">
    
    <div class="example-preview pl-6 pr-6" style="background-color:  #FFFFFF;">
        <div class="modal-header">
            <h4 class="modal-title">Add New </h4>
        </div>
        <div *ngIf=isActivityListNotEmpty>
            <div class="form-group row">

                <div class="col-md-4">
                    <label class="col-form-label form-padding-top-25">Activity
                        <span class="mandatory-marker">*</span>
                    </label>
                </div>
                <div class="col-md-8">
                    <mat-form-field appearance="standard" class="input-100">
                        <mat-select [(ngModel)]="selectedActivity" (selectionChange)='changeActivity($event.value)' [disabled]="isActivityFieldDisabled">
                            <mat-option>
                                <ngx-mat-select-search placeholderLabel="Search" noEntriesFoundLabel="No options found"
                                    #singleDropdownFilter1></ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="
                            let activity of activityList
                              | search: singleDropdownFilter1.value:'activityName'
                          " [value]="activity.activityId">
                                {{ activity.activityName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
<!-- activity field -->
            <form [formGroup]="addCompaniesForm" autocomplete="off" style="background-color: #FFF; border-radius: 10px;">
                <div id="div_{{customAttr.combinedAttributeName}}_{{customAttr.id}}" [ngClass]="{'hidden': customAttr.parentAttributeId !==0 && customAttr.parentAttributeId !==null}"
            class="form-group row" *ngFor="let customAttr of customAttributesList">

               <div class="col-md-4">
                   <label class="col-form-label form-padding-top-25" [ngClass]="{'header-text': customAttr.attributeType.id == 10, 'normal-text': customAttr.attributeType.id == 11}">{{customAttr.attributeName}}
                    <span class="mandatory-marker">{{customAttr.mandatoryFlag === 1 ? '*' : ''}}</span>
                   </label>
               </div>
                                                           
               <!-- first div (single select)-->  
               
               <div id="select_{{customAttr.id}}" class="col-md-8" *ngIf="customAttr.attributeType.id==1 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) ; else systemAttrbitues ">
                   <mat-form-field  appearance="standard" class="input-100">
   
                       <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr)">
                           <mat-option>
                               <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="singleDropdownFilter" ></ngx-mat-select-search>
                           </mat-option>
                           <mat-option *ngFor="let values of customAttr.lookupValues| search : singleDropdownFilter.value : 'lookupValue'" [value]="values.id">
                               {{values.lookupValue}}
                           </mat-option>
                           
                       </mat-select>
                       <mat-icon  *ngIf="customAttr.systemAttribute.id==66 || (customAttr.systemAttribute.id==25 && isSystemAttribute25Disabled)" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     

                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one.</mat-error>                      
       
                   </mat-form-field>                    
               </div>                   

               <!-- product form -->
               <div id="select_{{customAttr.id}}" class="col-md-12" *ngIf="customAttr.attributeType.id==13 && customAttr.parentFormId !==null ">
                   <!-- <ng-template #systemAttrbituesM > -->
           
                   <div *ngIf="selectedActivity > 100  && customAttr.parentFormId.id !==null && customAttr.parentFormId.id !==0" style="font-size:10px; padding:0 0 0 8px;">
                       <div >
                           <div class="form-group row" style="overflow-x: auto;" [formArrayName]="customAttr.combinedAttributeName" *ngIf="productFormFields[customAttr.combinedAttributeName]">
                               <div class="row">
                                   <td>                                            
                                       <tr>
                                           <table class="style">
                                               <tr>
                                                   <th *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">{{field.attributeName}}</th>
                                                   <th *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1">Action</th>
                                               </tr>
                                               <tr *ngFor="let item of getControls(customAttr.combinedAttributeName); let i=index; last as isLast" [formGroupName]="i">
                                                       <td *ngFor="let field of productFormFields[customAttr.combinedAttributeName]?.fields">
                                                           <mat-form-field  class="inputbox" *ngIf="field.attributeType.id!=13 && (field.attributeType.id==7 || field.attributeType.id==14)"> 
                                                               <input [readonly]='field.systemAttribute?.id == 50 || field.systemAttribute?.id == 52 || field.attributeType.id==14' (input)="calculateSummeryFieldsIfMatches(customAttr.combinedAttributeName, field.combinedAttributeName)" matInput formControlName="{{field.combinedAttributeName}}" [required]="isMandatory(customAttr.mandatoryFlag)" [ktStrictNumberOnlyDirective]="field.decimalFlag" (change)="productFormFieldChange(customAttr.combinedAttributeName,i,field,$event.target.value)">  
                                                           </mat-form-field>
                                                           <mat-form-field class="inputSelect"  *ngIf="field.attributeType.id==13 && field.systemAttribute && field.systemAttribute.id==48">
                                                               <input *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView===1" matInput formControlName="{{productFormFields[customAttr.combinedAttributeName].productFieldNameInput}}">  
                                                               <mat-select *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!==1" formControlName="{{field.combinedAttributeName}}" [required]="isMandatory(customAttr.mandatoryFlag)" (selectionChange)="updateItems($event.value,i,customAttr.combinedAttributeName,customAttr.parentFormId)">
                                                                   <!-- <mat-option>
                                                                       <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="skuFilterType" ></ngx-mat-select-search>
                                                                   </mat-option> -->
                                                                   <mat-option *ngFor="let sku of productFormIdWithProductIdNameMap.get(customAttr.parentFormId)" [value]="sku.productId" >
                                                                       {{sku.productName}}
                                                                   </mat-option>
                                                               </mat-select>
                                                            </mat-form-field>
                                                            <!-- form here (Date)  -->
                                                            <mat-form-field class="example-full-width"  *ngIf="field.attributeType.id==3">
                                                               <input matInput readonly [formControlName]="field.combinedAttributeName"  [matDatepicker]="picker">
                                                               <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                                               <mat-datepicker #picker></mat-datepicker>
                                                           </mat-form-field>

                                                           <!-- from here (Text-single-line) -->
                                                           <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==6">
                                                               <input matInput  #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                           </mat-form-field> 
                                                           
                                                           <!-- from here (Text-multi-line) -->
                                                           <mat-form-field appearance="standard" class="input-100"  *ngIf="field.attributeType.id==8">
                                                               <textarea  maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" 
                                                               [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces></textarea>
                                                           </mat-form-field>

                                                           <!-- from here (Email) -->
                                                           <mat-form-field class="mat-form-field-fluid" *ngIf="field.attributeType.id==9">
                                                               <input maxlength="{{field.maxCharacters}}" minlength="{{field.minCharacters}}" #message matInput placeholder="" [formControlName]="field.combinedAttributeName" ktAutoTrimSpaces>
                                                           </mat-form-field>

                                                            <!-- from here (Image) -->    
                                                            <div *ngIf="field.attributeType.id==4" style="display: flex; flex-direction: row; max-width: 80px; overflow-x:auto; overflow-y: hidden; height: 80px;">
                                                               <input id="{{customAttr.combinedAttributeName + field.combinedAttributeName + i}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                                                               (change)="processFile($event,customAttr.combinedAttributeName,field.attributeType.id,field.maxImagesAllowed, field.combinedAttributeName,i)">
                                                               
                                                               <span *ngIf="fileArray[customAttr.combinedAttributeName+field.combinedAttributeName+i].showUploadIcon" style="display: flex; flex-direction: row;"> 
                                                                   <div class="artist-collection-photo" style="width: 40px;">
                                                                       <img width="40" height="50" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName, field.combinedAttributeName+i)">	
                                                                       <div *ngIf="field.maxImagesAllowed && fileArray[customAttr.combinedAttributeName+field.combinedAttributeName + i].uploaded == 0" class="img-index">
                                                                           {{"0"}}/{{field.maxImagesAllowed}}
                                                                       </div>
                                                                   </div>
                                                               </span>

                                                               <span *ngIf="fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]" style="display: flex; flex-direction: row;">
                                                                   <span *ngFor="let file of fileArray[customAttr.combinedAttributeName + field.combinedAttributeName + i]?.files; index as ix">
                                   
                                                                       <div class="artist-collection-photo" style="width: 35px; margin: 4px;">
                                                                           <div class="remove-img-btn"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, ix, field.combinedAttributeName+i)">
                                                                               <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                                                           </button></div>
                                                                           <img width="40" height="50" [src]= "baseUrl + file" alt="image.jpg" class="img-thumbnail">
                                                                           <div *ngIf="field.maxImagesAllowed" class="img-index"> {{ix+1}}/{{field.maxImagesAllowed}} </div>
                                                                           <div *ngIf="!field.maxImagesAllowed" class="img-index text-center ml-0"> {{ix+1}} </div>
                                                                       </div>
                                   
                                                                   </span>
                                                               </span>
                                                               <mat-error *ngIf="isFormArrayControlHasError(customAttr.combinedAttributeName,field.combinedAttributeName,i,'required')">{{field.attributeName}} can not be blank</mat-error>
                                                            </div>   

                                                             <!-- From here (single select)-->  
               
                                                           <div *ngIf="field.attributeType.id==1; else systemAttrbitues ">
                                                               <mat-form-field  appearance="standard" class="input-100">
                                                                   <mat-select  [formControlName]="field.combinedAttributeName">
                                                       
                                                                       <mat-option *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                           {{values.lookupValue}}
                                                                       </mat-option>
                                                                       
                                                                   </mat-select>
                                                               </mat-form-field>                    
                                                           </div>                   

                                                           <!-- From here (multi select)-->
                                                               
                                                           <div *ngIf="field.attributeType.id==2" >
                                                               <mat-form-field class="mat-form-field-fluid">
                                                                   <mat-select #select3 [formControlName]="field.combinedAttributeName" multiple>
                                                                       <div class="select-all">
                                                                           <mat-checkbox [(ngModel)]="field.allSelected"
                                                                                   [ngModelOptions]="{standalone: true}"
                                                                                   (change)="toggleAllSelection(select3,field)">Select All
                                                                           </mat-checkbox>
                                                                         </div>
                                                                       <mat-option (click)="optionClick(select3,field)" *ngFor="let values of field.lookupValues" [value]="values.id">
                                                                           {{values.lookupValue}}
                                                                       </mat-option>
                                                                   </mat-select>
                                                               </mat-form-field>
                                                           </div>   
                                                       </td>
                                                       
                                                       <td *ngIf="productFormFields[customAttr.combinedAttributeName]?.productFormView!=1" style="min-width:90px;"> 
                                                           <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="isLast">
                                                               <i class="fas fa-plus" (click)="addTable(customAttr.combinedAttributeName)"></i>
                                                           </button>
                                       
                                                           <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary">
                                                               <i class="fa fa-minus" (click)="deleteRow(customAttr.combinedAttributeName, i)"></i>
                                                           </button>
                                                          
                                                       </td>
                                                       
                                             </tr>
                                             <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isAllowDiscount">
                                                 <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                   <div class="col-md-12 text-right" >
                                                       <label class="col-form-label form-padding-top-15">Sub-total</label>
                                                   </div>
                                                 </td>
                                                 <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                   <!-- <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{productFormFields[customAttr.combinedAttributeName]?.subTotal}}</label> -->
                                                    <input matInput ktTwoDigitDecimaNumberDirective 
                                                       [(ngModel)]="productFormFields[customAttr.combinedAttributeName].subTotal" 
                                                       [ngModelOptions]="{standalone: true}"> 
                                                 </td>
                                             </tr>
                                             <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountValue">
                                               <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                 <div class="col-md-12 text-right" >
                                                     <label class="col-form-label form-padding-top-15">Discount Amount</label>
                                                 </div>
                                               </td>
                                               <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                   <input matInput ktTwoDigitDecimaNumberDirective 
                                                       [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountValue" 
                                                       [ngModelOptions]="{standalone: true}" 
                                                       (input)="discountValueChanged(customAttr.combinedAttributeName)"> 
                                               </td>
                                            </tr>
                                            <tr *ngIf="productFormDiscountDetails[customAttr.combinedAttributeName]?.isDiscountPercent">
                                               <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                 <div class="col-md-12 text-right" >
                                                     <label class="col-form-label form-padding-top-15">Discount %</label>
                                                 </div>
                                               </td>
                                               <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                   <input matInput ktTwoDigitDecimaNumberDirective  
                                                       [(ngModel)]="productFormFields[customAttr.combinedAttributeName].discountPercent" 
                                                       [ngModelOptions]="{standalone: true}" 
                                                       (input)="discountPercentChanged(customAttr.combinedAttributeName)">
                                               </td>
                                            </tr>
                                            <tr>
                                               <td [attr.colspan]="productFormFields[customAttr.combinedAttributeName]?.fields.length-1" style="border-right: none;">
                                                 <div class="col-md-12 text-right" >
                                                     <label class="col-form-label form-padding-top-15">Total</label>
                                                 </div>
                                               </td>
                                               <td [attr.colspan]="2"  style="border-left: none; padding-left: 10px;">
                                                   <!-- <label class="col-form-label form-padding-top-15">&nbsp;&nbsp;{{productFormFields[customAttr.combinedAttributeName]?.subTotal}}</label> -->
                                                    <input matInput ktTwoDigitDecimaNumberDirective 
                                                       [(ngModel)]="productFormFields[customAttr.combinedAttributeName].total" 
                                                       [ngModelOptions]="{standalone: true}"> 
                                                 </td>
                                            </tr>
                                           
                                           </table>
                                        </tr>
                                        <tr style="height: 20px;"></tr>
                                   </td>
                               </div>  
                              
                           </div>                                   
                       </div>  
                       <mat-error *ngIf="addProductError">Please select product</mat-error>
                     
                   </div>

               </div>

               <!-- second div (multi select)-->
                   
               <div id="select_{{customAttr.id}}" class="col-md-8"  *ngIf="customAttr.attributeType.id==2 && (customAttr.systemAttribute['id'] !==16) && (customAttr.systemAttribute['id'] !==15) && (customAttr.systemAttribute['id'] !==21) && (customAttr.systemAttribute['id'] !==28); else systemAttrbituesM " >
                   <mat-form-field class="mat-form-field-fluid">
                       <mat-select #select4 [formControlName]="customAttr.combinedAttributeName" multiple (selectionChange)="customAttr.hasChildAttribute ? invokeChildMethod($event.value,customAttr.id) : ''">
                           <mat-option>
                               <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="multiDropdownFilter" ></ngx-mat-select-search>
                           </mat-option>
                           <div class="select-all">
                               <mat-checkbox [(ngModel)]="customAttr.allSelected"
                                       [ngModelOptions]="{standalone: true}"
                                       (change)="toggleAllSelection(select4,customAttr)">Select All
                               </mat-checkbox>
                             </div>
                           <mat-option (click)="optionClick(select4,customAttr)"*ngFor="let values of customAttr.lookupValues| search : multiDropdownFilter.value : 'lookupValue'" [value]="values.id">
                               {{values.lookupValue}}
                           </mat-option>
                       </mat-select>
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                   </mat-form-field>
                                   
               </div>

               <!-- third div (Date) -->
            <!--   <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3">
                   <mat-form-field class="example-full-width">
                       <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="minDate" [max]="maxDate" [matDatepicker]="picker" ktAutoTrimSpaces>
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       
                       <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                       <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                       <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                   </mat-form-field>
                                       
               </div> -->

               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMaxFlag && !DisplayDateMinFlag && !DisplayDateFlag">
                   <mat-form-field class="example-full-width">
                       <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                   </mat-form-field>
                                       
               </div>
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateMinFlag && !DisplayDateMaxFlag && !DisplayDateFlag">
                   <mat-form-field class="example-full-width">
                       <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate"  [matDatepicker]="picker">
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                   </mat-form-field>
                                       
               </div>
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag ">
                   <mat-form-field class="example-full-width">
                       <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [min]="customAttr.dateAttributes.minDate" [max]="customAttr.dateAttributes.maxDate" [matDatepicker]="picker">
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                   </mat-form-field>
                                       
               </div>
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==3 && !DisplayDateFlag && !DisplayDateMinFlag && !DisplayDateMaxFlag">
                   <mat-form-field class="example-full-width">
                       <input matInput readonly [formControlName]="customAttr.combinedAttributeName" [matDatepicker]="picker">
                       <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                       <mat-datepicker #picker></mat-datepicker>
                       
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                   </mat-form-field>
                                       
               </div>

               <!-- fourth div (Image) -->
                   
               <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==4">
                   <div class="mat-form-field">
                       <div class="mat-form-field-wrapper">
                           <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                               (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                           
                           <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                               <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">

                                   <div class="artist-collection-photo">
                                       <div class="remove-img-btn"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)">
                                           <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                       </button></div>
                                       <img width="90" height="100" [src]= "baseUrl + file" alt="image.jpg" class="img-thumbnail" (click)="showImageModalOnly(file)">
                                       <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div> 
                                       <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                   </div>

                               </span>
                           </span>
                           <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                               <div class="artist-collection-photo">
                                   <img width="90" height="100" src="./assets/media/svg/icons/General/imageIcon.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                   <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                       {{"0"}}/{{customAttr.maxImagesAllowed}}
                                   </div>
                               </div>
                           </span>
                       </div>
                   </div>
                   <div class="mat-form-field-underline ng-star-inserted">
                       <span class="mat-form-field-ripple"></span>
                   </div>
                   <div class="mat-form-field-subscript-wrapper">
                       <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                           <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                       </ng-container>
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                       <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                   </div>
                                       
                   <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
             
               </div>

               <!-- fifth div (Radio)-->
           
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==5">
                   <mat-radio-group [formControlName]="customAttr.combinedAttributeName" fxLayout="column" fxLayoutGap=".25rem">
                       <mat-radio-button *ngFor="let values of customAttr.lookupValues" [value]="values.id">
                           {{values.lookupValue}}
                       </mat-radio-button>
   
                   </mat-radio-group>    
                   <mat-hint>{{customAttr.instructions}}</mat-hint>     
                                                               
                   <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
               </div>

               <!-- sixth div (Text-single line)-->

               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==6 ||customAttr.attributeType.id==20">
                   <mat-form-field class="mat-form-field-fluid">
                       <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                       <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                       <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                   </mat-form-field>                                            
               </div>

               <!-- seventh div (Numeric) -->
           
              <!-- <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                   <mat-form-field class="mat-form-field-fluid">
                       <input maxlength="{{customAttr.maxNumber}}" minlength="{{customAttr.minNumber}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName">
                       <mat-hint *ngIf="customAttr.mandatoryFlag">{{customAttr.instructions}}</mat-hint>     
                       <mat-hint *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions">Optional</mat-hint>     
                       <mat-hint *ngIf="!customAttr.mandatoryFlag && customAttr.instructions">{{customAttr.instructions}} (Optional)</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">Only numeric values</mat-error>
                       <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                       <mat-hint *ngIf="customAttr.maxNumber" align="end">{{message.value.length}} / {{customAttr.maxNumber}}</mat-hint>
                   </mat-form-field>  
               </div> -->
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==7">
                   <div *ngIf="customAttr.numericValidationType==1">
                   <div *ngIf="customAttr.maxDigits!=null">
                   <mat-form-field class="mat-form-field-fluid">
                       <input maxlength="{{customAttr.decimalFlag?customAttr.maxDigits+3:customAttr.maxDigits}}" #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                       <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                      
                       <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                       <mat-hint  align="end">{{customAttr.decimalFlag?getDecimalFieldLength(message.value):message.value.length}} / {{customAttr.maxDigits}}</mat-hint>
                   </mat-form-field> 
                   </div> 
                   <div *ngIf="customAttr.maxDigits==null">
                       <mat-form-field class="mat-form-field-fluid">
                           <input  #message  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                           <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                          
                           <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                           <mat-hint  align="end">{{message.value.length}} </mat-hint>
                       </mat-form-field> 
                       </div> 
                   </div>
                   <div *ngIf="customAttr.numericValidationType==2">
                       <div *ngIf="customAttr.maxNumber!=null">
                           <mat-form-field class="mat-form-field-fluid">
                               <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                               <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                               
                           <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                               <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                               <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                           </mat-form-field>  
                       </div>
                       <div *ngIf="customAttr.maxNumber == null">
                           <mat-form-field class="mat-form-field-fluid">
                               <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                               <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                               
                           <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                               <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                               <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                           </mat-form-field>  
                       </div>

                   </div>
                   <div *ngIf="(customAttr.numericValidationType==null || customAttr.numericValidationType==0)">
                       <mat-form-field class="mat-form-field-fluid">
                           <input  #message2  matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" [ktStrictNumberOnlyDirective]="customAttr.decimalFlag">
                           <mat-hint>Only numeric values are allowed. {{customAttr.instructions}}</mat-hint>     
                           
                       <!--  <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank</mat-error>  -->
                           <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">format_list_numbered</mat-icon> 
                           <mat-hint  align="end">{{message2.value.length}}</mat-hint>
                       </mat-form-field>  
                   </div>
                      
                   
               </div>

               <!-- eigth div (Text -multi line)-->

               <div class="col-md-8 mb-2"  *ngIf="customAttr.attributeType.id==8">
                   <mat-form-field appearance="standard" class="input-100" >
                       <textarea  maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces></textarea>
                       
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'pattern')">{{customAttr.patternErrorMsg}}</mat-error>
                       <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                       <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                   </mat-form-field>
                                                               
               </div>

               <!-- nineth div (Email) -->
               <div class="col-md-8"  *ngIf="customAttr.attributeType.id==9">
                   <mat-form-field class="mat-form-field-fluid">
                       <input maxlength="{{customAttr.maxCharacters}}" minlength="{{customAttr.minCharacters}}" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces>
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} cannot be blank.</mat-error>
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'email')">Invalid email format.</mat-error>
                       <mat-icon  matSuffix [ngStyle]="{'color':'#369FFF'}">text_fields</mat-icon> 
                       <mat-hint *ngIf="customAttr.maxCharacters" align="end">{{message.value.length}} / {{customAttr.maxCharacters}}</mat-hint>
                   </mat-form-field>                                            
               </div>

               <!-- tenth div (document upload) --> 
               <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==15">
                   <div class="mat-form-field">
                       <div class="mat-form-field-wrapper">
                           <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                               (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                           
                           <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                               <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">

                                   <div class="artist-collection-photo">
                                       <div class="remove-img-btn"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)">
                                           <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                       </button></div>
                                       <a href="{{baseUrl + fileArray[customAttr.combinedAttributeName]?.files[i]}}" target="_blank">
                                           <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail">
                                       </a>
                                       <div *ngIf="customAttr.maxImagesAllowed" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                       <div *ngIf="!customAttr.maxImagesAllowed" class="img-index text-center ml-0"> {{i+1}} </div>
                                   </div>

                               </span>
                           </span>
                           <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                               <div class="artist-collection-photo">
                                   <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                   <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                       {{"0"}}/{{customAttr.maxImagesAllowed}}
                                   </div>
                               </div>
                           </span>
                       </div>
                   </div>
                   <div class="mat-form-field-underline ng-star-inserted">
                       <span class="mat-form-field-ripple"></span>
                   </div>
                   <div class="mat-form-field-subscript-wrapper">
                       <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                           <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                       </ng-container>
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                       <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                   </div>

                   <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                       
               </div>

               <!-- eleventh div (video upload) --> 
               <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==16">
                   <div class="mat-form-field">
                       <div class="mat-form-field-wrapper">
                           <input id="{{customAttr.combinedAttributeName}}"  type="file" style="display:none;" multiple=""   ktAutoTrimSpaces
                               (change)="processFile($event,customAttr.combinedAttributeName,customAttr.attributeType.id,customAttr.maxImagesAllowed)">
                           
                           <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                               <span *ngFor="let file of fileArray[customAttr.combinedAttributeName]?.files; index as i">

                                   <div class="artist-collection-photo">
                                       <div class="remove-img-btn" *ngIf="customAttr.systemAttribute.id!=70"><button class="closeImageButton close removeImgButton" type="button" (click)="removeFile(customAttr.combinedAttributeName, i)">
                                           <img class="close-svg" src="./assets/media/svg/icons/General/Close.svg">
                                       </button></div>
                                       <a href="{{ (fileArray[customAttr.combinedAttributeName]?.files[i]?.includes('http') || fileArray[customAttr.combinedAttributeName]?.files[i]?.includes('https')) ? fileArray[customAttr.combinedAttributeName]?.files[i] : (baseUrl + fileArray[customAttr.combinedAttributeName]?.files[i]) }}
                                       " target="_blank">
                                           <img  *ngIf="customAttr.systemAttribute.id!=70" width="90" height="100" src="./assets/media/svg/icons/General/file-alt-solid.svg" alt="image.jpg" class="img-thumbnail">
                                           <span *ngIf="customAttr.systemAttribute.id==70" class="btn btn-transparent-primary btn-sm pr-1"><i class="fas fa-play icon-md"></i></span> 
                                       </a>
                                       
                                       <div *ngIf="customAttr.maxImagesAllowed && customAttr.systemAttribute.id!=70" class="img-index"> {{i+1}}/{{customAttr.maxImagesAllowed}} </div>
                                       <div *ngIf="!customAttr.maxImagesAllowed && customAttr.systemAttribute.id!=70" class="img-index text-center ml-0"> {{i+1}} </div>
                                   </div>

                               </span>
                           </span>
                           <span *ngIf="fileArray[customAttr.combinedAttributeName].showUploadIcon"> 
                               <div class="artist-collection-photo">
                                   <img width="90" height="100" src="./assets/media/svg/icons/General/DocumentUpload.svg" alt="image.jpg" class="img-thumbnail" (click)="clickImage(customAttr.combinedAttributeName)">	
                                   <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                       {{"0"}}/{{customAttr.maxImagesAllowed}}
                                   </div>
                               </div>
                           </span>
                           <span *ngIf="customAttr.systemAttribute.id!=70 && fileArray[customAttr.combinedAttributeName].files.length==0"> 
                            <div class="artist-collection-photo">
                                <img width="90" height="100" src="./assets/media/svg/icons/General/file-alt-solid.svg" alt="image.jpg" class="img-thumbnail">
                                <div *ngIf="customAttr.maxImagesAllowed && fileArray[customAttr.combinedAttributeName].uploaded == 0" class="img-index">
                                    {{"0"}}/{{customAttr.maxImagesAllowed}}
                                </div>
                            </div>
                       </span>
                       </div>
                   </div>
                   <div class="mat-form-field-underline ng-star-inserted">
                       <span class="mat-form-field-ripple"></span>
                   </div>
                   <div class="mat-form-field-subscript-wrapper" *ngIf="customAttr.systemAttribute.id!=70">
                       <ng-container *ngIf="!isControlHasError(customAttr.combinedAttributeName, 'required') && !fileArray[customAttr.combinedAttributeName].hasError">
                           <mat-hint class="mat-hint_img" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                       </ng-container>
                       
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                       <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error>
                   </div>

                   <ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name={{customAttr.combinedAttributeName}}></ngx-spinner>
                                       
               </div>

               <!-- twelfth div (reference document) --> 
               <div class="col-md-8 mt-3 mb-1 pb-12"  *ngIf="customAttr.attributeType.id==17">
                   <div class="mat-form-field">
                       <div class="mat-form-field-wrapper">
                           
                           <span *ngIf="fileArray[customAttr.combinedAttributeName]">
                               <span *ngFor="let icon of fileArray[customAttr.combinedAttributeName]?.documentIcons; index as i">

                                   <div class="artist-collection-photo">
                                       <img width="90" height="100" [src]= "icon" alt="image.jpg" class="img-thumbnail" (click)="showRefDocModal(customAttr.combinedAttributeName, i)">
                                   </div>

                               </span>
                           </span>
                       </div>
                   </div>
                   <!-- <div class="mat-form-field-underline ng-star-inserted">
                       <span class="mat-form-field-ripple"></span>
                   </div> -->    
                   <div class="mat-form-field-subscript-wrapper">
                           <mat-hint class="mat-hint_img" *ngIf="customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >{{customAttr.instructions}}</mat-hint>
                           <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && !customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" >Optional </mat-hint>
                           <!-- <mat-hint class="mat-hint_img" *ngIf="!customAttr.mandatoryFlag && customAttr.instructions" style="font-size: 100% !important; padding-top: 0px !important;" > {{customAttr.instructions}} (Optional) </mat-hint> -->  
                       
                       <!-- <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}} can not be blank</mat-error>
                       <mat-error *ngIf="fileArray[customAttr.combinedAttributeName].hasError" style="font-size: 100% !important; padding-top: 0px !important;" >{{fileArray[customAttr.combinedAttributeName].errorMsg}}</mat-error> -->     
                   </div>                
               </div>

               <!-- thirteenth div (Data list field) -->

               <div id="select_{{customAttr.id}}" class="col-md-8" *ngIf="customAttr.attributeType.id==18">
                   <mat-form-field  appearance="standard" class="input-100">
   
                       <mat-select  [formControlName]="customAttr.combinedAttributeName" (selectionChange)="invokeChildMethod($event.value,customAttr)">
                           <mat-option>
                               <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #dataListFieldFilter ></ngx-mat-select-search>
                           </mat-option>
                           <mat-option *ngFor="let values of customAttr.lookupValues| search : dataListFieldFilter.value : 'lookupValue'" [value]="values.id">
                               {{values.lookupValue}}
                           </mat-option>
                       </mat-select>
                   
                       <mat-hint>{{customAttr.instructions}}</mat-hint>     
                       <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">Please select one.</mat-error>                      
       
                   </mat-form-field>                    
               </div> 

            <!-- fourteen div (Data Time field) -->

            <div class="col-md-8" *ngIf="customAttr.attributeType.id==21">
                <mat-form-field class="example-full-width">
                    <input matInput type="datetime-local" min="{{customAttr.dateAttributes.minDate}}"
                        max="{{customAttr.dateAttributes.maxDate}}" [formControlName]="customAttr.combinedAttributeName"
                        placeholder="" onkeydown="return false;">
                    <mat-icon  *ngIf="customAttr.systemAttribute.id==67" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                    <mat-hint>{{customAttr.instructions}}</mat-hint>
                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                        cannot be blank</mat-error>
                </mat-form-field>
            </div>

            <!-- fifteen div (Time Duration field) -->

            <div class="col-md-8" *ngIf="customAttr.attributeType.id==22">
                <mat-form-field class="mat-form-field-fluid">
                    <!-- Temp. fix of Time Duration Field, Since Call Duration Field will be always locked-->
                    <input *ngIf="customAttr.systemAttribute?.id!=68" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName" ktAutoTrimSpaces
                        type="time" step="1" onkeydown="return false;">
                        
                    <input *ngIf="customAttr.systemAttribute?.id==68" type="text" #message matInput placeholder="" [formControlName]="customAttr.combinedAttributeName">
                    <mat-icon  *ngIf="customAttr.systemAttribute.id==68" matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                    <mat-hint>{{customAttr.instructions}}</mat-hint>

                    <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName, 'required')">{{customAttr.attributeName}}
                        cannot be blank</mat-error>

                </mat-form-field>
            </div>


<!--      =========================system Attributes block================================= -->

               <ng-template #systemAttrbitues >
                   <div class="col-md-8"  *ngIf="(customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21) && selectedActivity > 100">
                       <div class="row">
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select [formControlName]="customAttr.combinedAttributeName+fixedCountryfieldName" (selectionChange)="changeCountry($event.value, customAttr.combinedAttributeName)">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                           {{country.countryName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-hint>Select country.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                                   <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName+fixedCountryfieldName, 'required')">Country cannot be blank</mat-error>
                               </mat-form-field>
                           </div>
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select [formControlName]="customAttr.combinedAttributeName+fixedStatefieldName" (selectionChange)="changeState($event.value, customAttr.combinedAttributeName)">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let state of stateListAct[customAttr.combinedAttributeName]| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                           {{state.stateName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName+fixedStatefieldName, 'required')">State cannot be blank</mat-error>

                                   <mat-hint>Select state.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                               </mat-form-field>
                           </div>
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select [formControlName]="customAttr.combinedAttributeName+fixedCityfieldName">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let city of cityListAct[customAttr.combinedAttributeName]| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                           {{city.cityName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-error *ngIf="isControlHasError(customAttr.combinedAttributeName+fixedCityfieldName, 'required')">City cannot be blank</mat-error>
            
                                   <mat-hint>Select city.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                               </mat-form-field>
                           </div>                                       
                       </div>                                                        
                   </div>
                   <div class="col-md-8"  *ngIf="(customAttr.systemAttribute.id==16 || customAttr.systemAttribute.id==15 || customAttr.systemAttribute.id==21) && selectedActivity <= 100">
                       <div class="row">
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select formControlName="countryName" (selectionChange)="changeCountry($event.value, customAttr.combinedAttributeName)">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="countryNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let country of countriesList| search : countryNameFilter.value :'countryName'" [value]="country.countryName+'@'+country.countryId">
                                           {{country.countryName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-hint>Select country.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                                   <mat-error *ngIf="isControlHasError('countryName', 'required')">Country cannot be blank</mat-error>
                               </mat-form-field>
                           </div>
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select formControlName="stateName" (selectionChange)="changeState($event.value)">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="stateNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let state of stateList| search : stateNameFilter.value :'stateName'" [value]="state.stateName+'@'+state.stateId">
                                           {{state.stateName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-error *ngIf="isControlHasError('stateName', 'required')">State cannot be blank</mat-error>

                                   <mat-hint>Select state.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                               </mat-form-field>
                           </div>
                           <div class="col-md-4">
                               <mat-form-field class="mat-form-field-fluid input-100">
                                   <mat-select formControlName="cityName">
                                       <mat-option>
                                           <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="cityNameFilter" ></ngx-mat-select-search>
                                       </mat-option>
                                       <mat-option *ngFor="let city of cityList| search : cityNameFilter.value :'cityName'" [value]="city.cityName+'@'+city.cityId">
                                           {{city.cityName}}
                                       </mat-option>
                                   </mat-select>
                                   <mat-error *ngIf="isControlHasError('cityName', 'required')">City cannot be blank</mat-error>
            
                                   <mat-hint>Select city.
                                    <span class="mandatory-marker">*</span>
                                   </mat-hint>
                   
                               </mat-form-field>
                           </div>                                       
                       </div>                                                        
                   </div>
                 
               </ng-template>

               <!-- <ng-template #systemAttrbituesM >
           
                   <div class="col-md-12" *ngIf="customAttr.systemAttribute.id==28">
                       <div >
                           <div class="form-group row" formArrayName="skuItems">
                               <div class="row">
                                   <td>                                            
                                       <tr>
                                           <table class="style">
                                               <tr>
                                                   <th>Product</th>
                                                   <th>Quantity</th>
                                                   <th>List Price</th>
                                                   <th>Selling Price</th>
                                                   <th>Discount %</th>
                                                   <th>Tax %</th>
                                                   <th>Total</th>
                                                   <th>Action</th>
                                               </tr>
                                               <tr *ngFor="let item of getControls(); let i=index" [formGroupName]="i">
                                                   <td>
                                                       <mat-form-field class="inputSelect">
                                                           <mat-select formControlName="sku">
                                                               <mat-option *ngFor="let sku of skuList" [value]="sku.productId">
                                                                   {{sku.productName}}
                                                               </mat-option>
                                                           </mat-select>
                                                           
                                                        </mat-form-field>
                                                   </td>
                                                       <td>
                                                           <mat-form-field  class="inputbox"> 
                                                               <input matInput formControlName="quantity" ktStrictNumberOnlyDirective >  
                                                         
                                                           </mat-form-field>
                                                       </td>
                                                       <td>
                                                           <mat-form-field class="inputbox"> 
                                                               <input matInput  formControlName="listPrice"  ktStrictNumberOnlyDirective>  
                                                           </mat-form-field>
                                                       </td>
                                                       <td>
                                                           <mat-form-field class="inputbox"> 
                                                               <input matInput  formControlName="sellingPrice" ktStrictNumberOnlyDirective>  
                                                               
                                                           </mat-form-field>
                                                       </td>
                                                       <td>
                                                           <mat-form-field class="inputbox"> 
                                                               <input matInput  formControlName="discount"  ktStrictNumberOnlyDirective>  
                                                               
                                                           </mat-form-field>
                                                       </td>
                                                       <td>
                                                           <mat-form-field class="inputbox"> 
                                                               <input matInput  formControlName="tax" ktTwoDigitDecimaNumberDirective>  
                                                           </mat-form-field>
                                                       </td>
                                                       <td>
                                                           <mat-form-field class="inputbox"> 
                                                               <input matInput  formControlName="total"  ktStrictNumberOnlyDirective>
                                                               
                                                           </mat-form-field>
                                                       </td>
                                                       <td style="width:90px; padding-left: 10px;"> 
                                                           <button class=" btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary ">
                                                               <i class="fas fa-plus" (click)="addTable()"></i>
                                                           </button>
                                       
                                                           <button class="btn_product btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary " *ngIf="addCompaniesForm.controls.skuItems.controls.length > 1">
                                                               <i class="fa fa-minus" (click)="deleteRow(i)"></i>
                                                           </button>
                                                          
                                                       </td>
                                                       
                                             </tr>
                                           
                                           </table>
                                        </tr>
                                        <tr style="height: 20px;"></tr>
                                   </td>
                               </div>  
                              
                           </div>                                   
                       </div>  
                       <mat-error *ngIf="addProductError">Please select product</mat-error>
                     
                   </div>
               </ng-template> -->
          
           </div>
           <div class="form-group row">
               <div class="col-md-12">
                   <span style="color:red">{{errorMes}}</span>
               </div>
               
           </div>
            </form>
        </div>
        <div class="modal-footer pl-0 pr-0">
            <div class="row col-md-12 p-0">
                <div class="col-md-6 p-0">
                    <!-- Align "Fetch Call Details" button to the left -->
                    <button *ngIf="!isFromCallLogsPage" type="button" class="btn btn-primary" (click)="fetchCallDetails()">Fetch Call Details</button>
                </div>
                <div class="col-md-6 p-0 text-right">
                    <!-- Align the other two buttons to the right -->
                    <button type="button" class="btn btn-secondary" [disabled]='disabled' (click)="closeModal()">Cancel</button>
                    &nbsp; &nbsp;
                    <button *ngIf="isActivityListNotEmpty" type="Submit" class="btn btn-primary"  [disabled]='disabled'  (click)="save()">Save</button>
                </div>
            </div>
          </div>
    </div>


    
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.5)" size="default" type="ball-spin-clockwise" name="addCompany">  
    <p class="spinner-fetch">Fetching Data... </p>  
</ngx-spinner> 