import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LayoutUtilsService, MessageType } from 'src/app/core/_base/crud';
import { AddActivityComponent } from './_subs/add-activity/add-activity.component';
import { ManageActivitiesService } from '../manage-activities/services/manage-activities.service';
import { EditCellRendererComponent } from './_subs/edit-cell-renderer/edit-cell-renderer.component';

import { EventEmitterService } from '../shared/services/event-emitter.service';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'kt-manage-activities',
  templateUrl: './manage-activities.component.html',
  styleUrls: ['./manage-activities.component.scss']
})
export class ManageActivitiesComponent implements OnInit {

  @ViewChild('myButton') myButton : ElementRef;

  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  gridApi: any;
  gridColumnApi: any;
  viewContent:boolean;
  noRowsTemplate: any;
  loadingTemplate: any;

  columnDefs: any;
  defaultColDef: any;
  rowData: any;
  domLayout: any;
  searchValue: any;
  selectedValue: any;
  paginationPageSize: any;
  gridOptions: any;
  sequenceOrder: any;
  modalReference: any;
  errorFlag = false;
  errorText = "";
  allowCompanyName: string;
  allowOpportunityName: string;
  opportunityEnabled: false;

  constructor(
    private modalService: NgbModal,
    private layoutUtilsService: LayoutUtilsService,
    private _mas: ManageActivitiesService,
    private eventEmitterService: EventEmitterService,
    private activityService: ManageActivitiesService,
    public snackBar: MatSnackBar,
  ) {
    this.gridOptions={
      context : {
        componentParent: this
      }
    }
    
  }

  ngOnInit(): void {
    this.getdynamicFieldNames();

    this.gridOptions.suppressNoRowsOverlay = true;

    if (this.eventEmitterService.subsVar==undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
      invokeFirstComponentFunction.subscribe((data:string) => {
        this.modalResult(data);
      });
    }
/*
    this.columnDefs = [
      {
        field: 'activityName',
        headerName: 'Activity Name(in display order)',
        rowDrag: true,
        menuTabs: ['filterMenuTab'],
      },
      {
        field: 'allowForCompanies',
        headerName: 'Allow for '+this.allowCompanyName,
        menuTabs: ['filterMenuTab'],
      },      
      {
        headerName: 'Status',
        field: 'status',
        maxWidth: 150,
        menuTabs: ['filterMenuTab'],
        cellRenderer: function(params) {
          if(params.value == 'Active')
            return '<button class="no-click label label-lg btn btn-success label-inline">' + params.value + '</button>';
          else if(params.value == 'Inactive')
            return '<button class="no-click label label-lg btn btn-danger label-inline">' + params.value + '</button>';
          else
            return '';
        },
      },
      {
        headerName: 'Action',
        field: '',
        maxWidth: 150,
        menuTabs: [],
        cellRendererFramework: EditCellRendererComponent
      }
    ];
*/
    
    this.defaultColDef = {
      minWidth: 150,
      sortable: false,
      filter: true,
      flex: 1,
    };

    this.domLayout = 'autoHeight';
    this.selectedValue='10';
    this.paginationPageSize = 10;

  }

  getdynamicFieldNames(){
    this.activityService.getdynamicFieldNames(this.selectedProject).subscribe(
      result=>{
        console.log( result['responseData']);
      result['responseData'].forEach(
        row => {

          if(row.moduleId==2){
            this.allowCompanyName =row.pluralName;
          } 
          if(row.moduleId==3){
            this.allowOpportunityName =row.pluralName;
            this.opportunityEnabled = row.opportunityEnabled;
          } 
        }
      )

      if(this.opportunityEnabled){
        this.columnDefs = [
          {
            field: 'activityName',
            headerName: 'Activity Name(in display order)',
            rowDrag: true,
            menuTabs: ['filterMenuTab'],
          },
          {
            field: 'allowForCompanies',
            headerName: 'Allow for '+this.allowCompanyName,
            menuTabs: ['filterMenuTab'],
          },
          {
            field: 'allowForOpportunities',
            headerName: 'Allow for '+this.allowOpportunityName,
            menuTabs: ['filterMenuTab'],
          },
          {
            headerName: 'Status',
            field: 'status',
            maxWidth: 150,
            menuTabs: ['filterMenuTab'],
            cellRenderer: function(params) {
              if(params.value == 'Active')
                return '<button class="no-click label label-lg btn btn-success label-inline">' + params.value + '</button>';
              else if(params.value == 'Inactive')
                return '<button class="no-click label label-lg btn btn-danger label-inline">' + params.value + '</button>';
              else
                return '';
            },
          },
          {
            headerName: 'Action',
            field: '',
            maxWidth: 150,
            menuTabs: [],
            cellRendererFramework: EditCellRendererComponent          }
        ];
      } else {

        this.columnDefs = [
          {
            field: 'activityName',
            headerName: 'Activity Name(in display order)',
            rowDrag: true,
            menuTabs: ['filterMenuTab'],
          },
          {
            field: 'allowForCompanies',
            headerName: 'Allow for '+this.allowCompanyName,
            menuTabs: ['filterMenuTab'],
          },          
          {
            headerName: 'Status',
            field: 'status',
            maxWidth: 150,
            menuTabs: ['filterMenuTab'],
            cellRenderer: function(params) {
              if(params.value == 'Active')
                return '<button class="no-click label label-lg btn btn-success label-inline">' + params.value + '</button>';
              else if(params.value == 'Inactive')
                return '<button class="no-click label label-lg btn btn-danger label-inline">' + params.value + '</button>';
              else
                return '';
            },
          },
          {
            headerName: 'Action',
            field: '',
            maxWidth: 150,
            menuTabs: [],
            cellRendererFramework: EditCellRendererComponent          }
        ];

      }
      
      this.defaultColDef = {
        minWidth: 150,
        sortable: false,
        filter: true,
        flex: 1,
      };
  
      this.domLayout = 'autoHeight';
      this.selectedValue='10';
      this.paginationPageSize = 10;
    },
    err=>{
    }
  )
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.getAllActivitiesList(0);
  }

  getAllActivitiesList(editFlag:number){
    this.gridOptions.suppressNoRowsOverlay = false;
    this.noRowsTemplate = '<span class="ag-overlay-loading-center">Data is loading...</span>';

    this._mas.getAllActivities(this.selectedProject,true).subscribe(
      data=>{

        if (!data || !data.length) {
          this.gridOptions.suppressNoRowsOverlay = false;
          this.noRowsTemplate = '<span">There are no records.</span>';
        }

       data['responseData'].forEach(res=>{
          res.allowForCompanies=this.getAllowForString(res.allowForCompanies);
          res.allowForOpportunities=this.getAllowForString(res.allowForOpportunities);
          res.status= this.getItemStatusString(res.active);
       })
       
       this.rowData =  data['responseData'];

       /** Refresh Grid after add and update */
       if(editFlag == 1) {
          this.gridApi.updateRowData({add: this.rowData});
          this.gridApi.refreshCells();
       }

        let el: HTMLElement = this.myButton.nativeElement as HTMLElement;
        setTimeout(() => {
          el.click();
          this.viewContent = true;
        }, 300);

      },
      err=>{
        this.errorFlag=true;
      }
    )
  }

  getItemStatusString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'Inactive';
      case 1:
        return 'Active';
      case 2:
        return 'Pending';
    }
    return '';
  }

  getAllowForString(status: number = 0): string {
    switch (status) {
      case 0:
        return 'No';
      case 1:
        return 'Yes';
    }
    return '';
  }

  onPageSizeChanged(newPageSize) {
    this.gridApi.paginationSetPageSize(Number(newPageSize));
  }

  quickSearch(){
    this.gridApi.setQuickFilter(this.searchValue);
  }

  /**
   * Add Activity Field
   */

  AddActivity() {
    const _saveMessage = `Activity has been saved.`;
    const _messageType = MessageType.Create;
    const ref=this.modalReference = this.modalService.open(AddActivityComponent,
      {  
        centered: false,
        size : 'lg',
        backdrop : 'static'
      });

      ref.result.then((result) => {
         this.modalResult(result);
      })
  }

  modalResult(result: string) {
    if(result === 'SUCCESS'){
      this.refreshGrid();
    }
  }


  refreshGrid(){
    this.gridApi.setRowData([]);
    this.getAllActivitiesList(1);
  }

  /**
   *  Generate Display Order
   */
  generateDisplayOrder() {
    let nodeData = [];
    this.gridApi.forEachNode(function(node) {
      nodeData.push(node.data);
    });

    const orderData=[];
    var count=1;

    for(let items of nodeData){
      var activityId=items.activityId;
      var name=items.activityName;
      orderData.push(
      {
        key: activityId,
        activityName: name,
        value: count
      });
      count++;
    }

    this.sequenceOrder=orderData;

    this.updateSequenceOrder(this.sequenceOrder);

  }

  /**
   * Update Sequence Order
   * @param orderList
   */
  updateSequenceOrder(orderList: any){
    this._mas.updateSequence(orderList).subscribe(
      data=>{
        console.log(data);

          this.snackBar.open('Display order updated.', '', {
            duration: 3000,
            panelClass: ['green-snackbar']
          });
      },
      err=>{
        this.errorFlag=true;
          this.snackBar.open('Unable to save changes. Please try again.', '', {
            duration: 3000,
            panelClass: ['red-snackbar']
          });
        
      }
    )
  }

  /**
   *  Delete Activity
   * @param customValues 
   */
  deleteActivity(customValues) {
    const _saveMessage = `Activity deleted successfully`;
    const _messageType = MessageType.Create;
    
    console.log(customValues);

    this._mas.deleteActivities(customValues.activityId, this.selectedProject).subscribe(
      data=>{
          if(data['statusCode'] != 200){
            this.errorFlag = true;
            this.errorText = data['message'];
          } else {
            this.refreshGrid();
          }
      },
      err=>{
        this.errorFlag=true;
      }
    )
  }

  /**
   * For quick display ag-grid data
   */
  triggerClick() {}

}
