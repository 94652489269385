import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactSettingComponent} from './contact-setting.component';
import { RouterModule, Routes } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdTabsetConfig } from './tabset-config';


import { ContactTypeListComponent } from './_subs/contact-type-list/contact-type-list.component';
import { AddContactComponent } from './add-contact/add-contact.component';
import { EditContactComponent } from './edit-contact/edit-contact.component';

//shared
import { SharedModule } from '../shared/shared.module';
import { SettingComponent } from './setting/setting.component';
const routes :Routes=[
  {
		path: '',
    component: ContactSettingComponent
	}
]

@NgModule({

  declarations: [ContactSettingComponent,NgbdTabsetConfig, ContactTypeListComponent,AddContactComponent, EditContactComponent, SettingComponent],

  imports: [
    CommonModule,
    RouterModule.forChild(routes),  
		NgbModule,
		SharedModule
    
  ],

	entryComponents:[AddContactComponent,EditContactComponent],
	exports:[]

})
export class ContactSettingModule { }
