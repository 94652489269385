<div class="modal-header">
    <h4 class="modal-title">Add Reports</h4>
    <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<kt-portlet> 
    <kt-portlet-body>
        <!-- start::FILTERS & GROUP ACTIONS -->
        <div class="example-header">
            
            <!-- start::FILTERS -->
            <div class="form-filtration" style="margin:0px;">
                <div class="row align-items-center">
                    <div class="col-md-2 kt-margin-bottom-10-mobile">
                        <mat-form-field class="mat-form-field-fluid">
                            <input matInput placeholder="Search" (keyup)="quickSearch()" [(ngModel)]="searchValue">
                            <mat-hint align="start">Search in all fields</mat-hint>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        <!-- end::FILTERS -->
        </div>
        <!-- AG GRID MODULE START -->

            <ag-grid-angular
                class="ag-theme-alpine"
                [columnDefs]="columnDefs"
                [defaultColDef]="defaultColDef"
                [rowDragManaged]="true"
                [animateRows]="true"
                [domLayout]="domLayout"
                [gridOptions]="gridOptions"
                [rowData]="rowData"
                [rowSelection]="rowSelection"
                (gridReady)="onGridReady($event)">
            </ag-grid-angular>
        </kt-portlet-body>
</kt-portlet>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="Close()">Cancel</button>
    <button type="button" class="btn btn-primary" *ngIf="!editFlag" (click)="save()">Save</button>
    <button type="button" class="btn btn-primary" *ngIf="editFlag" (click)="save()">Add</button>
</div>

<ngx-spinner size="default" type="ball-spin-clockwise" name='adddashBoardReport'>  
    <p style="color: white"></p>  
</ngx-spinner>


