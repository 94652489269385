import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'initials'
})
export class InitialsPipe implements PipeTransform {
  transform(name: string): string {
    const names = name.split(' ');
    let initials = '';

    for (let i = 0; i < names.length; i++) {
      const namePart = names[i];
      if (namePart.length > 0) {
        initials += namePart.charAt(0).toUpperCase();
      }
    }

    return initials;
  }
}