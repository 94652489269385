
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { LocalizationService } from './services/localization.service';
import { MenuConstants, MenuPermissionService } from '../shared/services/menu-permission.service';
import { AlertDialogComponent } from '../shared/alert-dialog/alert-dialog.component';
import { MatTab, MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { MatDialog } from '@angular/material/dialog';
import { CustomerSettingComponent } from './_subs/customer-setting/customer-setting.component';
import { OpportunitySettingComponent } from './_subs/opportunity-setting/opportunity-setting.component';
import { LocalizationComponent } from './_subs/localization/localization.component';
import { ContactSettingComponent } from './_subs/contact-setting/contact-setting.component';
import { SupportSettingComponent } from './_subs/support-setting/support-setting.component';

@Component({
  selector: 'kt-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  @ViewChild("tabGroup", { static: true }) tabGroup!: MatTabGroup;
  @ViewChild(ContactSettingComponent) contactSettingComponent!: ContactSettingComponent;
  @ViewChild(CustomerSettingComponent) customerSettingComponent!: CustomerSettingComponent;
  @ViewChild(LocalizationComponent) localizationComponent!: LocalizationComponent;
  @ViewChild(OpportunitySettingComponent) opportunitySettingComponent!: OpportunitySettingComponent;
  @ViewChild(SupportSettingComponent) supportSettingComponent!: SupportSettingComponent;
  
  selectedTab = 0;
  selectedProject = JSON.parse(localStorage.getItem('selectedProject'));
  contactTabLabel: string = "Contact";
  customerTabLabel: string = "Customer";
  opportunityTabLabel : string = "Opportunity";
  supportTabLabel : string = "Support";
  dynamicName:{ [key: string]: string } = {};
  dataMap = new Map();
  
  /**
     * Component constructor
     *
     * @param router: Router
     * @param route
     */
  
  constructor(
    private _settingService:LocalizationService,
    private route: ActivatedRoute,
    private activatedRoute: ActivatedRoute,
    private _spinnerService: NgxSpinnerService,
    private dialog: MatDialog) {
    }

  ngOnInit(): void {
    this.fetchModulesNameFromServer();

    const routeSubscription =  this.activatedRoute.params.subscribe(params => {
      if(params.tab) {
        this.selectedTab = params.tab;
        console.log('Tab : '+this.selectedTab);
      }
    });

    // Override the _handleClick method of MatTabGroup
    this.overrideMatTabGroupClick();
  }


  fetchModulesNameFromServer(){
    this._spinnerService.show();
    this._settingService.getModulesDynamicName(this.selectedProject).subscribe(
      resp=>{
        console.log(resp);
        var responseData = resp['responseData'];    
        if(responseData){
          responseData.forEach(item => {
            //this.dataMap.set(item.moduleId, item);
            //localStorage.setItem("dynamicName_module_id"+item.moduleId, item);
            switch (item.moduleId) {
              case 1:
                this.contactTabLabel = item["singularName"];
                this.dynamicName['contactTabLabel'] = item["singularName"];
              break;
              case 2:
                this.customerTabLabel = item["singularName"];
                this.dynamicName['customerTabLabel'] = item["singularName"];
              break;
              case 3:
                this.opportunityTabLabel = item["singularName"];
                this.dynamicName['opportunityTabLabel'] = item["singularName"];
              break;            
            }
          });
        }              
        this._spinnerService.hide();              
      },
      err=>{
        console.log(err);
      }
    )    
  }

  updateContactTabLabel(updatedContactTabText){    
    this.contactTabLabel = updatedContactTabText;
  }

  updateCustomerTabLabel(updatedCustomerTabText){    
    this.customerTabLabel = updatedCustomerTabText;
  }

  updateOpportunityTabLabel(updatedOpportunityTabText){    
    this.opportunityTabLabel = updatedOpportunityTabText;
  }

  private overrideMatTabGroupClick(): void {
    const handleClick = this.tabGroup._handleClick;

    this.tabGroup._handleClick = (tab: MatTab, tabHeader: any, index: number) => {
      let activeChild: any;
      switch (this.selectedTab) { // based on select tab component assign it to activeChild
        case 0:
          activeChild = this.localizationComponent;
          break;
        case 1:
          activeChild = this.customerSettingComponent;
          break;
        case 2:
          activeChild = this.opportunitySettingComponent;
          break;
        case 3:
          activeChild = this.contactSettingComponent;
          break;
        case 4:
            activeChild = this.supportSettingComponent;
            break;
        default:
          activeChild = null;
          break;
      }

      if (this.selectedTab != index && activeChild !== null && activeChild.unsavedChanges) {
        const dialogRef = this.dialog.open(AlertDialogComponent, {
          width: "lg",
          data: { title: "Unsaved Changes", body: "Unsaved changes will be lost. Do you want to save them?", isSetting: true },
        });

        dialogRef.afterClosed().subscribe((result) => {

          if (result === 0 || result == 2) {
            if (result === 0) {
              activeChild.Save(true);
              activeChild.saveAndExit.subscribe((saveSuccessful: boolean) => {
                if (saveSuccessful) {
                  tabHeader.focusIndex = index;

                  if (!tab.disabled) {
                    handleClick.call(this.tabGroup, tab, tabHeader, index);
                  }
                }
              });
            } else {
              tabHeader.focusIndex = index;

              if (!tab.disabled) {
                handleClick.call(this.tabGroup, tab, tabHeader, index);
              }
            }
          }
        });
      } else {
        tabHeader.focusIndex = index;

        if (!tab.disabled) {
          handleClick.call(this.tabGroup, tab, tabHeader, index);
        }
      }
    };
  }
}
