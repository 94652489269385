import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'kt-alert-dialog',
  templateUrl: './alert-dialog.component.html',
  styleUrls: ['./alert-dialog.component.scss']
})
export class AlertDialogComponent {

  isSetting : boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.isSetting=data.isSetting?data.isSetting:false;

    }
    
    closeDialog(): void {
      this.dialogRef.close();
    }
    saveAndExit(flag:number) : void{

      this.dialogRef.close(flag);
    }

}
