<div class="container">
    <div class="row">
        <div class="col-lg-3 col-xxl-3 pr-2">
            <kt-widget1-sales-stat cssClasses="card-stretch gutter-b" [parentData]="opportunityId"></kt-widget1-sales-stat>
        </div>
        <div class="col-lg-6 col-xxl-6 pl-2 pr-2">
            <kt-widget2-recent-activities cssClasses="card-stretch gutter-b" [parentData]="opportunityId"></kt-widget2-recent-activities>
        </div>
        <div class="col-lg-3 col-xxl-3 pl-2">
            <kt-widget3-contact cssClasses="gutter-b" [parentData]="opportunityId"></kt-widget3-contact>
            <kt-widget4-company *ngIf="!hideCompanyWidget" cssClasses="gutter-b" [parentData]="opportunityId" (childData)="hideContainer($event)"></kt-widget4-company>
            <kt-widget5-opportunity cssClasses="gutter-b" [parentData]="opportunityId"></kt-widget5-opportunity>
        </div>
    </div>
</div>