import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'kt-user-filter',
  templateUrl: './user-filter.component.html',
  styleUrls: ['./user-filter.component.scss']
})
export class UserFilterComponent implements OnInit {
  userFilterData:any;
  userFilterField:any;
  filterData: { [key: string]: any[] } = {};
  filteredData:any;
  chartData;
  maxDate: Date|String;
  minDate: Date|String;
  maxOriginalDate:Date | String;
  minOriginalDate:Date | String;
  userfilter: FormGroup;
  prevFilterFormData=[];
  dateFields=[];
  daysSelected: any[] = [];
  constructor(public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder) {

     }

  ngOnInit(): void {
    this.userFilterField=this.chartData.userFilters;
    this.userFilterField = this.userFilterField.map(item => {
      item.fieldName = item.fieldName.replace('.', '');
      return item;
  });
    
    this.userfilter = this.formBuilder.group({
    });
    this.userFilterField.forEach(field => {
      if(field.entityFieldType.id==3 || field.entityFieldType.id==21){
          this.dateFields.push('userFilter '+field.fieldName);
        if(this.chartData.reportFilters){
        this.chartData.reportFilters.forEach(report =>{
          if(field.entityId==report.entityId){
            this.userfilter.addControl('userFilter ' + field.fieldName, this.formBuilder.control(''));
            this.getMinMaxDate(report);
          }else{
            this.minDate="";
            this.maxDate="";
            this.userfilter.addControl('userFilter ' + field.fieldName, this.formBuilder.control(''));
          }
        })
      }else{
        this.minDate="";
        this.maxDate="";
        this.userfilter.addControl('userFilter ' + field.fieldName, this.formBuilder.control(''));
      }
      }else{
        this.userfilter.addControl('userFilter ' + field.fieldName, this.formBuilder.control([]));
     }
    });

    if(this.prevFilterFormData.length>0){
        this.prevFilterFormData.forEach(control => {
          this.userfilter.get(control.controls).setValue(control.value)
        });
      }
    this.getUserFilters();
    this.maxOriginalDate=this.maxDate;
    this.minOriginalDate=this.minDate;
  }

  ApplyFilters(){
    this.filteredData = this.userFilterData;
    Object.keys(this.userfilter.controls).forEach(controlName => {
      let val = this.userfilter.get(controlName).value;
      this.prevFilterFormData.push({controls:controlName,value:val})
      if(this.dateFields.includes(controlName) && val !== ""){
        let val1 = val.split(" - ")[0];
        let val2 = val.split(" - ")[1];
        this.filteredData = this.filteredData.filter(obj => {
          const dateValue = obj[controlName];
          let dateOnly;
          if (dateValue && dateValue.length > 10) {
            const dateParts = dateValue.split(' ');
            dateOnly = dateParts[0];
          } else {
            dateOnly = dateValue;
          }
         return dateOnly >= val1 && dateOnly <= val2;
        });
      }
      else if (val.length!==0){
        this.filteredData = this.filteredData.filter(obj => {
          const normalizedValue = obj[controlName]?.trim().toLowerCase();
          const normalizedArray = val.map(item => item?.trim().toLowerCase());
          return normalizedArray.includes(normalizedValue);
        });
      }
    });
    this.activeModal.close({ filteredData: this.filteredData, prevFormData: this.prevFilterFormData });
  }
  
  isSelected = (event: any) => {
    const day = ('00' + event.getDate()).slice(-2);
    const month = ('00' + (event.getMonth() + 1)).slice(-2);
    const year = event.getFullYear();
    const date = `${year}-${month}-${day}`;

    if (this.daysSelected.length === 2) {
      let startDate = this.daysSelected[0].split('-');
      startDate = new Date(startDate[0], startDate[1] - 1, startDate[2]);

      let endDate = this.daysSelected[1].split('-');
      endDate = new Date(endDate[0], endDate[1] - 1, endDate[2]);

      if (this.daysSelected[0] == date) {
        return 'kt-date-range-selected-first-date';
      }
      else if (this.daysSelected[1] == date) {
        return 'kt-date-range-selected-last-date';
      }
      else if (moment(event).isBetween(startDate, endDate)) {
        return 'kt-date-range-date-in-range';
      }
    }

    return this.daysSelected.find((x) => x == date) ? 'kt-date-range-selected' : null;
  };
  
  onApplyFilters() {
    if (this.userfilter.valid) {
      this.ApplyFilters();
    } else {
      this.userfilter.markAllAsTouched();
    }
  }
  


  getUserFilters() {
    const filterSet = new Set();
    for (let field of this.userFilterField){
      let filtersList= [];
      let key="";
      let filterValue="";
      for (const item of this.userFilterData) {
      key = "userFilter " + field.fieldName;
      filterValue = item[key];
      if (!filterSet.has(filterValue)) {
        filtersList.push({ id: filterValue, name: filterValue });
        filterSet.add(filterValue);
      }
       }
      if(field.entityFieldType.id!=3){
        const isString = typeof filtersList[0]?.id === 'string';
        filtersList.sort((a, b) => {
          if (isString && field.entityFieldType.id!=7 ) {
            return a.id?.localeCompare(b?.id);
          } else {
            return a.id - b.id;
          }
        });
      }

      this.filterData[key]=filtersList;
    }
    
  }
  
  select(event: any, calendar: any,controlName:string) {

    const date = event.getFullYear() + '-' + ('00' + (event.getMonth() + 1)).slice(-2) + '-' + ('00' + event.getDate()).slice(-2);
    const index = this.daysSelected.findIndex((x) => x == date);
    if (index < 0) this.daysSelected.push(date);
    else this.daysSelected.splice(index, 1);
    if (this.daysSelected.length > 2) this.daysSelected.splice(0, 2);
    if (this.daysSelected.length === 1) {
      const firstElement = this.daysSelected[0].split('-');
      this.minDate = new Date(firstElement[0], firstElement[1] - 1, firstElement[2]);
      this.maxDate=this.maxOriginalDate;
      this.userfilter.get(controlName).markAsUntouched();
      this.userfilter.get(controlName).setValue(this.daysSelected[0]);
    }
    else if (this.daysSelected.length === 2) {
      const firstElement = this.daysSelected[1].split('-');
      this.maxDate = new Date(firstElement[0], firstElement[1] - 1, firstElement[2]);
      this.userfilter.get(controlName).setValue(this.daysSelected[0] + ' - ' + this.daysSelected[1]);
    }
    else {
      this.minDate=this.minOriginalDate;
      this.maxDate=this.maxOriginalDate;
      this.userfilter.get(controlName).setValue('');
      this.userfilter.get(controlName).markAsTouched();
    }
    calendar.updateTodaysDate();
  }
  

  
  closeModal(){
    this.activeModal.close();
  }

  getMinMaxDate(field) {
    this.maxDate = new Date();
    this.minDate = new Date();
      if (field.filter.dateFilterType == 1) {
        if (field.filter.standardDateTypeValue == 0) {
          this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate());
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());
        }
        else if (field.filter.standardDateTypeValue == -1) {
          this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - 1);
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - 1);

        }
        else if (field.filter.standardDateTypeValue == 1) {
          this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - this.minDate.getDay());
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() - this.maxDate.getDay() + 6);
        }
        else if (field.filter.standardDateTypeValue == 2) {
          var date = new Date();
          this.minDate = new Date(date.getFullYear(), date.getMonth(), 1);
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());

        }
        else if (field.filter.standardDateTypeValue ==3) {
          var date = new Date();
          this.minDate = new Date(date.getFullYear(), date.getMonth(), 1);
          this.maxDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        }
        else if (field.filter.standardDateTypeValue == 4) {
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var diff = Number(now) - Number(start);
          var oneDay = 1000 * 60 * 60 * 24;
          var day = Math.floor(diff / oneDay);
          this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day - 1));
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate());

        }
        else if (field.filter.standardDateTypeValue == 5) {
          var now = new Date();
          var start = new Date(now.getFullYear(), 0, 0);
          var end = new Date(now.getFullYear(), 11, 31);
          var diffStart = Number(now) - Number(start);
          var diffend = Number(end) - Number(now);
          var oneDay = 1000 * 60 * 60 * 24;
          var day = Math.floor(diffStart / oneDay);
          var dayEnd = Math.floor(diffend / oneDay);
          this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - (day - 1));
          this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + (dayEnd + 1));
        }
      } else {
        if (field.filter.customPreviousDays == null && field.filter.customFutureDays == null) {
          this.minDate="";
          this.maxDate="";
        }
        else if (field.filter.customPreviousDays == null && field.filter.customFutureDays != null) {
          if (field.filter.customFutureDays >= 0) {
            this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + field.customFutureDays);
          }
        }
        else if (field.filter.customPreviousDays != null && field.filter.customPreviousDays == null) {
          if (field.filter.customPreviousDays >= 0) {
            this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - field.customPreviousDays);
          }
        } else {
          if (field.filter.customPreviousDays >= 0) {
             this.minDate = new Date(this.minDate.getFullYear(), this.minDate.getMonth(), this.minDate.getDate() - field.filter.customPreviousDays);
          }

          if (field.filter.customFutureDays >= 0) {
            this.maxDate = new Date(this.maxDate.getFullYear(), this.maxDate.getMonth(), this.maxDate.getDate() + field.filter.customFutureDays);
          }
        }
      }
  }

}


