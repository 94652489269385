<button #myButton id="refresh" aria-hidden="true" (click)="triggerClick()" class="display-none"></button>
<div class="example-preview" id="dashboardReport">
        <form [formGroup]="reportBuilderForm" novalidate autocomplete="off" >

               <div class="m-content">
                <div class="head col-lg-12">
                   <div class="row flex-container-btw">
                    <div class="col-lg-8 pl-8 pt-1 pb-1">
                        <div class="row">{{parentData.reportName}}</div>
                    </div>
                    <div class="col-lg-4 row flex-container-end">
                        <div class="filter" *ngIf="isUserFilter">
                            <button mat-icon-button (click)="applyfilters()">
                               <mat-icon>
                                 <span class="material-icons" [style.color]="buttonColor">
                                   filter_alt
                                 </span>
                               </mat-icon>
                             </button>                    
                         </div>  
                        <div *ngIf="isEdit">
                            <span class="reportActionBtn" (click)="editDashBoardReport()"><img src="./assets/media/svg/icons/General/edit-dashboard-icon.svg"></span>
                            <span class="reportActionBtn" (click)="removeReport()"><img src="./assets/media/svg/icons/General/delete-dashboard-icon.svg"></span>
                        </div>
                    </div>
                   </div>
                </div>
                <div *ngIf="!isChartDataLoaded" style='min-height: 400px; display: flex; align-items: center; justify-content: center;'>
                        Loading data...
                </div>
                <div class="col-sm-12" style="height: 425px; overflow-y: auto;" *ngIf="isChartDataLoaded">
                    <div >  
                        <div *ngIf="this.reportType == 1" style="overflow-x: auto;">                
                            <div
                                [id]="'chart1'+parentData.reportId"
                                style="flex: 1 1 auto; height: 400px; min-width:100%; " [ngStyle]="{'width':rowData.length>9?((rowData.length)*10.5)+'%':'100%'}"
                                ></div>
                            </div>
                        <div *ngIf="this.reportType == 2" style="overflow-y: auto; height: 400px; "> 
                            <div
                                [id]="'chart2'+parentData.reportId"
                                style="flex: 1 1 auto; width:100%;
                                "[ngStyle]="{'height': rowData.length > 9 ? ((rowData.length * 10.5) + '%') : '100%'}"
                            ></div>  
                        </div>
                        <div *ngIf="this.reportType == 3" style="overflow-x: auto;width: 100%; background-color: white;" > 
                            <div
                                [id]="'chart3'+parentData.reportId"
                                style="flex: 1 1 auto; height: 400px; min-width: 100%;" [ngStyle]="{'width': rowData ? (((rowData.length/8)*100)+300)+'px' : '600px'}"
                            ></div>  
                        </div>
                        <div *ngIf="this.reportType == 4" style="overflow-x: auto;"> 
                            <div
                                [id]="'chart4'+parentData.reportId"
                                style="flex: 1 1 auto; height: 400px;"
                                 [ngStyle]="{'width':rowData.length>9?((rowData.length)*10.5)+'%':'100%'}"
                            ></div>  
                        </div>
        
                        <div *ngIf="showDataCheckbox" class="p-2">
                            <mat-checkbox #checkbox  formControlName="dataTable" [(ngModel)]="dataTable" checked (change)="changeDataTableDisplay(dataTable)"></mat-checkbox>
                            &nbsp; Show data table 
                            <mat-checkbox *ngIf="this.reportType==3" style="margin-left: 10px;" #checkbox2 formControlName="labelData" [(ngModel)]="showLabel" checked (change)="changeLabelDlisplay(showLabel)">
                                Show label
                            </mat-checkbox>
                            &nbsp;
                        </div>
                        
                        <div *ngIf="this.reportType != 6"
                        [style.display]="displayDataTable ? 'block' : 'none'">
                            <ag-grid-angular
                                #agGrid
                                style="width: 100%; height: 425px;"
                                [id]="'myGrid'+parentData.reportId"
                                class="ag-theme-alpine"
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef"
                                [rowData]="rowData"
                                [enableRangeSelection]="true"
                                [enableCharts]="true"
                                
                                [popupParent]="popupParent"
                                [getChartToolbarItems]="getChartToolbarItems"
                                [groupDefaultExpanded]="groupDefaultExpanded"
                                (gridReady)="onGridReady($event)"
                                (rowDataChanged)="onRowDataChanged($event)"
                                ></ag-grid-angular>
                        </div>   
        
                        <div *ngIf="!displayDataTable && this.reportType == 6" style="overflow-x: auto;">
                            <ag-grid-angular
                                #agGrid
                                [ngStyle]="{'width': columnDefs ?  matrixTableWidth+'px' : '100%','height': '400px'}"
                                [id]="'myGrid'+parentData.reportId"
                                class="ag-theme-alpine"
                                [columnDefs]="columnDefs"
                                [defaultColDef]="defaultColDef"
                                [rowData]="rowData"
                                [enableRangeSelection]="true"
                                [enableCharts]="true"
                                [pinnedTopRowData]="pinnedBar"
                                [popupParent]="popupParent"
                                [gridOptions]="gridOptions"
                                [getChartToolbarItems]="getChartToolbarItems"
                                [groupDefaultExpanded]="groupDefaultExpanded"
                                (gridReady)="onGridReady($event)"
                                (rowDataChanged)="onRowDataChanged($event)"
                                ></ag-grid-angular>
                        </div> 
                    </div>   
                </div>
               </div>
        </form> 
</div>  
