export class AlertData {
    title: string;
    body: string;
    type: AlertMessageType;

    constructor(body: string, type: AlertMessageType = null, title?: string) {
        this.title = title ? title : 'Alert';
        this.body = body;
        this.type = type;
    }
}

type AlertMessageType = 'hyperlinkError' | null;