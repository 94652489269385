<div class="example-preview">
    <div>
        <form [formGroup]="editColEntityFieldForm" novalidate autocomplete="off" >
            <div class="modal-header">
                <h4 class="modal-title">Edit Field</h4>
                <button type="button" class="close" aria-label="Close" (click)="('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Field</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input1  maxlength="50" formControlName="fieldName" [(ngModel)]="fieldName" ktAutoTrimSpaces>
                            <mat-icon  matSuffix [ngStyle]="{'color':'grey'}">lock</mat-icon>
                        </mat-form-field>                    
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label form-padding-top-25">Label
                            <span class="mandatory-marker">*</span>
                        </label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field appearance="standard" class="input-100" >
                            <input matInput #input2  maxlength="200" formControlName="label" [(ngModel)]="label" ktAutoTrimSpaces>
                             <mat-icon  matSuffix [ngStyle]="{'color':'#3699FF'}">text_fields</mat-icon> 
                             <mat-hint align="end" *ngIf="!editColEntityFieldForm.get('label').hasError('maxlength')">
                                {{ editColEntityFieldForm.get('label').value?.length || 0 }}/{{ maxLabelLength }}
                              </mat-hint>
                             <mat-error *ngIf="isControlHasError('label', 'required')">Please enter a label.</mat-error>
                             <mat-error *ngIf="editColEntityFieldForm.get('label').hasError('maxlength')">
                                Label should not exceed {{ maxLabelLength }} characters.
                              </mat-error>  
                        </mat-form-field>                    
                    </div>
                </div>
              <!--  <div *ngIf="reportType == 1 || reportType == 3 || reportType == 4 || reportType == 5" class="form-group row">
                    <div class="col-md-4">
                        <label class="col-form-label pt-6">Measure</label>
                    </div>
                    <div class="col-md-8">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="measure" (selectionChange)="changeMeasureType($event.value)" >
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" [formControl]="measureValueType" ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let measure of measureTypeList| search : measureValueType.value :'value'" [value]="measure.id">
                                    {{measure.value}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>  
                </div> -->
                <div *ngIf="displayDateGroupBy && (reportType == 6)">
                    
                    <div class="form-group row">
                        <div class="col-md-4">
                            <label class="col-form-label form-padding-top-25">Group By
                                <span class="mandatory-marker">*</span>
                            </label>
                    </div>
                    <div class="col-md-8 form-padding-top-15">
                        <mat-form-field class="mat-form-field-fluid input-100">
                            <mat-select formControlName="dateGroupBy" >
                                <mat-option>
                                    <ngx-mat-select-search placeholderLabel = "Search" noEntriesFoundLabel="No options found" #dateGroupByFilter ></ngx-mat-select-search>
                                </mat-option>
                                <mat-option *ngFor="let groupByTypeValue of dateGroupByList| search : dateGroupByFilter.value :'value'" [value]="groupByTypeValue.id">
                                    {{groupByTypeValue.value}}
                                </mat-option>
                                <mat-error *ngIf="isControlHasError('dateGroupBy', 'required')">Select one</mat-error>
                            </mat-select>
                        </mat-form-field>
                    </div>  
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger"  (click)="Delete()">Delete Field</button>
                <td style="padding-right: 195px;"></td>
                <button type="button" class="btn btn-secondary"  (click)="Close()">Cancel</button>
                <button type="button" class="btn btn-primary"   (click)="Save()">Save</button>
            </div>
        </form> 
    </div>
</div>  
