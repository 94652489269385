import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../../../../../environments/environment';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ReportBuilderServiceService {

  
  constructor(private http:HttpClient) { }

  getAllConfigAssistant(projectId:number){
      return this.http.get<any[]>(
        environment.baseUrl + '/setting/api/configuration/assistant?projectId='+projectId);
  }

  getReportType(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl + '/reportsfetcher/api/reportBuilder/getReportTypes?projectId='+projectId);
  }

  getEntityDetails(projectId:number){
    return this.http.get<any[]>(
      environment.baseUrl + '/reportsfetcher/api/reportBuilder/getEntityDetails?projectId='+projectId);
  }
 
  getEntityFieldDetails(projectId:number, id:string){
    return this.http.get<any[]>(
      environment.baseUrl + '/reportsfetcher/api/reportBuilder/getEntityFieldDetails?projectId='+projectId+'&entityIds='+id);
  }
  previewData(previewData){
    
      return this.http.post<any[]>(environment.baseUrl +'/reportsfetcher/api/reportBuilder/fetchReportData',previewData)
  }

  saveReport(saveReportData){
    return this.http.post<any[]>(environment.baseUrl +'/reports/api/reportBuilder/saveReport',saveReportData)
}

getReportLocation(projectId:any, reportid: string=''){
  return this.http.get<any>(environment.baseUrl+'/reports/api/reportBuilder/fetchReportDashboardLocationList?projectId='+projectId+'&reportId='+reportid,{}).pipe(
    map(data => {
      return data;
   })
  )
}
}
