<div *ngIf="selectedProject === 578 || selectedProject === 413" class="card card-custom" [ngClass]="cssClasses">
    <!-- Header -->
    <div class="card-header pl-2 pr-5" [ngStyle]="{'border-bottom':'0'}">
        <div class="card-title text-primary font-weight-bold font-size-h6"> 
            <a (click)="isCollapsed = !isCollapsed" [attr.aria-expanded]="!isCollapsed" aria-controls="conversation" [ngStyle]="{'cursor':'pointer'}">
                <img *ngIf="isCollapsed" src="./assets/media/svg/icons/General/Angle-double-left.svg" class="pr-1">
                <img *ngIf="!isCollapsed" src="./assets/media/svg/icons/General/Angle-double-bottom.svg" class="pr-1">
            </a>
            Conversations
        </div>
        <div class="card-toolbar">
            <div class="example-tools justify-content-center">
                <a class="btn btn-xs btn-icon btn-bg-light btn-icon-primary btn-hover-primary"  (click)="sortConversations()">
                    <img src="./assets/media/svg/icons/General/sort.svg" class="pr-1">
                </a>
            </div>
        </div>
    </div>
    <!-- Body -->
    <div id="conversation" [ngbCollapse]="isCollapsed" class="card-body p-0 mb-1 conversation-body-scroll">
        <ngx-spinner size="default" type="ball-spin-clockwise" name='conversation'>  
            <p style="color: white">Fetching Data... </p>  
        </ngx-spinner>
        <div class="font-size-h6 text-center" *ngIf="isError">{{errorMessage}}</div>
        <!-- Conversation Start -->
        <!-- <div class="card card-border rounded p-2 ml-2 mr-2 mb-2">
            </div> -->
            <div class="card card-container card-border rounded p-2 ml-2 mr-2 ">
                <div *ngFor="let card of cards" >
                    <div class="inner-card d-flex align-items-center" (click) = openWhatsappConversation(card.id)>
                        <div class="icon-left">
                            <span class="m-2 p-2" ngbTooltip="{{card.name}}">
                                <a>{{card.name | initials}}</a>
                            </span>
                        </div>
                        <div class="content-center flex-grow-1 text-center">
                            <p>{{ card.title }}</p>
                        </div>
                        <div class="icon-right d-flex">
                            <img *ngIf="card.isBasket" src="./assets/media/svg/icons/General/cart.svg" class="pl-1 pr-1 svg-alignment">
                            <img *ngIf="card.isDocument" src="./assets/media/svg/icons/General/document.svg" class="pl-1 pr-1 svg-alignment">
                            <img *ngIf="!card.isConversationOpen" src="./assets/media/svg/icons/General/checkmark.svg" class="pl-1 pr-1 svg-alignment">
                            <img *ngIf="card.isConversationOpen" src="./assets/media/svg/icons/General/radiobutton.svg" class="pl-1 pr-1 svg-alignment">
                        </div>
                
                    </div>
                </div>
        </div>
        <!-- Conversation End -->
    </div> 
</div>
